import React from "react";
import styled from "styled-components";
import Button from "../../../../../components/button/Button";
import Flex from "../../../../../components/flex/Flex";
import BottomModal from "../../../../../components/modal/BottomModal";
import SimpleTopBar from "../../../../../layouts/basic/SimpleTopBar";
import AppraisalDetail from "./AppraisalDetail";
import BuySellDetail from "./BuySellDetail";
import DepositDetail from "./DepositDetail";
import GoodsDetail from "./GoodsDetail";
import SafeTradeDetail from "./SafeTradeDetail";
import WithdrawDetail from "./WithdrawDetail";
import CouponDetail from "./CouponDetail";
import EventDetail from "./EventDetail";
import RNHeader from "../../../../../components/common/RNHeader";
import SendAssetsDetail from "./SendAssetsDetail";
import ReceiveAssetsDetail from "./ReceiveAssetsDetail";

const TradeDetail = ({ visible, onClose, item = {} }) => {
  const { tradeTypeKor = "" } = item;

  let Detail = <Flex />;

  if (tradeTypeKor === "매수" || tradeTypeKor === "매도") {
    Detail = <BuySellDetail {...item} />;
  } else if (tradeTypeKor === "실물인출") {
    Detail = <GoodsDetail {...item} />;
  } else if (tradeTypeKor === "감정평가") {
    Detail = <AppraisalDetail {...item} />;
  } else if (tradeTypeKor === "입금") {
    Detail = <DepositDetail {...item} />;
  } else if (tradeTypeKor === "출금") {
    Detail = <WithdrawDetail {...item} />;
  } else if (tradeTypeKor === "쿠폰") {
    Detail = <CouponDetail {...item} />;
  } else if (tradeTypeKor === "이벤트") {
    Detail = <EventDetail {...item} />;
  } else if (tradeTypeKor.includes("안심직거래")) {
    Detail = <SafeTradeDetail {...item} />;
  } else if (tradeTypeKor.includes("자산 보냄")) {
    Detail = <SendAssetsDetail {...item} />;
  } else if (tradeTypeKor.includes("자산 받음")) {
    Detail = <ReceiveAssetsDetail {...item} />;
  }

  console.log(item);

  return (
    <BottomModal visible={visible}>
      <RNHeader
        visible={false}
        goBack={visible ? "CUSTOM" : "DEFAULT"}
        onGoBack={visible ? onClose : null}
      />
      <Flex style={{ height: window.innerHeight, background: "white" }}>
        <SimpleTopBar border style={{ height: 50 }}>
          주문상세내역
        </SimpleTopBar>
        <Container>
          <Flex style={{ overflowY: "auto", flex: 1 }}>{Detail}</Flex>
          <Button
            label="확인"
            style={{ borderRadius: 8, marginTop: 16 }}
            onClick={onClose}
          />
        </Container>
      </Flex>
    </BottomModal>
  );
};

const Container = styled(Flex)`
  flex: 1;
  background: #fcfcfc;
  padding: 16px 18px;
  height: calc(100% - 50px);
`;

export default TradeDetail;
