import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

export default function InquiryListItem({ pri, reply }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  return (
    <Flex className={classes.listitem}>
      <ButtonBase
        disabled={pri || !reply}
        onClick={() => {
          setOpen(!open);
        }}
        row
        className={classes.header}
      >
        <Flex style={{ alignItems: "flex-start" }}>
          <Flex row className="row-center">
            <img
              src={images.security}
              style={{ marginRight: "5px" }}
              className="small-icon"
            />
            <Text
              className={classes.l1}
              font={fonts.notoSansKRMedium}
              style={{ color: pri ? colors.text : "#999999" }}
            >
              {pri ? "문의합니다." : "비밀글입니다."}
            </Text>
          </Flex>
          <Text className={classes.l2}>
            <Text
              font={fonts.notoSansKRMedium}
              style={{ color: pri ? colors.text : "#999999" }}
            >
              김*성{" "}
            </Text>
            ㅣ 2020.11.12
          </Text>
        </Flex>
        <Text
          style={{ color: pri ? colors.text : "#999999" }}
          className={classes.l3}
        >
          {reply ? "답변완료" : "미답변"}
        </Text>
      </ButtonBase>

      <Text className={classes.desc}>
        문의하기 내용입니다문의하기 내용입니다 문의하기 내용입니다 문의하기
        내용입니다문의하기 내용입니다
      </Text>

      {open && (
        <Flex row className={classes.replyContent}>
          <Flex row style={{ alignItems: "center", alignSelf: "flex-start" }}>
            <img alt="rught" src={images.rightgray} className={classes.rigt} />
            <Text className={classes.reply}>답변</Text>
          </Flex>
          <Text className={classes.replydesc}>
            내용입니다 문의하기 내용입니다문의하기 내용입니다문의하기 내용입니다
            문의하기 내용입니다
            <br />
            <Text>2019.11.11.15:30</Text>
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  listitem: {
    margin: "0px 13px",
    padding: "20px 0px",
    boxSizing: "border-box",
    borderBottom: "1px solid #eee",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  l2: {
    fontSize: "11px",
    color: "#999",
  },
  l1: {
    marginBottom: "5px",
    color: "#999",
    fontSize: "14px",
  },
  l3: {
    color: "#999",
    fontSize: "13px",
  },
  desc: {
    marginTop: "16px",
    fontSize: "13px",
    color: colors.text,
  },
  replydesc: {
    fontSize: "13px",
    marginLeft: "17px",
    color: colors.text,
    "& span": {
      fontSize: "11px",
      color: "#999999",
    },
  },
  replyContent: {
    marginTop: "18px",
  },
  reply: {
    borderRadius: "8px",
    backgroundColor: colors.text,
    textAlign: "center",
    fontSize: "11px",
    boxSizing: "border-box",
    lineHeight: 1,
    whiteSpace: "nowrap",
    color: "#fff",
    padding: "6px 8px",
    alignSelf: "flex-start",
  },
  rigt: {
    width: "13px",
    height: "10px",
    objectFit: "contain",
    marginLeft: "5px",
    marginRight: "5px",
  },
});
