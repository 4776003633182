import {
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import ProductBasicInformation from "../../components/product-basic-information/ProductBasicInformation";
import ProductImages from "../../components/product-images/ProductImages";
import ProductInquiry from "../../components/product-inquiry/ProductInquiry";
import ProductReview from "../../components/product-review/ProductReview";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import Information from "./Information";

export default function DirectTransactionPDetail({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [data, setData] = useState([{}, {}, {}, {}]);
  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "상품 상세",
        border: true,
      }}
    >
      {tab === 0 && <ProductImages />}
      {tab === 0 && <Information />}
      <Tabs
        value={tab}
        TabIndicatorProps={{ style: { background: "black" } }}
        style={{
          borderBottom: "1px solid #eee",
        }}
        onChange={(e, v) => {
          setTab(v);
        }}
        indicatorColor="primary"
        textColor={colors.text}
        variant="fullWidth"
        className={classes.tabs}
      >
        <Tab
          // value="appraise"
          color={colors.gray}
          label="상세설명"
        />
        <Tab
          color={colors.gray}
          //value="real"
          label="기본정보"
        />
        <Tab
          color={colors.gray}
          //value="direct"
          label="상품문의"
        />
      </Tabs>
      <Flex className={classes.main}>
        {(() => {
          switch (tab) {
            case 0:
              return (
                <>
                  <Flex row className={classes.detailWrap}>
                    <Text>제조사</Text>
                    <Text font={fonts.notoSansKRMedium}>Rolex</Text>
                  </Flex>
                  <Flex row className={classes.detailWrap}>
                    <Text>제품명</Text>
                    <Text font={fonts.notoSansKRMedium}>Oyster Perpetual</Text>
                  </Flex>
                  <Flex row className={classes.detailWrap}>
                    <Text>제조출시년도</Text>
                    <Text font={fonts.notoSansKRMedium}>2018년</Text>
                  </Flex>
                  <Flex row className={classes.detailWrap}>
                    <Text>상품상태</Text>
                    <Text font={fonts.notoSansKRMedium}>매우 좋음</Text>
                  </Flex>

                  <img
                    style={{ marginTop: "50px" }}
                    src={images.sample2}
                    className={classes.detailImage}
                  />
                  <img src={images.sample2} className={classes.detailImage} />
                </>
              );
            case 1:
              return (
                <ProductBasicInformation
                  data={[
                    { label: "제조사", value: "Rolex" },
                    { label: "제품명", value: "Oyster Perpetual" },
                    { label: "모델번호", value: "6718" },
                    { label: "스타일", value: "Dress" },
                    { label: "시중산품가격", value: "-" },
                    { label: "제조출시년도", value: "2021년" },
                    { label: "무브먼트", value: "기계식자동" },
                    { label: "보증서", value: "유" },
                    { label: "상품구분", value: "중고상품" },
                    { label: "상품상태", value: "매우 좋음" },
                    { label: "A/S안내", value: "한국금거래소" },
                  ]}
                />
              );
            case 2:
              return <ProductInquiry />;
          }
        })()}

        <div style={{ height: "100px" }} />
      </Flex>
      {tab === 0 && (
        <Button
          onClick={() => {
            history.push(routes.order, { type: "direct-transaction" });
          }}
          label="구매하기"
          className="fixed-button"
        />
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
  textButton: {
    paddingRight: "16px",
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  main: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  detailWrap: {
    margin: "0px 12px",
    marginTop: "10px",
    "&:first-child": {
      marginTop: "25px",
    },
    alignItems: "center",
    "& span": {
      color: "#666",
      flex: 1,
      fontSize: 14,
      "&:last-child": {
        color: "#000",
        width: "auto",
        flex: 3,
      },
    },
  },
  detailImage: {
    width: "100%",
    height: "170px",
    objectFit: "cover",
    marginTop: "10px",
  },
});
