import React, { useState } from "react";
import { useDispatch } from "react-redux";
import deleteIcon from "../../assets/images/delete.png";
import ic_icon1 from "../../assets/images/ic_icon1.png";
import ic_ok_green_back from "../../assets/images/ic_ok_green_back.png";
import ic_ok_green_back2 from "../../assets/images/ic_ok_green_back2.png";
import ic_gold from "../../assets/images/ic_gold.png";
import ic_silver from "../../assets/images/ic_silver.png";
import ic_bank_won from "../../assets/images/ic_bank_won.png";
import ic_icon2 from "../../assets/images/ic_icon2.png";
import ic_no_red_back from "../../assets/images/ic_no_red_back.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { numberWithCommas } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";
import { useHistory } from "react-router";
import { messageError } from "../../redux/message/MessageActions";

const CouponRegister = () => {
  const dispatch = useDispatch();
  const [couponNumber, setCouponNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [couponData, setCouponData] = useState(null);
  const [complete, setComplete] = useState(false);

  // 쿠폰 조회
  const handleClickSearch = () => {
    if (couponNumber) {
      dispatch(loadingStart);
      APIS.getCouponSearch(couponNumber)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            setCouponData(data);
          } else {
            setErrorMessage(message);
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  // 쿠폰 등록
  const handleClickSubmit = async () => {
    dispatch(loadingStart);

    const serviceBreak = await APIS.getServiceBreak();
    if (serviceBreak) {
      dispatch(messageError(serviceBreak));
      dispatch(loadingEnd);
      return;
    }

    APIS.postCouponApproval({ couponNumber }).then(
      ({ data: { success, data, message } }) => {
        if (success) {
          pendingCouponApproval(1);
          // setComplete(true);
        } else {
          dispatch(actionError(message));
          fetchComplete();
        }
      }
    );
  };

  // 쿠폰 승인 대기
  const pendingCouponApproval = (count) => {
    setTimeout(() => {
      APIS.getCouponConfirm(couponNumber).then(({ data: { success } }) => {
        if (success) {
          setComplete(true);
          fetchComplete();
        } else if (count === 10) {
          dispatch(actionError("쿠폰승인 처리 중 입니다."));
          fetchComplete();
        } else {
          pendingCouponApproval(count + 1);
        }
      });
    }, 1000);
  };

  const fetchComplete = () => {
    setCouponNumber("");
    setCouponData(null);
    dispatch(loadingEnd);
  };

  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "쿠폰 등록" }}>
      {complete ? (
        <Complete />
      ) : (
        <div className="kbcoupon_wrap" style={{ flex: 1 }}>
          <div className="kbcoupon_input_wrap" style={{ flex: 1 }}>
            <h3 className="kbcoupon_input_title">쿠폰 번호를 입력해주세요.</h3>
            <div className="kbcoupon_input">
              <input
                type="text"
                placeholder="쿠폰 번호 입력"
                value={couponNumber}
                onChange={({ target: { value } }) => {
                  setCouponNumber(value.split(" ").join(""));
                }}
              />
              <img
                src={deleteIcon}
                alt="x"
                onClick={() => {
                  setCouponNumber("");
                }}
              />{" "}
            </div>
            <button
              className={`${couponNumber ? "on" : "off"}`}
              onClick={handleClickSearch}
            >
              확인
            </button>
          </div>
          <div className="kbcoupon_noti_wrap">
            <h3>
              <img src={ic_icon1} />
              금방금방 쿠폰 등록 안내
            </h3>
            <ul>
              <li>
                {/* 금방금방 쿠폰은 금방금방 앱에서 이용 가능한 보유 금 <br />
                또는 포인트로 충전됩니다. */}
                등록하시는 쿠폰은 금방금방 앱에서 이용 가능한 자산으로
                충전됩니다.
              </li>
              <li>
                쿠폰의 충전 자산 또는 용도는 판매처의 개별 판매 정책에 따라
                상이하게 적용됩니다.
              </li>
              <li className="org">
                등록된 쿠폰은 환불 또는 사용 취소되지 않습니다.
              </li>
            </ul>
          </div>
        </div>
      )}

      {couponData && (
        <ResultPopup
          onClose={() => {
            setCouponData(null);
          }}
          onSubmit={handleClickSubmit}
          data={couponData}
        />
      )}

      {errorMessage && (
        <ErrorPopup
          message={errorMessage}
          onClick={() => {
            setErrorMessage("");
          }}
        />
      )}
    </BasicLayout>
  );
};

const ResultPopup = ({ onClose, onSubmit, data }) => {
  const { assetType, couponName, gram, point } = data;
  return (
    <div
      className="kbcoupon_wrap over_bg"
      style={{ position: "fixed", top: 0, width: "100%" }}
    >
      <div className="kbcoupon_select">
        <div className="kbcoupon_top">
          <p>교환권 조회 결과</p>
          <h3>
            <img src={ic_ok_green_back2} />
            등록 가능
          </h3>
        </div>
        <div className="kbcoupon_cont">
          <p>
            <span>{couponName}</span>은 <span>나의 보유 자산으로 등록</span>
            됩니다.
          </p>
          <div className="kb_assets">
            <ul>
              <li>
                {assetType === "금" && (
                  <>
                    <img src={ic_gold} />
                    {assetType}
                  </>
                )}
                {assetType === "은" && (
                  <>
                    <img src={ic_silver} />
                    {assetType}
                  </>
                )}
              </li>
              <li>
                <span className="spoqa">{numberWithCommas(gram)}</span>g
              </li>
            </ul>
            <ul>
              <li>
                <img src={ic_bank_won} />
                포인트
              </li>
              <li>
                <span className="spoqa">{numberWithCommas(point)}</span>원
              </li>
            </ul>
          </div>
          <p className="noti">
            <img src={ic_icon2} />
            등록이 완료되면 <span>사용 취소</span>가 <span>불가</span>합니다.
          </p>
        </div>
        <div className="kbcoupon_btn">
          <button className="cancel" onClick={onClose}>
            취소
          </button>
          <button className="ok" onClick={onSubmit}>
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

const ErrorPopup = ({ message, onClick }) => {
  return (
    <div
      className="kbcoupon_wrap over_bg"
      style={{ position: "fixed", top: 0, width: "100%" }}
    >
      <div className="kbcoupon_select">
        <div className="kbcoupon_top red">
          <p>교환권 조회 결과</p>
          <h3>
            <img src={ic_no_red_back} />
            등록 불가
          </h3>
        </div>
        <p className="kbcoupon_text">{message}</p>
        <div className="kbcoupon_btn red">
          <button className="ok" onClick={onClick}>
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

const Complete = () => {
  const history = useHistory();
  return (
    <div className="kbcoupon_wrap" style={{ flex: 1 }}>
      <div className="kbcoupon_complete">
        <h3>
          <img src={ic_ok_green_back} />
          <br />
          <span>등록 완료</span> 되었습니다.
        </h3>
        <button
          className="ok"
          onClick={() => {
            history.goBack();
          }}
        >
          확인
        </button>
      </div>
    </div>
  );
};
export default CouponRegister;
