import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import colors from "../../libs/colors";
import images from "../../libs/images";
import routes from "../../libs/routes";
import Flex from "../flex/Flex";
import Text from "../text/Text";

const MENUS = [
  { TEXT: "홈", PATH: routes.main },
  { TEXT: "입출금", PATH: routes.depositWithdrawal },
  { TEXT: "마이", PATH: routes.mypage },
  { TEXT: "매수/매도", PATH: routes.trading },
  { TEXT: "메뉴", PATH: routes.menu },
];
export default function Bottombar({ relative, navigate }) {
  const classes = useStyle();
  const location = useLocation();
  const history = useHistory();
  const handleNavi = (path) => () => navigate(path);
  return (
    <Flex
      className={classes.root + " " + (!relative && classes.rootAbsolute)}
      row
    >
      {MENUS.map(({ TEXT, PATH }, i) => {
        let isActive = location.pathname.startsWith(PATH);
        if (PATH === routes.main) {
          isActive =
            location.pathname === routes.main ||
            location.pathname.startsWith("/main");
        }

        const imageSrc = `bottom${i + 1}${isActive ? "on" : ""}`;
        return (
          <BottomButton
            key={TEXT + i}
            onClick={handleNavi(PATH)}
            isActive={isActive}
            image={images[imageSrc]}
            text={TEXT}
          />
        );
      })}
    </Flex>
  );
}

const BottomButton = ({ onClick, isActive, image, text }) => {
  const classes = useStyle();
  return (
    <ButtonBase onClick={onClick} className={classes.item}>
      <img alt="b1" src={image} className={classes.icon} />
      <Text className={`${classes.label} ${isActive && classes.labelOn}`}>
        {text}
      </Text>
    </ButtonBase>
  );
};

const useStyle = makeStyles({
  root: {
    borderTop: "0.7px solid #dddddd",
    // minHeight: "60px",
    height: 60,
    backgroundColor: "#fff",
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 30,
    right: 0,
    alignItems: "stretch",
    zIndex: 10,
    overflow: "hidden",
  },
  rootAbsolute: {
    bottom: 0,
    position: "fixed",
    left: 0,
    right: 0,
  },
  item: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    width: "20px",
    height: "20px",
    objectFit: "contain",
  },
  label: {
    fontSize: "13px",
    color: colors.gray,
  },
  labelOn: {
    color: colors.primary,
  },
});
