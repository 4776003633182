export const listActionType = {
  show: "list/show",
  hide: "list/hide",
};

export const listShow = (title, data, onPress) => (dispatch) => {
  dispatch({ type: listActionType.show, title, data, onPress });
};
export const listHide = () => (dispatch) => {
  dispatch({ type: listActionType.hide });
};
