import { ButtonBase, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import VisitReservation from "../../components/common/VisitReservation";
import Flex from "../../components/flex/Flex";
import ProductListItem from "../../components/product-list/ProductListItem";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { actionShow } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { formatTime, objToQueryStr, phoneFormat } from "../../services/utils";
import Cancel from "./Cancel";
import CompleteCard from "./CompleteCard";
import DeliveryState from "./DeliveryState";
import * as APIS from "../../libs/apis";

export default function AppraiseDetail({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const state = history.location.state || {};

  useEffect(() => {
    fetchAppriaisalOrder();
  }, [state, history.location.key]);

  const fetchAppriaisalOrder = () => {
    const { id } = state;
    dispatch(loadingStart);
    APIS.getAppraisalRequestDetail(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setData(data);
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  // 접수 취소
  const requestCancel = () => {
    dispatch(loadingStart);
    APIS.deleteAppraisalRequest(data.id)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(toastShow("신청 취소되었습니다."));
          history.goBack();
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  let returnProcess =
    data?.appraisalRequestStatus?.filter((x) => x.status?.includes("반송"))
      .length > 0;

  if (!data) {
    return (
      <Flex className={classes.root}>
        <Flex
          row
          className="row-between"
          style={{
            margin: "16px 0px",
          }}>
          <div />
          <ButtonBase onClick={history.goBack} className={classes.close}>
            <img src={images.close} />
          </ButtonBase>
        </Flex>
      </Flex>
    );
  }

  const { assetType = "" } =
    data.appraisalRequestStatus.find((x) => x.status === "감정완료") || {};

  return (
    <Flex key={JSON.stringify(data)} className={classes.root}>
      <Flex row className="row-between">
        {(() => {
          //현장감정,내방,택배
          switch (data.status) {
            case "접수":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  <span
                    style={{
                      fontSize: 21,
                      color: "#333333",
                      fontFamily: fonts.notoSansKRBold,
                    }}>
                    접수 완료되었습니다.
                  </span>
                  <br />
                  {data.method === "택배" ? (
                    <span style={{ fontSize: 16, color: "#666666" }}>
                      우체국 택배 기사님께서 택배를 수거하실거에요. <br /> 잘
                      포장해서 준비해주세요.
                    </span>
                  ) : (
                    <span style={{ fontSize: 16, color: "#666666" }}>
                      매장에 방문하여 감정 받을 수 있습니다.
                    </span>
                  )}
                </Text>
              );
            case "배송중":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  {/* 접수 승인 되었습니다.
                  <br />
                  제품이 배송중입니다. */}
                  제품이 배송중입니다.
                </Text>
              );
            case "감정시작":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  감정사가 감정중입니다.
                </Text>
              );
            case "감정반려":
            case "접수반려":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  신청 접수가 반려되었습니다.
                </Text>
              );
            case "감정완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  제품이 감정 완료되었습니다.
                  <br />
                  최종 감정가를 확인해 주세요.
                </Text>
              );
            case "교환완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  {assetType === "SILVER" ? "은(SILVER)" : "금(GOLD)"}으로 교환
                  완료 되었습니다.
                  <br />
                  마이 메뉴에서 확인하실 수 있습니다.
                </Text>
              );
            case "반송신청":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송신청 접수되었습니다.
                </Text>
              );
            case "반송완료":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송 완료되었습니다.
                </Text>
              );
            case "반송반려":
              return (
                <Text className={classes.title} font={fonts.notoSansKRMedium}>
                  반송 신청이 반려되었습니다.
                </Text>
              );
          }
        })()}
        <ButtonBase onClick={history.goBack} className={classes.close}>
          <img src={images.close} />
        </ButtonBase>
      </Flex>

      <CompleteCard navigate={navigate} {...data} index={data.index} />

      <Flex row className={classes.card + " shadow row-between"}>
        <Flex>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {data.number}
          </Text>
          <Text className={classes.label2}>
            {formatTime(
              data.appraisalRequestStatus[0]?.createdAt,
              "YYYY.MM.DD"
            )}
          </Text>
        </Flex>

        {data.appraisalRequestStatus[data.appraisalRequestStatus?.length - 1]
          ?.status === "접수" && (
          <ButtonBase
            onClick={() => {
              dispatch(
                actionShow(
                  "신청을 취소하시겠습니까?",
                  ["닫기", "신청취소"],
                  [null, requestCancel]
                )
              );
            }}
            className={classes.button}>
            신청취소
          </ButtonBase>
        )}
      </Flex>

      {data.status?.includes("반려") && <Cancel {...data} />}

      <Flex row>
        <DeliveryState {...data} />

        <Flex className={classes.deliveryDesc}>
          {data.method !== "택배" ? null : (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "접수"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          )}
          {data.appraisalRequestStatus.filter(
            (x) => x.status === (data.method !== "택배" ? "접수" : "배송중")
          ).length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) =>
                    x.status === (data.method !== "택배" ? "접수" : "배송중")
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {/* {data.appraisalRequestStatus.filter((x) => x.status === "배송중")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "배송중"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )} */}
          {data.appraisalRequestStatus.filter((x) => x.status === "감정시작")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정시작"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {data.appraisalRequestStatus.filter((x) => x.status === "감정완료")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "감정완료"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
          {data.appraisalRequestStatus.filter((x) => x.status === "교환완료")
            .length >= 1 ? (
            <Text className={classes.deliveryLabel1}>
              {formatTime(
                data.appraisalRequestStatus.filter(
                  (x) => x.status === "교환완료"
                )[0].createdAt,
                "YYYY.MM.DD HH:mm"
              )}
            </Text>
          ) : (
            <Text className={classes.deliveryLabel1} style={{ color: "#fff" }}>
              1
            </Text>
          )}
        </Flex>
      </Flex>

      <Divider style={{ height: 3, marginTop: 25 }} />
      {data.method === "현장감정" || data.method === "택배" ? (
        ""
      ) : (
        <>
          <Text className={classes.label3}>
            <Text font={fonts.notoSansKRMedium}>
              {data.appraisalSimples.length}
            </Text>
            개의 제품 교환
          </Text>
          <Divider style={{ margin: "13px" }} />
          {data.appraisalSimples.map((x, i) => {
            return (
              <ProductListItem
                {...x}
                bottom={i + 1 === data.appraisalSimples.length}
                key={i.toString()}
              />
            );
          })}
          <Divider style={{ height: 3, marginTop: 20 }} />
        </>
      )}
      {state.isModify ? (
        <ModifyReservation id={data.id} />
      ) : (
        <Flex style={{ position: "relative" }}>
          {data.status === "접수" && data.method === "내방" && (
            <ButtonMini
              label="수정"
              className={classes.modifyButton}
              textStyle={{ fontSize: 14 }}
              onClick={() => {
                history.replace(location.pathname, {
                  ...state,
                  isModify: true,
                });
              }}
            />
          )}

          <Flex
            style={{
              marginBottom: data.method === "현장감정" ? 20 : 30,
            }}
            row
            className={classes.bottomHeader + " row-between"}>
            <Flex>
              <Text className={classes.label5}>
                {data.method === "택배"
                  ? "택배 감정 접수 정보"
                  : "제품 접수 정보"}
                {data.method === "현장감정" ? null : (
                  <>
                    <br />
                    <Text
                      font={fonts.notoSansKRMedium}
                      style={{ lineHeight: 1 }}>
                      {data.method === "내방"
                        ? "직접 내방 접수 예약하셨습니다."
                        : "택배 감정 신청하셨습니다."}
                      {data.method === "내방" ? (
                        <Text
                          style={{ color: colors.textRed, fontSize: 12 }}
                          font={fonts.notoSansKRMedium}>
                          <br />
                          매장 방문시 본인 외에는 접수가 불가합니다.
                        </Text>
                      ) : (
                        ""
                      )}
                    </Text>
                  </>
                )}
              </Text>
            </Flex>

            {/*
            {data.method === "현장감정" ? null : data.method === "택배" &&
          data.appraisalRequestStatus.filter((x) => x.status === "receiving").length === 0 ? (
          <ButtonBase className={classes.button2}>정보 변경</ButtonBase>
        ) : null}
          */}
          </Flex>

          <Flex
            className={classes.card + " shadow"}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <span
              style={{
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                marginLeft: 20,
              }}>
              {data.method === "택배" &&
                data.status === "접수" &&
                "아래의 정보와 주소로 수거 예정입니다."}
            </span>
            {data.method !== "택배" && (
              <Flex row className={classes.simpleTable}>
                <Text>수거 예정일</Text>
                <Text>{data.collectDate}</Text>
              </Flex>
            )}
            {data.method !== "택배" ||
            (data.method === "택배" && data.status === "접수") ? (
              <>
                <Flex row className={classes.simpleTable}>
                  <Text>성함</Text>
                  <Text>{data.user.name}</Text>
                </Flex>
                <Flex row className={classes.simpleTable}>
                  <Text>연락처</Text>
                  <Text>{phoneFormat(data.user.phone)}</Text>
                </Flex>
              </>
            ) : (
              ""
            )}
            {data.method === "택배" && data.status === "접수" ? (
              <Flex row className={classes.simpleTable}>
                <Text>주소</Text>
                <Text>
                  {data.collectAddress} {data.collectAddressDetail}
                </Text>
              </Flex>
            ) : data.method !== "택배" ? (
              <Flex row className={classes.simpleTable}>
                <Text>{returnProcess ? "반송매장" : "방문매장"}</Text>
                <Text>
                  <Text
                    font={fonts.notoSansKRMedium}
                    style={{ color: "black" }}>
                    {data.shop.name}
                  </Text>
                  <br />
                  <Text style={{ fontSize: 12 }}>
                    {data.shop.address} {data.shop.addressDetail}
                  </Text>
                </Text>
              </Flex>
            ) : (
              ""
            )}
            {data.method !== "현장감정" ? (
              <>
                <Flex row className={classes.simpleTable}>
                  <Text>{data.method === "택배" ? "" : "방문예약일"}</Text>
                  {data.method !== "택배" && (
                    <Text font={fonts.notoSansKRBold}>{data.visitDate}</Text>
                  )}
                </Flex>
                {/* <Flex row className={classes.simpleTable}>
                  <Text>영업시간</Text>
                  <Text>{data.businessTimes}</Text>
                </Flex> */}
              </>
            ) : (
              ""
            )}
            {data.method === "택배" && (
              <div>
                <Flex row className={classes.simpleTable}>
                  {data.status === "반송신청" ? (
                    <div
                      style={{
                        gap: 16,
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      <Flex row>
                        <Text>성함</Text>
                        <Text>{data.user.name}</Text>
                      </Flex>
                      <Flex row>
                        <Text>연락처</Text>
                        <Text>{phoneFormat(data.user.phone)}</Text>
                      </Flex>
                      <Flex row>
                        <Text>반송 주소</Text>
                        <Text>
                          {data.collectAddress} {data.collectAddressDetail}
                        </Text>
                      </Flex>
                    </div>
                  ) : (
                    <Flex row style={{ width: "100%" }}>
                      <Text>감정센터</Text>
                      <div>
                        <Text style={{ color: "#333" }}>
                          서울 특별시 종로구 돈화문로 5가길 1
                        </Text>
                        <br />
                        <Text>피카디리 플러스 8층 감정센터 앞</Text>
                      </div>
                    </Flex>
                  )}
                </Flex>
              </div>
            )}
          </Flex>
          {data.method !== "택배" && (
            <Text font={fonts.notoSansKRMedium} className={classes.message}>
              <img
                alt="w"
                src={images.danger}
                style={{
                  width: 12,
                  height: 12,
                  marginRight: "6px",
                  objectFit: "contain",
                }}
              />
              확인사항
            </Text>
          )}
          {data.method === "택배" ? (
            <>
              {/* <Text className={classes.desc} style={{ marginTop: "5px" }}>
                <Text font={fonts.notoSansKRMedium}>
                  ㆍ꼭 박스포장을 해주셔야 수거가 가능합니다.
                </Text>
                <br />
                ㆍ박스 포장이 되지 않은 경우 제품 수거가 거절될 수 있습니다.
                <br />
                ㆍ택배수거일은 택배사 사정에 따라 달라질 수 있습니다.
                <br />
                ㆍ상세감정 결과 총 중량이 1돈(3.75g) 이하일 경우, 교환
                금(중량)에서 택배비가 차감됩니다.
              </Text> */}
            </>
          ) : (
            <>
              <Text className={classes.desc} style={{ marginTop: "5px" }}>
                <Text font={fonts.notoSansKRMedium}>
                  ㆍ매장 방문 시 보증서가 있는 경우 제품과 함께 가져 오셔야
                  합니다.
                </Text>
              </Text>
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
}

const ModifyReservation = ({ id }) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state || {};

  const handleClickModify = () => {
    if (!locationState?.shop?.id) {
      dispatch(messageError("방문 매장을 선택해주세요."));
      return;
    } else if (!locationState.reservation?.date) {
      dispatch(messageError("방문 일자를 선택해주세요."));
      return;
    }
    dispatch(loadingStart);
    APIS.putAppraisalRequestsInfo(id, {
      shopId: locationState.shop.id,
      visitDate: formatTime(locationState.reservation.date),
    })
      .then(({ data: { success, message } }) => {
        if (success) {
          history.replace(location.pathname, {
            ...locationState,
            isModify: false,
            shop: {},
            reservation: {},
          });
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
    // APIS.putModifyGoodsTrans(transId, {
    //   shopId: locationState.shop.id,
    //   visitDate: formatTime(locationState.reservation.date),
    // })
    //   .then(({ data: { success, message } }) => {
    //     if (success) {
    //       history.replace(location.pathname, {
    //         ...locationState,
    //         isModify: false,
    //       });
    //       callback();
    //     } else {
    //       dispatch(actionError(message));
    //     }
    //   })
    //   .finally(() => dispatch(loadingEnd));
  };

  return (
    <Flex style={{ position: "relative", padding: "0 13px" }}>
      <ButtonMini
        onClick={() => {
          history.replace(history.location.pathname, {
            ...locationState,
            isModify: false,
            shop: {},
            reservation: {},
          });
        }}
        label="취소"
        className={classes.modifyButton}
      />
      <VisitReservation
        description={`감정을 위해\n방문하실 매장을 선택해 주세요.`}
      />
      <Flex style={{ height: 100 }} />
      <Button
        onClick={handleClickModify}
        label="수정하기"
        className="fixed-button"
      />
    </Flex>
  );
};

const useStyle = makeStyles({
  bottomHeader: {
    margin: "25px 13px 30px 13px",
  },
  desc: {
    color: "#666",
    fontSize: "13px",
    margin: "20px 30px 100px 30px",
    "& span": { color: colors.textRed },
  },
  message: {
    color: "#333",
    fontSize: "14px",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    margin: "30px 30px 0px 30px",
  },
  label3: {
    marginTop: 26,
    margin: "0px 13px",
    fontSize: 14,
    color: "#333",
  },
  root: {
    flexGrow: 1,
    backgroundColor: colors.white,
  },
  close: {
    alignSelf: "flex-start",
    padding: "16px 16px",
    "& img": { width: "16px", height: "16px", objectFit: "contain" },
  },
  title: {
    fontSize: 16,
    color: "#333",
    lineHeight: 1.3,
    margin: "28px 0px 28px 13px",
  },
  card: {
    margin: "0px 13px 20px 13px",
    padding: "16px 20px",
  },
  label1: { fontSize: 18, color: "#333" },
  label2: {
    color: "#666",
    fontSize: 12,
  },
  button: {
    padding: "9px 16px",
    fontSize: 14,
    color: "#fff",
    lineHeight: 1,
    fontFamily: fonts.notoSansKRMedium,
    backgroundColor: colors.text,
    borderRadius: "20px",
    wordBreak: "keep-all",
  },
  deliveryDesc: {
    justifyContent: "space-between",
    marginRight: 25,
    flex: 1,
    alignItems: "flex-end",
  },
  deliveryLabel1: {
    fontSize: "12px",
    color: "#666",
  },
  deliveryLabel2: {
    fontSize: "12px",
    color: "#333",
  },
  button2: {
    padding: "9px 16px",
    fontSize: 14,
    color: colors.primary,
    lineHeight: 1,
    fontFamily: fonts.notoSansKRMedium,
    border: "1px solid " + colors.primary,
    borderRadius: "20px",
  },
  label5: {
    fontSize: 14,
    color: "#666",
    "& span": { fontSize: 16, color: "#333" },
  },
  simpleTable: {
    alignSelf: "stretch",
    margin: "10px 30px 0px 30px",
    "& span": {
      flex: 5,
      "&:first-child": {
        flex: 2,
        color: "#666",
      },
      fontSize: "14px",
      color: "#333",
    },
  },
  modifyButton: {
    padding: 3,
    width: 70,
    position: "absolute",
    top: 20,
    right: 13,
  },
});
