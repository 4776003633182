import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import * as APIS from "../../libs/apis";

export default function TimeRangeSelector({ value, onChange, className }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [timeOption, setTimeOption] = useState([]);
  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    let openTime = await APIS.getEnvConfig("SHOP_OPEN_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    let closeTime = await APIS.getEnvConfig("SHOP_CLOSE_TIME").then(
      ({ data: { data } }) => {
        return data.value;
      }
    );

    const timeArray = [];
    while (openTime < closeTime) {
      if (!openTime || !closeTime) {
        break;
      }
      let open = +openTime;
      let close = open + 200;
      if (close > +closeTime) {
        close = +closeTime;
      }

      timeArray.push({ open, close });
      openTime = close;
    }

    const convertedArray = timeArray.map(({ open, close }) => {
      let openStr = open < 1000 ? "0" + open : "" + open;
      openStr = `${openStr.substring(0, 2)}:${openStr.substring(2)}`;

      let closeStr = close < 1000 ? "0" + close : "" + close;
      closeStr = `${closeStr.substring(0, 2)}:${closeStr.substring(2)}`;

      return `${openStr}~${closeStr}`;
    });
    setTimeOption(convertedArray);

    if (!value) {
      onChange(convertedArray[0]);
    }
  };

  return (
    <select
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={classes.item + " " + className}
    >
      {timeOption.map((time, i) => (
        <option key={i.toString()} value={time}>
          {time}
        </option>
      ))}
    </select>
  );
}

const useStyle = makeStyles({
  item: {
    display: "flex",
    alignSelf: "stretch",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    borderBottom: "1px solid black",
    padding: "10px 6px 10px 10px",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  input: {
    border: "none",
    color: colors.text,
    width: "130px",
    fontFamily: fonts.notoSansKRLight,
    boxSizing: "border-box",
  },
});
