import React from "react";
import ic_notice_danger from "../../assets/images/ic_notice_danger.png";
import ic_no from "../../assets/images/ic_no.png";
import { useSelector } from "react-redux";
import routes from "../../libs/routes";

const NoticePopup = ({ type, message = "", id, navigate }) => {
  const auth = useSelector((state) => state.auth);
  const isSocialUser = auth?.isSigned && auth?.role?.LEVEL === 1;

  if (isSocialUser) {
    type = "SELF_VERIFICATION";
    message = "본인 인증을 진행해 주세요.";
  }

  let buttonText = "";
  let handleClickButton = () => {};

  switch (type) {
    case "SELF_VERIFICATION":
      buttonText = "본인인증 진행";
      handleClickButton = () =>
        navigate(routes.selfVerification, {
          purpose: "SIGNUP",
        });
      break;
    case "APPRAISAL":
      buttonText = "감정평가";
      handleClickButton = () =>
        navigate(routes.mypage, {
          tab: "HISTORY",
          division: "ING",
          expend: ["APPRAISAL"],
        });
      break;
    case "GOODS":
      buttonText = "실물인출";
      handleClickButton = () => navigate(routes.myPhysicalWithDrawal);
      break;

    case "SAFE_TRADE_BUY":
      buttonText = "안심직거래";
      handleClickButton = () => {
        navigate(routes.mySafeTradeList);
        navigate(routes.mySafeTradeBuy, { id });
      };
      break;
    case "SAFE_TRADE_SELL":
      buttonText = "안심직거래";
      handleClickButton = () => {
        navigate(routes.mySafeTradeList, { tab: "sell" });
        navigate(routes.mySafeTradeSell, { id });
      };
      break;
    case "STORAGE_FEE":
      buttonText = "보관료 납부";
      handleClickButton = () => {
        navigate(routes.storageFee);
      };
      break;
  }
  return (
    <div>
      {!!type && (
        <div
          className="main_pop"
          style={{ height: "auto", padding: "8px 12px 8px 12px" }}>
          <div className="pop_noti">
            <p>
              <img src={ic_notice_danger} alt="" />
              <span
                style={{
                  flex: 1,
                  lineHeight: "normal",
                  wordBreak: "keep-all",
                }}>
                {message.split("").map((s, i) => (s === "\n" ? <br key={i} /> : s))}
              </span>
              <button onClick={handleClickButton} style={{ position: "unset" }}>
                {buttonText}
              </button>
            </p>
            {/* <img src={ic_no} alt="x" className="btn_close" /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default NoticePopup;
