import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import { numberWithCommas, formatTime } from "../../libs/utils";

export default function DateRange({ className, start, end, onChange }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Flex row className={`${classes.root} ${className}`}>
      <Flex row className={classes.item}>
        {
          <input
            className={classes.input}
            value={start}
            type="date"
            onChange={(e) => onChange({ start: e.target.value, end })}
            max={formatTime()}
          />
          //            <img alt="ca" src={images.calendar} className={classes.icon} />
        }
      </Flex>
      ~
      <Flex row className={classes.item}>
        {
          <input
            className={classes.input}
            value={end}
            type="date"
            onChange={(e) => onChange({ start, end: e.target.value })}
            min={start}
          />
          //            <img alt="ca" src={images.calendar} className={classes.icon} />
        }
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    justifyContent: "center",
    boxSizing: "border-box",
    alignItems: "center",
  },
  item: {
    flex: 1,
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "10px 6px 10px 10px",
    "&:first-child": {
      marginRight: "15px",
    },
    "&:last-child": {
      marginLeft: "15px",
    },
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  input: {
    border: "none",
    // borderWidth: 0,
    backgroundColor: "transparent",
    color: colors.text,
    width: "100%",
    fontFamily: fonts.notoSansKRLight,
    boxSizing: "border-box",
  },
});
