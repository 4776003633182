import styled from "styled-components";
import { ButtonBase } from "@material-ui/core";

import Flex from "../../../components/flex/Flex";

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666666;
  font-size: 13px;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
`;
const Tab = styled(ButtonBase)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabTitle = styled.div`
  display: flex;
  padding: 5% 0;
  font-size: 17px;
  color: ${({ isActive }) => (isActive ? "#000" : "#D9D9D9")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
`;

const TitleBottomBorder = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
  border-bottom: ${({ isActive }) => (isActive ? "4px solid #000" : 0)};
`;

const ModalWrap = styled(Flex)`
  display: flex;
  flex: 1;
  /* padding-top: 100%; */
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const WeightContent = styled.div`
  padding: 30px 16px 20px;
`;

const WeightTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: bold;
`;

const WeightSubTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  font-size: 12px;
  color: #ff6e0e;
`;

const WeightSummary = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 32px;
`;

const ModalContentWrap = styled(Flex)`
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 30px 0;
  width: 80%;
  height: auto;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
  text-align: center;
  padding: 0 20px;
`;

const Buttons = styled(ButtonBase)`
  padding: 10px 0;
  width: 100%;
  color: ${({ isConfirm }) => (isConfirm ? "#fff" : "#000")};
  border-radius: 12px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${({ isConfirm }) => (isConfirm ? "#FE6E0C" : "#e9ecf5")};
  line-height: 24px;
`;

const Title = styled(FlexRow)`
  font-weight: bold;
  color: #222;
  font-size: 1.1rem;
  font-family: Spoqa Han Sans Neo;
`;

const Description = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  color: #444;
  word-break: keep-all;
`;

const BorderLine = styled.div`
  border: 1px solid #eaeaea;
`;

const TextBorder = styled.span`
  text-decoration: underline;
  font-weight: 600;
`;

const TextWithNumber = styled.div`
  flex: 1;
  word-break: keep-all;
`;

const Indent = styled.div`
  width: 22px;
  margin-top: 2px;
`;

const FeeTotal = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffeee3;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 16px 16px;
  border: 1px solid #ffe6d5;
`;

const FeeTotalTitle = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: #333;
`;

const FeeTotalDate = styled.span`
  font-size: 12px;
  color: #666;
`;

export {
  FlexRow,
  Tabs,
  Tab,
  TabTitle,
  TitleBottomBorder,
  ModalWrap,
  WeightContent,
  WeightTitle,
  WeightSubTitle,
  WeightSummary,
  ModalContentWrap,
  Message,
  Buttons,
  Title,
  Description,
  BorderLine,
  TextBorder,
  Indent,
  TextWithNumber,
  FeeTotal,
  FeeTotalTitle,
  FeeTotalDate,
};
