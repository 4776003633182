import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import InputShadow from "../../components/input-shadow/InputShadow";
import Text from "../../components/text/Text";
import TopbarClose from "../../components/topbar-close/TopbarClose";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr } from "../../services/utils";

export default function VerifyBank({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useSelector((s) => s.user);
  const state = history.location.state;
  const [banks, setBanks] = useState([]);

  const number = state?.bankNumber;
  const setNumber = (v) => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      bankNumber: v,
    });
  };

  const accountHolder = state?.accountHolder;
  const setAccountHolder = (v) => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      accountHolder: v,
    });
  };

  useEffect(() => {
    if (id) {
      history.replace(routes.main, {});
    }
  }, [history.location.key, id]);

  useEffect(() => {
    axios
      .get(consts.apiBaseUrl + "/api/bank")
      .then((res) => {
        setBanks([...res.data]);
      })
      .catch((e) => {
        dispatch(messageError(e));
      });
  }, []);

  const checkValue = () => {
    if (!history.location.state?.bank) {
      dispatch(messageShow("은행을 선택해 주세요."));
    } else if (!state?.accountHolder || !state?.bankNumber) {
      dispatch(messageShow("예금주와 계좌번호를 입력해 주세요."));
    } else {
      axios
        .post(
          consts.apiBaseUrl +
            "/api/checkPay/checkAccount" +
            objToQueryStr({
              account_no: history.location.state.bankNumber,
              back_code: history.location.state?.bank.bankCode,
              member_name: history.location.state.accountHolder,
            }),
          {}
        )
        .then((res) => {
          const data = res.data;
          if (data.return_code === "0000") {
            dispatch(
              toastShow(
                "회원님의 계좌로 1원을 보냈습니다.\n입금자명의 " +
                  data.verify_len +
                  "자리 코드를 입력해 주세요."
              )
            );
            history.push("/verify-bank-code", {
              ...history.location.state,
              bankCode: {
                verify_tr_dt: data.verify_tr_dt,
                verify_tr_no: data.verify_tr_no,
                len: data.verify_len,
              },
              // bankVerify: true,
            });
          } else {
            setErrorMessage(
              data.return_msg ||
                "계좌정보가 일치하지 않습니다. 다시 확인해 주세요."
            );
            throw (
              data.return_msg ||
              "계좌정보가 일치하지 않습니다. 다시 확인해 주세요."
            );
          }
        })
        .catch((e) => {
          dispatch(messageError(e));
        });
    }
  };

  useEffect(() => {
    if (errorMessage && number) {
      setErrorMessage("");
    }
  }, [errorMessage, number]);

  return (
    <BasicLayout backgroundGray>
      <TopbarClose />
      <Text className={classes.label} font={fonts.notoSansKRMedium}>
        계좌인증
      </Text>
      <Text className={classes.label2}>본인의 계좌만 등록 가능합니다.</Text>

      <div className={classes.bank + " shadow"}>
        <select
          disabled={history.location.state?.bankVerify}
          value={history.location.state?.bank?.bankCode}
          onChange={(e) => {
            history.replace(history.location.pathname, {
              ...history.location.state,
              bank: banks.filter((x) => x.bankCode === e.target.value)[0],
            });
          }}
        >
          <option selected value="" disabled label="은행 선택" />
          {banks.map((x, i) => {
            return (
              <option
                value={x.bankCode}
                label={x.bankName}
                key={x.bankCode}
              ></option>
            );
          })}
        </select>
        <img alt="bottom" src={images.down} className={classes.down} />
      </div>
      <InputShadow
        value={accountHolder}
        disabled={history.location.state?.bankVerify}
        onChange={setAccountHolder}
        placeholder="예금주 입력"
        className={classes.input}
      />
      <InputShadow
        value={number}
        disabled={history.location.state?.bankVerify}
        number
        onChange={setNumber}
        placeholder="계좌번호 입력"
        className={classes.input}
      />
      <Button
        onClick={() => {
          if (history.location.state?.bankVerify) {
            history.push(routes.bankPassword, {
              register: true,
              ...history.location.state,
            });
          } else {
            checkValue();
          }
        }}
        className={classes.button}
        label={
          history.location.state?.bankVerify ? "결제 비밀번호 등록" : "확인"
        }
      />
      {errorMessage && <Text className={classes.error}>{errorMessage}</Text>}
      {history.location.state?.bankVerify && (
        <>
          <Flex style={{ flex: 1 }} />
          <Text className={classes.success}>계좌 인증 되었습니다.</Text>
        </>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: 24,
    margin: "70px 30px 0px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    margin: "24px 30px 0px 30px",
  },
  label2: {
    color: colors.gray,
    fontSize: 15,
    margin: "0px 30px",
  },
  input: { margin: "50px 30px 0px 30px", marginTop: 30 },
  inputmenu: {
    margin: "40px 30px 0px 30px",
  },
  error: {
    color: colors.textRed,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
  },
  success: {
    color: colors.text,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
    marginBottom: "30px",
  },
  name: {
    margin: "10px 30px 0px 30px",
    backgroundColor: colors.dddddd,
    color: colors.text,
    borderRadius: "3px",
    padding: "13px 16px 13px 30px",
    fontSize: "15px",
  },
  bank: {
    "& select": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      width: "100%",
      padding: "13px 0px 13px 30px",
      "font-size": "15px",
      border: "none",
      minHeight: 56,
      color: "#333333",
    },
    display: "flex",
    "background-color": "#ffffff",
    padding: "0px 0px 0px 0px",
    alignItems: "center",
    margin: "40px 30px 0px 30px",
    "border-radius": "3px",
    "justify-content": "space-between",
    "font-family": "NotoSansKR-Regular",
    position: "relative",
    "line-height": 2,
  },
  down: {
    position: "absolute",
    right: 16,
    width: "16px",
    height: "10px",
  },
});
