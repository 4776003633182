export const toastActionType = {
  show: "toast/show",
  hide: "toast/hide",
};

export const toastShow = (message, tm) => (dispatch) => {
  dispatch({ type: toastActionType.show, message, second: tm || 1 });
};

export const toastHide = () => (dispatch) => {
  dispatch({ type: toastActionType.hide });
};
