import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr, phoneFormat } from "../../services/utils";
import RNHeader from "../../components/common/RNHeader";

export default function SignUp({}) {
  const classes = useStyle();
  const history = useHistory();
  const { name } = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  const handleClickStart = () => {
    history.replace(routes.main);
  };

  const userName = name || history.location.state?.loginId;

  return (
    <BasicLayout backgroundGray>
      <RNHeader visible={false} goBack="CUSTOM" onGoBack={handleClickStart} />
      {/* <ImageButton
        onClick={history.goBack}
        className={classes.close}
        align="right"
        src={images.close}
      /> */}

      <Text font={fonts.notoSansKRLight} className={classes.label}>
        <Text font={fonts.notoSansKRBold} className={classes.message}>
          {userName}
        </Text>
        님<br />
        금방금방
        <br /> 회원이 되신 걸 환영합니다.
      </Text>

      {/* <Flex className={classes.content + " shadow"}>
        <Flex row className={classes.email}>
          <div
            className={classes.logo}
            style={{
              backgroundColor: (() => {
                switch (data.joinType) {
                  case "NAVER":
                    return "#21c603";
                  case "GOOGLE":
                    return "#fff";
                  case "KAKAO":
                    return "#f7e600";
                  default:
                    return "#000";
                }
              })(),
            }}
          >
            <img
              alt="logo"
              src={(() => {
                switch (data.joinType) {
                  case "NAVER":
                    return images.naver;
                  case "GOOGLE":
                    return images.google;
                  case "KAKAO":
                    return images.kakaotalk;
                  case "APPLE":
                    return images.apple;
                }
              })()}
            />
          </div>

          <Text className={classes.emailLABEL}>pjh39858@gmail.com</Text>
        </Flex>
        <Text font={fonts.notoSansKRMedium} className={classes.desc}>
          {data.name}, {phoneFormat(data.phone)}
          <br />
          {data.bankName} {data.accountNumber} {data.accountHolder}
        </Text>
      </Flex> */}
      <Flex style={{ height: 200 }} />

      <Button label="시작하기" defaultMargin onClick={handleClickStart} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  desc: {
    color: colors.text,
    marginTop: "20px",
    fontSize: 14,
  },
  close: {
    padding: "20px 13px",
  },
  label: {
    margin: "50px 30px 20px 30px",
    fontSize: "24px",
    color: colors.text,
    lineHeight: 1.5,
  },
  message: {
    color: colors.text,
    fontSize: "30px",
    wordBreak: "break-word",
  },
  content: {
    backgroundColor: colors.white,
    margin: "0px 30px 20px 30px",
    padding: "20px",
  },
  email: {
    alignItems: "center",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "20px",
    height: "20px",
    "& img": {
      objectFit: "contain",
      width: "60%",
      height: "60%",
    },
  },
  emailLABEL: {
    color: colors.text,
    fontSize: 14,
    marginLeft: 10,
    lineHeight: 1,
  },
});
