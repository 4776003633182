import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function DeliveryState({
  method,
  status,
  appraisalRequestStatus,
}) {
  const classes = useStyle();

  const gi = (m) => {
    let arr = appraisalRequestStatus.map((x, i) => [x.status, i + 1]);

    if (arr && arr.length) {
      let d = arr.filter((x) => x[0] === m)[0];

      if (d) {
        return d[1];
      }
      return 0;
    } else {
      return 0;
    }
  };
  return (
    <Flex row className={classes.root}>
      <Flex className={classes.navigator}>
        <Flex className={classes.divider} />
        {method === "현장감정" || method === "내방" ? (
          <Flex className={classes.navigatorBalls}>
            <div
              style={{
                backgroundColor:
                  status === "접수"
                    ? colors.primary
                    : gi("접수")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />

            <div
              style={{
                backgroundColor:
                  status === "감정시작"
                    ? colors.primary
                    : gi("감정시작")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            <div
              style={{
                backgroundColor:
                  status === "감정완료"
                    ? colors.primary
                    : gi("감정완료")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            <div
              style={{
                backgroundColor:
                  status === "교환완료" ? colors.primary : "#ddd",
              }}
              className={classes.ball}
            />
          </Flex>
        ) : (
          <Flex className={classes.navigatorBalls}>
            <div
              style={{
                backgroundColor:
                  status === "접수"
                    ? colors.primary
                    : gi("접수")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            <div
              style={{
                backgroundColor:
                  status === "배송중"
                    ? colors.primary
                    : gi("배송중")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            {/* <div
              style={{
                backgroundColor:
                  status === "접수승인"
                    ? colors.primary
                    : gi("접수승인")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            /> */}
            <div
              style={{
                backgroundColor:
                  status === "감정시작"
                    ? colors.primary
                    : gi("감정시작")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            <div
              style={{
                backgroundColor:
                  status === "감정완료"
                    ? colors.primary
                    : gi("감정완료")
                    ? "black"
                    : "#ddd",
              }}
              className={classes.ball}
            />
            <div
              style={{
                backgroundColor:
                  status === "교환완료" ? colors.primary : "#ddd",
              }}
              className={classes.ball}
            />
          </Flex>
        )}
      </Flex>

      {(() => {
        switch (method) {
          case "현장감정":
          case "내방":
            return (
              <Flex className={classes.labels}>
                <Text
                  font={
                    status === "접수"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "접수" && { color: "#333" }) }}
                  className={classes.label}>
                  {method === "현장감정" ? "현장감정 접수" : "방문 예약"}
                </Text>

                <Text
                  font={
                    status === "감정시작"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "감정시작" && { color: "#333" }) }}
                  className={classes.label}>
                  감정중
                </Text>

                <Text
                  font={
                    status === "감정완료"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{
                    ...(status === "감정완료" && { color: "#333" }),
                  }}
                  className={classes.label}>
                  감정완료
                </Text>

                <Text
                  font={
                    status === "교환완료"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "교환완료" && { color: "#333" }) }}
                  className={classes.label}>
                  교환완료
                </Text>
              </Flex>
            );
          default:
            return (
              <Flex className={classes.labels}>
                <Text
                  font={
                    status === "접수"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{
                    ...(status === "접수" && { color: "#333" }),
                  }}
                  className={classes.label}>
                  감정 접수
                </Text>
                <Text
                  font={
                    status === "배송중"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "배송중" && { color: "#333" }) }}
                  className={classes.label}>
                  배송중
                </Text>
                <Text
                  font={
                    status === "감정시작"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "감정시작" && { color: "#333" }) }}
                  className={classes.label}>
                  감정중
                </Text>

                <Text
                  font={
                    status === "감정완료"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{
                    ...(status === "감정완료" && { color: "#333" }),
                  }}
                  className={classes.label}>
                  감정완료
                </Text>

                <Text
                  font={
                    status === "교환완료"
                      ? fonts.notoSansKRMedium
                      : fonts.notoSansKRRegular
                  }
                  style={{ ...(status === "교환완료" && { color: "#333" }) }}
                  className={classes.label}>
                  교환완료
                </Text>
              </Flex>
            );
        }
      })()}
    </Flex>
  );
}

const useStyle = makeStyles({
  navigator: {
    width: "30px",
    position: "relative",
  },
  divider: {
    flex: 1,
    alignSelf: "center",
    width: "15%",
    backgroundColor: "#ddd",
  },
  root: { flex: 1, minHeight: "170px", marginLeft: "10px" },
  navigatorBalls: {
    zIndex: 2,
    left: 0,
    right: 0,
    alignItems: "center",
    top: 0,
    bottom: 0,
    position: "absolute",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ball: {
    width: "14px",
    height: "14px",
    backgroundColor: "#ddd",
    borderRadius: "100px",
    border: "2px solid #fff",
  },
  labels: {
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  label: { fontSize: "14px", color: "#999", textAlign: "left" },
});
