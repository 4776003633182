import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ReviewItem from "../../components/review-item/ReviewItem";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";

export default function ProductReview({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex>
      <Text className={classes.label}>
        <Text font={fonts.notoSansKRBold}>1</Text> 후기
      </Text>

      {[{}, {}, {}].map((x, i) => {
        return <ReviewItem {...x} key={i.toString()} />;
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
});
