import React from "react";
import BasicLayout from "../../layouts/basic/BasicLayout";

export default function MyReview({}) {
  return (
    <BasicLayout
      stackTopbar={{
        title: "상품 리뷰",
        border: true,
      }}>
      <div className="mypage">
        <div className="vreview-myreview-widget"></div>
      </div>
    </BasicLayout>
  );
}
