import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import BasicLayout from "../../layouts/basic/BasicLayout";
import fonts from "../../libs/fonts";
import { actionShow } from "../../redux/action/ActionActions";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import { formatTime, postMessage } from "../../libs/utils";
import noticeIcon from "../../assets/images/ic_notice_sub.png";

const NotificationItem = styled(Flex)`
  padding: 18px 25px;
  border-bottom: 1px solid #f7f8f9;
  background: ${(props) => props.color};
  .title {
    img {
      width: 19px;
      margin-right: 2px;
    }
    flex: 1;
    color: #001b8e;
    font-family: ${fonts.notoSansKRMedium};
    font-size: 15px;
  }

  .body {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 13px;
  }

  .at {
    color: #9e9fa0;
    font-size: 13px;
  }
`;

export default function Notification({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { count = 2 } = history.location.state || {};

  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    APIS.getNotifications().then(({ data: { data = [] } }) => {
      setNotifications(data);
    });
    postMessage({ command: "CLEAR_BADGE_COUNT" });
  }, []);

  return (
    <BasicLayout stackTopbar={{ title: "알림", border: true }}>
      {notifications.map(({ title, body, pushedAt }, i) => {
        return (
          <NotificationItem key={i} color={i < count ? "#fafcfd" : ""}>
            <Flex row>
              <Flex className="title" row>
                <img src={noticeIcon} />
                {title}
              </Flex>
              <Flex className="at spoqa">
                {formatTime(pushedAt, "YYYY.MM.DD")}
              </Flex>
            </Flex>
            <Flex className="body">{body}</Flex>
          </NotificationItem>
        );
      })}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  delete: {
    borderBottom: "1px solid #333",
    fontSize: "14px",
    padding: "6px 0px",
    color: "#333",
    fontFamily: fonts.notoSansKRMedium,
    marginRight: "20px",
  },
});
