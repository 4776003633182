import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DirectTransactionItem from "../../components/direct-transaction-item/DirectTransactionItem";
import StackTopbar from "../../layouts/basic/StackTopbar";
import MainLayout from "../../layouts/main/MainLayout";
import Text from "../../components/text/Text";
import Flex from "../../components/flex/Flex";
import Tables from "../../components/tables/Tables";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { formatTime, numberWithCommas, numFormat } from "../../libs/utils";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import colors from "../../libs/colors";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import ic_danger from "../../assets/images/ic_danger.png";
import routes from "../../libs/routes";

export default function MySafeTradeList({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};

  const [tab, setTab] = useState(state.tab || "buy");

  const handleClickItem = (id) => {
    history.replace(routes.mySafeTradeList, { tab });
    history.push(routes.safeTradeItem, { id });
  };

  const handleClickDetail = (id) => {
    history.replace(routes.mySafeTradeList, { tab });

    if (tab === "buy") {
      history.push(routes.mySafeTradeBuy, { id });
    } else {
      history.push(routes.mySafeTradeSell, { id });
    }
  };

  return (
    <MainLayout white topbar={<StackTopbar border title="나의 안심직거래" />}>
      <div className="mypage_wrap">
        <div className="my_menu">
          <ul>
            <li
              className={tab === "buy" ? "on" : ""}
              onClick={() => setTab("buy")}
            >
              구매
            </li>
            <li
              className={tab === "sell" ? "on" : ""}
              onClick={() => setTab("sell")}
            >
              판매
            </li>
          </ul>
        </div>
        {tab === "buy" && (
          <BuyList
            onClickItem={handleClickItem}
            onClickDetail={handleClickDetail}
          />
        )}
        {tab === "sell" && (
          <SellList
            onClickItem={handleClickItem}
            onClickDetail={handleClickDetail}
          />
        )}
      </div>
    </MainLayout>
  );
}

const BuyList = ({ onClickItem, onClickDetail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orders, setOrders] = useState();

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getMySafeTradeBuyList()
      .then(({ data: { data } }) => setOrders(data?.content))
      .finally(() => dispatch(loadingEnd));
  }, []);

  if (!orders) return <></>;

  return (
    <>
      {orders.length ? (
        <div className="my_buy">
          {orders.map((order, i) => {
            return (
              <div
                className="my_buy_box"
                key={i.toString()}
                onClick={() => onClickDetail(order.order_book_id)}
              >
                <div className="buy_state">{order.status_message}</div>
                <div className="my_list">
                  <div className="list_box">
                    <div className="list_box_wrap">
                      <div className="list_box_l">
                        <img
                          src={`${consts.s3BaseUrl}/${order.image}`}
                          alt=""
                          style={{ height: "100%", objectFit: "cover" }}
                        />
                      </div>
                      <div className="list_box_r">
                        <h3 className="list_box_brand">{order.brand}</h3>
                        <p className="list_box_name">{order.name}</p>
                        <span className="list_box_price">
                          <span className="spoqa">
                            {numberWithCommas(order.price)}
                          </span>
                          원
                        </span>
                        <p className="list_box_etc">
                          주문일{" "}
                          <span className="spoqa">
                            {formatTime(order.created_at, "YYYY.MM.DD")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="list_box_btn">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickItem(order.item_id);
                        }}
                      >
                        상품 보기
                      </button>
                      <button>주문 상세</button>
                    </div>
                  </div>
                  <div className="bar"></div>
                </div>
              </div>
            );
          })}

          {/* <div className="my_buy_box">
            <div className="buy_state view">미리보기 신청 완료</div>
            <div className="my_list">
              <div className="list_box">
                <div className="list_box_wrap">
                  <div className="list_box_l">
                    <img src="/images/ex1.png" alt="" />
                  </div>
                  <div className="list_box_r">
                    <h3 className="list_box_brand">Rolex</h3>
                    <p className="list_box_name">Datejust</p>
                    <span className="list_box_price">
                      <span className="spoqa">5,892,897</span>원
                    </span>
                    <p className="list_box_etc">
                      결제일 <span className="spoqa">2022.01.01</span>
                    </p>
                  </div>
                </div>
                <div className="list_box_btn">
                  <button>상품 보기</button>
                  <button>주문 상세</button>
                </div>
              </div>
              <div className="bar"></div>
            </div>
          </div> */}
        </div>
      ) : (
        <div className="my_buy none">
          <img src={ic_danger} alt="!" />
          <br />
          <p>주문 내역이 없습니다.</p>
        </div>
      )}
    </>
  );
};

const SellList = ({ onClickItem, onClickDetail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orders, setOrders] = useState();

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getMySafeTradeSellList()
      .then(({ data: { data } }) => setOrders(data?.content))
      .finally(() => dispatch(loadingEnd));
  }, []);

  if (!orders) return <></>;

  return (
    <>
      {orders.length ? (
        <div className="my_sell">
          {orders.map((order, i) => {
            // 판매 신청 단계
            const beforeStatus = [
              "INBOUND_WAIT",
              "INBOUND_COMPLETE",
              "INBOUND_CANCEL",
              "VERIFY_WAIT",
              "RETURN",
              "VERIFY_COMPLETE",
              "SELL_CONFIRM_WAIT",
              "SELL_WAIT",
            ];

            // 판매 완료
            const afterStatus = [
              "RETURN_COMPLETE",
              "TRANSFER_COMPLETE",
              "COMPLETE",
            ];

            const statusClassName = beforeStatus.includes(order.status)
              ? "before"
              : afterStatus.includes(order.status)
              ? "after"
              : "ing";
            return (
              <div
                className="my_sell_box"
                key={i.toString()}
                onClick={() => onClickDetail(order.order_id)}
              >
                <div className={`sell_state ${statusClassName}`}>
                  {order.status_message}
                </div>
                <div className="my_list">
                  <div className="list_box">
                    <div className="list_box_wrap">
                      <div className="list_box_l">
                        <img
                          src={`${consts.s3BaseUrl}/${order.image}`}
                          alt=""
                          style={{ height: "100%", objectFit: "cover" }}
                        />
                      </div>
                      <div className="list_box_r">
                        <h3 className="list_box_brand">{order.brand}</h3>
                        <p className="list_box_name">{order.name}</p>
                        <span className="list_box_price">
                          <span className="spoqa">
                            {numberWithCommas(order.price)}
                          </span>
                          원
                        </span>
                        <p className="list_box_etc">
                          신청일{" "}
                          <span className="spoqa">
                            {formatTime(order.created_at, "YYYY.MM.DD")}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="list_box_btn">
                      <button
                        className={order.status === "SELL" ? "" : "off"}
                        onClick={(e) => {
                          e.stopPropagation();
                          order.status === "SELL" && onClickItem(order.item_id);
                        }}
                      >
                        상품 보기
                      </button>
                      <button>주문 상세</button>
                    </div>
                  </div>
                  <div className="bar"></div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="my_sell none">
          <img src={ic_danger} alt="!" />
          <br />
          <p>판매 신청 내역이 없습니다.</p>
        </div>
      )}
    </>
  );
};
const useStyle = makeStyles({});
