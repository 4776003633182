import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import CloseLayout from "../../layouts/close/CloseLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { getShopDetail } from "../../libs/apis";
import consts from "../../libs/consts";
import images from "../../libs/images";

export const useGetShopDetail = () => {
  const params = useParams();

  const [detail, setDetail] = useState({});

  const { data, isLoading } = useQuery(
    ["shopDetail", params.id],
    () => getShopDetail(params.id),
    {
      select: (data) => data.data,
      onSuccess: ({ success, data }) => {
        // console.log(`Success!`, data);
      },
      onError: (error) => {
        console.log(`error!`, error);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setDetail(data.data);
    }
  }, [data]);

  return { detail, isLoading };
};

export default function ShopDetail({}) {
  const [width, setWidth] = useState(0);
  const classes = useStyle();
  const { id } = useParams();

  const { detail, isLoading } = useGetShopDetail();

  useEffect(() => {
    let imageWidth = (window.innerWidth * 88) / 336;
    setWidth(imageWidth);
    // console.log(imageWidth);
  }, []);

  return (
    <BasicLayout
      stackTopbar={{
        title: "대리점 정보",
        border: true,
      }}>
      {detail ? (
        <div className={classes.wrap}>
          {/* <div className={classes.imgWrap}>
            <img
              style={{
                width: detail.imagePath ? `${width}px` : "88px",
              }}
              src={`${consts.s3BaseUrl}/${detail.imagePath}`}
              alt="shop_image"
            />
          </div>
          <Flex row className={classes.item}>
            <Text className={classes.cityLabel}>서울</Text>
          </Flex> */}
          <Flex row className={classes.item}>
            <Text className={classes.shopName}>{detail.shopName}</Text>
          </Flex>
          <Flex row className={classes.item}>
            <div className={classes.flexRow}>
              <div>
                <img src={images.call_gray} alt="call_image" />
              </div>
              <Text className={classes.label}>전화</Text>
            </div>
            <a href={`tel:${detail.phone}`}>
              <Text className={classes.value}>{detail.phone}</Text>
            </a>
          </Flex>
          <Flex row className={classes.item}>
            <div className={classes.flexRow}>
              <div>
                <img src={images.location_small} alt="location_image" />
              </div>
              <Text className={classes.label}>주소</Text>
            </div>
            <Text className={classes.value}>{detail.address}</Text>
          </Flex>
        </div>
      ) : (
        <Flex className={classes.flexCenter}>찾을 수 없는 매장 입니다.</Flex>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  flexCenter: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    marginRight: "30px",
  },
  wrap: {
    padding: "0 24px",
  },
  imgWrap: { margin: "36px 0" },
  cityLabel: {
    padding: "10px 8px",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    color: colors.primary,
    fontSize: "14px",
    fontWeight: 500,
  },
  shopName: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "34.75px",
    letterSpacing: "-0.03%",
    borderBottom: "2px solid #EAEAEA",
    padding: "16px 0",
    marginBottom: 16,
    flex: 1,
  },
  item: {
    margin: "4px 0px",
    display: "flex",
    alignItems: "center",
  },
  label: {
    color: "#666",
    fontSize: "13px",
    flex: 1,
    // padding: "0px 14px"
  },
  value: {
    color: "#333",
    fontSize: "13px",
    marginRight: "30px",
    flex: 3,
  },
  button: {
    borderRadius: "10px",
    backgroundColor: colors.primary,
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    lineHeight: 1,
    fontSize: "14px",
    padding: "5px 16px",
    marginLeft: "6px",
  },
});
