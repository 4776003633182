import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import routes from "../../libs/routes";
import Button from "../button/Button";
import NumberFormat from "react-number-format";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { ProductGrade } from "../vreviewWidget";

export default function PhysicalWithDrawalItem({
  rows,
  sPrice,
  settype,
  order,
  currentPrice,
  toLocation,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [orderTxt, setorderTxt] = useState("결제완료");
  var setPrice = 0;
  var rowData = [];

  if (sPrice === "get") {
    useEffect(() => {
      rowData = rows || {};
      const { status, shipmentStatus } = rows;

      switch (status) {
        case "APPROVAL":
          switch (shipmentStatus) {
            case "MAKE":
              setorderTxt("제작중");
              break;
            case "SHIPMENT":
              setorderTxt("출고중");
              break;
            case "DELIVERY":
              setorderTxt("출고완료");
              break;
            default:
              setorderTxt("결제완료");
          }

          break;

        case "TAKEOUT":
          setorderTxt("인출완료");
          break;

        case "CONFIRM":
          setorderTxt("인출확정");
          break;
      }

      // APIS.getGoodsDetail(rows.goodsId).then(
      //   ({ data: { success, data, message } }) => {
      //     if (success) {
      //       setproductData(data);
      //       setstype(data.goldOrSilver);
      //     }
      //   }
      // );
    }, [rows.imageId?.goods.goldOrSilver]);
    setPrice =
      currentPrice[rows.imageId?.goods.goldOrSilver.toLowerCase()] *
      rows.imageId?.goods.gram;

    rowData = rows;
    if (rowData.code !== "") {
      if (Object.keys(rows.imageId ? rows.imageId : {}).length > 0) {
        rowData.image = rowData.imageId.path;
      }
    }
  } else {
    rowData = rows;
    // setPrice = sPrice * rowData.gram;
    setPrice = rows.totalPrice;
  }

  return (
    <Flex key={rows.id} className={classes.root}>
      <ButtonBase
        onClick={() => {
          if (toLocation) {
            history.push(routes.orderDetail(rows.id, rows.no, rows.goodsId));
          } else {
            // 실물인출 상품 상세

            history.replace(history.location.pathname, {
              ...history.location.state,
              scrollInfo: { Y: window.scrollY },
            });

            history.push(`${routes.detailGateway}${rows.id}`);
          }
        }}
        className={classes.item}>
        <img
          src={
            rowData.image
              ? consts.s3BaseUrl + "/" + rowData.image
              : images.goldbar
          }
          className={classes.sample1}
        />
        <Flex className={classes.main}>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text className={classes.label1}>{rowData.name}</Text>

            {rows.status === "CANCEL" ? (
              <>
                {order && (
                  <Text
                    className={classes.orderState}
                    font={fonts.notoSansKRMedium}>
                    취소
                  </Text>
                )}
              </>
            ) : (
              <>
                {order && (
                  <Text
                    className={classes.orderState}
                    font={fonts.notoSansKRMedium}>
                    {orderTxt}
                  </Text>
                )}
              </>
            )}
          </Flex>
          {!order && (
            <Text className={classes.label2}>
              <Text font={fonts.notoSansKRMedium}>{rowData.gram}g</Text> ={" "}
              <NumberFormat
                value={setPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          )}
          <ProductGrade id={rows.id} />
        </Flex>
      </ButtonBase>
      {order && (
        <Flex row className={classes.buttons}>
          <Button
            label="상품보기"
            onClick={() => {
              if (rows?.imageId?.goods.visible === "HIDDEN") {
                dispatch(
                  actionError("해당 제품은 현재 인출가능한 상태가 아닙니다.")
                );
              } else {
                history.push(`${routes.detailGateway}${rows.id}`);
              }
            }}
          />
          <Button
            onClick={() => {
              history.push(routes.orderDetail(rows.id, rows.no, rows.goodsId));
            }}
            label="주문상세"
          />
        </Flex>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    border: "1px solid #eee",
    objectFit: "contain",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
  },
  item: {
    padding: "14px 0px",
    margin: "0px 14px",
    alignItems: "center",
    display: "flex",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    alignItems: "flex-start",
    flex: 1,
  },
  label1: { color: colors.gray, fontSize: 12 },
  label2: {
    color: colors.gray,
    fontSize: 12,
    "& span": {
      fontSize: "16px",
      color: "#000",
    },
  },
  buttons: {
    margin: "13px 13px 20px 13px",
    "& button": {
      flex: 1,
      padding: "6px",
      marginLeft: "5px",
      "& span": {
        fontSize: "14px",
      },
      "&:first-child": {
        backgroundColor: "#fff",
        border: "1px solid " + colors.primary,
        "& span": {
          color: colors.primary + " !important",
        },
        marginLeft: "0px",
        marginRight: "5px",
      },
    },
  },
  orderState: { color: colors.primary, fontSize: "12px" },
});
