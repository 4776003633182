import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";
import closeIcon from "../../assets/images/close_icon.webp";
import checkbox_on from "../../assets/images/checkbox_on.webp";
import checkbox_off from "../../assets/images/checkbox_off.webp";
import Button from "../../components/button/Button";
import { PHONE_CODE } from "../../libs/consts";
import colors from "../../libs/colors";
import DaumPostcode from "react-daum-postcode";
import { useDispatch } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import { messageShow } from "../../redux/message/MessageActions";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { useHistory } from "react-router";

const DELIVERY_COMMENTS = [
  "문 앞에 놓아주세요.",
  "택배함에 넣어주세요.",
  "경비실에 맡겨주세요.",
  "부재 시 연락주세요.",
  "직접 받고 부재 시 문 앞에 놓아주세요.",
];

const AddressModify = ({ onComplete }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { id } = locationState;

  const [addressName, setAddressName] = useState(""); // 배송지명
  const [receiver, setReceiver] = useState(""); // 수령인
  const [phone, setPhone] = useState(""); // 연락처1
  const [mobile, setMobile] = useState(""); // 연락처2
  const [postCode, setPostCode] = useState(""); // 우편번호
  const [address, setAddress] = useState(""); // 기본주소
  const [addressDetail, setAddressDetail] = useState(""); // 상세주소
  const [isDefault, setIsDefault] = useState(false); // 기본 주소 여부
  const [memo, setMemo] = useState(""); // 수령메모
  const [isFreeDoor, setIsFreeDoor] = useState(true); // 출입방법
  const [passwordDoor, setPasswordDoor] = useState(""); // 공동현관 비밀번호
  const [isAddressSearch, setIsAddressSearch] = useState(false);

  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!id) {
      history.goBack();
    }
    dispatch(loadingStart);
    APIS.getUserAddressDetail(id)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAddressName(data.addressName);
          setReceiver(data.receiver);
          setPhone(data.phone);
          setMobile(data.mobile);
          setPostCode(data.postCode);
          setAddress(data.address);
          setAddressDetail(data.addressDetail);
          setIsDefault(data.isDefault);
          setMemo(data.memo);
          if (data.passwordDoor) {
            setIsFreeDoor(false);
            setPasswordDoor(data.passwordDoor);
          }
        } else {
          dispatch(actionError(message));
          history.goBack();
        }
      })
      .catch(() => {
        history.goBack();
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  }, []);

  const handleCompleteSearch = (data) => {
    setPostCode(data.zonecode);
    setAddress(data.address);
    setAddressDetail("");
    setIsAddressSearch(false);
  };

  const handleClickSubmit = () => {
    if (!addressName) {
      dispatch(toastShow("배송지명을 입력해주세요."));
      return false;
    }

    if (!receiver) {
      dispatch(toastShow("수령인을 입력해주세요."));
      return false;
    }
    if (!phone) {
      dispatch(toastShow("연락처를 입력해주세요."));
      return false;
    }
    if (!postCode || !address || !addressDetail) {
      dispatch(toastShow("배송지를 입력해주세요."));
      return false;
    }
    if (!memo) {
      dispatch(toastShow("수령메모를 선택해주세요."));
      return false;
    }

    if (!isFreeDoor && !passwordDoor) {
      dispatch(toastShow("공동현관 비밀번호를 입력해주세요."));
      return false;
    }

    const param = {
      postCode,
      address,
      addressDetail,
      isDefault,
      addressName,
      receiver,
      phone,
      mobile,
      memo,
      passwordDoor: isFreeDoor ? "" : passwordDoor,
    };

    dispatch(loadingStart);
    APIS.modifyUserAddress(id, param)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            messageShow("배송지를 수정하였습니다.", () => {
              history.goBack();
            })
          );
        } else {
          dispatch(messageShow(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <BasicLayout stackTopbar={{ title: "배송지 수정", border: true }}>
      <Container>
        <FormHeader>배송지 수정</FormHeader>
        <FormWrap>
          {isAddressSearch ? (
            <DaumPostcode
              autoClose
              animation
              height={contentHeight}
              width="100%"
              onComplete={handleCompleteSearch}
            />
          ) : (
            <div
              ref={contentRef}
              onLoad={(e) => {
                setContentHeight(contentRef.current.offsetHeight);
              }}
            >
              <Row mt={0}>
                <Subject>배송지명</Subject>
                <FocusInput value={addressName} onChange={setAddressName} />
              </Row>
              <Row>
                <Subject>수령인</Subject>
                <FocusInput value={receiver} onChange={setReceiver} />
              </Row>
              <Row>
                <Subject row>
                  연락처1<span style={{ color: colors.primary }}>*</span>
                </Subject>
                <FocusInput
                  value={phone}
                  onChange={setPhone}
                  type="number"
                  align="left"
                />
              </Row>
              <Row>
                <Subject>연락처2</Subject>
                <FocusInput
                  value={mobile}
                  onChange={setMobile}
                  type="number"
                  align="left"
                />
              </Row>
              <Row style={{ alignItems: "stretch" }}>
                <Subject style={{ alignSelf: "center" }}>배송지</Subject>
                <FocusInput
                  type="text"
                  readOnly
                  value={postCode}
                  placeholder="우편번호"
                />
                <SearchButton onClick={() => setIsAddressSearch(true)}>
                  주소검색
                </SearchButton>
              </Row>
              <Row mt={8}>
                <Subject />
                <FocusInput
                  type="text"
                  readOnly
                  value={address}
                  placeholder="기본주소"
                />
              </Row>
              <Row mt={8}>
                <Subject />
                <FocusInput
                  type="text"
                  placeholder="상세주소"
                  value={addressDetail}
                  onChange={setAddressDetail}
                />
              </Row>
              <Row mt={8}>
                <Subject />
                <CheckInput
                  label="기본 배송지로 선택"
                  checked={isDefault}
                  onClick={() => {
                    setIsDefault(!isDefault);
                  }}
                />
              </Row>

              <Row>
                <Subject>수령메모</Subject>
                <FocusSelect
                  value={memo}
                  onChange={({ target: { value } }) => {
                    setMemo(value);
                  }}
                >
                  <option
                    label="선택해주세요."
                    value=""
                    style={{ display: "none" }}
                  />
                  {DELIVERY_COMMENTS.map((value) => (
                    <option key={value} label={value} value={value} />
                  ))}
                </FocusSelect>
              </Row>

              <Row>
                <Subject style={{ alignSelf: "flex-start" }}>출입방법</Subject>
                <Flex style={{ flex: 1 }}>
                  <CheckInput
                    label="공동현관 비밀번호"
                    checked={!isFreeDoor}
                    onClick={() => {
                      setIsFreeDoor(false);
                    }}
                  >
                    <Flex
                      style={{
                        margin: "7px 0",
                        fontFamily: fonts.notoSansKRRegular,
                        fontSize: 12,
                        letterSpacing: -0.24,
                        color: "#666666",
                      }}
                    >
                      배송을 위해 필요한 기간동안
                      <br />
                      보관하는데 동의합니다.
                    </Flex>
                    <FocusInput
                      type="text"
                      placeholder="예: #1234*"
                      value={passwordDoor}
                      onChange={setPasswordDoor}
                    />
                  </CheckInput>
                  <CheckInput
                    label="자유 출입 가능"
                    style={{ marginTop: 10 }}
                    checked={isFreeDoor}
                    onClick={() => {
                      setIsFreeDoor(true);
                    }}
                  />
                </Flex>
              </Row>
            </div>
          )}
        </FormWrap>
        <Button
          style={{ marginTop: 40, borderRadius: 8 }}
          label="수정"
          onClick={handleClickSubmit}
        />
      </Container>
    </BasicLayout>
  );
};

const FocusInput = ({ maxLength, type, onChange, align, ...rest }) => {
  return (
    <FocusInputContainer
      type={type}
      isNumber={type === "number"}
      align={align}
      onChange={({ target: { value } }) => {
        if (maxLength && maxLength < value.length) {
          return false;
        }
        if (value && type === "number" && !/^[0-9]+$/.test(value)) {
          return false;
        }
        onChange && onChange(value);
      }}
      {...rest}
    />
  );
};

const FocusInputContainer = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  &:focus {
    border: 1px solid #222222;
  }

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}

  ${({ align }) => (align ? `text-align : ${align};` : "")}
`;

const CheckInput = ({ checked, label, children, onClick, style }) => {
  return (
    <CheckInputContainer style={style} checked={checked} onClick={onClick}>
      <img src={checked ? checkbox_on : checkbox_off} width={24} height={24} />
      <Flex className="label">
        <span>{label}</span>
        {children}
      </Flex>
    </CheckInputContainer>
  );
};

const Container = styled(Flex)`
  padding: 16px;
  background: #fafafa;
`;

const CheckInputContainer = styled(Flex)`
  flex: 1;
  flex-direction: row;
  padding: 4px;
  background: #f2f2f2;
  border-radius: 4px;
  box-shadow: ${({ checked }) =>
    checked ? "0 0 0 1px #ffb382 inset" : "none"};

  .label {
    flex: 1;
    margin-left: 11px;
    font-family: ${fonts.notoSansKRRegular};
    font-size: 14px;
    letter-spacing: -0.28px;
    color: #444444;
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 16px;
  border: 1px solid #eaeaea;
  border-top: none;
  border-radius: 0 0 8px 8px;
`;

const FormHeader = styled(Flex)`
  background: white;
  border: 1px solid #eaeaea;
  padding: 16px;
  border-radius: 8px 8px 0 0;
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
`;

const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;

  margin-top: ${({ mt = 15 }) => mt}px;

  .dash {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 16px;
    color: #dbdbdb;
    letter-spacing: -0.28px;
    margin: 0 1px;
  }
`;

const Subject = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.3px;
  color: #333333;
  width: 70px;
`;

const FocusSelect = styled.select`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;

  &:focus {
    border: 1px solid #222222;
  }

  background-image: linear-gradient(45deg, transparent 50%, #656667 50%),
    linear-gradient(135deg, #656667 50%, transparent 50%);

  background-position: calc(100% - 10px) calc(1em + 2px),
    calc(100% - 5px) calc(1em + 2px);

  background-size: 5px 5px, 5px 5px, 1px;

  background-repeat: no-repeat;
  appearance: none;

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      // text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;

const SearchButton = styled.button`
  margin-left: 8px;
  background: #6c6c6c;
  border: none;
  color: white;
  font-size: 13px;
  font-family: ${fonts.notoSansKRMedium};
  border-radius: 4px;
  padding: 10px 9px;
`;

export default AddressModify;
