import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const SimpleAccordion = ({
  expended = false,
  title,
  onChange,
  expendIcon,
  children,
  ...rest
}) => {
  const childRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(expended);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded !== expended) {
      setIsExpanded(expended);
    }
  }, [expended]);

  useEffect(() => {
    if (!childRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setHeight(childRef.current?.offsetHeight);
    });
    resizeObserver.observe(childRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  const handleClickToggle = () => {
    setIsExpanded(!isExpanded);
    onChange && onChange(!isExpanded);
  };

  return (
    <Container {...rest}>
      <Header onClick={handleClickToggle}>
        {title}
        {expendIcon && (
          <ExpendIconWrap expended={isExpanded}>{expendIcon}</ExpendIconWrap>
        )}
      </Header>
      <ContentWrapper height={isExpanded ? height : 0}>
        <Content ref={childRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const ExpendIconWrap = styled.div`
  position: absolute;
  right: 0;
  transform: scaleY(${({ expended }) => (expended ? -1 : 1)});
`;

const ContentWrapper = styled.div`
  height: ${({ height }) => height}px;
  overflow: hidden;
  transition: all 0.3s ease-out;
`;

const Content = styled.div``;
export default SimpleAccordion;
