import { makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import { messageHide } from "../../redux/message/MessageActions";

export default function Message({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { open, message, onPress } = useSelector((s) => s.message);

  const handleClose = () => {
    if (onPress) {
      onPress();
    }
    dispatch(messageHide());
  };
  if (!open) {
    return null;
  }

  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <Text className={classes.message}>
          {message.split("\n").map((row) => (
            <>
              {row}
              <br />
            </>
          ))}
        </Text>
        <Button onClick={handleClose} className={classes.button} label="확인" />
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  message: {
    color: colors.text,
    textAlign: "center",
    fontSize: "16px",
    margin: "20px 0px",
    marginBottom: 10,
  },
  button: {
    alignSelf: "center",
    padding: "10px 50px",
    marginTop: "30px",
  },
});
