import { makeStyles } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";

import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { toastShow } from "../../redux/toast/ToastActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import NumberFormat from "react-number-format";
import routes, { ROLE } from "../../libs/routes";
import { useHistory } from "react-router";
import { getMobileOperatingSystem, numberWithCommas } from "../../libs/utils";

export default function Deposit({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { roles = [] } = useSelector((state) => state.auth);

  const [bankName, setBankName] = useState("");
  const [account, setAccount] = useState("");
  const [virtualAccount, setVirtualAccount] = useState("");
  const [depositMinKrw, setDepositMinKrw] = useState(10000);
  const [depositMaxKrw, setDepositMaxKrw] = useState(100000000);
  const [withdrawTimeLimit, setWithdrawTimeLimit] = useState(0);

  useEffect(() => {
    dispatch(loadingStart);

    Promise.all([
      // 입금 정보
      APIS.getAccountInfo("DEPOSIT").then(
        ({ data: { success, data, message } }) => {
          const {
            bankName = "",
            bankAccountNumber = "",
            virtualBankNumber = "",
          } = data || {};
          setBankName(bankName);
          setAccount(bankAccountNumber);
          setVirtualAccount(virtualBankNumber);
        }
      ),
      // 최소 입금액
      APIS.getEnvConfig("DEPOSIT_MIN_KRW").then(
        ({ data: { success, data } }) => {
          success && setDepositMinKrw(data?.value || 10000);
        }
      ),
      // 1일 최대 입금액
      APIS.getEnvConfig("MAX_KRW_DEPOSIT_DAY").then(
        ({ data: { success, data } }) => {
          success && setDepositMaxKrw(data?.value || 100000000);
        }
      ),
      // 입금후 출금 제한 시간
      APIS.getEnvConfig("WITHDRAW_TIME_LIMIT").then(
        ({ data: { success, data } }) => {
          success && setWithdrawTimeLimit(data?.value || 0);
        }
      ),
    ]).finally(() => dispatch(loadingEnd));
  }, []);

  const handleClickCopy = () => {
    var textField = document.createElement("textarea");
    textField.innerText = virtualAccount;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    // if (getMobileOperatingSystem() === "iOS") {
    dispatch(toastShow("가상계좌가 복사되었습니다."));
    // }
  };

  return (
    <BasicLayout stackTopbar={{ title: "입금하기", border: true }}>
      <div className="kb_bank_wrap">
        <div className="kb_bank_deposit pd60">
          <h3>
            지정한 입금 계좌에서
            <br />
            금방금방 가상 계좌로 입금해 주세요.
          </h3>
          <div className="kb_bank_account">
            <h3>내가 지정한 입금 계좌</h3>
            <p>
              {bankName}
              <span>{account}</span>
            </p>
          </div>
          <div className="kb_bank_account grbox" onClick={handleClickCopy}>
            <h3>금방금방 가상 계좌</h3>
            <p>
              KEB 하나은행 (구 외환은행)<span>{virtualAccount}</span>
            </p>
          </div>
        </div>
        <div className="kb_bank_noti">
          <h3>금방금방 가상 계좌로 입금 시 유의사항</h3>
          <ul>
            <li>
              <p>본 가상 계좌는 고객님 전용 계좌입니다.</p>
            </li>
            <li>
              <p>
                <span className="spoqa">입금한 금액</span> 은 거래여부와
                관계없이{" "}
                <span className="spoqa">{withdrawTimeLimit}시간 후 출금</span>{" "}
                가능합니다.{" "}
              </p>
            </li>
            <li>
              <p>
                <span className="spoqa">
                  최소 입금 금액은 {numberWithCommas(depositMinKrw)}원입니다.
                </span>{" "}
                최소 입금 금액 미만의 금액을 입금 시도하시면 은행마다 다른 에러
                메시지가 뜨며, 입금이 제한됩니다.
              </p>
            </li>
            <li>
              <p>
                지정한 입금계좌에서만 입금이 가능하며, 타 계좌에서 금방금방
                가상계좌로 입금은 제한됩니다.
              </p>
            </li>
            <li>
              <p>
                <span>오픈뱅킹 서비스를 통한 입금은 제한됩니다.</span>
              </p>
            </li>
          </ul>
        </div>
        <div className="kb_bank_table">
          <h3>입금 한도</h3>
          <ul>
            <li className="left_table">
              <div className="tb_top">1회 최소</div>
              <div className="tb_bt">
                최소 <br />
                <span className="spoqa">{numberWithCommas(depositMinKrw)}</span>
                <span>원</span>
              </div>
            </li>
            <li className="right_table">
              <div className="tb_top">1일 한도</div>
              <div className="tb_bt">
                1일 최대 <br />
                <span className="spoqa">{numberWithCommas(depositMaxKrw)}</span>
                <span>원</span>
                까지
              </div>
            </li>
          </ul>
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  card: {
    margin: "10px 30px 20px 30px",
    alignItems: "center",
    padding: "16px 0px",
  },
  label: {
    margin: "50px 30px 20px 30px",
    color: colors.text,
    fontSize: "18px",
    wordBreak: "break-word",
  },
  row: {
    alignItems: "center",
    margin: "0px 40px",
    marginBottom: "5px",
  },
  hint: {
    fontSize: "14px",
    lineHeight: 1,
    marginLeft: "10px",
    color: colors.text,
  },
  down: {
    width: "15px",
    height: "20px",
  },
  button: {
    margin: "0px 30px",
  },
  placeholder: {
    fontSize: "12px",
    color: colors.gray,
    margin: "20px 30px",
    alignSelf: "center",
    textAlign: "center",
  },
  cardLabel: {
    color: colors.gray,
    flex: 1,
    textAlign: "center",
    fontSize: "13px",
  },
  cardValue: {
    flex: 3,
    fontSize: "14px",
    color: colors.text,
  },
  message: {
    lineHeight: 1.6,
    fontSize: "13px",
    margin: "20px 30px",
    color: colors.gray,
  },
  message2: {
    fontSize: "15px",
    color: colors.text,
  },
});
