import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { userInit } from "../../redux/user/UserActions";

export default function RegisterShop({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const userReducer = useSelector((s) => s.user);

  useEffect(() => {
    if (userReducer.id) {
      history.replace(routes.main, { register: true });
    }
  }, [userReducer.id]);

  const register = () => {
    if (!state.register) {
      dispatch(messageShow("올바르지 않은 요청입니다."));
    } else if (!state.bank || !state.bankVerify) {
      dispatch(messageShow("본인명의 계좌와 결제 비밀번호를 등록해 주세요."));
    }
    //게좌
    const accountHolder = state.accountHolder;
    const bankCode = state.bank.bankCode;
    const bankNumber = state.bankNumber;
    //소셜
    const socialKey = state.token;
    const socialMethod = state.method;
    //결제비밀번호
    const bankPassword = state.bankPassword;
    //본인인증
    const email = state.email;
    const name = state.name;
    const phone = state.phone;

    dispatch(loadingStart);
    axios
      .post(consts.apiBaseUrl + "/api/oauth/joinAccount", {
        name,
        phone,
        socialKey,
        type: socialMethod,
        securityPassword: bankPassword,
        bankCode,
        accountNumber: bankNumber,
        accountHolder: accountHolder,
        shopId: history.location.state?.shop?.id || null,
        email,
      })
      .then((res) => {
        dispatch(loadingEnd);
        const data = res.data;
        const token = data.response.access_token;
        localStorage.setItem(consts.accessToken, token);
        dispatch(userInit);
      })
      .catch((e) => {
        dispatch(loadingEnd);
        dispatch(messageError(e));
      });
  };

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "추천 매장",
      }}
    >
      <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        추천 매장이 있으신가요?
      </Text>
      <ButtonMini
        style={{ marginBottom: state.shop ? 0 : 30 }}
        onClick={() => {
          history.push(routes.findShop, {
            to: history.location.pathname,
            state: history.location.state,
            mode: "click",
            shopId: history.location.state?.shop?.id,
          });
        }}
        label="매장 찾기"
        className={classes.bt + " margin"}
      />
      {state.shop && <ShopItemSimple {...state.shop} className="margin" />}
      <div style={{ height: "100px" }} />
      <Button
        onClick={register}
        label={state?.shop ? "다음" : "건너뛰기"}
        className={classes.button}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  button: {
    marginLeft: 30,
    marginRight: 30,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
});
