import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import routes from "../../libs/routes";

import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import Password from "./Password";
import NumberFormat from "react-number-format";
import * as APIS from "../../libs/apis";
import { numberWithCommas } from "../../libs/utils";
import { objToQueryStr } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function WithDrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signed, id } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.auth);
  const [account, setAccount] = useState({});
  const [asset, setAsset] = useState({});
  const [inputprice, setinputprice] = useState("");
  const [fee, setFee] = useState(0);
  const [autoWithdraw, setAutoWithdraw] = useState(0);
  const [maxCountWithdrawDay, setMaxCountWithdrawDay] = useState(3);
  const [maxKrwWithdrawDay, setMaxKrwWithdrawDay] = useState(0);
  const [withdrawTimeLimit, setWithdrawTimeLimit] = useState(0);
  /*
  useEffect(() => {
    //  dispatch(toastShow("출금요청이 완료되었습니다."));
    //     dispatch(messageShow("출금요청이 완료되었습니다.", history.goBack));
  }, []);
  */

  useEffect(() => {
    dispatch(loadingStart);
    Promise.all([
      APIS.getAccountInfo("WITHDRAW").then(({ data: { success, data } }) => {
        success && data && setAccount(data);
      }),
      APIS.getUserHoldingsInfo().then(({ data: { success, data } }) => {
        success && setAsset(data);
      }),
      APIS.getEnvConfig("WITHDRAW_FEE_KRW").then(({ data }) => {
        const fee = data?.data?.value;
        setFee(fee || 0);
      }),
      APIS.getEnvConfig("AUTO_WITHDRAW_LIMIT").then(({ data }) => {
        const autoWithdraw = data?.data?.value;
        setAutoWithdraw(autoWithdraw || 0);
      }),
      APIS.getEnvConfig("MAX_COUNT_WITHDRAW_DAY").then(({ data }) => {
        const maxCountWithdrawDay = data?.data?.value;
        setMaxCountWithdrawDay(maxCountWithdrawDay || 0);
      }),
      APIS.getEnvConfig("MAX_KRW_WITHDRAW_DAY").then(({ data }) => {
        const maxKrwWithdrawDay = data?.data?.value;
        setMaxKrwWithdrawDay(maxKrwWithdrawDay || 0);
      }),
      // 입금후 출금 제한 시간
      APIS.getEnvConfig("WITHDRAW_TIME_LIMIT").then(({ data }) => {
        const withdrawTimeLimit = data?.data?.value;
        setWithdrawTimeLimit(withdrawTimeLimit || 0);
      }),
    ]).finally(() => dispatch(loadingEnd));
  }, []);

  const expayOrder = () => {
    if (isNaN(inputprice) || +inputprice <= 0) {
      dispatch(toastShow("출금 금액을 입력하세요."));
      return;
    }
    const check = /^[0-9]+$/;
    if (!check.test(inputprice)) {
      dispatch(toastShow("숫자만 입력 가능합니다."));
    }

    if (asset.acceptableKrw < +inputprice + +fee) {
      dispatch(toastShow(`출금가능금액을 초과하였습니다.`));
      return;
    }

    history.push(routes.commonSecureKeypad, {
      type: "WITHDRAWAL",
      payload: { requestAmount: inputprice },
      buttonText: "출금하기",
    });
  };

  // 출금 가능 금액
  // const withdrawable =
  //   asset.acceptableKrw - fee > 0 ? asset.acceptableKrw - fee : 0;
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "출금하기" }}>
      <Flex row className={classes.row}>
        <Text className={classes.label}>등록계좌</Text>
        <Text className={classes.value}>
          {account.bankAccountNumber} {account.bankName}
        </Text>
      </Flex>
      {/* <Flex row className={classes.row}>
        <Text className={classes.label}>사용가능원화</Text>
        <Text className={classes.value}>
          <NumberFormat
            value={asset.acceptableKrw}
            displayType={"text"}
            thousandSeparator={true}
            className="spoqa"
          />{" "}
          원
        </Text>
      </Flex> */}
      <Flex row className={classes.row}>
        <Text className={classes.label}>수수료</Text>
        <Text className={classes.value}>
          <NumberFormat
            value={fee}
            displayType="text"
            thousandSeparator={true}
            className="spoqa"
          />{" "}
          원
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.label}>출금가능금액</Text>
        <Text className={classes.value}>
          <NumberFormat
            value={asset.withdrawKrw}
            displayType="text"
            thousandSeparator={true}
            className="spoqa"
          />{" "}
          원
        </Text>
      </Flex>
      <Flex row className={classes.subRow}>
        <Text className={classes.subValue}>
          ({withdrawTimeLimit}시간 이내 입금 금액 제외)
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.label}>출금금액</Text>
        <InputBase
          // type="number"
          // inputMode="numeric"
          inputProps={{ inputMode: "decimal" }}
          value={
            inputprice === 0 || !inputprice ? "" : numberWithCommas(inputprice)
          }
          onChange={(e) => {
            let values = e.target.value;
            values = values.replace(/,/gi, "");
            let regexp = /^[0-9]*$/;
            if (!regexp.test(values)) {
              return;
            } else {
              values = values.replace(/,/gi, "");
            }
            setinputprice(+values);
          }}
          className={classes.input}
          placeholder="0"
        />
      </Flex>

      <Button
        onClick={() => expayOrder()}
        className={classes.button}
        label="출금 요청"
      />
      <div className="kb_bank_wrap withdraw">
        <div className="kb_bank_noti">
          <h3>등록계좌로 출금 시 유의사항</h3>
          <ul>
            <li>
              <p>
                출금 수수료는{" "}
                <NumberFormat
                  value={fee}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                원입니다.
              </p>
            </li>
            <li>
              <p>
                <span>입금한 금액</span>은 거래여부와 관계없이{" "}
                <NumberFormat
                  value={withdrawTimeLimit}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                <span>시간 후 출금</span> 가능합니다.
              </p>
            </li>
            <li>
              <p>
                거래 안전을 위해 일일 자동 출금 횟수는{" "}
                <NumberFormat
                  value={maxCountWithdrawDay}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                회로 제한됩니 다.{" "}
                <NumberFormat
                  value={maxCountWithdrawDay}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                회 초과 시 본사 승인 후 출금됩니다.
              </p>
            </li>
            <li>
              <p>
                <NumberFormat
                  value={autoWithdraw}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                원 초과 출금은 본사 승인 후 출금됩니다.
              </p>
            </li>
            <li>
              <p>
                <span className="spoqa">18</span>시 이후{" "}
                <NumberFormat
                  value={autoWithdraw}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                원 초과 또는{" "}
                <NumberFormat
                  value={maxCountWithdrawDay}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                회 초과 출금 요청 건은 영업일 기준 익일{" "}
                <span className="spoqa">9</span>시부터 확인 후 순차적으로 출금
                처리됩니다.
              </p>
            </li>
            <li>
              <p>
                확인 업무시간: 평일 <span className="spoqa">09:00~18:00</span>{" "}
                (공휴일 제외)
              </p>
            </li>
          </ul>
        </div>
        <div className="kb_bank_table">
          <h3>출금 한도</h3>
          <ul>
            <li className="left_table">
              <div className="tb_top">자동 출금</div>
              <div className="tb_bt">
                1회 최대 <br />
                <NumberFormat
                  value={autoWithdraw}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                <span>원</span>
                <br />
                <p>
                  <NumberFormat
                    value={maxCountWithdrawDay}
                    displayType="text"
                    thousandSeparator={true}
                  />
                  회
                </p>
                까지
              </div>
            </li>
            <li className="right_table">
              <div className="tb_top">승인 출금</div>
              <div className="tb_bt">
                1일 최대
                <br />
                <NumberFormat
                  value={maxKrwWithdrawDay}
                  displayType="text"
                  thousandSeparator={true}
                  className="spoqa"
                />
                <span>원</span>
                까지
              </div>
            </li>
          </ul>
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  message: {
    lineHeight: 1.6,
    fontSize: "13px",
    margin: "20px 30px",
    color: colors.gray,
  },
  message2: {
    fontSize: "15px",
    color: colors.text,
  },
  button: {
    margin: "20px 16px 0px 16px",
  },
  row: {
    alignItems: "center",
    margin: "20px 16px 0px 16px",
    justifyContent: "space-between",
  },
  label: {
    color: colors.gray,
    fontSize: 14,
  },
  value: {
    textAlign: "right",
    color: colors.text,
    fontSize: "14px",
  },
  input: {
    backgroundColor: colors.white,
    height: "40px",
    fontSize: "17px",
    padding: "10px",
    boxSizing: "border-box",
    fontFamily: fonts.notoSansKRBold,
    color: colors.black,
    width: "50%",
    borderRadius: "4px",
    textAlign: "right",
    textAlignLast: "right",
    "&> input": {
      textAlign: "right",
    },
  },
  subRow: {
    alignItems: "center",
    marginRight: "16px",
    justifyContent: "flex-end",
  },
  subValue: {
    textAlign: "right",
    color: colors.text,
    fontSize: "12px",
  },
});
