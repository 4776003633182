import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function Tables({ labelLength, columns, data = [] }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex className={classes.root}>
      {labelLength && (
        <Text className={classes.length}>
          총<Text font={fonts.notoSansKRMedium}> {labelLength}</Text>건
        </Text>
      )}
      <Flex row className={classes.header}>
        {columns.map((x, i) => {
          return (
            <Flex className={classes.column} key={i.toString()}>
              <Text>
                {x.label.split("\n").map((x, i) => {
                  if (i !== 0) {
                    return (
                      <>
                        <br /> {x}
                      </>
                    );
                  }
                  return x;
                })}
              </Text>
            </Flex>
          );
        })}
      </Flex>

      {data.map((x, i) => {
        return (
          <Flex className={classes.row} row key={i.toString()}>
            {columns.map((column, index) => {
              return (
                <Flex className={classes.value} key={index.toString()}>
                  {column.render ? column.render(x) : x[column.key] || "-"}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  length: {
    margin: "10px 28px",
    fontSize: "12px",
    color: "#999",
    "& span": { color: "#333" },
  },
  root: {},
  header: {
    alignSelf: "stretch",
    backgroundColor: "#eeeeee",
  },
  column: {
    flex: 1,
    alignItems: "center",
    padding: "13px",
    justifyContent: "center",
    "& span": {
      color: colors.text,
      textAlign: "center",
      lineHeight: 1.3,
      fontSize: "13px",
    },
  },
  value: {
    alignItems: "center",
    flex: 1,
    color: colors.text,
    fontSize: "13px",
    textAlign: "center",
    justifyContent: "center",
    padding: "13px",
  },
  row: { borderBottom: "1px solid #eeeeee", alignItems: "stretch" },
});
