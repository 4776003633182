import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import Button from "../../components/button/Button";
import DateSelector from "../../components/date-selector/DateSelector";
import Flex from "../../components/flex/Flex";
import ProductList from "../../components/product-list/ProductList";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import TimeRangeSelectorNew from "../../components/time-range-selector/TimeRangeSelectorNew";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import {
  getPurityLabel,
  numFormat,
  objToQueryStr,
  phoneFormat,
} from "../../services/utils";
import ExchangeDeliveryResult from "./ExchangeDeliveryResult";
import * as APIS from "../../libs/apis";
import { formatTime, parsePhoneNumber } from "../../libs/utils";
import { initFindShop } from "../../redux/common/findShop";
import Calendar from "../../components/common/Calendar";
import VisitReservation from "../../components/common/VisitReservation";
import product_default from "../../assets/images/product_default.webp";
import PolicyAgreePopup from "../../components/policy-agree-popup/PolicyAgreePopup";
import styled from "styled-components";
import delivery_car from "../../assets/images/delivery_car.png";
import DaumPostcode from "react-daum-postcode";
import info from "../../assets/images/icon_info.png";

// const DELIVERY_COMMENTS = [
//   "문 앞에 놓아주세요.",
//   "택배함에 넣어주세요.",
//   "경비실에 맡겨주세요.",
//   "부재 시 연락주세요.",
//   "직접 받고 부재 시 문 앞에 놓아주세요.",
// ];

export default function Exchange({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [products, setProducts] = useState([]);

  //delivery-------
  // const [addressDetail, setAddressDetail] = useState("");
  //-------delivery
  // const [date, setDate] = useState(formatTime());
  // const [isFullDayOff, setIsFullDayOff] = useState(false);
  // const [time, setTime] = useState("");
  //----
  const [popup, setPopup] = useState(false);
  const [usingDelivery, setUsingDelivery] = useState(false);
  const [postCode, setPostCode] = useState(""); // 우편번호
  const [address, setAddress] = useState(""); // 기본주소
  const [addressDetail, setAddressDetail] = useState(""); // 상세주소
  const [isDefault, setIsDefault] = useState(false); // 기본 주소 여부
  const [isAddressSearch, setIsAddressSearch] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [assetType, setAssetType] = useState("");
  const state = history.location.state || {};
  const requestMode =
    "appraisal" === state.action && state.request && state.appraisalSimpleIds;
  // const userReducer = useSelector((s) => s.user);
  const method = state?.shop?.id
    ? "shop"
    : history.location.state?.method || "";

  const { name, phone = "" } = useSelector((s) => s.user);

  const handleCompleteSearch = (data) => {
    setPostCode(data.zonecode);
    setAddress(data.address);
    setAddressDetail("");
    setIsAddressSearch(false);
  };

  const setMethod = (v) =>
    history.replace(history.location.pathname, { ...state, method: v });

  useEffect(() => {
    if (requestMode) {
      const getGoldGramPrice = () => {
        return new Promise((resolve, reject) => {
          APIS.getCurrentMarketPrice("GOLD")
            .then(({ data: { data } }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      };
      const getSilverGramPrice = () => {
        return new Promise((resolve, reject) => {
          APIS.getCurrentMarketPrice("SILVER")
            .then(({ data: { data } }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      };

      const fetchData1 = () => {
        // if (!userReducer.id) {
        //   return;
        // }
        dispatch(
          axiosDispatch(
            {
              method: "get",
              url:
                consts.apiBaseUrl +
                "/api/v1/user/market/appraisal/Simples" +
                objToQueryStr({
                  status: "접수",
                  // userId: userReducer.id,
                }),
            },
            (err, res) => {
              if (err) {
                dispatch(messageError(err));
              } else {
                const list = res.data.data.filter((x) =>
                  state.appraisalSimpleIds.includes(x.id)
                );
                setAssetType(list[0]?.appraisalProduct.assetType);
                if (!list.length) {
                  dispatch(
                    messageShow("교환 가능한 제품이 없습니다.", () => {
                      history.replace(routes.appraise);
                    })
                  );
                  return;
                }
                dispatch(
                  axiosDispatch(
                    {
                      method: "get",
                      url:
                        consts.apiBaseUrl +
                        "/api/v1/user/market/appraisal/appraisalWeightRates",
                    },
                    (err2, res2) => {
                      if (err2) {
                        dispatch(messageError(err2));
                      } else {
                        let func =
                          list[0]?.appraisalProduct.type === "GOLD"
                            ? getGoldGramPrice
                            : getSilverGramPrice;
                        func()
                          .then((price) => {
                            setProducts([
                              ...list.map((x, i) => {
                                x.calc =
                                  x.weightGram *
                                  (res2.data.data.content.filter(
                                    (y) =>
                                      y.type === x?.appraisalProduct.type &&
                                      x.purity === y.purity
                                  )[0]?.weightRatePct /
                                    100) *
                                  (x?.appraisalProduct.weightRatePct / 100) *
                                  x.quantity;
                                x.price = price * x.calc;
                                return x;
                              }),
                            ]);
                          })
                          .catch((e) => {
                            dispatch(messageError(e));
                          });
                      }
                    }
                  )
                );
              }
            }
          )
        );
      };

      fetchData1();
    }
  }, [requestMode]);

  useEffect(() => {
    APIS.getEnvConfig("APPRAISAL_USING_DELIVERY_POST").then(
      ({ data: { success, data } }) => {
        success && setUsingDelivery(data?.value);
      }
    );
  }, []);

  const requestExchange = () => {
    try {
      if (method === "delivery") {
        if (postCode === "" || address === "") {
          throw "주소를 검색해주세요.";
        } else if (addressDetail === "") {
          throw "상세주소를 입력해 주세요.";
        }
      } else {
        if (!state.shop?.id) {
          throw "방문 매장을 선택해주세요.";
        } else if (!state.reservation?.date) {
          throw "방문 일자를 선택해주세요.";
        }
      }
      if (!state.appraisalSimpleIds.length) {
        history.replace(routes.appraise);
        throw "교환하실 제품을 선턱해 주세요.";
      }
    } catch (error) {
      dispatch(messageError(error));
      return;
    }
    setPopup(true);
  };

  // if (!state?.method) {
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: history.location.pathname,
  //         state: {
  //           ...history.location.state,
  //           method: "", // delivery, shop 둘다 선택되게 해야해서 일단은 "" 표시해야함.
  //         },
  //       }}
  //     />
  //   );
  // } else
  if (state?.deliveryComplete) {
    if (!state.appraisalRequestId) {
      return <Redirect to={routes.appraise} />;
    }
    return (
      <BasicLayout
        stackTopbar={{
          close: true,
          disableClose: true,
          option: (
            <ButtonBase
              onClick={() => {
                history.replace(routes.main);
              }}>
              <img
                src={images.home}
                style={{ width: 20, height: 20, marginRight: "16px" }}
              />
            </ButtonBase>
          ),
        }}>
        <ExchangeDeliveryResult appraisalRequestId={state.appraisalRequestId} />
      </BasicLayout>
    );
  } else if (requestMode) {
    return (
      <BasicLayout stackTopbar={{ title: "감정 신청", border: true }}>
        <div
          className="kb_cal_wrap easy"
          ref={contentRef}
          onLoad={(e) => {
            setContentHeight(contentRef.current.offsetHeight - 100);
          }}>
          <div className="cal_top">
            <h3>{products.length}개의 제품을 감정 신청합니다.</h3>
          </div>
          <div className="cal_product" style={{ borderBottom: "unset" }}>
            {products.map((product, i) => {
              const imageUrl =
                product.topImageUrl ||
                product.frontImageUrl ||
                product.leftSideImageUrl ||
                product.rightSideImageUrl;

              return (
                <ul key={i.toString()}>
                  <li className="ul_tit ul_img">
                    <img
                      src={
                        imageUrl
                          ? consts.s3BaseUrl + "/" + imageUrl
                          : product_default
                      }
                      alt="img"
                    />
                  </li>
                  <li className="ul_table">
                    <ul>
                      <li>
                        <h3>{product?.appraisalProduct.name}</h3>
                      </li>
                      <li>
                        <p>
                          {getPurityLabel(
                            product.purity,
                            product?.appraisalProduct.type
                          )}{" "}
                          {numFormat(product.quantity)}개 보증서(
                          {product.guaranteeImageUrl ? "유" : "무"})
                        </p>
                      </li>
                    </ul>
                  </li>
                </ul>
              );
            })}
          </div>
          <BorderLine />
          {usingDelivery === "true" ? (
            <>
              <div
                className="kb_cal_wrap"
                style={{
                  margin: `0px 16px ${
                    method === "" || method === "shop" ? "100px" : ""
                  }
                  `,
                }}>
                <div
                  className="cal_bottom"
                  style={{ padding: 0, marginTop: "24px" }}>
                  {method === "" && (
                    <>
                      <SelectHeaderFont>
                        접수 방법을 선택해 주세요.
                      </SelectHeaderFont>
                      <DeliverySquare>
                        <Badge>배송</Badge>
                        <Title>
                          <span>편하게 </span>
                          <Highlight>택배</Highlight>로
                          <Highlight>감정 신청</Highlight>하기
                          <NewBadge>New</NewBadge>
                        </Title>
                        <Description>
                          <List>
                            <li>
                              - 택배 감정은 금방금방이 고객님의 제품을 수거하여
                              신뢰도 있는 전문 감정사가 자산으로 교환을 돕는
                              서비스입니다.
                            </li>
                            <li>
                              <span
                                style={{
                                  color: "#777",
                                  fontFamily: "Noto Sans KR",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "150%",
                                  letterSpacing: "-0.28px",
                                }}>
                                - 택배비 무료
                              </span>
                              <br />
                              (단, 돌려받기 시 택배비는 고객 부담입니다)
                            </li>
                          </List>
                        </Description>
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                            width: "100%",
                          }}>
                          <DeliveryRequestButton
                            // className={"off"}
                            onClick={() => {
                              setMethod("delivery");
                            }}>
                            택배 감정 신청하기
                          </DeliveryRequestButton>
                          <img
                            src={delivery_car}
                            style={{ position: "absolute", right: 16, top: 14 }}
                          />
                        </div>
                      </DeliverySquare>
                    </>
                  )}
                  {method === "" || method === "shop" ? (
                    <VisitReservation
                      description="대리점으로 직접 방문하여 감정 신청하기"
                      usingDelivery={usingDelivery}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div style={{ margin: "0px 16px 100px" }}>
              <VisitReservation description="방문하실 매장을 선택해주세요." />
            </div>
          )}
          {method === "delivery" &&
            (isAddressSearch ? (
              <DaumPostcode
                autoClose
                animation
                height={contentHeight}
                width="100%"
                onComplete={handleCompleteSearch}
              />
            ) : (
              <div>
                <div style={{ margin: "0px 16px" }}>
                  <SelectHeaderFont>접수 방법</SelectHeaderFont>
                  <RequestHeaderBox>
                    <Badge>배송</Badge>
                    <Title>
                      <span>편하게 </span>
                      <Highlight>택배</Highlight>로
                      <Highlight>감정 신청</Highlight>
                      하기
                      <NewBadge>New</NewBadge>
                    </Title>
                  </RequestHeaderBox>
                </div>
                <BorderLine />
                <div style={{ margin: "24px 16px 88px 16px" }}>
                  <SelectHeaderFont>배송지 입력</SelectHeaderFont>
                  <DeliveryInputBox>
                    <NoticeBox>
                      <img
                        src={info}
                        style={{ width: "24px", height: "24px" }}
                      />
                      <NoticeText>
                        입력하신 주소로 택배 기사님이 방문예정입니다.
                        <HighlightBlue> 정확히 입력</HighlightBlue>해 주세요!
                      </NoticeText>
                    </NoticeBox>
                    <div style={{ marginTop: "16px", width: "100%" }}>
                      <InputHeaderText>수거 주소</InputHeaderText>
                      <BorderLine2 />
                      <Row>
                        <Subject
                          style={{ display: "flex", flexDirection: "row" }}>
                          수령인
                        </Subject>
                        <Content>{name}</Content>
                      </Row>
                      <Row>
                        <Subject row>연락처</Subject>
                        <Content>{parsePhoneNumber(phone, "-")}</Content>
                      </Row>
                      <Row style={{ alignItems: "stretch" }}>
                        <Subject
                          style={{
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "row",
                          }}>
                          배송지
                          <span style={{ color: colors.primary }}>*</span>
                        </Subject>
                        <FocusInput
                          type="text"
                          readOnly
                          value={postCode}
                          placeholder="우편번호"
                        />
                        <SearchButton onClick={() => setIsAddressSearch(true)}>
                          주소검색
                        </SearchButton>
                      </Row>
                      <Row mt={8}>
                        <Subject />
                        <FocusInput
                          type="text"
                          readOnly
                          value={address}
                          placeholder="기본주소"
                        />
                      </Row>
                      <Row mt={8}>
                        <Subject />
                        <FocusInput
                          type="text"
                          placeholder="상세주소"
                          value={addressDetail}
                          onChange={setAddressDetail}
                        />
                      </Row>
                      {/* <Row>
                        <Subject>수령메모</Subject>
                        <FocusSelect
                          value={memo}
                          onChange={({ target: { value } }) => {
                            setMemo(value);
                          }}>
                          <option
                            label="선택해주세요."
                            value=""
                            style={{ display: "none" }}
                          />
                          {DELIVERY_COMMENTS.map((value) => (
                            <option key={value} label={value} value={value} />
                          ))}
                        </FocusSelect>
                      </Row> */}
                    </div>
                  </DeliveryInputBox>
                </div>
              </div>
            ))}
          <button
            className={`bt_btn ${
              (state.shop?.id && state.reservation?.date && "on") ||
              (address && "on")
            }`}
            onClick={requestExchange}>
            신청하기
          </button>
        </div>
        {popup &&
          (state.shop?.id ? (
            <PolicyAgreePopup
              title="서비스 이용 동의"
              onClose={() => {
                setPopup(false);
              }}
              onAction={() => {
                // 매장 방문 예약 접수 신청 API 실행
                const params = {
                  acceptTermsYn: "Y",
                  appraisalSimpleIds: state.appraisalSimpleIds.join(","),
                  type: products[0]?.appraisalProduct.type,
                };
                params.method = "SIMPLE_VISIT";
                params.visitDate = formatTime(state.reservation.date);
                params.shopId = state.shop.id;
                dispatch(loadingStart);
                dispatch(
                  axiosDispatch(
                    {
                      method: "post",
                      url:
                        consts.apiBaseUrl +
                        "/api/v1/user/market/appraisal/Requests",
                      data: params,
                    },
                    (err, res) => {
                      dispatch(loadingEnd);
                      if (err) {
                        dispatch(messageError(err));
                      } else {
                        const appraisalRequestId = res.data.data.id;
                        history.replace(routes.exchange, {
                          deliveryComplete: true,
                          appraisalRequestId: appraisalRequestId,
                        });
                      }
                    }
                  )
                );
              }}
              data={[
                { label: "서비스 이용약관에 동의합니다." },
                {
                  label:
                    "제품에 포함된 각종 보석류 및 세팅 등은 가치를 산정하지 않습니다.",
                },
                {
                  label:
                    "상세감정 과정의 특성상 제품에 흠집 또는 손상이 갈 수 있습니다.",
                },
                {
                  label:
                    "불법적 획득 제품으로 의심될 경우 상세감정이 거부될 수 있습니다.",
                },

                {
                  label: "상세 감정 결과는 간편 감정 결과와 다를 수 있습니다.",
                },
                {
                  label:
                    "상세 감정 결과에 따라 금/은 제품을 보유 금/은(g) 자산으로 교환할 수 있습니다.",
                },
                { label: "교환이 완료된 경우 거래의 취소가 불가합니다." },
                {
                  label:
                    "교환이 완료된 제품은 원재료 형태로 정련되어 안전하게 보관됩니다.",
                },
                {
                  label:
                    "교환이 완료된 제품은 실물인출 형태로 반환신청을 할 수 있습니다.",
                },

                {
                  label:
                    "회원의 서비스 이용 내용은 서비스 고도화를 위한 데이터로 활용될 수 있습니다.",
                },
              ]}
            />
          ) : (
            <PolicyAgreePopup
              title="서비스 이용 동의"
              onClose={() => {
                setPopup(false);
              }}
              onAction={() => {
                // 택배 신청 API 실행
                const Data = {
                  acceptTermsYn: "Y",
                  appraisalSimpleIds: state.appraisalSimpleIds.join(","),
                  type: products[0]?.appraisalProduct.type,
                  method: "SIMPLE_DELIVERY",
                  assetType: assetType,
                  deliveryRequest: {
                    postCode, // 우편번호
                    address, // 주소
                    addressDetail, // 상세주소
                    // addressName, // 배송지명
                    // receiver, // 수령인
                    // phone: phone.join(""), // 연락처1
                    // mobile: mobile.join(""), // 연락처2
                    // memo, // 수령메모
                  },
                };
                APIS.postAppraiseRequest(Data).then(
                  ({ data: { success, data, message } }) => {
                    if (!success) {
                      dispatch(messageError(message));
                    } else {
                      const appraisalRequestId = data.id;
                      history.push(routes.exchange, {
                        deliveryComplete: true,
                        appraisalRequestId: appraisalRequestId,
                      });
                    }
                  }
                );
              }}
              data={[
                { label: "자산 교환 완료 시 본사로 이용대리점이 변경됩니다." },
                {
                  label:
                    "실제 전문가 감정결과는 간편감정 결과와 다를 수 있습니다.",
                },
                {
                  label: "사진과 다른 제품일 경우 확인 후 반송처리됩니다.",
                },
                {
                  label: "반송시 배송비는 고객 부담입니다.",
                },

                {
                  label:
                    "감정 과정에서 제품이 절단, 분리 등 손상될 수 있습니다.",
                },
                {
                  label:
                    "배송 보낸 물품과 감정물품이 다른 경우 CCTV 영상으로 판별됩니다.",
                },
                {
                  label:
                    "판별이 완료되었음에도 다르다고 주장하시는 경우 입증책임은 고객에게 있습니다.",
                },
                {
                  label: "자산 교환 완료 후 돌려받기는 불가합니다.",
                },
              ]}
            />
          ))}
      </BasicLayout>
    );
  } else {
    return <Redirect to={routes.appraise} />;
  }
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": {
      color: colors.text,
      marginLeft: "20px",
    },
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
  },
});

const SelectHeaderFont = styled.div`
color: #333;
font-family: "Noto Sans KR";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.36px;
`;

const DeliverySquare = styled.div`
margin-top: 16px;
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
border: 2px solid #FFCF23;
background: #FFF;

/* shadow_card_02 */
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

const Badge = styled.div`
display: flex;
height: 24px;
padding: 0px 8px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 25px;
border: 1px solid rgba(255, 110, 14, 0.20);
background: rgba(255, 255, 255, 0.00);
color: #FF6E0E;
leading-trim: both;
text-edge: cap;
font-family: Pretendard;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
margin-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: #444;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
  
  > * + * {
    margin-left: 5px;
  }
`;

const Highlight = styled.span`
  color: #FF6E0E;
`;
const HighlightBlue = styled.span`
color: #467CC4;
font-family: "Noto Sans KR";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 160%;
letter-spacing: -0.28px;
`;

const NewBadge = styled.div`
display: flex;
padding: 4px 6px;
align-items: center;
gap: 8px;
border-radius: 80px;
background: #FFF2C5;
color: #FF6E0E;
font-family: Pretendard;
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.2px;
`;

const Description = styled.span`
margin-top:12px;
color: #777;
font-family: "Noto Sans KR";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.28px;
`;

const List = styled.ul`
list-style: circle;
`;

const DeliveryRequestButton = styled.button`
display: flex;
height: 36px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
border: none;
background: #FFEFE4;
color: #FF6E0E;
leading-trim: both;
text-edge: cap;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.28px;
margin-top: 32px;
`;

const FocusInput = ({ maxLength, type, onChange, ...rest }) => {
  return (
    <FocusInputContainer
      type={type}
      isNumber={type === "number"}
      onChange={({ target: { value } }) => {
        if (maxLength && maxLength < value.length) {
          return false;
        }
        if (value && type === "number" && !/^[0-9]+$/.test(value)) {
          return false;
        }
        onChange && onChange(value);
      }}
      {...rest}
    />
  );
};

const FocusInputContainer = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  &:focus {
    border: 1px solid #222222;
  }

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;

const RequestHeaderBox = styled.div`
display: flex;
padding: 16px;
margin: 16px 0px 24px 0px;
flex-direction: column;
align-items: flex-start;
border: 1px solid #EAEAEA;
background: #FFF;

/* shadow_card_02 */
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;
const BorderLine = styled.div`
display: flex;
height: 8px;
justify-content: center;
align-items: center;
flex-shrink: 0;
background: rgba(0, 0, 0, 0.05);
`;

const DeliveryInputBox = styled.div`
display: flex;
padding: 20px 16px;
flex-direction: column;
align-items: flex-start;
background: #FAFAFA;

/* shadow_card_02 */
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

const NoticeBox = styled.div`
display: flex;
padding: 8px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
background: #F2F7FF;
`;

const NoticeText = styled.span`
color: #444;
font-family: "Noto Sans KR";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 22.4px */
letter-spacing: -0.28px;
`;

const InputHeaderText = styled.span`
color: var(--point_color, #FE6E0C);
font-family: "Noto Sans KR";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.32px;
`;

const BorderLine2 = styled.div`
margin: 8px 0px 16px 0px;
display: flex;
height: 1px;
justify-content: center;
align-items: center;
align-self: stretch;
background: var(--grey-Grayscale_20_EAEAEA, #EAEAEA);
`;

const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;

  margin-top: ${({ mt = 15 }) => mt}px;

  .dash {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 16px;
    color: #dbdbdb;
    letter-spacing: -0.28px;
    margin: 0 1px;
  }
  `;

const SearchButton = styled.button`
  margin-left: 8px;
  background: #6c6c6c;
  border: none;
  color: white;
  font-size: 13px;
  font-family: ${fonts.notoSansKRMedium};
  border-radius: 4px;
  padding: 10px 9px;
`;

const Subject = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.3px;
  color: #333333;
  width: 70px;
`;
const Content = styled(Flex)`
font-size: 15px;
line-height: 27px;
`;
const FocusSelect = styled.select`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;

  &:focus {
    border: 1px solid #222222;
  }

  background-image: linear-gradient(45deg, transparent 50%, #656667 50%),
    linear-gradient(135deg, #656667 50%, transparent 50%);

  background-position: calc(100% - 10px) calc(1em + 2px),
    calc(100% - 5px) calc(1em + 2px);

  background-size: 5px 5px, 5px 5px, 1px;

  background-repeat: no-repeat;
  appearance: none;

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      // text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;
