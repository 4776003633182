import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";

export default function More({onClick}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ButtonBase className={classes.button} onClick={onClick}>
      <Flex className={classes.iconWrap}>
        <img src={images.plus} className={classes.icon} />
      </Flex>
      더보기
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  icon: {
    width: "8px",
    height: "8px",
    objectFit: "contain",
  },
  iconWrap: {
    width: "16px",
    height: "16px",
    borderRadius: "100px",
    border: "1px solid #666",
    marginRight: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    alignSelf: "center",
    margin: "30px",
    fontSize: "14px",
    color: "#333",
    fontFamily: fonts.notoSansKRRegular,
  },
});
