import { ButtonBase, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import InputShadow from "../../components/input-shadow/InputShadow";
import PolicyPopup from "../../components/policy-popup/PolicyPopup";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import { objToQueryStr } from "../../services/utils";

export default function Register({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { userId } = useSelector((s) => s.user);
  const [emailError, setemailError] = useState("");
  const [email, setEmail] = useState("");
  const [nickname, setNickname] = useState("");

  const [terms, setTerms] = useState([]);

  useEffect(() => {
    setemailError("");
  }, [email]);

  useEffect(() => {
    if (state?.email) {
      setEmail(state.email);
    }
    if (state?.name) {
      setNickname(state.name);
    }
  }, [state]);

  useEffect(() => {
    if (userId) {
      history.replace(routes.main, {});
    }
  }, [history.location.key, userId]);

  useEffect(() => {
    axios
      .get(consts.apiBaseUrl + "/api/terms/kindList")
      .then((res) => {
        const data = res.data;
        setTerms([...data.map((x) => ({ kind: x }))]);
      })
      .catch((e) => {
        dispatch(messageError(e));
      });
  }, []);

  const handleNext = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setemailError("올바른 이메일 형식을 입력해 주세요.");
    } else if (!nickname) {
      dispatch(messageError("이름을 입력해 주세요."));
    } else {
      axios
        .get(
          consts.apiBaseUrl +
            "/api/accounts/emailCheck" +
            objToQueryStr({
              email,
            })
        )
        .then((res) => {
          const data = res.data;
          if (data) {
            setemailError("이미 가입된 이메일 입니다.");
          } else {
            history.push(routes.verify, { ...state, email, name: nickname });
          }
        })
        .catch((e) => {
          dispatch(messageError(e));
        });
    }
  };

  const renderLogo = (margin) => {
    return (
      <div
        className={classes.logo}
        style={{
          marginRight: margin ? 16 : 0,
          backgroundColor: (() => {
            switch (state.method) {
              case "NAVER":
                return "#21c603";
              case "GOOGLE":
                return "#fff";
              case "KAKAO":
                return "#f7e600";
              default:
                "APPLE";
                return "#000";
            }
          })(),
        }}
      >
        <img
          alt="logo"
          src={(() => {
            switch (state.method) {
              case "NAVER":
                return images.naver;
              case "GOOGLE":
                return images.google;
              case "KAKAO":
                return images.kakaotalk;
              case "APPLE":
                return images.apple;
            }
          })()}
        />
      </div>
    );
  };

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "회원가입",
      }}
    >
      <Flex className={`${classes.container} shadow`}>
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {renderLogo(true)}
          {(() => {
            switch (state.method) {
              case "NAVER":
                return "네이버";
              case "GOOGLE":
                return "구글";
              case "KAKAO":
                return "카카오톡";
              case "APPLE":
                return "애플";
            }
          })()}
          계정으로 회원가입하기
        </Text>
      </Flex>

      <InputShadow
        required
        value={nickname}
        onChange={setNickname}
        label="이름"
        placeholder="이름을 입력해 주세요."
        className={classes.input}
      />
      <InputShadow
        required
        value={email}
        onChange={setEmail}
        label="이메일"
        error={emailError}
        //disabled={Boolean(state.email)}
        disabledColor
        placeholder="이메일 주소를 입력해 주세요."
        className={classes.input}
      />

      <CheckboxCircle
        className={classes.checkbox}
        label="전체동의"
        checked={
          terms.length && terms.filter((x) => x.checked).length === terms.length
        }
        onCheckedChange={(v) => {
          setTerms((t) => [...t.map((x) => ({ ...x, checked: v }))]);
        }}
      />
      {terms.map((x, i) => {
        return (
          <Checkbox
            key={i.toString()}
            optionComponent={
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(routes.policy, { kind: x.kind });
                }}
                alt="right"
                src={images.right}
                className={classes.right}
              />
            }
            className={classes.cb}
            onCheckedChange={(p) => {
              setTerms((t) => [
                ...t.map((y, z) => ({
                  ...x,
                  checked: i === z ? p : y.checked,
                })),
              ]);
            }}
            checked={x.checked}
            label={"[필수] " + x.kind}
          />
        );
      })}

      <ButtonBase
        onClick={handleNext}
        disabled={
          !(
            terms.length &&
            terms.filter((x) => x.checked).length === terms.length
          ) ||
          !email ||
          !nickname ||
          Boolean(emailError)
        }
        className={classes.rightWrap}
      >
        <img
          alt="next"
          src={
            terms.length &&
            terms.filter((x) => x.checked).length === terms.length &&
            email &&
            nickname &&
            !Boolean(emailError)
              ? images.arrowrighton
              : images.arrowright
          }
          className={classes.arrowright}
        />
      </ButtonBase>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  arrowright: {
    width: "40px",
    height: "30px",
  },
  rightWrap: {
    alignSelf: "flex-end",
    marginRight: "30px",
    marginTop: "50px",
  },
  input: {
    margin: "0px 30px 30px 30px",
  },
  container: {
    margin: "50px 30px 30px 30px",
    backgroundColor: colors.white,
    padding: "24px",
    boxSizing: "border-box",
  },
  right: { width: "8px", height: "12px" },
  cb: {
    margin: "0px 30px",
    marginTop: 30,
  },
  label: {
    color: colors.text,
    fontSize: 18,
    display: "flex",
    alignItems: "center",
  },
  email: {
    alignItems: "center",
    marginTop: "20px",
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "24px",
    height: "24px",
    "& img": {
      objectFit: "contain",
      width: "60%",
      height: "60%",
    },
  },
  emailLABEL: {
    color: colors.text,
    fontSize: 16,
    marginLeft: 10,
    lineHeight: 1,
  },
  checkbox: {
    margin: "0px 30px",
  },
});
