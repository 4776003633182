export const messageActionType = {
  show: "message/show",
  hide: "message/hide",
};

export const messageError = (error, onPress) => (dispatch) => {
  // console.log(error);
  dispatch({
    type: messageActionType.show,
    onPress,
    message: error.data
      ? error.data.cause
      : error.response
      ? error.response.message || error.response.msg || error.response.data
        ? error.response.data.error || error.response.data.message
        : JSON.stringify(error.data) ||
          error.response.error ||
          JSON.stringify(error.response)
      : error.message ||
        error.msg ||
        (typeof error === "object" ? JSON.stringify(error) : error),
  });
};

export const messageShow = (message, onPress) => (dispatch) => {
  dispatch({ type: messageActionType.show, message, onPress });
};
export const messageHide = () => (dispatch) => {
  dispatch({ type: messageActionType.hide });
};
