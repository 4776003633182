// Actions
const INIT = "common/find-shop/INIT";
const UPDATE = "common/find-shop/UPDATE";

const INIT_STATE = {};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case INIT:
      return INIT_STATE;
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// Action Creator
export function initFindShop() {
  return { type: INIT };
}

export function updateFindShop(payload) {
  return { type: UPDATE, payload };
}
