import React from "react";
// import Modal from '../../../components/modal/Modal';

import { Buttons, Message, ModalContentWrap, ModalWrap } from "./style";
import Text from "../../../components/text/Text";
import { numFormat } from "../../../libs/utils";
import Flex from "../../../components/flex/Flex";
import { Modal } from "@material-ui/core";
import ic_krw from "../../../assets/images/icon_krw.png";

function StorageFeePayModal({
  isVisible,
  onClose,
  totalFee,
  onConfirm,
  payType,
}) {
  return (
    <Modal
      open={isVisible}
      onClose={() => {
        onClose();
      }}>
      <ModalWrap>
        <ModalContentWrap>
          <div>
            <img src={ic_krw} alt="보관료 납부 아이콘" />
          </div>
          <Message>
            <Text
              style={{
                fontSize: 16,
                fontWeight: 700,
              }}>
              {payType && (
                <span>
                  {`${payType === "withdraw" ? "출금" : "인출"}하시려면`}
                  <br />
                </span>
              )}
              보관료{" "}
              <div
                style={{
                  display: "inline-block",
                  position: "relative",
                  color: "#ff6e0e",
                  fontSize: 18,
                  lineHeight: "25.5px",
                }}>
                <span>{`${numFormat(totalFee)}`}KRW</span>
                <div
                  style={{
                    backgroundColor: "#ff6e0e",
                    position: "absolute",
                    width: "100%",
                    height: "40%",
                    bottom: 2,
                    left: 0,
                    // zIndex: 14000,
                    opacity: 0.25,
                  }}
                />
              </div>
              를<br />
              {payType ? "납부해주세요." : "납부하시겠습니까?"}
            </Text>
            <br />
            <br />
            <Text
              style={{
                fontSize: "15px",
                color: "#666",
              }}>
              보유하신 KRW에서 자동납부됩니다.
            </Text>
          </Message>
          <Flex
            row
            style={{
              width: "100%",
              padding: "20px 20px 0",
            }}>
            <Buttons
              onClick={() => {
                // onCloseConfirmModal();
                onClose();
              }}>
              취소
            </Buttons>
            <div style={{ width: 16 }} />
            <Buttons
              isConfirm={true}
              onClick={() => {
                onConfirm();
              }}>
              납부하기
            </Buttons>
          </Flex>
        </ModalContentWrap>
      </ModalWrap>
    </Modal>
  );
}

export default StorageFeePayModal;
