import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import MainLayout from "../../layouts/main/MainLayout";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Form from "./Form";
import Header from "./Header";
import History from "./History";
import List from "./List";
import Tabs from "./Tabs";
import { Redirect, useHistory, useLocation } from "react-router";
import routes, { ROLE } from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { requestNativeLogin } from "../../libs/utils";

export default function Trading({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);

  const locationState = location.state || {};
  const { type = "gold" } = locationState;
  const typehigh = type.toUpperCase();

  const index = type === "gold" ? 0 : 1;

  const [price, setPrice] = useState("");
  const [method, setmethod] = useState(location?.state?.method || "");
  const [menu, setMenu] = useState(location?.state?.menu || "order");
  const [trade, settrade] = useState([{ orderPrice: 0, remainGram: 0 }]);
  const [feeRate, setFeeRate] = useState([]);

  // 하한가
  const [minPrice, setMinPrice] = useState(0);
  // 상한가
  const [maxPrice, setMaxPrice] = useState(0);

  const initFeeRate = async () => {
    // 수수료율 조회

    const feeRate = [
      { configType: "BUY", assetType: "GOLD", feeRate: 0.02, priceLimit: 0 },
      { configType: "SELL", assetType: "GOLD", feeRate: 0.015, priceLimit: 0 },
      { configType: "BUY", assetType: "SILVER", feeRate: 0.025, priceLimit: 0 },
      {
        configType: "SELL",
        assetType: "SILVER",
        feeRate: 0.025,
        priceLimit: 0,
      },
    ];

    const assetTypes = ["GOLD", "SILVER"];
    const configTypes = ["BUY", "SELL"];

    for (let configType of configTypes) {
      for (let assetType of assetTypes) {
        await APIS.getBuySellPolicy(configType, assetType).then(
          ({ data: { success, data } }) => {
            if (success) {
              let obj = feeRate.find(
                ({
                  configType: defaultConfigType,
                  assetType: defaultAssetType,
                }) =>
                  defaultConfigType === configType &&
                  defaultAssetType === assetType
              );
              obj.feeRate = data.userFeeRate / 100;
              obj.priceLimit = data.priceLimit;
              // console.log(obj);
            }
          }
        );
      }
    }
    let buyPrice = feeRate.find(
      (item) => item.configType === "BUY" && item.assetType === typehigh
    )?.priceLimit;
    let sellPrice = feeRate.find(
      (item) => item.configType === "SELL" && item.assetType === typehigh
    )?.priceLimit;
    setMinPrice(buyPrice);
    setMaxPrice(sellPrice);
    // console.log(feeRate);

    setFeeRate(feeRate);
  };

  useEffect(() => {
    initFeeRate();
  }, []);

  useEffect(() => {
    setMenu("order");
  }, [index]);

  function setIndexFunc(price, method) {
    setPrice(price);
    //setmethod(method);
  }

  const currentchFunc = (dataH) => {
    settrade(dataH);
  };

  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    setContentHeight(contentRef.current.offsetHeight);
  }, []);

  // 로그아웃 상태 -> 로그인 필요 팝업
  // SNS 로그인 상태 -> 본인인증 페이지 이동
  const authBlock = () => {
    if (auth?.role?.LEVEL < ROLE.ROLE_TEMP.LEVEL) {
      // 로그아웃 상태
      dispatch(
        actionShow(
          "로그인 후 이용 가능합니다",
          ["닫기", "로그인"],
          [null, () => requestNativeLogin(history)]
        )
      );
      return false;
    } else if (auth?.role?.LEVEL < ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      // SNS 로그인 상태
      history.push(routes.selfVerification, { purpose: "SIGNUP" });
      return false;
    }
    return true;
  };

  // 하한가(매수), 상한가(매도) 설정
  const getMinMax = () => {
    let buyPrice = feeRate.find(
      (item) => item.configType === "BUY" && item.assetType === typehigh
    )?.priceLimit;
    let sellPrice = feeRate.find(
      (item) => item.configType === "SELL" && item.assetType === typehigh
    )?.priceLimit;
    setMinPrice(buyPrice);
    setMaxPrice(sellPrice);
  };

  return (
    <MainLayout
      white
      topbar={
        <Tabs
          index={index}
          onIndexChange={(index) => {
            history.replace(location.pathname, {
              ...locationState,
              type: index === 0 ? "gold" : "silver",
            });
          }}
        />
      }
      bottomSize="160">
      <Header settype={typehigh} trade={trade} />
      <Flex row>
        <ButtonBase
          onClick={() => {
            setMenu("order");
          }}
          className={
            classes.value + " " + (menu === "order" && classes.valueSel)
          }>
          주문
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            if (authBlock()) {
              setMenu("history");
            }
          }}
          className={
            classes.value + " " + (menu === "history" && classes.valueSel)
          }>
          진행중
        </ButtonBase>
      </Flex>
      <Flex
        key={index.toString()}
        row
        className={classes.content}
        innerRef={contentRef}>
        {menu === "order" ? (
          <>
            <Flex style={{ flex: 2, height: contentHeight }}>
              <Flex
                style={{
                  height: 30,
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  color: "#999999",
                  borderBottom: "1px solid white",
                }}>
                <Flex
                  style={{
                    flex: 3,
                    textAlign: "center",
                    fontSize: 12,
                    // borderRight: "1px solid white",
                  }}>
                  가격(원)
                </Flex>
                <Flex style={{ flex: 2, textAlign: "center", fontSize: 12 }}>
                  중량(g)
                </Flex>
              </Flex>
              {!!contentHeight && (
                <List
                  settype={typehigh}
                  onpriceChange={setIndexFunc}
                  currentchFunc={currentchFunc}
                  height={contentHeight - 30}
                  minPrice={minPrice}
                  maxPrice={maxPrice}
                />
              )}
            </Flex>
            <Form
              settype={typehigh}
              onChangePrice={setIndexFunc}
              price={price}
              method={method}
              feeRates={feeRate}
              authBlock={authBlock}
              getMinMax={getMinMax}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
          </>
        ) : (
          <History settype={typehigh} type={type} />
        )}
      </Flex>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  button: { flex: 1 },
  value: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    flex: 1,
    color: "#999999",
    fontSize: "14px",
    backgroundColor: "#eeeeee",
    padding: "10px",
    fontFamily: fonts.notoSansKRMedium,
  },
  valueSel: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  content: {
    alignItems: "stretch",
    flex: 1,
  },
});
