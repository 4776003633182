import React from "react";

function ReviewSummary() {
  return (
    <div>
      <div className="vreview-summary-widget"></div>
    </div>
  );
}

export default ReviewSummary;
