import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ic_close from "../../assets/images/ic_close.png";
import ic_del from "../../assets/images/ic_del.png";
import useCryptoKeyboard from "../../hooks/useCryptoKeyboard";
import usePlainKeyboard from "../../hooks/usePlainKeyboard";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";

const SecureKeypad = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef(null);
  const locationState = history.location.state || {};
  const { type, payload, buttonText = "결제하기" } = locationState;
  const [keyArr, setKeyArr] = useState([]);

  const auth = useSelector((state) => state.auth);
  const userVersion = auth.version;

  const [secured, secureActions] =
    userVersion === "V1" ? usePlainKeyboard() : useCryptoKeyboard();

  useLayoutEffect(() => {
    shuffle();
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  const isClick = useRef(false);

  const handleClickPayment = () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(loadingStart);

      if (type === "SAFE_TRADE_BUY") {
        // 안심직거래 구매 결제요청
        APIS.postSafeTradeBuyPayment({ ...payload, password: secured.result })
          .then(({ data: { success, message } }) => {
            if (success) {
              dispatch(toastShow("결제 요청이 완료되었습니다."));
              history.push(routes.main);
              history.push(routes.mySafeTradeList);
            } else {
              dispatch(actionError(message));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            isClick.current = false;
          });
      } else if (type === "GOODS_WITHDRAWAL") {
        // 실물인출 결제
        const { id } = payload;
        const param = { ...payload };
        delete param.id;
        param.finPassword = secured.result;

        APIS.putgoodwithdrawalOrder(param, id)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                messageShow("결제가 완료되었습니다.", () => {
                  history.push(routes.main);
                  history.push(routes.physicalWithDrawal);
                })
              );
            } else {
              dispatch(messageShow(message));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            isClick.current = false;
          });
      } else if (type === "WITHDRAWAL") {
        // 출금요청

        const param = { ...payload };
        param.securityPasswd = secured.result;

        APIS.withdrawalSend(param)
          .then(({ data: { success, data, message } }) => {
            if (success) {
              dispatch(
                messageShow("출금 요청이 완료되었습니다.", () =>
                  history.push(routes.main)
                )
              );
            } else {
              dispatch(
                messageError(message, () => {
                  if (data === "E_0087") {
                    history.goBack();
                  }
                })
              );
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            isClick.current = false;
          });
      } else if (type === "TRADE") {
        const param = { ...payload };
        param.password = secured.result;

        APIS.postRequestTrade(param)
          .then(({ data: { success, message } }) => {
            if (success) {
              dispatch(toastShow("주문이 완료 되었습니다."));
              history.goBack();
            } else {
              dispatch(actionError(message));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            isClick.current = false;
          });
      } else if (type === "STORAGE_FEE") {
        // console.log(payload);
        APIS.payStorageFee({
          ...payload,
          finPassword: secured.result,
        })
          .then(({ data: { success, message } }) => {
            // console.log(message, success);
            if (success) {
              dispatch(toastShow("보관료 납부가 완료되었습니다."));
              history.push(routes.main);
            } else {
              dispatch(actionError(message));
              secureActions.clearAll();
            }
          })
          .finally(() => {
            dispatch(loadingEnd);
            isClick.current = false;
          });
      }
    }
  };

  return (
    <div className="password_wrap" style={{ flex: 1 }}>
      <div className="password_view_wrap" style={{ flex: 1 }}>
        <div className="password_view">
          <img
            src={ic_close}
            onClick={() => {
              history.goBack();
            }}
          />
          <p>결제 비밀번호</p>
          <div className="password_num">
            <ul>
              {Array.from({ length: 6 }).map((_, i) => (
                <li
                  className={secured.count > i ? "on" : ""}
                  key={i.toString()}
                />
              ))}
            </ul>
          </div>
          <button
            className={`password_btn ${secured.count === 6 ? "on" : ""}`}
            onClick={() => {
              if (secured.count === 6) {
                handleClickPayment();
              }
            }}>
            {buttonText}
          </button>
          {/* <!--on일때(숫자 6자리 다 눌렸을 때) 배경 오렌지색 글씨 하얀색--> */}
        </div>
      </div>
      <div className="password_key_wrap" style={{ flex: 0.37 }}>
        <div className="password_key">
          {keyArr.map((row, i) => {
            return (
              <ul key={i.toString()}>
                {i === 3 && (
                  <li
                    className="ft15"
                    onClick={() => {
                      secureActions.clearAll();
                    }}>
                    전체 삭제
                  </li>
                )}

                {row.map((key, j) => {
                  return (
                    <li
                      key={j.toString()}
                      onClick={() => {
                        secureActions.pressKey(key);
                      }}>
                      {key}
                    </li>
                  );
                })}

                {i === 3 && (
                  <li
                    onClick={() => {
                      secureActions.removeOne();
                    }}>
                    <img src={ic_del} alt="삭제" />
                  </li>
                )}
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SecureKeypad;
