import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";

export default function ProductBasicInformation({ data }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root}>
      <table className={classes.table}>
        {data.map((x, i) => {
          return (
            <tr key={i.toString()}>
              <td>{x.label} </td>
              <td colSpan="4">{x.text}</td>
            </tr>
          );
        })}
      </table>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    flex: 1,
    padding: "30px",
  },
  table: {
    height: "auto",
    borderCollapse: "collapse",
    borderBottom: "1px solid #ddd",
    "& tr": {
      border: "none",
      "& td": {
        padding: "9px 12px",
        color: "#666",
        fontSize: "14px",
        lineHeight: 1,
        fontFamily: fonts.notoSansKRRegular,
        border: "none",
        borderTop: "1px solid #ddd",
        "&:first-child": { backgroundColor: "#f1f1f1" },
        "&:last-child": {
          color: "#000",
        },
      },
    },
  },
});
