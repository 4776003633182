import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import fonts from "../../../../libs/fonts";
import tradeBuy from "../../../../assets/images/trade_buy.webp";
import tradeSell from "../../../../assets/images/trade_sell.webp";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import Span from "../../../../components/text/Span";
import { formatTime, numberWithCommas } from "../../../../libs/utils";
import { useDispatch } from "react-redux";
import PurchaseCancelPopup from "../../../trading/PurchaseCancelPopup";

const TradeItem = ({
  requestAt,
  tradeType,
  assetType,
  requestGram,
  contractGram,
  requestPrice,
  requestId,
  onCancel,
  ...rest
}) => {
  const percent = (contractGram / requestGram) * 100;

  const isBuy = tradeType === "BUY";
  const isGold = assetType === "GOLD";
  // const [cancelPopup, setCancelPopup] = useState({ open: false });
  return (
    <Container>
      <Header>
        <GraphIcon percent={percent} />
        <StatusLabel>{isBuy ? "구매" : "판매"} 진행</StatusLabel>
        <TimeLabel>{formatTime(requestAt, "YYYY.MM.DD HH:mm")}</TimeLabel>
      </Header>
      <SpaceBetween>
        <ItemType>
          <img src={isBuy ? tradeBuy : tradeSell} width={18} />{" "}
          {isBuy ? "매수" : "매도"}
        </ItemType>
        <Span
          size={13}
          color="#999999"
          style={{
            borderBottom: "1px solid #999999",
            cursor: "pointer",
            alignSelf: "center",
          }}
          onClick={onCancel}
        >
          취소
        </Span>
      </SpaceBetween>

      <SpaceBetween style={{ alignItems: "flex-end" }}>
        <Span font={fonts.notoSansKRBold} size={17} spacing={-0.34}>
          {isGold ? "금" : "은"}
        </Span>
        <Span font={fonts.notoSansKRMedium} size={18} spacing={-0.36}>
          <Span font={fonts.spoqa} weight="bold">
            {numberWithCommas(requestGram)}
          </Span>
          g
        </Span>
      </SpaceBetween>
      <SpaceBetween style={{ alignItems: "flex-end", marginTop: 16 }}>
        <Span size={14} spacing={-0.28} color="#666666">
          체결중량
        </Span>
        <Span size={14} spacing={-0.3} color="#666666">
          <Span font={fonts.spoqa} weight="bold" size={15}>
            {numberWithCommas(contractGram)}
          </Span>
          &nbsp;g
        </Span>
      </SpaceBetween>
      <SpaceBetween style={{ alignItems: "flex-end", marginTop: 4 }}>
        <Span size={14} spacing={-0.28} color="#666666">
          미체결중량
        </Span>
        <Span size={14} spacing={-0.3} color="#666666">
          <Span font={fonts.spoqa} weight="bold" size={15}>
            {numberWithCommas(+(requestGram - contractGram).toFixed(10))}
          </Span>
          &nbsp;g
        </Span>
      </SpaceBetween>

      <SpaceBetween style={{ alignItems: "flex-end", margin: "16px 0" }}>
        <Span size={16} spacing={-0.32} color="#222222">
          주문 가격
        </Span>
        <Span size={16} spacing={-0.32} color="#222222">
          <Span font={fonts.spoqa} weight="bold" size={18}>
            {numberWithCommas(requestPrice)}
          </Span>
          원
        </Span>
      </SpaceBetween>
    </Container>
  );
};

const Container = styled(Flex)`
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Header = styled(Flex)`
  flex-direction: row;
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;

const StatusLabel = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #e76e26;
`;
const TimeLabel = styled(Flex)`
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid #999999;
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #666666;
`;

const GraphIcon = styled(Flex)`
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: conic-gradient(
    ${({ color }) => color || "#ff6e0e"} 0% ${({ percent }) => percent}%,
    #bdbdbd ${({ percent }) => percent}% 100%
  );
  align-items: center;
  justify-content: center;

  &::before {
    width: 50%;
    height: 50%;
    background: white;
    border-radius: 50%;
    content: "";
  }
`;

const ItemType = styled(Flex)`
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #222222;
  padding: 14px 0 8px 0;
`;

const GramWrap = styled(Flex)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 7px 0 15px 0;
`;

export default TradeItem;
