import { messageActionType } from "./MessageActions";

const initMessage = {
  open: false,
  message: "",
  onPress: null,
};

export default function message(state = initMessage, action) {
  switch (action.type) {
    case messageActionType.show:
      return {
        ...state,
        ...action,
        open: true,
      };
    case messageActionType.hide:
      return {
        ...initMessage,
      };
    default:
      return state;
  }
}
