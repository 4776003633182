import { listActionType } from "./ListActions";

const initList = {
  open: false,
  title: "",
  data: [],
  onPress: null,
};

export default function list(state = initList, action) {
  switch (action.type) {
    case listActionType.show:
      return {
        ...state,
        ...action,
        open: true,
      };
    case listActionType.hide:
      return {
        ...initList,
      };
    default:
      return state;
  }
}
