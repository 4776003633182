import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function CheckboxCircle({
  className,
  labelSize,
  checked,
  onCheckedChange,
  label,
  small,
}) {
  const classes = useStyle();
  return (
    <ButtonBase
      onClick={() => {
        onCheckedChange(!checked);
      }}
      className={`${classes.root} ${className}`}
      row
    >
      {checked ? (
        <img
          alt="check"
          style={{
            ...(small && { width: 16, height: 16 }),
          }}
          src={images.checkcircleon}
          className={classes.checkbox}
        />
      ) : (
        <span
          style={{
            width: 23,
            height: 23,
            borderRadius: 100,
            border: "1px solid #cccccc",

            ...(small && { width: 16, height: 16 }),
          }}
        />
      )}

      <Text
        style={{ ...(labelSize && { fontSize: labelSize }) }}
        font={fonts.notoSansKRMedium}
        className={classes.check + " " + (small && classes.checkSmall)}
      >
        {label}
      </Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: { alignItems: "center", display: "flex", alignSelf: "flex-start" },
  check: {
    color: colors.text,
    fontSize: 18,
    color: colors.text,
    marginLeft: 8,
  },
  checkbox: {
    width: 24,
    height: 24,
  },
  checkSmall: {
    fontSize: "12px",
  },
});
