import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";

export default function Label({ top, children }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex
      className={classes.root}
      style={{
        borderTop: top ? "" : "1px solid #eee",
      }}
    >
      <Text className={classes.text}>{children}</Text>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    padding: "13px",
    borderBottom: "1px solid #eee",
  },
  text: {
    color: "#999",
    fontSize: "14px",
  },
});
