import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import Flex from "../flex/Flex";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // css import
import { calcDate, formatTime } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { makeStyles } from "@material-ui/core";
import cal_next from "../../assets/images/cal_next.png";
import cal_before from "../../assets/images/cal_before.png";
import ed4_bk from "../../assets/images/ed4_bk.png";

const Calendar = ({
  value,
  month: initMonth,
  onChange,
  onMonthChange,
  holidays = [],
  minDate,
  maxDate,
  useShop = false,
}) => {
  const [month, setMonth] = useState(initMonth);

  useEffect(() => {
    handleChangeMonth(initMonth);
  }, [initMonth]);

  useEffect(() => {
    if (
      minDate &&
      formatTime(minDate, "YYYY-MM") > formatTime(month, "YYYY-MM")
    ) {
      handleChangeMonth(minDate);
    }
  }, [minDate]);

  useEffect(() => {
    if (value) {
      if (
        (minDate && formatTime(value) < formatTime(minDate)) ||
        (maxDate && formatTime(value) > formatTime(maxDate))
      ) {
        handleChangeValue(null);
      }
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    if (value && holidays.includes(formatTime(value))) {
      handleChangeValue(null);
    }
  }, [holidays]);

  const handleChangeMonth = (month) => {
    setMonth(month);
    onMonthChange && onMonthChange(month);
  };

  const handleChangeValue = (value) => {
    onChange && onChange(value);
  };

  function generate() {
    const today = moment(month);
    const startWeek = today.clone().startOf("month").week();
    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();
    let calendar = [];
    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <ul className="date">
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .startOf("year")
                .week(week)
                .startOf("week")
                .add(n + i, "day");

              const dateString = formatTime(current);

              const outOfRange =
                (minDate && formatTime(minDate) > dateString) ||
                (maxDate && formatTime(maxDate) < dateString);

              const isOtherMonth = current.format("M") !== today.format("M");

              const isChecked =
                value && !isOtherMonth && formatTime(value) === dateString;

              const isDisabled = holidays.includes(dateString) || outOfRange;

              return (
                <li
                  key={i}
                  className={`${isChecked ? "checked" : ""} ${
                    isDisabled ? "disabled" : ""
                  }`}
                  onClick={() => {
                    !isOtherMonth &&
                      !isDisabled &&
                      handleChangeValue(new Date(current));
                  }}>
                  {!isOtherMonth && current.format("D")}
                </li>
              );
            })}
        </ul>
      );
    }
    return calendar;
  }

  const getMonth = (x) => {
    return moment(x).startOf("month");
  };

  return (
    <div className="calendar_wrap" style={{ padding: 0 }}>
      <div className="date_select">
        <div className="select_cal">
          <div className="year_month">
            {useShop ? (
              getMonth(minDate).isBefore(getMonth(month)) && (
                <div
                  className="before"
                  onClick={() => {
                    handleChangeMonth(calcDate("M", -1, month));
                  }}>
                  <img src={cal_before} alt=">" />
                </div>
              )
            ) : (
              <div
                className="before"
                onClick={() => {
                  handleChangeMonth(calcDate("M", -1, month));
                }}>
                <img src={cal_before} alt=">" />
              </div>
            )}
            <table className="num_tb tb1">
              <tr>
                {formatTime(month, "YYYY")
                  .split("")
                  .map((c, i) => (
                    <td key={i} className="spoqa">
                      <p>{c}</p>
                    </td>
                  ))}
                <td>년</td>
              </tr>
            </table>
            <table className="num_tb tb2">
              <tr>
                {formatTime(month, "MM")
                  .split("")
                  .map((c, i) => (
                    <td key={i} className="spoqa">
                      <p>{c}</p>
                    </td>
                  ))}
                <td>월</td>
              </tr>
            </table>
            {useShop ? (
              getMonth(month).isBefore(getMonth(maxDate)) && (
                <div
                  className="next"
                  onClick={() => {
                    handleChangeMonth(calcDate("M", 1, month));
                  }}>
                  <img src={cal_next} alt=">" />
                </div>
              )
            ) : (
              <div
                className="next"
                onClick={() => {
                  handleChangeMonth(calcDate("M", 1, month));
                }}>
                <img src={cal_next} alt=">" />
              </div>
            )}
          </div>
          <div className="calendar" style={{ width: "100%" }}>
            <ul className="week">
              <li>일</li>
              <li>월</li>
              <li>화</li>
              <li>수</li>
              <li>목</li>
              <li>금</li>
              <li>토</li>
            </ul>
            {generate()}
          </div>
        </div>
      </div>
    </div>
  );
};

const Calendar2 = ({
  shopId,
  value = new Date(),
  onChange = () => {},
  minDate = new Date(),
}) => {
  const classes = useStyle();
  const [selectMonth, setSelectMonth] = useState(formatTime(value, "YYYY-MM"));
  const [workingDays, setWorkingDays] = useState({});

  const getLastDay = (date) => {
    const copy = new Date(date);
    copy.setMonth(copy.getMonth() + 1);
    copy.setDate(0);
    return copy.getDate();
  };

  useEffect(() => {
    APIS.getShopHours(shopId, selectMonth).then(
      ({ data: { success, message, data } }) => {
        if (success) {
          const { businessHoursList = [], holidaysList = [] } = data;
          const businessList = {};
          const resList = {};

          businessHoursList.forEach(
            ({ dayWeek, isFullDayOff, openHr, closeHr }) => {
              businessList[dayWeek] = {
                isFullDayOff,
                open: openHr,
                close: closeHr,
              };
            }
          );

          for (let i = 1; i <= getLastDay(selectMonth); i++) {
            const current = new Date(selectMonth);
            current.setDate(i);

            const currentDay = current.getDay();
            resList[formatTime(current)] = { ...businessList[currentDay] };
          }

          holidaysList.forEach(
            ({ manageDate, isFullDayOff, openHr, closeHr }) => {
              resList[manageDate] = {
                isFullDayOff,
                open: openHr,
                close: closeHr,
              };
            }
          );
          setWorkingDays(resList);

          // 초기 load 시점에 isFullDayOff 값을 전달해주기 위함
          if (selectMonth === formatTime(value, "YYYY-MM")) {
            const selectedSchedule = resList[formatTime(value)];
            onChange(value, selectedSchedule?.isFullDayOff);
          }
        }
      }
    );
  }, [selectMonth]);

  useEffect(() => {
    if (minDate) {
      if (value < minDate) {
        handleChangeDate(minDate);
        setSelectMonth(formatTime(minDate, "YYYY-MM"));
      }
    }
  }, [minDate]);

  const getWorkingTimeText = () => {
    const selectedSchedule = workingDays[formatTime(value)];

    if (selectedSchedule) {
      const { isFullDayOff, open, close } = selectedSchedule;
      if (isFullDayOff) {
        return (
          <span>
            [{formatTime(value, "YYYY년 MM월 DD일")}] 은{" "}
            <span style={{ color: "red" }}>휴무</span>
            입니다.
          </span>
        );
      } else if (open && close) {
        return (
          <span>
            [{formatTime(value, "YYYY년 MM월 DD일")}]<br />
            영업시간은{" "}
            <span style={{ color: "red" }}>
              {selectedSchedule.open} ~ {selectedSchedule.close}
            </span>{" "}
            입니다.
          </span>
        );
      } else {
        return "";
      }
    }
  };

  const handleChangeDate = (date) => {
    const selectedSchedule = workingDays[formatTime(date)];
    onChange(date, selectedSchedule?.isFullDayOff);
  };
  return (
    <div>
      <ReactCalendar
        className={classes.calendar}
        calendarType="US"
        formatDay={(_, date) => formatTime(date, "D")}
        minDate={minDate}
        value={value}
        onChange={handleChangeDate}
        showNeighboringMonth={false}
        onActiveStartDateChange={({ activeStartDate }) => {
          setSelectMonth(formatTime(activeStartDate, "YYYY-MM"));
        }}
        maxDate={calcDate("M", 1)}
        tileClassName={({ date, view }) => {
          if (view === "month") {
            const schedule = workingDays[formatTime(date)];
            if (schedule?.isFullDayOff) {
              return "day-off";
            }
          }
          return "";
        }}
        // tileContent={({ date, view }) => {
        //   console.log({ date, view });
        //   if (view === "month") {
        //     return <abbr style={{ color: "black" }}>2</abbr>;
        //   } else {
        //     return <></>;
        //   }
        // }}
      />
      <span>{getWorkingTimeText()}</span>
    </div>
  );
};

const useStyle = makeStyles({
  calendar: {
    "& .react-calendar__tile:disabled": {
      color: "rgba(16, 16, 16, 0.3)",
    },
    "& .react-calendar__tile:enabled": {
      color: "black",
    },
    "& .react-calendar__tile.day-off:enabled": {
      color: "red",
    },
    "& .react-calendar__tile--active:enabled": {
      color: "white !important",
    },
  },
});

export default Calendar;
