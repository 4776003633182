import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import ProductDirectTransactionItem from "./ProductDirectTranscationItem";
import ProductListItem from "./ProductListItem";
import ProductListItemPhysical from "./ProductListItemPhysical";

export default function ProductList({
  data = [],
  productType,
  headerComponent,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const ProductComponent =
    productType === "physical-withdrawal"
      ? ProductListItemPhysical
      : productType === "direct-transaction"
      ? ProductDirectTransactionItem
      : ProductListItem;

  return (
    <Flex className={classes.root}>
      {headerComponent ? (
        <Flex className={classes.header}>{headerComponent}</Flex>
      ) : (
        <Text className={classes.label}>
          <Text font={fonts.notoSansKRMedium}>{data.length}</Text>
          개의 제품을 교환 신청합니다.
        </Text>
      )}

      <Flex className={classes.list}>
        {data.map((x, i) => {
          return (
            <ProductComponent
              {...x}
              key={i.toString()}
              bottom={data.length === i + 1}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "14px",
    color: colors.text,
    margin: "20px 14px",
  },
  list: {
    borderTop: "1px solid #eee",
  },
  root: {
    paddingBottom: "20px",
    borderBottom: "3px solid #eee",
  },
  header: {
    margin: "20px 14px",
  },
});
