import { makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionHide } from "../../redux/action/ActionActions";

export default function ConfirmPopup({ onClose }) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <Text className={classes.message}>
          정말로
          <Text font={fonts.notoSansKRBold}> 탈퇴</Text>
          하시겠습니까?
          <br />
          <Text>탈퇴 후 7일간 가입하실 수 없습니다.</Text>
        </Text>
        <Flex row style={{ alignSelf: "stretch" }}>
          <Button
            onClick={() => {
              history.push(routes.exitReason);
            }}
            className={classes.button}
            label={"회원탈퇴"}
          />
          <Button onClick={onClose} className={classes.button} label={"취소"} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  message: {
    color: colors.text,
    fontSize: "16px",
    margin: "20px 0px",
    textAlign: "center",
    "& span": {
      color: colors.textRed,
      "&:last-child": {
        fontSize: "14px",
        color: colors.gray,
      },
    },
  },
  button: {
    alignSelf: "center",
    padding: "10px",
    flex: 1,
    marginTop: "30px",
    "&:last-child": {
      backgroundColor: "#666666",
    },
    "&:first-child": {
      marginRight: "16px",
    },
  },
});
