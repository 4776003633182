import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import info_img from "../../assets/images/info_img.png";
import ic_close from "../../assets/images/ic_close.png";
import ic_place_bk from "../../assets/images/ic_place_bk.png";
import ic_price from "../../assets/images/ic_price.png";
import ic_phone from "../../assets/images/ic_phone.png";
import ic_noti from "../../assets/images/ic_noti.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import consts from "../../libs/consts";
import {
  callPhone,
  encodeFileToBase64,
  formatTime,
  numberWithCommas,
  calcDate,
} from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import {
  initSafeTradeOrder,
  updateSafeTradeOrder,
} from "../../redux/safe-trade/safeTradeOrder";
import { initFindShop } from "../../redux/common/findShop";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import { Swiper, SwiperSlide } from "swiper/react";
import TimeRangeSelector from "../../components/common/TimeRangeSelector";
import DatePicker from "react-datepicker";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { E_001, I_001, CALENDAR_ALERT } from "../../libs/messages";
import colors from "../../libs/colors";
import Calendar from "../../components/common/Calendar";
import VisitReservation from "../../components/common/VisitReservation";

export default function SafeTradeBuy({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const itemId = state?.id;
  const findShop = useSelector((state) => state.findShop);
  const { shop, ordered_at, reservation } = state;
  const [isFullDayOff, setIsFullDayOff] = useState("");

  const [acceptableKrw, setAcceptableKrw] = useState(0);

  // 상품 정보
  const [item, setItem] = useState({});

  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  useEffect(() => {
    if (!itemId) {
      history.goBack();
      return false;
    }

    init();
  }, []);

  const init = async () => {
    dispatch(loadingStart);

    const getItem = await APIS.getSafeTradeItem(itemId).then(
      ({ data: { success, data } }) => {
        success && setItem(data);
        return data;
      }
    );

    const getAsset = APIS.getUserHoldingsInfo().then(
      ({ data: { success, data } }) => {
        success && setAcceptableKrw(data?.acceptableKrw);

        const totalPrice = getItem.price + getItem.fee;
        if (data?.acceptableKrw < totalPrice) {
          dispatch(
            actionShow(
              "사용가능 원화가 부족합니다.",
              ["닫기", "입금하기"],
              [
                () => {},
                () => {
                  history.push(routes.depositWithdrawal);
                },
              ]
            )
          );
          history.goBack();
        }
      }
    );

    Promise.all([getItem, getAsset]).finally(() => dispatch(loadingEnd));
  };

  const handleChangeValue = (key, value) => {
    history.replace(routes.safeTradeBuy, { ...state, [key]: value });
  };

  const handleClickPayment = () => {
    if (!shop?.id) {
      dispatch(messageError("방문 매장을 선택해주세요."));
      return false;
    }
    if (!reservation?.date) {
      dispatch(messageError("방문 일자를 선택해주세요."));
      return false;
    }
    // if (!reservation_time) {
    //   dispatch(actionError("매장 방문 시간을 선택하세요."));
    //   return false;
    // }

    if (acceptableKrw < totalPrice) {
      dispatch(
        actionShow(
          "사용가능 원화가 부족합니다.",
          ["닫기", "입금하기"],
          [
            () => {},
            () => {
              history.push(routes.depositWithdrawal);
            },
          ]
        )
      );
    } else {
      setPaymentModalVisible(true);
    }
  };

  const { image, brand, name, price, fee } = item;
  const canNext = shop?.id && reservation?.date;
  const totalPrice = +price + +fee;
  return (
    <BasicLayout stackTopbar={{ title: "주문 상세" }}>
      <div className="store_visit_top">
        <h3>
          해당 상품은 <span>원화(KRW)</span>로만 <span>결제</span> 가능합니다.
        </h3>
      </div>
      <div className="store_visit_top_sub">
        <img src={ic_price} alt="" />
        <p>
          사용 가능 금액{" "}
          <span className="spoqa">{numberWithCommas(acceptableKrw)}</span>원
        </p>
      </div>
      <div className="order_wrap">
        <div className="store_visit">
          <div className="store_visit_pd">
            <div className="visit_pd_left">
              <img src={`${consts.s3BaseUrl}/${image}`} />
            </div>
            <div className="visit_pd_right">
              <table>
                <tr>
                  <td className="ft14">
                    <h3>{brand}</h3>
                  </td>
                  <td className="ft10 clgr">
                    <span className="spoqa">판매번호: {itemId}</span>
                  </td>
                </tr>
                <tr className="bdbottom">
                  <td className="ft14">{name}</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="ft10">거래 가격</td>
                  <td className="ft11">
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </td>
                </tr>
                <tr>
                  <td className="ft10">구매 수수료</td>
                  <td className="ft11">
                    <span className="spoqa">{numberWithCommas(fee)}</span>원
                  </td>
                </tr>
                <tr>
                  <td className="ft12">구매 금액</td>
                  <td className="ft13">
                    <h3 className="spoqa">{numberWithCommas(totalPrice)}</h3>원
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <VisitReservation
          description="상품 수령을 위해 방문하실 매장을 선택해 주세요."
          minDate={calcDate("D", 5)}
        />
      </div>
      <div
        className={`ok_btn ${canNext ? "on" : "off"}`}
        onClick={handleClickPayment}
        style={{ zIndex: 0 }}
      >
        결제하기
      </div>
      {/* <TimeRangeSelector
        visible={timeSelectorVisible}
        value={reservation_time}
        onChange={(value) => handleChangeValue("reservation_time", value)}
        onClose={() => setTimeSelectorVisible(false)}
      /> */}

      {paymentModalVisible && (
        <PaymentModal
          acceptableKrw={acceptableKrw}
          totalPrice={totalPrice}
          onClose={() => setPaymentModalVisible(false)}
          onConfirm={() => {
            setPaymentModalVisible(false);

            dispatch(loadingStart);
            APIS.postSafeTradeBuy(itemId, {
              shop_id: shop.id,
              reservation: reservation.date,
              // reservation_time,
            })
              .then(({ data: { success, message, data } }) => {
                if (success) {
                  history.push(routes.commonSecureKeypad, {
                    type: "SAFE_TRADE_BUY",
                    payload: { itemId, orderBookId: data.order_book_id },
                  });
                } else {
                  dispatch(actionError(message));
                }
              })
              .finally(() => dispatch(loadingEnd));
          }}
          orderedAt={ordered_at}
        />
      )}
    </BasicLayout>
  );
}

const PaymentModal = ({
  acceptableKrw = 0,
  totalPrice = 0,
  onClose,
  onConfirm,
  orderedAt,
}) => {
  let limitText = "30분 이내";
  if (orderedAt) {
    limitText = formatTime(
      calcDate("MI", 30, orderedAt),
      "YYYY-MM-DD HH:mm 까지"
    );
  }
  return (
    <div
      className="trade_wrap_bk"
      style={{ zIndex: 1, position: "fixed", top: 0 }}
    >
      <div className="pd_pay">
        <div className="pay_tit">
          <h3>결제하기</h3>
        </div>
        <div className="pay_list">
          <ul>
            <li>보유잔고</li>
            <li>
              <span className="spoqa">{numberWithCommas(acceptableKrw)}</span>원
            </li>
          </ul>
          <ul className="ulbd">
            <li>결제 금액</li>
            <li>
              <span className="spoqa">{numberWithCommas(totalPrice)}</span>원
            </li>
          </ul>
          <p>
            <img src={ic_noti} />
            {limitText} 결제하지 않으면 구매는 자동 취소됩니다.
          </p>
          <div className="pay_btn">
            <button className="cancel" onClick={onClose}>
              취소
            </button>
            <button className="ok" onClick={onConfirm}>
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  inputDate: {
    opacity: 0,
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    zIndex: 1,
  },
});
