import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { saveSignupStep } from "../../libs/sign";
import images from "../../libs/images";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import UsingShopDialog from "../../components/find-shop/UsingShopDialog";

export default function AuthRegisterShop() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [isVisible, setIsVisible] = useState(false);

  const { purpose, recommandShop } = useSelector((s) => s.signUp);

  const register = () => {
    history.push(routes.authRegisterEmail);
  };

  useEffect(() => {
    if (purpose === "SIGNUP") saveSignupStep("RECOMMEND_SHOP");
  }, []);

  const { id, name } = recommandShop;

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "추천 매장",
      }}>
      {/* <UsingShopDialog
        isVisible={isVisible}
        onCloseModal={() => {
          setIsVisible(false);
        }}
        purpose={"SIGNUP"}
      /> */}
      <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        {/* 금방금방을 가입 경로를 알려주세요. */}
        금방금방을 소개받았거나 향후 이용하게 될 매장을 선택해주세요.
      </Text>
      {/* <Button
        onClick={() => {
          setIsVisible(true);
        }}
        className={`${classes.button} ${
          id && id !== 1 ? classes.selected : ""
        }`}
        style={{
          marginBottom: "18px",
        }}
        label={
          <div className={classes.buttonLabel}>
            <div className={classes.labelIcon}>
              <img src={images.ic_store} alt="icon_store" />
            </div>
            <div>
              <span className={classes.title}>
                {id && id !== 1 ? name : "금방금방을 추천해 준 대리점이 있어요"}
              </span>
              <span className={classes.subTitle}>
                대리점 방문, 대리점 지인 추천, 대리점 광고/블로그 등
              </span>
            </div>
          </div>
        }
      />
      <Button
        onClick={() => {
          dispatch(
            updateSignUp({
              recommandShop: {
                id: 1,
                name: "본사",
              },
            })
          );
        }}
        className={`${classes.button}  ${
          id && id == 1 ? classes.selected : ""
        }`}
        label={
          <div className={classes.buttonLabel}>
            <div className={classes.labelIcon}>
              <img src={images.ic_more_up} alt="icon_more_up" />
            </div>
            <div>
              <span className={classes.title}>추천 대리점이 없어요.</span>
              <span className={classes.subTitle}>지인추천, 광고 등</span>
            </div>
          </div>
        }
      /> */}
      {/* <Text font={fonts.notoSansKRMedium} className={classes.label1}>
        추천 매장이 없으면 &quot;추천 매장 찾기&quot; 후<br />
        &quot;금방금방 본사&quot;를 선택해주세요
      </Text> */}

      {id && (
        <ShopItemSimple {...recommandShop} shopName={name} className="margin" />
      )}
      <Flex className={classes.bottom}>
        <Button
          onClick={() => history.push(routes.authFindShop)}
          label="추천 매장 찾기"
        />
        <Flex style={{ height: 10 }} />
        <Button
          onClick={register}
          label={"다음"}
          className={`${classes.button} ${!id ? classes.disabled : ""}`}
          disabled={!id}
          textStyle={{ color: !id ? "#c2c2c2" : "black" }}
          // className={`${classes.nextbtn} ${!id ? classes.disabled : ""}`}
        />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    // padding: "22px 23px 23px 23px",
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
    // marginBottom: "43px",
  },
  // back: {
  //   width: "16px",
  //   height: "16px",
  // },
  bottom: {
    position: "absolute",
    bottom: 50,
    left: 30,
    width: "calc(100% - 60px)",

    // marginLeft: 30,
    // marginRight: 30,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  nextbtn: {
    backgroundColor: "#FE8B3D",
    borderRadius: "8px",
    padding: "23px 0px 22px 0px",
  },
  button: { backgroundColor: "white", border: `1px solid ${colors.primary}` },
  disabled: { background: "#f4f4f4", border: "none" },
  // disabled: {
  //   backgroundColor: "white",
  //   border: `1px solid #8D939D`,
  // },
  // button: {
  //   backgroundColor: "white",
  //   border: "1px solid black",
  //   margin: "0 20px",
  //   justifyContent: "flex-start",
  //   borderRadius: "8px",
  //   padding: "11px 20px",
  //   height: "68px",
  // },
  selected: { border: "2px solid #FE8B3D" },
  buttonLabel: {
    color: "black",
    display: "flex",
    alignItems: "center",
  },
  labelIcon: {
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    display: "block",
    fontSize: "16px",
    fontWeight: "700",
    textAlign: "left",
    paddingBottom: "2px",
  },
  subTitle: {
    display: "block",
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
  },
});
