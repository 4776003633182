import React from "react";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import fonts from "../../../../../libs/fonts";
import { formatTime, numberWithCommas } from "../../../../../libs/utils";
import history_goods from "../../../../../assets/images/history_goods.webp";
import product_default from "../../../../../assets/images/product_default.webp";
import safe_trade_icon from "../../../../../assets/images/safe_trade_icon.webp";
import check_circle from "../../../../../assets/images/check_circle.webp";
import consts from "../../../../../libs/consts";

const SafeTradeItem = (props) => {
  const {
    requestAt,
    completedAt,
    tradeType,
    tradeTypeKor,
    assetType,
    tradeGram,
    pureTradeKrw,
    userFee,
    imagePath,
    tradeNumber,
    productName,
  } = props;

  return (
    <Container>
      <TopWrap>
        <img
          src={check_circle}
          width={16}
          height={16}
          style={{ marginRight: 4 }}
        />
        <Span size={14} spacing={-0.28} color="#e76e26">
          거래완료
        </Span>
        <Span
          font={fonts.spoqa}
          size={14}
          color="#666666"
          style={{
            marginLeft: 5,
            paddingLeft: 6,
            borderLeft: "1px solid #999999",
          }}
        >
          {formatTime(completedAt, "YYYY.MM.DD HH:mm")}
        </Span>
      </TopWrap>

      <TradeTypeWrap>
        <img src={safe_trade_icon} width={18} height={18} />
        {tradeTypeKor}
      </TradeTypeWrap>

      <ContentWrap>
        <ProductImage
          src={imagePath ? `${consts.s3BaseUrl}/${imagePath}` : product_default}
        />
        <Flex style={{ flex: 1 }}>
          <Span size={17} weight="bold">
            {productName}
          </Span>
          <Flex row>
            <InfoTitle>거래금액</InfoTitle>
            <InfoData font={fonts.spoqa} align="right">
              {numberWithCommas(pureTradeKrw)}원
            </InfoData>
          </Flex>
          <Flex row>
            <InfoTitle>수수료</InfoTitle>
            <InfoData font={fonts.spoqa} align="right">
              {numberWithCommas(userFee)}원
            </InfoData>
          </Flex>
        </Flex>
      </ContentWrap>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 15px 16px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
`;

const TopWrap = styled(Flex)`
  flex-direction: row;
  padding-bottom: 8.5px;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;
const TradeTypeWrap = styled(Flex)`
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;

  img {
    margin-right: 3px;
  }
`;

const ContentWrap = styled(Flex)`
  flex-direction: row;
  margin-top: 14px;
`;

const ProductImage = styled.img`
  width: 72px;
  height: 72px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  object-fit: contain;
  margin-right: 30px;
`;

const InfoTitle = styled(Span)`
  font-size: 15px;
  color: #666666;
  width: 70px;
`;

const InfoData = styled(Span)`
  flex: 1;
  font-size: 15px;
  spacing: -0.32px;
  color: #666666;

  ${({ align }) => (align ? `text-align:${align};` : "")}
`;

export default SafeTradeItem;
