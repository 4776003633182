import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch, useLocation } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import routes from "../../libs/routes";
import EventItem from "./EventItem";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { messageError } from "../../redux/message/MessageActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import StackTopbar from "../../layouts/basic/StackTopbar";
import Flex from "../../components/flex/Flex";
import { postMessage } from "../../libs/utils";

export default function Event({}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [tag, setTag] = useState("ongoing");
  const [openEventList, setOpenEventList] = useState([]);
  const [closedEventList, setClosedEventList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/v1/user/event",
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;
            setOpenEventList(data.openEventList);
            setClosedEventList(data.closedEventList);
          }
        }
      )
    );
  }, []);

  return (
    <BasicLayout white>
      <StackTopbar
        title="이벤트"
        border
        onGoBack={() => {
          history.replace(routes.menu);
        }}
        style={{ position: "fixed", width: "100%", zIndex: 10000 }}
      />
      <div style={{ height: "60px" }} />
      <div className="ft_tab_box">
        <div
          className={`ft_tab ${tag === "ongoing" && "on"}`}
          onClick={() => setTag("ongoing")}
        >
          진행중인 이벤트
        </div>
        <div
          className={`ft_tab ${tag === "closed" && "on"}`}
          onClick={() => setTag("closed")}
        >
          종료된 이벤트
        </div>
      </div>

      <div className="ft_wrap" style={{ paddingBottom: 30 }}>
        {tag === "ongoing" ? (
          openEventList.length ? (
            openEventList.map((item, index) => {
              return (
                <EventItem
                  key={index}
                  item={item}
                  type={"ongoing"}
                  onClick={() => {
                    postMessage({
                      command: "SET_HEADER",
                      param: {
                        visible: false,
                        goBack: "DEFAULT",
                        title: "이벤트",
                      },
                    });
                    window.location.href = item.content;
                  }}
                />
              );
            })
          ) : (
            <Flex style={{ marginTop: 20, flex: 1, alignItems: "center" }}>
              진행중인 이벤트가 없습니다.
            </Flex>
          )
        ) : closedEventList.length ? (
          closedEventList.map((item, index) => {
            return <EventItem key={index} item={item} type={"closed"} />;
          })
        ) : (
          <Flex style={{ marginTop: 20, flex: 1, alignItems: "center" }}>
            종료된 이벤트가 없습니다.
          </Flex>
        )}
      </div>
    </BasicLayout>
  );
}
