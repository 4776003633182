import React, { useEffect, useMemo, useState } from "react";
import { ButtonBase, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { escapeRegExp } from "lodash";
import { useHistory } from "react-router";

import * as APIS from "../../libs/apis";
import BottomModal from "../modal/BottomModal";
import images from "../../libs/images";
import ShopItemSelect from "../shop-item-select/ShopItemSelect";
import { getCurrentMyPosition, objToQueryStr } from "../../services/utils";
import InputText from "../input-text/InputText";
import SimpleTopBar from "../../layouts/basic/SimpleTopBar";
import Flex from "../flex/Flex";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import { userUpdate } from "../../redux/user/UserActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import Modal from "../modal/Modal";
import Text from "../text/Text";
import colors from "../../libs/colors";
import { getDistanceFromLatLonInKm } from "../../libs/utils";
import Button from "../button/Button";
import routes from "../../libs/routes";

function UsingShopDialog({ isVisible, onCloseModal, purpose }) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [shops, setShops] = useState([]);
  const [shopInfo, setShopInfo] = useState({
    shopId: 0,
    shopName: "",
  });
  const [confirmShop, setConfirmShop] = useState({
    visible: false,
    shopId: 0,
    shopName: "",
  });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const history = useHistory();
  const classes = useStyle();
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.user);

  const onChangeShop = () => {
    dispatch(loadingStart);
    APIS.updateUsingShop({
      using_shop: shopInfo.shopId,
    })
      .then(({ data: { success, data } }) => {
        if (success) {
          dispatch(
            userUpdate({
              ...userInfo,
              usingShopId: shopInfo.shopId,
              usingShopName: shopInfo.shopName,
            })
          );
          dispatch(toastShow("이용대리점이 변경되었습니다."));
          setSearchKeyword("");
          onCloseConfirmModal();
          onCloseModal();
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickShop = (shop) => {
    // if (purpose === "SIGNUP") {
    //   // dispatch(updateSignUp({ recommandShop: shop }));
    //   // setSearchKeyword("");
    //   // onCloseModal();
    // }
    setShopInfo({
      shopId: shop.id,
      shopName: shop.name,
    });
    if (purpose === "UPDATE_USING_SHOP") {
      setConfirmShop({
        visible: true,
      });
    }
  };

  const handleClickNext = () => {
    dispatch(
      updateSignUp({
        recommandShop: {
          id: shopInfo.shopId,
          name: shopInfo.shopName,
        },
      })
    );
    setSearchKeyword("");
    onCloseModal();
    history.push(routes.authRegisterEmail);
  };

  const onCloseConfirmModal = () => {
    setConfirmShop({
      ...confirmShop,
      visible: false,
    });
  };

  const mapDataToComponent = (shops) => {
    return shops.map((shop, i) => {
      const prevId = shopInfo.shopId;
      const { id, name, address, phoneNumber, distance, shopType } = shop;
      return (
        <ShopItemSelect
          key={`${i}`}
          list
          shopName={name}
          address={address}
          shopPhone={phoneNumber}
          distance={distance}
          onCheckedChange={() => handleClickShop(shop)}
          onClick={() => handleClickShop(shop)}
          checked={prevId === id}
          shopType={shopType}
        />
      );
    });
  };

  function ch2pattern(ch) {
    const offset = 44032; /* '가'의 코드 */
    // 한국어 음절
    if (/[가-힣]/.test(ch)) {
      const chCode = ch.charCodeAt(0) - offset;
      // 종성이 있으면 문자 그대로를 찾는다.
      if (chCode % 28 > 0) {
        return ch;
      }
      const begin = Math.floor(chCode / 28) * 28 + offset;
      const end = begin + 27;
      return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 한글 자음
    if (/[ㄱ-ㅎ]/.test(ch)) {
      const con2syl = {
        ㄱ: "가".charCodeAt(0),
        ㄲ: "까".charCodeAt(0),
        ㄴ: "나".charCodeAt(0),
        ㄷ: "다".charCodeAt(0),
        ㄸ: "따".charCodeAt(0),
        ㄹ: "라".charCodeAt(0),
        ㅁ: "마".charCodeAt(0),
        ㅂ: "바".charCodeAt(0),
        ㅃ: "빠".charCodeAt(0),
        ㅅ: "사".charCodeAt(0),
      };
      const begin =
        con2syl[ch] ||
        (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl["ㅅ"];
      const end = begin + 587;
      return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 그 외엔 그대로 내보냄
    // escapeRegExp는 lodash에서 가져옴
    return escapeRegExp(ch);
  }

  function createFuzzyMatcher(input) {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  }

  // const ShopList = useMemo(() => {
  //   const matchShops = shops.filter(({ name, address }) => {
  //     return createFuzzyMatcher(searchKeyword).test(name);
  //   });
  //   return mapDataToComponent(matchShops);
  // }, [shops, searchKeyword]);

  const init = async () => {
    const shopSearchIf = "RECOMMEND_SHOP";
    // 현재 위치 불러오기
    dispatch(loadingStart);
    const position = await getCurrentMyPosition()
      .then((position) => {
        return position;
      })
      .catch((position) => {
        return position;
      });
    dispatch(loadingEnd);
    const { lat, lng } = position;

    APIS.getNearbyShop(objToQueryStr({ shopSearchIf })).then(
      ({ data: { success, data } }) => {
        if (success) {
          let sortedData = data;
          if (lat && lng) {
            // 위치 허용인 경우
            sortedData = data.map((item) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (latitude && longitude) {
                distance = getDistanceFromLatLonInKm(
                  lat,
                  lng,
                  latitude,
                  longitude
                ).toFixed(2);
              }
              return { ...item, distance };
            });
            if (shopSearchIf === "RECOMMEND_SHOP") {
              // visibleOrder와 distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => {
                if (a.visibleOrder !== null && b.visibleOrder !== null) {
                  return a.visibleOrder - b.visibleOrder;
                } else if (a.visibleOrder !== null) {
                  return -1;
                } else if (b.visibleOrder !== null) {
                  return 1;
                } else {
                  return a.distance - b.distance;
                }
              });
            } else {
              // distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => a.distance - b.distance);
            }
          } else {
            sortedData = sortedData.sort((a, b) => {
              let aName = a.name;
              let bName = b.name;
              if (aName < bName) return -1;
              else if (aName === bName) return 0;
              else return 1;
            });
          }
          const matchShops = sortedData.filter(({ name, address }) => {
            return createFuzzyMatcher(searchKeyword).test(name);
          });
          if (matchShops.length === 0) {
            setShops(null);
          } else {
            setShops(matchShops);
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!isVisible) {
      setSearchKeyword("");
      setShops([]);
    }
    if (purpose === "UPDATE_USING_SHOP") {
      setShopInfo({
        shopId: userInfo?.usingShopId,
        shopName: userInfo?.usingShopName,
      });
    }
  }, [isVisible, purpose]);

  return (
    <BottomModal visible={isVisible} onClickBackground={onCloseModal}>
      {purpose === "UPDATE_USING_SHOP" && confirmShop.visible && (
        <Modal
          styleProps={{
            overlay: {
              borderRadius: "18px 18px 0px 0px",
            },
          }}
          onClickBackground={() => {
            onCloseConfirmModal();
          }}>
          <Flex
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              marginTop: "20%",
              alignItems: "center",
            }}>
            <Flex className={classes.modalWrap}>
              <Text
                style={{
                  fontSize: 18,
                }}>
                대리점을 수정하시겠습니까?
              </Text>
              <Flex row>
                <ButtonBase
                  className={classes.btn}
                  style={{
                    backgroundColor: "#8D939D",
                  }}
                  onClick={() => {
                    onCloseConfirmModal();
                  }}>
                  취소
                </ButtonBase>
                <div style={{ width: 16 }} />
                <ButtonBase
                  className={classes.btn}
                  style={{
                    backgroundColor: "#FE8B3D",
                  }}
                  onClick={() => {
                    onChangeShop();
                  }}>
                  확인
                </ButtonBase>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
      <Flex
        style={{
          flex: 1,
          height: window.innerHeight * 0.8,
          background: "white",
          borderRadius: "18px 18px 0px 0px",
        }}>
        <Flex
          style={{
            height: "32px",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <div
            style={{
              width: "64px",
              height: "6px",
              borderRadius: "100px",
              backgroundColor: "#D9D9D9",
            }}
          />
        </Flex>
        <SimpleTopBar
          style={{
            padding: "20px 25px",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <div>
            <span>대리점 찾기</span>
          </div>
          <div className={classes.back} onClick={onCloseModal}>
            <img
              alt="back"
              src={images.close}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </SimpleTopBar>
        <div
          style={{
            flex: 1,
            height: "80%",
            padding: "21px",
          }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flex: 1,
              alignItems: "flex-end",
            }}>
            <InputText
              placeholder="대리점을 검색하세요. (예:종로, 대전)"
              value={searchKeyword}
              onChange={setSearchKeyword}
              style={{
                marginRight: "8px",
              }}
            />

            <ButtonBase
              style={{
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                init();
              }}>
              <img
                src={images.search_new}
                style={{
                  // padding: "10px",
                  width: "100%",
                  height: "100%",
                }}
                alt=""
              />
            </ButtonBase>
          </div>
          {shops === null ? (
            <div
              style={{
                padding: "20px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              검색된 결과가 없습니다.
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                overflow: "scroll",
                height: "100%",
                paddingBottom: "9px",
              }}>
              {/* {searchKeyword !== "" && ShopList} */}
              {mapDataToComponent(shops)}
            </div>
          )}
        </div>
        {purpose === "SIGNUP" && (
          <Flex className={classes.bottom}>
            <Button
              onClick={handleClickNext}
              label={"다음"}
              className={`${classes.nextbtn} ${
                !shopInfo.shopId ? classes.disabled : ""
              }`}
              disabled={!shopInfo.shopId}
            />
          </Flex>
        )}
      </Flex>
    </BottomModal>
  );
}

const useStyle = makeStyles({
  back: {
    width: "16px",
    height: "16px",
  },
  modalWrap: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: 10,
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10,
    padding: "30px 0",
    width: "70%",
    height: "25%",
    alignItems: "center",
  },
  btn: {
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    padding: "8px 24px",
    borderRadius: 5,
    marginTop: 50,
  },
  bottom: {
    // position: "absolute",
    // bottom: 50,
    // left: 30,
    alignItems: "center",
    marginBottom: 24,
  },
  nextbtn: {
    width: "calc(100% - 60px)",
    backgroundColor: "#FE8B3D",
    borderRadius: "8px",
    padding: "23px 0px 22px 0px",
  },
  disabled: {
    backgroundColor: "white",
    border: `1px solid #8D939D`,
  },
});
export default UsingShopDialog;
