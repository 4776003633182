import React from "react";

/**
 * 반응형 View를 위한 가로 세로 비율 설정 View
 *
 * @param {object}    style
 * @param {number}    width    가로 비
 * @param {number}    height    세로 비
 * @param {Component} children
 *
 * @return {Component} 가로 세로 고정비의 View
 */
const AspectRatioBox = ({
  style = {},
  width = 100,
  height = 100,
  children,
  hidden = true,
  className = "",
}) => {
  return (
    <div
      style={{
        overflow: hidden ? "hidden" : "visible",
        paddingTop: (height / width) * 100 + "%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          ...style,
        }}
        className={className}
      >
        {children}
      </div>
    </div>
  );
};

export default AspectRatioBox;
