import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Radio from "../../components/radio/Radio";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { userUpdate } from "../../redux/user/UserActions";

export default function ExitReason({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState("1");
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "탈퇴하기",
      }}
    >
      <Text className={classes.label} font={fonts.notoSansKRMedium}>
        탈퇴사유 선택
      </Text>
      <Radio
        value={value === "1"}
        onChange={(x) => {
          setValue("1");
        }}
        className={classes.radio}
        label="금, 은에 대한 투자의향이 없음"
      />
      <Radio
        value={value === "2"}
        onChange={(x) => {
          setValue("2");
        }}
        className={classes.radio}
        label="매수/매도 수수료"
      />
      <Text className={classes.label2} font={fonts.notoSansKRMedium}>
        서비스 이용 관련
      </Text>
      <Radio
        value={value === "3"}
        onChange={(x) => {
          setValue("3");
        }}
        className={classes.radio}
        label="비밀번호 입력의 불편함"
      />
      <Radio
        value={value === "4"}
        onChange={(x) => {
          setValue("4");
        }}
        className={classes.radio}
        label="전자지갑 사용의 불편함"
      />
      <Radio
        value={value === "5"}
        onChange={(x) => {
          setValue("5");
        }}
        className={classes.radio}
        label="입출금 불편함"
      />
      <Text className={classes.label2} font={fonts.notoSansKRMedium}>
        서비스 신뢰성 관련
      </Text>
      <Radio
        value={value === "6"}
        onChange={(x) => {
          setValue("6");
        }}
        className={classes.radio}
        label="회사의 신뢰성"
      />
      <Radio
        value={value === "7"}
        onChange={(x) => {
          setValue("7");
        }}
        className={classes.radio}
        label="서비스 평판"
      />
      <Radio
        value={value === "8"}
        onChange={(x) => {
          setValue("8");
        }}
        className={classes.radio}
        label="실물 금/은 보관 신뢰성"
      />
      <Flex row style={{ margin: "50px 30px 0px 30px" }}>
        <Button
          onClick={history.goBack}
          className={classes.button}
          label="취소"
        />
        <Button
          onClick={() => {
            dispatch(userUpdate({ userId: null }));
            history.push(routes.exitComplete);
          }}
          className={classes.button}
          label="다음단계"
        />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    margin: "70px 30px 20px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  radio: {
    margin: "10px 30px 0px 30px",
    alignSelf: "flex-start",
  },
  label2: {
    fontSize: "14px",
    color: colors.primary,
    margin: "30px 30px 0px 30px",
  },
  button: {
    flex: 1,
    "&:first-child": {
      backgroundColor: "#999999",
      marginRight: "15px",
    },
    "&:last-child": {
      marginLeft: "15px",
    },
  },
});
