import React, { useState } from "react";
import AspectRatioBox from "../../components/common/AspectRatioBox";

import { Swiper, SwiperSlide } from "swiper/react";
import { postMessage } from "../../libs/utils";
import { useHistory } from "react-router-dom";
import routes from "../../libs/routes";

const TopBanner = ({ banners = [] }) => {
  return (
    <div>
      <AspectRatioBox width={360} height={161}>
        {banners.length > 1 ? (
          <SwiperBanner banners={banners} />
        ) : banners.length === 1 ? (
          <OneBanner banner={banners[0]} />
        ) : (
          <></>
        )}
      </AspectRatioBox>
    </div>
  );
};

const SwiperBanner = ({ banners = [] }) => {
  const history = useHistory();
  const [swiperIndex, setSwiperIndex] = useState(0);

  const handleClickBanner = (banner) => {
    if (banner.content) {
      if (banner.category === "EVENT") {
        postMessage({
          command: "SET_HEADER",
          param: {
            visible: false,
            goBack: "DEFAULT",
            title: "이벤트",
          },
        });

        window.location.href = banner.content;
      } else {
        history.push(routes.noticeDetail(banner.content), {
          id: Number(banner.content),
        });
      }
    }
  };
  return (
    <Swiper
      loop
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
      centeredSlides
      spaceBetween={9}
      slidesPerView={"auto"}
      // style={{ height: "100%" }}
    >
      {banners?.map((banner, i) => {
        return (
          <SwiperSlide
            style={{ width: "91.1111%" }}
            key={i.toString()}
            onClick={() => handleClickBanner(banner)}
          >
            <img
              src={banner.src}
              alt="배너"
              style={{
                borderRadius: 8,
                border: "1px solid #eaeaea",
                width: "100%",
                // height: "100%",
              }}
            />
          </SwiperSlide>
        );
      })}
      <div className="indicator_wrap">
        <ul
          style={{
            zIndex: 1,
            position: "absolute",
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          {banners.map((_, i) => (
            <li
              key={i.toString()}
              className={`${swiperIndex === i ? "on" : ""}`}
              style={{ transition: `width 300ms ease-in-out` }}
            />
          ))}
        </ul>
      </div>
    </Swiper>
  );
};
const OneBanner = ({ banner }) => {
  const history = useHistory();

  const handleClickBanner = (banner) => {
    if (banner.category === "EVENT") {
      postMessage({
        command: "SET_HEADER",
        param: {
          visible: false,
          goBack: "DEFAULT",
          title: "이벤트",
        },
      });

      window.location.href = banner.content;
    } else {
      history.push(routes.noticeDetail(), {
        id: isNaN(Number(banner.content))
          ? banner.content
          : Number(banner.content),
        // category: banner.category,
      });
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{ width: "91.1111%" }}
        onClick={() => handleClickBanner(banner)}
      >
        <img
          src={banner.src}
          alt="이벤트베너"
          style={{
            borderRadius: 8,
            border: "1px solid #eaeaea",
            width: "100%",
            // height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default TopBanner;
