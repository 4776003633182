import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import ic_check_white from "../../assets/images/ic_check_white.png";
import img_ex_all from "../../assets/images/img ex_all.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageShow } from "../../redux/message/MessageActions";
import { initSafeTradeOrder } from "../../redux/safe-trade/safeTradeOrder";

export default function SafeTradeStart({ navigate }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    dispatch(
      messageShow("종료 된 서비스입니다.", () => {
        history.goBack();
      })
    );
  }, []);

  const handleClickCheckbox = (index) => {
    if (checkedList.includes(index)) {
      setCheckedList(checkedList.filter((i) => i !== index));
    } else {
      setCheckedList([...checkedList, index]);
    }
  };

  const handleClickStart = () => {
    dispatch(initSafeTradeOrder());
    checkedList.length === 3 && navigate(routes.safeTradeStep1);
  };

  return (
    <BasicLayout
      stackTopbar={{
        title: "상품 등록",
        option: (
          <ButtonBase
            onClick={() => {
              history.replace(routes.main);
            }}
          >
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="trade_wrap" style={{ flex: 1 }}>
        <div className="pd_fst">
          <p className="pd_txt org">필독</p>
          <h3 className="pd_tit">상품 등록 전 반드시 확인해 주세요.</h3>
          <p className="pd_txt">
            가까운 매장에 먼저 방문하시면 판매 접수 관련 도움을 받을 수
            있습니다.
          </p>
          <div className="pd_fst_checkbox">
            <div
              className={`pd_box ${checkedList.includes(1) && "on"}`}
              onClick={() => {
                handleClickCheckbox(1);
              }}
            >
              <div className="ck_cir">
                <img src={ic_check_white} alt="" />
              </div>
              <p>가품은 판매하실 수 없습니다.</p>
            </div>
            <div
              className={`pd_box ${checkedList.includes(2) && "on"}`}
              onClick={() => {
                handleClickCheckbox(2);
              }}
            >
              <div className="ck_cir">
                <img src={ic_check_white} alt="" />
              </div>
              <p>
                본인 상품이 아니거나 입력하신 상세정보가 사실과 다른 경우 접수가
                반려될 수 있습니다.
              </p>
            </div>
            <div
              className={`pd_box ${checkedList.includes(3) && "on"}`}
              onClick={() => {
                handleClickCheckbox(3);
              }}
            >
              <div className="ck_cir">
                <img src={ic_check_white} alt="" />
              </div>
              <p>
                사진은 상품 전체가 나오게 촬영하셔야하며 정면, 뒷면, 모델명은
                필수 입니다.
                <br />
              </p>
              <div className="ex_img">
                <img src={img_ex_all} alt="예시이미지" />
              </div>
            </div>
          </div>
        </div>
        {/* 활성화(on) : 검정색(#111111), 비활성화 : 회색(#DDDDDD) */}
        <div
          className={`ok_btn ${checkedList.length === 3 && "on"}`}
          onClick={handleClickStart}
        >
          시작하기
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({});
