import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function ShopItem({
  onClick,
  list,
  shopName,
  address,
  addressDetail,
  shopPhone,
  distance,
}) {
  const classes = useStyle();
  return (
    <Flex
      className={classes.root}
      style={{ ...(list && { paddingBottom: "0px" }) }}
      row
    >
      <ButtonBase
        className={classes.button}
        disabled={!Boolean(onClick)}
        onClick={onClick}
      >
        <Flex
          row
          style={{
            alignItems: "center",
            paddingRight: 20,
            justifyContent: "space-between",
          }}
        >
          <Text font={fonts.notoSansKRMedium} className={classes.label}>
            {shopName}
          </Text>
          <Flex row style={{ alignItems: "center" }}>
            <img src={images.location} className={classes.li} />
            <Text className={classes.distance}>{parseFloat(distance)}km</Text>
          </Flex>
        </Flex>
        <Text className={classes.address}>
          {address} {addressDetail}
        </Text>
      </ButtonBase>
      <ButtonBase
        onClick={() => {
          if (shopPhone) {
            window.location.href = "tel:" + shopPhone;
          } else {
            alert("매장 전화번호가 등록되지 않았습니다.");
          }
        }}
      >
        <img src={images.call} className={classes.ci} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    padding: "18px 13px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    color: "#333",
    textAlign: "left",
    fontSize: "17px",
  },
  distance: {
    color: "#333",
    lineHeight: 1,
    fontSize: "12px",
  },
  address: {
    fontSize: "14px",
    color: colors.text,
    marginTop: "5px",
    textAlign: "left",
  },
  button: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
  },
  li: {
    width: "14px",
    height: "14px",
    marginRight: "6px",
    objectFit: "contain",
  },
  ci: {
    width: "26px",
    height: "26px",
    objectFit: "contain",
  },
});
