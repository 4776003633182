import { makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
export default function DateSelector({
  value,
  onChange,
  className,
  placeholder,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex row className={classes.item + " " + className}>
      <input
        style={{ fontFamily: fonts.notoSansKRRegular, color: "#000", background: 'none' }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        className={classes.input}
        type="date"
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    justifyContent: "center",
    boxSizing: "border-box",
    alignItems: "center",
  },
  item: {
    alignSelf: "stretch",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "10px 6px 10px 10px",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  input: {
    border: "none",
    color: colors.text,
    width: "100%",
    fontFamily: fonts.notoSansKRLight,
    boxSizing: "border-box",
  },
});
