import { actionActionTypes } from "./ActionActions";

const initAction = {
  open: false,
  message: "",
  title: [],
  onPress: [],
  content: null,
  closable: false,
};

export default function action(state = initAction, action) {
  switch (action.type) {
    case actionActionTypes.show:
      return {
        ...state,
        ...action,
        open: true,
      };
    case actionActionTypes.hide:
      return {
        ...initAction,
      };
    case actionActionTypes.close:
      return {
        ...initAction,
      };
    case actionActionTypes.open:
      return {
        ...state,
        ...action,
        open: true,
      };
    default:
      return state;
  }
}
