import { ButtonBase, Dialog, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";

export default function DirectTransactionList({
  onClose,
  categoryId,
  setCategoryId,
}) {
  const classes = useStyle();
  const [list, setList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/category1",
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            let c = [];
            res.data.forEach((x) => {
              c.push(...x.category2Depth);
            });
            setList([...c]);
          }
        }
      )
    );
  }, []);

  return (
    <Dialog classes={{ paper: classes.paper }} scroll="paper" open>
      <ButtonBase
        onClick={() => {
          onClose();
        }}
        className="img-button"
      >
        <img src={images.close} />
      </ButtonBase>
      <Grid container className={classes.list}>
        <ButtonBase
          onClick={() => {
            setCategoryId("");
            onClose();
          }}
          className={classes.item + " " + (!categoryId ? classes.itemS : "")}
        >
          <img src={images.dt1} />
          <Text font={fonts.notoSansKRMedium} className={classes.buttonLabel}>
            전체
          </Text>
        </ButtonBase>
        {list.map((x, i) => {
          return (
            <ButtonBase
              key={i.toString()}
              onClick={() => {
                setCategoryId(x.parentId + "," + x.id + "," + x.name);
                onClose();
              }}
              className={
                classes.item +
                " " +
                (x.parentId + "," + x.id + "," + x.name === categoryId
                  ? classes.itemS
                  : "")
              }
            >
              <img src={x.imageUrl} />
              <Text
                font={fonts.notoSansKRMedium}
                className={classes.buttonLabel}
              >
                {x.name}
              </Text>
            </ButtonBase>
          );
        })}
      </Grid>
    </Dialog>
  ); /*
  return (

    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <ButtonBase
          onClick={() => {
            onClose();
          }}
          className="img-button"
        >
          <img src={images.close} />
        </ButtonBase>
        <Grid container className={classes.list}>
          <ButtonBase
            onClick={() => {
              setCategoryId("");
            }}
            className={classes.item + " " + (!categoryId ? classes.itemS : "")}
          >
            <img src={images.dt1} />
            <Text font={fonts.notoSansKRMedium} className={classes.buttonLabel}>
              전체
            </Text>
          </ButtonBase>
          {list.map((x, i) => {
            return (
              <ButtonBase
                key={i.toString()}
                onClick={() => {
                  setCategoryId(x.parentId + "," + x.id);
                }}
                className={
                  classes.item +
                  " " +
                  (x.parentId + "," + x.id === categoryId ? classes.itemS : "")
                }
              >
                <img src={x.imageUrl} />
                <Text
                  font={fonts.notoSansKRMedium}
                  className={classes.buttonLabel}
                >
                  {x.name}
                </Text>
              </ButtonBase>
            );
          })}
        </Grid>
      </Flex>
    </Flex>
  );*/
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },

  paper: {
    borderRadius: "10px",
    display: "flex",
    minWidth: window.innerWidth - 32,
    flexDirection: "column",
    padding: "30px",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  title: {
    color: colors.text,
    marginBottom: "20px",
    fontSize: "18px",
  },
  itemS: {
    border: "1px solid " + colors.primary,
  },
  item: {
    flexDirection: "column",
    padding: "17px",
    borderRadius: "3px",
    width: "33.3%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "24px",
      height: "24px",
      objectFit: "contain",
      marginBottom: "15px",
    },
  },
  list: {
    margin: "0px 20px",
    marginTop: "20px",
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
  },
  buttonLabel: {
    whiteSpace: "nowrap",
    fontSize: 14,
    color: "#333",
  },
});
