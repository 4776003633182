import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import checkboxMiniOn from "../../../../assets/images/checkbox_mini_on.webp";
import checkboxMiniOff from "../../../../assets/images/checkbox_mini_off.webp";
import historyAppraisalGold from "../../../../assets/images/history_appraisal_gold.webp";
import historyAppraisalSilver from "../../../../assets/images/history_appraisal_silver.webp";
import product_default from "../../../../assets/images/product_default.webp";
import Span from "../../../../components/text/Span";
import fonts from "../../../../libs/fonts";
import consts from "../../../../libs/consts";
import checkboxOff from "../../../../assets/images/checkbox_off.webp";
import checkboxOn from "../../../../assets/images/checkbox_on.webp";
import searchIcon from "../../../../assets/images/searchIcon.png";

import { formatTime } from "../../../../libs/utils";
import PolicyAgreePopup from "../../../../components/policy-agree-popup/PolicyAgreePopup";
import routes from "../../../../libs/routes";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { actionShow } from "../../../../redux/action/ActionActions";
import * as APIS from "../../../../libs/apis";
import { toastShow } from "../../../../redux/toast/ToastActions";

const SimpleIng = ({ list, assetType, fetchList }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState([]);
  // const [popup, setPopup] = useState(false);
  const [usingDelivery, setUsingDelivery] = useState(false);
  const isAllChecked = list.length && list.length === checked.length;

  useEffect(() => {
    APIS.getEnvConfig("APPRAISAL_USING_DELIVERY_POST").then(
      ({ data: { success, data } }) => {
        success && setUsingDelivery(data?.value);
      }
    );
  }, []);
  return (
    <>
      <SpaceBetween style={{ marginBottom: 8, alignItems: "flex-end" }}>
        <ExchangeButton
          onClick={() => {
            if (!checked.length) {
              dispatch(toastShow("제품을 선택해주세요."));
              return false;
            } else {
              history.push(routes.exchange, {
                action: "appraisal",
                request: true,
                appraisalSimpleIds: checked, //선택한 감청평가 id 배열
              });
            }
          }}>
          {/* 자산({assetType === "GOLD" ? "금" : "은"})으로 교환 */}
          <div>
            <img src={searchIcon} />
          </div>
          <RequestFont style={{ fontFamily: fonts.notoSansKRMedium }}>
            감정 신청하기
          </RequestFont>
          {usingDelivery === "true" && (
            <div
              style={{
                display: "flex",
                padding: "6px 8px",
                alignItems: "center",
                gap: "8px",
                borderRadius: "18px",
                background: "#F5F5F5",
              }}>
              <NewFont>New</NewFont>
              <ServiceFont>택배서비스</ServiceFont>
            </div>
          )}
        </ExchangeButton>
        <AllCheckWrap
          onClick={() => {
            if (isAllChecked) {
              setChecked([]);
            } else {
              setChecked(list.map(({ requestId }) => requestId));
            }
          }}>
          {isAllChecked ? (
            <img src={checkboxMiniOn} width={32} height={32} />
          ) : (
            <img src={checkboxMiniOff} width={32} height={32} />
          )}

          <Span
            font={fonts.notoSansKRMedium}
            size={13}
            style={{ marginLeft: 26 }}>
            전체 선택
          </Span>
        </AllCheckWrap>
      </SpaceBetween>

      {list.map((item, i) => {
        const { status, requestId, productName } = item;
        return (
          <Container
            key={i.toString()}
            onClick={() => {
              if (checked.includes(requestId)) {
                setChecked(checked.filter((id) => id !== requestId));
              } else {
                setChecked([...checked, requestId]);
              }
            }}>
            <Header>
              <Flex row>
                <StatusIcon />
                <StatusLabel>{status}</StatusLabel>
              </Flex>
              <Flex>
                {status === "간편감정" && (
                  <img
                    src={checked.includes(requestId) ? checkboxOn : checkboxOff}
                    width={24}
                    height={24}
                  />
                )}
              </Flex>
            </Header>
            <SpaceBetween style={{ marginTop: 11 }}>
              <Flex
                row
                style={{
                  fontFamily: fonts.notoSansKRMedium,
                  fontSize: 15,
                  alignItems: "flex-end",
                }}>
                <img
                  src={
                    assetType === "GOLD"
                      ? historyAppraisalGold
                      : historyAppraisalSilver
                  }
                  width={18}
                  height={18}
                />
                감정평가
              </Flex>
            </SpaceBetween>
            <Flex row style={{ marginTop: 14 }}>
              <Flex>
                <img
                  src={
                    item.imagePath
                      ? `${consts.s3BaseUrl}/${item.imagePath}`
                      : product_default
                  }
                  width={72}
                  height={72}
                  style={{
                    border: "1px solid #d6d6d6",
                    borderRadius: 8,
                    marginRight: 30,
                    objectFit: "contain",
                  }}
                />
              </Flex>
              <Flex style={{ flex: 1 }}>
                <Span font={fonts.notoSansKRBold} size={17}>
                  {productName}
                </Span>
                <SpaceBetween>
                  <Span size={15} color="#666666" style={{ width: 70 }}>
                    중량
                  </Span>
                  <Span font={fonts.spoqa} size={16} color="#666666">
                    {item.requestGram}g
                  </Span>
                </SpaceBetween>
              </Flex>
            </Flex>
            <Flex row>
              <Span
                size={13}
                color="#999999"
                style={{
                  borderBottom: "1px solid #999999",
                  marginTop: 9,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(
                    actionShow(
                      "선택하신 제품을 삭제하시겠습니까?",
                      ["취소", "삭제"],
                      [
                        null,
                        () => {
                          APIS.deleteAppraisalSimple(requestId).then(fetchList);
                        },
                      ]
                    )
                  );
                }}>
                삭제
              </Span>
            </Flex>
            <Flex style={{ height: 17 }} />
          </Container>
        );
      })}
      {/* { popup && (
        <PolicyAgreePopup
          title="서비스 이용 동의"
          onClose={() => {
            setPopup(false);
          }}
          onAction={() => {
            history.push(routes.exchange, {
              action: "appraisal",
              request: true,
              appraisalSimpleIds: checked,
            });
          }}
          data={[
            { label: "서비스 이용약관에 동의합니다." },
            {
              label:
                "제품에 포함된 각종 보석류 및 세팅 등은 가치를 산정하지 않습니다.",
            },
            {
              label:
                "상세감정 과정의 특성상 제품에 흠집 또는 손상이 갈 수 있습니다.",
            },
            {
              label:
                "불법적 획득 제품으로 의심될 경우 상세감정이 거부될 수 있습니다.",
            },

            { label: "상세 감정 결과는 간편 감정 결과와 다를 수 있습니다." },
            {
              label:
                "상세 감정 결과에 따라 금/은 제품을 보유 금/은(g) 자산으로 교환할 수 있습니다.",
            },
            { label: "교환이 완료된 경우 거래의 취소가 불가합니다." },
            {
              label:
                "교환이 완료된 제품은 원재료 형태로 정련되어 안전하게 보관됩니다.",
            },
            {
              label:
                "교환이 완료된 제품은 실물인출 형태로 반환신청을 할 수 있습니다.",
            },

            {
              label:
                "회원의 서비스 이용 내용은 서비스 고도화를 위한 데이터로 활용될 수 있습니다.",
            },
          ]}
        />
      )} */}
    </>
  );
};

const ExchangeButton = styled.div`
display: flex;
flex-direction: row;
height: 40px;
padding: 4px 12px;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 12px;
border: 1px solid #FF6E0E;
background: #FFF;

/* shadow_card_02 */
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;
// border: 1px solid #ff6e0e;
// border-radius: 4px;
// font-size: 14px;
// padding: 3px 7.5px;
// // font-family: ${fonts.notoSansKRBold};
// color: black;
// background: rgba(255, 110, 14, 0.3);
const RequestFont = styled.div`
color: #222;
leading-trim: both;
text-edge: cap;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.24px;
`;
const NewFont = styled.div`
color: #FF6E0E;
leading-trim: both;
text-edge: cap;
font-family: "Noto Sans KR";
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.2px;
`;

const ServiceFont = styled.div`
color: #444;
leading-trim: both;
text-edge: cap;
font-family: "Noto Sans KR";
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.2px;
`;

const AllCheckWrap = styled(Flex)`
  position: relative;
  flex-direction: row;
  align-items: center;
  img {
    position: absolute;
    left: 0;
  }
`;

const Container = styled(Flex)`
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Header = styled(Flex)`
  flex-direction: row;
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
  justify-content: space-between;
`;

const StatusLabel = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #e76e26;
`;

const StatusIcon = styled(Flex)`
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(00, 00, 00, 0.1);

  &::before {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background: #ff6e0e;
    content: "";
  }
`;

export default SimpleIng;
