import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import myPageBell from "../../assets/images/mypage_bell.webp";
import myAssetGold from "../../assets/images/my_asset_gold.webp";
import myAssetKrw from "../../assets/images/my_asset_krw.webp";
import myAssetSilver from "../../assets/images/my_asset_silver.webp";
import arrowDownTwice from "../../assets/images/arrow_down_twice.webp";
import my_asset_point from "../../assets/images/my_asset_point.webp";
import * as APIS from "../../libs/apis";
import fonts from "../../libs/fonts";
import { numberWithCommas } from "../../libs/utils";
import SimpleAccordion from "../../components/accordion/SimpleAccordion";
import colors from "../../libs/colors";
import { useHistory } from "react-router";

const MyAsset = () => {
  const history = useHistory();
  const locationState = history.location.state || {};
  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
    acceptablePoint: 0,
  });

  const [asset, setAsset] = useState([
    { assetType: "GOLD" },
    { assetType: "SILVER" },
  ]);

  const [ingCount, setIngCount] = useState(0);

  useEffect(() => {
    APIS.getUserHoldingsInfo().then(({ data: { success, data = {} } }) => {
      success && setHoldings(data);
    });

    APIS.getUserAsset().then(({ data: { success, data = [] } }) => {
      success && setAsset(data);
    });

    APIS.getTradeHistoryIngCount().then(({ data: { success, data } }) => {
      success && setIngCount(data);
    });
  }, []);

  return (
    <Container>
      <TopWrap>
        <TopLabelWrap>
          <div style={{ position: "relative" }}>
            <img src={myPageBell} width={14} height={16} />
            <div
              style={{
                width: 4,
                height: 4,
                background: "#ff6e0e",
                borderRadius: "50%",
                position: "absolute",
                right: 0,
                top: 0,
                transform: "translate(50%, -50%)",
              }}
            />
          </div>
          <TopLabel>거래 대기</TopLabel>
        </TopLabelWrap>
        <TopWrapButton
          onClick={() => {
            history.replace(history.location.pathname, {
              tab: "HISTORY",
              division: "ING",
            });
          }}
        >
          진행중
          <span className="spoqa">{numberWithCommas(ingCount)}</span>
        </TopWrapButton>
      </TopWrap>
      <ContentWrap>
        <AssetCard>
          <AssetTitle>
            <img src={myAssetKrw} width={24} />
            원화
            <span style={{ fontFamily: fonts.notoSansKRRegular }}>(KRW)</span>
          </AssetTitle>
          <AssetRow style={{ marginTop: 18, border: "none" }}>
            <AssetSubject>보유 원화</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.krw)}
              <span>원</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>거래 대기</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.krw - holdings.acceptableKrw)}
              <span>원</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>사용 가능</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.acceptableKrw)}
              <span>원</span>
            </AssetValue>
          </AssetRow>
        </AssetCard>

        {/* 포인트 */}
        <AssetCard>
          <AssetTitle>
            <img src={my_asset_point} width={24} />
            포인트
          </AssetTitle>
          <AssetRow style={{ marginTop: 18, border: "none" }}>
            <AssetSubject>보유 포인트</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.point)}
              <span>pt</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>거래 대기</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.point - holdings.acceptablePoint)}
              <span>pt</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>사용 가능</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.acceptablePoint)}
              <span>pt</span>
            </AssetValue>
          </AssetRow>
        </AssetCard>

        {/* 금 */}
        <AssetCard>
          <AssetTitle>
            <img src={myAssetGold} width={24} />금
          </AssetTitle>
          <AssetRow style={{ marginTop: 18, border: "none" }}>
            <AssetSubject>보유 금</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.gold)}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>거래 대기</AssetSubject>
            <AssetValue>
              {numberWithCommas(
                +(holdings.gold - holdings.acceptableGold).toFixed(3)
              )}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>사용 가능</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.acceptableGold)}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <ValuationAccordion
            {...asset.find(({ assetType }) => assetType === "GOLD")}
          />
        </AssetCard>

        {/* 은 */}
        <AssetCard>
          <AssetTitle>
            <img src={myAssetSilver} width={24} />은
          </AssetTitle>
          <AssetRow style={{ marginTop: 18, border: "none" }}>
            <AssetSubject>보유 은</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.silver)}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>거래 대기</AssetSubject>
            <AssetValue>
              {numberWithCommas(
                +(holdings.silver - holdings.acceptableSilver).toFixed(1)
              )}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <AssetRow>
            <AssetSubject>사용 가능</AssetSubject>
            <AssetValue>
              {numberWithCommas(holdings.acceptableSilver)}
              <span>g</span>
            </AssetValue>
          </AssetRow>
          <ValuationAccordion
            {...asset.find(({ assetType }) => assetType === "SILVER")}
          />
        </AssetCard>
      </ContentWrap>
    </Container>
  );
};

const Container = styled(Flex)`
  flex: 1;
  padding: 17px;
  background: #fcfcfc;
`;

const TopWrap = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 16px;
  background: #ffeee3;
  border: 1px solid #ffe6d5;
  border-radius: 8px;
  height: 64px;
`;

const TopLabelWrap = styled(Flex)`
  flex-direction: row;
  align-items: center;
`;

const TopLabel = styled.span`
  font-family: ${fonts.notoSansKRBold};
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #333333;
  margin-left: 5px;
`;

const TopWrapButton = styled(Flex)`
  flex-direction: row;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px 15px;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #333333;
  align-items: center;
  height: 40px;

  span {
    display: flex;
    background: #ff6e0e;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    font-weight: 700;
    margin-left: 3px;
  }
`;

const ContentWrap = styled(Flex)`
  padding: 16px 0;
`;

const AssetCard = styled(Flex)`
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 20px 15px 4px 15px;

  &:not(:first-of-type) {
    margin-top: 16px;
  }
`;

const AssetTitle = styled(Flex)`
  img {
    margin-right: 4px;
  }
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  letter-spacing: -0.34px;
  color: #333333;
`;

const AssetRow = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  height: 40px;
  align-items: center;

  border-top: 1px solid #eaeaea;
`;
const AssetSubject = styled(Flex)`
  font-family: ${fonts.notoSansKRRegular};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #444444;
`;
const AssetValue = styled(Flex)`
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.spoqa};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #444444;

  span {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 15px;
    font-weight: normal;
    margin-left: 1px;
  }
`;

const ValuationAccordion = (props) => {
  const [expended, setExpended] = useState(false);

  const {
    amount = 0,
    averagePrice = 0,
    earningsRate = 0,
    lastPrice = 0,
    price = 0,
    totalPrice = 0,
  } = props;

  return (
    <AccordionContainer
      title={
        <Flex className="accordion-header">
          평가 손익
          {/* <img src={arrowDownTwice} width={30} /> */}
        </Flex>
      }
      expended={expended}
      onChange={setExpended}
      expendIcon={
        <img src={arrowDownTwice} width={30} style={{ verticalAlign: "top" }} />
      }
    >
      <Flex className="accordion-content">
        <Flex row>
          <Flex className="subject">현재가</Flex>
          <Flex className="value">
            {numberWithCommas(price)}
            <span>원</span>
          </Flex>
        </Flex>
        <Flex row>
          <Flex className="subject">평가 금액</Flex>
          <Flex className="value">
            {numberWithCommas(totalPrice)}
            <span>원</span>
          </Flex>
        </Flex>
        <Flex row>
          <Flex className="subject">수익률</Flex>
          <Flex
            className={`value ${
              earningsRate > 0 ? "plus" : earningsRate < 0 ? "minus" : "normal"
            }`}
          >
            {numberWithCommas(earningsRate)}
            <span>%</span>
          </Flex>
        </Flex>
        <Flex row>
          <Flex className="subject">평균 매수가</Flex>
          <Flex className="value">
            {numberWithCommas(averagePrice)}
            <span>원</span>
          </Flex>
        </Flex>
      </Flex>
    </AccordionContainer>
  );
};
const AccordionContainer = styled(SimpleAccordion)`
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 12px;
  border: 1px solid #eaeaea;

  .accordion-header {
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    font-family: ${fonts.notoSansKRBold};
    font-size: 14px;
    letter-spacing: -0.28px;
    color: #444444;
  }

  .accordion-content {
    margin: 0 8.5px;
    border-top: 1px solid #bdbdbd;
    padding: 4px 0;

    > div {
      padding: 10px 0;
      justify-content: space-between;

      &:not(:first-of-type) {
        border-top: 1px solid #dbdbdb;
      }

      .subject {
        font-family: ${fonts.notoSansKRRegular};
        font-size: 14px;
        letter-spacing: -0.28px;
        color: #444444;
      }

      .value {
        flex-direction: row;
        align-items: center;

        font-family: ${fonts.spoqa};
        font-weight: bold;
        font-size: 15px;
        letter-spacing: -0.3px;
        color: #444444;

        &.plus {
          color: ${colors.textRed};
        }

        &.minus {
          color: ${colors.textBlue};
        }

        span {
          margin-left: 2px;
          font-family: ${fonts.notoSansKRRegular};
          font-weight: normal;
        }
      }
    }
  }
`;

export default MyAsset;
