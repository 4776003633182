import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const LeaveReason = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || { selectedReason: "" };
  const { selectedReason } = locationState;

  const [reasonList, setReasonList] = useState([]);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getLeaveReason()
      .then(({ data: { success, data } }) => {
        if (success) {
          const reasonList = [];

          for (let reason of data) {
            const list = reasonList.find(
              ({ title }) => title === reason.group
            )?.list;

            if (list) {
              list.push(reason);
            } else {
              reasonList.push({ title: reason.group, list: [reason] });
            }
          }

          setReasonList(reasonList);
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  const handleChangeRadio = (e) => {
    history.replace(history.location.pathname, {
      selectedReason: e.target.value,
    });
  };

  const handleClickNext = () => {
    if (selectedReason) {
      history.push(routes.leaveSms, { leaveReason: selectedReason });
    }
  };
  return (
    <BasicLayout stackTopbar={{ title: "탈퇴하기", border: true }}>
      <div className="withdrawal_wrap" style={{ flex: 1 }}>
        <div className="check_list_wrap">
          <div className="check_list_top">
            <h3>
              탈퇴사유 선택 <span className="orgcolor">*</span>
            </h3>
            <p>
              아래 항목에서 가장 가까운 이유를 <span className="spoqa">1</span>
              개 선택해 주세요.
            </p>
          </div>
          <div className="check_list">
            {reasonList.map(({ title, list }, i) => {
              return (
                <>
                  {!!title && <h3>{title}</h3>}

                  <ul className="check_list1">
                    {list.map(({ description, leaveReason }, j) => {
                      return (
                        <li key={j.toString()}>
                          <input
                            type="radio"
                            name="radio"
                            id={`radio${i}_${j}`}
                            value={leaveReason}
                            onChange={handleChangeRadio}
                            checked={selectedReason === leaveReason}
                          />
                          <label htmlFor={`radio${i}_${j}`}>
                            {description}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className="withdrawal_btn checklist">
        <button onClick={() => history.goBack()}>취소</button>
        <button
          className={selectedReason ? "on" : "off"}
          onClick={handleClickNext}
        >
          다음
        </button>
      </div>
    </BasicLayout>
  );
};

export default LeaveReason;
