import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";
import closeIcon from "../../assets/images/close_icon.webp";
import { parsePhoneNumber } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { actionShow } from "../../redux/action/ActionActions";
import { useHistory } from "react-router";
import routes from "../../libs/routes";

const AddressList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addressList, setAddressList] = useState([]);

  useEffect(() => {
    searchAddress();
  }, []);

  const searchAddress = () => {
    dispatch(loadingStart);
    APIS.getUserAddress()
      .then(({ data: { success, data } }) => {
        success && setAddressList(data);
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickDelete = (addressId) => {
    dispatch(
      actionShow(
        "선택하신 배송지를 삭제하시겠습니까?",
        ["취소", "삭제"],
        [
          null,
          () => {
            dispatch(loadingStart);
            APIS.deleteUserAddress(addressId)
              .then(({ data: { success, message } }) => {
                if (success) {
                  dispatch(toastShow("삭제되었습니다."));
                  searchAddress();
                } else {
                  dispatch(messageShow(message));
                }
              })
              .finally(() => dispatch(loadingEnd));
          },
        ]
      )
    );
  };

  return (
    <Container>
      {addressList.map(
        (
          {
            id,
            isDefault,
            receiver,
            phone = "",
            mobile,
            postCode,
            address,
            addressDetail,
            memo,
            passwordDoor,
          },
          i
        ) => {
          return (
            <AddressCard key={i.toString()}>
              <CardHeader>
                <Flex row>
                  {receiver}
                  {isDefault && (
                    <span
                      style={{
                        marginLeft: 5,
                        fontFamily: fonts.notoSansKRRegular,
                        color: "#ea792b",
                      }}
                    >
                      (기본)
                    </span>
                  )}
                </Flex>
                <img
                  src={closeIcon}
                  width={48}
                  onClick={() => {
                    handleClickDelete(id);
                  }}
                />
              </CardHeader>
              <CardBody>
                <Flex row>
                  {phone && (
                    <PhoneWrap>{parsePhoneNumber(phone, "-")}</PhoneWrap>
                  )}

                  {mobile && (
                    <PhoneWrap
                      style={{
                        marginLeft: 5,
                        paddingLeft: 5,
                        borderLeft: "1px solid #777777",
                      }}
                    >
                      {parsePhoneNumber(mobile, "-")}
                    </PhoneWrap>
                  )}
                </Flex>
                <AddressWrap>
                  ({postCode}) {address} {addressDetail}
                </AddressWrap>
                <DescriptionWrap>
                  <Flex row>
                    <Subject>수령방법:</Subject>
                    <Content>{memo}</Content>
                  </Flex>
                  <Flex row>
                    <Subject>출입방법:</Subject>
                    <Content>{passwordDoor || "자유 출입 가능"}</Content>
                  </Flex>
                </DescriptionWrap>
                <ButtonWrap
                  onClick={() => {
                    history.push(routes.addressModify, { id });
                  }}
                >
                  수정
                </ButtonWrap>
              </CardBody>
            </AddressCard>
          );
        }
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  flex: 1;
  background: #f6f6f6;
  padding: 16px;
  border: 1px solid #eaeaea;
  border-top: 0;
  border-radius: 0 0 8px 8px;
`;

const AddressCard = styled(Flex)`
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 8px;

  &:not(:first-of-type) {
    margin-top: 15px;
  }
`;

const CardHeader = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.3px;
  color: #333333;
  position: relative;
  justify-content: center;
  padding: 8px 12px;
  border-bottom: 1px solid #eaeaea;

  img {
    position: absolute;
    right: -6px;
  }
`;
const CardBody = styled(Flex)`
  padding: 13px 16px;
`;

const PhoneWrap = styled(Flex)`
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #777777;
`;
const AddressWrap = styled(Flex)`
  margin-top: 8px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  color: #777777;
  letter-spacing: -0.28px;
`;

const DescriptionWrap = styled(Flex)`
  margin-top: 11px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.28px;
`;
const Subject = styled(Flex)`
  font-family: ${fonts.notoSansKRMedium};
  color: #4d4d4d;
`;
const Content = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  color: #444444;
`;
const ButtonWrap = styled(Flex)`
  margin-top: 9px;
  align-self: flex-end;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 13px;
  color: #6c6c6c;
  border-bottom: 1px solid #6c6c6c;
`;

export default AddressList;
