import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DirectTransactionItem from "../../components/direct-transaction-item/DirectTransactionItem";
import StackTopbar from "../../layouts/basic/StackTopbar";
import MainLayout from "../../layouts/main/MainLayout";
import Text from "../../components/text/Text";
import Flex from "../../components/flex/Flex";
import Tables from "../../components/tables/Tables";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { formatTime, numFormat } from "../../libs/utils";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import colors from "../../libs/colors";
import DateSelector2 from "../../components/date-selector/DateSelector2";
import * as APIS from "../../libs/apis";

export default function MyDirectTransaction({}) {
  const defaultFrom = new Date();
  defaultFrom.setMonth(defaultFrom.getMonth() - 1);

  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ChangeDate, setChangeDate] = useState(
    formatTime(defaultFrom, "YYYY-MM-DD")
  );
  const [ChangeDateEnd, setChangeDateEnd] = useState(
    formatTime(new Date(), "YYYY-MM-DD")
  );
  const [rows, setRows] = useState([]);
  const { id } = useSelector((s) => s.user);

  useEffect(() => {
    APIS.getSignedList({ from: ChangeDate, to: ChangeDateEnd }).then(
      ({ data: { data } }) => {
        setRows(data);
      }
    );
  }, [ChangeDate, ChangeDateEnd]);

  return (
    <MainLayout
      white
      topbar={<StackTopbar border title="매수/매도 체결내역" />}
    >
      <Flex
        row
        className={classes.root}
        style={{ width: "100%", "justify-content": "space-between" }}
      >
        <DateSelector2
          value={ChangeDate}
          className={classes.date}
          onChange={(v) => setChangeDate(v)}
        />
        <span className={classes.datedot}>~</span>
        <DateSelector2
          value={ChangeDateEnd}
          className={classes.date}
          onChange={(v) => setChangeDateEnd(v)}
        />
      </Flex>
      <Flex className={classes.root}>
        <Tables
          data={rows}
          columns={[
            {
              label: "체결시간",
              render: (x) => {
                var listData1 = formatTime(x.completedAt, "MM.DD");
                var listData2 = formatTime(x.completedAt, "HH:mm:ss");
                return (
                  <>
                    {" "}
                    {listData1} <br />
                    {listData2}{" "}
                  </>
                );
              },
            },
            {
              label: "거래구분",
              render: (x) => {
                const { tradeType } = x;
                let className = "";
                let text = "";

                if (tradeType === "SELL") {
                  text = "매도";
                  className = classes.blue;
                } else if (tradeType === "BUY") {
                  text = "매수";
                  className = classes.red;
                } else if (tradeType === "EVENT") {
                  text = "이벤트";
                }

                return (
                  <>
                    <Text className={className}>{text}</Text>
                    {x.assetType === "GOLD" ? "금" : "은"}({x.assetType})
                  </>
                );
              },
            },
            {
              label: "체결중량\n체결단가",
              render: (x) => {
                var tradeGram = numFormat(x.tradeGram);
                var pricePerGram = numFormat(x.pricePerGram);
                return (
                  <>
                    {" "}
                    {tradeGram}g <br />
                    {pricePerGram}{" "}
                  </>
                );
              },
            },
            {
              // label: "체결금액\n수익률",
              label: "체결금액",
              render: (x) => {
                var totalAmount = numFormat(x.pureTradeKrw);
                return <>{totalAmount}</>;
              },
            },
            {
              label: "수수료",
              render: (x) => {
                var fee = numFormat(x.userFee);
                return <>{fee}</>;
              },
            },
          ]}
        />
        {rows.length < 1 && (
          <Text className={classes.no}>체결된 내역이 없습니다.</Text>
        )}
      </Flex>
    </MainLayout>
  );
}
const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
    padding: "10px",
  },
  row: {
    alignSelf: "flex-end",
    padding: "16px",
  },
  close: {
    width: "20px",
    height: "20px",
  },
  label: {
    color: colors.text,
    margin: "30px 16px 13px 16px",

    fontSize: "22px",
  },
  no: {
    fontSize: "13px",
    alignSelf: "center",
    color: colors.text,
    marginTop: "50px",
  },
  red: {
    color: colors.textRed,
  },
  blue: {
    color: colors.textBlue,
  },
  date: {
    margin: "0px 0px 0px 0px",
    background: "none",
    width: "calc(50% - 20px)",
  },
  DateSelector2: {
    margin: "0px 0px 0px 0px",
    background: "none",
    width: "calc(50% - 20px)",
  },
  datedot: {
    position: "relative",
    top: "10px",
  },
});
