import React, { useMemo } from "react";
import { useParams, Redirect } from "react-router-dom";
import routes from "../../libs/routes";

function DetailGateway() {
  const params = useParams();
  const { id } = params;

  const isShop = id.includes("-");

  return isShop ? (
    <Redirect
      to={{
        pathname: `${routes.shopDetail(id)}`,
      }}
    />
  ) : (
    <Redirect
      to={{
        pathname: `${routes.physicalWithDrawalPDetail}${id}`,
      }}
    />
  );
}

export default DetailGateway;
