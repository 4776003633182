import React, { useEffect, useState } from "react";
import BasicLayout from "../../layouts/basic/BasicLayout";
import Text from "../../components/text/Text";
import { makeStyles } from "@material-ui/core";
import fonts from "../../libs/fonts";
import InputShadow from "../../components/input-shadow/InputShadow";
import Button from "../../components/button/Button";
import axios from "axios";
import consts from "../../libs/consts";
import { useDispatch, useSelector } from "react-redux";
import { userInit } from "../../redux/user/UserActions";
import { useHistory } from "react-router";
import routes from "../../libs/routes";

export default function LoginId({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");
  const [error, setError] = useState(false);
  const user = useSelector((s) => s.user);
  const history = useHistory();
  useEffect(() => {
    if (user.id) {
      history.replace(routes.main);
    }
  }, [user]);
  useEffect(() => {
    if (id && pw && error) {
      setError(false);
    }
  }, [id, pw]);

  const login = () => {
    axios
      .post(consts.apiBaseUrl + "/api/authenticate", {
        password: pw,
        userId: id,
      })
      .then((res) => {
        const token = res.data.token;
        localStorage.setItem(consts.accessToken, token);
        dispatch(userInit);
      })
      .catch((e) => {
        setError(true);
        //dispatch(messageError(e));
      });
  };
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "로그인" }}>
      <Text font={fonts.notoSansKRBold} className={classes.label}>
        매장회원 로그인
      </Text>

      <InputShadow
        value={id}
        onChange={setId}
        className={classes.input}
        placeholder="아이디"
      />
      <InputShadow
        password
        value={pw}
        onChange={setPw}
        className={classes.input}
        placeholder="비밀번호"
      />

      {error && (
        <Text className={classes.error}>
          아이디와 비밀번호를 다시 확인해 주세요.
        </Text>
      )}
      <Button
        onClick={login}
        disabled={error || !id || !pw}
        className={classes.button}
        label="로그인"
      ></Button>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: 20,
    lineHeight: "30px",
    color: "#333333",
    marginTop: 70,
    marginBottom: 33,
    marginLeft: 30,
    marginRight: 30,
  },
  input: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 6,
  },
  button: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
  },
  error: {
    fontSize: 12,
    marginTop: 7,
    color: "#eb0c0c",
    textAlign: "center",
  },
});
