import { ButtonBase, makeStyles, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import queryString from "query-string";
import { objToQueryStr } from "../../services/utils";
import routes from "../../libs/routes";
import AppleLogin from "react-apple-login";
import consts from "../../libs/consts";
import jwtDecode from "jwt-decode";
import qs from "qs";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { decodeJwt, postMessage } from "../../libs/utils";
import InputText from "../../components/input-text/InputText";
import { toastShow } from "../../redux/toast/ToastActions";
import { actionError } from "../../redux/action/ActionActions";
import { signIn } from "../../libs/sign";

export default function SignInId({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();

  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");

  const handleClickClose = () => {
    history.goBack();
  };

  const handleClickSignUp = () => {
    history.push(routes.signUpId);
  };

  const handleClickSignIn = () => {
    if (!loginId) {
      dispatch(toastShow("아이디를 확인해주세요."));
      return false;
    } else if (!password) {
      dispatch(toastShow("비밀번호를 확인해주세요."));
      return false;
    } else {
      dispatch(loadingStart);
      APIS.postSignInId({ loginId, password })
        .then(({ data: { success, message, data } }) => {
          if (success) {
            const { authToken } = data;

            const { roles = "" } = decodeJwt(authToken);
            if (roles.split(",").includes("ROLE_SELF_VERIFICATION_MALL")) {
              history.push(routes.acceptTerms, {
                purpose: "MALL_TO_APP",
                token: authToken,
              });
            } else {
              signIn(authToken);
              dispatch(toastShow("로그인 되었습니다."));
              history.replace(routes.main);
            }
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };
  return (
    <Flex className={classes.root}>
      <ImageButton
        onClick={handleClickClose}
        className={classes.close}
        align="right"
        src={images.close}
      />
      <Flex className={classes.top}>
        <Text font={fonts.notoSansKRLight} className={classes.label}>
          모바일 금은방
        </Text>
        <Image src={images.logo} width="50vw" />
      </Flex>
      <Flex className={classes.bottom}>
        <InputText label="아이디 입력" value={loginId} onChange={setLoginId} />
        <Flex style={{ height: 10 }} />
        <InputText
          type="password"
          label="비밀번호 입력"
          value={password}
          onChange={setPassword}
        />
        <Flex className={classes.buttonSection}>
          <ButtonBase className={classes.social} onClick={handleClickSignIn}>
            <Text font={fonts.notoSansKRMedium}>로그인</Text>
          </ButtonBase>
          <Flex row style={{ marginTop: 20 }}>
            <Text
              font={fonts.notoSansKRRegular}
              style={{ fontSize: 13, color: "#717171" }}
            >
              아직 회원이 아니신가요?
            </Text>
            <ButtonBase style={{ marginLeft: 7 }} onClick={handleClickSignUp}>
              <Text
                font={fonts.notoSansKRMedium}
                style={{ fontSize: 13, color: "#333333" }}
              >
                회원가입하기
              </Text>
            </ButtonBase>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: { flex: 1, backgroundColor: "rgb(240,240,240)" },
  close: { padding: "20px 13px" },
  top: { alignItems: "center", paddingTop: 60 },
  bottom: { flex: 1, alignItems: "center", padding: "60px 36px" },
  label: { color: colors.black, fontSize: "4.5vw", marginBottom: 10 },
  social: {
    width: "100%",
    display: "flex",
    padding: "12px 17px",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 5,
    color: "#fff",
    backgroundColor: colors.primary,
  },
  buttonSection: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
