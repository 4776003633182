import moment from "moment-timezone";
import colors from "../libs/colors";
import queryString from "query-string";

export const getSafeLabel = (type) => {
  switch (type) {
    case "NEW":
      return "새상품";
    case "VERYGOOD":
      return "매우좋음";
    case "GOOD":
      return "좋음";
    case "USUALLY":
      return "보통";
  }
};
export const getProductSummaryName = (products) => {
  if (!products) {
    return "";
  }

  let ar = products.split(",").filter((x) => x);
  if (ar.length === 1) {
    return ar[0];
  } else {
    return ar[0] + " 외 " + (ar.length - 1) + "건";
  }
};
export const objToQueryStr = (obj) => {
  Object.keys(obj).map((key) => {
    if (obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    }
  });

  return "?" + queryString.stringify(obj || {});
};
export const getStateLabel = (state, method, type) => {
  if (method === "direct") {
    switch (state) {
      case "request":
        return "접수대기";
      case "appraisal":
        return "감정중";
      case "appraisal-finish":
        return "감정완료";
      case "exchanged":
        if (type === "gold") {
          return "금(GOLD) 교환완료";
        } else if (type === "silver") {
          return "은(SILVER) 교환완료";
        } else {
          return "무형자산 교환완료";
        }
      case "cancel":
        return "신청취소";
      case "return":
        return "교환취소";
      case "reject":
        return "관리자 반려";
      default:
        return "";
    }
  }
  switch (state) {
    case "request":
      return "접수대기";
    case "receiving":
      if (method === "delivery") {
        return "배송중";
      } else {
        return "방문예약";
      }
    case "appraisal":
      return "감정중";
    case "appraisal-finish":
      return "감정완료";
    case "exchanged":
      if (type === "gold") {
        return "금(GOLD) 교환완료";
      } else {
        return "은(SILVER) 교환완료";
      }
    case "cancel":
      return "신청취소";
    case "return":
      return "교환취소";
    case "reject":
      return "관리자 반려";
    default:
      return "";
  }
};
export const getStateColor = (state) => {
  switch (state) {
    case "접수":
      return colors.primary;
    case "배송중":
    case "방문예약":
      return colors.black;
    case "감정중":
      return colors.primary;
    case "감정완료":
      return colors.black;
    case "교환완료":
      return colors.black;
    case "반송신청":
      return colors.red;
    case "취소":
      return colors.gray;
    case "반송대기":
      return colors.black;
    default:
      return "";
  }
};

export const getMethodTimeLabel = (level) => {
  switch (level) {
    case "1":
      return "00:00~03:00";
    case "2":
      return "03:00~06:00";
    case "3":
      return "06:00~09:00";
    case "4":
      return "09:00~12:00";
    case "5":
      return "12:00~15:00";
    case "6":
      return "15:00~18:00";
    case "7":
      return "18:00~21:00";
    case "8":
      return "21:00~24:00";
    default:
      return "";
  }
};

export const formatTime = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).format(format);
  } else {
    return "";
  }
};

export const getCurrentMyPosition = () => {
  const geolocationOptions = {
    timeout: 1000 * 10, // 캐시 사용
    maximumAge: 1000 * 3600 * 24, // 캐시값 사용 가능한 시간
  };
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          resolve({ lat: coords.latitude, lng: coords.longitude });
        },
        () => {
          reject({ lat: 0, lng: 0 });
        },
        geolocationOptions
      );
    } else {
      // Fallback for no geolocation
      reject({ lat: 0, lng: 0 });
    }
  });
};

export const getPurityLabel = (purity, type) => {
  if (type === "gold" || type === "금" || type === "GOLD") {
    switch (String(purity)) {
      case "1":
        return "14K(585)";
      case "2":
        return "18K(750)";

      case "3":
        return "24K(999)";
    }
  } else {
    switch (String(purity)) {
      case "1":
        return "기타";
      case "2":
        return "925";
      case "3":
        return "999이상";
    }
  }
};

export const numFormat = (num) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return "0";
  }
};
export const phoneFormat = (str) => {
  if (str) {
    return str
      .replace(/[^0-9*]/g, "")
      .replace(
        /(^02|^0505|^1[0-9*]{3}|^0[0-9*]{2})([0-9*]+)?([0-9*]{4})$/,
        "$1-$2-$3"
      )
      .replace("--", "-");
  } else {
    return "";
  }
};
