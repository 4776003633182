import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

export default function SearchTopbar({
  fixed,
  title,
  value,
  initOn,
  onChange,
}) {
  const classes = useStyle();
  const history = useHistory();
  const [search, setSearch] = useState(false);
  const [keyword, setKeyword] = useState();

  useEffect(() => {
    setSearch(initOn);
  }, [initOn]);

  useEffect(() => {
    value !== undefined && setKeyword(value || "");
  }, [value]);

  useEffect(() => {
    let tm = setTimeout(() => {
      if (keyword !== value) {
        onChange(keyword);
      }
    }, 500);
    return () => {
      clearTimeout(tm);
    };
  }, []);

  if (search) {
    return (
      <Flex
        className={classes.root + " " + (fixed && classes.rootfixed)}
        style={{ borderBottom: "1px solid #eee" }}
        row
      >
        <Flex row className={classes.inputWrap + " row-between"}>
          <InputBase
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className={classes.input}
            placeholder="브랜드, 제품명"
          />
          <ButtonBase onClick={() => { setSearch(false); }} className={classes.textButton2}>
            <img src={images.search} />
          </ButtonBase>
        </Flex>
        <ButtonBase
          onClick={() => {
            setSearch(false);
          }}
          className={classes.searchCancel}
        >
          취소
        </ButtonBase>
      </Flex>
    );
  }
  return (
    <Flex
      className={classes.root + " " + (fixed && classes.rootfixed)}
      style={{ borderBottom: "1px solid #eee" }}
      row
    >
      <ButtonBase
        onClick={() => {
          history.goBack();
        }}
        className={classes.backButton}
      >
        <img
          alt="back"
          src={images.back}
          className={classes.back}
          style={{
            width: "8px",
          }}
        />
      </ButtonBase>
      <Text className={classes.title}>{title}</Text>
      <ButtonBase
        onClick={() => {
          setSearch(true);
        }}
        className={classes.textButton}
      >
        <img src={images.search} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    height: "60px",
    alignItems: "center",
  },
  rootfixed: {
    position: "fixed",
    zIndex: 30,
    top: 0,
    backgroundColor: "#fff",
    right: 0,
    left: 0,
  },
  textButton2: {
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  textButton: {
    paddingRight: "16px",
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  back: {
    width: "8px",
    height: "18px",
    objectFit: "contian",
  },
  backButton: {
    padding: "18px 16px",
  },
  title: {
    flex: 1,
    color: "#000",
    fontSize: 18,
    lineHeight: 1,
  },
  input: {
    fontSize: 14,
    fontFamily: fonts.notoSansKRRegular,
    padding: "5px 10px",
  },
  searchCancel: {
    fontSize: 14,
    color: "#333",
    paddingRight: 14,
    fontFamily: fonts.notoSansKRMedium,
  },
  inputWrap: {
    flex: 1,
    borderBottom: "1px solid black",
    margin: "0px 20px",
    marginRight: "15px",
  },
});
