const { useQuery } = require("react-query");
import { getAllGoodsList } from "../../libs/apis";

export const useGoodsListQuery = () => {
  const { data: goodsData = [] } = useQuery(
    "goodsAllList",
    () => {
      return getAllGoodsList().then(({ data: { success, data } }) => {
        if (success) {
          return data;
        }
      });
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );

  return { goodsData };
};
