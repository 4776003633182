import { ButtonBase, makeStyles, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import queryString from "query-string";
import { objToQueryStr } from "../../services/utils";
import routes from "../../libs/routes";
import AppleLogin from "react-apple-login";
import consts from "../../libs/consts";
import jwtDecode from "jwt-decode";
import qs from "qs";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { postMessage } from "../../libs/utils";
import InputText from "../../components/input-text/InputText";
import MainLayout from "../../layouts/main/MainLayout";
import StackTopbar from "../../layouts/basic/StackTopbar";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { actionError } from "../../redux/action/ActionActions";
import { signIn } from "../../libs/sign";

export default function SignInId({}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();

  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validation, setValidation] = useState({
    loginId: false,
    password: false,
  });

  const [passwordError, setPasswordError] = useState("");

  const initValidation = () => {
    setValidation({ loginId: false, password: false });
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
  };

  const handleChangeLoginId = (value) => {
    setLoginId(value);
    initValidation();
  };

  const handleClickValidate = () => {
    APIS.getValidateLoginId(loginId).then(({ data: { success, message } }) => {
      if (success) {
        setValidation({ loginId: true, password: false });
      } else {
        dispatch(messageShow(message));
      }
    });
  };

  const handleClickSignUp = () => {
    dispatch(loadingStart);
    APIS.postSignUpId({ loginId, password, confirmPassword })
      .then(({ data: { success, message, data } }) => {
        if (success) {
          const { authToken } = data;
          signIn(authToken);
          history.replace(routes.signUp, { loginId });
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const changeTimer = useRef(null);
  useEffect(() => {
    if (changeTimer.current) {
      clearTimeout(changeTimer.current);
    }

    if (password) {
      changeTimer.current = setTimeout(() => {
        APIS.postValidatePassword({ password }).then(
          ({ data: { success, message } }) => {
            if (success) {
              setPasswordError("");
              setValidation({ ...validation, password: true });
            } else {
              setPasswordError(message);
              setValidation({ ...validation, password: false });
            }
          }
        );
      }, 800);
    } else {
      setPasswordError("");
      setValidation({ ...validation, password: false });
    }
  }, [password]);

  const BottomButton = () => {
    if (!validation.loginId) {
      const disabled = !loginId;
      return (
        <ButtonBase
          className={`${classes.bottomButton} ${
            disabled && classes.buttonDisabled
          }`}
          onClick={handleClickValidate}
          disabled={disabled}
        >
          <Text font={fonts.notoSansKRMedium}>아이디 중복확인</Text>
        </ButtonBase>
      );
    } else {
      const disabled = !(validation.password && password === confirmPassword);

      return (
        <ButtonBase
          className={`${classes.bottomButton} ${
            disabled && classes.buttonDisabled
          }`}
          onClick={handleClickSignUp}
          disabled={disabled}
        >
          <Text font={fonts.notoSansKRMedium}>회원가입</Text>
        </ButtonBase>
      );
    }
  };

  const confirmPasswordError = confirmPassword && password !== confirmPassword;
  return (
    <BasicLayout>
      <StackTopbar
        border
        title="회원가입"
        // style={{ backgroundColor: "rgb(240,240,240)" }}
      />
      <Flex className={classes.root}>
        <Flex className={classes.content}>
          <Text className={classes.titleText}>
            <Text
              font={fonts.notoSansKRBold}
              style={{ fontSize: 20, borderTop: "2px solid" }}
            >
              아이디・비밀번호
            </Text>
            를<br />
            입력해주세요.
          </Text>

          <Flex style={{ height: 30 }} />
          <InputText
            label="아이디 입력"
            className={classes.inputSection}
            value={loginId}
            onChange={handleChangeLoginId}
            helperText={validation.loginId ? "사용가능한 아이디입니다." : ""}
          />
          {validation.loginId && (
            <>
              <InputText
                type="password"
                label="비밀번호 입력"
                className={classes.inputSection}
                value={password}
                onChange={setPassword}
                error={!!(password && passwordError)}
                helperText={
                  password ? passwordError || "사용가능한 비밀번호입니다." : ""
                }
              />
              <InputText
                type="password"
                label="비밀번호 확인"
                className={classes.inputSection}
                value={confirmPassword}
                onChange={setConfirmPassword}
                error={confirmPasswordError}
                helperText={
                  confirmPassword
                    ? confirmPasswordError
                      ? "비밀번호가 일치하지 않습니다."
                      : "비밀번호가 일치합니다."
                    : ""
                }
              />
            </>
          )}
        </Flex>

        <BottomButton />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: { flex: 1, padding: "28px 25px" },
  content: { flex: 1, alignItems: "center", padding: "0px 20px" },
  titleText: {
    fontFamily: fonts.notoSansKRRegular,
    lineHeight: "25px",
    textAlign: "center",
  },

  inputSection: { minHeight: 80 },

  bottomButton: {
    width: "100%",
    display: "flex",
    padding: "12px 17px",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 5,
    color: "#fff",
    backgroundColor: colors.primary,
  },

  buttonDisabled: { backgroundColor: "#f4f4f4", color: "#c2c2c2" },
});
