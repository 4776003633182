// Actions
const UPDATE = "marketPrice/UPDATE";

const INIT_STATE = {
  updatedAt: "",
  gold: { current: 0, closing: 0 },
  silver: { current: 0, closing: 0 },
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

// Action 생성자

export function updateMarketPrice(payload) {
  return { type: UPDATE, payload };
}
