import React from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import fonts from "../../../../libs/fonts";
import tradeBuy from "../../../../assets/images/trade_buy.webp";
import tradeSell from "../../../../assets/images/trade_sell.webp";
import historyWithdraw from "../../../../assets/images/history_withdraw.webp";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import Span from "../../../../components/text/Span";
import { formatTime, numberWithCommas } from "../../../../libs/utils";

const WithdrawItem = ({
  requestAt,
  tradeType,
  requestAssetType,
  requestGram,
  contractGram,
  requestPrice,
  requestKrw,
  userFee,
  ...rest
}) => {
  return (
    <Container>
      <Header>
        <WithdrawIcon />
        <StatusLabel>대기</StatusLabel>
        <TimeLabel>{formatTime(requestAt, "YYYY.MM.DD HH:mm")}</TimeLabel>
      </Header>
      <ItemType>
        <img src={historyWithdraw} width={18} /> 출금
      </ItemType>
      <SpaceBetween style={{ alignItems: "flex-end" }}>
        <Span font={fonts.notoSansKRBold} size={17} spacing={-0.34}>
          KRW
        </Span>

        <Span spacing={-0.32}>
          출금액
          <Span
            style={{ marginLeft: 3 }}
            font={fonts.spoqa}
            weight="bold"
            spacing={-0.36}
          >
            {numberWithCommas(requestKrw)}
          </Span>
          원
        </Span>
      </SpaceBetween>
      <FeeWrap>
        <Span size={14} spacing={-0.28} color="#666666">
          수수료
          <Span
            font={fonts.spoqa}
            weight="bold"
            size={15}
            spacing={-0.3}
            style={{ marginLeft: 6 }}
          >
            {numberWithCommas(userFee)}
          </Span>
          원
        </Span>
      </FeeWrap>
    </Container>
  );
};

const Container = styled(Flex)`
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Header = styled(Flex)`
  flex-direction: row;
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;

const StatusLabel = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #e76e26;
`;
const TimeLabel = styled(Flex)`
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid #999999;
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #666666;
`;

const WithdrawIcon = styled(Flex)`
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(00, 00, 00, 0.1);

  &::before {
    width: 50%;
    height: 50%;
    background: #ffb100;
    content: "";
  }
`;

const ItemType = styled(Flex)`
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #222222;
  padding: 14px 0 8px 0;
`;

const FeeWrap = styled(Flex)`
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  margin: 7px 0 15px 0;
`;

export default WithdrawItem;
