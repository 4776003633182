var BaseURL;
if (process.env.NODE_ENV === "development") {
  BaseURL = "http://localhost:8181";
} else {
  BaseURL = "http://52.78.242.118:8181";
}

BaseURL = process.env.REACT_APP_API_URL;

export default {
  token: "gumtoken",
  drawerMinWidth: 250,
  accessToken: "goldmarket_accessToken",
  apiBaseUrl: BaseURL,
  s3BaseUrl: process.env.REACT_APP_S3_BASE_URL,
};

export const SERVICE_TYPE = process.env.REACT_APP_SERVICE_TYPE;

const STORAGE_KEY_PREFIX = "kumbang.";
export const STORAGE_KEY = {
  ACCESS_TOKEN: STORAGE_KEY_PREFIX + "accessToken",
  FIRST_LOAD: STORAGE_KEY_PREFIX + "firstLoad",
  NO_COACHING: STORAGE_KEY_PREFIX + "noCoaching.20220822",
  READ_BOTTOM_POPUPS: STORAGE_KEY_PREFIX + "readBottomPopups",
  POPUP_EXPIRES: STORAGE_KEY_PREFIX + "popupExpires",
  USER_AGENT: STORAGE_KEY_PREFIX + "userAgent",
};

export const SAFE_TRADE_STATUS = {
  INBOUND: ["INBOUND_WAIT", "INBOUND_COMPLETE", "INBOUND_CANCEL"],
  VERIFY: ["VERIFY_WAIT", "VERIFY_COMPLETE"],
  SELL: ["RETURN"],
};

export const APP_VERSION = process.env.REACT_APP_VERSION;

export const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
export const KAKAO_CHANNEL_PUBLIC_ID =
  process.env.REACT_APP_KAKAO_CHANNEL_PUBLIC_ID;

export const PHONE_CODE = [
  "010",
  "011",
  "016",
  "017",
  "018",
  "019",
  "02",
  "031",
  "032",
  "033",
  "041",
  "042",
  "043",
  "044",
  "051",
  "052",
  "053",
  "054",
  "055",
  "061",
  "062",
  "063",
  "064",
];
