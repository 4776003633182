import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ShopItem from "../../components/shop-item/ShopItem";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function Shops({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <BasicLayout
      stackTopbar={{
        title: "매장 안내",
        border: true,
      }}
    >
      <Text font={fonts.notoSansKRMedium} className={classes.label}>
        가까운 매장
      </Text>
      <ShopItem />

      <Text font={fonts.notoSansKRMedium} className={classes.me}>
        전체매장
      </Text>
      <Flex className={classes.list} row>
        <Flex className={classes.list1}>
          {[{}, {}, {}, {}, {}, {}].map((x, i) => {
            return (
              <ButtonBase
                style={{
                  color: i === 0 ? colors.text : "#999",
                  borderTop: i === 0 ? "1px solid #eee" : "",
                  borderLeft: i === 0 ? "1px solid #eee" : "",
                  borderBottom: i === 0 ? "1px solid #eee" : "",
                  borderRight: i === 0 ? "" : "1px solid #eee",
                }}
                className={classes.category}
                key={i.toString()}
              >
                서울
              </ButtonBase>
            );
          })}
        </Flex>
        <Flex className={classes.list2}>
          {[{}, {}, {}, {}, {}, {}].map((x, i) => {
            return (
              <ButtonBase
                onClick={() => {
                  history.push(routes.shopDetail("1"));
                }}
                style={{
                  color: i === 0 ? colors.primary : colors.text,
                }}
                className={classes.comp}
                key={i.toString()}
              >
                서울
              </ButtonBase>
            );
          })}
        </Flex>
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    color: colors.text,
    margin: "13px 13px 0px 13px",
    fontSize: "14px",
    borderBottom: "1px solid #eee",
    padding: "13px 0px",
  },
  me: {
    marginTop: 30,
    margin: "0px 14px",
    fontSize: "14px",
    color: colors.text,
  },
  list: {
    alignSelf: "stretch",
    margin: "0px 13px",
    marginTop: "16px",
  },
  list1: {
    flex: 1,
  },
  list2: {
    flex: 3,
    borderTop: "1px solid #eee",
    borderRight: "1px solid #eee",
    borderBottom: "1px solid #eee",
  },
  category: {
    padding: "13px",
    color: "#999999",
    textAlign: "center",
  },
  comp: {
    flexDirection: "column",
    fontSize: "14px",
    alignItems: "flex-start",
    display: "flex",
    textAlign: "left",
    alignSelf: "stretch",
    padding: "10px 20px",
  },
});
