import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { toastShow } from "../../redux/toast/ToastActions";
import axios from "axios";
import consts from "../../libs/consts";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";
import { loadingStart, loadingEnd } from "../../redux/loading/LoadingActions";

export default function Password({ onConfirm, onClose, inputprice }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { securityPassword, id } = useSelector((s) => s.user);
  const [inputpass, setinputpass] = useState("");

  const lock = useRef(false);

  const secretPassCker = async () => {
    if (lock.current) {
      return false;
    }
    if (inputpass === "") {
      dispatch(toastShow("보안 비밀번호를 입력해주세요."));
      return;
    }

    lock.current = true;
    dispatch(loadingStart);

    var params = {
      requestAmount: inputprice,
      securityPasswd: inputpass,
    };

    await APIS.withdrawalSend(params).then(
      ({ data: { success, data, message } }) => {
        if (success) {
          onConfirm();
          onClose();
        } else {
          dispatch(actionError(message));
        }
      }
    );

    dispatch(loadingEnd);
    lock.current = false;
  };
  return (
    <Flex className={classes.root}>
      <ButtonBase onClick={onClose} className={classes.button}>
        취소
      </ButtonBase>

      <Text font={fonts.notoSansKRBold} className={classes.bold}>
        보안 비밀번호
      </Text>

      <input
        type="number"
        value={inputpass}
        placeholder="비밀번호 입력"
        pattern="\d*"
        inputMode="numeric"
        className={classes.input}
        onChange={(e) => {
          var values = e.target.value;
          if (values.length < 7) {
            setinputpass(e.target.value);
          }
        }}
      />
      <Button
        onClick={() => secretPassCker()}
        label="출금요청"
        className={classes.cb}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.background,
    left: 0,
    zIndex: 20,
  },
  button: {
    alignSelf: "flex-end",
    padding: "20px 16px",
    color: colors.text,
    fontSize: "18px",
  },
  bold: {
    color: colors.text,
    fontSize: "20px",
    margin: "80px 30px 50px 30px",
  },
  input: {
    borderBottom: "2px solid black",
    fontSize: 16,
    color: "#333",
    margin: "0px 30px",
    padding: "10px",
    lineHeight: 1,
    border: "none",
    background: "none",
    WebkitTextSecurity: "disc",
  },
  cb: {
    margin: "20px 30px",
  },
});
