import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../../components/flex/Flex";

export default function DataButton({ label, image, last, onClickfunc, funcdata }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <ButtonBase
      style={{
        display: "flex",
        alignSelf: "stretch",
        borderRadius: 3,
        boxSizing: "border-box",

        backgroundColor: "#eeeeee",
        ...(label && {
          padding: "8px 0px",
          flex: 1,
          color: "#999999",
          marginRight: last ? "0px" : "5px",
        }),
        ...(image && {
          padding: "10px",
          marginLeft: "6px",
        }),
      }}
      onClick={() => { if(onClickfunc){ onClickfunc(funcdata); } }} 
  
    >
      {label && label}
      {image && <img alt="db" src={image} className={classes.icon} />}
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  icon: {
    width: 14,

    height: 14,
    objectFit: "contain",
  },
});
