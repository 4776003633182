import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";

export default function ImageButton({
  onClick,
  align,
  className,
  size = 16,
  src,
  alt,
}) {
  const classes = useStyle();
  return (
    <ButtonBase
      onClick={onClick}
      style={{
        alignSelf: (() => {
          switch (align) {
            case "right":
              return "flex-end";
            default:
              return "flex-start";
          }
        })(),
      }}
      className={`${classes.root} ${className}`}
    >
      <img
        alt={`${alt}-alt`}
        src={src}
        style={{
          objectFit: "contain",
          width: size,
          height: size,
        }}
      />
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
