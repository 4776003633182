import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";
import DateRange from "../../components/date-range/DateRange";
import Flex from "../../components/flex/Flex";
import List from "../../components/list/List";
import Tables from "../../components/tables/Tables";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import ListItem from "./ListItem";
import * as APIS from "../../libs/apis";
import { numberWithCommas, formatTime, calcDate } from "../../libs/utils";

export default function AssetHistory({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const { userId } = useSelector((s) => s.auth);

  const [historyList, setHistoryList] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: formatTime(calcDate("M", -1)),
    end: formatTime(),
  });

  const [holdings, setHoldings] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    acceptableKrw: 0,
    acceptableGold: 0,
    acceptableSilver: 0,
  });

  useEffect(() => {
    APIS.getUserHoldingsInfo(userId).then(({ data: { success, data } }) => {
      success && setHoldings(data);
    });
  }, []);

  useEffect(() => {
    const { start, end } = dateRange;
    APIS.getWonsHistory(end, start).then(({ data }) => {
      setHistoryList(data);
    });
  }, [dateRange]);

  const { start, end } = dateRange;
  return (
    <BasicLayout stackTopbar={{ title: "원화 결제 내역" }}>
      <Flex row className={classes.card + " shadow"}>
        <Text className={classes.label}>사용가능 원화</Text>
        <Text className={classes.asset} font={fonts.notoSansKRMedium}>
          {numberWithCommas(holdings.acceptableKrw)}원
        </Text>
      </Flex>
      <DateRange
        className={classes.range}
        start={start}
        end={end}
        onChange={({ start, end }) => {
          setDateRange({ start, end });
        }}
      />

      <List
        labelLength={historyList.length}
        data={historyList}
        renderItem={(item, index) => {
          return <ListItem item={item} key={index.toString()} />;
        }}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  card: {
    padding: "16px 13px",
    boxSizing: "border-box",
    margin: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    color: colors.text,
    fontSize: "14px",
  },
  asset: {
    color: colors.text,
    fontSize: "20px",
  },
  range: {
    margin: "20px 25px",
  },
});
