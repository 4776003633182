import React, { useEffect, useRef, useState } from "react";

function ReviewCollection({ className, style, ...props }) {
  const parentRef = useRef();

  useEffect(() => {
    if (parentRef) {
      console.log(parentRef.current.getElementsByTagName("iframe"));
    }
  }, [parentRef]);

  return (
    <div {...props} ref={parentRef} className={className} style={style}>
      <div className="vreview-review-collection-widget"></div>
    </div>
  );
}

export default React.memo(ReviewCollection);
