import {
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import ProductBasicInformation from "../../components/product-basic-information/ProductBasicInformation";
import ProductImages from "../../components/product-images/ProductImages";
import ProductInquiry from "../../components/product-inquiry/ProductInquiry";
import ProductReview from "../../components/product-review/ProductReview";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { requestNativeLogin } from "../../libs/utils";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import { loadingStart, loadingEnd } from "../../redux/loading/LoadingActions";

import Information from "./Information";
import * as APIS from "../../libs/apis";
import AspectRatioBox from "../../components/common/AspectRatioBox";
import { Swiper, SwiperSlide } from "swiper/react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  ReviewBoard,
  ReviewCount,
  ReviewSummary,
} from "../../components/vreviewWidget";
import TopButton from "../../components/button/TopButton";
import StorageFeePayModal from "../mypage/storageFee/StorageFeePayModal";

const useGetGoodsDetail = (id) => {
  const queryClient = useQueryClient();

  const [detail, setDetail] = useState({});

  const { data, isLoading } = useQuery(
    ["goodsDetail", id],
    () => APIS.getGoodsDetail(id),
    {
      select: (data) => data.data?.data,
      onSuccess: (result) => {
        const totalList = queryClient.getQueryData("goodsAllList");

        let goodsItem =
          Array.isArray(totalList) && totalList.find((item) => item.id === id);
        if (
          goodsItem &&
          (goodsItem.fees !== result.fees ||
            goodsItem.totalPrice - goodsItem.fees !== result.price)
        ) {
          queryClient.setQueryData("goodsAllList", (oldData) => {
            return oldData
              ? oldData.map((item) =>
                  item.id === result.id
                    ? {
                        ...item,
                        totalPrice: result.price + result.fees,
                        fees: result.fees,
                      }
                    : item
                )
              : oldData;
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setDetail(data);
    }
  }, [data]);

  return { detail, isLoading };
};

export default function PhysicalWithdrawalPDetail({ navigate }) {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const [tab, setTab] = useState("DETAIL_IMAGE");
  const [data, setData] = useState([{}, {}, {}, {}]);
  // const [detail, setdetail] = useState();
  const [stock, setstock] = useState(1);
  const [seloption, setseloption] = useState("");
  const [acceptable, setAcceptable] = useState({});
  const [showBackButton, setShowBackButton] = useState(false);
  const [resize, setResize] = useState(window.innerWidth + 312);
  const [isVisible, setIsVisible] = useState(false);

  // 상세설명 이미지 보기에 데이터 없을 경우
  const [isOldVersion, setIsOldVersion] = useState(false);

  const authState = useSelector(({ auth }) => auth);

  const contentRef = useRef(null);

  const { detail, isLoading } = useGetGoodsDetail(id);

  const onContentClick = () => {
    contentRef.current.scrollIntoView({ behavior: "smooth" });
    setTab("REVIEW");
  };

  const handleScroll = useCallback(() => {
    if (window.scrollY > resize) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [window.scrollY]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (authState.isSigned) {
      APIS.getUserHoldingsInfo().then(({ data: { data } }) => {
        setAcceptable(data);
      });
    }
  }, []);

  // useEffect(() => {
  //   dispatch(loadingStart);
  //   APIS.getGoodsDetail(id)
  //     .then(({ data: { success, data, message } }) => {
  //       if (success) {
  //         setdetail(data);

  //         if (!data.detailImages) {
  //           setIsOldVersion(true);
  //           setTab("DETAIL");
  //         }
  //       } else {
  //         dispatch(actionError(message));
  //       }
  //     })
  //     .finally(() => dispatch(loadingEnd));
  // }, [id]);

  const handleClickPost = () => {
    if (acceptable?.storageFee > 0) {
      setIsVisible(true);
      return;
    }
    if (!authState.isSigned) {
      dispatch(
        actionShow(
          "로그인 후 이용 가능합니다.",
          ["닫기", "로그인"],
          [() => {}, () => requestNativeLogin(history)]
        )
      );
      return;
    }

    if (stock < 1) {
      dispatch(actionError("수량을 입력해주세요."));
      return;
    }

    // 노출 상태가 visible인 옵션의 배열
    const options =
      detail?.goodsOptions
        ?.filter((item) => item.visible === "VISIBLE")
        .map((item) => item.optionValue) || [];

    if (detail?.options === true && options.length && seloption === "") {
      dispatch(actionError("옵션을 선택해주세요."));
      return;
    }

    if (
      detail["goldOrSilver"] === "GOLD" &&
      acceptable.acceptableGold < detail.gram * stock
    ) {
      dispatch(
        actionShow(
          "사용가능 금(GOLD)이 부족합니다.",
          ["닫기", "매수하기"],
          [
            () => {},
            () => navigate(routes.trading, { type: "gold", method: "buy" }),
          ]
        )
      );
      return;
    }

    if (
      detail["goldOrSilver"] === "SILVER" &&
      acceptable.acceptableSilver < detail.gram * stock
    ) {
      dispatch(
        actionShow(
          "사용가능 은(SILVER)이 부족합니다.",
          ["닫기", "매수하기"],
          [
            () => {},
            () => navigate(routes.trading, { type: "silver", method: "buy" }),
          ]
        )
      );
      return;
    }

    if (
      acceptable.acceptableKrw + acceptable.acceptablePoint <
      detail.fees * stock
    ) {
      dispatch(
        actionShow(
          "사용가능 원화(KRW)가 부족합니다.",
          ["닫기", "입금하기"],
          [() => {}, () => navigate(routes.depositWithdrawal)]
        )
      );
      return;
    }

    navigate(routes.order, {
      type: "physical-withdrawal",
      detail: detail,
      id: id,
      sPrice: state.sPrice,
      settype: state.settype,
      stock: stock,
      seloption: seloption,
    });
  };

  const detailImages = detail?.detailImages || [];
  return (
    <BasicLayout
      white
      stackTopbar={{
        title: "상품 상세",
        border: true,
      }}
    >
      <StorageFeePayModal
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        totalFee={acceptable?.storageFee}
        onConfirm={() => {
          navigate(routes.commonSecureKeypad, {
            type: "STORAGE_FEE",
            payload: {
              totalFeeKrw: `${acceptable?.storageFee}`,
            },
            buttonText: "납부하기",
          });
        }}
        payType={"withdrawal"}
      />
      {showBackButton && (
        <>
          <TopButton />
        </>
      )}
      {/* 상세 페이지에서는 상품 아이디 삽입 로직 필요 */}
      <input type="hidden" vreview-pdp-product-id={detail?.id} />
      <ImageSwiper
        images={detail?.imageIds?.map(({ path }) => {
          return { src: consts.s3BaseUrl + "/" + path };
        })}
      />
      {detail && (
        <>
          {/* <ProductImages imagesData={detail} /> */}
          <Information
            x={detail}
            sPrice={state.sPrice}
            setstock={setstock}
            stock={stock}
            setseloption={setseloption}
            gotoReview={() => {
              onContentClick();
              setTab("REVIEW");
            }}
          />
        </>
      )}
      <ReviewSummary />

      <div className="infoTabWrap" ref={contentRef}></div>
      <Tabs
        value={tab}
        TabIndicatorProps={{ style: { background: "black" } }}
        style={{
          borderBottom: "1px solid #eee",
          color: colors.text,
        }}
        onChange={(e, v) => {
          //console.log(v);
          setTab(v);
        }}
        indicatorColor="primary"
        textColor={"inherit"}
        variant="fullWidth"
        className={classes.tabs}
      >
        {!isOldVersion && (
          <Tab color={colors.gray} value="DETAIL_IMAGE" label="상품설명" />
        )}
        {/* <Tab color={colors.gray} value="INFO" label="기본정보" />
        <Tab color={colors.gray} value="DETAIL" label="상세정보" /> */}
        <Tab color={colors.gray} value="REVIEW" label={<ReviewCount />} />
        {/* <Tab
          color={colors.gray}
          //value="direct"
          label="상품문의"
        />
        <Tab
          color={colors.gray}
          //value="direct"
          label="상품후기"
        /> */}
      </Tabs>
      <Flex className={classes.main}>
        {tab === "DETAIL_IMAGE" && (
          <>
            {detailImages.map(({ path }, i) => (
              <img
                key={i.toString()}
                src={consts.s3BaseUrl + path}
                style={{ width: "100%" }}
              />
            ))}
            {detail?.goodsInfoDtoList && (
              <ProductBasicInformation data={detail.goodsInfoDtoList} />
            )}
            {detail?.comment &&
            typeof detail?.comment === "string" &&
            detail?.comment.includes("\n") ? (
              <Flex style={{ padding: 13, paddingBottom: 40 }}>
                {detail?.comment.split("\n").map((comment, k) => {
                  //this.props.data.content: 내용
                  return (
                    <span key={k}>
                      {comment}
                      <br />
                    </span>
                  );
                })}
              </Flex>
            ) : null}
          </>
        )}
        {tab === "INFO" && (
          <ProductBasicInformation data={detail.goodsInfoDtoList} />
        )}
        {tab === "DETAIL" && (
          <Flex style={{ padding: 13, paddingBottom: 40 }}>
            {detail?.comment.split("\n").map((comment, k) => {
              //this.props.data.content: 내용
              return (
                <span key={k}>
                  {comment}
                  <br />
                </span>
              );
            })}
          </Flex>
        )}
        {tab === "REVIEW" && <ReviewBoard />}
        <div style={{ height: "100px" }} />
      </Flex>
      <Button
        onClick={handleClickPost}
        label="인출하기"
        className="fixed-button"
      />
    </BasicLayout>
  );
}

const ImageSwiper = ({ images = [] }) => {
  const classes = useStyle();
  const [swiperIndex, setSwiperIndex] = useState(0);
  return (
    <AspectRatioBox>
      {!!images.length && (
        <Swiper
          onRealIndexChange={(swiper) => setSwiperIndex(swiper.realIndex)}
          style={{ height: "100%" }}
          loop={!!(images.length > 1)}
        >
          {images.map(({ src }, i) => (
            <SwiperSlide key={i.toString()}>
              <img src={src} style={{ height: "100%", objectFit: "contain" }} />
            </SwiperSlide>
          ))}
          {images.length > 1 && (
            <div
              style={{
                width: images.length * 6 + (images.length - 1) * 10,
              }}
              className={classes.indicatorWrapper}
            >
              {images.map((_, i) => (
                <div
                  key={i.toString()}
                  className={classes.indicator}
                  style={{
                    background: swiperIndex === i ? colors.primary : "#aaaaaa",
                  }}
                />
              ))}
            </div>
          )}
        </Swiper>
      )}
    </AspectRatioBox>
  );
};

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
  textButton: {
    paddingRight: "16px",
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  main: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "10px",
    fontSize: "12px",
    color: colors.text,
  },

  indicatorWrapper: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    zIndex: 1,
    justifyContent: "space-between",
    bottom: "10%",
    left: "50%",
    transform: "translate(-50%)",
    maxWidth: "100%",
  },
  indicator: { width: 6, height: 6, borderRadius: 100 },
});
