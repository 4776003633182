import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import fonts from "../../libs/fonts";
import { WITHDRAW_ACCOUNT_REQUIRED } from "../../libs/messages";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import { phoneFormat } from "../../services/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function AppraiseDirect({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const userReducer = useSelector((s) => s.user);
  const state = history.location.state;

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getAccountInfo("WITHDRAW")
      .then(({ data: { data } }) => {
        if (data?.bankAccountStatus !== "COMPLETED") {
          dispatch(
            messageError(WITHDRAW_ACCOUNT_REQUIRED, () =>
              history.push(routes.manageAccount)
            )
          );
          history.goBack();
        }
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  const supply = () => {
    if (!state?.shop?.id) {
      dispatch(messageError("의뢰하실 매장을 선택해 주새요."));
    } else {
      APIS.postAppraiseRequest({
        acceptTermsYn: "Y",
        // method: "현장감정",
        method: "DIRECT_VISIT",
        shopId: parseInt(state.shop.id),
        userId: userReducer.id,
      }).then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(messageError(message));
        } else {
          const appraisalRequestId = data.id;
          history.push(routes.exchange, {
            deliveryComplete: true,
            appraisalRequestId: appraisalRequestId,
          });
        }
      });
    }
  };

  if (!userReducer.id) {
    return <Redirect to={routes.appraise} />;
  }
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "현장 감정 접수하기" }}>
      <Text className={classes.label} font={fonts.notoSansKRBold}>
        현장 감정을 의뢰하실
        <br />
        매장을 선택해 주세요.
      </Text>
      <ButtonMini
        onClick={() => {
          history.push(routes.authFindShop, {
            from: routes.appraiseDirect,
          });
        }}
        label="매장 찾기"
        style={{ marginBottom: state?.shop ? 0 : 30 }}
        className={classes.button}
      />
      {Boolean(state?.shop) && (
        <ShopItemSimple
          className={classes.shopItem}
          name={state?.shop.name}
          address={state?.shop.address}
          addressDetail={state?.shop.addressDetail}
          distance={state?.shop.distance}
        />
      )}
      <Text className={classes.info1}>접수자</Text>
      <Text font={fonts.notoSansKRMedium} className={classes.info2}>
        {userReducer.name}({phoneFormat(userReducer.phone)})
      </Text>
      <Button
        disabled={!Boolean(state?.shop)}
        onClick={supply}
        label="현장 감정 접수하기"
        className="fixed-button"
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "20px",
    color: "#333",
    margin: "50px 30px 30px 30px",
  },
  button: {
    margin: "0px 30px",
    alignSelf: "flex-start",
  },
  shopItem: {
    margin: "0px 30px",
    marginTop: 10,
  },
  info1: {
    fontSize: 12,
    color: "#666",
    margin: "0px 30px",
  },
  info2: {
    margin: "0px 30px",
    color: "#333",
    fontSize: 16,
  },
});
