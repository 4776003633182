import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import Images from "../../components/images/Images";
import InputShadow from "../../components/input-shadow/InputShadow";
import PurityPopup from "../../components/purity-popup/PurityPopup";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import appraisepicGuide from "../../assets/images/appraisepic_guide.webp";

import routes from "../../libs/routes";
import { actionError } from "../../redux/action/ActionActions";
import { messageError } from "../../redux/message/MessageActions";

export default function AppraiseRegist3({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const inputRef = useRef();
  const { gram, quantity, purity, appraisalItem } = state;

  useEffect(() => {
    if (!appraisalItem || !gram || !quantity || !purity) {
      history.goBack();
    }
  }, []);
  const { faceImage, leftSideImage, rightSideImage, modelNameImage } = state;

  // const faceImage = state.faceImage;
  // const leftSideImage = state.leftSideImage;
  // const rightSideImage = state.rightSideImage;
  // const modelNameImage = state.modelNameImage;
  // const images = [faceImage, leftSideImage, rightSideImage, modelNameImage];

  const setFaceImage = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        faceImage: v,
      },
    });
  const setLeftSideImage = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        leftSideImage: v,
      },
    });
  const setRightSideImage = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        rightSideImage: v,
      },
    });
  const setModelNameImage = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        modelNameImage: v,
      },
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!file.type.match(/image-*/)) {
        dispatch(actionError("등록할 수 없는 파일입니다."));
        return;
      }

      const fs = new FileReader();
      fs.onloadend = () => {
        let data = {
          file,
          uri: fs.result,
        };
        switch (inputRef.current.index) {
          case 0:
            setFaceImage(data);
            break;
          case 1:
            setLeftSideImage(data);
            break;
          case 2:
            setRightSideImage(data);
            break;
          case 3:
            setModelNameImage(data);
            break;
        }
      };
      fs.readAsDataURL(file);
    }

    e.target.value = "";
  };
  const handleClickNext = () => {
    if (
      [faceImage, leftSideImage, rightSideImage, modelNameImage].some(
        (image) => !!image
      )
    ) {
      history.push(routes.appraiseRegist, {
        ...history.location.state,
        step: 4,
      });
    } else {
      dispatch(messageError("제품의 사진을 1장 이상 등록해주세요."));
    }
  };

  return (
    <div className={classes.root}>
      <Text className={classes.title} font={fonts.notoSansKRBold}>
        제품의 사진을
        <br />
        등록해 주세요.
      </Text>

      <div className={classes.images}>
        {[faceImage, leftSideImage, rightSideImage, modelNameImage].map(
          (x, i) => {
            return (
              <div
                key={i.toString()}
                style={{ height: "auto" }}
                className={classes.wrap}>
                {x ? (
                  <div
                    className={classes.imageWrap}
                    onClick={() => {
                      inputRef.current.index = i;
                      inputRef.current?.click();
                    }}>
                    <img className={classes.image} src={x.uri} />
                    <ButtonBase
                      onClick={(e) => {
                        e.stopPropagation();
                        switch (i) {
                          case 0:
                            setFaceImage(null);
                            break;
                          case 1:
                            setLeftSideImage(null);
                            break;
                          case 2:
                            setRightSideImage(null);
                            break;
                          case 3:
                            setModelNameImage(null);
                            break;
                        }
                      }}
                      className={classes.close}>
                      <img src={images.closewhite} />
                    </ButtonBase>
                  </div>
                ) : (
                  <ButtonBase
                    onClick={() => {
                      inputRef.current.index = i;
                      inputRef.current?.click();
                    }}
                    className={classes.img}>
                    <img className={classes.camera} src={images.camera} />
                  </ButtonBase>
                )}

                {/* <span className={classes.wrapLabel}>
                  {(() => {
                    switch (i) {
                      case 0:
                        return "윗면";
                      case 1:
                        return "앞면";
                      case 2:
                        return "좌측면";
                      case 3:
                        return "우측면";
                    }
                  })()}
                </span> */}
              </div>
            );
          }
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: "none" }}
      />

      <Text className={classes.desc}>
        <Text font={fonts.notoSansKRBold}>제품사진 촬영 가이드</Text>
        <br />
        ㆍ흰색 종이를 바닥에 깔고 촬영해 주세요.
        <br />
        ㆍ자, 생수, 병뚜껑 등 주변 사물을 이용해 크기를 비교해 주세요.
        <br />
        ㆍ제품의 상태를 파악할 수 있게 다양한 각도로 촬영해 주세요.
        <br />
        ㆍ사진은 최소 1장 이상 등록해 주세요.
      </Text>
      <Text
        font={fonts.notoSansKRBold}
        style={{ margin: "0px 30px 10px 30px" }}>
        촬영 예시
      </Text>
      {/* <Flex style={{ position: "relative" }}>
        <span className={classes.gg}>촬영예시</span>
        <span
          style={{
            left: "45%",
            top: "10%",
          }}
          className={classes.guide}>
          윗면
        </span>
        <span
          style={{
            left: "25%",
            top: "45%",
          }}
          className={classes.guide}>
          좌측면
        </span>
        <span
          style={{
            left: "60%",
            top: "48%",
          }}
          className={classes.guide}>
          앞면
        </span>
        <span
          style={{
            left: "75%",
            top: "15%",
          }}
          className={classes.guide}>
          우측면
        </span>
        <img
          src={images.appraisepicguide}
          style={{
            borderRadius: 3,
            margin: "0px 20px 50px 20px",
            height: "150px",
            objectFit: "cover",
          }}
        />
      </Flex> */}
      <img
        src={appraisepicGuide}
        style={{
          borderRadius: 3,
          margin: "0px 30px 50px 30px",
          objectFit: "cover",
        }}
      />
      <div style={{ height: 100 }} />
      <ButtonBase
        onClick={handleClickNext}
        // disabled={
        //   !(faceImage || leftSideImage || rightSideImage || modelNameImage)
        // }
        className={classes.button}>
        다음
      </ButtonBase>
    </div>
  );
}

const useStyle = makeStyles({
  guide: {
    position: "absolute",
    zIndex: 2,
    width: 40,
    height: 40,
    borderRadius: 100,
    fontFamily: fonts.notoSansKRMedium,
    fontSize: 12,
    color: "rgb(255,0,0)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",

    borderStyle: "dashed",
    border: "1px solid rgb(255,0,0)",
  },
  root: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  },

  close: {
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "25%",
    height: "25%",
    position: "absolute",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    zIndex: 3,
    "& img": {
      width: "70%",
      height: "70%",
      objectFit: "contain",
    },
  },
  gg: {
    position: "absolute",
    borderRadius: 3,
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    fontSize: 12,
    padding: "5px 10px",
    left: 30,
    top: 10,
    zIndex: 2,
  },
  img: {
    borderRadius: 3,
    border: "2px solid #7d7d7d",
    borderStyle: "dotted",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 60,
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  imageWrap: {
    position: "relative",
    minHeight: 60,
    maxHeight: 60,
    width: "100%",
    borderRadius: 3,
    cursor: "pointer",
  },
  wrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "8px",
    "&:last-child": {
      marginRight: 0,
    },
    flex: 1,
  },
  wrapLabel: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: "12px",
    color: "#666",
  },
  camera: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  home: {
    width: 20,
    marginRight: 16,
    height: 20,
    objectFit: "contain",
  },
  images: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 26,
    display: "flex",
  },
  title: {
    margin: "35px 30px 0px 30px",
    fontSize: "20px",
    lineHeight: "25px",
    color: colors.text,
  },
  desc: {
    color: "#666",
    fontSize: "11.5px",
    lineHeight: "16px",
    margin: "30px",
    "& span": {
      fontSize: "15px",
      color: "#333",
      fontFamily: fonts.notoSansKRMedium,
      lineHeight: "30px",
    },
  },
  message: {
    borderBottom: "1px solid " + colors.primary,
    alignSelf: "flex-start",
    margin: "5px 30px 30px 30px",
    color: colors.primary,
    fontSize: "14px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  navbar: {
    height: 3,
    backgroundColor: "#dddddd",
    alignSelf: "stretch",
    "& div": {
      height: "100%",
      backgroundColor: "#ff6e0e",
      width: "75%",
      marginLeft: 0,
    },
    position: "fixed",
    right: 0,
    left: 0,
    top: 60,
  },
  topbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    display: "flex",
    backgroundColor: "rgb(241,241,241)",
    alignItems: "center",
  },
  back: {
    "& img": {
      width: 10,
      height: 20,
      objectFit: "contain",
    },
    paddingLeft: 14,
    paddingRight: 11,
  },
  navi: {
    fontSize: 16,
    flex: 1,
    color: "#333333",
  },
  item: {
    width: "30%",
    padding: "20px",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    flexDirection: "column",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "30px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
  button: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    backgroundColor: "#ff6e0e",
    zIndex: 30,
    width: "100%",
    textAlign: "center",
    color: "#fff",
    fontSize: 13.3,
    fontFamily: fonts.notoSansKRBold,
    "&:disabled": { backgroundColor: "#999999" },
  },
});
