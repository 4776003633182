import React, { useLayoutEffect, useRef, useState } from "react";

export default function Image({
  alt,
  src,
  className,
  objectFit,
  size,
  width,
  height,
  sameWidth,
}) {
  const imageRef = useRef();
  const [resizeHeight, setResizeHeight] = useState(false);

  useLayoutEffect(() => {
    const event = () => {
      setResizeHeight(
        sameWidth
          ? imageRef.current.width
          : imageRef.current.height *
              (imageRef.current.offsetWidth / imageRef.current.width)
      );
    };
    if (imageRef.current) {
      if (width && !height) {
        event();
        window.addEventListener("resize", event);
        return () => {
          window.removeEventListener("resize", event);
        };
      }
    }
  }, [imageRef]);
  return (
    <img
      ref={imageRef}
      alt={alt || "i"}
      src={src}
      className={`${className || ""}`}
      style={{
        objectFit: objectFit || "contain",
        ...(width && { width }),
        ...(height && { height }),
        ...(resizeHeight && { height: resizeHeight }),
        ...(size && {
          width: size,
          height: size,
        }),
      }}
    />
  );
}
