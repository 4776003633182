import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import StackTopbar from "../../../layouts/basic/StackTopbar";
import MainLayout from "../../../layouts/main/MainLayout";
import {
  callPhone,
  formatTime,
  numberWithCommas,
  numFormat,
} from "../../../libs/utils";
import * as APIS from "../../../libs/apis";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import ic_ok_green from "../../../assets/images/ic_ok_green.png";
import ic_danger from "../../../assets/images/ic_danger.png";
import ic_phone from "../../../assets/images/ic_phone.png";
import ic_re_ing from "../../../assets/images/ic_re_ing.png";
import ic_re_ok from "../../../assets/images/ic_re_ok.png";
import ic_re_off from "../../../assets/images/ic_re_off.png";
import ic_close from "../../../assets/images/ic_close.png";
import re_bar from "../../../assets/images/re_bar.png";
import ic_re_product_p from "../../../assets/images/ic_re_product_p.png";
import ic_re_no from "../../../assets/images/ic_re_no.png";
import ic_re_product_ing from "../../../assets/images/ic_re_product_ing.png";
import { SAFE_TRADE_STATUS } from "../../../libs/consts";
import BasicLayout from "../../../layouts/basic/BasicLayout";
import {
  actionError,
  actionOpen,
  actionShow,
} from "../../../redux/action/ActionActions";
import routes from "../../../libs/routes";
import { messageShow } from "../../../redux/message/MessageActions";
import Text from "../../../components/text/Text";
import { toastShow } from "../../../redux/toast/ToastActions";

export default function MySafeTradeSell({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history?.location?.state || {};
  const user = useSelector((state) => state.user);

  const [order, setOrder] = useState({});
  const [orderHistory, setOrderHistory] = useState([]);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [withdrawalPopupVisible, setWithdrawalPopupVisible] = useState(false);

  const [item, setItem] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    dispatch(loadingStart);

    const item_id = await APIS.getMySellSafeSell(locationState.id)
      .then(({ data: { success, data } }) => {
        success && setOrder(data);

        return data.item_id;
      })
      .catch(() => {
        dispatch(loadingEnd);
      });

    if (item_id) {
      APIS.getSafeTradeHistory(item_id, "sell")
        .then(({ data: { success, data } }) => {
          success && setOrderHistory(data);
        })
        .finally(() => dispatch(loadingEnd));

      APIS.getSafeTradeItem(item_id).then(
        ({ data: { success, data } }) => success && setItem(data)
      );
    }
  };

  const handleClickCancel = () => {
    dispatch(
      actionShow(
        "안심직거래 판매 접수신청을\n취소하시겠습니까?",
        ["닫기", "신청취소"],
        [
          () => {},
          () => {
            dispatch(loadingStart);
            APIS.deleteMySafeTrade(order.item_id)
              .then(({ data: { success, message } }) => {
                if (success) {
                  dispatch(
                    messageShow("신청 취소되었습니다.", () => history.goBack())
                  );
                } else dispatch(actionError(message));
              })
              .catch(() =>
                dispatch(actionError("알 수 없는 오류가 발생했습니다."))
              )
              .finally(() => dispatch(loadingEnd));
          },
        ]
      )
    );
  };

  const handleClickDetail = () => {
    history.push(routes.mySafeTradeSellDetail, { id: order.item_id });
  };

  const handleClickReturn = () => {
    history.push(routes.mySafeTradeSellReturn, { order });
  };

  const handleClickWithdraw = () => {
    setWithdrawalPopupVisible(false);
    dispatch(loadingStart);
    APIS.deleteMySafeTrade(item_id)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(toastShow("판매가 철회되었습니다."));
          init();
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleClickReturnComplete = () => {
    dispatch(loadingStart);

    APIS.postSafeTradeConfirm(item_id)
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(toastShow("반송 확인되었습니다."));
          init();
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const {
    created_at,
    brand,
    category_name,
    name,
    price,
    shop_name,
    shop_address,
    shop_phone,
    status,
    auth_price,
    item_id,
    is_user_closed,
    cancel_reason,
    state,
    penalty,
    delivery_type,
    fee,
    serial,
  } = order;

  let icon = null;
  let title = "";
  switch (status) {
    case "INBOUND_WAIT":
    case "INBOUND_COMPLETE":
      icon = ic_ok_green;
      title = "신청하신 상품이 접수 되었습니다.";
      break;

    case "INBOUND_CANCEL":
      icon = ic_danger;
      if (is_user_closed) {
        title = "접수신청이 취소되었습니다.";
      } else {
        title = "접수신청이 반려되었습니다.";
      }

      break;

    case "VERIFY_WAIT":
      icon = ic_ok_green;
      title = "감정사가 상품 감정을 시작하였습니다.";
      break;

    case "VERIFY_COMPLETE":
      icon = ic_ok_green;
      title = "상품 감정이 완료 되었습니다.\n감정가를 확인해 주세요.";
      break;

    case "SELL_CONFIRM_WAIT":
    case "SELL_WAIT":
      icon = ic_re_product_p;
      title =
        "관리자가 상품 판매를 위해\n상품 사진 촬영 및 등록을 준비 중입니다.";
      break;

    case "COMPLETE_CLOSE":
      icon = ic_danger;
      title = "접수신청이 반려되었습니다.";
      break;

    case "SELL":
    case "PAYMENT_WAIT":
    case "TRANSFER_RETURN":
    case "TRANSFER_CANCEL":
      icon = ic_re_product_ing;
      title = "상품이 판매 중입니다.";
      break;

    case "PAYMENT_DONE":
      icon = ic_re_product_ing;
      title =
        "구매자가 상품 구매를 위해 결제하였습니다.\n판매완료시 대금이 정산됩니다.";
      break;

    case "RETURN":
      icon = ic_ok_green;
      title = "판매가 철회되었습니다.\n반송절차가 진행됩니다.";
      break;

    case "DELIVERY":
      if (delivery_type === "BUY") {
        icon = ic_re_product_ing;
        title = "상품이 출고되었습니다.";
      } else {
        icon = ic_ok_green;
        title = "판매가 철회되었습니다.\n반송절차가 진행됩니다.";
      }
      break;
    case "TRANSFER_WAIT":
      if (delivery_type === "BUY") {
        icon = ic_re_product_ing;
        title =
          "상품이 구매 접수 매장에 도착하였습니다.\n구매자 수령 대기중입니다.";
      } else {
        icon = ic_ok_green;
        title = "상품이 매장에 도착했습니다.";
      }
      break;

    case "TRANSFER_COMPLETE":
      icon = ic_re_product_ing;
      title =
        "상품이 구매 접수 매장에 도착하였습니다.\n구매자 수령 대기중입니다.";
      break;

    case "COMPLETE":
      icon = ic_ok_green;
      title = "판매가 완료되었습니다.\n판매대금이 지급되었습니다.";
      break;

    case "RETURN_COMPLETE":
      icon = ic_ok_green;
      title = "상품 반송이 완료되었습니다.";
      break;
  }

  // 판매 요청 이전 단계
  const beforeSellStep = [
    "INBOUND_WAIT",
    "INBOUND_COMPLETE",
    "INBOUND_CANCEL",
    "VERIFY_WAIT",
    "VERIFY_COMPLETE",
    "COMPLETE_CLOSE",
  ];

  // 판매 중 단계
  const ingSellStep = [
    "SELL",
    "PAYMENT_DONE",
    "PAYMENT_WAIT",
    "TRANSFER_RETURN",
    "TRANSFER_COMPLETE",
    "TRANSFER_CANCEL",
  ];

  // 반송 단계
  const returnStep = ["RETURN", "DELIVERY", "TRANSFER_WAIT", "RETURN_COMPLETE"];

  // 감정 중
  const isVerifying = !!orderHistory.find(
    ({ status }) => status === "VERIFY_WAIT"
  );
  // 감정 완료
  const isVerified = !!orderHistory.find(
    ({ status }) => status === "VERIFY_COMPLETE"
  );
  return (
    <BasicLayout stackTopbar={{ title: "주문 상세", border: true }} white>
      <div className="receipt_wrap">
        <div className="receipt_progress">
          <div className="receipt ok">
            <img src={icon} alt="" />
            <p>
              {title.split("").map((s, i) => (s === "\n" ? <br key={i} /> : s))}
            </p>

            {status === "INBOUND_WAIT" && (
              <span>관리자 승인 검토 중입니다.</span>
            )}
            {status === "INBOUND_COMPLETE" && (
              <span>감정사가 감정을 준비중입니다.</span>
            )}

            {beforeSellStep.includes(status) && (
              <div className="receipt_table">
                <ul>
                  <li className="dot1 on">
                    <div className="dot">
                      <img src={ic_re_ok} style={{ zIndex: 1 }} />
                    </div>
                    접수 신청
                  </li>
                  <li className={`dot2 ${isVerifying ? "on" : ""}`}>
                    <div className="dot center">
                      <img
                        src={isVerifying ? ic_re_ok : ic_re_off}
                        style={{ zIndex: 1 }}
                      />
                    </div>
                    감정
                  </li>
                  <li className={`dot3 ${isVerified ? "on" : ""}`}>
                    <div className="dot">
                      <img
                        src={isVerified ? ic_re_ok : ic_re_off}
                        style={{ zIndex: 1 }}
                      />
                    </div>
                    감정 완료
                  </li>
                </ul>
              </div>
            )}
          </div>

          {status === "VERIFY_COMPLETE" && (
            <>
              <img
                src={re_bar}
                alt=""
                style={{ width: "100%", display: "block" }}
              />
              <div className="receipt_price">
                <ul className="receipt_price_ul">
                  <li>감정가</li>
                  <li>
                    <span className="spoqa">
                      {numberWithCommas(auth_price)}
                    </span>
                    원
                  </li>
                </ul>
                <p onClick={handleClickDetail}>상세 감정 결과 보기 &gt;</p>
                <ul className="receipt_price_bt">
                  <li>
                    <button onClick={handleClickReturn}>돌려받기</button>
                  </li>
                  <li>|</li>
                  <li>
                    <button onClick={() => setSellModalVisible(true)}>
                      판매신청
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}

          {(status === "SELL_CONFIRM_WAIT" || status === "SELL_WAIT") && (
            <>
              <img
                src={re_bar}
                alt=""
                style={{ width: "100%", display: "block" }}
              />
              <div className="receipt_price product">
                <ul className="receipt_price_ul">
                  <li>감정가</li>
                  <li>
                    <span className="spoqa">
                      {numberWithCommas(auth_price)}
                    </span>
                    원
                  </li>
                </ul>
                <p onClick={handleClickDetail}>상세 감정 결과 보기 &gt;</p>
                <ul className="receipt_sell_price">
                  <li>판매가</li>
                  <li>
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </li>
                </ul>
              </div>
            </>
          )}

          {(ingSellStep.includes(status) ||
            (returnStep.includes(status) && delivery_type === "BUY")) && (
            <>
              <img
                src={re_bar}
                alt=""
                style={{ width: "100%", display: "block" }}
              />
              <div className="receipt_price product">
                <ul className="receipt_price_ul">
                  <li>감정가</li>
                  <li>
                    <span className="spoqa">
                      {numberWithCommas(auth_price)}
                    </span>
                    원
                  </li>
                </ul>
                <p onClick={handleClickDetail}>상세 감정 결과 보기 &gt;</p>
                <ul className="receipt_sell_price">
                  <li>판매가</li>
                  <li>
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                    {status === "SELL" && (
                      <span
                        className="revise"
                        onClick={() => setSellModalVisible(true)}>
                        수정
                      </span>
                    )}
                  </li>
                </ul>
                <ul className="receipt_price_bt">
                  <li className={status !== "SELL" ? "disabled" : ""}>
                    <button
                      onClick={
                        status === "SELL"
                          ? () => {
                              setWithdrawalPopupVisible(true);
                            }
                          : () => {}
                      }>
                      판매 철회
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}

          {returnStep.includes(status) && delivery_type === "RETURN" && (
            <>
              <img
                src={re_bar}
                alt=""
                style={{ width: "100%", display: "block" }}
              />
              <div className="receipt_price product">
                <ul className="receipt_price_ul">
                  <li>감정가</li>
                  <li>
                    <span className="spoqa">
                      {numberWithCommas(auth_price)}
                    </span>
                    원
                  </li>
                </ul>
                <p onClick={handleClickDetail}>상세 감정 결과 보기 &gt;</p>
                <ul className="receipt_sell_price">
                  <li>판매가</li>
                  <li>
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </li>
                </ul>
                <ul className="receipt_price_bt">
                  {(() => {
                    switch (status) {
                      case "RETURN":
                      case "DELIVERY":
                      case "RETURN_COMPLETE":
                        return (
                          <li className="disabled">
                            <button>반송 확인</button>
                          </li>
                        );

                      case "TRANSFER_WAIT":
                        return (
                          <li>
                            <button onClick={handleClickReturnComplete}>
                              반송 확인
                            </button>
                          </li>
                        );
                    }
                  })()}
                </ul>
              </div>
            </>
          )}

          {status === "COMPLETE" && (
            <>
              <img
                src={re_bar}
                alt=""
                style={{ width: "100%", display: "block" }}
              />
              <div className="receipt_price product">
                <ul className="receipt_price_ul">
                  <li>판매가</li>
                  <li>
                    <span className="spoqa">{numberWithCommas(price)}</span>원
                  </li>
                </ul>
                <p onClick={handleClickDetail}>상세 감정 결과 보기 &gt;</p>
                <ul className="receipt_sell_price">
                  <li>정산금액</li>
                  <li>
                    <span className="spoqa">
                      {numberWithCommas(price - fee)}
                    </span>
                    원
                  </li>
                </ul>
                <ul className="receipt_price_bt">
                  <li className="disabled">
                    <button>판매 철회</button>
                  </li>
                </ul>
              </div>
            </>
          )}

          <div className="receipt_num">
            <h3 className="spoqa">
              접수번호: {locationState.id}
              <br />
              <span className="spoqa receipt_date">
                {formatTime(created_at, "YYYY.MM.DD")}
              </span>
            </h3>
            {status === "INBOUND_WAIT" && (
              <p className="cancel_btn" onClick={handleClickCancel}>
                신청 취소
              </p>
            )}
          </div>
          <div className="bar"></div>
          {(ingSellStep.includes(status) ||
            returnStep.includes(status) ||
            status === "COMPLETE") && (
            <>
              <div className="receipt_info product">
                <h3>판매 상품 정보</h3>
                <table>
                  <tr>
                    <td>카테고리</td>
                    <td>
                      {item.category_name} &gt; {item.brand}
                    </td>
                  </tr>
                  <tr>
                    <td>상품명</td>
                    <td>{item.name}</td>
                  </tr>
                  <tr>
                    <td>모델 번호</td>
                    <td className="spoqa">{item.model_number}</td>
                  </tr>
                  <tr>
                    <td>상품 상태</td>
                    <td className="ftbd">{item.state}</td>
                  </tr>
                </table>
              </div>
              <div className="bar"></div>
            </>
          )}
          <InfoAccordion
            enable={
              ingSellStep.includes(status) ||
              returnStep.includes(status) ||
              status === "COMPLETE"
            }>
            <table>
              <tr>
                <td>카테고리</td>
                <td>
                  {category_name} &gt; {brand}
                </td>
              </tr>
              <tr>
                <td>상품명</td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>판매 희망가</td>
                <td className="spoqa">{numberWithCommas(price)}</td>
              </tr>
              <tr>
                <td>방문매장</td>
                <td>
                  {shop_name}
                  <p className="grad">{shop_address}</p>
                  <span
                    className="spoqa orgbt"
                    onClick={(e) => {
                      e.stopPropagation();
                      callPhone(shop_phone);
                    }}>
                    <img src={ic_phone} />
                    {shop_phone}
                  </span>
                </td>
              </tr>
            </table>
          </InfoAccordion>
          {!!cancel_reason && (
            <>
              <div className="bar"></div>
              <div className="receipt_fail">
                <img src={ic_re_no} alt="X" />
                <p>접수 불가</p>
                <span>사유 : {cancel_reason}</span>
              </div>
            </>
          )}

          {returnStep.includes(status) && delivery_type === "RETURN" && (
            <ReturnProgress history={orderHistory} status={status} />
          )}

          {delivery_type === "BUY" && (
            <DeliveryProgress history={orderHistory} status={status} />
          )}

          <div className="bar"></div>
          <SafeTradeSellHistory history={orderHistory} />
        </div>
        {/* <div
          className="ok_btn on"
          onClick={() => history.goBack()}
          style={{ zIndex: "auto" }}
        >
          확인
        </div> */}
        {sellModalVisible && (
          <SellModal
            onClose={() => setSellModalVisible(false)}
            itemId={item_id}
            onSuccess={() => {
              setSellModalVisible(false);
              init();
            }}
            status={status}
          />
        )}
        {withdrawalPopupVisible && (
          <SellWithdrawalPopup
            fee={penalty}
            onClose={() => setWithdrawalPopupVisible(false)}
            onClick={handleClickWithdraw}
          />
        )}
      </div>
    </BasicLayout>
  );
}

// 상품 진행 내역
const SafeTradeSellHistory = ({ history = [] }) => {
  const [tab, setTab] = useState("");

  useEffect(() => {
    if (history.length) {
      const latest = history[history.length - 1];

      if (SAFE_TRADE_STATUS["INBOUND"].includes(latest.status)) {
        setTab("INBOUND");
      } else if (SAFE_TRADE_STATUS["VERIFY"].includes(latest.status)) {
        setTab("VERIFY");
      } else {
        setTab("SELL");
      }
    }
  }, [history]);

  const filtered = history.filter(({ status }) => {
    if (tab === "SELL") {
      return (
        !SAFE_TRADE_STATUS["INBOUND"].includes(status) &&
        !SAFE_TRADE_STATUS["VERIFY"].includes(status)
      );
    } else if (tab === "VERIFY" || tab === "INBOUND") {
      return SAFE_TRADE_STATUS[tab].includes(status);
    }
    return false;
  });

  return (
    <div className="receipt_history">
      <h3>접수 진행 내역</h3>
      <ul>
        <li
          className={`${tab === "INBOUND" ? "on" : ""}`}
          onClick={() => setTab("INBOUND")}>
          접수
        </li>
        <li
          className={`${tab === "VERIFY" ? "on" : ""}`}
          onClick={() => setTab("VERIFY")}>
          감정
        </li>
        <li
          className={`${tab === "SELL" ? "on" : ""}`}
          onClick={() => setTab("SELL")}>
          판매
        </li>
      </ul>
      <table>
        {filtered.map(({ description, createdAt }, i) => {
          return (
            <tr key={i.toString()}>
              <td>{description}</td>
              <td className="spoqa">
                {formatTime(createdAt, "YYYY.MM.DD / HH:mm")}
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const InfoAccordion = ({ children, enable = false }) => {
  return (
    <div className={`receipt_info ${enable ? "acco" : ""}`}>
      {enable ? (
        <div className="accordion vertical">
          <ul>
            <li style={{ zIndex: 0 }}>
              <input
                type="checkbox"
                id="checkbox-1"
                name="checkbox-accordion"
              />
              <label htmlFor="checkbox-1">
                <h3>상품 접수 정보</h3>
                <div className="btn_cx_down" style={{ zIndex: -1 }} />
              </label>
              <div className="content">
                <div className="btn_cx_up" style={{ zIndex: -1 }} />
                {children}
              </div>
            </li>
          </ul>
        </div>
      ) : (
        <>
          <h3>상품 접수 정보</h3>
          {children}
        </>
      )}
    </div>
  );
};

const SellModal = ({ onClose, itemId, onSuccess, status }) => {
  const dispatch = useDispatch();

  const timer = useRef(null);
  const [isCalculated, setIsCalculated] = useState(false);
  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);

  useEffect(() => {
    setFee(0);

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      APIS.getSafeTradeCalculate(itemId, "SELL", price).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            setFee(data.fee);
            setIsCalculated(true);
          } else {
            dispatch(actionError(message));
          }
        }
      );
    }, 800);
  }, [price]);

  const handleClickSell = () => {
    let api = () => {};

    if (status === "VERIFY_COMPLETE") {
      // 판매 요청
      api = APIS.postSafeTradeSellOpen;
    } else if (status === "SELL") {
      // 가격 수정
      api = APIS.postSafeTradeUpdatePrice;
    } else {
      dispatch(actionError("요청 할 수 없는 상태입니다."));
      return false;
    }

    dispatch(loadingStart);
    api(itemId, price)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          onSuccess();
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleChangeInput = (e) => {
    var values = e.target.value;
    values = values.replace(/,/gi, "");
    var regexp = /^[0-9]*$/;
    if (!regexp.test(values)) {
      return;
    } else {
      values = values.replace(/,/gi, "");
    }

    setIsCalculated(false);
    setPrice(+values);
  };

  return (
    <div
      className="trade_wrap_bk"
      style={{ position: "absolute", top: 0, zIndex: 3 }}>
      <div className="sell_app">
        <div className="sell_tit">
          <h3>판매 신청</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="sell_app_list">
          <h3>원하는 상품 판매가를 입력해 주세요.</h3>
          <ul>
            <li>판매가</li>
            <li>
              <input
                type="text"
                placeholder="판매가를 입력하세요."
                onChange={handleChangeInput}
                inputMode="decimal"
                value={numberWithCommas(price)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    document.activeElement.blur();
                  }
                }}
                style={{ textAlign: "right", paddingRight: 20 }}
              />
              <div className="won">원</div>
            </li>
          </ul>
          <ul>
            <li>수수료</li>
            <li>
              <span className="spoqa">{numberWithCommas(fee)}</span>원
            </li>
          </ul>
          <ul>
            <li>정산 금액</li>
            <li>
              <span className="spoqa">
                {!isCalculated ? "0" : numberWithCommas(price - fee)}
              </span>
              원
            </li>
          </ul>
        </div>
        <div className="bar"></div>
        <div className="sell_app_noti">
          <ul>
            <li>판매 가격은 판매 중에 변경할 수 있습니다.</li>
            <li>
              감정 결과의 감정가보다 높을 경우 판매까지 시간이 걸리거나 판매되지
              않을 수 있습니다.
            </li>
            <li>
              판매 기간은 상품 게시 일로부터 3개월이며, 3개월 내 판매 철회 시
              위약금이 부과됩니다.
            </li>
            <li>
              판매 완료 시 수수료를 공제한 금액이 금방금방 원화(KRW)로
              지급됩니다.
            </li>
            <li>상품 하자에 대한 책임은 판매자에게 있습니다.</li>
          </ul>
        </div>
      </div>
      <div
        className={`ok_btn ${price - fee > 0 && isCalculated ? "on" : "off"}`}
        onClick={price - fee > 0 && isCalculated ? handleClickSell : () => {}}>
        확인
      </div>
    </div>
  );
};

const ReturnProgress = ({ history = [], status }) => {
  // RETURN 반송
  // DELIVERY 출고 중
  // TRANSFER_WAIT 인도 대기
  // RETURN_COMPLETE 반송 완료

  const DELIVERY = history.find(({ status }) => status === "DELIVERY");
  const TRANSFER_WAIT = history.find(
    ({ status }) => status === "TRANSFER_WAIT"
  );
  const RETURN_COMPLETE = history.find(
    ({ status }) => status === "RETURN_COMPLETE"
  );
  return (
    <>
      <div className="bar" />
      <div className="receipt_ing">
        <h3>진행 내역</h3>
        <div className="receipt_table">
          <ul>
            <li className={`dot1 ${DELIVERY ? "on" : "off"}`}>
              <div className="dot">
                <img
                  src={
                    status === "DELIVERY"
                      ? ic_re_ing
                      : DELIVERY
                      ? ic_re_ok
                      : ic_re_off
                  }
                />
              </div>
              반송 출고 중<br />
              <span className="spoqa">
                {!!DELIVERY &&
                  formatTime(DELIVERY.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
            <li className={`dot2 ${TRANSFER_WAIT ? "on" : "off"}`}>
              <div className="dot center">
                <img
                  src={
                    status === "TRANSFER_WAIT"
                      ? ic_re_ing
                      : TRANSFER_WAIT
                      ? ic_re_ok
                      : ic_re_off
                  }
                />
              </div>
              출고 완료
              <br />
              <span className="spoqa">
                {!!TRANSFER_WAIT &&
                  formatTime(TRANSFER_WAIT.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
            <li className={`dot3 ${RETURN_COMPLETE ? "on" : "off"}`}>
              <div className="dot">
                <img src={RETURN_COMPLETE ? ic_re_ok : ic_re_off} />
              </div>
              반송 완료
              <br />
              <span className="spoqa">
                {!!RETURN_COMPLETE &&
                  formatTime(RETURN_COMPLETE.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const DeliveryProgress = ({ history = [], status }) => {
  // DELIVERY 출고 중
  // TRANSFER_WAIT 인도 대기
  // TRANSFER_COMPLETE 상품 인도
  // COMPLETE 판매 완료

  const DELIVERY = history.find(({ status }) => status === "DELIVERY");
  const TRANSFER_WAIT = history.find(
    ({ status }) => status === "TRANSFER_WAIT"
  );
  const COMPLETE = history.find(({ status }) => status === "COMPLETE");

  return (
    <>
      <div className="bar" />
      <div className="receipt_ing">
        <h3>진행 내역</h3>
        <div className="receipt_table">
          <ul>
            <li className={`dot1 ${DELIVERY ? "on" : "off"}`}>
              <div className="dot">
                <img
                  src={
                    status === "DELIVERY"
                      ? ic_re_ing
                      : DELIVERY
                      ? ic_re_ok
                      : ic_re_off
                  }
                />
              </div>
              출고 중<br />
              <span className="spoqa">
                {!!DELIVERY &&
                  formatTime(DELIVERY.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
            <li className={`dot2 ${TRANSFER_WAIT ? "on" : "off"}`}>
              <div className="dot center">
                <img
                  src={
                    status === "TRANSFER_WAIT"
                      ? ic_re_ing
                      : TRANSFER_WAIT
                      ? ic_re_ok
                      : ic_re_off
                  }
                />
              </div>
              출고 완료
              <br />
              <span className="spoqa">
                {!!TRANSFER_WAIT &&
                  formatTime(TRANSFER_WAIT.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
            <li className={`dot3 ${COMPLETE ? "on" : "off"}`}>
              <div className="dot">
                <img src={COMPLETE ? ic_re_ok : ic_re_off} />
              </div>
              판매 완료
              <br />
              <span className="spoqa">
                {!!COMPLETE &&
                  formatTime(COMPLETE.createdAt, "YYYY.MM.DD/HH:mm")}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

const SellWithdrawalPopup = ({ fee, onClose, onClick }) => {
  return (
    <div className="trade_wrap_bk" style={{ position: "fixed", top: 0 }}>
      <div className="pd_pay_cancel">
        <div className="pay_tit">
          <h3>결제하기</h3>
          <img src={ic_close} alt="X" onClick={onClose} />
        </div>
        <div className="pay_cancel_list">
          <ul>
            <li>
              판매를 철회하시겠습니까?
              <span>
                상품이 판매 중입니다. <br />
                판매 철회 시 수수료가 결제됩니다.
              </span>
            </li>
          </ul>
          <ul className="ulbd">
            <li>위약 수수료</li>
            <li>
              <span className="spoqa">{numberWithCommas(fee)}</span>원
            </li>
          </ul>
        </div>
      </div>
      <div className="ok_btn on" onClick={onClick}>
        확인
      </div>
    </div>
  );
};
const useStyle = makeStyles({});
