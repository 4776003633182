import React from "react";
import styled from "styled-components";
import fonts from "../../libs/fonts";

const SimpleTopBar = styled.div`
  padding: 13px 16px;
  font-family: ${fonts.notoSansKRBold};
  letter-spacing: -0.32px;
  color: #333333;

  ${({ border }) => (border ? "border-bottom:1px solid #e0e0e0;" : "")}
`;

export default SimpleTopBar;
