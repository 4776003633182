import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function ButtonMini({
  style,
  label,
  onClick,
  className,
  border,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <ButtonBase
      style={style}
      onClick={onClick}
      className={`${classes.root} ${className} ${border && classes.rootBOrder}`}
    >
      {label}
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: {
    padding: "6px 20px",
    borderRadius: "20px",
    backgroundColor: colors.primary,
    textAlign: "center",
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
  },
  rootBOrder: {
    backgroundColor: colors.white,
    color: colors.primary,
    border: "2px solid " + colors.primary,
  },
});
