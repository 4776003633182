import React, { useRef } from "react";
import styled from "styled-components";
import Flex from "../flex/Flex";

const BottomModal = ({ visible, children, onClickBackground }) => {
  const childRef = useRef();

  return (
    <>
      {visible && <ModalOverlay onClick={onClickBackground} />}
      <ModalInner visible={visible}>
        <div ref={childRef}>{children}</div>
      </ModalInner>
    </>
  );
};

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;
const ModalInner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
//   height: ${({ height }) => height}px;
  transition: all 0.3s ease-out;
  z-index: 1000;
  transform: translateY(${({ visible }) => (visible ? 0 : 100)}%);
`;

export default BottomModal;
