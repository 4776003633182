import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import routes from "../../libs/routes";
import Button from "../button/Button";
import Image from "../image/Image";
import { numFormat } from "../../services/utils";

export default function DirectTransactionItemGrid({
  order,
  productName,
  brandName,
  onClick,
  productType,
  createdAt,
  purchaseYear,
  salePrice,
  faceImageUrl,
  id,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [height, setHeight] = useState(0);
  return (
    <Flex className={classes.root}>
      <ButtonBase
        onClick={() => {
          history.push(routes.directTransactionDetail(id));
        }}
        disabled={order}
        className={classes.item}
      >
        <div
          style={{ ...(height && { minHeight: height }) }}
          ref={(r) => {
            if (r) {
              setHeight(r.offsetWidth);
            }
          }}
          className={classes.imageWrap}
        >
          <img src={faceImageUrl} className={classes.sample1} />
        </div>
        <Flex className={classes.main}>
          <Text className={classes.label1}>{brandName}</Text>
          <Text className={classes.label2} font={fonts.notoSansKRMedium}>
            {productName}
          </Text>
          <Text className={classes.label1}>{numFormat(salePrice)}원</Text>
        </Flex>
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  },
  imageWrap: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #eee",
  },
  root: {
    boxSizing: "border-box",
    width: "50%",
    padding: "15px 3px",
  },
  item: {
    padding: "14px 0px",
    margin: "0px 14px",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  main: {
    marginTop: "5px",
    alignSelf: "stretch",
    alignItems: "flex-start",
    flex: 1,
  },
  label1: { color: colors.gray, fontSize: 12 },
  label3: { color: colors.gray, fontSize: 10 },
  label2: {
    fontSize: "16px",
    color: "#000",
  },
  buttons: {
    margin: "13px 13px 20px 13px",
    "& button": {
      flex: 1,
      padding: "6px",
      marginLeft: "5px",
      "& span": {
        fontSize: "14px",
      },
      "&:first-child": {
        backgroundColor: "#fff",
        border: "1px solid " + colors.primary,
        "& span": {
          color: colors.primary + " !important",
        },
        marginLeft: "0px",
        marginRight: "5px",
      },
    },
  },
  orderState: { color: colors.primary, fontSize: "12px" },
});
