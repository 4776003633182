export const imageActionTypes = {
  show: "image/show",
  hide: "image/hide",
};

export const imageViewerShow = (srcs) => (dispatch) => {
  dispatch({ type: imageActionTypes.show, srcs });
};
export const imageViewerHide = () => (dispatch) => {
  dispatch({ type: imageActionTypes.hide });
};
