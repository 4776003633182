import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DateRange from "../../components/date-range/DateRange";
import Flex from "../../components/flex/Flex";
import Tables from "../../components/tables/Tables";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";

export default function WonHistory({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  return (
    <BasicLayout
      stackTopbar={{
        title:
          state?.type === "gold"
            ? "금(GOLD) 거래 내역"
            : "은(SILVER) 거래 내역",
      }}
    >
      <Flex row className={classes.card + " shadow"}>
        <Text className={classes.label}>
          {state?.type === "gold" ? "보유 금" : "보유 은"}
        </Text>
        <Text className={classes.asset} font={fonts.notoSansKRMedium}>
          295.411g
        </Text>
      </Flex>

      <DateRange className={classes.range} />

      <Tables
        labelLength={2}
        data={[{}, {}]}
        columns={[
          {
            label: "체결시간",
            render: (x) => {
              return (
                <>
                  02.06
                  <br />
                  18:45:23
                </>
              );
            },
          },
          {
            label: "거래구분",
            render: (x) => {
              return (
                <>
                  <Text style={{ color: colors.textRed }}>매수</Text>
                  금(GOLD)
                </>
              );
            },
          },
          {
            label: "체결중량\n체결단가",
            render: (x) => {
              return (
                <>
                  0.900g
                  <br />
                  83,100
                </>
              );
            },
          },
          {
            label: "체결금액\n수익률",
            render: (x) => {
              return (
                <>
                  65,790
                  <br />
                  <Text style={{ color: colors.textRed }}>+1.26%</Text>
                </>
              );
            },
          },
        ]}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  card: {
    padding: "16px 13px",
    boxSizing: "border-box",
    margin: "16px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    color: colors.text,
    fontSize: "14px",
  },
  asset: {
    color: colors.text,
    fontSize: "20px",
  },
  range: {
    margin: "20px 25px",
  },
});
