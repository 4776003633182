import { makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageHide } from "../../redux/message/MessageActions";

export default function ErrorPopup({}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  const history = useHistory();

  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <img alt="wa" className={classes.danger} src={images.danger} />
        <Text className={classes.message}>
          <Text font={fonts.notoSansKRMedium}>탈퇴하실 수 없습니다.</Text>
          <br />
          보유한 금(GOLD), 은(SILVER)을 모두 매도한 후 탈퇴하시기 바랍니다.
        </Text>
        <Button
          onClick={() => {
            history.push(routes.mypage);
          }}
          className={classes.button}
          label="보유자산 확인하기"
        />
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  message: {
    color: colors.text,
    textAlign: "center",
    fontSize: "16px",
    margin: "10px 0px 0px 0px",
    "& span": {
      marginBottom: "16px",
      fontSize: "18px",
      lineHeight: 2,
    },
  },
  button: {
    alignSelf: "center",
    padding: "10px 50px",
    marginTop: "30px",
  },
  danger: {
    width: "27px",
    height: "27px",
  },
});
