import { ButtonBase, Divider, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Rating from "../../components/rating/Rating";
import images from "../../libs/images";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import moment from "moment-timezone";
import { numFormat, objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
export default function AppraiseReviewDetail({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [desc, setDesc] = useState("");
  const state = history.location.state;
  const [rating, setRating] = useState(0);
  const [reviewData, setReviewData] = useState({});
  const userReducer = useSelector((s) => s.user);
  const [appraiseData, setAppraisData] = useState({});
  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/Requests/" +
            state.requestId,
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;

            const list = data.appraisalRequestStatus;

            const finish = list.filter((x) => x.status === "감정완료")[0];

            if (!finish) {
              return;
            }
            APIS.getCurrentMarketPrice(finish.appraiseType)
              .then(({ data: { data } }) => {
                setAppraisData({
                  gram: finish.appraiseGram,
                  type: finish.appraiseType,
                  price: parseFloat(data) * parseFloat(finish.appraiseGram),
                });
              })
              .catch(() => dispatch(messageError(err)));
          }
        }
      )
    );

    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/appraisalFeedbacks/" +
            state.id,
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;
            setReviewData({ ...data });
          }
        }
      )
    );
  }, [state.id]);
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "교환 완료",
      }}
    >
      <span className={classes.label1}>
        {appraiseData.type === "금" ? "금(GOLD)" : "은(SILVER)"}으로 교환이
        완료되었습니다.
      </span>
      <span className={classes.label2}>
        {appraiseData.type === "금" ? "금(GOLD)" : "은(SILVER)"}{" "}
        {appraiseData.gram}g
      </span>
      <span className={classes.label3}>
        = {numFormat(appraiseData.price)}원
      </span>
      <ButtonBase className={classes.button} onClick={history.goBack}>
        거래내역확인
      </ButtonBase>

      <span className={classes.label4}>
        {moment().format("YYYY.MM.DD")} 작성됨
      </span>
      <Rating rating={reviewData.rating} size={30} className={classes.rating} />

      <span className={classes.content}>{reviewData.content}</span>
      {reviewData?.imageUrl && (
        <img
          alt="review-image"
          src={reviewData.imageUrl}
          className={classes.image}
        />
      )}

      <img src={images.simpleinspect2} className={classes.banner} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  content: {
    fontSize: 14,
    lineHeight: "17px",
    color: "#333333",
    margin: "0px 30px 20px 30px",
  },
  image: {
    width: "calc(100% - 60px)",
    objectFit: "contain",
    alignSelf: "center",
  },
  rating: {
    alignSelf: "center !important",
    marginBottom: "20px",
  },
  label1: {
    fontSize: 14,
    marginTop: 40,
    fontFamily: fonts.notoSansKRLight,
    color: "#333333",
    alignSelf: "center",
  },
  label2: {
    fontSize: 24,
    color: "#333333",
    marginTop: 14,
    fontFamily: fonts.notoSansKRBold,
    alignSelf: "center",
  },
  label3: {
    fontSize: 16,
    color: "#333",
    fontFamily: fonts.notoSansKRLight,
    alignSelf: "center",
  },
  button: {
    borderRadius: 20,
    border: "1px solid #ff6e0e",
    padding: "9px 30px",
    backgroundColor: "#fff",
    color: colors.primary,
    fontSize: 13,
    alignSelf: "center",
    marginTop: 16,
    fontFamily: fonts.notoSansKRBold,
  },
  label4: {
    fontSize: 14,
    color: "#333333",
    fontFamily: fonts.notoSansKRRegular,
    alignSelf: "center",
    marginTop: 38,
    marginBottom: 14,
  },
  ipwrap: {
    margin: "20px",
    backgroundColor: "#fff",
    borderRadius: 3,
    padding: 18,
    "& textarea": {
      fontSize: 13,
      flex: 1,
      maxHeight: "200px",
    },
  },
  images: {
    marginLeft: "28px",
    marginRight: "28px",
    marginBottom: 24,
  },
  button2: {
    backgroundColor: colors.primary,
    borderRadius: 3,
    marginLeft: 20,
    marginRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    fontSize: 14,
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
  },
  banner: {
    width: "100%",
    backgroundColor: "#fec729",
    marginTop: 100,
  },
});
