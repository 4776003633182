import { updateMarketPrice } from "../redux/marketPrice/MarketPriceReducer";
import { messageError } from "../redux/message/MessageActions";
import * as APIS from "./apis";
import { signOut } from "./sign";

const { store } = require("../redux/store");

export const refreshMarketPrice = () => {
  const parseData = ({ data: { data } }) => {
    const [current, closing] = data.split(",");

    return { current, closing };
  };

  const getGoldPrice =
    APIS.getCurrentClosingMarketPrice("GOLD").then(parseData);
  const getSilverPrice =
    APIS.getCurrentClosingMarketPrice("SILVER").then(parseData);

  Promise.all([getGoldPrice, getSilverPrice]).then(
    ([
      gold = { current: 0, closing: 0 },
      silver = { current: 0, closing: 0 },
    ]) => {
      const updatedAt = new Date();
      const payload = { updatedAt, gold, silver };
      store.dispatch(updateMarketPrice(payload));
    }
  );
};

export const expireAuth = () => {
  store.dispatch(
    messageError("인증 정보가 만료되었습니다.\n다시 로그인해주세요.", signOut)
  );
};
