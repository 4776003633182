// ERROR
export const E_001 =
  "일요일 방문은 가맹점으로 전화하셔서 약속을 잡은 후 방문 하시기 바랍니다.";
export const WITHDRAW_ACCOUNT_REQUIRED =
  "감정 및 매도 완료 후 출금하기 위해서는 본인 실명계좌 등록이 필요합니다.";

// INFO
export const I_001 =
  "※ 매장 영업시간은 매장별로 상이하니, 방문 전 해당 매장에 전화문의 후 방문 바랍니다.";

export const CALENDAR_ALERT = {
  APPRAISAL: {
    START: "20220101",
    END: "20321231",
    MESSAGE: "방문 전 해당 매장의 영업시간을 확인해주세요.",
  },
  GOODS: {
    START: "20220101",
    END: "20321231",
    MESSAGE: "방문 전 해당 매장의 영업시간 및 출고 여부를 확인해주세요.",
  },
  SAFE_TRADE_BUY: {
    START: "20220101",
    END: "20321231",
    MESSAGE: "방문 전 해당 매장의 영업시간 및 출고 여부를 확인해주세요.",
  },
  SAFE_TRADE_SELL: {
    START: "20220101",
    END: "20321231",
    MESSAGE: "방문 전 해당 매장의 영업시간을 확인해주세요.",
  },
};
