import React, { useState } from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import checkboxMiniOn from "../../../../assets/images/checkbox_mini_on.webp";
import checkboxMiniOff from "../../../../assets/images/checkbox_mini_off.webp";
import historyAppraisalGold from "../../../../assets/images/history_appraisal_gold.webp";
import historyAppraisalSilver from "../../../../assets/images/history_appraisal_silver.webp";
import history_appraisal from "../../../../assets/images/history_appraisal.webp";
import return_icon from "../../../../assets/images/return_icon.webp";
import product_default from "../../../../assets/images/product_default.webp";
import Span from "../../../../components/text/Span";
import fonts from "../../../../libs/fonts";
import consts from "../../../../libs/consts";
import checkboxOff from "../../../../assets/images/checkbox_off.webp";
import checkboxOn from "../../../../assets/images/checkbox_on.webp";
import { formatTime } from "../../../../libs/utils";
import PolicyAgreePopup from "../../../../components/policy-agree-popup/PolicyAgreePopup";
import routes from "../../../../libs/routes";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { actionShow } from "../../../../redux/action/ActionActions";
import * as APIS from "../../../../libs/apis";

const AppraisalItem = ({
  requestAt,
  tradeType,
  requestAssetType,
  requestGram,
  contractGram,
  requestPrice,
  requestId,
  status,
  imagePath,
  productName,
  tradeNumber,
  visitDate,
  statusDateType,
  statusDate,
  ...rest
}) => {
  const history = useHistory();

  let icon = <StatusIcon />;

  if (status === "반송중") {
    icon = <img src={return_icon} width={16} height={16} />;
  }
  return (
    <Container
      onClick={() => {
        history.push(routes.appraiseDetail, { id: requestId });
      }}
    >
      <Header>
        <Flex row style={{ alignItems: "center" }}>
          {icon}
          <StatusLabel>{status}</StatusLabel>
        </Flex>
      </Header>
      <SpaceBetween style={{ marginTop: 11 }}>
        <Flex
          row
          style={{
            fontFamily: fonts.notoSansKRMedium,
            fontSize: 15,
            alignItems: "flex-end",
          }}
        >
          <img src={history_appraisal} width={18} height={18} />
          감정평가
        </Flex>
        {/* <Span size={15} color="#999999">
          내방접수
        </Span> */}
      </SpaceBetween>
      <Flex row style={{ marginTop: 14 }}>
        <Flex>
          <img
            src={
              imagePath ? `${consts.s3BaseUrl}/${imagePath}` : product_default
            }
            width={72}
            height={72}
            style={{
              border: "1px solid #d6d6d6",
              borderRadius: 8,
              marginRight: 30,
              objectFit: "contain",
            }}
          />
        </Flex>
        <Flex style={{ flex: 1 }}>
          <Span font={fonts.notoSansKRBold} size={17}>
            {productName}
          </Span>
          <SpaceBetween>
            <Span size={15} color="#666666" style={{ width: 70 }}>
              접수번호
            </Span>
            <Span
              size={10}
              color="#666666"
              style={{ flex: 1, textAlign: "right" }}
            >
              {tradeNumber}
            </Span>
          </SpaceBetween>
          <SpaceBetween>
            <Span size={15} color="#666666" style={{ width: 70 }}>
              {statusDateType}
            </Span>
            <Span size={16} color="#666666">
              {formatTime(statusDate, "YYYY.MM.DD")}
            </Span>
          </SpaceBetween>
        </Flex>
      </Flex>
      <Flex style={{ height: 17 }} />
    </Container>
  );
};

const ExchangeButton = styled(Flex)`
  border: 1px solid #ff6e0e;
  border-radius: 4px;
  font-size: 12px;
  color: #ff6e0e;
  padding: 3px 7.5px;
`;

const AllCheckWrap = styled(Flex)`
  position: relative;
  flex-direction: row;
  align-items: center;
  img {
    position: absolute;
    left: 0;
  }
`;

const Container = styled(Flex)`
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Header = styled(Flex)`
  flex-direction: row;
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
  justify-content: space-between;
`;

const StatusLabel = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #e76e26;
`;

const StatusIcon = styled(Flex)`
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(00, 00, 00, 0.1);

  &::before {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background: #ff6e0e;
    content: "";
  }
`;

export default AppraisalItem;
