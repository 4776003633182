import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import RenderInput from "../../components/render-input/RenderInput";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import simages from "../../libs/images";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";

export default function AppraiseRegist4({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [has, setHas] = useState(false);
  const [warranty, setWarranty] = useState(false);
  const inputRef = useRef();
  const userReducer = useSelector((s) => s.user);

  const {
    gram,
    quantity,
    purity,
    appraisalItem,
    faceImage,
    leftSideImage,
    rightSideImage,
    modelNameImage,
  } = history.location.state || {};

  useEffect(() => {
    const { warranty, has } = history.location.state || {};
    if (warranty) {
      setWarranty(warranty);
    }
    setHas(has);
  }, [history.location.state]);

  useEffect(() => {
    if (
      !appraisalItem ||
      !gram ||
      !quantity ||
      !purity
      // || !(faceImage || leftSideImage || rightSideImage || modelNameImage)
    ) {
      history.goBack();
    }
  }, []);

  const insertImage = (id, type, image, cb) => {
    if (!image) {
      cb();
      return;
    }

    const form = new FormData();
    form.append(type, image);
    dispatch(
      axiosDispatch(
        {
          method: "put",
          url:
            consts.apiBaseUrl +
            "/api/v1/user/market/appraisal/Simples/" +
            id +
            "/" +
            type,
          data: form,
        },
        (err, res) => {
          if (err) {
            dispatch(loadingEnd);

            dispatch(messageError(err));
          } else {
            cb();
          }
        }
      )
    );
  };

  useEffect(() => {
    if (has && inputRef.current) {
      inputRef.current.click();
    }
  }, [has, inputRef.current]);
  const appraisal = () => {
    history.replace(routes.appraiseRegist, {
      ...history.location.state,
      warranty,
      has,
    });

    const gram = history.location.state.gram;
    const purity = history.location.state.purity;
    const quantity = history.location.state.quantity;
    const warrantyFile = warranty ? warranty : null;
    const appraisalProductId = history.location.state?.appraisalItem.id;

    try {
      if (!userReducer.id) {
        throw "로그인 후 진행해 주세요.";
      } else if (!appraisalProductId) {
        throw "감정평가 하실 제품을 선택해 주세요.";
      } else if (gram === "0" || gram === "0.0" || gram === "") {
        throw "제품의 중량을 입력해 주세요.";
      } else if (!purity) {
        throw "제품의 순도를 선택해 주세요.";
      } else if (!quantity) {
        throw "제품의 수량을 입력해 주세요.";
      }
    } catch (error) {
      dispatch(messageError(error));
      return;
    }
    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "post",
          url: consts.apiBaseUrl + "/api/v1/user/market/appraisal/Simples",
          data: {
            appraisalProductId,
            // userId: userReducer.id,
            purity: purity,
            quantity: parseInt(quantity),
            weightGram: parseFloat(gram),
          },
        },
        (err, res) => {
          if (err) {
            dispatch(loadingEnd);
            dispatch(messageError(err));
          } else {
            if (res.data.success) {
              const id = res.data.data.id;
              insertImage(id, "topImage", faceImage?.file, () => {
                insertImage(id, "frontImage", leftSideImage?.file, () => {
                  insertImage(id, "leftSideImage", rightSideImage?.file, () => {
                    insertImage(
                      id,
                      "rightSideImage",
                      modelNameImage?.file,
                      () => {
                        if (warrantyFile) {
                          insertImage(
                            id,
                            "guaranteeImage",
                            warrantyFile.file,
                            () => {
                              dispatch(loadingEnd);

                              history.push(routes.appraiseRegist, {
                                result: true,
                                id,
                              });
                            }
                          );
                        } else {
                          dispatch(loadingEnd);
                          history.push(routes.appraiseRegist, {
                            result: true,
                            id,
                          });
                        }
                      }
                    );
                  });
                });
              });
            } else {
              dispatch(loadingEnd);
              dispatch(messageError(res.data.message));
            }
          }
        }
      )
    );
  };

  return (
    <Flex>
      <Text className={classes.title} font={fonts.notoSansKRBold}>
        보증서를
        <br />
        등록해 주세요.
      </Text>
      <Text className={classes.message}>마지막 단계입니다.</Text>
      <Checkbox
        className={classes.cb}
        disabled
        checked
        label="보증서가 있으신가요?"
      />

      <Flex row className={classes.buttons}>
        <span
          onClick={() => {
            setHas(true);
          }}
          className={(has && classes.bl) + " shadow"}
        >
          있음
        </span>
        <span
          onClick={() => {
            setWarranty(null);
            setHas(false);
          }}
          className={(!has && classes.bl) + " shadow"}
        >
          없음
        </span>
        <div style={{ flex: 1 }}></div>
      </Flex>

      {has &&
        (warranty ? (
          <ButtonBase
            onClick={() => {
              inputRef.current.click();
            }}
            className={classes.add}
          >
            <img src={warranty.uri} className={classes.img2} />
          </ButtonBase>
        ) : (
          <ButtonBase
            onClick={() => {
              inputRef.current.click();
            }}
            className={classes.add}
          >
            <img src={simages.camera} className={classes.camera} />
            <span>보증서를 등록해 주세요.</span>
          </ButtonBase>
        ))}
      <img
        src={simages.appraise4}
        style={{
          margin: (has ? "0px" : "30px") + " 20px 50px 20px",
          height: "150px",
          objectFit: "cover",
        }}
      />
      <div style={{ height: 100 }} />
      <Button
        disabled={has && !warranty}
        onClick={appraisal}
        label="감정 결과 확인하기"
        className={classes.button}
      />
      <RenderInput
        inputRef={inputRef}
        onlyImage
        onChange={(file) => {
          setWarranty(file);
        }}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  images: {
    margin: "0px 20px",
    marginTop: "20px",
  },
  title: {
    margin: "35px 30px 0px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  desc: {
    color: "#666",
    fontSize: "12px",
    margin: "20px",
    "& span": { fontSize: "16px", color: "#333" },
  },
  message: {
    alignSelf: "flex-start",
    margin: "5px 30px 30px 30px",
    color: "#666",
    fontSize: "14px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  item: {
    width: "30%",
    padding: "20px",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    flexDirection: "column",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  img2: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  button: {
    zIndex: 2,
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: "0px",
    width: "100%",
    alignSelf: "stretch",
  },
  image: {
    backgroundColor: "#ddd",
    width: 70,
    height: 70,
    borderRadius: "100px",
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "0px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
  add: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    "& span": {
      color: colors.gray,
      marginTop: "10px",
    },
    alignSelf: "stretch",
    margin: "30px 20px 10px 20px",
    borderRadius: "5px",
    height: "150px",
    border: "1px solid #7d7d7d",
    borderStyle: "dashed",
  },
  camera: {
    width: "20px",
    height: "17px",
  },
  buttons: {
    alignSelf: "stretch",
    margin: "0px 20px",
    "& span": {
      flex: 1,
      display: "flex",
      lineHeight: 1,
      cursor: "pointer",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      color: colors.text,
      marginRight: "10px",
      alignItems: "center",
      textAlign: "center",
      "&:last-child": {
        marginRight: "0px",
      },
      borderRadius: "20px",
      padding: "10px 20px",
    },
  },
  bl: {
    backgroundColor: colors.primary,
    color: "#fff !important",
  },
});
