import React from "react";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import fonts from "../../../../../libs/fonts";
import { formatTime, numberWithCommas } from "../../../../../libs/utils";
import deposit_icon from "../../../../../assets/images/deposit_icon.webp";
import check_circle from "../../../../../assets/images/check_circle.webp";

const DepositItem = (props) => {
  const {
    requestAt,
    tradeType,
    tradeTypeKor,
    assetType,
    tradeGram,
    pureTradeKrw,
    userFee,
    completedAt,
  } = props;

  return (
    <Container>
      <TopWrap>
        <img
          src={check_circle}
          width={16}
          height={16}
          style={{ marginRight: 4 }}
        />
        <Span size={14} spacing={-0.28} color="#e76e26">
          입금완료
        </Span>
        <Span
          font={fonts.spoqa}
          size={14}
          color="#666666"
          style={{
            marginLeft: 5,
            paddingLeft: 6,
            borderLeft: "1px solid #999999",
          }}
        >
          {formatTime(completedAt, "YYYY.MM.DD HH:mm")}
        </Span>
      </TopWrap>

      <TradeTypeWrap>
        <img src={deposit_icon} width={18} height={18} />
        {tradeTypeKor}
      </TradeTypeWrap>

      <AmountWrap>
        <Span weight="bold" size={18}>
          {assetType}
        </Span>

        <Span size={16}>
          입금액
          <Span
            size={18}
            font={fonts.spoqa}
            weight="bold"
            style={{ marginLeft: 3 }}
          >
            {numberWithCommas(pureTradeKrw)}
          </Span>
          원
        </Span>
      </AmountWrap>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 15px 16px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
`;

const TopWrap = styled(Flex)`
  flex-direction: row;
  padding-bottom: 8.5px;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;
const TradeTypeWrap = styled(Flex)`
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;

  img {
    margin-right: 3px;
  }
`;
const AmountWrap = styled(Flex)`
  margin-top: 8px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export default DepositItem;
