import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";
import {
  Area,
  AreaChart,
  Line,
  LineChart,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { objToQueryStr, formatTime } from "../../services/utils";
import * as APIS from "../../libs/apis";

import NumberFormat from "react-number-format";

export default function Header({ settype, trade }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  var color = colors.textRed;
  const [marketprice, setMarketprice] = useState([]);
  const [marketpriceweek, setmarketpriceweek] = useState([]);
  var today = new Date();

  /*
  APIS.getUserInfo()
    .then(({ data: { success, data, message } }) => {
      console.log("search", data);
      //if (success) {

      //}
    })
    .catch((e) => {
      console.log("catch", e);
    });
  */
  useEffect(() => {
    //const timer = setInterval(() => {
    const { orderPrice = 0, remainGram = 0 } = trade[0] || {};
    var sumPrice = orderPrice * 1 - remainGram * 1;
    //console.log(sumPrice);
    var sumper = (sumPrice / (orderPrice * 1)) * 100;
    if (sumper < 0) {
      sumper = sumper * -1;
    }
    //console.log(sumper);
    var updown = "";
    var color = "";
    var marketData = [];
    if (sumPrice > 0) {
      updown = "up";
      color = "#eb0c0c";
      marketData = {
        price: orderPrice * 1,
        sumPrice: updown === "up" ? sumPrice : sumPrice * -1,
        sumper: sumper.toFixed(2),
        updown: updown,
        color: color,
      };
      setMarketprice(marketData);
    } else {
      updown = "down";
      color = "#2476c2";
      marketData = {
        price: orderPrice,
        sumPrice: updown === "up" ? sumPrice : sumPrice * -1,
        sumper: sumper.toFixed(2),
        updown: updown,
        color: color,
      };
      setMarketprice(marketData);
    }
  }, [trade]);

  useEffect(() => {
    const from = new Date();
    from.setDate(from.getDate() - 7);

    APIS.getClosingContract({
      assetType: settype,
      from: formatTime(from),
      to: formatTime(),
    }).then(({ data: { data } }) => {
      // console.log("res", res);
      setmarketpriceweek(data.map(({ closePrice }) => ({ closePrice: +closePrice })));
    });
  }, [settype]);

  return (
    <Flex row className={classes.root}>
      <Flex>
        <Text
          font={fonts.notoSansKRMedium}
          style={{ color: marketprice.color }}
          className={classes.label}
        >
          <NumberFormat
            value={marketprice.price}
            displayType={"text"}
            thousandSeparator={true}
          />
          원/g
        </Text>
        <Text style={{ color: marketprice.color }} className={classes.label2}>
          {marketprice.sumper}% {marketprice.updown === "up" ? "▲" : "▼"}{" "}
          <NumberFormat
            value={marketprice.sumPrice}
            displayType={"text"}
            thousandSeparator={true}
          />
        </Text>
      </Flex>

      <AreaChart
        width={100}
        height={50}
        stor
        data={marketpriceweek}
        domain={["dataMin", "dataMax"]}
      >
        <Area
          type="basis"
          dataKey="closePrice"
          stroke={colors.textRed}
          fill={colors.textRed + "44"}
          strokeWidth={2}
        />
        <YAxis type="number" domain={["dataMin", "dataMax"]} width={0} />
      </AreaChart>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: "10px 10px 10px 30px",
    borderBottom: "0.5px solid #dddddd",
  },
  label: {
    fontSize: "17px",
  },
  label2: {
    fontSize: "12px",
  },
});
