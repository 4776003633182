import {
  ButtonBase,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import DirectTransactionItem from "../../components/direct-transaction-item/DirectTransactionItem";
import DirectTransactionItemGrid from "../../components/direct-transaction-item/DirectTransactionItemGrid";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import SearchTopbar from "../../components/search-topbar/SearchTopbar";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { listShow } from "../../redux/list/ListActions";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import { numFormat, objToQueryStr } from "../../services/utils";
import DirectTransactionList from "./DirectTransactionList";

export default function DirectTransaction({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState([{}, {}, {}, {}]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popup, setPopup] = useState(false);
  let keywordRef = useRef().current;
  const state = history.location.state;
  //options

  useEffect(() => {
    state?.page !== undefined && fetchData();
  }, [
    state?.limit,
    state?.page,
    state?.keyword,
    state?.sortKey,
    state?.sortOrder,
    state?.categoryId,
  ]);

  const fetchData = () => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url:
            consts.apiBaseUrl +
            "/api/safeDirectTransaction" +
            objToQueryStr({
              keyword: state.keyword,
              limit: state.limit,
              category1: state.categoryId ? state.categoryId.split(",")[0] : "",
              category2: state.categoryId ? state.categoryId.split(",")[1] : "",
              page: state.page,
              sort: state.sortKey,
              order: state.sortOrder,
            }),
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            history.replace({
              pathname: history.location.pathname,
              state: {
                ...state,
                totalCount: res.data.totalElements,
                data:
                  state.page === 0
                    ? [...res.data.content]
                    : [...state.data, ...res.data.content],
              },
            });
          }
        }
      )
    );
  };

  const setCategoryId = (id) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        categoryId: id,
        page: 0,
      },
    });
  };

  const setSort = (key, order) => {
    history.replace({
      pathname: history.location.pathname,
      state: {
        ...state,
        sortKey: key,
        sortOrder: order,
        page: 0,
      },
    });
  };

  if (!state || state.page === undefined) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname,
          state: {
            view: "vertical",
            page: 0,
            limit: 10,
            sortKey: "createdAt",
            sortOrder: "DESC",
            keyword: "",
            categoryId: null,
            data: [],
            totalCount: 0,
          },
        }}
      />
    );
  }

  const handleChange = (value) => {
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, keyword: value, page: 0 },
    });
  };

  return (
    <MainLayout white className={classes.root}>
      <SearchTopbar
        fixed
        initOn={Boolean(state?.keyword)}
        value={state.keyword}
        onChange={handleChange}
        title="안심직거래"
      />
      {popup && (
        <DirectTransactionList
          categoryId={state.categoryId}
          setCategoryId={setCategoryId}
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
      <Flex
        row
        style={{
          margin: "20px 13px",
        }}
      >
        <Text className={classes.label2}>
          금방금방의 실제 감정평가를 통해 판매되는{"\n"}
          안심 직거래입니다.
        </Text>

        <ButtonBase
          className={classes.addButton}
          onClick={() => {
            history.push("/directtransactionadd");
          }}
        >
          제품등록하기
        </ButtonBase>
      </Flex>

      <Flex className={classes.main}>
        <Flex row className={"row-between " + classes.header}>
          <ButtonBase
            onClick={() => {
              setPopup(true);
            }}
            className="row-center"
          >
            <img src={images.menuun} className={"icon"} />
            <Text className={classes.legnth}>
              {state.categoryId ? state.categoryId.split(",")[2] : "전체"}
              <Text font={fonts.notoSansKRBold}>
                {" "}
                {numFormat(state.totalCount) || "0"}
              </Text>
              개
            </Text>
          </ButtonBase>

          <Flex row className="row-center">
            <ButtonBase
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
              className="row-center"
            >
              <Text className={classes.legnth2}>
                {(() => {
                  if (state.sortKey === "createdAt") {
                    return "최신순";
                  } else if (state.sortOrder === "ASC") {
                    return "낮은가격순";
                  } else {
                    return "높은가격순";
                  }
                })()}
              </Text>
              <img src={images.updown} className={"icon"} />
            </ButtonBase>
            <ButtonBase
              onClick={(e) => {
                if (state.view === "grid") {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, view: "vertical" },
                  });
                } else {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { ...state, view: "grid" },
                  });
                }
              }}
              className={classes.grid}
            >
              <img
                src={state.view === "vertical" ? images.grid : images.more}
                className={"icon"}
              />
            </ButtonBase>
          </Flex>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            {["최신", "낮은가격", "높은가격"].map((x, i) => {
              return (
                <MenuItem
                  key={x}
                  style={{ minHeight: "auto" }}
                  onClick={() => {
                    setAnchorEl(null);
                    if (i === 0) {
                      setSort("createdAt", "DESC");
                    } else if (i === 1) {
                      setSort("salePrice", "ASC");
                    } else {
                      setSort("salePrice", "DESC");
                    }
                  }}
                >
                  {x}순
                </MenuItem>
              );
            })}
          </Menu>
        </Flex>

        {state.view === "grid" ? (
          <Grid container style={{ padding: "0px 10px" }}>
            {state.data.map((x, i) => {
              return <DirectTransactionItemGrid {...x} key={i.toString()} />;
            })}
          </Grid>
        ) : (
          state.data.map((x, i) => {
            return <DirectTransactionItem {...x} key={i.toString()} />;
          })
        )}
        {state.data.length < state.totalCount && <More />}
      </Flex>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
  root: {
    paddingTop: 60,
  },
  main: {
    flexGrow: 1,
  },
  header: {
    padding: "20px 13px 10px 13px",
    borderBottom: "1px solid #eee",
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  buttonRadio: {
    flex: 1,
    backgroundColor: "#dddddd",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
    padding: "10px",
    color: "#999",
  },
  addButton: {
    borderRadius: 15,
    padding: "6px 24px",
    color: "#fff",
    backgroundColor: "#ff6e0e",
    marginLeft: 18,
    alignSelf: "center",
    fontSize: 12,
    fontFamily: fonts.notoSansKRMedium,
  },
  buttonRadioSel: {
    backgroundColor: colors.primary,
    color: "#fff",
  },
  label2: { fontSize: "12px", color: "#000", flex: 1 },
  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "6px",
    fontSize: "12px",
    color: colors.text,
  },
  grid: {
    marginLeft: "20px",
  },
});
