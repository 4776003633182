import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { postRequest } from "../../network";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { userInit, userUpdate } from "../../redux/user/UserActions";
export default function BankPassword({}) {
  const classes = useStyle();
  const history = useHistory();
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password3, setPassword3] = useState("");
  const [password4, setPassword4] = useState("");
  const [password5, setPassword5] = useState("");
  const [password6, setPassword6] = useState("");
  const dispatch = useDispatch();
  const { state, key } = history.location;
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const { id } = useSelector((s) => s.user);

  useEffect(() => {
    if (state.bankPassword) {
      inputRef1.current?.focus();
      setPassword1("");
      setPassword2("");
      setPassword3("");
      setPassword4("");
      setPassword5("");
      setPassword6("");
    }
  }, [state.bankPassword]);
  useEffect(() => {
    if (id && state?.action !== "update") {
      history.replace(routes.main, {});
    }
  }, [key, id, state]);

  useEffect(() => {
    if (password6 && !state?.bankPassword) {
      handleClick();
    }
  }, [password6]);

  useEffect(() => {
    if (password1) {
      inputRef2.current?.focus();
    }
  }, [password1, inputRef2]);

  useEffect(() => {
    if (password2) {
      inputRef3.current?.focus();
    }
  }, [password2, inputRef3]);
  useEffect(() => {
    if (password3) {
      inputRef4.current?.focus();
    }
  }, [password3, inputRef4]);
  useEffect(() => {
    if (password4) {
      inputRef5.current?.focus();
    }
  }, [password4, inputRef5]);

  useEffect(() => {
    if (password5) {
      inputRef6.current?.focus();
    }
  }, [password5, inputRef6]);

  const handleClick = async () => {
    const bankPassword =
      password1 + password2 + password3 + password4 + password5 + password6;
    if (state?.bankPassword) {
      if (bankPassword !== state.bankPassword) {
        dispatch(messageShow("결제 비밀번호가 일치하지 않습니다."));
      } else {
        if (state?.action === "update") {
          //  history.goBack();
          //  dispatch(toastShow("결제 비밀번호가 변경되었습니다."));
        } else {
          history.push("/register-shop", state);
        }
      }
    } else {
      if (bankPassword.length === 6) {
        history.push(history.location.pathname, {
          ...state,
          bankPassword: bankPassword,
        });
      } else {
        dispatch(messageShow("결제 비밀번호 6자리를 입력해 주세요."));
      }
    }
  };
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "결제 비밀번호" }}>
      <Text font={fonts.notoSansKRMedium} className={classes.text}>
        {state?.action === "update"
          ? state?.bankPassword
            ? "결제 비밀번호 재입력"
            : "현재 결제 비밀번호"
          : "결제 비밀번호 등록"}
      </Text>
      <Text className={classes.message}>
        {state?.bankPassword
          ? state?.action === "update"
            ? "한번 더 입력해 주세요."
            : "한번 더 입력해 주세요."
          : state?.action === "update"
          ? "지금 사용하시는 결제비밀번호 6자리를 등록하세요."
          : "안전한 결제를 위해 결제비밀번호 6자리를 등록하세요."}
      </Text>

      <Flex row className={classes.inputs}>
        <input
          type={password1 ? "text" : "number"}
          maxLength={2}
          ref={inputRef1}
          onChange={(e) => {
            const v = e.target.value;
            setPassword1(v.substr(v.length - 1));
          }}
          value={password1 ? "●" : password1}
          className={classes.input}
        />
        <input
          type={password2 ? "text" : "number"}
          onFocus={() => {
            if (!password1) {
              inputRef1.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !password2) {
              inputRef1.current?.focus();
            }
          }}
          onChange={(e) => {
            const v = e.target.value;
            if (!v) {
              inputRef1.current?.focus();
            }
            setPassword2(v.substr(v.length - 1));
          }}
          maxLength={2}
          value={password2 ? "●" : password2}
          ref={inputRef2}
          className={classes.input}
        />
        <input
          type={password3 ? "text" : "number"}
          onFocus={() => {
            if (!password2) {
              inputRef2.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !password3) {
              inputRef2.current?.focus();
            }
          }}
          onChange={(e) => {
            const v = e.target.value;
            if (!v) {
              inputRef2.current?.focus();
            }
            setPassword3(v.substr(v.length - 1));
          }}
          value={password3 ? "●" : password3}
          maxLength={2}
          ref={inputRef3}
          className={classes.input}
        />
        <input
          type={password4 ? "text" : "number"}
          onFocus={() => {
            if (!password3) {
              inputRef3.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !password4) {
              inputRef3.current?.focus();
            }
          }}
          onChange={(e) => {
            const v = e.target.value;
            if (!v) {
              inputRef3.current?.focus();
            }
            setPassword4(v.substr(v.length - 1));
          }}
          maxLength={2}
          value={password4 ? "●" : password4}
          ref={inputRef4}
          className={classes.input}
        />
        <input
          type={password5 ? "text" : "number"}
          onFocus={() => {
            if (!password4) {
              inputRef4.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !password5) {
              inputRef4.current?.focus();
            }
          }}
          onChange={(e) => {
            const v = e.target.value;
            if (!v) {
              inputRef4.current?.focus();
            }
            setPassword5(v.substr(v.length - 1));
          }}
          maxLength={2}
          value={password5 ? "●" : password5}
          ref={inputRef5}
          className={classes.input}
        />
        <input
          type={password6 ? "text" : "number"}
          onFocus={() => {
            if (!password5) {
              inputRef5.current?.focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Backspace" && !password6) {
              inputRef5.current?.focus();
            }
          }}
          onChange={(e) => {
            const v = e.target.value;
            if (!v) {
              inputRef5.current?.focus();
            }
            setPassword6(v.substr(v.length - 1));
          }}
          maxLength={2}
          value={password6 ? "●" : password6}
          ref={inputRef6}
          className={classes.input}
        />
      </Flex>
      {state?.bankPassword && (
        <Button className={classes.button} label="다음" onClick={handleClick} />
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  text: {
    fontSize: 24,
    color: colors.text,
    margin: "100px 30px 0px 30px",
  },
  message: {
    fontSize: 14,
    color: colors.gray,
    margin: "6px 30px 0px 30px",
  },
  button: {
    margin: "50px 30px 0px 30px",
  },
  input: {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px 0px",
    marginRight: "5px",
    textAlign: "center",
    fontSize: 18,
    color: colors.text,
    borderBottom: "3px solid " + colors.text,
    width: "40px",
  },
  inputs: {
    margin: "50px 30px 0px 30px",
  },
});
