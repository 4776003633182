import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { getPurityLabel, numFormat } from "../../services/utils";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import product_default from "../../assets/images/product_default.webp";

export default function ProductListItem({
  bottom,
  gram,
  quantity,
  name,
  path,
  purity,
  type,
  appraisalProduct,
  guaranteeImageUrl,
  price,
  calc,
  topImageUrl,
  frontImageUrl,
  leftSideImageUrl,
  rightSideImageUrl,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const imageUrl =
    topImageUrl || frontImageUrl || leftSideImageUrl || rightSideImageUrl;
  return (
    <Flex
      style={{ ...(bottom && { borderBottom: "none" }) }}
      row
      className={classes.root}
    >
      <img
        src={imageUrl ? consts.s3BaseUrl + "/" + imageUrl : product_default}
        className={classes.sample1}
      />
      <Flex className={classes.main}>
        <Flex className="row-between" row>
          <Text className={classes.label1} font={fonts.notoSansKRMedium}>
            {appraisalProduct.name}
          </Text>
          <Text className={classes.label1} font={fonts.notoSansKRMedium}>
            {/* {calc.toFixed(4)}g */}
          </Text>
        </Flex>

        <Flex className="row-between" row>
          <Text className={classes.label2}>
            {getPurityLabel(purity, appraisalProduct.assetType)}{" "}
            {numFormat(quantity)}개 보증서(
            {guaranteeImageUrl ? "유" : "무"})
          </Text>
          <Text className={classes.label3}>
            {/* (= {numFormat(Math.floor(price))}원) */}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    objectFit: "cover",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
    alignItems: "center",
    padding: "14px 0px",
    margin: "0px 14px",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    flex: 1,
  },
  label1: { color: colors.text, fontSize: 16 },
  label2: {
    fontSize: "12px",
    color: colors.text,
  },
  label3: {
    fontSize: "12px",
    color: colors.gray,
  },
});
