import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

const LeaveConfirmPopup = ({ onCancel = () => {}, onConfirm = () => {} }) => {
  const dispatch = useDispatch();
  const [resignDays, setResignDays] = useState(30);

  useEffect(() => {
    dispatch(loadingStart);
    APIS.getEnvConfig("USER_RESIGNUP_DAYS")
      .then(({ data: { success, data } }) => {
        success && setResignDays(data?.value);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);
  return (
    <div
      className="trade_wrap_bk"
      style={{ position: "fixed", top: 0, zIndex: 40 }}
    >
      <div className="pd_select">
        <div className="withdrawal_pop2">
          <h3>정말 탈퇴 하시겠습니까?</h3>
          <p>
            탈퇴 후 <span className="spoqa">{resignDays}</span>일간 가입하실 수
            없습니다.
          </p>
        </div>
        <div className="withdrawal_btn">
          <button onClick={onCancel}>탈퇴 취소</button>
          <button className="on" onClick={onConfirm}>
            탈퇴 확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveConfirmPopup;
