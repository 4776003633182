import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonChecked from "../../components/button-checked/ButtonChecked";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { objToQueryStr } from "../../services/utils";
import queryString from "query-string";

export default function Verify({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useSelector((s) => s.user);
  const state = history.location.state;
  const encodeRef = useRef();
  const query = queryString.parse(history.location.search);
  const niceFormRef = useRef();

  useEffect(() => {
    const verifyError = (socialKey, type, name, email) => {
      dispatch(
        messageShow("본인 인증 실패했습니다. 다시 시도해 주세요.", () => {
          history.replace("/verify", {
            token: socialKey,
            method: type,
            name: name,
            email: email,
          });
        })
      );
    };
    const checkNiceResult = (isError) => {
      axios
        .get(
          consts.apiBaseUrl +
            "/api/nice/decode" +
            objToQueryStr({
              EncodeData: query.EncodeData,
            })
        )
        .then((res) => {
          const data = res.data;
          const socialKey = queryString.parse(data.REQ_SEQ).socialKey;
          const type = queryString.parse(data.REQ_SEQ).method;
          const email = queryString.parse(data.REQ_SEQ).email;
          const name = queryString.parse(data.REQ_SEQ).name;
          if (isError) {
            verifyError(socialKey, type, name, email);
          } else {
            const phone = data.MOBILE_NO;
            axios
              .get(
                consts.apiBaseUrl +
                  "/api/accounts/phoneCheck" +
                  objToQueryStr({ phone })
              )
              .then((res) => {
                const data = res.data;
                if (data.exits) {
                  history.push("/registerexist", data);
                } else {
                  history.replace("/verify", {
                    token: socialKey,
                    method: type,
                    name: name,
                    email: email,
                    phone,
                  });
                }
              })
              .catch((e) => {
                verifyError(socialKey, type, name, email);
              });
          }
        });
    };
    switch (history.location.pathname) {
      case "/verify/nice/1":
      case "/verify/nice/0":
        checkNiceResult(history.location.pathname === "/verify/nice/0");
        return;
    }
  }, [query, history.location.pathname]);

  useEffect(() => {
    if (userId) {
      history.replace(routes.main, {});
    }
  }, [history.location.key, userId]);

  const basicVerify = () => {
    axios
      .get(
        consts.apiBaseUrl +
          "/api/nice" +
          objToQueryStr({
            socialKey: state.token,
            method: state.method,
            name: encodeURIComponent(state.name),
            email: decodeURIComponent(state.email),
            returnUrl: window.location.origin + "/verify/nice/1",
            errorUrl: window.location.origin + "/verify/nice/0",
          })
      )
      .then((res) => {
        const data = res.data;
        const niceEncodeData = data;
        encodeRef.current.value = niceEncodeData;
        niceFormRef.current.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
        niceFormRef.current.submit();
      })
      .catch((e) => {
        dispatch(messageError(e));
      });
  };

  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "본인인증" }}>
      <Text className={classes.label}>
        금방금방은
        <br />
        안전한 거래를 위해
        <br />
        <Text font={fonts.notoSansKRBold}>본인 인증</Text>
        및 <br />
        <Text font={fonts.notoSansKRBold}>계좌 인증</Text>이 필요합니다.
      </Text>
      {history.location.state?.phone ? (
        <ButtonChecked className={classes.button} label="본인 인증 완료" />
      ) : (
        <Button
          onClick={basicVerify}
          className={classes.button}
          label="본인 인증"
        />
      )}
      <Button
        onClick={() => {
          history.push(routes.verifyBank, state);
        }}
        disabled={!history.location.state?.phone}
        className={classes.button}
        label="계좌 인증"
      />
      <form ref={niceFormRef} method="post" style={{ display: "none" }}>
        <input type="hidden" name="m" value="checkplusService" />
        <input ref={encodeRef} type="hidden" name="EncodeData" />
      </form>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: 24,
    margin: "50px 30px 30px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    margin: "24px 30px 0px 30px",
  },
});
