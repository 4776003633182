import { ButtonBase, makeStyles } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function Button({
  defaultMargin,
  label,
  className,
  onClick,
  disabled,
  style,
  textStyle,
}) {
  const classes = useStyle();
  return (
    <ButtonBase
      style={{
        ...style,
        ...(defaultMargin && {
          margin: "10px 30px 10px 30px",
        }),
      }}
      disabled={disabled}
      onClick={onClick}
      className={`${classes.button} ${
        disabled ? classes.buttonGray : ""
      } ${className}`}
    >
      <Text
        font={fonts.notoSansKRBold}
        className={`${classes.label} ${disabled && classes.labelGray}`}
        style={{ ...textStyle }}
      >
        {label}
      </Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  label: {
    color: colors.white,
    fontSize: 16,
  },
  button: {
    borderRadius: "3px",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px 16px",
    flexDirection: "row",
    backgroundColor: colors.primary,
  },
  buttonGray: { backgroundColor: colors.dddddd },
  labelGray: {
    color: "#bbbbbb",
  },
});
