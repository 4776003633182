// Actions
const INIT = "sign-up/INIT";
const UPDATE = "sign-up/UPDATE";

const INIT_STATE = {
  purpose: "",
  requestId: "",
  termsTypes: [],
  recommandShop: {},
  email: "",
};

// Reducer
export default function reducer(state = INIT_STATE, { type, payload }) {
  switch (type) {
    case INIT:
      return { ...INIT_STATE, ...payload };

    case UPDATE:
      return { ...state, ...payload };

    default:
      return state;
  }
}

// Action 생성자
export function initSignUp(payload) {
  return { type: INIT, payload };
}

export function updateSignUp(payload) {
  return { type: UPDATE, payload };
}
