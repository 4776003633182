import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import styled from "styled-components";

//buy sell
export default function ListItem({
  method,
  data,
  keys,
  center,
  onpriceChangef,
  lastPrice,
  minPrice,
  maxPrice,
}) {
  const classes = useStyle();
  var donPrice = data.orderPrice * 3.75;
  var orderPrice = data.orderPrice;

  function pricedata() {
    onpriceChangef(orderPrice, method);
  }
  return (
    <ButtonBase className={classes.button} onClick={() => pricedata()}>
      <Flex
        className={`${classes.root} ${
          method === "buy" ? classes.rootBuy : classes.rootSell
        }`}
        row
      >
        <Flex className={classes.left}>
          <Flex row style={{ alignItems: "center" }}>
            {orderPrice === +maxPrice && (
              <LimitText color={colors.textRed}>상</LimitText>
            )}
            {orderPrice === +minPrice && (
              <LimitText color={colors.textBlue}>하</LimitText>
            )}
            <Text
              style={{
                // color: center
                //   ? colors.black
                //   : method === "buy"
                //   ? colors.textBlue
                //   : colors.textRed,

                color:
                  data.orderPrice > lastPrice
                    ? colors.textRed
                    : data.orderPrice < lastPrice
                    ? colors.textBlue
                    : colors.black,
              }}
              font={fonts.notoSansKRMedium}
              className={classes.label}
            >
              <NumberFormat
                value={data.orderPrice}
                displayType={"text"}
                thousandSeparator={true}
                className="spoqa"
              />
            </Text>
          </Flex>
          <Text className={classes.hint} font={fonts.notoSansKRLight}>
            <NumberFormat
              value={donPrice}
              displayType={"text"}
              thousandSeparator={true}
              className="spoqa"
            />
            /돈
          </Text>
        </Flex>
        <Flex className={classes.right}>
          <NumberFormat
            value={data.remainGram}
            displayType={"text"}
            thousandSeparator={true}
            className={`${classes.medium} spoqa`}
          />
        </Flex>
      </Flex>
    </ButtonBase>
  );
}

const LimitText = styled(Text)`
  color: ${({ color }) => color};
  border: 1px solid ${({ color }) => color};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 4px;
`;

const useStyle = makeStyles({
  hint: { fontSize: "10px", color: "#999999" },
  button: {
    width: "100%",
    alignSelf: "stretch",
    marginBottom: 1,
    boxSizing: "border-box",
  },
  root: { flex: 1, boxSizing: "border-box" },
  rootSell: { backgroundColor: "#E1F4FE" },
  rootBuy: { backgroundColor: "#FDECEC" },
  left: {
    flex: 3,
    borderRight: "1px solid white",
    alignItems: "flex-end",
    padding: "6px 0px",
    paddingRight: "10px",
    justifyContent: "center",
  },
  right: {
    alignItems: "center",
    justifyContent: "center",
    flex: 2,
    padding: "10px 0px",
  },
  medium: { color: "#444", fontSize: "12px" },
  label: { fontSize: 15 },
});
