import { ButtonBase, makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import colors from "../../libs/colors";
import images from "../../libs/images";
import { imageViewerHide } from "../../redux/image/ImageActions";

export default function ImageViewer({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { open, srcs } = useSelector((s) => s.image);

  const handleClose = () => {
    dispatch(imageViewerHide());
  };

  if (!open) {
    return null;
  }
  return (
    <Flex className={classes.root}>
      <ButtonBase onClick={handleClose} className={classes.close}>
        <img src={images.closewhite} />
      </ButtonBase>
      <Flex className={classes.main}>
        {srcs[0] && <img className={classes.image} src={srcs[0]} />}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    minHeight: "50%",
    justifyContent: "center",
    padding: "30px",
  },

  close: {
    alignSelf: "flex-end",
    padding: "20px 16px",
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 3,
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
  image: {
    width: "90%",
    height: "90%",
    objectFit: "contain",
  },
});
