export const loadingActionType = {
  start: "loading/start",
  end: "loading/end",
};

export const loadingStart = (dispatch) => {
  dispatch({ type: loadingActionType.start });
};
export const loadingEnd = (dispatch) => {
  dispatch({ type: loadingActionType.end });
};
