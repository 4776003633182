import moment from "moment-timezone";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";
import routes from "./routes";
import "moment/locale/ko";
import { STORAGE_KEY } from "./consts";

export const formatTime = (date = new Date(), format = "YYYY-MM-DD") => {
  if (date) {
    return moment(date, "YYYYMMDDHHmmss").locale("ko").format(format);
  } else {
    return "";
  }
};

export const numFormat = (num) => {
  if (num) {
    return Number(num).toLocaleString();
  } else {
    return "";
  }
};

export const decodeJwt = (token) => {
  if (token) {
    return jwtDecode(token);
  } else {
    return {};
  }
};

export const getDistanceFromLatLonInKm = (lat1, lng1, lat2, lng2) => {
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

export const parsePhoneNumber = (phoneNumber, token = " ") => {
  var phone = phoneNumber.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    `$1${token}$2${token}$3`
  );
  return phone;
};

export function numberWithCommas(x) {
  if (x) {
    let onlyNumber = x.toString().replace(/,/gi, "");
    let parts = onlyNumber.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return parts.join(".");
  } else if (x === "") {
    return "";
  } else {
    return "0";
  }
}

export const postMessage = (param) => {
  try {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(param));
    } else {
      // console.log("can not post message");
    }
  } catch (err) {
    console.log("post Message Error", err);
  }
};

export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod|iOS/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const calcDate = (unit, value, base = new Date()) => {
  const copiedDate = new Date(base);
  switch (unit) {
    case "Y":
      copiedDate.setFullYear(copiedDate.getFullYear() + value);
      return copiedDate;
    case "M":
      copiedDate.setMonth(copiedDate.getMonth() + value);
      return copiedDate;

    case "MI":
      copiedDate.setMinutes(copiedDate.getMinutes() + value);
      return copiedDate;
    default:
      copiedDate.setDate(copiedDate.getDate() + value);
      return copiedDate;
  }
};

export const encodeFileToBase64 = (fileBlob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(fileBlob);
  });
};

export const callPhone = (phoneNumber) => {
  window.location.href = "tel:" + phoneNumber;
};

export const getAppVersion = () => {
  const userAgent = navigator.userAgent;

  const reg = /v(\d*)\.(\d*)\.(\d*)/;
  const version = userAgent.match(reg);

  const codePushReg = /cv(\d*)/;
  const codePushVersion = userAgent.match(codePushReg);

  return (version?.[0] || "0") + "." + (codePushVersion?.[1] || "0");
};

export const versionParser = (text = "") => {
  const reg = /[^(0-9|.)]/g;
  const version = text.replace(reg, "");
  const versionArr = version.split(".");

  const parseVersion = versionArr.reduce((acc, cur, index) => {
    return acc + cur * Math.pow(100, versionArr.length - index);
  }, 0);

  return parseVersion;
};

export const requestNativeLogin = (history) => {
  const appVersion = getAppVersion();

  if (versionParser(appVersion) >= versionParser("5.0.0.0")) {
    postMessage({
      command: "REQUEST_LOGIN",
      param: { uri: process.env.REACT_APP_API_URL },
    });
  } else {
    history.push(routes.signIn);
  }
};

export const requestChangePassword = (error) => {
  const appVersion = getAppVersion();

  if (versionParser(appVersion) >= versionParser("6.5.0.0")) {
    postMessage({
      command: "CHANGE_PASSWORD",
      param: {
        uri: process.env.REACT_APP_API_URL,
        token: sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN),
      },
    });
  } else {
    error && error();
  }
};

export const validateEmail = (email) => {
  const regex =
    /^[-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]+@[-0-9A-Za-z!#$%&'*+/=?^_`{|}~]+[.]{1}[0-9A-Za-z]/;

  return regex.test(email);
};
