import styled from "styled-components";
import fonts from "../../libs/fonts";

const Span = styled.span`
  font-family: ${({ font }) => font || fonts.notoSansKRRegular};
  ${({ size }) => (size ? `font-size:${size}px;` : "")}
  ${({ weight }) => (weight ? `font-weight:${weight};` : "")}
  ${({ spacing }) => (spacing ? `letter-spacing:${spacing}px;` : "")}
  ${({ color }) => (color ? `color:${color};` : "")}
`;

export default Span;
