import React from "react";
import rightButton from "../../assets/images/ic_go.png";
import listNoneEventImage from "../../assets/images/list_event_none.png";
import consts from "../../libs/consts";

export default function EventItem({ key, item, type, onClick }) {
  const { serviceImages = [] } = item;
  const imageUrl = serviceImages.find((item) => item.imageType === "THUMBNAIL");

  const convertDateFormat = (date) => {
    if (date === "-") {
      return "-";
    } else {
      const convertDate = date.replace(/-/g, "/");
      const parseDate = new Date(convertDate);
      var year = parseDate.getFullYear();
      var month = parseDate.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      var day = parseDate.getDate();
      day = day >= 10 ? day : "0" + day;
      return parseDate ? [year, month, day].join("-") : "-";
    }
  };

  return (
    <div
      className="ft_wrap_box"
      key={`${type} ${key}`}
      onClick={onClick ? onClick : () => {}}
    >
      <div className="ft_wrap_img">
        <img
          src={
            serviceImages.length > 0 && imageUrl
              ? `${consts.s3BaseUrl}${imageUrl.path}`
              : listNoneEventImage
          }
          alt="이벤트이미지"
          className="ft_event_img"
          style={{ marginBottom: -6, borderRadius: 6, maxHeight: "120px" }}
        />
        {type !== "closed" ? (
          <div className="ft_into_tit cof">진행중</div>
        ) : (
          <>
            <div className="ft_event_img_bg"></div>
            <div className="ft_into_tit">종료</div>
          </>
        )}
      </div>
      <div className="ft_box_text">
        <div className="ft_body lg">
          {item.subject}
          <div className="ft_spo date">
            {convertDateFormat(item?.startDate || "-")} ~{" "}
            {item?.endDate ? convertDateFormat(item?.endDate) : "별도 공지 시까지"}
          </div>
        </div>
        <div className="ft_box_btn">
          <img src={rightButton} alt="버튼" />
        </div>
      </div>
    </div>
  );
}
