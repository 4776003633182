import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { toastShow } from "../../redux/toast/ToastActions";
import Button from "../button/Button";
import CheckboxCircle from "../checkbox-circle/CheckboxCircle";
import Checkbox from "../checkbox/Checkbox";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function PolicyAgreePopup({ data, title, onClose, onAction }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = useState([]);

  useEffect(() => {
    setList([...data]);
  }, [data]);
  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <ButtonBase
          onClick={onClose}
          style={{ margin: "6px", alignSelf: "flex-end" }}
        >
          <img src={images.close} style={{ width: 16, height: 16 }} />
        </ButtonBase>
        <Text font={fonts.notoSansKRBold} className={classes.title}>
          {title}
        </Text>
        <CheckboxCircle
          checked={list.length === list.filter((x) => x.checked).length}
          onCheckedChange={(v) => {
            setList([...list.map((x) => ({ ...x, checked: v }))]);
          }}
          label="전체동의"
          className={classes.all}
          labelSize={14}
        />
        {list.map((x, i) => {
          return (
            <Checkbox
              {...x}
              labelSize={12}
              className={classes.c}
              key={i.toString()}
              onCheckedChange={(v) => {
                list[i].checked = v;
                setList([...list]);
              }}
            />
          );
        })}

        <Flex row className={classes.buttons}>
          <Button onClick={onClose} className={classes.button} label="닫기" />

          <Button
            onClick={() => {
              if (
                list.length >= 1 &&
                list.length === list.filter((x) => x.checked).length
              ) {
                onClose();
                onAction();
              } else {
                dispatch(toastShow("서비스 이용에 동의해 주세요."));
              }
            }}
            className={classes.button}
            label="동의"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    top: 0,
    bottom: 0,
    zIndex: 25,
    right: 0,
    left: 0,
    justifyContent: "flex-end",
  },
  main: {
    backgroundColor: colors.white,
    borderTopRightRadius: 10,
    padding: "13px",
    alignSelf: "stretch",
    borderTopLeftRadius: 10,
  },
  c: {
    margin: "10px 20px 0px 30px",
  },
  title: {
    fontSize: "18px",
    color: colors.text,
    margin: "0px 30px",
  },
  all: {
    margin: "20px 30px 10px 30px",
  },
  buttons: { alignItems: "center", margin: "30px" },
  button: {
    flex: 1,
    "&:first-child": {
      backgroundColor: "#666666",
      marginRight: "5px",
    },
    "&:last-child": {
      marginLeft: "5px",
    },
  },
});
