import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Bottombar from "../../components/bottombar/Bottombar";
import Flex from "../../components/flex/Flex";

import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import Auth from "../../hoc/auth";

export default function MainLayout({ white, className, children, topbar, bottomSize }) {
  const classes = useStyle();
  return (
    <BasicLayout>
      {topbar}
      <Flex
        style={{
          //  maxHeight: topbar ? "calc(100vh - 120px)" : "calc(100vh - 60px)",
          backgroundColor: white ? "#fff" : colors.backgroundGray,
        }}
        className={classes.scroll + " " + className}
      >
        {children}
      </Flex>
      <BottomBarWithNavigate relative />
    </BasicLayout>
  );
}
const BottomBarWithNavigate = Auth(Bottombar);

const useStyle = makeStyles({
  root: {},
  scroll: {
    flex: 1,
    overflowY: "scroll",
    boxSizing: "border-box",
    paddingBottom: 60,
  },
});
