import { ButtonBase, Input, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import AppraiseItem from "../../components/appraise-item/AppraiseItem";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import Button from "../../components/button/Button";
import DateSelector from "../../components/date-selector/DateSelector";
import Flex from "../../components/flex/Flex";
import ProductList from "../../components/product-list/ProductList";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import TimeRangeSelectorNew from "../../components/time-range-selector/TimeRangeSelectorNew";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import PaymentPopup from "./PaymentPopup";
import consts from "../../libs/consts";
import {
  calcDate,
  formatTime,
  numberWithCommas,
  numFormat,
} from "../../libs/utils";
import { axiosDispatch } from "../../services/network";
import { actionError } from "../../redux/action/ActionActions";
import { initFindShop } from "../../redux/common/findShop";
import PhysicalWithDrawalItemOrder from "../../components/physical-withdrawal-item/PhysicalWithDrawalItemOrder";
import { NoEncryption } from "@material-ui/icons";
import * as APIS from "../../libs/apis";
import { I_001 } from "../../libs/messages";
import Calendar from "../../components/common/Calendar";
import VisitReservation from "../../components/common/VisitReservation";
import ic_price from "../../assets/images/ic_price.png";
import FormatAmount from "../../components/common/FormatAmount";
import { messageError } from "../../redux/message/MessageActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import InputSmallBorder from "../../components/input-small-border/InputSmallBorder";
import DataButton from "../physical-withdrawal-pdetail/components/DataButton";
import NumberCounter from "../../components/common/NumberCounter";

// 택배 수령 추가
import styled, { css } from "styled-components";
import { PHONE_CODE } from "../../libs/consts";
import DaumPostcode from "react-daum-postcode";
import Modal from "../../components/modal/Modal";

const DELIVERY_COMMENTS = [
  "문 앞에 놓아주세요.",
  "택배함에 넣어주세요.",
  "경비실에 맡겨주세요.",
  "부재 시 연락주세요.",
  "직접 받고 부재 시 문 앞에 놓아주세요.",
];

const TABS = {
  DELIVERY: "delivery",
  SHOP: "shop",
};

export default function Order({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [asset, setAsset] = useState({});
  const [deliveryPossibleConfig, setDeliveryPossibleConfig] = useState(null);

  const state = history.location.state || {};
  const minDate = state.detail.earliestVisitDate
    ? new Date(state.detail.earliestVisitDate)
    : calcDate("D", state.detail.days || 1);
  // console.log(state.deliveryRequest, state.isDelivery);

  // 배송지 정보
  const deliveryRequest = state?.deliveryRequest || {};
  const [isDelivery, setIsDelivery] = useState(
    state?.isDelivery === undefined ? false : state?.isDelivery
  );
  const [addressName, setAddressName] = useState(
    deliveryRequest.addressName || ""
  );
  const [receiver, setReceiver] = useState(deliveryRequest.receiver || "");
  const [phone, setPhone] = useState(deliveryRequest.phone || ["010", "", ""]);
  const [mobile, setMobile] = useState(
    deliveryRequest.mobile || ["010", "", ""]
  );
  const [postCode, setPostCode] = useState(deliveryRequest.postCode || "");
  const [address, setAddress] = useState(deliveryRequest.address || "");
  const [addressDetail, setAddressDetail] = useState(
    deliveryRequest.addressDetail || ""
  );
  const [memo, setMemo] = useState(deliveryRequest.memo || "");

  const [modalVisible, setModalVisible] = useState(false);

  // 초기 탭 설정
  useEffect(() => {
    let initTab = false;
    APIS.getAvailableAmount().then(({ data: { data = {} } }) => {
      setAsset(data);
    });

    APIS.getEnvConfig("GOODS_USING_DELIVERY_POST").then(
      ({ data: { data = {} } }) => {
        // console.log(data.value);
        setDeliveryPossibleConfig(data.value);
        if (state?.isDelivery === undefined) {
          // 전역 설정 택배 가능 일 때
          if (data.value === "true") {
            const deliveryDisabled = isTabDisabled(
              true,
              data.value,
              state.detail.isShopTaken,
              state.detail.isPost
            );
            const shopDisabled = isTabDisabled(
              false,
              data.value,
              state.detail.isShopTaken,
              state.detail.isPost
            );
            if (deliveryDisabled) {
              handleClickTab(false);
              initTab = false;
            } else if (shopDisabled) {
              handleClickTab(true);
              initTab = true;
            } else {
              if (!deliveryDisabled && !shopDisabled) {
                handleClickTab(false);
                initTab = false;
              }
            }
          } else {
            // 전역 설정 택배 불가능일 때 isDelivery를 false로 변경
            handleClickTab(false);
            initTab = false;
          }
        }
      }
    );

    // isDelivery 초기화
    APIS.getDeliveryAddress().then(({ data: { data = {} } }) => {
      const defaultAddress = data.find((address) => address.isDefault === true);
      if (state.isDelivery === undefined) {
        if (!state.deliveryRequest) {
          // deliveryRequest가 존재하지 않을 때만 초기화
          if (defaultAddress) {
            // 기본 배송지가 있을 때
            const newPhone = [
              defaultAddress.phone.slice(0, 3),
              defaultAddress.phone.slice(3, 7),
              defaultAddress.phone.slice(7),
            ];
            const newMobile = [
              defaultAddress?.mobile?.slice(0, 3),
              defaultAddress?.mobile?.slice(3, 7),
              defaultAddress?.mobile?.slice(7),
            ];

            setReceiver(defaultAddress.receiver);
            setPhone(newPhone);
            setMobile(newMobile);
            setPostCode(defaultAddress.postCode);
            setAddress(defaultAddress.address);
            setAddressDetail(defaultAddress.addressDetail);
            setMemo(defaultAddress.memo);

            const newDeliveryRequest = {
              receiver: defaultAddress.receiver,
              phone: newPhone,
              mobile: newMobile,
              postCode: defaultAddress.postCode,
              address: defaultAddress.address,
              addressDetail: defaultAddress.addressDetail,
              memo: defaultAddress.memo,
            };

            history.replace({
              ...history.location,
              state: {
                ...history.location.state,
                deliveryRequest: newDeliveryRequest,
                isDelivery: initTab,
              },
            });
          } else {
            // 기본 배송지가 없을 때
            history.replace(history.location.pathname, {
              ...state,
              deliveryRequest: {
                receiver: "",
                phone: ["010", "", ""],
                postCode: "",
              },
              isDelivery: initTab,
            });
          }
        }
      }
    });
  }, []);

  const isTabDisabled = (
    isDelivery,
    deliveryPossibleConfig,
    isShopTaken,
    isPost
  ) => {
    // 택배
    if (isDelivery) {
      if (deliveryPossibleConfig === "true") {
        // 택배, 샵 둘 다 선택 가능함.
        // isPost가 false면 True
        return !isPost;
      } else {
        // 전체 택배 설정 막히면 택배 막음.
        return true;
      }
    } else {
      // 샵
      if (deliveryPossibleConfig === "true") {
        // 대리점 수령 여부에 따라 비활성화
        return !isShopTaken;
      } else {
        // 전역상태에서 택배 막히면 무조건 매장 활성화
        return false;
      }
    }
  };

  const deliveryDisabled = isTabDisabled(
    true,
    deliveryPossibleConfig,
    state.detail.isShopTaken,
    state.detail.isPost
  );
  const shopDisabled = isTabDisabled(
    false,
    deliveryPossibleConfig,
    state.detail.isShopTaken,
    state.detail.isPost
  );

  const isPaymentEnabled = () => {
    if (isDelivery) {
      const deliveryInfo = state.deliveryRequest;

      return (
        deliveryInfo?.receiver !== "" &&
        state.deliveryRequest?.phone?.join("")?.length !== 3 &&
        deliveryInfo?.postCode !== ""
      );
    } else {
      return state.shop?.id && state.reservation?.date;
    }
  };

  const handleClickTab = (tab) => {
    // console.log(`tab: ${tab}`);
    setIsDelivery(tab);
    history.replace({
      ...history.location,
      state: { ...history.location.state, isDelivery: tab },
    });
  };

  const handleCompleteSearch = (data) => {
    const newPostCode = data.zonecode;
    const newAddress = data.address;
    const newAddressDetail = "";

    setPostCode(newPostCode);
    setAddress(newAddress);
    setAddressDetail(newAddressDetail);

    const newDelivery = {
      ...deliveryRequest,
      postCode: newPostCode,
      address: newAddress,
      addressDetail: newAddressDetail,
    };

    history.replace({
      ...history.location,
      state: { ...history.location.state, deliveryRequest: newDelivery },
    });

    setModalVisible(false);
  };

  const handleInputChange = (field, value) => {
    const newDelivery = { ...deliveryRequest, [field]: value };
    switch (field) {
      case "addressName":
        setAddressName(value);
        break;
      case "receiver":
        setReceiver(value);
        break;
      case "postCode":
        setPostCode(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "addressDetail":
        setAddressDetail(value);
        break;
      case "memo":
        setMemo(value);
        break;
      default:
        break;
    }
    // history.state 업데이트
    history.replace({
      ...history.location,
      state: { ...history.location.state, deliveryRequest: newDelivery },
    });
  };

  const handlePhoneChange = (field, index, value) => {
    const updatedArray = field === "phone" ? [...phone] : [...mobile];
    updatedArray[index] = value;

    if (field === "phone") {
      setPhone(updatedArray);
    } else if (field === "mobile") {
      setMobile(updatedArray);
    }

    const newDelivery = { ...deliveryRequest, [field]: updatedArray };

    history.replace({
      ...history.location,
      state: { ...history.location.state, deliveryRequest: newDelivery },
    });
  };

  if (!state.type) {
    return <Redirect to={routes.main} />;
  }

  var x = state.detail;
  const type = x.goldOrSilver.toLowerCase();

  const handleClickPayment = () => {
    // 택배일 때
    if (state.isDelivery || state.isDelivery === undefined) {
      if (!state.deliveryRequest?.receiver) {
        dispatch(messageError("수령인을 입력해주세요."));
        return;
      }
      if (state.deliveryRequest?.phone?.join("")?.length === 3) {
        dispatch(messageError("연락처1을 입력해주세요."));
        return;
      }
      if (!state.deliveryRequest?.postCode) {
        dispatch(messageError("배송지를 입력해주세요."));
        return;
      }
    } else {
      // 대리점 방문일 때
      if (!state.shop?.id) {
        dispatch(messageError("방문 매장을 선택해주세요."));
        return;
      }
      if (!state.reservation?.date) {
        dispatch(messageError("방문 일자를 선택해주세요."));
        return;
      }
    }

    if (!state.stock) {
      dispatch(messageError("수량을 입력해주세요."));
      return;
    }

    if (
      x.gram * state.stock > asset[type] ||
      x.fees * state.stock > asset["krw"] + asset["point"]
    ) {
      dispatch(messageError("주문 가능 수량을 초과하였습니다."));
      return false;
    }

    dispatch(loadingStart);
    APIS.getServiceBreak().then((serviceBreak) => {
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        dispatch(loadingEnd);
      } else {
        dispatch(loadingEnd);
        setPaymentVisible(true);
      }
    });
  };

  const handleChangeStock = (value) => {
    const stock = value < 0 ? 0 : +value;

    if (x.gram * stock > asset[type]) {
      return false;
    }

    if (x.fees * stock > asset["krw"] + asset["point"]) {
      return false;
    }

    history.replace(history.location.pathname, { ...state, stock });
  };

  const disabledTabStyle = {
    border: "1px solid #dddddd",
    color: "#bbbbbb",
    backgroundColor: "#dddddd",
    cursor: "not-allowed",
  };

  return (
    <BasicLayout stackTopbar={{ title: "주문 상세", border: true }}>
      {modalVisible ? (
        <Container>
          <ModalBackdrop onClick={() => setModalVisible(false)} />
          {modalVisible && (
            <ModalBody modalVisible={modalVisible}>
              <DaumPostcode autoClose onComplete={handleCompleteSearch} />
            </ModalBody>
          )}
        </Container>
      ) : null}
      <>
        <div className="kb_cal_wrap">
          <div className="cal_top bgbg withdrawal">
            <h3>
              해당 상품은 <span className="fwbd">원화</span>
              <span className="fwrg">로만</span>{" "}
              <span className="fwbd">결제</span> 가능합니다.
            </h3>
            <ul>
              <li className="ul_tit">
                <img src={ic_price} />
                사용가능
              </li>
              <li className="ul_table">
                <table>
                  <tbody>
                    <tr className="bdbt">
                      <td>{type === "gold" ? "금" : "은"}</td>
                      <td>
                        <table className="num_tb">
                          <tr>
                            <FormatAmount amount={asset[type]} />
                            <td className="gram">g</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>원화</td>
                      <td>
                        <table className="num_tb">
                          <tr>
                            <FormatAmount
                              amount={asset["krw"] + asset["point"]}
                            />
                            <td className="won">원</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
          <Bar />
          <div className="cal_product">
            <GoodsTitle>주문 상품</GoodsTitle>
            <ul>
              <li className="ul_tit ul_img">
                <img
                  src={
                    state.detail.imageIds[0]?.path
                      ? consts.s3BaseUrl + "/" + state.detail.imageIds[0]?.path
                      : images.goldbar
                  }
                  alt="img"
                />
              </li>
              <li className="ul_table">
                <table>
                  <tbody>
                    <tr className="product_num">
                      <td>{x.name}</td>
                      <td>
                        <span className="spoqa">{x.code}</span>
                      </td>
                    </tr>
                    <tr className="product_name">
                      <td>{x.name}</td>
                    </tr>
                    <tr>
                      <td>제품 중량</td>
                      <td>
                        <span className="spoqa">
                          {numberWithCommas(x.gram * state.stock)}
                        </span>
                        g
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Flex style={{ height: 40, justifyContent: "center" }}>
                          제품 수량
                        </Flex>
                      </td>
                      <td>
                        <NumberCounter
                          value={state.stock}
                          onChange={handleChangeStock}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>선택 옵션</td>
                      <td>{state.seloption || "-"}</td>
                    </tr>
                    <tr>
                      <td>인출 수수료</td>
                      <td>
                        <span className="spoqa">
                          {numberWithCommas(x.fees * state.stock)}
                        </span>
                        원
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
          {deliveryPossibleConfig === "true" ? (
            <>
              <Bar />
              <HowToReceiveTab>
                <Title>수령 방법 선택</Title>
                <DeliveryMethodTabs>
                  <Tab
                    left
                    selected={isDelivery === false}
                    style={shopDisabled ? disabledTabStyle : {}}
                    onClick={() => !shopDisabled && handleClickTab(false)}
                  >
                    {isDelivery === false ? selectedCheck : notSelectedCheck}
                    대리점 방문
                  </Tab>
                  <Tab
                    selected={isDelivery === true}
                    style={deliveryDisabled ? disabledTabStyle : {}}
                    onClick={() => !deliveryDisabled && handleClickTab(true)}
                  >
                    {isDelivery === true ? selectedCheck : notSelectedCheck}
                    택배 수령
                  </Tab>
                </DeliveryMethodTabs>
              </HowToReceiveTab>
            </>
          ) : null}
          <Bar />
          <div style={{ margin: "24px 16px 100px" }}>
            {isDelivery ? (
              <div>
                <GoodsTitle>배송지</GoodsTitle>
                <Row>
                  <Label>배송지명</Label>
                  <FocusInput
                    value={addressName}
                    onChange={(e) => handleInputChange("addressName", e)}
                    placeholder="집, 회사 등 배송지명을 입력하세요."
                  />
                </Row>
                <Row>
                  <Label>
                    수령인<Star>*</Star>
                  </Label>
                  <FocusInput
                    value={receiver}
                    onChange={(e) => handleInputChange("receiver", e)}
                    placeholder="20자 이내로 입력하세요."
                  />
                </Row>
                <Row>
                  <Label>
                    연락처1<Star>*</Star>
                  </Label>
                  <FocusSelect
                    value={phone[0]}
                    onChange={({ target: { value } }) =>
                      handlePhoneChange("phone", 0, value)
                    }
                    isNumber={true}
                  >
                    {PHONE_CODE.map((code) => (
                      <option key={code} label={code} value={code} />
                    ))}
                  </FocusSelect>
                  <Dash />
                  <FocusInput
                    type="number"
                    value={phone[1]}
                    onChange={(value) => handlePhoneChange("phone", 1, value)}
                    placeholder="0000"
                    maxLength={4}
                  />
                  <Dash />
                  <FocusInput
                    type="number"
                    value={phone[2]}
                    onChange={(value) => handlePhoneChange("phone", 2, value)}
                    placeholder="0000"
                    maxLength={4}
                  />
                </Row>
                <Row>
                  <Label>연락처2</Label>
                  <FocusSelect
                    value={mobile[0]}
                    onChange={({ target: { value } }) =>
                      handlePhoneChange("mobile", 0, value)
                    }
                    isNumber={true}
                  >
                    {PHONE_CODE.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </FocusSelect>
                  <Dash />
                  <FocusInput
                    type="number"
                    value={mobile[1]}
                    onChange={(value) => handlePhoneChange("mobile", 1, value)}
                    placeholder="0000"
                    maxLength={4}
                  />
                  <Dash />
                  <FocusInput
                    type="number"
                    value={mobile[2]}
                    onChange={(value) => handlePhoneChange("mobile", 2, value)}
                    placeholder="0000"
                    maxLength={4}
                  />
                </Row>
                <Row>
                  <Label>
                    배송지<Star>*</Star>
                  </Label>
                  <div>
                    <Flex row>
                      <FocusInput
                        type="text"
                        readOnly
                        value={postCode}
                        placeholder="우편번호"
                      />
                      <SearchButton onClick={() => setModalVisible(true)}>
                        주소검색
                      </SearchButton>
                    </Flex>
                    <FocusInput
                      type="text"
                      readOnly
                      value={address}
                      placeholder="기본주소"
                      style={{ marginTop: "8px" }}
                    />
                    <FocusInput
                      type="text"
                      placeholder="상세주소"
                      value={addressDetail}
                      onChange={(e) => handleInputChange("addressDetail", e)}
                      style={{ marginTop: "8px" }}
                    />
                  </div>
                </Row>
                <Row>
                  <Label>수령메모</Label>
                  <FocusSelect
                    value={memo}
                    onChange={({ target: { value } }) =>
                      handleInputChange("memo", value)
                    }
                  >
                    <option
                      label="선택해주세요."
                      value=""
                      style={{ display: "none" }}
                    />
                    {DELIVERY_COMMENTS.map((value) => (
                      <option key={value} label={value} value={value} />
                    ))}
                  </FocusSelect>
                </Row>
              </div>
            ) : (
              <>
                <GoodsTitle>대리점</GoodsTitle>
                <VisitReservation
                  description="상품 수령을 위해 방문하실 매장을 선택해 주세요."
                  minDate={minDate}
                  deliveryLabel={false}
                  goodsId={state.detail.id}
                  shopType={"withdraw"}
                />
              </>
            )}
          </div>

          <button
            className={`bt_btn ${isPaymentEnabled() ? "on" : "off"}`}
            onClick={handleClickPayment}
          >
            결제하기
          </button>
        </div>
        {paymentVisible && (
          <PaymentPopup
            type={"direct-transaction-order"}
            onClose={() => {
              setPaymentVisible(false);
            }}
            rows={x}
            stock={state.stock}
            optionValue={state.seloption}
            assetKrw={asset["krw"]}
            assetGram={asset[type]}
            visitDate={formatTime(state?.reservation?.date)}
            shop={state.shop}
            deliveryRequest={{
              ...state.deliveryRequest,
              phone: state.deliveryRequest?.phone
                ? state.deliveryRequest?.phone.join("")
                : "",
              mobile: state.deliveryRequest?.mobile
                ? state.deliveryRequest?.mobile.join("")
                : "",
              isDefault: true,
            }}
            isDelivery={state.isDelivery}
          />
        )}
      </>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
    background: "none",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
    background: "none",
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": { color: colors.text, marginLeft: "20px" },
  },
  headerLabel1: { fontSize: "12px", color: "#333" },
  headerLabel2: { fontSize: "14px", color: "#333" },
  simpleTable: {
    marginTop: "3px",
    "& span": {
      "&:first-child": {
        marginRight: "30px",
        color: colors.text,
        fontSize: "12px",
      },
      "&:last-child": {
        fontSize: "14px",
        color: "#000",
      },
    },
  },
  form: { width: "200px" },
});

const Title = styled.div`
  color: #222;
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
`;

const GoodsTitle = styled(Title)`
  padding-bottom: 12px;
  border-bottom: 1px solid #eaeaea;
`;

const HowToReceiveTab = styled.div`
  padding: 24px 16px;
`;

const Bar = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
`;

// 수령 방법 탭 컴포넌트
const DeliveryMethodTabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: fit-content;
  height: 44px;
`;

const Tab = styled.div`
  display: flex;
  min-width: 165px;
  height: 44px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: ${(props) =>
    props.left ? "4px 0px 0px 4px" : "0px 4px 4px 0px"};
  color: ${(props) => (props.selected ? "#444" : "#999999")};
  font-weight: ${(props) => (props.selected ? "700" : "400")};
  border: 1px solid ${(props) => (props.selected ? "#000" : "#DBDBDB")};
  background-color: ${(props) => (props.selected ? "#FFF" : "#FAFAFA")};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Label = styled.div`
  color: #777;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 60px;
`;

const FocusInput = ({ maxLength, type, onChange, ...rest }) => {
  return (
    <FocusInputContainer
      type={type}
      isNumber={type === "number"}
      onChange={({ target: { value } }) => {
        if (maxLength && maxLength < value.length) {
          return false;
        }
        if (value && type === "number" && !/^[0-9]+$/.test(value)) {
          return false;
        }
        onChange && onChange(value);
      }}
      {...rest}
    />
  );
};

const FocusInputContainer = styled.input`
  flex: 1;
  width: 100%;
  height: 35px;
  padding: 0 12px;
  color: #444;
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;

  transition: border-color 0.3s;

  &:hover {
    border-color: #777;
  }

  &:focus {
    border-color: #777;
  }

  ${(props) =>
    props.hasInput &&
    css`
      border-color: #777;
    `}

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;

const FocusSelect = styled.select`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;

  &:focus {
    border: 1px solid #222222;
  }

  background-image: linear-gradient(45deg, transparent 50%, #656667 50%),
    linear-gradient(135deg, #656667 50%, transparent 50%);

  background-position: calc(100% - 10px) calc(1em + 2px),
    calc(100% - 5px) calc(1em + 2px);

  background-size: 5px 5px, 5px 5px, 1px;

  background-repeat: no-repeat;
  appearance: none;

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      // text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;

const Dash = styled.span`
  width: 4px;
  height: 1px;
  background: #dbdbdb;
`;

const SearchButton = styled.button`
  margin-left: 8px;
  background-color: #999999;
  border: none;
  border-radius: 4px;
  padding: 10px 9px;
  width: 70px;
  height: 35px;
  color: #fff;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const selectedCheck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M1.03149 6.93457L4.82732 12.2744L12.321 1.72559"
      stroke="#FF6E0E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const notSelectedCheck = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M1.35522 6.93457L5.15105 12.2744L12.6447 1.72559"
      stroke="#CACACA"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// render 모달
const Container = styled.div`
  ${({ theme }) => theme.flexCenter};
  height: 100%;
`;
const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;

  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;

const Star = styled.span`
  color: #e76e26;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
