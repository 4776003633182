import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";

export default function Policy({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;

  const [content, setContent] = useState("");
  useEffect(() => {
    axios
      .post(consts.apiBaseUrl + "/api/terms/list", { kind: state.kind })
      .then((res) => {
        const data = res.data[0];
        if (!data) {
          dispatch(
            messageShow("약관을 불러올 수 없습니다.", () => {
              history.goBack();
            })
          );
        } else {
          setContent(data.content);
        }
      })
      .catch((e) => {
        dispatch(messageError(e));
      });
  }, []);
  return (
    <BasicLayout
      stackTopbar={{
        title: state?.kind,
      }}
    >
      <Text className={classes.text}>
        {content.split("").map((x, i) => {
          if (x === "\n") {
            return <br key={i.toString()} />;
          } else {
            return x;
          }
        })}
      </Text>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  text: { fontSize: "12px", color: "#666", margin: "30px 20px 50px 20px" },
});
