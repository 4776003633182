import React, { useEffect, useState } from "react";
import bottom_check from "../../assets/images/bottom_check.png";
import bottom_close from "../../assets/images/bottom_close.png";
import * as APIS from "../../libs/apis";
import consts, { STORAGE_KEY } from "../../libs/consts";
import styled from "styled-components";
import routes from "../../libs/routes";
import { useHistory } from "react-router-dom";

const stringify = (array) => {
  try {
    return JSON.stringify(array) || "";
  } catch {
    return "";
  }
};

const parse = (string) => {
  try {
    return JSON.parse(string) || [];
  } catch {
    return [];
  }
};

const BottomPopup = () => {
  const history = useHistory();
  const [popups, setPopups] = useState([]);
  const [showMainPop, setShowMainPop] = useState(false);
  const HOME_VISITED = parse(
    localStorage.getItem(STORAGE_KEY.POPUP_EXPIRES)
  )?.expires;

  useEffect(() => {
    APIS.getPopups().then(({ data: { success, data = [] } }) => {
      if (success) {
        setPopups(data);
      }
    });
  }, []);

  useEffect(() => {
    if (popups.length && showMainPop) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "unset";
      };
    }
  }, [showMainPop]);

  useEffect(() => {
    const today = new Date();

    const handleMainPop = () => {
      if (HOME_VISITED === 0) {
        return;
      }
      if (HOME_VISITED && HOME_VISITED > today) {
        // 현재 date가 localStorage의 시간보다 크면 return
        return;
      }
      if (!HOME_VISITED || HOME_VISITED < today) {
        // 저장된 date가 없거나 today보다 작다면 popup 노출
        setShowMainPop(true);
      }
    };
    window.setTimeout(handleMainPop, 500);
  }, [HOME_VISITED]);

  // 닫기 또는 확인
  const handleClosePopup = () => {
    setShowMainPop(false);
  };

  // ~ 일간 보지 않기
  const handlePeriodClose = (expireDays) => {
    if (expireDays !== 0) {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + expireDays * 24);
      localStorage.setItem(
        STORAGE_KEY.POPUP_EXPIRES,
        stringify({ expires: +expires })
      );
      // 현재 시간의 24시간 뒤의 시간을 homeVisited에 저장
      setShowMainPop(false);
    } else {
      localStorage.setItem(
        STORAGE_KEY.POPUP_EXPIRES,
        stringify({ expires: +expireDays })
      );
      setShowMainPop(false);
    }
  };

  const handleClickPopup = ({ category, content }) => {
    if (content) {
      if (category === "EVENT") {
        postMessage({
          command: "SET_HEADER",
          param: {
            visible: false,
            goBack: "DEFAULT",
            title: "이벤트",
          },
        });

        window.location.href = content;
      } else {
        history.push(routes.noticeDetail(content), {
          id: Number(content),
          category: category,
        });
      }
    }
  };
  if (showMainPop && popups.length) {
    const { noticeId, popupPeriod, path, ...rest } = popups[0];
    return (
      <Dimmed className="bottomsheet">
        <div className="sheet_wrap">
          <div className="sheet_top">
            <ul>
              {/* 3일간 보지 않기,7일간 보지 않기,30일간 보지 않기,하루동안 보지 않기,다시 보지 않기 */}
              {popupPeriod ? (
                <li
                  className="check_btn"
                  onClick={() => handlePeriodClose(popupPeriod)}
                >
                  <img src={bottom_check} />
                  <span className="spoqa">
                    {popupPeriod
                      ?.toString()
                      ?.replace("1", "오늘 하루 ")
                      ?.replace("3", "3일간 ")
                      ?.replace("7", "7일간 ")
                      ?.replace("0", "다시 ")}
                  </span>
                  보지 않기
                </li>
              ) : (
                <li />
              )}
              <li className="close_btn" onClick={() => handleClosePopup()}>
                <img src={bottom_close} />
                닫기
              </li>
            </ul>
          </div>
          <div className="sheet_cont">
            <div className="sheet_image">
              <img
                src={consts.s3BaseUrl + path}
                onClick={() => handleClickPopup({ ...rest })}
              />
            </div>
          </div>
          <button className="act_button" onClick={() => handleClosePopup()}>
            확인
          </button>
        </div>
      </Dimmed>
    );
  } else {
    return <></>;
  }
};

const Dimmed = styled.div`
  z-index: 20;
  position: fixed;
  width: 100%;
  height: 100%;
`;
export default BottomPopup;
