import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Image from "../../components/image/Image";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import images from "../../libs/images";
import routes from "../../libs/routes";

export default function SelectBank({}) {
  const classes = useStyle();
  const history = useHistory();
  const [data, setData] = useState([]);

  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "은행 선택" }}>
      <Grid container className={classes.grid}>
        {[
          { src: images.kb, name: "필수국민", bankCode: "001", type: "kb" },
          {
            src: images.woori,
            name: "필수국민",
            bankCode: "001",
            type: "woori",
          },
          { src: images.ibk, name: "필수국민", bankCode: "001", type: "ibk" },
        ].map((x, i) => {
          return (
            <ButtonBase
              onClick={() => {
                //history.goBack();
                history.replace(history.location.state.to, {
                  ...history.location.state.state,
                  bank: x,
                });
              }}
              key={i.toString()}
              className={classes.item + " shadow"}
            >
              <Image alt="bank-logo" src={x.src} width="60%" />
            </ButtonBase>
          );
        })}
      </Grid>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  grid: {
    margin: "50px 30px 50px 30px",
  },
  item: {
    marginRight: "16px",
    width: "calc(30% - 16px)",
    borderRadius: "3px",
    backgroundColor: colors.white,
    padding: "20px 0px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
});
