import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { actionShow } from "../../redux/action/ActionActions";
import service_list1 from "../../assets/images/service_list1.png";
import service_list2 from "../../assets/images/service_list2.png";
import service_list3 from "../../assets/images/service_list3.png";
import service_list4 from "../../assets/images/service_list4.png";
import ic_con_right from "../../assets/images/ic_con_right.png";
import ic_con_right_org from "../../assets/images/ic_con_right_org.png";

export default function AppraiseRegistMain({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const location = history.location;
  const userReducer = useSelector((s) => s.user);

  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        onGoBack: null,
        title: "감정평가 서비스",
        option: (
          <ButtonBase
            onClick={() => {
              history.replace(routes.main);
            }}
          >
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="service_wrap">
        <div className="service_main">
          <h3 className="service_tit">감정평가 서비스란</h3>
          <p className="service_cont">
            고객님의 금/은 제품을 전문 감정을 통해
            <br />
            시세를 확인하고 자산으로 교환할 수 있습니다.
          </p>
          <div className="service_list">
            <ul>
              <li>
                <img src={service_list1} alt="" />
                <br />
                시세확인
              </li>
              <li>
                <img src={service_list2} alt="" />
                <br />
                자산교환
              </li>
              <li>
                <img src={service_list3} alt="" />
                <br />
                가치상승
              </li>
              <div className="service_img">
                <img src={service_list4} alt="" />
              </div>
            </ul>
          </div>
        </div>
        <div className="service_order">
          <div>
            <h3 className="order_tit">1. 간편감정 서비스</h3>
            <p className="order_cont">
              보유중인 금, 은 제품의 정보를 입력하여 현재 시세를 확인
              <br />
              하고 자산교환 신청을 할 수 있습니다.
            </p>
            <button
              className="org_bt"
              onClick={() => navigate(routes.appraiseRegist, { step: 1 })}
            >
              금/은 간편감정 시작하기
              <img src={ic_con_right} alt=">" className="ic_right" />
            </button>
            <div className="order_txt">
              <p>
                <span>간편감정 결과는</span> 고객님이 입력한 정보에 기초하여
                제공 되며, 매장에서의 실제 감정 결과와 다를 수 있습니다.
              </p>
            </div>
          </div>
          <div>
            <h3 className="order_tit">2. 현장감정 서비스</h3>
            <p className="order_cont">
              제품정보 입력 없이 방문한 매장에서 바로 감정과
              <br />
              자산교환을 할 수 있습니다.
            </p>
            <button
              className="wh_bt"
              onClick={() => navigate(routes.appraiseDirect)}
            >
              현장 감정 접수하기
              <img src={ic_con_right_org} alt=">" className="ic_right" />
            </button>
          </div>

          <p>
            본 서비스는 한국금거래소디지털에셋이 제공합니다.
            <br />
            www.korda.im
          </p>
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  desc: {
    fontSize: "16px",
    color: colors.text,
    margin: "50px 30px 30px 30px",
    "& span": {
      fontSize: "20px",
    },
  },
  desc2: {
    fontSize: "12px",
    color: colors.text,
    margin: "30px 30px 30px 30px",
  },

  images: {
    margin: "0px 30px",
    justifyContent: "space-between",
    alignItems: "center",
    "& div": {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      "& img": {
        width: "70px",
        height: "70px",
      },
      "& span": {
        fontSize: "12px",
        marginTop: "10px",
        color: colors.text,
      },
    },
  },
  button: {
    margin: "10px 30px 0px 30px",
    "&:last-child": {
      backgroundColor: "#fff",
      border: "1px solid " + colors.primary,
      "& span": { color: colors.primary },
    },
  },
  desc3: {
    fontSize: "10px",
    color: colors.gray,
    margin: "10px 30px 30px 30px",
  },
});
