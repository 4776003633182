import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import images from "../../libs/images";
import routes from "../../libs/routes";
import AppraiseRegist1 from "./AppraiseRegist1";
import AppraiseRegist2 from "./AppraiseRegist2";
import AppraiseRegist3 from "./AppraiseRegist3";
import AppraiseRegist4 from "./AppraiseRegist4";
import AppraiseRegistResult from "./AppraiseRegistResult";
export default function AppraiseRegist({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const userReducer = useSelector((s) => s.user);

  if (!userReducer.id) {
    return <Redirect to={routes.appraiseRegistMain} />;
  }

  if (!state.step) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname,
          state: {
            ...history.location.state,
            step: 1,
          },
        }}
      />
    );
  }

  const isResult = !!state?.result;
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        onGoBack: isResult
          ? () => {
              history.replace(routes.mypage, {
                tab: "HISTORY",
                division: "ING",
                expend: ["SIMPLE_GOLD", "SIMPLE_SILVER"],
              });
            }
          : null,
        // close: isResult,
        title: !isResult && state?.step ? `${state.step}/4` : "",
        option: (
          <ButtonBase
            onClick={() => {
              history.replace("/");
            }}
          >
            <img src={images.home} className={classes.home} />
          </ButtonBase>
        ),
      }}
    >
      {state?.step && (
        <Flex className={classes.stepWrap}>
          <Flex
            className={classes.step}
            style={{
              width: isResult ? "100%" : `${(100 / 4) * state?.step}%`,
            }}
          />
        </Flex>
      )}
      {isResult && <AppraiseRegistResult />}
      {!isResult && state?.step === 1 && <AppraiseRegist1 />}
      {!isResult && state?.step === 2 && <AppraiseRegist2 />}
      {!isResult && state?.step === 3 && <AppraiseRegist3 />}
      {!isResult && state?.step === 4 && <AppraiseRegist4 />}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  stepWrap: { height: 4, backgroundColor: "#ddd" },
  home: {
    width: 20,
    height: 20,
    marginRight: "16px",
  },
  step: {
    height: "100%",
    backgroundColor: colors.primary,
  },
  desc: {
    fontSize: "16px",
    color: colors.text,
    margin: "50px 30px 30px 30px",
    "& span": {
      fontSize: "20px",
    },
  },
  desc2: {
    fontSize: "12px",
    color: colors.text,
    margin: "30px 30px 30px 30px",
  },

  images: {
    margin: "0px 30px",
    justifyContent: "space-between",
    alignItems: "center",
    "& div": {
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      "& img": {
        width: "70px",
        height: "70px",
      },
      "& span": {
        fontSize: "12px",
        marginTop: "10px",
        color: colors.text,
      },
    },
  },
  button: {
    margin: "10px 30px 0px 30px",
    "&:last-child": {
      backgroundColor: "#fff",
      border: "1px solid " + colors.primary,
      "& span": { color: colors.primary },
    },
  },
  desc3: {
    fontSize: "10px",
    color: colors.gray,
    margin: "10px 30px 30px 30px",
  },
});
