import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import consts from "../../libs/consts";
import { useDispatch } from "react-redux";
//import { actionError } from "../../redux/action/ActionActions";
import { axiosDispatch } from "../../services/network";

import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import ListItem from "./ListItem";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import useInterval from "../../hooks/useInterval";

export default function List({
  settype,
  onpriceChange,
  currentchFunc,
  height,
  minPrice,
  maxPrice,
}) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [tradeBuy, settradeBuy] = useState([]);

  // 전일 종가
  const [lastPrice, setLastPrice] = useState(0);

  const [appState, setAppState] = useState("active");
  const timer = useRef(null);

  useEffect(() => {
    // 앱 백그라운드 상태에서 API 호출 중지를 위한 리스너
    document.addEventListener("message", listener);
    window.addEventListener("message", listener);
    return () => {
      document.removeEventListener("message", listener);
      window.removeEventListener("message", listener);
    };
  }, []);

  const listener = (event) => {
    if (typeof event.data === "string") {
      const { command, data } = JSON.parse(event.data || "{}");
      if (command === "APP_STATE") {
        setAppState(data);
      }
    }
  };

  const fetchPendingSummary = () => {
    APIS.getPendingSummary(settype).then(({ data: res }) => {
      var data = [];
      var dataH = [];

      for (let i in res.data) {
        if (res.data[i].tradeType === null) {
          dataH.push(res.data[i]);
          setLastPrice(res.data[i]["remainGram"]);
        } else {
          data.push(res.data[i]);
        }
      }
      settradeBuy(data);
      currentchFunc(dataH);
    });
  };

  useInterval(fetchPendingSummary, appState === "active" ? 2000 : null);

  function onpriceChangef(price, method) {
    onpriceChange(price, method);
  }

  const contentRef = useRef(null);
  const middleRef = useRef(null);

  const sellList = tradeBuy.filter(
    ({ tradeType, orderPrice }) =>
      tradeType === "SELL" && orderPrice >= +minPrice && orderPrice <= +maxPrice
  );
  const buyList = tradeBuy.filter(
    ({ tradeType, orderPrice }) =>
      tradeType === "BUY" && orderPrice >= +minPrice && orderPrice <= +maxPrice
  );

  const firstLoad = useRef(false);
  useEffect(() => {
    if (!middleRef.current) return;
    if (firstLoad.current) return;
    if (!(sellList.length || buyList.length)) return;

    firstLoad.current = true;
    const getAbsoluteTop = (element) => {
      return window.pageYOffset + element.getBoundingClientRect().top;
    };

    const parentElement = contentRef.current;
    const parentAbsoluteTop = getAbsoluteTop(parentElement);
    const absoluteTop = getAbsoluteTop(middleRef.current);
    const relativeTop = absoluteTop - parentAbsoluteTop;

    contentRef.current.scrollTo(0, relativeTop - height / 2);
  }, [sellList.length, buyList.length]);

  return (
    <Flex
      style={{ display: "block", height }}
      className={classes.root}
      innerRef={contentRef}
    >
      <Flex style={{ minHeight: height / 2, justifyContent: "flex-end" }}>
        {sellList.map((row, i) => {
          return (
            <ListItem
              key={i.toString()}
              method="sell"
              data={row}
              onpriceChangef={onpriceChangef}
              lastPrice={lastPrice}
              maxPrice={maxPrice}
              minPrice={minPrice}
            />
          );
        })}
      </Flex>
      <div ref={middleRef} />
      <Flex style={{ minHeight: height / 2 }}>
        {buyList.map((row, i) => {
          return (
            <ListItem
              key={i.toString()}
              method="buy"
              data={row}
              onpriceChangef={onpriceChangef}
              lastPrice={lastPrice}
              maxPrice={maxPrice}
              minPrice={minPrice}
            />
          );
        })}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    overflowY: "scroll",
    "-webkit-overflow-scrolling": "touch",
    flex: 2,
    alignSelf: "stretch",
  },
});
