import { updateAuth } from "../redux/auth/AuthReducer";
import { toastShow } from "../redux/toast/ToastActions";
import { userRefresh } from "../redux/user/UserActions";
import * as APIS from "./apis";
import { STORAGE_KEY } from "./consts";
import { ROLE } from "./routes";
import { decodeJwt, postMessage } from "./utils";
const { store } = require("../redux/store");

export const signIn = (token) => {
  let accessToken = token || sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);

  if (accessToken) {
    sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);

    // 권한 저장
    APIS.setAuthorization(accessToken);

    const {
      name,
      roles = "NONE",
      sns,
      sub = "",
      version = "",
    } = decodeJwt(accessToken);

    const ids = sub.split(":");

    let role = ROLE["NONE"];

    const splitRoles = roles.split(",");
    for (let name of splitRoles) {
      if (ROLE[name] !== undefined) {
        if (role.LEVEL < ROLE[name]?.LEVEL) {
          role = ROLE[name];
        }
      }
    }

    store.dispatch(
      updateAuth({
        isSigned: true,
        name,
        role,
        sns,
        socialId: +ids[0] || 0,
        userId: +ids[1] || 0,
        version,
        roles: splitRoles,
      })
    );

    store.dispatch(userRefresh);

    /*
     * APP PUSH TOKEN 저장을 위한 로직입니다.
     * 본인인증을 한 유저만 APP PUSH TOKEN 발급 요청합니다.
     *
     * token 파라미터가 없을 경우는 이미 로그인한 회원의 자동로그인 기능이기 때문에
     * 이 떄는 중복 요청이기에 요청하지 않습니다.
     */
    if (token) {
      // && role.LEVEL >= ROLE["ROLE_SELF_VERIFICATION"].LEVEL
      const param = {
        command: "SEND_TOKEN",
        data: {
          authToken: token,
          apiBaseUrl: process.env.REACT_APP_API_URL,
        },
      };
      postMessage(param);
    }

    if (token) {
      // && role.LEVEL >= ROLE["ROLE_SELF_VERIFICATION"].LEVEL
      APIS.putNotificationClear();
    }

    // store.dispatch(toastShow("로그인 되었습니다."));
  }
};

export const signOut = () => {
  // APIS.postSignOut();
  APIS.initAuthorization();
  // history.push(routes.main);
  sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  store.dispatch(updateAuth({ isSigned: false }));
};

export const saveSignupStep = (step) => {
  APIS.postSignUpStep(step).then(({ data: { success, message, data } }) => {
    if (success) {
      // console.log(step, message, data);
      postMessage({
        command: "CUSTOM_LOG",
        param: {
          log: `${step} SUCCESS`,
        },
      });
    }
  });
};
