import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import ClosePopup from "../../layouts/close-popup/ClosePopup";
import colors from "../../libs/colors";

export default function ProductInquiryAdd({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [privacy, setPrivacy] = useState(false);
  return (
    <BasicLayout
      stackTopbar={{
        title: "문의하기",
        border: true,
      }}
    >
      <Text className={classes.title}>문의내용을 입력해 주세요.</Text>
      <Flex className={classes.wrap}>
        <InputBase
          multiline
          placeholder="내용을 입력해 주세요. (1000자 이하)"
        />
      </Flex>
      <CheckboxCircle
        className={classes.cb}
        small
        label="비밀글"
        checked={privacy}
        onCheckedChange={setPrivacy}
      />
      <Flex row className={"row-center"}>
        <Button
          onClick={history.goBack}
          label="취소"
          className={classes.button}
        />

        <Button
          onClick={history.goBack}
          label="작성완료"
          className={classes.button}
        />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  title: {
    fontSize: "12px",
    color: colors.text,
    margin: "13px",
    marginTop: "25px",
  },
  wrap: {
    backgroundColor: "#f1f1f1",
    minHeight: "250px",
    margin: "0px 13px",
    borderRadius: "5px",
    "& textarea": { fontSize: "13px", padding: "20px" },
  },
  button: {
    flex: 1,
    margin: "20px 13px",
    padding: "10px",
    "&:first-child": {
      backgroundColor: "#999999",
      marginRight: "5px",
    },
    "&:last-child": {
      marginLeft: "5px",
    },
  },
  cb: {
    margin: "13px",
  },
});
