import { Snackbar } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastHide } from "../../redux/toast/ToastActions";

export default function Toast({}) {
  const dispatch = useDispatch();
  const { open, message, second } = useSelector((s) => s.toast);

  useEffect(() => {
    if (open) {
      let tm = setTimeout(
        () => {
          dispatch(toastHide());
        },
        second ? second * 1000 : 1000
      );
      return () => {
        clearTimeout(tm);
      };
    }
  }, [open, second]);

  if (!open) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={second * 1000 || 1000}
      message={message}
    />
  );
}
