import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Button from "../button/Button";
import CheckboxCircle from "../checkbox-circle/CheckboxCircle";
import Checkbox from "../checkbox/Checkbox";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function PurityPopup({ onClose, assetType, url }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <ButtonBase
          onClick={onClose}
          style={{ margin: "6px", alignSelf: "flex-end" }}
        >
          <img src={images.close} style={{ width: 16, height: 16 }} />
        </ButtonBase>
        <Text font={fonts.notoSansKRBold} className={classes.title}>
          제품 순도 확인 방법
        </Text>

        <Text className={classes.message}>
          제품 또는 보증서에 표시된 순도를 선택해 주세요. 만약 선택사항에 없는
          제품일 경우 근사치의 항목을 선택해 주십시오.
        </Text>
        <Text className={classes.message}>
          {assetType === "SILVER"
            ? `은제품의 종류, 제작년도, 유통채널 등에 따라 순은 함유량은 약간의 차이가 있을 수 있습니다.`
            : `금제품의 종류, 제작년도, 유통채널 등에 따라 순금 함유량은 약간의
            차이가 있을 수 있습니다.`}
        </Text>

        {assetType === "SILVER" ? (
          <table cellSpacing={0} cellPadding={0} className={classes.table}>
            <tr>
              <th>구분</th>
              <th>999이상</th>
              <th>925</th>
              <th>기타</th>
            </tr>
            <tr>
              <td>순은 함유량</td>
              <td>99.9% 이상</td>
              <td>92.5%</td>
              <td>90% 이하</td>
            </tr>
            <tr>
              <td>표시 방법</td>
              <td>999</td>
              <td>925</td>
              <td>800, 700</td>
            </tr>
          </table>
        ) : (
          <table cellSpacing={0} cellPadding={0} className={classes.table}>
            <tr>
              <th>구분</th>
              <th>24K(999)</th>
              <th>18K(750)</th>
              <th>14K(585)</th>
            </tr>
            <tr>
              <td>순금 함유량</td>
              <td>99.9% 이상</td>
              <td>75.0%</td>
              <td>58.5%</td>
            </tr>
            <tr>
              <td>표시 방법</td>
              <td>999</td>
              <td>750</td>
              <td>585</td>
            </tr>
          </table>
        )}

        <img className={classes.image} src={consts.s3BaseUrl + "/" + url} />

        <Text className={classes.message}>
          <Text font={fonts.notoSansKRMedium}>제품 중량 확인 방법</Text>
          <br />
          ㆍ제품 또는 보증서에 표시된 무게를 입력해 주세요.
          <br />
          ㆍ정확한 무게를 모르실 경우 감정시 무게의 오차가 발생할 수 있습니다.
          <br />ㆍ{assetType === "SILVER" ? "은제품" : "금제품"} 무게 이해{" "}
          <br /> 1돈 → 3.75g
          <br /> 1냥 → 10돈 → 37.5g
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  table: {
    margin: "20px 20px 10px 20px",
    fontSize: "11px",
    color: colors.gray,
    "& th": {
      padding: "4px",
      borderBottom: "1px solid #dddddd",
      borderTop: "1px solid #dddddd",
      backgroundColor: "#f1f1f1",
    },
    "& td": {
      padding: "4px",
      borderBottom: "1px solid #f1f1f1",
      textAlign: "center",
    },
  },
  image: { border: "0.5px solid #ddd", margin: "10px 20px 10px 20px" },
  message: {
    marginTop: "5px",
    "&:before": {
      content: "'ㆍ'",
    },
    fontSize: "12px",
    margin: "0px 20px",
    color: colors.gray,
    "& span": {
      color: "#333",
      fontSize: "14px",
    },
  },
  root: {
    backgroundColor: "rgba(0,0,0,0.8)",
    position: "fixed",
    top: 0,
    bottom: 0,
    zIndex: 25,
    right: 0,
    left: 0,
    justifyContent: "flex-end",
  },
  main: {
    maxHeight: "80vh",
    overflowY: "auto",
    backgroundColor: colors.white,
    borderTopRightRadius: 10,
    padding: "13px",
    paddingBottom: "50px",
    alignSelf: "stretch",
    borderTopLeftRadius: 10,
  },
  title: {
    fontSize: "18px",
    color: colors.text,
    margin: "0px 20px",
  },
  all: {
    margin: "20px 20px 10px 20px",
  },
});
