import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import images from "../../libs/images";

export default function CloseLayout({ onClose, children, title }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root}>
      <Flex className={classes.header} row>
        <Text className={classes.title}>{title}</Text>

        <ButtonBase
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              history.goBack();
            }
          }}
        >
          <img alt="close " src={images.close} className={classes.close} />
        </ButtonBase>
      </Flex>
      <Flex className={classes.content}>{children}</Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  content: {
    overflowY: "auto",
  },
  root: {
    height: "calc(100%-60px)",
    backgroundColor: colors.white,
  },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    borderBottom: "1px solid #eee",
  },
  close: {
    margin: "13px 16px",
    width: "16px",
    height: "16px",
  },
  title: {
    marginLeft: "14px",
    fontSize: "16px",
    color: colors.text,
  },
});
