import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";

import Flex from "../../components/flex/Flex";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";
import check from "../../assets/icons/check.webp";

const TRANS_HISTORIES = {
  DELIVERY: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "DELIVERY", description: "배송중" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
  VISIT: [
    { status: "PAYMENT_COMPLETED", description: "결제완료" },
    { status: "PREPARING", description: "상품준비중" },
    { status: "ARRIVED", description: "대리점도착" },
    { status: "RECEIVED", description: "상품수령" },
    { status: "CONFIRMED", description: "구매확정" },
  ],
};

export default function DeliveryState({
  status,
  shipmentStatus,
  updatedAt,
  transSummary,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  // APPROVAL, // 결제완료
  // MAKE, // 제작중
  // SHIPMENT, // 출고중
  // DELIVERY, // 출고완료
  // TAKEOUT, // 인출완료
  // CONFIRM, // 인출확정
  // CANCEL, // 결제취소
  const transHistory = TRANS_HISTORIES[status];

  let currentStep = 0;

  switch (status) {
    case "APPROVAL":
      switch (shipmentStatus) {
        case "MAKE":
          currentStep = 2;
          break;
        case "SHIPMENT":
          currentStep = 3;
          break;
        default:
          currentStep = 1;
      }

      break;

    case "TAKEOUT":
      currentStep = 4;
      break;
    case "CONFIRM":
      currentStep = 5;
      break;
  }

  const STEPS = [
    { step: 1, label: "결제완료" },
    { step: 2, label: "제작중" },
    { step: 3, label: "배송중" },
    { step: 4, label: "인출완료" },
    { step: 5, label: "인출확정" },
  ];
  // console.log(transSummary);
  return (
    <InnerBox title="주문 상품 제작 과정 안내">
      {/* <Flex className={classes.navigator}>
        <Flex className={classes.divider} />
        <Flex className={classes.navigatorBalls}>
          {STEPS.map(({ step, label }) => {
            let backgroundColor = "#EDEDED";
            if (currentStep === step) {
              backgroundColor = colors.primary;
            } else if (currentStep > step) {
              backgroundColor = "white";
            }

            return (
              <div
                key={step}
                style={{ backgroundColor }}
                className={classes.ball}
              />
            );
          })}
        </Flex>
      </Flex>
      <Flex className={classes.labels}>
        {STEPS.map(({ step, label }) => {
          return (
            <Flex key={step} row className={classes.labelSection}>
              <Text
                key={step}
                font={
                  step === currentStep
                    ? fonts.notoSansKRMedium
                    : fonts.notoSansKRRegular
                }
                style={{ ...(step === currentStep && { color: "#333" }) }}
                className={classes.label}>
                {label}
              </Text>
              {currentStep === step && (
                <Text className={classes.deliveryLabel1}>
                  {formatTime(updatedAt, "YYYY-MM-DD HH:mm")}
                </Text>
              )}
            </Flex>
          );
        })}
      </Flex> */}
      {transHistory.map(({ description, status: transStatus }) => {
        const reversed = [...transSummary].reverse();
        // 가장 최근 history의 index === 0
        const historyIndex = reversed.findIndex(
          ({ status }) => status === transStatus
        );

        const { createdAt } = reversed[historyIndex] || {};
        const isDone = historyIndex >= 0;
        const isLatest = historyIndex === 0;

        return (
          <ProcessRow
            className={[isDone && "done", isLatest && "latest"]}
            key={description}
          >
            <span className="description">{description}</span>
            <span className="created-at">
              {isDone ? formatTime(createdAt, "YYYY-MM-DD HH:mm") : ""}
            </span>
          </ProcessRow>
        );
      })}
    </InnerBox>
  );
}

const useStyle = makeStyles({
  navigator: {
    width: "30px",
    position: "relative",
  },
  divider: {
    flex: 1,
    // alignSelf: "center",
    marginLeft: "22%",
    width: "10%",
    backgroundColor: "#ddd",
  },
  root: { flex: 1, flexDirection: "column", minHeight: "80px" },
  navigatorBalls: {
    zIndex: 2,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: "absolute",
    // alignItems: "center",
    justifyContent: "space-between",
  },
  ball: {
    width: "16px",
    height: "16px",
    backgroundColor: "#ddd",
    borderRadius: "100px",
    border: "2px solid #DFDFDF",
  },
  labels: {
    flex: 1,
  },
  label: {
    fontSize: "16px",
    color: "#999",
    textAlign: "left",
  },
  labelSection: { justifyContent: "space-between" },
  deliveryLabel1: {
    fontSize: "12px",
    color: "#666",
  },
});

const InnerBox = styled(Flex)`
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0 12px;
  padding-bottom: 12px;

  &:before {
    content: "${({ title }) => title}";
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.28px;
    color: #333333;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ProcessRow = styled(Flex)`
  margin-top: 10px;
  flex-direction: row;
  align-items: center;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border: 2px solid #dfdfdf;
    border-radius: 8px;
    margin-right: 4px;
    background: #ededed;
  }
  .description {
    flex: 1;
    font-size: 14px;
    color: #999999;
    letter-spacing: -0.28px;
  }
  .created-at {
    font-family: ${fonts.spoqa};
    font-size: 13px;
    letter-spacing: 0.26px;
    color: #999999;
  }

  &.done {
    &:before {
      background: none;
      background-image: url(${check});
      background-size: 8px;
      background-position: center;
      background-repeat: no-repeat;
    }
    .description {
      color: #444444;
    }

    .created-at {
      color: #999999;
    }
  }

  &.latest {
    &:before {
      background: #ff6e0e;
    }
    .description {
      color: #ff6e0e;
      font-weight: bold;
    }
    .created-at {
      color: #444444;
    }
  }
`;
