import { makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import Flex from "../flex/Flex";
export default function Dots({ color, className, index, length = 0 }) {
  const classes = useStyle();

  return (
    <Flex row className={`${classes.root} ${className}`}>
      {[...new Array(length)].map((x, i) => {
        return (
          <span
            key={i.toString()}
            style={{ ...(index === i && color && { backgroundColor: color }) }}
            className={`${classes.dot} ${index === i && classes.dotS}`}
          />
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    alignSelf: "center",
  },
  dot: {
    backgroundColor: "#aaaaaa",
    borderRadius: 100,
    width: 6,
    marginRight: 10,
    height: 6,
  },
  dotS: { backgroundColor: colors.white },
});
