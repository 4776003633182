import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";

export default function Information({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root}>
      <Flex row className="row-between">
        <Text className={classes.label1}>Rolex</Text>
        <Text className={classes.label1}>NO.DP20210220001</Text>
      </Flex>
      <Text className={classes.label2} font={fonts.notoSansKRMedium}>
        Datejust
      </Text>
      <Text font={fonts.notoSansKRMedium} className={classes.label4}>
        5,892,297원
      </Text>
      <Text className={classes.label3}>
        구매일 <Text>2002</Text> | 상태
        <Text>좋음</Text>
      </Text>

      <Flex row className={classes.marker}>
        <img className="icon" src={images.protect} />
        <Text className={classes.label4}>
          한국금거래소에서 감정평가를 통해 판매되는 상품입니다.
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  marker: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 23,
    padding: "10px",
    border: "1px solid #eee",
    borderRadius: "5px",
    alignSelf: "stretch",
    "& img": {
      marginRight: "10px",
    },
  },
  root: {
    borderBottom: "1px solid #eee",
    padding: "13px",
    paddingBottom: "20px",
  },
  label1: {
    color: colors.gray,
    fontSize: 12,
  },
  label2: {
    color: "#000",
    fontSize: 16,
  },
  label4: {
    color: "#000",
    fontSize: 12,
  },
  label3: {
    lineHeight: 1,
    marginTop: "10px",
    alignSelf: "stretch",
    color: "#666",
    fontSize: "14px",
    "& span": {
      color: "#000",
    },
  },
});
