import React from "react";
import styled from "styled-components";
import SimpleAccordion from "../../../components/accordion/SimpleAccordion";
import Flex from "../../../components/flex/Flex";
import arrowDown from "../../../assets/images/arrow_down.webp";
import { parsePhoneNumber } from "../../../libs/utils";

const DeliveryInfo = ({
  receiver,
  addressName,
  mobile,
  phone,
  postCode,
  address,
  addressDetail,
  memo,
  ...rest
}) => {
  return (
    <AccordionContainer
      title={<AccordionHeader>배송지</AccordionHeader>}
      expendIcon={<img src={arrowDown} width={44} />}
      expended={true}
      {...rest}
    >
      <ContentWrap>
        <NameWrap>
          {receiver} / {addressName}
        </NameWrap>
        <Flex row>
          {!!mobile && <PhoneWrap>{parsePhoneNumber(mobile)}</PhoneWrap>}
          {mobile && phone && <RowSeparator />}
          {!!phone && <PhoneWrap>{parsePhoneNumber(phone)}</PhoneWrap>}
        </Flex>
        <AddressWrap>
          ({postCode}) {address} {addressDetail}
        </AddressWrap>
        {!!memo && <MemoWrap>{memo}</MemoWrap>}
      </ContentWrap>
    </AccordionContainer>
  );
};

const AccordionContainer = styled(SimpleAccordion)`
  border: 1px solid #eaeaea;
  border-radius: 8px;
`;

const AccordionHeader = styled(Flex)`
  margin: 16px;
  font-weight: bold;
  color: #333333;
  font-size: 17px;
  letter-spacing: -0.34px;
`;
const ContentWrap = styled(Flex)`
  border-top: 1px solid #eaeaea;
  margin: 0 16px;
  padding: 16px 0;
`;

const NameWrap = styled.span`
  font-weight: bold;
  font-size: 15px;
  font-color: #333333;
  letter-spacing: -0.3px;
`;

const PhoneWrap = styled.span`
  margin-top: 8px;
  font-size: 14px;
  color: #777777;
  font-family: "Spoqa Han Sans Neo", "sans-serif";
`;
const AddressWrap = styled.span`
  margin-top: 16px;
  font-size: 14px;
  color: #777777;
  line-height: 23px;
  letter-spacing: -0.28px;
`;

const RowSeparator = styled.div`
  position: relative;
  width: 17px;
  &:after {
    content: "";
    position: absolute;
    border-right: 1px solid #999999;
    height: 11px;
    left: 8px;
    bottom: 2px;
  }
`;

const MemoWrap = styled.div`
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 400;
  color: #444;
  margin-top: 8px;
`;

export default DeliveryInfo;
