import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { messageShow } from "../../redux/message/MessageActions";

export default function Password({ onClose, onClick }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState('');

  return (
    <Flex className={classes.root}>
      <ButtonBase onClick={onClose} className={classes.cancel}>
        취소
      </ButtonBase>

      <Text font={fonts.notoSansKRMedium} className={classes.label}>
        보안 비밀번호
      </Text>

      <input type="number" value={password} placeholder="비밀번호 입력" pattern="\d*" inputMode="numeric" className={classes.input} 
             onChange = {(e) => {
              var values = e.target.value;
              if(values.length < 7) {
                setPassword(e.target.value);
              }
            }}
      />
      <Button
        onClick={() => onClick(password)}
        className={classes.bt}
        label="결제하기"
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 30,
    backgroundColor: colors.background,
  },
  cancel: {
    alignSelf: "flex-end",
    padding: "20px 16px",
    color: "#333",
    fontSize: 16,
  },
  label: { fontSize: 20, color: "#333", margin: "70px 30px 30px 30px" },
  input: {
    borderBottom: "2px solid black",
    fontSize: 16,
    color: "#333",
    margin: "0px 30px",
    padding: "10px",
    lineHeight: 1,
    border: 'none',
    background: 'none',
    WebkitTextSecurity: "disc"
  },
  bt: { alignSelf: "stretch", padding: "10px", margin: "20px 30px 0px 30px" },
});
