import { makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import { formatTime } from "../../libs/utils";
import { E_001, CALENDAR_ALERT } from "../../libs/messages";
import { messageShow } from "../../redux/message/MessageActions";
export default function DateSelector({
  value,
  onChange,
  className,
  placeholder,
  business,
  min = "",
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex row className={classes.item + " " + className}>
      <input
        style={{
          fontFamily: fonts.notoSansKRRegular,
          color: "#000",
          background: "none",
        }}
        value={value}
        min={min || formatTime()}
        onChange={(e) => {
          if (
            moment(e.target.value, "YYYY-MM-DD").isBefore(
              moment().set({
                hours: 0,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
              })
            )
          ) {
            dispatch(toastShow("지난 날짜는 선택 불가능합니다."));
          }
          const dateString = formatTime(e.target.value, "YYYYMMDD");
          if (
            business &&
            dateString >= CALENDAR_ALERT[business].START &&
            dateString <= CALENDAR_ALERT[business].END
          ) {
            dispatch(messageShow(CALENDAR_ALERT[business].MESSAGE));
          } else if (new Date(e.target.value).getDay() === 0) {
            dispatch(messageShow(E_001));
          }

          onChange(e.target.value);
        }}
        placeholder={placeholder}
        className={classes.input}
        type="date"
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    justifyContent: "center",
    boxSizing: "border-box",
    alignItems: "center",
  },
  item: {
    alignSelf: "stretch",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "10px 6px 10px 10px",
  },
  icon: {
    width: "20px",
    height: "20px",
  },
  input: {
    border: "none",
    color: colors.text,
    width: "130px",
    fontFamily: fonts.notoSansKRLight,
    boxSizing: "border-box",
  },
});
