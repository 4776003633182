import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ButtonChecked from "../../components/button-checked/ButtonChecked";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { postMessage } from "../../libs/utils";
import { saveSignupStep } from "../../libs/sign";

export default function SelfVerification({}) {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state;
  const { purpose = "SIGNUP" } = state || {};

  const handleClickVerification = () => {
    APIS.getSelfVerificationRequestId(purpose).then(
      ({ data: { requestId } }) => {
        window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/user/verification/self/start?requestId=${requestId}`;
        postMessage({
          command: "SET_HEADER",
          param: { visible: true, title: "본인인증" },
        });
      }
    );
  };

  useEffect(() => {
    if (purpose === "SIGNUP") saveSignupStep("SELF_VERIFICATION");
  }, []);

  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "본인인증" }}>
      {(() => {
        switch (purpose) {
          case "UPDATE_SECURE_PASSWORD":
            return (
              <Text className={classes.label}>
                결제 비밀번호 변경을 위해
                <br />
                <Text font={fonts.notoSansKRBold}>본인 인증</Text>이 필요합니다.
              </Text>
            );

          case "UPDATE_PHONENUMBER":
            return (
              <Text className={classes.label}>
                휴대폰 번호 변경을 위해
                <br />
                <Text font={fonts.notoSansKRBold}>본인 인증</Text>이 필요합니다.
              </Text>
            );

          default:
            return (
              <Text className={classes.label}>
                금방금방은
                <br />
                안전한 거래를 위해
                <br />
                <Text font={fonts.notoSansKRBold}>본인 인증</Text>이 필요합니다.
              </Text>
            );
        }
      })()}

      <Button
        onClick={handleClickVerification}
        className={classes.button}
        label="본인 인증"
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: 24,
    margin: "50px 30px 30px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    // margin: "24px 30px 0px 30px",
    position: "absolute",
    bottom: 50,
    width: "calc(100% - 60px)",
    left: 30,
  },
});
