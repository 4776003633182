import React from "react";
import styled from "styled-components";
import Flex from "../flex/Flex";
import images from "../../libs/images";
import { numberWithCommas } from "../../libs/utils";

const NumberCounter = ({ value, onChange }) => {
  const handleChangeInput = (e) => {
    if (onChange) {
      const value = e.target.value.replace(/,/gi, "");
      const regexp = /^[0-9]*$/;
      if (!regexp.test(value)) {
        return false;
      }

      onChange(value);
    }
  };

  return (
    <Container>
      <CommandButton
        onClick={() => {
          if (value > 1) {
            onChange && onChange(value - 1);
          }
        }}
      >
        <img src={images.minus} />
      </CommandButton>
      <Flex
        style={{
          border: "1px solid #d5d5d5",
          borderTop: "none",
          borderBottom: "none",
        }}
      >
        <NumberInput
          type="text"
          inputMode="decimal"
          value={value ? numberWithCommas(value) : value}
          onChange={handleChangeInput}
        />
      </Flex>
      <CommandButton
        onClick={() => {
          onChange && onChange(value + 1);
        }}
      >
        <img src={images.plus} />
      </CommandButton>
    </Container>
  );
};

export default NumberCounter;

const Container = styled(Flex)`
  flex-direction: row;
  border: 1px solid #d5d5d5;
  width: 136px;
  height: 40px;
  border-radius: 8px;
`;

const CommandButton = styled(Flex)`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  img {
    width: 12px;
  }
`;

const NumberInput = styled.input`
  width: 56px;
  height: 40px;
  text-align: center;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
`;
