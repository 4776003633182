import { makeStyles } from "@material-ui/core";
import { RowingSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { formatTime } from "../../libs/utils";
import NumberFormat from "react-number-format";

export default function ListItem({ rows }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const parseStatus =
    rows.status === "COMPLETE"
      ? "완료"
      : rows.status === "CANCEL"
      ? "실패"
      : rows.status === "FAILED"
      ? "실패"
      : "처리중";
  const fontColor =
    rows.status === "CANCEL" || rows.status === "FAILED"
      ? "red"
      : rows.status === "COMPLETE"
      ? "black"
      : "blue";

  const parseValue = rows?.failedReason?.includes(":") || false ? rows.failedReason.substring(rows.failedReason.indexOf(":")+1) : rows.failedReason;
  return (
    <Flex className={classes.root}>
      <Text className={classes.date}>
        {formatTime(new Date(rows.requestDate), "YYYY.MM.DD HH:mm:ss")}
        {/* <span style={{ color: fontColor, marginLeft : "10px" }}>{parseStatus}</span> */}
      </Text>
      <Flex className={classes.content} row>
        <Text className={classes.albel}>
          {rows.depositOrWithdraw === "WITHDRAW" ? "출금" : "입금"}&nbsp;&nbsp;-
          <span style={{ color: fontColor, marginLeft: "10px" }}>
            {parseStatus}
          </span><br/>
        <Text className={classes.reason}>
          {((rows.status === "FAILED" ||
            rows.status === "CANCEL") && rows.failedReason) && (
              <span style={{ fontSize: "14px",}}>
                ( {parseValue || "-"} )
              </span>
            )}
        </Text>
        </Text>

        <Text font={fonts.notoSansKRMedium} className={classes.price}>
          <NumberFormat
            value={rows.requestAmount}
            displayType={"text"}
            thousandSeparator={true}
          />
          원
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  date: {
    color: colors.gray,
    fontSize: "11px",
  },
  root: {
    padding: "19px 15px 14px 15px",
    boxSizing: "border-box",
    margin: "0px 15px",
    borderBottom: "1px solid #eeeeee",
  },
  albel: {
    marginTop: "3px",
    color: colors.text,
    fontSize: "14px",
  },
  content: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  price: {
    fontSize: "16px",
    color: colors.text,
  },
  reason : {
    width : "120px", marginRight: "25px", lineHeight: "10px"
  }
});
