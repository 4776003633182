import { InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import ConfirmPopup from "./ConfirmPopup";

export default function ExitVerify({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [popup, setPopup] = useState(false);
  return (
    <BasicLayout stackTopbar={{ title: "탈퇴하기" }} backgroundGray>
      <Text font={fonts.notoSansKRMedium} className={classes.title}>
        본인인증
      </Text>
      <Text className={classes.message}>
        회원탈퇴를 진행하기 위해서는 본인인증이 필요합니다.
      </Text>
      <Text className={classes.label}>연락처 본인 인증</Text>
      <Button label="인증번호 SMS 전송 요청" className={classes.button1} />

      <InputBase
        style={{
          marginTop: "20px",
        }}
        placeholder="등록된 휴대폰번호로 전송된 인증번호 입력"
        className={classes.input}
      />
      <Text className={classes.label}>로그인 비밀번호</Text>

      <InputBase className={classes.input} />
      <Button
        onClick={() => {
          setPopup(true);
        }}
        label="계속하기"
        className={classes.button2}
      />
      {popup && (
        <ConfirmPopup
          onClose={() => {
            setPopup(false);
          }}
        />
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  title: {
    color: colors.text,
    fontSize: "20px",
    margin: "50px 30px 0px 30px",
  },
  message: {
    fontSize: "14px",
    color: colors.gray,
    margin: "10px 30px 0px 30px",
  },
  label: {
    margin: "30px 30px 10px 30px",
    fontSize: "14px",
    color: colors.text,
  },
  button2: {
    margin: "20px 30px 30px 30px",
  },
  button1: {
    margin: "0px 30px",
    backgroundColor: colors.text,
  },
  input: {
    margin: "0px 30px",
    padding: "6px",
    borderBottom: "2px solid black",
  },
});
