import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import fonts from "../../libs/fonts";
import BasicLayout from "../../layouts/basic/BasicLayout";
import consts from "../../libs/consts";
import { getCurrentMyPosition, objToQueryStr } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import ShopItemSelect from "../../components/shop-item-select/ShopItemSelect";
import { useHistory } from "react-router";
import { messageError } from "../../redux/message/MessageActions";
import axios from "axios";
import * as APIS from "../../libs/apis";
import InputShadow from "../../components/input-shadow/InputShadow";
import SearchTopbar from "../../components/search-topbar/SearchTopbar";
import images from "../../libs/images";
import { getDistanceFromLatLonInKm } from "../../libs/utils";
import queryString from "query-string";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import routes from "../../libs/routes";
import { escapeRegExp } from "lodash";

const AuthFindShop = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const { recommandShop } = useSelector((s) => s.signUp);

  const [shops, setShops] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(loadingStart);
    init();
  }, []);

  const init = async () => {
    // 현재 위치 불러오기
    const position = await getCurrentMyPosition()
      .then((position) => {
        return position;
      })
      .catch((position) => {
        return position;
      });

    const shopSearchIf = history.location.state?.from ? "" : "RECOMMEND_SHOP";
    const { lat, lng } = position;

    let func =
      history.location.state?.from === routes.main
        ? APIS.getNearbyShop
        : APIS.getAppraisalShop;

    func(objToQueryStr({ shopSearchIf }))
      .then(({ data: { success, data } }) => {
        if (success) {
          let sortedData = data;

          if (lat && lng) {
            // 위치 허용인 경우
            sortedData = data.map((item) => {
              const { latitude, longitude } = item;
              let distance = 0;
              if (latitude && longitude) {
                distance = getDistanceFromLatLonInKm(
                  lat,
                  lng,
                  latitude,
                  longitude
                ).toFixed(2);
              }
              return { ...item, distance };
            });

            if (shopSearchIf === "RECOMMEND_SHOP") {
              // visibleOrder와 distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => {
                if (a.visibleOrder !== null && b.visibleOrder !== null) {
                  return a.visibleOrder - b.visibleOrder;
                } else if (a.visibleOrder !== null) {
                  return -1;
                } else if (b.visibleOrder !== null) {
                  return 1;
                } else {
                  return a.distance - b.distance;
                }
              });
            } else {
              // distance에 따라 정렬
              sortedData = sortedData.sort((a, b) => a.distance - b.distance);
            }
          }
          // 위치 허용 X: 서버에서 오는 순서대로 적용
          setShops(sortedData);
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleClickSearch = () => {
    setSearchKeyword(keyword);
  };

  const handleClickShop = (recommandShop) => {
    if (history.location.state?.from === routes.appraiseDirect) {
      //현장감정 매장 찾기에서 클릭하면
      return history.replace(routes.appraiseDirect, {
        shop: recommandShop,
      });
    } else if (history.location.state?.from === routes.exchange) {
      //감정 주문 직접 내방 접수 예약
      return history.replace(routes.exchange, {
        ...history.location.state.state,
        shop: recommandShop,
      });
    } else if (history.location.state?.from === routes.order) {
      //실물인출 주문에서 클릭
      return history.replace(routes.order, {
        ...history.location.state,
        shop: recommandShop,
      });
    } else if (history.location.state?.from === routes.appraiseReturn) {
      //감정교환 반송
      return history.replace(routes.appraiseReturn, {
        ...history.location.state.state,
        shop: recommandShop,
      });
    } else if (history.location.state?.from === routes.main) {
      return false;
    }
    dispatch(updateSignUp({ recommandShop }));
    history.goBack();
  };

  const mapDataToComponent = (shops) => {
    return shops.map((shop, i) => {
      const { id: prevId } = recommandShop;
      const { id, name, address, phoneNumber, distance, shopType } = shop;

      return (
        <ShopItemSelect
          key={i.toString()}
          list
          shopName={name}
          address={address}
          shopPhone={phoneNumber}
          distance={distance}
          onCheckedChange={() => handleClickShop(shop)}
          onClick={() => handleClickShop(shop)}
          checked={prevId === id}
          shopType={shopType}
        />
      );
    });
  };

  function ch2pattern(ch) {
    const offset = 44032; /* '가'의 코드 */
    // 한국어 음절
    if (/[가-힣]/.test(ch)) {
      const chCode = ch.charCodeAt(0) - offset;
      // 종성이 있으면 문자 그대로를 찾는다.
      if (chCode % 28 > 0) {
        return ch;
      }
      const begin = Math.floor(chCode / 28) * 28 + offset;
      const end = begin + 27;
      return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 한글 자음
    if (/[ㄱ-ㅎ]/.test(ch)) {
      const con2syl = {
        ㄱ: "가".charCodeAt(0),
        ㄲ: "까".charCodeAt(0),
        ㄴ: "나".charCodeAt(0),
        ㄷ: "다".charCodeAt(0),
        ㄸ: "따".charCodeAt(0),
        ㄹ: "라".charCodeAt(0),
        ㅁ: "마".charCodeAt(0),
        ㅂ: "바".charCodeAt(0),
        ㅃ: "빠".charCodeAt(0),
        ㅅ: "사".charCodeAt(0),
      };
      const begin =
        con2syl[ch] ||
        (ch.charCodeAt(0) - 12613) /* 'ㅅ'의 코드 */ * 588 + con2syl["ㅅ"];
      const end = begin + 587;
      return `[${ch}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }
    // 그 외엔 그대로 내보냄
    // escapeRegExp는 lodash에서 가져옴
    return escapeRegExp(ch);
  }
  function createFuzzyMatcher(input) {
    const pattern = input.split("").map(ch2pattern).join(".*?");
    return new RegExp(pattern);
  }

  const ShopList = useMemo(() => {
    const matchShops = shops.filter(({ name, address }) => {
      return createFuzzyMatcher(keyword).test(name);
    });
    return mapDataToComponent(matchShops);
  }, [shops, keyword]);
  return (
    <BasicLayout white stackTopbar={{ title: "매장 찾기", border: true }}>
      <Flex style={{ position: "relative", marginTop: 16 }}>
        <InputShadow
          value={keyword}
          onChange={setKeyword}
          className={classes.input}
          placeholder="매장명 검색"
        />
        <ButtonBase onClick={handleClickSearch} className={classes.textButton2}>
          <img src={images.search} />
        </ButtonBase>
      </Flex>

      {/* <Text className={classes.label} font={fonts.notoSansKRMedium}>
        가까운 매장
      </Text> */}
      {ShopList}
    </BasicLayout>
  );
};
const useStyle = makeStyles({
  root: {
    height: "100%",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  label: {
    fontSize: "14px",
    color: "#333",
    margin: "0px 13px",
    marginTop: "16px",
    padding: "13px 0px",
    alignSelf: "stretxh",
    borderBottom: "1px solid #eee",
  },
  input: {
    margin: "0px 20px",
  },
  textButton2: {
    position: "absolute",
    width: 50,
    height: 50,
    top: "calc(50% - 25px)",
    right: 20,
    "& img": {
      width: "20px",
      height: "20px",
      objectFit: "contain",
    },
  },
});
export default AuthFindShop;
