import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { numberWithCommas } from "../../libs/utils";

export default function CanOrder({ acceptableGold = 0, acceptableSilver = 0 }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  return (
    <Flex className={classes.root} row>
      <Flex className={classes.wrap}>
        <Text className={classes.label}>주문가능 금(GOLD)</Text>
        <Text font={fonts.notoSansKRBold} className={classes.value}>
          {numberWithCommas(acceptableGold)}g
        </Text>
      </Flex>
      <Flex className={classes.wrap}>
        <Text className={classes.label}>주문가능 은(SILVER)</Text>
        <Text font={fonts.notoSansKRBold} className={classes.value}>
          {numberWithCommas(acceptableSilver)}g
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    margin: "0px 16px",
    padding: "16px 0px",
    borderBottom: "1px solid #eee",
    alignItems: "center",
  },
  wrap: {
    backgroundColor: "#f1f1f1",
    flex: 1,
    padding: "9px 15px",
    "&:first-child": {
      marginRight: "10px",
    },
  },
  label: {
    color: "#666",
    fontSize: "13px",
  },
  value: {
    color: colors.text,
    fontSize: "18px",
  },
});
