import React from "react";
import styled from "styled-components";
import upIcon from "../../assets/images/up-circle.webp";

function TopButton() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const ToTop = () => {
    return <img src={upIcon} alt="상단이동" />;
  };

  return (
    <>
      <ToTopButton onClick={handleClick}>
        <ToTop />
      </ToTopButton>
    </>
  );
}

export default TopButton;

const ToTopButton = styled.button`
  position: fixed;
  bottom: 64px;
  right: 0;
  cursor: pointer;
  width: 66px;
  height: auto;

  z-index: 200;
  background-color: initial;
  border: none;
  outline: none;
  cursor: pointer;
  img {
    width: 100%;
  }
`;
