import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import Span from "../../../../components/text/Span";
import fonts from "../../../../libs/fonts";
import arrow_down from "../../../../assets/images/arrow_down.webp";
import next_icon from "../../../../assets/images/next_icon.webp";
import skip_icon from "../../../../assets/images/skip_icon.webp";
import BottomModal from "../../../../components/modal/BottomModal";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import SearchModal from "./SearchModal";
import { useHistory } from "react-router";
import * as APIS from "../../../../libs/apis";
import { formatTime } from "../../../../libs/utils";
import BuyItem from "./trade-item/BuyItem";
import SellItem from "./trade-item/SellItem";
import DepositItem from "./trade-item/DepositItem";
import WithdrawItem from "./trade-item/WithdrawItem";
import GoodsItem from "./trade-item/GoodsItem";
import AppraisalItem from "./trade-item/AppraisalItem";
import { useDispatch } from "react-redux";
import {
  loadingEnd,
  loadingStart,
} from "../../../../redux/loading/LoadingActions";
import TradeDetail from "./trade-detail/TradeDetail";
import SafeTradeItem from "./trade-item/SafeTradeItem";
import EventItem from "./trade-item/EventItem";
import CouponItem from "./trade-item/CouponItem";
import ReceiveAssets from "./trade-item/ReceiveAssets";
import SendAssets from "./trade-item/SendAssets";
import StorageFeeItem from "./trade-item/StorageFeeItem";

const TRADE_TYPE = [
  "BUY",
  "SELL",
  "GOODS",
  "APPRAISAL",
  "DEPOSIT",
  "WITHDRAW",
  "COUPON",
  "SAFE_TRADE",
  "EVENT",
  "SEND_ASSET",
  "RECEIVE_ASSET",
  "STORAGE_FEE",
];
const ASSET_TYPE = ["KRW", "POINT", "GOLD", "SILVER"];

const PERIOD = [
  { label: "오늘", value: "TODAY" },
  { label: "1주일", value: "WEEK" },
  { label: "1개월", value: "MONTH" },
  { label: "3개월", value: "3MONTH" },
  { label: "직접입력", value: "CUSTOM" },
];

const HistoryComplete = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const {
    searchParam = {
      period: "3MONTH",
      tradeType: [...TRADE_TYPE],
      assetType: [...ASSET_TYPE],
      sort: "DESC",
      from: new Date(),
      to: new Date(),
      page: 0,
    },
  } = locationState;
  const [searchModalVisible, setSearchModalVisible] = useState(false);
  const [paging, setPaging] = useState({ current: 0, total: 0 });
  const [items, setItems] = useState([]);
  const [tradeDetail, setTradeDetail] = useState({ visible: false });

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleChangeSearchParam = (param) => {
    fetchSearch(param);
    setSearchModalVisible(false);
  };

  useEffect(() => {
    fetchSearch(searchParam);
  }, []);

  const fetchSearch = (searchParam) => {
    let fromDt = formatTime(searchParam.from);
    let toDt = formatTime(searchParam.to);

    const date = new Date();
    switch (searchParam.period) {
      case "TODAY":
        fromDt = formatTime();
        toDt = formatTime();
        break;
      case "WEEK":
        date.setDate(date.getDate() - 7);
        fromDt = formatTime(date);
        toDt = formatTime();
        break;
      case "MONTH":
        date.setMonth(date.getMonth() - 1);
        fromDt = formatTime(date);
        toDt = formatTime();
        break;
      case "3MONTH":
        date.setMonth(date.getMonth() - 3);
        fromDt = formatTime(date);
        toDt = formatTime();
        break;
    }

    let tradeTypeList = [...searchParam.tradeType];
    if (tradeTypeList.includes("SAFE_TRADE")) {
      tradeTypeList = tradeTypeList.filter((type) => type !== "SAFE_TRADE");
      tradeTypeList = [
        ...tradeTypeList,
        "SAFE_TRADE_SELL",
        "SAFE_TRADE_BUY",
        "SAFE_TRADE_AUTH_PRICE",
        "SAFE_TRADE_SELL_PENALTY",
        "SAFE_TRADE_BUY_PENALTY",
      ];
    }
    const param = {
      assetTypeList: [...searchParam.assetType],
      tradeTypeList,
      fromDt,
      toDt,
      page: searchParam.page,
      size: 30,
      sortType: searchParam.sort,
    };

    dispatch(loadingStart);
    APIS.getTradeHistoryComplete(param)
      .then(({ data: { success, data, message } }) => {
        if (success) {
          handleChangeLocationState({ searchParam });
          setPaging({ current: data.number + 1, total: data.totalPages });
          setItems(data.content);
          window.scrollTo(0, 0);
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const handleChangePage = (page) => {
    fetchSearch({ ...searchParam, page: page - 1 });
  };

  const periodLabel =
    searchParam.period === "CUSTOM"
      ? "기간 선택"
      : PERIOD.find(({ value }) => value === searchParam.period).label;

  const tradeLabel =
    searchParam.tradeType.length === TRADE_TYPE.length
      ? "유형 전체"
      : "유형 선택";

  const assetLabel =
    searchParam.assetType.length === ASSET_TYPE.length
      ? "자산 전체"
      : "자산 선택";

  const sortLabel = searchParam.sort === "DESC" ? "최신순" : "과거순";

  const [fromLabel, toLabel] = useMemo(() => {
    let from = formatTime(searchParam.from, "YYYY.MM.DD");
    let to = formatTime(searchParam.to, "YYYY.MM.DD");

    const date = new Date();
    switch (searchParam.period) {
      case "WEEK":
        date.setDate(date.getDate() - 7);
        from = formatTime(date, "YYYY.MM.DD");
        break;
      case "MONTH":
        date.setMonth(date.getMonth() - 1);
        from = formatTime(date, "YYYY.MM.DD");
        break;
      case "3MONTH":
        date.setMonth(date.getMonth() - 3);
        from = formatTime(date, "YYYY.MM.DD");
        break;
    }

    return [from, to];
  }, [searchParam.period, searchParam.from]);

  return (
    <Container>
      <SearchWrap
        onClick={() => {
          setSearchModalVisible(true);
        }}
      >
        <Span font={fonts.notoSansKRBold} size={15}>
          {periodLabel} • {tradeLabel} • {assetLabel} • {sortLabel}
        </Span>
        <img src={arrow_down} width={44} />
      </SearchWrap>
      <PeriodWrap>
        {fromLabel}~{toLabel}
      </PeriodWrap>
      <ContentWrap>
        {items.map((item, i) => {
          let Component = <></>;
          if (item.tradeTypeKor === "매수") {
            Component = <BuyItem {...item} />;
          }
          if (item.tradeTypeKor === "매도") {
            Component = <SellItem {...item} />;
          }

          if (item.tradeTypeKor === "입금") {
            Component = <DepositItem {...item} />;
          }

          if (item.tradeTypeKor === "출금") {
            Component = <WithdrawItem {...item} />;
          }

          if (item.tradeTypeKor === "실물인출") {
            Component = <GoodsItem {...item} />;
          }

          if (item.tradeTypeKor === "감정평가") {
            Component = <AppraisalItem {...item} />;
          }

          if (item.tradeTypeKor.includes("안심직거래")) {
            Component = <SafeTradeItem {...item} />;
          }

          if (item.tradeTypeKor === "이벤트") {
            Component = <EventItem {...item} />;
          }

          if (item.tradeTypeKor === "쿠폰") {
            Component = <CouponItem {...item} />;
          }

          if (item.tradeTypeKor === "자산 받음") {
            Component = <ReceiveAssets {...item} />;
          }

          if (item.tradeTypeKor === "자산 보냄") {
            Component = <SendAssets {...item} />;
          }
          if (item.tradeTypeKor === "보관료") {
            Component = <StorageFeeItem {...item} />;
          }
          return (
            <div
              key={i.toString()}
              onClick={() => {
                if (item.tradeTypeKor !== "보관료") {
                  setTradeDetail({ visible: true, item });
                }
              }}
            >
              {Component}
            </div>
          );
        })}
      </ContentWrap>
      <Paging {...paging} onChange={handleChangePage} />
      <SearchModal
        visible={searchModalVisible}
        onClose={() => setSearchModalVisible(false)}
        onChange={handleChangeSearchParam}
        initState={searchParam}
      />
      <TradeDetail
        {...tradeDetail}
        onClose={() => {
          setTradeDetail({ visible: false });
        }}
      />
    </Container>
  );
};

const Paging = ({ current, total, onChange }) => {
  const startPage = Math.floor((current - 1) / 5) * 5 + 1;
  let endPage = startPage + 4;
  if (endPage > total) {
    endPage = total;
  }

  const buttons = Array.from(
    { length: endPage - startPage + 1 },
    (v, i) => startPage + i
  );

  const hasBefore = startPage > 1;
  const hasNext = endPage < total;
  return total ? (
    <PagingWrap>
      <JumpWrap
        img={skip_icon}
        reverse
        active={hasBefore}
        onClick={() => {
          onChange(1);
        }}
      />
      <JumpWrap
        img={next_icon}
        reverse
        active={hasBefore}
        onClick={() => {
          onChange(startPage - 1);
        }}
      />
      {buttons.map((number) => (
        <PagingButton
          number={number}
          key={number.toString()}
          selected={number === current}
          onClick={() => {
            onChange(number);
          }}
        />
      ))}
      <JumpWrap
        img={next_icon}
        active={hasNext}
        onClick={() => {
          onChange(endPage + 1);
        }}
      />
      <JumpWrap
        img={skip_icon}
        active={hasNext}
        onClick={() => {
          onChange(total);
        }}
      />
    </PagingWrap>
  ) : (
    <></>
  );
};

const Container = styled(Flex)`
  flex: 1;
  padding: 17px;
`;

const SearchWrap = styled(Flex)`
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 17px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding-right: 32px;

  img {
    position: absolute;
    right: 0;
  }
`;

const PeriodWrap = styled(Flex)`
  margin-top: 20px;
  border-bottom: 2px solid rgba(167, 67, 0, 0.4);
  font-family: ${fonts.spoqa};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: -0.28px;
  padding-bottom: 4px;
`;

const ContentWrap = styled(Flex)`
  flex: 1;

  > div {
    margin-top: 16px;
  }
`;

const PagingWrap = styled(Flex)`
  padding: 23px 0;
  flex-direction: row;
  justify-content: center;
`;

const JumpWrap = ({ img, reverse, active, ...rest }) => {
  return (
    <Flex
      {...rest}
      style={{
        width: 40,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {active && <JumpButton active={active} img={img} reverse={reverse} />}
    </Flex>
  );
};
const JumpButton = styled(Flex)`
  width: 24px;
  height: 24px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  transform: scaleX(${({ reverse }) => (reverse ? -1 : 1)});
  &:after {
    content: "";
    background-image: url(${({ img }) => img});
    background-size: 7px 10px;
    width: 7px;
    height: 10px;
  }
`;
const PagingButton = styled(Flex)`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: ${({ selected }) =>
    selected ? "rgba(247, 111, 39, 0.1)" : "transparent"};
  font-family: ${({ selected }) =>
    selected ? fonts.notoSansKRBold : fonts.notoSansKRRegular};
  color: ${({ selected }) => (selected ? "#f76f27" : "#999999")};
  font-size: 17px;
  &:after {
    content: "${({ number }) => number}";
  }
`;

export default HistoryComplete;
