import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import Tables from "../../components/tables/Tables";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { formatTime, numFormat } from "../../libs/utils";
import * as APIS from "../../libs/apis";

export default function TradeHistoryPopup(state) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const onClose = state.onClose;
  const [rows, setData] = useState([]);
  const { id } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.user);

  //console.log(state);
  useEffect(() => {
    APIS.getSignedHistoryList(state.method).then(({ data: { data } }) => {
      setData(data);
    });
  }, []);

  return (
    <Flex className={classes.root}>
      <ButtonBase onClick={onClose} className={classes.row}>
        <img alt="close" src={images.close} className={classes.close} />
      </ButtonBase>

      <Text font={fonts.notoSansKRBold} className={classes.label}>
        체결 내역
      </Text>
      <Tables
        data={rows}
        columns={[
          {
            label: "체결시간",
            render: (x) => {
              var listData1 = formatTime(x.completedAt, "MM.DD");
              var listData2 = formatTime(x.completedAt, "HH:mm:ss");
              return (
                <>
                  {" "}
                  {listData1} <br />
                  {listData2}{" "}
                </>
              );
            },
          },
          {
            label: "거래구분",
            render: (x) => {
              return (
                <>
                  <Text
                    className={
                      x.tradeType === "SELL" ? classes.blue : classes.red
                    }
                  >
                    {x.tradeType === "SELL" ? "매도" : "매수"}
                  </Text>
                  {x.assetType === "GOLD" ? "금" : "은"}({x.assetType})
                </>
              );
            },
          },
          {
            label: "체결중량\n체결단가",
            render: (x) => {
              var tradeGram = numFormat(x.tradeGram);
              var pricePerGram = numFormat(x.pricePerGram);
              return (
                <>
                  {" "}
                  {tradeGram}g <br />
                  {pricePerGram}{" "}
                </>
              );
            },
          },
          {
            label: "체결금액",
            render: (x) => {
              var totalAmount = numFormat(x.pureTradeKrw);
              return (
                <>
                  {totalAmount}
                  {/*<Text className={classes.red}>+1.26%</Text>*/}
                </>
              );
            },
          },
        ]}
      />

      {rows.length < 1 && (
        <Text className={classes.no}>체결된 내역이 없습니다.</Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    zIndex: 11,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: colors.white,
  },
  row: {
    alignSelf: "flex-end",
    padding: "16px",
  },
  close: {
    width: "20px",
    height: "20px",
  },
  label: {
    color: colors.text,
    margin: "30px 16px 13px 16px",

    fontSize: "22px",
  },
  no: {
    fontSize: "13px",
    alignSelf: "center",
    color: colors.text,
    marginTop: "50px",
  },
  red: {
    color: colors.textRed,
  },
  blue: {
    color: colors.textBlue,
  },
});
