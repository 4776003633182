import { toastActionType } from "./ToastActions";

const initToast = {
  open: false,
  second: 1,
  message: "",
};

export default function toast(state = initToast, action) {
  switch (action.type) {
    case toastActionType.hide:
      return {
        ...initToast,
      };
    case toastActionType.show:
      return {
        ...state,
        ...action,
        open: true,
      };
    default:
      return state;
  }
}
