import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import RenderInput from "../render-input/RenderInput";
import { toastShow } from "../../redux/toast/ToastActions";
export default function Images({ data, setData, max, className, size = 70 }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  return (
    <Flex row className={classes.root + " " + className}>
      <RenderInput
        inputRef={ref}
        onlyImage
        onChange={(f) => {
          setData([f, ...data]);
        }}
      />
      <ButtonBase
        onClick={() => {
          if (!isNaN(max)) {
            if (data.length >= max) {
              return dispatch(
                toastShow("이미지는 최대 " + max + "개 까지 등록 가능합니다.")
              );
            }
          }
          ref.current?.click();
        }}
        className={classes.add}
        style={{ width: size, height: size }}
      >
        <img src={images.camera} className={classes.camera} />
      </ButtonBase>
      {data.map((x, i) => {
        return (
          <Flex
            key={i.toString()}
            className={classes.item}
            style={{
              width: size,
              height: size,
            }}
          >
            <img src={x.uri} className={classes.image} />
            <ButtonBase
              className={classes.close}
              onClick={() => {
                setData([...data.filter((z, y) => i !== y)]);
              }}
            >
              <img
                src={images.closewhite}
                style={{ width: "12px", height: "12px" }}
              />
            </ButtonBase>
          </Flex>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    maxWidth: "100%",
    overflowX: "auto",
    alignItems: "center",
  },
  add: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderRadius: "5px",
    border: "1px solid #7d7d7d",
    borderStyle: "dashed",
  },
  camera: {
    width: "20px",
    height: "17px",
  },
  image: {
    width: "100%",
    borderRadius: "5px",
    height: "100%",
    objectFit: "cover",
  },
  item: {
    marginLeft: 10,
    position: "relative",
  },
  close: {
    borderTopRightRadius: 5,
    backgroundColor: "rgba(0,0,0,0.5)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    position: "absolute",
    top: 0,
    right: 0,
    width: 24,
    height: 24,
  },
});
