import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function TopbarClose({}) {
  const history = useHistory();
  const classes = useStyle();
  return (
    <Flex row className={classes.header}>
      <span />
      <ButtonBase
        onClick={() => {
          history.goBack();
        }}
      >
        <img alt="cloase" src={images.close} className={classes.close} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  header: { alignItems: "center", justifyContent: "space-between" },
  close: {
    width: "18px",
    height: "18px",
    margin: "16px 16px",
  },
  label: {
    margin: "16px",
    color: colors.text,
    fontSize: "18px",
  },
});
