import axios from "axios";
import queryString from "query-string";
import consts from "../libs/consts";
import { messageShow } from "../redux/message/MessageActions";
import { userActionType } from "../redux/user/UserActions";

export const axiosDispatch = (
  { method, url, data, header, responseType, file },
  callback
) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem(consts.accessToken);

    // accessToken Validation 임시 주석처리
    // if (!accessToken) {
    //   dispatch(
    //     messageShow(
    //       "토큰 인증이 만료되었습니다.\n다시 로그인 해주세요.",
    //       () => {
    //         window.location.replace("/login");
    //       }
    //     )
    //   );
    //   return;
    // }
    const res = await axios({
      method,
      responseType: responseType ? responseType : "",
      url,
      data,
      headers: {
        ...header,
        accept: "application/json;charset=UTF-8",
        "Content-Type": file ? "multipart/form-data" : "application/json",
        //authorization: "Bearer " + accessToken,
      },
    });
    if (
      url.startsWith(consts.apiBaseUrl) &&
      res.data &&
      res.data.code &&
      res.data.code !== 200
    ) {
      throw res.data;
    }
    callback(null, res);
  } catch (error) {
    callback(error, null);
    return;
    /*
    if (error.response?.status === 401) {
      localStorage.clear(consts.accessToken);
      dispatch({ type: userActionType.init });
      dispatch(messageShow("토큰 인증 만료되었습니다.\n다시 로그인 해주세요."));
    } else {
      callback(error, null);
    }*/
  }
};
