import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../../components/button/Button";
import Flex from "../../../components/flex/Flex";
import fonts from "../../../libs/fonts";
import HistoryIng from "./hisory-ing/HistoryIng";
import HistoryComplete from "./history-complete/HistoryComplete";
import * as APIS from "../../../libs/apis";
import { useDispatch } from "react-redux";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";

const TradeHistory = () => {
  const history = useHistory();
  const locationState = history.location.state || {};
  const { division } = locationState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!division) {
      dispatch(loadingStart);
      APIS.getTradeHistoryIngList()
        .then(({ data: { success, data = [], message } }) => {
          if (data?.length) {
            handleClickDivision("ING");
          } else {
            handleClickDivision("COMPLETE");
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  }, []);
  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleClickDivision = (division) => {
    history.replace(history.location.pathname, { tab: "HISTORY", division });
  };

  return (
    <Container>
      <DivisionTabWrap>
        <DivisionTab
          label="거래 완료"
          selected={division === "COMPLETE"}
          onClick={() => handleClickDivision("COMPLETE")}
        />
        <Flex style={{ width: 30 }} />
        <DivisionTab
          label="진행중"
          selected={division === "ING"}
          onClick={() => handleClickDivision("ING")}
        />
        {/* <DivisionTab
          label="거래 현황"
          selected={division === "STATUS"}
          onClick={() => handleChangeLocationState({ division: "STATUS" })}
        /> */}
      </DivisionTabWrap>
      {division === "ING" && <HistoryIng />}
      {division === "COMPLETE" && <HistoryComplete />}
    </Container>
  );
};

const Container = styled(Flex)`
  flex: 1;
  background: #fcfcfc;
`;
const DivisionTabWrap = styled(Flex)`
  flex-direction: row;
  background: white;
  padding: 12px 0;
  justify-content: center;
  border-bottom: 1px solid #ededed;
`;
const DivisionTab = styled(Button)`
  border-radius: 20px;
  height: 40px;
  width: 88px;
  // flex: 1;
  background: ${({ selected }) => (selected ? "#222222" : "#eaeaea")};
  padding: 0;

  span {
    font-family: ${fonts.notoSansKRBold};
    font-size: 17px;
    letter-spacing: -0.34px;
    color: ${({ selected }) => (selected ? "#ffffff" : "#999999")};
  }
`;

export default TradeHistory;
