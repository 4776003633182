import { ButtonBase, makeStyles } from "@material-ui/core";
import { ClassSharp } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { listHide } from "../../redux/list/ListActions";

export default function List({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { open, title, data, onPress } = useSelector((s) => s.list);

  const handleClose = () => {
    if (onPress) {
      onPress();
    }
    dispatch(listHide());
  };

  if (!open) {
    return null;
  }

  return (
    <Flex className={classes.root}>
      <Flex className={classes.main}>
        <ButtonBase
          onClick={() => {
            dispatch(listHide());
          }}
          className="img-button"
        >
          <img src={images.close} />
        </ButtonBase>
        <Text font={fonts.notoSansKRMedium} className={classes.title}>
          {title}
        </Text>
        {data.map((x, i) => {
          return (
            <ButtonBase
              onClick={() => {
                dispatch(listHide());
              }}
              className={
                classes.button + " " + (i === 0 && classes.buttonPrimary)
              }
              key={i.toString()}
            >
              {x.label}
            </ButtonBase>
          );
        })}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: "center",
    zIndex: 20,
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  main: {
    alignSelf: "stretch",
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px",
    borderRadius: "10px",
    padding: "30px",
  },
  title: {
    color: colors.text,
    marginBottom: "20px",
    fontSize: "18px",
  },
  button: {
    alignSelf: "stretch",
    padding: "10px 50px",
    marginTop: "10px",
    border: "1px solid #ddd",
    borderRadius: "3px",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
  },
  buttonPrimary: {
    border: "none",
    backgroundColor: colors.primary,
    color: "#fff",
  },
});
