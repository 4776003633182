import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { toastShow } from "../../redux/toast/ToastActions";
import DaumPostcode from "react-daum-postcode";
import * as APIS from "../../libs/apis";
import Flex from "../../components/flex/Flex";
import styled from "styled-components";
import AddressList from "./AddressList";
import infoIcon from "../../assets/images/info_icon.webp";
import AddAddress from "./AddAddress";

export default function AddressManagement() {
  const history = useHistory();
  const locationState = history.location.state || {};
  const { tab = "LIST" } = locationState;

  const handleChangeLocationState = (newState = {}) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  return (
    <BasicLayout stackTopbar={{ title: "배송지 관리", border: true }}>
      <Container>
        {/* <Info row>
          <img src={infoIcon} width={16} />
          배송지는 최대&nbsp;<span className="spoqa">10</span>개까지 저장
          가능합니다.
        </Info> */}
        <ContentWrap>
          <ContentHeader>배송지</ContentHeader>
          <Flex row>
            <ContentTab
              selected={tab === "LIST"}
              onClick={() => {
                handleChangeLocationState({ tab: "LIST" });
              }}
              color="#f6f6f6"
            >
              배송지 목록
            </ContentTab>
            <ContentTab
              selected={tab === "ADD"}
              onClick={() => {
                handleChangeLocationState({ tab: "ADD" });
              }}
              color="white"
            >
              신규 배송지 입력
            </ContentTab>
          </Flex>
        </ContentWrap>
        {tab === "LIST" && <AddressList />}
        {tab === "ADD" && (
          <AddAddress
            onComplete={() => {
              handleChangeLocationState({ tab: "LIST" });
            }}
          />
        )}
      </Container>
    </BasicLayout>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #f9f9f9;
  padding: 16px;
`;

const Info = styled(Flex)`
  padding: 7px;
  margin-bottom: 16px;
  border: 1px solid #eaeaea;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 12px;
  letter-spacing: -0.24px;
  color: #444444;

  img {
    margin-right: 5px;
  }

  .spoqa {
    font-size: 13px;
    letter-spacing: -0.26px;
    font-weight: 500;
  }
`;

const ContentWrap = styled(Flex)`
  border: 1px solid #eaeaea;
  border-radius: 8px 8px 0 0;
  border-bottom: 0;
  overflow: hidden;
`;

const ContentHeader = styled(Flex)`
  background: #ffffff;
  padding: 10px 15px;
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  line-height: 24.5px;
  letter-spacing: -0.32px;
  color: #333333;
  border-bottom: 1px solid #eaeaea;
`;

const ContentTab = styled(Flex)`
  flex: 1;
  padding: 10px;
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  line-height: 27px;
  letter-spacing: -0.34px;
  color: #333333;
  align-items: center;

  background: ${({ selected, color }) => (selected ? color : "#e5e5e5")};
`;
