import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ic_place_bk from "../../assets/images/ic_place_bk.png";
import ic_phone_bk from "../../assets/images/ic_phone_bk.png";
import ic_phone from "../../assets/images/ic_phone.png";
import ic_close from "../../assets/images/ic_close.png";
import ic_place_white from "../../assets/images/ic_place_white.png";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import images from "../../libs/images";
import routes from "../../libs/routes";
import {
  callPhone,
  encodeFileToBase64,
  formatTime,
  numberWithCommas,
} from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import {
  initSafeTradeOrder,
  updateSafeTradeOrder,
} from "../../redux/safe-trade/safeTradeOrder";
import { initFindShop } from "../../redux/common/findShop";
import TimeRangeSelector from "../../components/common/TimeRangeSelector";
import DatePicker from "react-datepicker";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { E_001, I_001, CALENDAR_ALERT } from "../../libs/messages";
import colors from "../../libs/colors";
import Calendar from "../../components/common/Calendar";
import VisitReservation from "../../components/common/VisitReservation";

export default function SafeTradeStep4({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shop, reservation } = useSelector((state) => state.safeTradeOrder);
  const safeTradeOrder = useSelector((state) => state.safeTradeOrder);

  const locationState = history.location.state || {};

  useEffect(() => {
    const reservationDate = locationState.reservation?.date;
    if (reservationDate) {
      updateOrder({ reservation: formatTime(reservationDate) });
    } else {
      updateOrder({ reservation: "" });
    }

    updateOrder({ shop: locationState.shop || {} });
  }, [locationState.reservation, locationState.shop]);

  const updateOrder = (order) => dispatch(updateSafeTradeOrder(order));

  const handleClickNext = () => {
    if (!shop?.id) {
      dispatch(messageError("방문 매장을 선택해주세요."));
      return;
    }

    if (!reservation) {
      dispatch(messageError("방문 일자를 선택해주세요."));
      return;
    }
    if (shop?.id && reservation) {
      const formData = new FormData();

      const dto = { ...safeTradeOrder, shop_id: shop.id };
      delete dto.shop;

      const imageKeys = ["front", "side", "back", "model", "contract", "parts"];
      for (let key of imageKeys) {
        if (dto[key]) {
          formData.append(key, dto[key]);
        }
        delete dto[key];
      }

      formData.append(
        "safetrade",
        new Blob([JSON.stringify(dto)], { type: "application/json" })
      );

      for (let key of formData.keys()) {
        console.log(key, ":", formData.get(key));
      }

      dispatch(loadingStart);
      APIS.postSafeTradeOrder(formData)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            history.push(routes.main);
            history.push(routes.safeTradeCreateComplete, data);
            dispatch(initSafeTradeOrder());
          } else {
            dispatch(actionError(message));
          }
        })
        .catch(() => {
          dispatch(actionError("알 수 없는 오류가 발생하였습니다"));
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };

  return (
    <BasicLayout
      stackTopbar={{
        title: "상품 등록",
        option: (
          <ButtonBase onClick={() => history.replace(routes.main)}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}
    >
      <div className="trade_wrap" style={{ padding: "0px 16px 100px" }}>
        <VisitReservation
          description={`상품 판매 접수를 위해\n의뢰하실 매장을 선택해 주세요.`}
        />

        <div
          className={`ok_btn ${shop?.id && reservation && "on"}`}
          onClick={handleClickNext}
          style={{ zIndex: 0 }}
        >
          다음
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  inputDate: {
    opacity: 0,
    position: "absolute !important",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100% !important",
    zIndex: 1,
  },
});
