import {
  getAppVersion,
  getMobileOperatingSystem,
  versionParser,
} from "../../libs/utils";

// Actions
const DISABLE = "kakao/chat/DISABLE";

// ios 6.7.0.0 / android 6.7.5.1
const checkAppVersion = () => {
  const appVersion = getAppVersion();

  const requiredVersion =
    getMobileOperatingSystem() === "iOS" ? "6.7.0.0" : "6.7.5.1";

  if (versionParser(requiredVersion) <= versionParser(appVersion)) return false;
  else return true;
};

const INIT_STATE = {
  disabled: checkAppVersion(),
};

// Reducer
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case DISABLE:
      return { ...INIT_STATE, disabled: true };
    default:
      return state;
  }
}

// Action Creator
export function disableKakaoChat() {
  return { type: DISABLE };
}
