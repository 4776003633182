import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import moment from "moment-timezone";
import { numFormat, objToQueryStr } from "../../services/utils";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { actionError } from "../../redux/action/ActionActions";
import { toastShow } from "../../redux/toast/ToastActions";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";

export default function CompleteCard({
  appraisalRequestStatus,
  id,
  navigate,
  shop,
  method,
  collectAddress, // 반송 주소
  collectAddressDetail, // 반송 상세 주소
  collectPostNo, // 반송 우편번호
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [rt, setRt] = useState(null);
  const [unitPrice, setUnitPrice] = useState(0);

  useEffect(() => {
    dispatch(loadingStart);

    // const api1 = APIS.getAppraisalFeedbakcByRequest(id)
    //   .then((res) => {
    //     const data = res.data;
    //     let d = data.data.content[0];
    //     setRt(d ? d : false);
    //   })
    //   .catch((err) => dispatch(messageError(err)));

    const { assetType = "" } =
      appraisalRequestStatus.find((x) => x.status === "감정완료") || {};

    const api2 = assetType
      ? APIS.getCurrentMarketPrice(assetType).then(({ data: { data } }) => {
          setUnitPrice(data);
        })
      : "";

    Promise.all([api2]).finally(() => dispatch(loadingEnd));
  }, []);

  if (
    !appraisalRequestStatus ||
    !appraisalRequestStatus.filter((x) => x.status === "감정완료").length
  ) {
    return null;
  }

  const isClick = useRef(false);
  const completeExchange = async () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(loadingStart);

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        completeFetch();
        return;
      }

      APIS.putAppraisalRequestsStatus(id, { status: "EXCHANGE_COMPLETE" }).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            pendingExchange(1);
          } else {
            dispatch(messageError(message));
            completeFetch();
          }
        }
      );
    }
  };

  const pendingExchange = (count) => {
    setTimeout(() => {
      APIS.getAppraisalRequestPending(id).then(({ data: { success } }) => {
        if (success) {
          completeFetch();
          dispatch(toastShow("교환 완료 되었습니다."));
        } else if (count === 10) {
          completeFetch();
          dispatch(toastShow("교환 처리 중 입니다."));
        } else {
          pendingExchange(count + 1);
        }
      });
    }, 1000);
  };

  const completeFetch = () => {
    isClick.current = false;
    dispatch(loadingEnd);
    history.replace({
      pathname: history.location.pathname,
      state: history.location.state,
    });
  };

  let d = appraisalRequestStatus.filter((x) => x.status === "감정완료")[0];
  let finished =
    appraisalRequestStatus.filter((x) => x.status === "교환완료").length > 0;

  return (
    <Flex className={classes.card + " shadow2"}>
      <Flex row className="row-between">
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          최종 감정가
        </Text>
        {/*
        <ButtonBase className={classes.button}>상세 감정 결과 보기</ButtonBase>
           */}
      </Flex>
      <Text font={fonts.notoSansKRBold} className={classes.title}>
        {d.assetType === "GOLD" ? "금(GOLD)" : "은(SILVER)"} {d.appraiseGram}g
        <Text>
          <br />= {numFormat(unitPrice * d.appraiseGram)}원
        </Text>
      </Text>

      <Text className={classes.message}>
        {moment().format("YYYY.MM.DD")} 기준{" "}
        {d.assetType === "GOLD" ? "금(GOLD)" : "은(SILVER)"} 시세{" "}
        {numFormat(unitPrice)}원/g
      </Text>

      {appraisalRequestStatus.filter((x) => x.status?.includes("반송")).length >
      0 ? null : finished ? (
        <Flex row className={classes.buttons2}>
          {/* {rt !== null && (
            <Button
              label={rt ? "작성 후기 보기" : "교환 후기 작성"}
              onClick={() => {
                if (rt) {
                  return navigate(routes.appraiseReviewDetail, {
                    id: rt.id,
                    requestId: id,
                  });
                }
                navigate(routes.writeReview, {
                  id,
                  type: "appraise",
                });
              }}
            />
          )} */}
        </Flex>
      ) : (
        <Flex row className={classes.buttons}>
          <Button
            label="돌려받기"
            onClick={() => {
              navigate(routes.appraiseReturn, {
                id,
                shop,
                method,
                collectAddress,
                collectAddressDetail,
                collectPostNo,
              });
              // dispatch(toastShow("준비중 기능입니다."));
            }}
          />
          <Button onClick={completeExchange} label="승인하기" />
        </Flex>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  card: {
    marginBottom: "20px",
    padding: "20px 30px",
  },
  title: {
    marginTop: 28,
    fontSize: 24,
    color: "#333",
    textAlign: "center",
    lineHeight: 1,
    alignItems: "center",
    "& span": { fontSize: 16 },
  },
  message: {
    alignSelf: "center",
    color: "#333",
    fontSize: 12,
    marginTop: 17,
    marginBottom: 20,
  },
  buttons: {
    alignSelf: "stretch",
    "& button": {
      padding: "10px",
      flex: 1,
      "&:first-child": {
        marginRight: 5,
        backgroundColor: "#333",
      },
      "&:last-child": {
        marginLeft: "5px",
      },
    },
  },
  label: {
    fontSize: 16,
    color: "#333",
  },
  button: {
    color: colors.primary,
    border: "2px solid " + colors.primary,
    padding: "10px 20px",
    lineHeight: 1,
    fontSize: 14,
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: 20,
  },
  buttons2: {
    alignSelf: "stretch",
    "& button": {
      padding: "10px",
      flex: 1,
    },
  },
});
