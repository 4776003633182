import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionError } from "../../redux/action/ActionActions";

import Flex from "../../components/flex/Flex";
import InputSmallBorder from "../../components/input-small-border/InputSmallBorder";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import DataButton from "./components/DataButton";
import FormPopup from "./FormPopup";

import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../components/button/Button";
import * as APIS from "../../libs/apis";
import { ROLE } from "../../libs/routes";
import { numberWithCommas } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";

const GRAM_RANGE = {
  GOLD: ["-100", "-10", "+10", "+100"],
  SILVER: ["-1000", "-100", "+100", "+1000"],
};

const DON_RANGE = {
  GOLD: ["-5", "-1", "+1", "+5"],
  SILVER: ["-50", "-10", "+10", "+50"],
};

const convertBetweenDonGram = (income, targetTo, toFixedNumber = 4) => {
  if (targetTo === "don") {
    const resultToDon = Number(income) / 3.75;
    return Number(resultToDon.toFixed(toFixedNumber));
  }
  const resultGram = Number(income) * 3.75;
  return Number(resultGram.toFixed(toFixedNumber));
};

const digitValidation = (input, allowedDigit) => {
  let regex = /^\d+$/;

  if (allowedDigit === 1) {
    regex = /^\d+\.\d{1}$/;
  }
  if (allowedDigit === 2) {
    regex = /^\d+\.\d{1,2}$/;
  }
  if (allowedDigit === 3) {
    regex = /^\d+\.\d{1,3}$/;
  }

  return regex.test(Number(input));
};

export default function Form({
  settype,
  price,
  method,
  onChangePrice,
  feeRates = [],
  authBlock,
  minPrice,
  maxPrice,
  getMinMax,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [index, setIndex] = useState(method === "buy" ? 0 : 1);
  const { id } = useSelector((s) => s.user);
  const [purchaseData, setPurchaseData] = useState({ open: false });
  const [possiblePrice, setPossiblePrice] = useState(0);
  const [gram, setGram] = useState("");
  const [don, setDon] = useState("");
  const [totalPrice, setTotalPrice] = useState("0");
  const [fee, setFee] = useState(0);
  const [hasPoint, setHasPoint] = useState(false);

  const locationState = history.location.state || {};
  const { orderType = "LIMITS" } = locationState; // LIMITS: "지정가", MARKET: "시장가"

  const auth = useSelector((state) => state.auth);
  const isSilver = settype === "SILVER";

  Number.prototype.format = function () {
    if (this == 0) return 0;

    var reg = /(^[+-]?\d+)(\d{3})/;
    var n = this + "";

    while (reg.test(n)) n = n.replace(reg, "$1" + "," + "$2");

    return n;
  };

  // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
  String.prototype.format = function () {
    var num = parseFloat(this);
    if (isNaN(num)) return "";

    return num.format();
  };
  console.log(feeRates);
  useEffect(() => {
    const total = Math.floor(+price * gram);

    const feeRate = (feeRates.find(({ configType, assetType }) => {
      const method = index === 0 ? "BUY" : "SELL";
      return assetType === settype && configType === `${method}`;
    }) || {})["feeRate"];

    let fee = parseInt(total * feeRate);
    if (index === 1) {
      fee = -fee;
    }
    setTotalPrice(total);
    setFee(fee);
  }, [price, gram, don, index]);

  useEffect(() => {
    init();
  }, [index, auth, settype]);

  const init = () => {
    // index == 0 > 매수

    if (auth?.role?.LEVEL >= ROLE.ROLE_SELF_VERIFICATION.LEVEL) {
      APIS.getAvailableAmount().then(({ data: { data } }) => {
        setHasPoint(false);
        const { gold = 0, silver = 0, krw = 0, point = 0 } = data || {};
        if (index === 1) {
          if (settype === "GOLD") {
            setPossiblePrice(gold);
          } else {
            setPossiblePrice(silver);
          }
        } else {
          if (point) {
            setHasPoint(true);
          }
          setPossiblePrice(krw + point);
        }
      });
    }

    getMinMax();
  };

  const setOrderType = (orderType) => {
    history.replace(history.location.pathname, { ...locationState, orderType });
  };

  const handleIndexChange = (index) => () => {
    history.replace(history.location.pathname, {
      ...(history.location.state || {}),
      method: index === 0 ? "buy" : "sell",
    });
    setIndex(index);
  };

  const handleTrade = async () => {
    if (!authBlock()) {
      return false;
    }

    if (orderType === "LIMITS") {
      // 지정가 거래
      if (settype === "SILVER") {
        if (price % 5 !== 0) {
          dispatch(actionError("5원단위로 가격을 입력해주세요."));
          return;
        }
        if (+(+gram * 10).toFixed(11) % 5 !== 0) {
          dispatch(actionError("0.5g 단위로 중량을 입력해주세요."));
          return;
        }
      } else {
        if (price % 100 !== 0) {
          dispatch(actionError("100원단위로 가격을 입력해주세요."));
          return;
        }

        if (+(+gram * 1000).toFixed(9) % 5 !== 0) {
          dispatch(actionError("0.005g 단위로 중량을 입력해주세요."));
          return;
        }
      }

      if (index === 1) {
        if (possiblePrice < gram) {
          dispatch(actionError("주문가능 금액을 초과하였습니다."));
          return;
        }
      } else {
        if (possiblePrice < +totalPrice + (fee || 0)) {
          dispatch(actionError("주문가능 금액을 초과하였습니다."));
          return;
        }
      }

      if (+price < minPrice) {
        dispatch(
          actionError(
            `하한가(${numberWithCommas(minPrice)}원) 이상으로 입력해주세요.`
          )
        );
        return false;
      }

      if (+price > maxPrice) {
        dispatch(
          actionError(
            `상한가(${numberWithCommas(maxPrice)}원) 이하로 입력해주세요.`
          )
        );
        return false;
      }

      if (price < 1) {
        dispatch(actionError("가격을 입력해 주세요"));
        return;
      }
      if (gram < 0.001) {
        dispatch(actionError("중량을 입력해 주세요"));
        return;
      }

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(actionError(serviceBreak));
        return;
      }

      if (hasPoint) {
        dispatch(toastShow("보유한 포인트가 먼저 사용됩니다."));
      }

      setPurchaseData({
        open: true,
        method: index === 0 ? "buy" : "sell",
        settype,
        orderType,
        inputprice: price,
        gram,
        totalprice: totalPrice,
        id,
        fee,
      });
    } else {
      // 시장가 거래
      if (settype === "SILVER") {
        if (+(+gram * 10).toFixed(11) % 5 !== 0) {
          dispatch(actionError("0.5g 단위로 중량을 입력해주세요."));
          return;
        }
      } else {
        if (+(+gram * 1000).toFixed(9) % 5 !== 0) {
          dispatch(actionError("0.005g 단위로 중량을 입력해주세요."));
          return;
        }
      }

      if (gram < 0.001) {
        dispatch(actionError("중량을 입력해 주세요"));
        return;
      }

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(actionError(serviceBreak));
        return;
      }

      if (hasPoint) {
        dispatch(toastShow("보유한 포인트가 먼저 사용됩니다."));
      }
      expectMarketTrade();
    }
  };

  const expectMarketTrade = () => {
    dispatch(loadingStart);
    const payload = {
      tradeType: (index === 0 ? "buy" : "sell").toUpperCase(),
      assetType: settype,
      orderType,
      requestGram: gram,
    };
    APIS.postExpectTrade(payload)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(messageError(message));
        } else {
          const fee = index === 0 ? data.tradeFee : -data.tradeFee;
          setPurchaseData({
            open: true,
            method: index === 0 ? "buy" : "sell",
            settype,
            orderType,
            inputprice: price,
            gram,
            totalprice: data.tradeKrw,
            id,
            fee,
          });
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handlePurchasePopupClose = () => {
    setPurchaseData({
      open: false,
    });
    //setgraminput("");
    //setdoninput("");
    //settotalprice("");
    /*
    if(tradeorder === 0){
      settradeorder(1);
    } else {
      settradeorder(0);
    }
    */
  };
  const hanldeGramInput = (val) => {
    const dondata = convertBetweenDonGram(val, "don", 4); // 4로?? 원본은 2
    setDon(dondata);
    setGram(val);
  };
  const handleDonInput = (val) => {
    const gramdata = convertBetweenDonGram(val, "gram", 4); // 4로?? 원본은 2
    setDon(val);
    setGram(gramdata);
  };

  const priceSum = function (val) {
    onChangePrice(+price + +val);
  };

  function priceMinus(val) {
    const nextPrice = +price - +val;
    if (nextPrice < 0) {
      onChangePrice(0);
    } else {
      onChangePrice(nextPrice);
    }
  }

  function gramsum(val) {
    const toBeDisplayedGram = +gram + +val;
    if (toBeDisplayedGram < 0) {
      setGram(0);
      setDon(0);
      return;
    }
    setGram(+(toBeDisplayedGram < 0 ? 0 : toBeDisplayedGram).toFixed(3));
    setDon(convertBetweenDonGram(toBeDisplayedGram, "don", 4));
  }

  function donsum(val) {
    const toBeDisplayedDon = Number(don) + Number(val);
    const result = +gram + Number(val) * 3.75;
    if (result < 0) {
      setGram(0);
      setDon(0);
      return;
    }
    setGram(Number((result < 0 ? 0 : result).toFixed(3)));
    setDon(toBeDisplayedDon);
  }

  const handleClickClear = () => {
    onChangePrice("");
    setDon("");
    setGram("");
  };

  return (
    <Flex className={classes.root}>
      {purchaseData.open && (
        <FormPopup
          {...purchaseData}
          onClose={handlePurchasePopupClose}
          onSuccess={() => {
            handleClickClear();
            init();
          }}
        />
      )}
      <Flex row>
        <ButtonBase
          onClick={handleIndexChange(0)}
          className={`${classes.button} ${index === 0 && classes.buttonSel}`}
        >
          매수
        </ButtonBase>
        <ButtonBase
          onClick={handleIndexChange(1)}
          className={`${classes.button} ${index === 1 && classes.buttonSel2}`}
        >
          매도
        </ButtonBase>
      </Flex>
      <Flex className={classes.content}>
        <Text className={classes.message1}>
          {auth?.role?.LEVEL >= ROLE.ROLE_SELF_VERIFICATION.LEVEL && (
            <>
              주문가능
              <Text
                font={fonts.notoSansKRBold}
                className={classes.message1Bold}
              >
                <NumberFormat
                  value={possiblePrice}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
                {index === 1 ? "g" : "원"}
              </Text>
            </>
          )}
        </Text>
        <OrderTypeWrap>
          <OrderTypeButton
            label="지정가"
            textStyle={{ color: orderType === "LIMITS" ? "white" : "#999999" }}
            disabled={orderType === "LIMITS"}
            onClick={() => {
              setOrderType("LIMITS");
            }}
          />
          <OrderTypeButton
            label="시장가"
            textStyle={{ color: orderType === "MARKET" ? "white" : "#999999" }}
            disabled={orderType === "MARKET"}
            onClick={() => {
              setOrderType("MARKET");
            }}
          />
        </OrderTypeWrap>

        {orderType === "MARKET" ? (
          <>
            <Flex style={{ marginTop: "12px" }}>
              <InputSmallBorder
                placeholder={isSilver ? "최소0.5" : "최소0.005"}
                value={gram}
                number2={true}
                onChange={(e) => {
                  hanldeGramInput(e);
                }}
                label="중량"
                labeloption="g"
              />
              <Flex row className={classes.buttons}>
                {(GRAM_RANGE[settype] || []).map((value, i) => {
                  return (
                    <DataButton
                      key={value.toString()}
                      label={value + "g"}
                      onClickfunc={gramsum}
                      funcdata={+value}
                      last={i === (GRAM_RANGE[settype] || []).length - 1}
                    />
                  );
                })}
              </Flex>
            </Flex>
            <Flex style={{ marginTop: "12px" }}>
              <Flex row>
                <Text font={fonts.notoSansKRBold} className={classes.equals}>
                  =
                </Text>
                <InputSmallBorder
                  placeholder={isSilver ? "최소1돈" : "최소0.01돈"}
                  value={don}
                  number2={true}
                  onChange={(e) => {
                    if (isSilver) {
                      let nextDon = parseInt(+e);

                      if (nextDon % 2) {
                        nextDon++;
                      }
                      hanldeGramInput(nextDon * 3.75);
                      handleDonInput(e);
                    } else {
                      hanldeGramInput(e * 3.75);
                      handleDonInput(e);
                    }
                  }}
                  label="중량"
                  labeloption="돈"
                />
              </Flex>
              <Flex row className={classes.buttons}>
                {(DON_RANGE[settype] || []).map((value, i) => {
                  return (
                    <DataButton
                      key={value.toString()}
                      label={value + "돈"}
                      onClickfunc={donsum}
                      funcdata={+value}
                      last={i === (DON_RANGE[settype] || []).length - 1}
                    />
                  );
                })}
              </Flex>
            </Flex>

            <InformationWrap>
              <li>
                <span>
                  시장가 주문은 실시간 대기 물량에서 주문한 수량 만큼 자동으로
                  거래 체결됩니다.
                </span>
              </li>
              <li>
                <span>유리한 가격의 대기 물량부터 차례로 체결됩니다.</span>
              </li>
            </InformationWrap>
          </>
        ) : (
          <>
            <Flex row className={classes.form}>
              <InputSmallBorder
                isComma
                placeholder="0 원"
                value={price}
                onChange={(e) => {
                  onChangePrice(e);
                }}
                label=""
                className={classes.priceInput}
              />
              {settype === "SILVER" ? (
                <>
                  <DataButton
                    image={images.plus}
                    onClickfunc={priceSum}
                    funcdata={5}
                  />
                  <DataButton
                    image={images.minus}
                    onClickfunc={priceMinus}
                    funcdata={5}
                  />
                </>
              ) : (
                <>
                  <DataButton
                    image={images.plus}
                    onClickfunc={priceSum}
                    funcdata={100}
                  />
                  <DataButton
                    image={images.minus}
                    onClickfunc={priceMinus}
                    funcdata={100}
                  />
                </>
              )}
            </Flex>

            <Flex>
              <InputSmallBorder
                placeholder={settype === "SILVER" ? "최소0.5" : "최소0.005"}
                value={gram}
                onChange={(e) => {
                  hanldeGramInput(e);
                }}
                label="중량"
                labeloption="g"
              />
              <Flex row className={classes.buttons}>
                {(GRAM_RANGE[settype] || []).map((value, i) => {
                  return (
                    <DataButton
                      key={value.toString()}
                      label={value + "g"}
                      onClickfunc={gramsum}
                      funcdata={+value}
                      last={i === (GRAM_RANGE[settype] || []).length - 1}
                    />
                  );
                })}
              </Flex>
            </Flex>

            <Flex style={{ marginTop: "12px" }}>
              <Flex row>
                <Text font={fonts.notoSansKRBold} className={classes.equals}>
                  =
                </Text>
                <InputSmallBorder
                  placeholder={settype === "SILVER" ? "최소1돈" : "최소0.01돈"}
                  value={don}
                  // value={gram !== "" ? Number((gram / 3.75).toFixed(4)) : ""}
                  onChange={(e) => {
                    if (settype === "SILVER") {
                      let nextDon = parseInt(e);

                      if (nextDon % 2) {
                        nextDon++;
                      }
                      hanldeGramInput(nextDon * 3.75);
                      handleDonInput(e);
                    } else {
                      hanldeGramInput(e * 3.75);
                      handleDonInput(e);
                    }
                  }}
                  label="중량"
                  labeloption="돈"
                />
              </Flex>
              <Flex row className={classes.buttons}>
                {(DON_RANGE[settype] || []).map((value, i) => {
                  return (
                    <DataButton
                      key={value.toString()}
                      label={value + "돈"}
                      onClickfunc={donsum}
                      funcdata={+value}
                      last={i === (DON_RANGE[settype] || []).length - 1}
                    />
                  );
                })}
              </Flex>
            </Flex>

            <InputSmallBorder
              className={classes.input}
              placeholder="0 원"
              label="총액"
              value={numberWithCommas(+totalPrice + (fee || 0))}
              readonly={true}
            />
          </>
        )}

        <Flex className={classes.buttons2} row>
          <ButtonBase className={classes.init} onClick={handleClickClear}>
            초기화
          </ButtonBase>
          <ButtonBase
            onClick={handleTrade}
            className={classes.trans}
            style={{
              backgroundColor: index === 0 ? colors.textRed : colors.textBlue,
            }}
          >
            {index === 0 ? "매수" : "매도"}
          </ButtonBase>
        </Flex>
      </Flex>
    </Flex>
  );
}

const OrderTypeWrap = styled(Flex)`
  flex-direction: row;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
`;

const OrderTypeButton = styled(Button)`
  flex: 1;
  border-radius: 0;
  background: ${({ disabled }) => (disabled ? "#222222" : "#eaeaea")};
`;

const InformationWrap = styled.ul`
  margin-top: 8px;
  li {
    display: flex;
    flex-direction: row;
    margin-top: 4px;

    ::before {
      content: " ";
      display: flex;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: #999999;
      margin-top: 8px;
      margin-right: 4px;
    }

    span {
      display: flex;
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      color: #444444;
      letter-spacing: -0.02em;
      word-break: keep-all;
    }
  }
`;

const useStyle = makeStyles({
  trans: {
    flex: 2,
    padding: "10px",
    fontSize: "14px",
    color: "#fff",
    fontFamily: fonts.notoSansKRBold,
    borderRadius: "5px",
  },
  init: {
    flex: 1,
    padding: "10px",
    backgroundColor: "#999",
    fontSize: "14px",
    color: "#fff",
    marginRight: "6px",
    fontFamily: fonts.notoSansKRBold,
    borderRadius: "5px",
  },
  buttons2: {
    marginTop: "12px",
    alignItems: "center",
    alignSelf: "stretch",
  },
  content: {
    flex: 1,
    padding: "0px 12px",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
  root: {
    overflowY: "scroll",
    flex: 3,
    alignSelf: "stretch",
  },
  form: {
    margin: "16px 0px",
  },
  input: {
    marginTop: "20px",
  },
  button: {
    textAlign: "center",
    flex: 1,
    fontSize: "16px",
    color: "#999999",
    fontFamily: fonts.notoSansKRMedium,
    backgroundColor: "#dddddd",
    padding: "14px",
  },
  buttonSel: {
    backgroundColor: colors.white,
    color: colors.textRed,
  },
  buttonSel2: {
    backgroundColor: colors.white,
    color: colors.textBlue,
  },
  message1: {
    fontSize: 14,
    color: colors.gray,
    margin: "20px 0px",
  },
  message1Bold: {
    color: colors.text,
    marginLeft: "16px",
    fontSize: 15,
  },
  radios: {},
  buttons: {
    margin: "4px 0px",
  },
  equals: {
    fontSize: "18px",
    color: "#000",
    alignSelf: "center",
    lineHeight: 1,
    margin: "0px 8px",
  },
  priceInput: {
    paddingLeft: 10,
    paddingRight: 10,
  },
});
