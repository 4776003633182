import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
export default function RegisterExist({}) {
  const classes = useStyle();
  const history = useHistory();
  const { state } = useLocation();

  const renderLogo = (margin, method) => {
    return (
      <div
        className={classes.logo}
        style={{
          backgroundColor: (() => {
            switch (method) {
              case "NAVER":
                return "#21c603";
              case "GOOGLE":
                return "#fff";
              case "KAKAO":
                return "#f7e600";
              default:
                return "#000";
            }
          })(),
        }}
      >
        <img
          alt="logo"
          src={(() => {
            switch (method) {
              case "NAVER":
                return images.naver;
              case "GOOGLE":
                return images.google;
              case "KAKAO":
                return images.kakaotalk;
              case "APPLE":
                return images.apple;
            }
          })()}
        />
      </div>
    );
  };

  return (
    <BasicLayout backgroundGray>
      <ImageButton
        onClick={history.goBack}
        className={classes.close}
        align="right"
        src={images.close}
      />

      <Text font={fonts.notoSansKRLight} className={classes.label}>
        이미 금방금방 서비스에
        <br />
        가입한 계정이 있습니다.
      </Text>

      <Flex className={classes.content + " shadow"}>
        <Flex row className={classes.email}>
          <Text className={classes.emailLABEL}>{state.email}</Text>
        </Flex>

        {Boolean(state?.kakao) && (
          <Flex row className={classes.email}>
            {renderLogo(false, "KAKAO")}
            <Text className={classes.emailLABEL}>{state.kakao}</Text>
          </Flex>
        )}
        {Boolean(state?.naver) && (
          <Flex row className={classes.email}>
            {renderLogo(false, "NAVER")}
            <Text className={classes.emailLABEL}>{state.naver}</Text>
          </Flex>
        )}
        {Boolean(state?.google) && (
          <Flex row className={classes.email}>
            {renderLogo(false, "GOOGLE")}
            <Text className={classes.emailLABEL}>{state.google}</Text>
          </Flex>
        )}

        {Boolean(state?.apple) && (
          <Flex row className={classes.email}>
            {renderLogo(false, "APPLE")}
            <Text className={classes.emailLABEL}>{state.apple}</Text>
          </Flex>
        )}

        <Text font={fonts.notoSansKRMedium} className={classes.desc}>
          {state.name}, {state.phone}
        </Text>
      </Flex>

      <Button
        label="로그인 하러 가기"
        defaultMargin
        onClick={() => {
          history.push(routes.login);
        }}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  desc: {
    color: colors.text,
    marginTop: "20px",
    fontSize: 14,
  },
  close: {
    padding: "20px 13px",
  },
  label: {
    margin: "50px 30px 20px 30px",
    fontSize: "24px",
    color: colors.text,
    lineHeight: 1.5,
  },
  message: {
    color: colors.text,
    fontSize: "30px",
  },
  content: {
    backgroundColor: colors.white,
    margin: "0px 30px 20px 30px",
    padding: "20px",
  },
  email: {
    alignItems: "center",
    marginBottom: 16,
    "&:first-child": {
      marginBottom: 30,
    },
  },
  logo: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    display: "flex",
    width: "20px",
    height: "20px",
    "& img": {
      objectFit: "contain",
      width: "60%",
      height: "60%",
    },
  },
  emailLABEL: {
    color: colors.text,
    fontSize: 14,
    lineHeight: 1,
  },
});
