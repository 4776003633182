import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { numberWithCommas } from "../../libs/utils";

export default function AssetItem({
  state,
  onCancel,
  up,
  type,

  amount = 0,
  assetType = "",
  averagePrice = 0,
  earningsRate = 0,
  lastPrice = 0,
  price = 0,
  totalPrice = 0,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();

  const korName =
    assetType === "GOLD" ? "금" : assetType === "SILVER" ? "은" : "";

  const diffPrice = price - lastPrice;
  const diffPer = +((diffPrice / price) * 100).toFixed(2);
  const diff = diffPrice > 0 ? "up" : diffPrice < 0 ? "down" : "normal";

  const textColor = {
    normal: { color: colors.text },
    up: { color: colors.textRed },
    down: { color: colors.textBlue },
  };

  return (
    <Flex className={classes.root + " shadow"}>
      <Flex row className={classes.header}>
        <Text
          style={{ color: "#000" }}
          font={fonts.notoSansKRMedium}
          className={classes.method}
        >
          {`${korName}(${assetType})`}
        </Text>
        <Text
          style={textColor[diff]}
          className={classes.info}
          font={fonts.notoSansKRMedium}
        >
          {numberWithCommas(price)}원/g
          <Text font={fonts.notoSansKRRegular} style={{ marginLeft: 5 }}>
            {numberWithCommas(diffPer)}% {diff === "up" && "▲"}
            {diff === "down" && "▼"} {numberWithCommas(diffPrice)}
          </Text>
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>보유 {korName}</Text>
        <Text className={classes.value} font={fonts.notoSansKRMedium}>
          {numberWithCommas(amount)}g{" "}
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>평가금액</Text>
        <Text className={classes.value}>{numberWithCommas(totalPrice)}원</Text>
      </Flex>

      <Flex row className={classes.row}>
        <Text className={classes.hint}>수익률</Text>
        <Text
          style={
            earningsRate > 0
              ? textColor["up"]
              : earningsRate < 0
              ? textColor["down"]
              : textColor["normal"]
          }
          className={classes.value}
          font={fonts.notoSansKRMedium}
        >
          {numberWithCommas(earningsRate)}%{/* {up ? "+2.04%" : "-2.04%"} */}
        </Text>
      </Flex>
      <Flex row className={classes.row}>
        <Text className={classes.hint}>평균매수가</Text>
        <Text className={classes.value}>
          {numberWithCommas(averagePrice)}원
        </Text>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    borderRadius: "5px",
    margin: "13px 13px 0px 13px",
    boxSizing: "border-box",
    padding: "15px 12px",
  },

  row: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "6px",
    boxSizing: "border-box",
    padding: "0px 10px",
  },
  hint: {
    color: "#999999",
    fontSize: "14px",
    flex: 1,
  },
  value: {
    flex: 1,
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
  },
  header: {
    alignItems: "center",
    margin: "0px 10px",
    marginBottom: "10px",
    boxSizing: "border-box",
    borderBottom: "1px solid #dddddd",
    padding: "16px 0px",
    paddingTop: "0px",
    justifyContent: "space-between",
  },
  date: { color: colors.gray, fontSize: "13px" },
  method: {
    color: colors.textRed,
    flex: 1,
    fontSize: "16px",
  },
  info: {
    fontSize: "14px",
    "& span": {
      fontSize: "12px",
    },
  },
});
