import { imageActionTypes } from "./ImageActions";

const initMessage = {
  open: false,
  srcs: [],
};

export default function image(state = initMessage, action) {
  switch (action.type) {
    case imageActionTypes.show:
      return {
        ...state,
        ...action,
        open: true,
      };
    case imageActionTypes.hide:
      return {
        ...initMessage,
      };
    default:
      return state;
  }
}
