import { ButtonBase, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Button from "../../components/button/Button";
import TopbarClose from "../../components/topbar-close/TopbarClose";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";

export default function DirectTranscationAdd({}) {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/category1",
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            let d = [];
            res.data.forEach((x) => {
              d.push(...x.category2Depth);
            });
            setData([...d]);
          }
        }
      )
    );
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.topbar}>
        <ButtonBase
          onClick={() => {
            history.goBack();
          }}
          className={classes.back}
        >
          <img src={images.back} />
        </ButtonBase>
        <span className={classes.navi}>1/4</span>
        <ButtonBase
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={images.home} className={classes.home} />
        </ButtonBase>
      </div>
      <div className={classes.navbar}>
        <div />
      </div>

      <div className={classes.content}>
        <span className={classes.title}>
          어떤 제품을
          <br />
          감정하실 건가요?
        </span>
        <span className={classes.sub}>1개의 제품만 선택해 주세요.</span>

        <div className={classes.list}>
          {data.map((x, i) => {
            return (
              <Paper
                onClick={() => {
                  history.replace({
                    pathname: history.location.pathname,
                    state: { category: x },
                  });
                }}
                className={
                  classes.paper +
                  " " +
                  (x.id === state?.category?.id && classes.paper1)
                }
                key={i.toString()}
              >
                <img src={x.imageUrl} />
                <span>{x.name}</span>
              </Paper>
            );
          })}
        </div>
        {state?.category && (
          <span className={classes.place}>
            <span>{state.category.name}</span>를 판매하시겠습니까?
          </span>
        )}
      </div>
      <ButtonBase
        onClick={() => {
          history.push("/directtransactionadd2", { category: state.category });
        }}
        disabled={!state?.category}
        className={classes.button}
      >
        다음
      </ButtonBase>
    </div>
  );
}

const useStyle = makeStyles({
  button: {
    height: 50,
    backgroundColor: "#ff6e0e",
    textAlign: "center",
    color: "#fff",
    fontSize: 13.3,
    fontFamily: fonts.notoSansKRBold,
    "&:disabled": { backgroundColor: "#999999" },
  },
  place: {
    fontSize: 13.3,
    color: "#333333",
    alignSelf: "center",
    "& span": { fontFamily: fonts.notoSansKRBold },
  },
  paper: {
    borderRadius: 3,
    marginRight: 13,
    paddingTop: 23,
    minWidth: 140,
    display: "flex",
    flexDirection: "column",
    marginTop: 50,
    marginBottom: 35,
    cursor: "pointer",
    alignSelf: "stretch",
    alignItems: "center",
    "& span": {
      fontSize: 16,
      color: "#000000",
      marginTop: 26,
      marginBottom: 23,
      fontFamily: fonts.notoSansKRMedium,
    },
    "& img": {
      width: 66,
      height: 66,
      borderRadius: 100,
      objectFit: "cover",
    },
    "&:first-child": { marginLeft: 30 },
  },
  paper1: {
    border: "3px solid #ff6e0e",
  },
  home: {
    width: 20,
    marginRight: 16,
    height: 20,
    objectFit: "contain",
  },
  list: {
    overflowX: "auto",
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    marginTop: 90,
  },
  title: {
    lineHeight: "26px",
    fontSize: 20,
    marginLeft: 30,
    marginRight: 30,
    color: "#333333",
    fontFamily: fonts.notoSansKRBold,
  },
  sub: {
    marginLeft: 30,
    marginRight: 30,
    lineHeight: "26px",
    color: "#666666",
    fontSize: 13,
  },
  root: {
    flex: 1,
    backgroundColor: "rgb(241,241,241)",
    flexDirection: "column",
    display: "flex",
  },
  navbar: {
    height: 3,
    backgroundColor: "#dddddd",
    alignSelf: "stretch",
    "& div": {
      height: "100%",
      backgroundColor: "#ff6e0e",
      width: "25%",
      marginLeft: 0,
    },
    position: "fixed",
    right: 0,
    left: 0,
    top: 60,
  },
  topbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    display: "flex",
    alignItems: "center",
  },
  back: {
    "& img": {
      width: 10,
      height: 20,
      objectFit: "contain",
    },
    paddingLeft: 14,
    paddingRight: 11,
  },
  navi: {
    fontSize: 16,
    flex: 1,
    color: "#333333",
  },
});
