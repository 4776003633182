import { InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function InputShadow({
  value,
  required,
  unit,
  className,
  onChange,
  number,
  disabled,
  disabledColor,
  error,
  placeholder,
  message,
  label,
  integerOnly,
  password,
}) {
  const classes = useStyle();
  return (
    <Flex className={`${classes.container} ${className}`}>
      {label && (
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {label}
          {required && <Text className={classes.labelRequired}>(필수)</Text>}
        </Text>
      )}
      <Flex className={classes.input + "  shadow"} row>
        <InputBase
          inputProps={
            integerOnly
              ? {
                  pattern: "[0-9]{10}",
                  min: "0",
                  step: "1",
                }
              : {}
          }
          type={password ? "password" : number ? "number" : "text"}
          disabled={disabled}
          value={value}
          onKeyDown={(e) => {
            if (number && e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (onChange) {
              let value = e.target.value;
              if (number) {
                if (value && isNaN(value.replace(/\./g, ""))) {
                  return;
                }
              }
              onChange(value);
            }
          }}
          className={`${classes.root} ${
            disabledColor && disabled && classes.disabled
          }`}
          placeholder={placeholder}
        />
        {unit && <Text className={classes.unit}>{unit}</Text>}
      </Flex>
      {Boolean(error) && (
        <Text className={classes.error}>
          {error.split("").map((x, i) => {
            if (x === "\n") {
              return <br key={i.toString()} />;
            } else {
              return x;
            }
          })}
        </Text>
      )}
      {message && (
        <Text className={classes.message}>
          {message.split("").map((x, i) => {
            if (x === "\n") {
              return <br key={i.toString()} />;
            } else {
              return x;
            }
          })}
        </Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    flex: 1,
    fontFamily: fonts.notoSansKRRegular,
    padding: "12px 30px",
  },
  input: {
    alignItems: "center",
    fontFamily: fonts.notoSansKRRegular,
    backgroundColor: colors.white,
    borderRadius: "3px",
  },
  label: {
    color: colors.text,
    fontSize: 14,
    marginBottom: 10,
  },
  container: {},
  labelRequired: {
    color: colors.gray,
  },
  unit: { fontSize: 14, color: colors.text, marginRight: "10px" },
  message: {
    marginTop: "10px",
    whiteSpace: "nowrap",
    fontSize: "11px",
    lineHeight: 1.5,
    color: colors.text,
  },
  error: {
    marginTop: "10px",
    whiteSpace: "nowrap",
    fontSize: "11px",
    lineHeight: 1.5,
    color: colors.red,
  },
  disabled: {
    color: colors.text,
    "& input": {
      color: colors.text,
    },
  },
});
