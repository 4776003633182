import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { toastShow } from "../../redux/toast/ToastActions";
import DaumPostcode from "react-daum-postcode";
import * as APIS from "../../libs/apis";

export default function SetAddress({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { mainAddress = {} } = location?.state || {};

  const {
    id = "",
    postCode: originPostCode = "",
    address: originAddress = "",
    addressDetail: originAddressDetail = "",
  } = mainAddress;
  const [postCode, setPostCode] = useState(originPostCode);
  const [address, setAddress] = useState(originAddress);
  const [addressDetail, setAddressDetail] = useState(originAddressDetail);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const handleClickFind = () => {
    setIsOpenPost(true);
  };

  const handlePostComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setPostCode(data.zonecode);
    setAddress(fullAddress);
    setAddressDetail("");
    setIsOpenPost(false);
  };

  const handleClickSave = () => {
    if (!addressDetail) {
      dispatch(toastShow("상세 주소를 입력해주세요."));
    } else {
      if (id) {
        APIS.modifyUserAddress(id, {
          isDefault: true,
          postCode,
          address,
          addressDetail,
        }).then(() => {
          requestCallback();
        });
      } else {
        APIS.addUserAddress({
          isDefault: true,
          postCode,
          address,
          addressDetail,
        }).then(() => {
          requestCallback();
        });
      }
    }
  };

  const requestCallback = () => {
    dispatch(toastShow("주소지가 변경 되었습니다."));
    history.goBack();
  };

  return (
    <BasicLayout stackTopbar={{ title: "주소지 변경", border: true }}>
      {isOpenPost ? (
        <DaumPostcode
          style={{ display: "flex", flex: "auto" }}
          autoClose
          submitMode={false}
          onComplete={handlePostComplete}
        />
      ) : (
        <>
          <ButtonBase onClick={handleClickFind} className={classes.button}>
            주소검색
          </ButtonBase>
          <Text className={classes.address}>
            {address && (
              <>
                {postCode}
                <br />
                {address}
              </>
            )}
          </Text>
          <InputBase
            className={classes.addressDetail}
            placeholder="상세주소를 입력하세요."
            value={addressDetail}
            onChange={({ target: { value } }) => {
              setAddressDetail(value);
            }}
            onClick={() => {
              !address && handleClickFind();
            }}
          />

          {address && (
            <Button
              onClick={handleClickSave}
              label="저장"
              className={classes.save}
            />
          )}
        </>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  button: {
    margin: "40px 30px 0px 30px",
    backgroundColor: colors.primary,
    color: "#fff",
    alignSelf: "flex-start",
    fontFamily: fonts.notoSansKRBold,
    fontSize: "14px",
    lineHeight: 1,
    borderRadius: "20px",
    padding: "10px 20px",
  },
  address: {
    color: "#333",
    fontSize: "14px",
    margin: "17px 30px 0px 30px",
  },
  addressDetail: {
    padding: "10px 0px",
    lineHeight: 1,
    margin: "0px 30px",
    borderBottom: "2px solid #333",
    fontSize: "14px",
    color: "#333",
  },
  save: {
    margin: "30px",
  },
});
