import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionShow } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";

const ManageAccount = ({ navigate }) => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [depositAccount, setDepositAccount] = useState({});
  const [withdrawAccount, setWithdrawAccount] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    dispatch(loadingStart);
    Promise.all([
      APIS.getAccountInfo("DEPOSIT").then(
        ({ data: { success, data = {}, message } }) => {
          success && data && setDepositAccount(data);
        }
      ),
      APIS.getAccountInfo("WITHDRAW").then(
        ({ data: { success, data = {}, message } }) => {
          success && data && setWithdrawAccount(data);
        }
      ),
    ]).finally(() => {
      setIsLoaded(true);
      dispatch(loadingEnd);
    });
  };

  const hasDepositAccount = !!depositAccount?.bankAccountNumber;
  const hasWithdrawAccount = withdrawAccount?.bankAccountStatus === "COMPLETED";

  // 입금 계좌 클릭 이벤트
  const handleClickDeposit = () => {
    const navigateManage = () => {
      if (hasDepositAccount) {
        history.push(routes.authBankAccount, {
          purpose: "UPDATE",
          bankType: "DEPOSIT",
        });
      } else {
        history.push(routes.authBankAccount, {
          purpose: "NEW",
          bankType: "DEPOSIT",
        });
      }
    };

    if (depositAccount?.bankAccountStatus === "REQUEST") {
      dispatch(messageError("계좌 등록 처리 중 입니다."));
    } else {
      if (hasWithdrawAccount) {
        dispatch(
          actionShow(
            "등록된 출금계좌가 있습니다.\n\n이미 등록하신 출금계좌를 입금계좌로 사용하시겠습니까?",
            ["신규 등록", "출금계좌 사용"],
            [navigateManage, requestDepositFromWithdraw],
            "",
            true
          )
        );
      } else {
        navigateManage();
      }
    }
  };

  const requestDepositFromWithdraw = () => {
    dispatch(loadingStart);
    APIS.postDepositFromWithdraw()
      .then(({ data: { success, message } }) => {
        if (success) {
          pendingCompleteAccount(1);
        } else {
          dispatch(messageError(message));
          dispatch(loadingEnd);
        }
      })
      .catch(() => dispatch(loadingEnd));
  };

  const pendingCompleteAccount = (count) => {
    dispatch(loadingStart);
    setTimeout(() => {
      APIS.getAccountInfo("DEPOSIT").then(
        ({ data: { success, data, message } }) => {
          if (success) {
            const { bankAccountStatus } = data || {};
            if (bankAccountStatus === "COMPLETED") {
              dispatch(messageShow("계좌 등록이 완료되었습니다.", init));
              dispatch(loadingEnd);
            } else if (bankAccountStatus === "REQUEST") {
              if (count === 10) {
                dispatch(messageShow("계좌 등록 처리 중 입니다.", init));
                dispatch(loadingEnd);
              } else {
                pendingCompleteAccount(count + 1);
              }
            } else {
              dispatch(messageShow("계좌 등록에 실패하였습니다."));
              dispatch(loadingEnd);
            }
          } else {
            dispatch(messageShow(message));
            dispatch(loadingEnd);
          }
        }
      );
    }, 1000);
  };

  // 출금 계좌 클릭 이벤트
  const handleClickWithdraw = () => {
    const navigateManage = () => {
      if (hasWithdrawAccount) {
        history.push(routes.authBankAccount, {
          purpose: "UPDATE",
          bankType: "WITHDRAW",
        });
      } else {
        history.push(routes.authBankAccount, {
          purpose: "NEW",
          bankType: "WITHDRAW",
        });
      }
    };

    // 입금계좌 있을 경우
    if (depositAccount?.bankAccountStatus === "COMPLETED") {
      dispatch(
        actionShow(
          "등록된 입금계좌가 있습니다.\n\n이미 등록하신 입금계좌를 출금계좌로 사용하시겠습니까?",
          ["신규 등록", "입금계좌 사용"],
          [navigateManage, requestWithdrawFromDeposit],
          "",
          true
        )
      );
    } else {
      navigateManage();
    }
  };

  const requestWithdrawFromDeposit = () => {
    dispatch(loadingStart);
    APIS.postWithdrawFromDeposit()
      .then(({ data: { success, message } }) => {
        if (success) {
          dispatch(messageShow("계좌 등록이 완료되었습니다.", init));
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <BasicLayout stackTopbar={{ title: "입출금 계좌 관리", border: true }}>
      {isLoaded ? (
        <div className="kb_bank_wrap">
          <div className="kb_bank_deposit">
            <h3>입금 계좌</h3>

            {hasDepositAccount ? (
              <div
                className="kb_bank_account bdbox bt20"
                onClick={handleClickDeposit}
              >
                <h3>{depositAccount?.bankName}</h3>
                <p>{depositAccount?.bankAccountNumber}</p>
              </div>
            ) : (
              <div
                className={`bt20 ${classes.btn}`}
                onClick={handleClickDeposit}
              >
                <span>+ 입금 계좌 등록하기</span>
              </div>
            )}

            <h3>출금 계좌</h3>
            {hasWithdrawAccount ? (
              <div
                className="kb_bank_account bdbox"
                onClick={handleClickWithdraw}
              >
                <h3>{withdrawAccount?.bankName}</h3>
                <p>{withdrawAccount?.bankAccountNumber}</p>
              </div>
            ) : (
              <div className={`${classes.btn}`} onClick={handleClickWithdraw}>
                <span>+ 출금 계좌 등록하기</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </BasicLayout>
  );
};

const useStyle = makeStyles({
  btn: {
    display: "flex",
    height: 48,
    background: colors.primary,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,

    "& span": {
      color: "white",
      fontFamily: fonts.notoSansKRBold,
    },
  },
});

export default ManageAccount;
