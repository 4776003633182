import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";

export default function ButtonRadio({ data, className, value, onChange }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex className={classes.root + " " + className} row>
      {data.map((x, i) => {
        return (
          <ButtonBase
            onClick={() => {
              onChange(x.value);
            }}
            className={`${classes.item} ${value === x.value && classes.sel}`}
            key={i.toString()}
          >
            {x.label}
          </ButtonBase>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignSelf: "stretch",
    alignItems: "center",
  },
  item: {
    flex: 1,
    textAlign: "center",
    lineHeight: 1,
    fontSize: "14px",
    color: "#999",
    padding: "10px",
    fontFamily: fonts.notoSansKRMedium,
    backgroundColor: "#ddd",
  },
  sel: {
    color: colors.white,
    backgroundColor: colors.primary,
  },
});
