import axios from "axios";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import { postRequest } from "../../network";
import { messageError } from "../message/MessageActions";
import * as APIS from "../../libs/apis";

export const userActionType = {
  init: "user/init",
  update: "user/update",
};

export const userUpdate = (user) => (dispatch) => {
  dispatch({ type: userActionType.update, user });
};

export const userSignIn = () => async (dispatch) => {
  postRequest(consts.apiBaseUrl + "/users/signin", {})
    .then(({ token }) => {
      localStorage.setItem(consts.token, token);
      dispatch(userInit);
    })
    .catch((e) => {
      alert(e.message || e);
    });
};
export const userSignOut = async (dispatch) => {
  localStorage.clear(consts.accessToken);
  dispatch({ type: userActionType.init });
};
export const userInit = async (dispatch) => {
  const token = localStorage.getItem(consts.accessToken);
  if (token) {
    axios
      .get(consts.apiBaseUrl + "/api/authenticate?token=" + token)
      .then((res) => {
        const data = res.data;
        if (data.type === "ADMIN") {
          localStorage.clear(consts.accessToken);
          dispatch(messageError("관리자는 로그인 불가능합니다."));
          return;
        }
        dispatch({
          type: userActionType.update,
          user: { ...data, signed: true },
        });
      })
      .catch((e) => {
        localStorage.clear(consts.accessToken);
        dispatch(
          messageError("토큰 만료되었습니다. 다시 로그인 해주세요.", () => {
            window.location.replace(routes.login);
          })
        );
      });
  }
};

export const userRefresh = (dispatch) => {
  APIS.getUserInfo().then(({ data: { data, success } }) => {
    if (success) {
      //   public class UserInfoResponseDto {
      //     private Long id; // 사용자 ID
      //     private LocalDateTime createdAt; // 가입일
      //     private String name; // 이름
      //     private String sns; // 로그인한 sns
      //     private Integer age; // 나이
      //     private Integer gender; // 성별
      //     private String email; // 이메일
      //     private String phone; // 전화번호
      //     private String bankName; // 은행 이름
      //     private String bankAccountNumber; // 은행 계좌 번호
      //     private String virtualAccountName; // 가상 계좌 은행
      //     private String virtualAccountAccountNumber; // 가상 계좌 번호
      //     private String postCode; // 우편번호
      //     private String address; // 주소
      //     private String addressDetail; // 주소 상세
      //     private UserStatus status; // 계정 상태
      //     private String recommendShopName; // 추천 매장 이름
      //     private Long recommendShopId; // 추천 매장 ID
      //     private BigDecimal gold; // GOLD 보유량
      //     private BigDecimal silver; // SILVER 보유량
      //     private BigDecimal krw; // KRW 보유량
      // }
     

      dispatch(userUpdate(data));
    } else {
      dispatch({ type: userActionType.init });
    }
  });
};
