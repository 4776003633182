import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Image from "../image/Image";
export default function Rating({
  className,
  editMode,
  onChange,
  size = 16,
  rating = 2,
}) {
  const classes = useStyle();

  const handleClick = (value) => (e) => {
    onChange(value);
  };

  return (
    <Flex row className={classes.root + " " + className}>
      {[1, 2, 3, 4, 5].map((x, i) => {
        return (
          <ButtonBase
            disabled={!editMode}
            onClick={handleClick(x)}
            key={i.toString()}
          >
            <Image
              size={size}
              src={rating >= x ? images.star_sel : images.star}
              className={classes.rating}
            />
          </ButtonBase>
        );
      })}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    alignItems: "center",
    alignSelf: "flex-start",
  },
  rating: { marginRight: 4 },
});
