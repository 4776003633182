import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { numberWithCommas } from "../../libs/utils";

const QuoteChart = ({ data = [] }) => {
  return data.length ? (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data} className="spoqa">
        <CartesianGrid strokeOpacity={0.5} stroke="#CCCCCC" />
        <XAxis
          dataKey="date"
          tickFormatter={(tick) => (tick.substring && tick.substring(5)) || ""}
          tick={{ fontSize: 12, fill: "#666666" }}
          height={20}
          stroke="#CCCCCC"
          tickLine={false}
          //   style={{ fontFamily: "spoqa" }}
        />
        <YAxis
          dataKey="price"
          type="number"
          domain={["dataMin", "dataMax"]}
          tickFormatter={(tick = "") => numberWithCommas(tick)}
          orientation="right"
          tick={{ fontSize: 12, fill: "#666666" }}
          tickLine={false}
          width={50}
          stroke="#CCCCCC"
          //   style={{ fontFamily: "spoqa" }}
        />
        <Tooltip
          labelClassName="spoqa"
          formatter={(value) => (
            <>
              <span className="spoqa">{numberWithCommas(value)}</span>원
            </>
          )}
          cursor={{ stroke: "#E32626", opacity: 0.6 }}
        />
        <Line
          type="monotone"
          dataKey="price"
          stroke="#E32626"
          name="종가"
          dot={false}
          strokeWidth={2}
          activeDot={{ stroke: "#ffffff", strokeWidth: 1 }}
        />
      </LineChart>
    </ResponsiveContainer>
  ) : (
    <></>
  );
};

export default QuoteChart;
