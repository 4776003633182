import React from "react";
import { numberWithCommas } from "../../libs/utils";

const FormatAmount = ({ amount, unit }) => {
  return (
    <>
      {numberWithCommas(amount)
        .split("")
        .map((c, i) => {
          return (
            <td key={i.toString()} className="spoqa">
              {c}
            </td>
          );
        })}
      {!!unit && <td>{unit}</td>}
    </>
  );
};

export default FormatAmount;
