import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Notification from "../../components/notification/Notification";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import { toastShow } from "../../redux/toast/ToastActions";
import { signOut } from "../../libs/sign";
import { requestNativeLogin } from "../../libs/utils";

export default function Topbar({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { signed } = useSelector((s) => s.user);

  const {
    role: { LEVEL },
  } = useSelector((s) => s.auth);

  const isSigned = signed || LEVEL;
  return (
    <Flex row className={classes.root}>
      <Text className={classes.text}>메뉴</Text>

      <Flex row style={{ alignItems: "center" }}>
        <ButtonBase
          onClick={() => {
            if (isSigned) {
              APIS.postSignOut();
              signOut();
            } else {
              requestNativeLogin(history);
            }
          }}
          font={fonts.notoSansKRLight}
          className={classes.login}
        >
          {isSigned ? "로그아웃" : "로그인"}
        </ButtonBase>
        {/* {!!isSigned && <Notification className={classes.icon} black />} */}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: "#fff",
    zIndex: 10,
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    padding: "0px 16px",
    borderBottom: "1px solid #eeeeee",
  },
  text: {
    color: colors.text,
    fontSize: "16px",
  },
  login: {
    color: colors.text,
    fontSize: "16px",
  },
  icon: {
    marginLeft: "20px",
    marginRight: "16px",
  },
});
