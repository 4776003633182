import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Button from "../../components/button/Button";
import RNHeader from "../../components/common/RNHeader";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import useCryptoKeyboard from "../../hooks/useCryptoKeyboard";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { getMobileOperatingSystem } from "../../libs/utils";
import { postRequest } from "../../network";
import { messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { userInit, userUpdate } from "../../redux/user/UserActions";
import ic_close from "../../assets/images/ic_close.png";
import ic_del from "../../assets/images/ic_del.png";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import { saveSignupStep } from "../../libs/sign";

export default function AuthRegisterPassword({}) {
  const classes = useStyle();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    purpose = "SIGNUP",
    requestId,
    termsTypes,
    recommandShop,
    email,
  } = useSelector((s) => s.signUp);

  const isUpdate = purpose === "UPDATE_SECURE_PASSWORD";

  const [secured, secureActions] = useCryptoKeyboard();
  const [keyArr, setKeyArr] = useState([]);
  const [prevPassword, setPrevPassword] = useState("");

  useLayoutEffect(() => {
    shuffle();
  }, []);

  useEffect(() => {
    if (secured.count === 6 && !prevPassword) {
      setPrevPassword(secured.last);
      secureActions.clearAll();
      shuffle();
    }
  }, [secured.count]);
  
  useEffect(() => {
    if(purpose === 'SIGNUP') saveSignupStep('FIN_NUMBER')
  },[])


  const shuffle = () => {
    const newArr = Array.from({ length: 10 }, (_, i) => i).sort(
      () => Math.random() - 0.5
    );

    setKeyArr(Array.from({ length: 4 }).map(() => newArr.splice(0, 3)));
  };

  const handleClickRegister = () => {
    //twoFactor
    if (secured.last === prevPassword) {
      dispatch(loadingStart);

      APIS.postSelfVerificationComplete({
        requestId,
        termsTypes,
        recommandShopId: recommandShop.id,
        securePassword: secured.result,
        email,
      })
        .then(({ data: { success, data, message: errorMessage } }) => {
          if (success) {
            const { code, name } = data;

            const message = isUpdate
              ? "결제 비밀번호 변경이 완료되었습니다."
              : "인증절차가 완료되었습니다.";

            dispatch(
              messageShow(message, () => {
                history.push(
                  `${routes.authGateway}?code=${code}&name=${name}&type=SELF_VERIFICATION_COMPLETED&purpose=${purpose}`
                );
              })
            );
          } else {
            dispatch(
              messageShow(errorMessage, () => {
                setPrevPassword("");
                secureActions.clearAll();
                shuffle();
              })
            );
          }
        })
        .finally(() => dispatch(loadingEnd));
    } else {
      dispatch(
        messageShow(
          "결제 비밀번호가 일치하지 않습니다.\n다시 입력해주세요.",
          () => {
            setPrevPassword("");
            secureActions.clearAll();
            shuffle();
          }
        )
      );
    }
  };

  return (
    <>
      {isUpdate && (
        <RNHeader
          visible={false}
          goBack="CUSTOM"
          onGoBack={() => {
            history.push(routes.main);
          }}
        />
      )}
      <div className="password_wrap" style={{ flex: 1 }}>
        <div className="password_view_wrap" style={{ flex: 1 }}>
          <div className="password_view">
            <img
              src={ic_close}
              onClick={() => {
                history.push(routes.main);
              }}
            />
            <p>
              {!prevPassword
                ? !isUpdate
                  ? "결제 비밀번호 등록"
                  : "결제 비밀번호 변경"
                : "결제 비밀번호 재입력"}
            </p>
            <p className="sub">
              {!prevPassword ? (
                <>
                  안전한 결제를 위해
                  <br />
                  결제비밀번호 6자리를 등록하세요.
                </>
              ) : (
                "한번 더 입력해 주세요."
              )}
            </p>
            <div className="password_num">
              <ul>
                {Array.from({ length: 6 }).map((_, i) => (
                  <li
                    className={secured.count > i ? "on" : ""}
                    key={i.toString()}
                  />
                ))}
              </ul>
            </div>
            <button
              className={`password_btn ${
                prevPassword && secured.count === 6 ? "on" : ""
              }`}
              onClick={() => {
                if (prevPassword && secured.count === 6) {
                  handleClickRegister();
                }
              }}
            >
              {isUpdate ? "변경하기" : "등록하기"}
            </button>
          </div>
        </div>
        <div className="password_key_wrap" style={{ flex: 0.37 }}>
          <div className="password_key">
            {keyArr.map((row, i) => {
              return (
                <ul key={i.toString()}>
                  {i === 3 && (
                    <li
                      className="ft15"
                      onClick={() => {
                        secureActions.clearAll();
                      }}
                    >
                      전체 삭제
                    </li>
                  )}

                  {row.map((key, j) => {
                    return (
                      <li
                        key={j.toString()}
                        onClick={() => {
                          secureActions.pressKey(key);
                        }}
                      >
                        {key}
                      </li>
                    );
                  })}

                  {i === 3 && (
                    <li
                      onClick={() => {
                        secureActions.removeOne();
                      }}
                    >
                      <img src={ic_del} alt="삭제" />
                    </li>
                  )}
                </ul>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

const useStyle = makeStyles({
  text: {
    fontSize: 24,
    color: colors.text,
    margin: "100px 30px 0px 30px",
  },
  message: {
    fontSize: 14,
    color: colors.gray,
    margin: "6px 30px 0px 30px",
  },
  button: {
    margin: "50px 30px 0px 30px",
  },
  input: {
    border: "none",
    backgroundColor: "transparent",
    padding: "10px 0px",
    marginRight: "5px",
    textAlign: "center",
    fontSize: 18,
    color: colors.text,
    borderBottom: "3px solid " + colors.text,
    width: "40px",
  },
  inputs: {
    margin: "50px 30px 0px 30px",
  },
});
