import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Dots from "../../components/dots/Dots";
import Flex from "../../components/flex/Flex";
import Image from "../../components/image/Image";
import Pager from "../../components/pager/Pager";
import colors from "../../libs/colors";
import images from "../../libs/images";
import { imageViewerShow } from "../../redux/image/ImageActions";
import consts from "../../libs/consts";

export default function ProductImages({ imagesData }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const pagerRef = useRef();
  const [viewer, setViewer] = useState(0);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(imagesData.imageIds);
    setViewer(1);
  }, [imagesData]);
  return (
    <>
      {viewer === 1 && (
        <Flex className={classes.root}>
          <Pager
            ref={pagerRef}
            className={classes.pager}
            onPageChange={setIndex}
          >
            {data.map((x, i) => {
             
              return (
                <Flex className={classes.item} key={i.toString()}>
                  <ButtonBase
                    onClick={() => {
                      dispatch(
                        imageViewerShow([
                          consts.s3BaseUrl + "/" + x.path,
                          consts.s3BaseUrl + "/" + x.path,
                          consts.s3BaseUrl + "/" + x.path,
                          consts.s3BaseUrl + "/" + x.path,
                        ])
                      );
                    }}
                    style={{ height: "100%" }}
                  >
                    <Image
                      src={consts.s3BaseUrl + "/" + x.path}
                      alt={i}
                      width="100%"
                      height={200}
                      objectFit="contain"
                    />
                  </ButtonBase>
                </Flex>
              );
            })}
          </Pager>
          <Dots
            color={colors.primary}
            index={index}
            length={data.length}
            className={classes.dots}
          />
        </Flex>
      )}
    </>
  );
}

const useStyle = makeStyles({
  root: {
    position: "relative",
    boxSizing: "border-box",
    minHeight: "200px",
    backgroundColor: "#eee",
  },
  dots: {
    zIndex: 3,
    position: "absolute",
    bottom: "10%",
  },
  item: {
    alignItems: "center",
    justifyContent: "center",
  },
});
