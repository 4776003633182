import React from "react";
import { ButtonBase, makeStyles } from "@material-ui/core";

import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Radio from "../radio/Radio";
import Text from "../text/Text";

export default function ShopItemSelect({
  onClick,
  list,
  shopName,
  address,
  shopId,
  addressDetail,
  shopPhone,
  distance,
  onCheckedChange,
  checked,
  noRadio,
  shopType,
}) {
  const classes = useStyle();
  const fontWeight = shopType === "HEAD_OFFICE" ? "bold" : "normal";
  return (
    <Flex
      className={`${classes.root} ${checked ? classes.checked : ""}`}
      style={{ ...(list && {  }) }}
      row>
      <Radio
        // className={classes.radio}
        value={checked}
        onChange={(e) => {
          onCheckedChange(e);
        }}
        color={"#FE6E0C"}
      />
      <ButtonBase
        disabled={!Boolean(onClick)}
        className={classes.button}
        onClick={onClick}>
        <Flex
          row
          style={{
            alignItems: "center",
          }}>
          <Text
            font={fonts.notoSansKRMedium}
            className={classes.label}
            style={{ fontWeight }}>
            {shopName}
          </Text>
          {!!distance && (
            <Flex row style={{ alignItems: "center" }}>
              <img src={images.location} className={classes.li} />
              <Text className={classes.distance}>{parseFloat(distance)}km</Text>
            </Flex>
          )}
        </Flex>
        <Text className={classes.address} style={{ fontWeight }}>
          {address}
        </Text>
      </ButtonBase>
      <ButtonBase
        onClick={() => {
          if (shopPhone) {
            window.location.href = "tel:" + shopPhone;
          } else {
            alert("매장 전화번호가 등록되지 않았습니다.");
          }
        }}>
        <img src={images.call} className={classes.ci} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  radio: {
    paddingRight: 10,
    alignSelf: "stretch",
    alignItems: "flex-start",
    marginTop: 3,
  },
  checked: {
    borderRadius: 8,
    border: "1px solid #FE6E0C",
  },
  root: {
    margin: "5px 3px",
    alignItems: "center",
    padding: 8
  },
  label: {
    marginRight: 10,
    color: "#333",
    lineHeight: 1,
    textAlign: "left",
    fontSize: "17px",
  },
  distance: {
    color: "#333",
    lineHeight: 1,
    fontSize: "12px",
  },
  address: {
    fontSize: "14px",
    color: colors.text,
    marginTop: "5px",
    textAlign: "left",
  },
  button: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "stretch",
    wordBreak: "keep-all",
  },
  li: {
    width: "14px",
    height: "14px",
    marginRight: "6px",
    objectFit: "contain",
  },
  ci: {
    width: "26px",
    height: "26px",
    objectFit: "contain",
  },
});
