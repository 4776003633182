import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";
import { getPurityLabel, numFormat, objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import product_default from "../../assets/images/product_default.webp";

import moment from "moment-timezone";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
export default function AppraiseRegistResult({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const [data, setData] = useState();
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const userReducer = useSelector((s) => s.user);

  useEffect(() => {
    APIS.getCurrentMarketPrice("GOLD").then(({ data: { data } }) => {
      setGoldGramPrice(parseFloat(data));
    });
    APIS.getCurrentMarketPrice("SILVER").then(({ data: { data } }) => {
      setSilverGramPrice(parseFloat(data));
    });
  });

  useEffect(() => {
    if (state.id) {
      dispatch(loadingStart);

      APIS.getAppraisalSimplesDetail(state.id)
        .then(({ data: { data } }) => {
          setData({
            ...data,
            assetType: data.appraisalProduct.assetType,
            calc: data.simpleGoldWeightGram,
          });
        })
        .catch((err) => dispatch(messageError(err)))
        .finally(() => dispatch(loadingEnd));
    }
  }, [state.id]);

  const handleExchange = () => {
    history.push(routes.main);
    history.push(routes.mypage, {
      tab: "HISTORY",
      division: "ING",
      expend: [`SIMPLE_${data.assetType}`],
    });
  };

  if (!state.id) {
    return <Redirect to={routes.appraiseRegistMain} />;
  }
  if (!data) {
    return null;
  }

  let korText = "";
  let price = 0;
  if (data.assetType === "GOLD") {
    korText = "금";
    price = goldGramPrice;
  } else if (data.assetType === "SILVER") {
    korText = "은";
    price = silverGramPrice;
  }
  const productImageUrl =
    data.topImageUrl ||
    data.frontImageUrl ||
    data.leftSideImageUrl ||
    data.rightSideImageUrl;

  const imageUrl = productImageUrl
    ? `${consts.s3BaseUrl}/${productImageUrl}`
    : product_default;

  return (
    <Flex>
      <Text className={classes.title} font={fonts.notoSansKRBold}>
        간편감정 결과입니다.
      </Text>
      <Flex row className={classes.content}>
        <div
          className={"shadow"}
          style={{ width: "80px", height: "80px", borderRadius: 8 }}>
          <img
            src={imageUrl}
            style={{
              width: "100%",
              objectFit: "cover",
              height: "100%",
            }}
          />
        </div>
        <Flex style={{ flex: 1 }}>
          <Flex row className={classes.labelWrap}>
            <Text>제품</Text>
            <Text font={fonts.notoSansKRMedium}>
              {data.appraisalProduct.name}
            </Text>
          </Flex>
          <Flex row className={classes.labelWrap}>
            <Text>순도</Text>
            <Text>{getPurityLabel(data.purity, data.assetType)}</Text>
          </Flex>
          <Flex row className={classes.labelWrap}>
            <Text>수량</Text>
            <Text>{numFormat(data.quantity)}개</Text>
          </Flex>
          <Flex row className={classes.labelWrap}>
            <Text>보증서</Text>
            <Text>{data.guaranteeImageUrl ? "있음" : "없음"}</Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex className={classes.card + " shadow"}>
        <Text className={classes.l1}>
          {korText}({data.assetType}) 예상 교환량
        </Text>
        <Text className={classes.l2}>
          <Text font={fonts.notoSansKRMedium}>{data.calc}g</Text> (=
          {numFormat(Math.floor(price * data.calc))}
          원)
        </Text>
        <Text className={classes.l3}>
          {moment().format("YYYY.MM.DD")} 현재 {korText}({data.assetType}) 시세
          <Text font={fonts.notoSansKRMedium}>
            {" "}
            ({numFormat(price)}
            원/g)
          </Text>
        </Text>
      </Flex>
      <Text className={classes.desc}>
        <Text font={fonts.notoSansKRMedium}>
          {korText}({data.assetType}) 이란?
        </Text>
        <br />ㆍ{korText}({data.assetType})은 정련을 거쳐 보유하게 되는 실물자산
        입니다.
        <br />ㆍ{korText} 시세의 변동에 따라 {korText}({data.assetType})의
        가치도 변동됩니다.
        <br />ㆍ{korText}({data.assetType})은 금방금방 거래소를 통해 매수,
        매도하실 수 있습니다.
        <br />
        ㆍ자산으로 보유한 {korText}({data.assetType})은 다양한 제품으로
        실물인출이 가능합니다.
      </Text>
      <Text className={classes.desc}>
        <Text font={fonts.notoSansKRMedium}>서비스 유의사항</Text>
        <br />
        ㆍ간편 감정 결과는 고객님이 입력한 정보에 기반한 것입니다.
        <br />ㆍ{korText}({data.assetType})으로 교환 시 전문감정을 실시하며,
        간편감정 결과와 다를 수 있습니다.
        <br />
        ㆍ실시간 시세를 반영한 결과이므로 교환 시 간편감정 결과와 다를 수
        있습니다.
        <br />
        교환이 완료된 경우 반환/취소가 불가능하며 실물인출을 통해 실물로 받을 수
        있습니다.
      </Text>
      <div style={{ height: 100 }} />
      <Flex row className={classes.buttons}>
        <Button
          style={{ backgroundColor: "#999" }}
          onClick={() => {
            history.push(routes.main);
            history.push(routes.appraiseRegist, { step: 1 });
          }}
          label="추가 감정"
        />
        <Button onClick={handleExchange} label={"다음"} />
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  images: {
    margin: "0px 20px",
    marginTop: "20px",
  },
  title: {
    margin: "35px 30px 0px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  desc: {
    color: "#666",
    fontSize: "12px",
    margin: "20px 20px 0px 20px",
    "& span": { fontSize: "16px", color: "#333" },
  },
  message: {
    borderBottom: "1px solid " + colors.primary,
    alignSelf: "flex-start",
    margin: "5px 30px 30px 30px",
    color: colors.primary,
    fontSize: "14px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  item: {
    width: "30%",
    padding: "20px",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    flexDirection: "column",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  buttons: {
    zIndex: 2,
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    borderRadius: "0px",
    width: "100%",
    alignSelf: "stretch",
    "& button": {
      flex: 2,
      borderRadius: "0px",
      "&:first-child": { flex: 1 },
    },
  },
  image: {
    backgroundColor: "#ddd",
    width: 70,
    height: 70,
    borderRadius: "100px",
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "30px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
  card: {
    margin: "10px 20px 20px 20px",
    padding: "15px 18px",
  },

  l1: {
    color: "#666",
    fontSize: "12px",
  },
  l2: {
    color: "#666",
    marginTop: "3px",
    fontSize: "12px",
    "& span": {
      color: "#333",
      fontSize: "20px",
      fontFamily: fonts.notoSansKRMedium,
    },
  },
  l3: {
    color: "#666",
    marginTop: "5px",
    fontSize: "12px",
    "& span": {
      fontFamily: fonts.notoSansKRMedium,
      color: "#333",
    },
  },
  content: {
    margin: "30px",
    alignItems: "center",
  },
  labelWrap: {
    alignSelf: "stretch",
    marginLeft: "10px",
    alignItems: "center",
    "& span": {
      "&:first-child": { flex: 1, color: "#666", fontSize: "12px" },
      "&:last-child": { flex: 4, color: "#333", fontSize: "12px" },
    },
  },
});
