import React, { useState } from "react";
import styled from "styled-components";
import SimpleAccordion from "../../../components/accordion/SimpleAccordion";
import Flex from "../../../components/flex/Flex";
import arrowDown from "../../../assets/images/arrow_down.webp";
import { formatTime } from "../../../libs/utils";
import checkMini from "../../../assets/images/check_mini.webp";

const DeliveryHistory = ({ regiNo, traceList = [] }) => {
  const [expanded, setExpanded] = useState(false);

  const latestTrace = traceList[traceList.length - 1];
  return (
    <AccordionContainer
      title={<AccordionHeader>배송조회</AccordionHeader>}
      expendIcon={<img src={arrowDown} width={44} />}
      expended={true}
    >
      <ContentWrap>
        <RowBetween>
          <InfoTitle>송장번호</InfoTitle>
          <InfoDesc className="spoqa">{regiNo}</InfoDesc>
        </RowBetween>
        <RowBetween>
          <InfoTitle>배송업체</InfoTitle>
          <InfoDesc>우체국 택배</InfoDesc>
        </RowBetween>
        <InnerBox title="배송 상세보기">
          {expanded ? (
            traceList.map((trace, i) => {
              return (
                <DeliveryTrace
                  key={i.toString()}
                  latest={i === traceList.length - 1}
                  {...trace}
                />
              );
            })
          ) : (
            <>
              {latestTrace && <DeliveryTrace latest {...latestTrace} />}

              <ExpandButton
                onClick={() => {
                  setExpanded(true);
                }}
              >
                상세보기
              </ExpandButton>
            </>
          )}
        </InnerBox>
      </ContentWrap>
    </AccordionContainer>
  );
};

const DeliveryTrace = ({ latest, currentPosition, status, createdAt }) => {
  return (
    <DeliveryTraceWrap className={latest && "latest"}>
      <Flex flex={1}>
        <span className="position">{currentPosition}</span>
        <span className="at">{formatTime(createdAt, "YYYY-MM-DD HH:mm")}</span>
      </Flex>
      <span className="status">{status}</span>
    </DeliveryTraceWrap>
  );
};

const AccordionContainer = styled(SimpleAccordion)`
  border: 1px solid #eaeaea;
  border-radius: 8px;
`;

const AccordionHeader = styled(Flex)`
  margin: 16px;
  font-weight: bold;
  color: #333333;
  font-size: 17px;
  letter-spacing: -0.34px;
`;
const ContentWrap = styled(Flex)`
  border-top: 1px solid #eaeaea;
  margin: 0 16px;
  padding: 16px 0;
`;

const RowBetween = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
`;

const InfoTitle = styled.span`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #777777;
  line-height: 24px;
`;
const InfoDesc = styled.span`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #444444;
  line-height: 24px;
`;
const InnerBox = styled(Flex)`
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0 12px;
  padding-bottom: 12px;
  margin-top: 24px;

  &:before {
    content: "${({ title }) => title}";
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.28px;
    color: #333333;
    border-bottom: 1px solid #eaeaea;
  }
`;

const DeliveryTraceWrap = styled(Flex)`
  flex-direction: row;
  margin: 8px 0;

  &:before {
    content: "";
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border: 2px solid #dfdfdf;
    border-radius: 8px;
    margin-right: 4px;
    background-image: url(${checkMini});
    background-size: 8px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.latest {
    &:before {
      background: #ff6e0e;
    }

    .position,
    .status {
      font-weight: bold;
      color: #ff6e0e;
    }
  }

  .position,
  .status {
    font-size: 14px;
    ling-height: 32px;
    letter-spacing: -0.28px;
    color: #444444;
  }

  .at {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
    font-size: 10px;
    color: #999999;
    margin-top: 4px;
  }
`;

const ExpandButton = styled(Flex)`
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaeaea;
  font-size: 12px;
  color: #222222;
`;

export default DeliveryHistory;
