import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import Flex from "../flex/Flex";
import Rating from "../rating/Rating";
import Text from "../text/Text";
import asmpl1 from "../../assets/images/sample1.png";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
export default function ReviewItem({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ButtonBase
      onClick={() => {
        history.push(routes.reviewDetail("3"));
      }}
      className={classes.root}
      row
    >
      <Flex row>
        <img alt="i" className={classes.imge} src={asmpl1} />
        <Flex>
          <Text className={classes.l1} font={fonts.notoSansKRLight}>
            홍○동 님
          </Text>
          <Rating rating={3} className={classes.rating} />
          <Text className={classes.l2}>내용입니다</Text>
        </Flex>
      </Flex>
      <Text className={classes.date}>2020.06.28</Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: {
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
    margin: "0px 13px",
    display: "flex",
    boxSizing: "border-box",
    padding: "14px ",
  },
  date: {
    alignSelf: "flex-start",
    color: "#999",
    fontSize: "11px",
  },
  l1: {
    alignSelf: "flex-start",
    color: colors.gray,
    fontSize: "13px",
  },
  l2: {
    alignSelf: "flex-start",
    fontSize: "13px",
    color: colors.text,
  },
  imge: {
    width: "80px",
    marginRight: "13px",
    height: "80px",
    objectFit: "cover",
    border: "1px solid #eee",
  },
  rating: {
    marginTop: "6px",
    marginBottom: "12px",
  },
});
