import { makeStyles } from "@material-ui/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export default forwardRef(function Pager(
  { onPageChange, className, children },
  ref
) {
  const classes = useStyle();
  const [rootWidth, setRootWidth] = useState(0);
  const rootRef = useRef();
  let scrollEndEvent = useRef(null).current;

  useImperativeHandle(ref, () => ({
    setPage: (page) => {
      const width = rootRef.current.offsetWidth;
      rootRef.current.scrollLeft = width * page;
    },
  }));

  const handlePagerScrollEnd = useCallback(() => {
    if (scrollEndEvent) {
      clearTimeout(scrollEndEvent);
    }
    scrollEndEvent = setTimeout(() => {
      clearTimeout(scrollEndEvent);
      if (!rootRef.current) {
        return;
      }
      const width = rootRef.current.offsetWidth;
      const x = rootRef.current.scrollLeft;
      const curIndex = Math.round(x / width);
      rootRef.current.scrollLeft = width * curIndex;
      if (onPageChange) {
        onPageChange(curIndex);
      }
    }, [100]);
  }, [scrollEndEvent, rootRef.current, onPageChange]);

  useEffect(() => {
    setRootWidth(rootRef.current?.offsetWidth || 0);
  }, [rootRef.current]);

  return (
    <div
      ref={rootRef}
      onScroll={handlePagerScrollEnd}
      className={`${classes.root} ${className ? className : ""}`}
    >
      {React.Children.map(children, (child, index) => {
        const newChild = React.cloneElement(child, {
          ...child.props,
          style: {
            ...child.props.style,
            display: "flex",
            flexDirection: "column",
            width: rootWidth || 0,
            minWidth: rootWidth || 0,
          },
          key: "page-" + index.toString(),
        });
        return newChild;
      })}
    </div>
  );
});

const useStyle = makeStyles({
  root: {
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    boxSizing: "border-box",
    flex: 1,
  },
});
