import styled from "styled-components";
import Flex from "../../../components/flex/Flex";
import { postMessage } from "../../../libs/utils";
import fonts from "../../../libs/fonts";

const DELIVERY_CORP = {
  VALEX: {
    kor: "발렉스 특수물류",
    url: (regiNo) =>
      `https://vnos.valex.co.kr/pages/progressStatus/nonScheduleStts/popup/jobOrderList2/${regiNo}`,
  },
  EPOST: {
    kor: "우체국 택배",
    url: (
      regiNo
    ) => `https://m.epost.go.kr/postal/mobile/mobile.trace.RetrieveDomRigiTraceList.comm?sid1=${regiNo}
`,
  },
};
const InfoKeyValue = ({ children }) => {
  return (
    <Flex
      row
      style={{
        justifyContent: "space-between",
        width: "100%",
        height: "32px",
        padding: "8px 0px",
        alignItems: "center",
        fontSize: "14px",
        color: "#444444",
        fontFamily: `${fonts.notoSansKRMedium}`,
      }}
    >
      {children}
    </Flex>
  );
};

const DeliveryCorpInfo = ({ isVisible, deliveryInfo }) => {
  if (!isVisible) return null;
  const { regiNo, deliveryCorp } = deliveryInfo;

  const handleClick = () => {
    postMessage({
      command: "SET_HEADER",
      param: {
        visible: false,
        goBack: "DEFAULT",
        title: "이벤트",
      },
    });

    window.location.href = DELIVERY_CORP[deliveryCorp].url(regiNo);
  };

  return (
    <InnerBox>
      <Flex style={{ width: "100%" }}>
        <InfoKeyValue>
          <div>송장 번호</div>
          <div>{regiNo}</div>
        </InfoKeyValue>
        <InfoKeyValue>
          <div>배송 업체</div>
          <div>{DELIVERY_CORP[deliveryCorp]?.kor}</div>
        </InfoKeyValue>
      </Flex>
      <Buttons onClick={handleClick}>배송조회</Buttons>
    </InnerBox>
  );
};

export default DeliveryCorpInfo;

const InnerBox = styled(Flex)`
  margin-top: 8px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const Buttons = styled.button`
  border-radius: 40px;
  border: 1px solid #777777;
  background: #fff;
  width: 90px;
  height: 36px;
  padding: 0px 16px;
  text-align: center;
  color: #222222;
  font-size: 14px;
`;
