import { ButtonBase, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import AppraiseItem from "../../components/appraise-item/AppraiseItem";
import AppraiseItemProgress from "../../components/appraise-item/AppraiseItemProgress";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Flex from "../../components/flex/Flex";
import PolicyAgreePopup from "../../components/policy-agree-popup/PolicyAgreePopup";
import BasicLayout from "../../layouts/basic/BasicLayout";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import { messageError } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { WITHDRAW_ACCOUNT_REQUIRED } from "../../libs/messages";

export default function Appraise({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [type, setType] = useState("GOLD");
  const [popup, setPopup] = useState(false);
  const userReducer = useSelector((s) => s.user);
  const state = history.location.state || { tab: 0 };

  //tab
  const { tab } = state;
  const setTab = (v) => {
    history.replace(history.location.pathname, {
      ...state,
      tab: v,
    });
  };

  //data
  const [requestTotal, setRequestTotal] = useState(0);
  const [progressTotal, setProgressTotal] = useState(0);
  const [finishTotal, setFinishTotal] = useState(0);
  const [requests, setRequests] = useState([]);
  const [progresses, setProgresses] = useState([]);
  const [finish, setFinish] = useState([]);
  const selRequets = requests.filter((x) => x.appraisalProduct.type === type);
  const [hasWithdrawAccount, setHasWithdrawAccount] = useState(false);

  const { role } = useSelector((s) => s.auth);

  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  useEffect(() => {
    APIS.getCurrentMarketPrice("GOLD").then(({ data: { data } }) => {
      setGoldGramPrice(data);
    });
    APIS.getCurrentMarketPrice("SILVER").then(({ data: { data } }) => {
      setSilverGramPrice(data);
    });
    APIS.getAccountInfo("WITHDRAW").then(({ data: { data } }) => {
      if (data?.bankAccountStatus === "COMPLETED") {
        setHasWithdrawAccount(true);
      }
    });
  }, []);

  useEffect(() => {
    if (tab === undefined || tab === null) {
      history.replace({
        pathname: history.location.pathname,
        state: {
          ...state,
          tab: 0,
        },
      });
    }
  }, [tab]);

  useEffect(() => {
    if (history.location.state?.type) {
      setType(history.location.state?.type || "SILVER");
    }
  }, [history.location.state?.type]);

  useEffect(() => {
    if (tab === 0) {
      setRequests((r) => r.map((x) => ({ ...x, checked: false })));
    }
  }, [type, tab]);

  useEffect(() => {
    if (!selRequets.length) {
      if (requests.filter((x) => x.appraisalProduct.type === type).length) {
        setType(type === "GOLD" ? "SILVER" : "GOLD");
      }
    }
  }, [requests?.length]);

  const fetchData1 = () => {
    //감정평가 리스트 조회
    // if (!userReducer.id) {
    //   return;
    // }

    APIS.getAppraisalSimples(objToQueryStr({ status: "접수" })).then(
      ({ data }) => {
        const list = data.data;
        setRequestTotal(data.data.length);

        setRequests(
          list.map((x, i) => {
            if (history.location.state?.selectedId === x.id) {
              x.checked = true;
            }
            x.calc = x.simpleGoldWeightGram;
            return x;
          })
        );
      }
    );
  };
  const fetchData2 = () => {
    // if (!userReducer.id) {
    //   return;
    // }

    APIS.getAppraisalRequests(objToQueryStr({ status: "VERIFY_START" })).then(
      ({ data }) => {
        const list = data.data;
        setProgressTotal(data.data.length);
        setProgresses(list);
      }
    );
  };

  const fetchData3 = () => {
    // if (!userReducer.id) {
    //   return;
    // }

    APIS.getAppraisalRequests(objToQueryStr({ status: "VERIFY_COMPLETE" })).then(
      ({ data }) => {
        const list = data.data;
        setFinishTotal(data.data.length);
        setFinish(list);
      }
    );
  };

  useEffect(() => {
    if (tab === 0) {
      fetchData1();
    } else if (tab === 1) {
      fetchData2();
    } else {
      fetchData3();
    }
  }, [tab, userReducer.id]);

  useEffect(() => {
    if (tab === 0) {
      fetchData2();
      fetchData3();
    } else if (tab === 1) {
      fetchData1();
      fetchData3();
    } else {
      fetchData2();
      fetchData1();
    }
  }, [userReducer.id]);

  const handleRequestDelete = (item) => () => {
    dispatch(
      actionShow(
        "선택하신 제품을 삭제하시겠습니까?",
        ["취소", "삭제"],
        [
          null,
          () => {
            APIS.deleteAppraisalSimple(item.id).then(fetchData1);
          },
        ]
      )
    );
  };

  const handleExchange = () => {
    if (!selRequets.filter((x) => x.checked).length) {
      dispatch(toastShow("제품을 선택해 주세요."));
      return;
    }
    if (!hasWithdrawAccount) {
      dispatch(
        messageError(WITHDRAW_ACCOUNT_REQUIRED, () =>
          history.push(routes.manageAccount)
        )
      );
      return;
    }
    setPopup(true);
  };

  return (
    <MainLayout white>
      <BasicLayout
        stackTopbar={{
          title: "감정평가",
          border: true,
          option: (
            <ButtonBase
              onClick={() => {
                history.push(routes.appraiseRegistMain);
              }}
              className={classes.textButton}
            >
              + 간편감정하기
            </ButtonBase>
          ),
        }}
      >
        <Tabs
          value={tab}
          TabIndicatorProps={{ style: { background: "black" } }}
          style={{
            borderBottom: "1px solid #eee",
          }}
          onChange={(e, v) => {
            setTab(v);
          }}
          indicatorColor="primary"
          textColor={colors.text}
          variant="fullWidth"
          className={classes.tabs}
        >
          <Tab color={colors.gray} label={"접수대기 " + requestTotal} />
          <Tab color={colors.gray} label={"진행중 " + progressTotal} />
          <Tab color={colors.gray} label={"완료/취소 " + finishTotal} />
        </Tabs>
        <Flex className={classes.main}>
          {(() => {
            switch (tab) {
              case 0:
                return (
                  <>
                    <Flex row className={"row-between " + classes.header}>
                      <ButtonBase
                        onClick={() => {
                          setType("GOLD");
                        }}
                        className={
                          classes.buttonRadio +
                          " " +
                          (type === "GOLD" && classes.buttonRadioSel)
                        }
                      >
                        금(GOLD)
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => {
                          setType("SILVER");
                        }}
                        className={
                          classes.buttonRadio +
                          " " +
                          (type === "SILVER" && classes.buttonRadioSel)
                        }
                      >
                        은(SILVER)
                      </ButtonBase>
                    </Flex>
                    <Flex row className={"row-between " + classes.header}>
                      <CheckboxCircle
                        labelSize={14}
                        checked={
                          selRequets.length &&
                          selRequets.length ===
                            selRequets.filter((x) => x.checked).length
                        }
                        onCheckedChange={(a) => {
                          setRequests([
                            ...requests.map((x) => ({
                              ...x,
                              checked:
                                x.appraisalProduct.type === type ? a : false,
                            })),
                          ]);
                        }}
                        label="전체선택"
                      />

                      <ButtonBase
                        onClick={handleExchange}
                        className={classes.button}
                      >
                        자산({type === "GOLD" ? "금" : "은"})으로 교환
                      </ButtonBase>
                    </Flex>
                    {selRequets.map((x, i) => {
                      return (
                        <AppraiseItem
                          {...x}
                          calcPrice={Math.floor(
                            x.calc *
                              (x.appraisalProduct.type === "GOLD"
                                ? goldGramPrice
                                : silverGramPrice)
                          )}
                          onDelete={handleRequestDelete(x)}
                          key={type + i.toString()}
                          onCheckedChange={(v) => {
                            setRequests((r) =>
                              r.map((y) => ({
                                ...y,
                                checked: x.id === y.id ? v : y.checked,
                              }))
                            );
                          }}
                        />
                      );
                    })}
                  </>
                );
              case 1:
                return progresses.map((x, i) => {
                  return (
                    <AppraiseItemProgress
                      {...x}
                      key={type + i.toString()}
                      onClick={() => {
                        history.push(routes.appraiseDetail, { id: x.id });
                      }}
                    />
                  );
                });
              default:
                return finish.map((x, i) => {
                  return (
                    <AppraiseItemProgress
                      {...x}
                      key={type + i.toString()}
                      onClick={() => {
                        history.push(routes.appraiseDetail, { id: x.id });
                      }}
                    />
                  );
                });
            }
          })()}
        </Flex>
        {popup && (
          <PolicyAgreePopup
            title="서비스 이용 동의"
            onClose={() => {
              setPopup(false);
            }}
            onAction={() => {
              history.push(routes.exchange, {
                action: "appraisal",
                request: true,
                appraisalSimpleIds: selRequets
                  .filter((x) => x.checked)
                  .map((x) => x.id),
              });
            }}
            data={[
              { label: "서비스 이용약관에 동의합니다." },
              {
                label:
                  "제품에 포함된 각종 보석류 및 세팅 등은 가치를 산정하지 않습니다.",
              },
              {
                label:
                  "상세감정 과정의 특성상 제품에 흠집 또는 손상이 갈 수 있습니다.",
              },
              {
                label:
                  "불법적 획득 제품으로 의심될 경우 상세감정이 거부될 수 있습니다.",
              },

              { label: "상세 감정 결과는 간편 감정 결과와 다를 수 있습니다." },
              {
                label:
                  "상세 감정 결과에 따라 금/은 제품을 보유 금/은(g) 자산으로 교환할 수 있습니다.",
              },
              { label: "교환이 완료된 경우 거래의 취소가 불가합니다." },
              {
                label:
                  "교환이 완료된 제품은 원재료 형태로 정련되어 안전하게 보관됩니다.",
              },
              {
                label:
                  "교환이 완료된 제품은 실물인출 형태로 반환신청을 할 수 있습니다.",
              },

              {
                label:
                  "회원의 서비스 이용 내용은 서비스 고도화를 위한 데이터로 활용될 수 있습니다.",
              },
            ]}
          />
        )}
      </BasicLayout>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },
  textButton: {
    fontSize: "14px",
    paddingRight: "16px",
    color: colors.black,
  },
  main: {
    flexGrow: 1,
    backgroundColor: colors.background,
  },
  header: {
    margin: "20px 13px 0px 13px",
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  buttonRadio: {
    flex: 1,
    backgroundColor: "#dddddd",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
    padding: "10px",
    color: "#999",
  },
  buttonRadioSel: {
    backgroundColor: colors.primary,
    color: "#fff",
  },
});
