import { Divider, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import BasicLayout from "../../layouts/basic/BasicLayout";
import sample1 from "../../assets/images/sample1.png";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Rating from "../../components/rating/Rating";
import ProductDirectTransactionItem from "../../components/product-list/ProductDirectTranscationItem";
import images from "../../libs/images";
export default function ReviewDetail({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  return (
    <BasicLayout
      stackTopbar={{
        title: state?.me ? "리뷰쓰기" : "후기 상세",
        border: true,
      }}
    >
      {state?.me && (
        <Text className={classes.title} font={fonts.notoSansKRMedium}>
          구매해 주셔서 감사합니다.
        </Text>
      )}
      {state?.me ? (
        <>
          <ProductDirectTransactionItem bottom />
          <Divider style={{ height: 3 }} />
        </>
      ) : (
        <Flex row className={classes.header}>
          <img className={classes.img} src={sample1} alt="src" />
          <Flex>
            <Text font={fonts.notoSansKRMedium} className={classes.name}>
              반지
            </Text>
            <Text className={classes.name2}>14K·1개·보증서(유)</Text>
            <Text className={classes.name3}>
              최종 품위
              <Text font={fonts.notoSansKRMedium}>18K</Text>
            </Text>
            <Text className={classes.name3}>
              최종 중량
              <Text font={fonts.notoSansKRMedium}>3.69g</Text>
            </Text>
          </Flex>
        </Flex>
      )}
      {state?.me ? (
        <Text className={classes.info}>2021.02.24 작성됨</Text>
      ) : (
        <Text className={classes.info}>홍0동님 │ 2021.02.24 작성됨</Text>
      )}

      <Rating size={30} className={classes.rating} />

      <Text className={classes.desc}>
        간편하게 이용할 수 있어 좋았습니다.
        <br /> 다음에도 다시 이용하고 싶어요. 간편하게 이용할 수 있어
        좋았습니다.
        <br /> 다음에도 다시 이용하고 싶어요.
      </Text>

      <img alt="d" src={sample1} className={classes.image} />

      {state?.me && (
        <>
          <div style={{ height: "140px" }} />
          <img src={images.simpleinspect2} className={classes.banner} />
        </>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  banner: {
    zIndex: 3,
    width: "100%",
    position: "fixed",
    left: 0,
    right: 0,
    backgroundColor: "#fec729",
    bottom: 0,
  },
  image: {
    width: "calc(100% - 60px)",
    height: "180px",
    marginTop: "20px",
    borderRadius: "3px",
    alignSelf: "center",
  },
  desc: {
    fontSize: "13px",
    color: colors.text,
    margin: "0px 30px",
  },
  info: {
    fontSize: "12px",
    color: colors.text,
    alignSelf: "center",
    marginTop: 21,
    marginBottom: 16,
  },
  rating: {
    alignSelf: "center !important",
    marginBottom: "20px",
  },
  header: {
    borderBottom: "3px solid #eee",
    alignItems: "center",
    padding: "20px 16px",
  },
  img: {
    width: "80px",
    height: "80px",
    marginRight: "16px",
    border: "1px solid #eee",
  },
  name: {
    color: colors.text,
    fontSize: "16px",
  },
  name2: {
    fontSize: "13px",
    marginBottom: "6px",
    color: colors.text,
  },
  name3: {
    color: colors.gray,
    fontSize: "13px",
    "& span": {
      color: colors.text,
      marginLeft: "16px",
    },
  },
  title: { margin: "30px 30px 0px 13px", color: "#333", fontSize: 16 },
});
