import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import BottomModal from "../../../../components/modal/BottomModal";
import Span from "../../../../components/text/Span";
import fonts from "../../../../libs/fonts";
import close_icon from "../../../../assets/images/close_icon.webp";
import refresh_icon from "../../../../assets/images/refresh_icon.webp";
import check_on from "../../../../assets/images/check_on.webp";
import check_off from "../../../../assets/images/check_off.webp";
import calendar_icon from "../../../../assets/images/calendar_icon.webp";
import Calendar from "../../../../components/common/Calendar";
import Button from "../../../../components/button/Button";
import { formatTime } from "../../../../libs/utils";
import { useDispatch } from "react-redux";
import { toastShow } from "../../../../redux/toast/ToastActions";

const PERIOD = [
  { label: "오늘", value: "TODAY" },
  { label: "1주일", value: "WEEK" },
  { label: "1개월", value: "MONTH" },
  { label: "3개월", value: "3MONTH" },
  { label: "직접입력", value: "CUSTOM" },
];

const TRADE_TYPE = [
  { label: "매수", value: "BUY" },
  { label: "매도", value: "SELL" },
  { label: "실물인출", value: "GOODS" },
  { label: "감정평가", value: "APPRAISAL" },
  { label: "입금", value: "DEPOSIT" },
  { label: "출금", value: "WITHDRAW" },
  { label: "포인트/쿠폰", value: "COUPON" },
  { label: "이벤트", value: "EVENT" },
  { label: "안심직거래", value: "SAFE_TRADE" },
  { label: "자산 보냄", value: "SEND_ASSET" },
  { label: "자산 받음", value: "RECEIVE_ASSET" },
  { label: "보관료", value: "STORAGE_FEE" },
];

const ASSET_TYPE = [
  { label: "원화", value: "KRW" },
  { label: "포인트", value: "POINT" },
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
];

const SearchModal = ({ visible, onClose, onChange, initState }) => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState("3MONTH");
  const [tradeType, setTradeType] = useState(
    TRADE_TYPE.map(({ value }) => value)
  );
  const [assetType, setAssetType] = useState(
    ASSET_TYPE.map(({ value }) => value)
  );
  const [sort, setSort] = useState("DESC");
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [fromModalVisible, setFromModalVisible] = useState(false);
  const [toModalVisible, setToModalVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      setPeriod(initState.period);
      setTradeType(initState.tradeType);
      setAssetType(initState.assetType);
      setSort(initState.sort);
      setFrom(initState.from);
      setTo(initState.to);
    }
  }, [visible]);

  // 초기화 버튼
  const handleClickReset = () => {
    setPeriod("3MONTH");
    setTradeType(TRADE_TYPE.map(({ value }) => value));
    setAssetType(ASSET_TYPE.map(({ value }) => value));
    setSort("DESC");
    setFrom(new Date());
    setTo(new Date());
  };

  // 거래 유형 선택
  const handleClickTradeType = (value) => {
    if (tradeType.includes(value)) {
      setTradeType(tradeType.filter((type) => type !== value));
    } else {
      setTradeType([...tradeType, value]);
    }
  };

  // 자산 유형 선택
  const handleClickAssetType = (value) => {
    if (assetType.includes(value)) {
      setAssetType(assetType.filter((type) => type !== value));
    } else {
      setAssetType([...assetType, value]);
    }
  };

  const handleClickSubmit = () => {
    if (!tradeType.length) {
      dispatch(toastShow("거래유형을 선택해주세요."));
      return false;
    }

    if (!assetType.length) {
      dispatch(toastShow("거래자산을 선택해주세요."));
      return false;
    }
    onChange({
      period,
      tradeType,
      assetType,
      sort,
      from: period === "CUSTOM" ? from : new Date(),
      to: period === "CUSTOM" ? to : new Date(),
      page: 0,
    });
  };

  return (
    <BottomModal visible={visible} onClickBackground={onClose}>
      <Container>
        <TopWrap>
          <Span font={fonts.notoSansKRBold} size={22} spacing={-0.44}>
            조회 옵션
          </Span>
          <RefreshWrap onClick={handleClickReset}>
            <img src={refresh_icon} width={18} />
            초기화
          </RefreshWrap>
          <img src={close_icon} width={48} onClick={onClose} />
        </TopWrap>
        <Flex style={{ overflowY: "auto" }}>
          <SelectWrap>
            <Title>기간 선택</Title>
            <SelectBoxWrap>
              {PERIOD.map(({ label, value }) => (
                <SelectBox
                  key={value}
                  onClick={() => {
                    setPeriod(value);
                  }}
                  selected={period === value}>
                  {label}
                </SelectBox>
              ))}
            </SelectBoxWrap>

            {period === "CUSTOM" && (
              <PeriodRangeWrap>
                <PeriodLabel
                  onClick={() => {
                    setFromModalVisible(true);
                  }}>
                  {formatTime(from, "YYYY.MM.DD")}
                </PeriodLabel>
                ~
                <PeriodLabel
                  onClick={() => {
                    setToModalVisible(true);
                  }}>
                  {formatTime(to, "YYYY.MM.DD")}
                </PeriodLabel>
              </PeriodRangeWrap>
            )}
          </SelectWrap>
          <Separator />
          <SelectWrap>
            <Title>거래유형</Title>
            <SelectBoxWrap>
              <SelectBox
                onClick={() => {
                  if (tradeType.length === TRADE_TYPE.length) {
                    setTradeType([]);
                  } else {
                    setTradeType(TRADE_TYPE.map(({ value }) => value));
                  }
                }}
                selected={tradeType.length === TRADE_TYPE.length}>
                전체
              </SelectBox>
              {TRADE_TYPE.map(({ label, value }) => (
                <SelectBox
                  key={value}
                  onClick={() => handleClickTradeType(value)}
                  selected={tradeType.includes(value)}>
                  {label}
                </SelectBox>
              ))}
            </SelectBoxWrap>
          </SelectWrap>
          <Separator />
          <SelectWrap>
            <Title>거래자산</Title>
            <SelectBoxWrap>
              <SelectBox
                onClick={() => {
                  if (assetType.length === ASSET_TYPE.length) {
                    setAssetType([]);
                  } else {
                    setAssetType(ASSET_TYPE.map(({ value }) => value));
                  }
                }}
                selected={assetType.length === ASSET_TYPE.length}>
                전체
              </SelectBox>
              {ASSET_TYPE.map(({ label, value }) => (
                <SelectBox
                  key={value}
                  onClick={() => handleClickAssetType(value)}
                  selected={assetType.includes(value)}>
                  {label}
                </SelectBox>
              ))}
            </SelectBoxWrap>
          </SelectWrap>
          <Separator />
          <SelectWrap style={{ marginRight: 8 }}>
            <Title>정렬 순서</Title>
            <SelectBoxWrap>
              <OrderBox
                selected={sort === "DESC"}
                onClick={() => {
                  setSort("DESC");
                }}>
                최신순
              </OrderBox>
              <Flex style={{ width: 8 }} />
              <OrderBox
                selected={sort === "ASC"}
                onClick={() => {
                  setSort("ASC");
                }}>
                과거순
              </OrderBox>
            </SelectBoxWrap>
          </SelectWrap>
        </Flex>
        <Button
          label="확인"
          style={{ marginTop: 17, borderRadius: 8 }}
          onClick={handleClickSubmit}
        />
      </Container>

      <CalendarModal
        visible={fromModalVisible}
        selected={from}
        onClose={() => {
          setFromModalVisible(false);
        }}
        onChange={(date) => {
          setFrom(date);
          setFromModalVisible(false);
        }}
        max={to}
      />
      <CalendarModal
        visible={toModalVisible}
        selected={to}
        onClose={() => {
          setToModalVisible(false);
        }}
        onChange={(date) => {
          setTo(date);
          setToModalVisible(false);
        }}
        min={from}
      />
    </BottomModal>
  );
};

const CalendarModal = ({ visible, selected, onClose, onChange, max, min }) => {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");

  useEffect(() => {
    if (visible) {
      setDate(selected);
      setMonth(selected);
    }
  }, [visible]);

  return (
    <BottomModal visible={visible} onClickBackground={onClose}>
      <Container>
        <TopWrap>
          <Span font={fonts.notoSansKRBold} size={22} spacing={-0.44}>
            날짜 선택
          </Span>
          <img src={close_icon} width={48} onClick={onClose} />
        </TopWrap>
        <Flex style={{ overflowY: "auto" }}>
          <Flex style={{ marginTop: 18, minHeight: 380 }}>
            <Calendar
              value={date}
              onChange={setDate}
              month={month}
              onMonthChange={setMonth}
              maxDate={max}
              minDate={min}
            />
          </Flex>
        </Flex>
        <Button
          label="확인"
          style={{ marginTop: 17, borderRadius: 8 }}
          onClick={() => {
            onChange(date);
          }}
        />
      </Container>
    </BottomModal>
  );
};

const Container = styled(Flex)`
  background: white;
  padding: 26px 23px 39px 23px;
  border-radius: 24px 24px 0 0;
  max-height: ${window.innerHeight}px;
`;

const TopWrap = styled(Flex)`
  position: relative;
  flex-direction: row;
  align-items: flex-end;

  > img {
    position: absolute;
    right: -23px;
    align-self: center;
  }
`;

const RefreshWrap = styled(Flex)`
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRBold};
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #666666;
  margin-left: 14.5px;
`;

const SelectWrap = styled(Flex)`
  padding: 18px 0 24px 0;
`;

const Separator = styled(Flex)`
  border-top: 1px solid #eeeeee;
`;

const Title = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  letter-spacing: -0.34px;
  margin-bottom: 13px;
`;
const SelectBoxWrap = styled(Flex)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;
`;
const SelectBox = styled(Flex)`
  padding: 9px;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ selected }) => (selected ? "#656667" : "#dbdbdb")};
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-family: ${({ selected }) =>
    selected ? fonts.notoSansKRMedium : fonts.notoSansKRRegular};
  font-size: 16px;
  letter-spacing: -0.32px;
  color: ${({ selected }) => (selected ? "#222222" : "#888888")};

  &:before {
    content: "";
    background-image: url(${({ selected }) =>
      selected ? check_on : check_off});
    background-size: 10.8px 10.5px;
    width: 10.8px;
    height: 10.5px;
    margin-right: 7.7px;
  }
`;

const OrderBox = styled(Flex)`
  position: relative;
  padding: 9px;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ selected }) => (selected ? "#656667" : "#dbdbdb")};
  border-radius: 4px;
  font-family: ${({ selected }) =>
    selected ? fonts.notoSansKRMedium : fonts.notoSansKRRegular};
  font-size: 16px;
  letter-spacing: -0.32px;
  color: ${({ selected }) => (selected ? "#222222" : "#888888")};

  &:before {
    position: absolute;
    left: 9px;
    content: "";
    background-image: url(${({ selected }) =>
      selected ? check_on : check_off});
    background-size: 10.8px 10.5px;
    width: 10.8px;
    height: 10.5px;
    justify-self: flex-start;
  }
`;

const PeriodRangeWrap = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
  border: 1px solid #656667;
  border-radius: 4px;
  padding: 12px 16px;
`;

const PeriodLabel = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.spoqa};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.32px;
  &:after {
    content: "";
    background-image: url(${calendar_icon});
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    margin-left: 6px;
  }
`;

export default SearchModal;
