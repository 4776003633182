import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Span from "../../components/text/Span";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import fonts from "../../libs/fonts";
import { validateEmail } from "../../libs/utils";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageShow } from "../../redux/message/MessageActions";
import { userRefresh } from "../../redux/user/UserActions";

const EditEmail = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const originEmail = user.email;

  useEffect(() => {
    dispatch(userRefresh);
  }, []);

  return (
    <BasicLayout stackTopbar={{ title: "이메일", border: true }}>
      <Container>
        {originEmail ? (
          <ModifyEmail originEmail={originEmail} />
        ) : (
          <SaveEmail />
        )}
      </Container>
    </BasicLayout>
  );
};

// 이메일 등록
const SaveEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValidation(validateEmail(email));
  }, [email]);

  const handleClickSubmit = () => {
    if (email && validation) {
      dispatch(loadingStart);
      APIS.postUserEmailModify({ email })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(userRefresh);
            dispatch(
              messageShow("이메일이 등록되었습니다.", () => {
                history.goBack();
              })
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  return (
    <Flex style={{ flex: 1, justifyContent: "space-between" }}>
      <Flex>
        <Span
          font={fonts.notoSansKRBold}
          size={18}
          spacing={-0.36}
          color="#333333"
        >
          이메일을 입력해주세요.
        </Span>
        <EmailInput
          type="text"
          placeholder="이메일을 입력하세요"
          value={email}
          onChange={({ target: { value } }) => {
            setValidation(false);
            setEmail(value.split(" ").join(""));
          }}
        />
      </Flex>

      <Button label="등록" onClick={handleClickSubmit} disabled={!validation} />
    </Flex>
  );
};

// 이메일 수정
const ModifyEmail = ({ originEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setValidation(validateEmail(email));
  }, [email]);

  const handleClickSubmit = () => {
    if (email && validation) {
      dispatch(loadingStart);
      APIS.postUserEmailModify({ email })
        .then(({ data: { success, message } }) => {
          if (success) {
            dispatch(userRefresh);
            dispatch(
              messageShow("이메일이 변경되었습니다.", () => {
                history.goBack();
              })
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  return (
    <Flex style={{ flex: 1, justifyContent: "space-between" }}>
      <Flex>
        <Span
          font={fonts.notoSansKRBold}
          size={18}
          spacing={-0.36}
          color="#333333"
        >
          이메일을 변경합니다.
        </Span>

        <Span
          font={fonts.notoSansKRBold}
          size={14}
          spacing={-0.28}
          color="#333333"
          style={{ marginTop: 25 }}
        >
          나의 이메일
        </Span>
        <Span
          font={fonts.notoSansKRBold}
          size={18}
          spacing={-0.36}
          color="#777777"
          style={{ marginTop: 7 }}
        >
          {originEmail}
        </Span>
        <Span
          font={fonts.notoSansKRBold}
          size={14}
          spacing={-0.28}
          color="#333333"
          style={{ marginTop: 42 }}
        >
          변경할 이메일 주소 입력
        </Span>
        <EmailInput
          type="text"
          placeholder="이메일을 입력하세요"
          value={email}
          onChange={({ target: { value } }) => {
            setValidation(false);
            setEmail(value.split(" ").join(""));
          }}
        />
      </Flex>

      <Button label="변경" onClick={handleClickSubmit} disabled={!validation} />
    </Flex>
  );
};

const Container = styled(Flex)`
  flex: 1;
  padding: 16px;
`;

const EmailInput = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid #333333;
  padding: 8px 0;
  margin-top: 12px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 16px;

  &::placeholder {
    color: #989898;
  }
`;

export default EditEmail;
