import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ButtonRadio from "../../components/button-radio/ButtonRadio";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { phoneFormat } from "../../services/utils";
import * as APIS from "../../libs/apis";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import { parsePhoneNumber } from "../../libs/utils";
import DaumPostcode from "react-daum-postcode";

export default function AppraiseReturn({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const { shop, id, method } = state;
  const userReducer = useSelector((s) => s.user);
  // const method = history.location.state?.method;
  const [postCode, setPostCode] = useState(state.collectPostNo || ""); // 우편번호
  const [address, setAddress] = useState(state.collectAddress || ""); // 기본주소
  const [addressDetail, setAddressDetail] = useState(
    state.collectAddressDetail || ""
  ); // 상세주소
  const [changeAdress, setChangeAdress] = useState(false);
  const [isAddressSearch, setIsAddressSearch] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  const setMethod = (v) =>
    history.replace(history.location.pathname, { ...state, method: v });

  const handleCompleteSearch = (data) => {
    setPostCode(data.zonecode);
    setAddress(data.address);
    setAddressDetail("");
    setIsAddressSearch(false);
    history.goBack();
  };

  useEffect(() => {
    if (state?.addressDetail) {
      setAddressDetail(state.addressDetail);
    }
  }, [state]);

  useEffect(() => {
    if (method === "내방" || method === "현장감정") {
      setMethod("shop");
    }
  }, [method]);

  const isClick = useRef(false);
  const onReturn = () => {
    try {
      if (method === "택배") {
        if (address === "") {
          throw "주소를 검색해 주세요.";
        } else if (addressDetail === "") {
          throw "상세주소를 입력해 주세요.";
        }
      } else {
        if (!state.shop) {
          throw "방문하실 매장을 선택해 주세요.";
        }
      }
    } catch (error) {
      return dispatch(messageError(error));
    }

    if (!isClick.current) {
      isClick.current = true;
      dispatch(loadingStart);
      const param =
        method === "택배"
          ? {
              returnUserAddress: address,
              returnUserAddressDetail: addressDetail,
              returnUserAddressPostcode: postCode,
              returnShopId: state.shop.id,
              status: "RETURN_REQUEST",
            }
          : {
              returnShopId: state.shop.id,
              // status: "반송신청",
              status: "RETURN_REQUEST",
            };

      APIS.putAppraisalRequestsStatus(state.id, param)
        .then(({ data: { success, data, message } }) => {
          if (success) {
            returnComplete();
            dispatch(toastShow("반송 신청 되었습니다."));
            history.goBack();
          } else {
            dispatch(messageError(message));
            returnComplete();
          }
        })
        .catch(() => {
          returnComplete();
          history.goBack();
        });
    }
  };

  const returnComplete = () => {
    dispatch(loadingEnd);
    isClick.current = false;
  };

  const handleChangeAddress = () => {
    setChangeAdress(!changeAdress);
  };
  // if (!userReducer.id) {
  //   return <Redirect to={routes.appraise} />;
  // } else if (!state?.id) {
  //   return <Redirect to={routes.appraise} />;
  // }

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [contentRef.current]);

  return (
    <BasicLayout
      backgroundGray={method === "택배" ? false : true}
      stackTopbar={{
        title: method === "택배" ? "돌려받기" : "반송 신청하기",
      }}
    >
      <div
        id="aaa"
        ref={contentRef}
        onLoad={(e) => {
          setContentHeight(contentRef?.current.offsetHeight);
        }}
      >
        {method === "택배" ? (
          isAddressSearch ? (
            <DaumPostcode
              autoClose
              animation
              height={contentHeight}
              width="100%"
              onComplete={handleCompleteSearch}
            />
          ) : (
            <div style={{ margin: "34px 16px 0px 16px" }}>
              <Text font={fonts.notoSansKRBold} style={{ fontSize: "21px" }}>
                제품을 교환하지 않고
                <br />
                돌려 받으시겠습니까?
                <br />
                <Text font={fonts.notoSansKRRegular} style={{ fontSize: 16 }}>
                  돌려받으실 주소를 입력해주세요.
                </Text>
              </Text>
              <div style={{ marginTop: "24px", width: "100%" }}>
                <Text font={fonts.notoSansKRBold}>배송지 입력</Text>
                <InputBox>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <InputHeaderText>받는 주소</InputHeaderText>
                    <ChangeBtn onClick={handleChangeAddress}>
                      배송지 변경
                    </ChangeBtn>
                  </div>
                  <BorderLine2 />
                  <Row>
                    <Subject style={{ display: "flex", flexDirection: "row" }}>
                      수령인
                    </Subject>
                    <Content>{userReducer?.name}</Content>
                  </Row>
                  <Row>
                    <Subject row>연락처</Subject>
                    <Content>
                      {parsePhoneNumber(userReducer?.phone, "-")}
                    </Content>
                  </Row>
                  {changeAdress ? (
                    <>
                      <Row style={{ alignItems: "stretch" }}>
                        <Subject
                          style={{
                            alignSelf: "center",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          배송지
                          <span style={{ color: colors.primary }}>*</span>
                        </Subject>
                        <FocusInput
                          type="text"
                          readOnly
                          value={postCode}
                          placeholder="우편번호"
                        />
                        <SearchButton onClick={() => setIsAddressSearch(true)}>
                          주소검색
                        </SearchButton>
                      </Row>
                      <Row mt={8}>
                        <Subject />
                        <FocusInput
                          type="text"
                          readOnly
                          value={address}
                          placeholder="기본주소"
                        />
                      </Row>
                      <Row mt={8}>
                        <Subject />
                        <FocusInput
                          type="text"
                          placeholder="상세주소"
                          value={addressDetail}
                          onChange={setAddressDetail}
                        />
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Subject>배송지</Subject>
                      <Content>
                        {`(${postCode})`}
                        <br /> {`${address} ${addressDetail}`}
                      </Content>
                    </Row>
                  )}
                </InputBox>
              </div>
              <div className={classes.buttons}>
                <ButtonBase onClick={history.goBack}>취소</ButtonBase>
                <ButtonBase onClick={onReturn}>돌려받기</ButtonBase>
              </div>
            </div>
          )
        ) : (
          //method === shop일 때
          <>
            <div style={{ margin: "20px 30px 20px 30px" }}>
              <Text font={fonts.notoSansKRBold} className={classes.label1}>
                제품을 교환하지 않고
                <br />
                돌려 받으시겠습니까?
              </Text>
            </div>
            <ButtonRadio
              onChange={setMethod}
              value={method}
              className="margin"
              data={[{ label: "매장방문 회수", value: "shop" }]}
            />
            <div style={{ marginTop: 20 }}>
              <Text font={fonts.notoSansKRBold} className={classes.label4}>
                방문하실 매장을 선택해 주세요.
              </Text>
            </div>
            <ButtonMini
              style={{ marginBottom: state.shop ? 0 : 30 }}
              onClick={() => {
                return dispatch(
                  toastShow("반송신청은 주문 접수한 매장에서만 가능합니다.")
                );
                /*
              history.push(routes.authFindShop, {
                from: routes.appraiseReturn,
                state: {
                  ...history.location.state,
                  method,
                },
              });*/
              }}
              label="매장 찾기"
              className={classes.bt + " margin"}
            />
            {Boolean(state?.shop) && (
              <ShopItemSimple
                {...state?.shop}
                shopName={state?.shop.name}
                address={state?.shop.address}
                addressDetail={state?.shop.addressDetail}
                shopPhone={state?.shop.phoneNumber}
                distance={state?.shop.distance}
                className="margin"
              />
            )}
            <Text className={classes.label2}></Text>
            <div className={classes.buttons}>
              <ButtonBase onClick={history.goBack}>취소</ButtonBase>
              <ButtonBase onClick={onReturn}>반송신청</ButtonBase>
            </div>
          </>
        )}
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 30px 120px 30px",
    "& button": {
      flex: 1,
      paddingTop: 13,
      fontSize: 16,
      lineHeight: "18px",
      color: "#fff",
      fontFamily: fonts.notoSansKRBold,
      paddingBottom: 13,
      backgroundColor: colors.primary,
      marginLeft: 7,
      "&:first-child": {
        marginLeft: 0,
        marginRight: 7,
        backgroundColor: "#666666",
      },
      borderRadius: 3,
    },
  },
  label1: {
    // margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 18,
  },
  label4: {
    margin: "20px 30px 0px 30px",
    color: colors.text,
    fontSize: 16,
  },
  label2: {
    margin: "5px 30px 10px 30px",
    fontSize: "13px",
    color: colors.gray,
    "& span": {
      color: colors.text,
      marginLeft: "20px",
    },
  },
  label3: {
    margin: "20px 30px 10px 30px",
    fontSize: "13px",
    color: colors.text,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
  input: {
    "& input": {
      fontSize: "14px",
      borderBottom: "1.5px solid #333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    margin: "0px 30px 0px 30px",
  },
  date: {
    margin: "20px 10px 10px 0px",
  },
  date2: {
    margin: "20px 0px 10px 10px",
    flex: 1,
  },
});

const InputHeaderText = styled.span`
  color: var(--point_color, #fe6e0c);
  font-family: "Noto Sans KR";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.32px;
`;

const BorderLine2 = styled.div`
  margin: 8px 0px 16px 0px;
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--grey-Grayscale_20_EAEAEA, #eaeaea);
`;

const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;

  margin-top: ${({ mt = 15 }) => mt}px;

  .dash {
    font-family: ${fonts.notoSansKRRegular};
    font-size: 16px;
    color: #dbdbdb;
    letter-spacing: -0.28px;
    margin: 0 1px;
  }
`;

const Subject = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.3px;
  color: #333333;
  width: 70px;
`;
const Content = styled(Flex)`
  font-size: 15px;
  line-height: 27px;
`;

const FocusInput = ({ maxLength, type, onChange, ...rest }) => {
  return (
    <FocusInputContainer
      type={type}
      isNumber={type === "number"}
      onChange={({ target: { value } }) => {
        if (maxLength && maxLength < value.length) {
          return false;
        }
        if (value && type === "number" && !/^[0-9]+$/.test(value)) {
          return false;
        }
        onChange && onChange(value);
      }}
      {...rest}
    />
  );
};

const FocusInputContainer = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 8px;
  outline: none;
  &:focus {
    border: 1px solid #222222;
  }

  ${({ isNumber }) =>
    isNumber
      ? `
      font-family: 'Spoqa Han Sans Neo';
      text-align : center;
      font-size:14px;
      letter-spacing:0.56px;
      color:#222222;
  `
      : ""}
`;

const SearchButton = styled.button`
  margin-left: 8px;
  background: #6c6c6c;
  border: none;
  color: white;
  font-size: 13px;
  font-family: ${fonts.notoSansKRMedium};
  border-radius: 4px;
  padding: 10px 9px;
`;

const InputBox = styled.div`
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  background: #fafafa;
  margin-top: 16px;
`;

const ChangeBtn = styled.div`
  color: #777;
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  text-decoration-line: underline;
`;
