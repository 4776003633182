import React, { useEffect, useLayoutEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import * as APIS from "../../libs/apis";
import routes, { ROLE } from "../../libs/routes";
import { useDispatch } from "react-redux";
import { toastShow } from "../../redux/toast/ToastActions";
import { updateAuth } from "../../redux/auth/AuthReducer";
import { initSignUp } from "../../redux/auth/SignUpReducer";
import { STORAGE_KEY } from "../../libs/consts";
import { decodeJwt, postMessage } from "../../libs/utils";
import { signIn } from "../../libs/sign";
import { actionError } from "../../redux/action/ActionActions";

export default function AuthGateway({}) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search.replace(/&amp;/gi, "&"));

  const code = params.get("code");
  const type = params.get("type");
  const purpose = params.get("purpose");
  const name = params.get("name");
  const requestId = params.get("requestId");
  const requireCompleteAction = params.get("requireCompleteAction");
  const error = params.get("error");
  const message = params.get("message");
  const errorType = params.get("errorType");
  const leaveDate = params.get("leaveDate");
  const reSignupDate = params.get("reSignupDate");

  useLayoutEffect(() => {
    if (error === "true") {
      if (errorType === "CANNOT_RESIGNUP") {
        sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
        history.replace(routes.main, { errorType, leaveDate, reSignupDate });
        return;
      }

      message && dispatch(actionError(message));
      history.replace(routes.main);
      return;
    }

    switch (type) {
      // SNS 인증 완로
      case "OAUTH_COMPLETED":
        switch (purpose) {
          case "SIGNUP":
            saveAuthInfo({ code, nextPage: routes.signUp });
            break;
          case "SIGNIN":
            saveAuthInfo({
              code,
              nextPage: routes.main,
              message: "로그인 되었습니다.",
            });
            break;
          default:
        }
        break;

      // 본인 인증 완료
      case "SELF_VERIFICATION_COMPLETED":
        switch (purpose) {
          case "SIGNUP":
            if (requireCompleteAction === "true") {
              // 본인인증 후 가입 시작
              dispatch(initSignUp({ purpose, requestId }));
              history.replace(routes.acceptTerms);
            } else {
              // 가입 완료

              // 가입 완료시에는 기존 social login token이 만료가 되었기에 기존 토큰 삭제
              sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);

              saveAuthInfo({ code, nextPage: routes.main, logAppsflyer: true });
            }
            break;

          case "UPDATE_SECURE_PASSWORD":
            if (requireCompleteAction === "true") {
              dispatch(initSignUp({ purpose, requestId }));
              history.replace(routes.authRegisterPassword);
            } else {
              saveAuthInfo({ code, nextPage: routes.main });
            }
            break;

          case "UPDATE_PHONENUMBER":
            saveAuthInfo({
              code,
              nextPage: routes.main,
              message: "휴대폰 번호 변경이 완료되었습니다.",
            });
            break;
          default:
        }
        break;

      default:
        break;
    }
  }, []);

  const saveAuthInfo = ({ code, nextPage, message, logAppsflyer }) => {
    APIS.getAuthToken(code).then(
      ({ data: { success, data, message: errorMessage } }) => {
        if (success) {
          if (logAppsflyer) {
            const { sub = "" } = decodeJwt(data.authToken);
            const ids = sub.split(":");

            const param = { event: "gb_make_account" };
            if (ids.length > 1) {
              const userId = +ids[1];
              param.value = { customer_user_id: `gb_${userId}` };
              param.userId = userId;
            }

            postMessage({ command: "LOG_APPS_FLYER", param });
          }

          signIn(data.authToken);

          if (message) {
            dispatch(toastShow(message));
          }

          history.replace(nextPage);
        } else {
          dispatch(actionError(errorMessage));
          history.replace(routes.main);
        }
      }
    );
  };
  return <></>;
}
