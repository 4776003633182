import React from "react";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import fonts from "../../../../../libs/fonts";
import { numberWithCommas } from "../../../../../libs/utils";
import CommonDetail from "./CommonDetail";
const DepositDetail = (props) => {
  const { tradeType, tradeTypeKor, pureTradeKrw } = props;

  return (
    <div>
      <ContentWrap>
        <ContentTitle>{tradeTypeKor}</ContentTitle>
        <Separator color="#bdbdbd" />
        <Separator color="#eaeaea" style={{ marginTop: 2, marginBottom: 20 }} />
        <div>
          <CommonDetail {...props} />
          <ContentRow>
            <DetailTitle>입금액</DetailTitle>
            <DetailContent>
              <AmountText>{numberWithCommas(pureTradeKrw)}</AmountText>원
            </DetailContent>
          </ContentRow>
        </div>
      </ContentWrap>
      <AfterAsset {...props} />
    </div>
  );
};

const AfterAsset = ({ afterGold, afterKrw, afterPoint, afterSilver }) => {
  return (
    <ContentWrap>
      <SubContentTitle>거래 후 잔고</SubContentTitle>
      <Separator color="#eaeaea" style={{ marginBottom: 20 }} />
      <div>
        <ContentRow>
          <DetailTitle>금</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterGold)}</AmountText>g
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>은</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterSilver)}</AmountText>g
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>원화(KRW)</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterKrw)}</AmountText>원
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>포인트</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterPoint)}</AmountText>pt
          </DetailContent>
        </ContentRow>
      </div>
    </ContentWrap>
  );
};

const ContentWrap = styled(Flex)`
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 20px 15px;
`;
const ContentTitle = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  letter-spacing: -0.34px;
  margin-bottom: 20px;
`;
const SubContentTitle = styled(Flex)`
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #999999;
  margin-bottom: 20px;
`;
const Separator = styled(Flex)`
  border-bottom: 1px solid ${({ color }) => color};
`;

const ContentRow = styled(Flex)`
  &:not(:first-of-type) {
    margin-top: 11px;
  }
  flex-direction: row;
`;

const DetailTitle = styled(Span)`
  display: flex;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #999999;
`;

const DetailContent = styled(Span)`
  display: flex;
  flex: 1;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #444444;
  font-weight: 500;
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
`;

const AmountText = styled(Span)`
  font-family: ${fonts.spoqa};
  font-weight: ${({ sub }) => (sub ? "500" : "bold")};
  font-size: 16px;
  margin-right: 1px;
`;

export default DepositDetail;
