import {
  ButtonBase,
  InputBase,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Button from "../../components/button/Button";
import TopbarClose from "../../components/topbar-close/TopbarClose";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";

export default function DirectTranscationAdd2({}) {
  const classes = useStyle();
  const history = useHistory();
  const state = history.location.state || {};
  const dispatch = useDispatch();
  const brandName = state.brandName;
  const productName = state.productName;
  const year = state.year;
  const price = state.price;
  const productType = state.productType;
  const setBrandName = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, brandName: v },
    });
  const setProductName = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, productName: v },
    });
  const setYear = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, year: v },
    });
  const setPrice = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, price: v },
    });
  const setProductType = (v) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, productType: v },
    });

  const handleNext = () => {
    history.push("/directtransactionadd3", {
      ...state,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.topbar}>
        <ButtonBase
          onClick={() => {
            history.goBack();
          }}
          className={classes.back}
        >
          <img src={images.back} />
        </ButtonBase>
        <span className={classes.navi}>2/4</span>
        <ButtonBase
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={images.home} className={classes.home} />
        </ButtonBase>
      </div>
      <div className={classes.navbar}>
        <div />
      </div>

      <div className={classes.content}>
        <span className={classes.title}>제품의 정보를 입력해 주세요.</span>
        <span className={classes.label}>
          <img src={images.checkon} />
          <span>브랜드(제조사)</span>
        </span>
        <InputBase
          value={brandName}
          placeholder="브랜드를 입력하세요."
          className={classes.input}
          onChange={(e) => setBrandName(e.target.value)}
        />
        <span className={classes.label}>
          <img src={images.checkon} />
          <span>제품명</span>
        </span>
        <InputBase
          value={productName}
          placeholder="제품명을 입력하세요."
          className={classes.input}
          onChange={(e) => setProductName(e.target.value)}
        />
        <span className={classes.label}>
          <img src={images.checkon} />
          <span>제조출시년도</span>
        </span>
        <InputBase
          value={year}
          placeholder="제조년도를 입력하세요."
          className={classes.input}
          type="number"
          onChange={(e) =>
            e.target.value.length > 4 ? null : setYear(e.target.value)
          }
        />

        <span className={classes.label}>
          <img src={images.checkon} />
          <span>구매 가격</span>
        </span>

        <div className={classes.input2}>
          <InputBase
            value={price}
            placeholder="구매하신 금액을 입력하세요."
            type="number"
            onChange={(e) =>
              e.target.value.length > 11 ? null : setPrice(e.target.value)
            }
          />
          <span>원</span>
        </div>

        <span className={classes.label}>
          <img src={images.checkon} />
          <span>제품 상태</span>
        </span>

        <div className={classes.list}>
          {[
            { value: "NEW", label: "새상품" },
            { value: "VERYGOOD", label: "매우 좋음" },
            { value: "GOOD", label: "좋음" },
            { value: "USUALLY", label: "보통" },
          ].map((x, i) => {
            return (
              <Paper
                onClick={() => {
                  setProductType(x.value);
                }}
                key={i.toString()}
                className={
                  classes.listItem +
                  " " +
                  (productType === x.value && classes.listItemOn)
                }
              >
                {x.label}
              </Paper>
            );
          })}
        </div>
      </div>
      <ButtonBase
        onClick={handleNext}
        disabled={
          !year || !productName || !brandName || !productType || price === ""
        }
        className={classes.button}
      >
        다음
      </ButtonBase>
    </div>
  );
}

const useStyle = makeStyles({
  label: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 16,
    "& img": {
      width: 16,
      height: 10,
      objectFit: "contain",
      marginRight: 8,
    },
    "& span": {
      fontSize: 13.3,
      color: "#333333",
      fontFamily: fonts.notoSansKRMedium,
    },
  },
  button: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    backgroundColor: "#ff6e0e",
    width: "100%",
    textAlign: "center",
    color: "#fff",
    fontSize: 13.3,
    fontFamily: fonts.notoSansKRBold,
    "&:disabled": { backgroundColor: "#999999" },
  },
  place: {
    fontSize: 13.3,
    color: "#333333",
    alignSelf: "center",
    "& span": { fontFamily: fonts.notoSansKRBold },
  },

  paper1: {
    border: "3px solid #ff6e0e",
  },
  home: {
    width: 20,
    marginRight: 16,
    height: 20,
    objectFit: "contain",
  },
  input: {
    fontSize: "13.3px",
    lineHeight: "13.3px",
    marginLeft: 30,
    marginRight: 30,
    borderBottom: "1px solid #333333",
    padding: "6px 13px",
  },
  input2: {
    marginLeft: 30,
    marginRight: 30,
    borderBottom: "1px solid #333333",
    alignItems: "center",
    padding: "6px 13px",
    display: "flex",
    flexDirection: "row",
    "& div": {
      flex: 1,
    },
    "& input": {
      fontSize: "13.3px",
      lineHeight: "13.3px",
      textAlign: "right",
    },
    "& span": {
      fontSize: 13,
      marginLeft: 22,
      color: "#333333",
      fontFamily: fonts.notoSansKRMedium,
    },
  },
  list: {
    overflowX: "auto",
    display: "flex",
    marginLeft: 30,
    marginRight: 30,
  },
  listItem: {
    flex: 1,
    borderRadius: 16,
    marginRight: 6,
    cursor: "pointer",
    "&:last-child": { marginRight: 0 },
    backgroundColor: "#ffffff",
    marginBottom: 40,
    color: "#666",
    fontSize: "13.3px",
    textAlign: "center",
    lineHeight: "30px",
    fontFamily: fonts.notoSansKRMedium,
  },
  listItemOn: {
    backgroundColor: "#ff6e0e",
    color: "#fff",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    marginBottom: 60,
    marginTop: 90,
  },
  title: {
    lineHeight: "26px",
    fontSize: 20,
    marginLeft: 30,
    marginRight: 30,
    color: "#333333",
    fontFamily: fonts.notoSansKRBold,
  },
  sub: {
    marginLeft: 30,
    marginRight: 30,
    lineHeight: "26px",
    color: "#666666",
    fontSize: 13,
  },
  root: {
    flex: 1,
    backgroundColor: "rgb(241,241,241)",
    flexDirection: "column",
    display: "flex",
  },
  navbar: {
    height: 3,
    backgroundColor: "#dddddd",
    alignSelf: "stretch",
    "& div": {
      height: "100%",
      backgroundColor: "#ff6e0e",
      width: "50%",
      marginLeft: 0,
    },
    position: "fixed",
    right: 0,
    left: 0,
    top: 60,
  },
  topbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    display: "flex",
    backgroundColor: "rgb(241,241,241)",
    alignItems: "center",
  },
  back: {
    "& img": {
      width: 10,
      height: 20,
      objectFit: "contain",
    },
    paddingLeft: 14,
    paddingRight: 11,
  },
  navi: {
    fontSize: 16,
    flex: 1,
    color: "#333333",
  },
});
