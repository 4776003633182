import { Divider, InputBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Images from "../../components/images/Images";
import ProductDirectTransactionItem from "../../components/product-list/ProductDirectTranscationItem";
import Rating from "../../components/rating/Rating";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";

export default function OrderCompleteWrite({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  return (
    <BasicLayout
      stackTopbar={{
        border: true,
        title: state?.type === "review" ? "리뷰쓰기" : "주문 상세",
      }}
    >
      <Text className={classes.title} font={fonts.notoSansKRMedium}>
        {state?.type === "review"
          ? "구매해 주셔서 감사합니다."
          : "구매를 확정해 주세요."}
      </Text>
      <ProductDirectTransactionItem bottom />
      <div style={{ height: 10 }} />
      <Divider style={{ height: 3 }} />
      <Rating size={30} className={classes.rating} />

      <Flex className={classes.inputWrap + " shadow-small"}>
        <InputBase placeholder="내용을 입력해 주세요.(200자 이내)" multiline />
      </Flex>

      <Images className={classes.images} />
      <Button
        onClick={() => {
          history.replace(routes.orderDetail(1), { ...state.state });
        }}
        label={state?.type === "review" ? "등록" : "구매 확정"}
        className={classes.button}
      />
      <img src={images.simpleinspect2} className={classes.banner} />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  banner: {
    zIndex: 3,
    width: "100%",
    position: "fixed",
    left: 0,
    right: 0,
    backgroundColor: "#fec729",
    bottom: 0,
  },
  button: {
    margin: "30px",
    marignTop: "0px !important",
    marginBottom: "140px",
    padding: "10px",
  },
  images: {
    margin: "30px",
    marginBottom: "0px",
  },
  inputWrap: {
    padding: "18px",
    margin: "0px 30px",

    minHeight: "160px",
    "& textarea": {
      fontSize: 14,
      fontFamily: fonts.notoSansKRRegular,
    },
  },
  rating: { alignSelf: "center", margin: "20px 0px" },
  title: { fontSize: 16, color: "#333", margin: "30px 13px 0px 13px" },
});
