import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { numberWithCommas } from "../../libs/utils";
import { ChevronRight } from "@material-ui/icons";

export default function Header({
  krw = 0,
  gold = 0,
  silver = 0,
  acceptableKrw = 0,
  navigate,
}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  // const { gold = 0, krw = 0, silver = 0 } = useSelector((s) => s.user);
  return (
    <Flex className={classes.root}>
      <Flex className={classes.header} row>
        <Text font={fonts.notoSansKRMedium} className={classes.noto}>
          마이
        </Text>
        {/* <ButtonBase className={classes.avatar}>
          <img alt="avatar" src={images.avatar} />

          <Flex className={classes.profileWrap}>
            <Text font={fonts.notoSansKRMedium}>수정</Text>
          </Flex>
        </ButtonBase> */}
      </Flex>

      <Flex row>
        <ButtonBase
          onClick={() => {
            navigate(routes.mytradehistory);
            // navigate(routes.assetHistory, { type: "gold" });
            // history.push(routes.assetHistory, { type: "gold" });
          }}
          className={classes.l1}
        >
          <Text className={classes.label}>금(GOLD) 보유자산</Text>
          <Text font={fonts.notoSansKRBold} className={classes.label2}>
            {numberWithCommas(gold)}
            <Text font={fonts.notoSansKRBold}>g</Text>
          </Text>
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            navigate(routes.mytradehistory);
            // navigate(routes.assetHistory, { type: "silver" });
            // history.push(routes.assetHistory, { type: "silver" });
          }}
          className={classes.l1}
        >
          <Text className={classes.label}>은(SILVER) 보유자산</Text>
          <Text font={fonts.notoSansKRBold} className={classes.label2}>
            {numberWithCommas(silver)}
            <Text font={fonts.notoSansKRBold}>g</Text>
          </Text>
        </ButtonBase>
      </Flex>

      <Flex className={classes.won} row>
        <Text font={fonts.notoSansKRBold} className={classes.wonLabel}>
          보유 원화
        </Text>
        <Flex row className={classes.wonCe}>
          <Text font={fonts.notoSansKRBold} className={classes.wonLabel}>
            {numberWithCommas(krw)}원
          </Text>
        </Flex>
      </Flex>

      <ButtonBase
        className={classes.acceptableKrw}
        onClick={() => {
          navigate(routes.wonHistory);
        }}
      >
        <Text font={fonts.notoSansKRBold} className={classes.wonLabel}>
          사용가능 원화
        </Text>
        <Flex row className={classes.wonCe}>
          <Text font={fonts.notoSansKRBold} className={classes.wonLabel}>
            {numberWithCommas(acceptableKrw)}원
          </Text>
        </Flex>
        <ChevronRight
          style={{ color: "white", position: "absolute", right: 0 }}
        />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  wonCe: {
    alignItems: "center",
    display: "flex",
    "& img": {
      marginLeft: "8px",
      width: "8px",
      height: "14px",
    },
  },
  won: {
    marginTop: "20px",
    padding: "12px 30px 12px 30px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #555555",
  },
  acceptableKrw: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 30px 12px 30px",
  },
  wonLabel: {
    color: "#fff",
    fontSize: "15px",
    lineHeight: 1,
  },
  l1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    paddingLeft: "30px",
    "&:first-child": {
      borderRight: "1px solid #555555",
    },
  },
  label: {
    color: "#fff",
    fontSize: "13px",
  },
  label2: {
    color: colors.primary,
    fontSize: "24px",
    "& span": {
      fontSize: "17px",
    },
  },
  root: {
    backgroundColor: colors.text,
  },
  noto: {
    color: "#fff",
    fontSize: "18px",
  },
  header: {
    padding: "20px 16px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    "& img": {
      width: "50%",
      height: "50%",
    },
    position: "relative",
    height: "50px",
    borderRadius: "100px",
  },
  profileWrap: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 20,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
    borderBottomLeftRadius: 100,
    borderBottomRightRadius: 100,
    "& span": {
      fontSize: "12px",
      color: "#fff",
    },
  },
});
