import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";

export default function InputText({
  type = "text",
  className = "",
  style = {},
  label = "",
  value = "",
  onChange = () => {},
  error = false,
  helperText = "",
  placeholder = "",
}) {
  const classes = useStyle();

  return (
    <TextField
      type={type}
      label={label}
      style={style}
      placeholder={placeholder}
      className={`${classes.input} ${className}`}
      InputLabelProps={{ classes: { focused: classes.inputLabelFocus } }}
      InputProps={{ classes: { underline: classes.inputUnderline } }}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      autoComplete="new-password"
      error={error}
      helperText={helperText}
    />
  );
}

const useStyle = makeStyles({
  input: { width: "100%" },
  inputLabelFocus: { color: `${colors.primary} !important` },
  inputUnderline: { "&:after": { borderBottomColor: colors.primary } },
});
