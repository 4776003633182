import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";

export default function Notification({ className, black }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ButtonBase
      onClick={() => {
        history.push(routes.notification);
      }}
      style={{
        position: "relative",
      }}
      className={className}
    >
      <img alt="notifi" src={images.notifi2} className={classes.notifi} />
      <Text
        className={classes.length}
        font={fonts.notoSansKRBold}
        style={{
          backgroundColor: black ? "#000" : colors.primary,
        }}
      >
        12
      </Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  notifi: {
    widows: "24px",
    objectFit: "contain",
    height: "24px",
  },
  length: {
    borderRadius: "10px",
    backgroundColor: colors.primary,
    color: "#fff",
    right: -15,
    top: -5,
    fontSize: "11px",
    textAlign: "center",
    lineHeight: 1,
    position: "absolute",
    boxSizing: "border-box",
    padding: "3px 5px",
  },
});
