import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Checkbox from "../checkbox/Checkbox";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import sample1 from "../../assets/images/sample1.png";
import consts from "../../libs/consts";
import { getPurityLabel, numFormat } from "../../services/utils";
export default function AppraiseItem({
  state,
  checked,
  onCheckedChange,
  onClick,
  onDelete,
  path,
  calc,
  calcPrice,
  purity,
  type,
  quantity,
  guaranteeImageUrl,
  name,
  weightGram,
  appraisalProduct,
  gram,
  topImageUrl,
  frontImageUrl,
  leftSideImageUrl,
  rightSideImageUrl,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const imageUrl =
    topImageUrl || frontImageUrl || leftSideImageUrl || rightSideImageUrl;
  return (
    <Flex className={classes.root + " " + "shadow"}>
      <Flex row className={classes.header}>
        {state === "progress" ? (
          <Text className={classes.date}>2021.23.12</Text>
        ) : (
          <ButtonBase
            onClick={() => {
              onCheckedChange(!checked);
            }}
            row
            className={classes.check}
          >
            <img
              src={checked ? images.checkon : images.checkoff}
              className={classes.checke}
            />
            <Text className={classes.name} font={fonts.notoSansKRMedium}>
              {name}
            </Text>
          </ButtonBase>
        )}

        {state === "progress" && (
          <Text font={fonts.notoSansKRMedium} className={classes.progress}>
            감정중
          </Text>
        )}
        {Boolean(onDelete) && (
          <ButtonBase onClick={onDelete}>
            <img style={{ width: "16px", height: "16px" }} src={images.trash} />
          </ButtonBase>
        )}
      </Flex>

      <ButtonBase
        onClick={onClick}
        disabled={!Boolean(onClick)}
        row
        className={classes.main}
      >
        <img
          src={`${consts.s3BaseUrl}/${imageUrl}`}
          className={classes.image}
        />
        <Flex
          style={{ alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            {calc}g <Text> (= {numFormat(calcPrice)}원)</Text>
          </Text>
          <Text className={classes.desc}>
            순도
            <Text>{getPurityLabel(purity, appraisalProduct.assetType)}</Text>
          </Text>
          <Text className={classes.desc}>
            수량
            <Text>{numFormat(quantity)}개</Text>
          </Text>
          <Text className={classes.desc}>
            보증서
            <Text>{guaranteeImageUrl ? "있음" : "없음"}</Text>
          </Text>
        </Flex>
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    margin: "13px",
    padding: "16px",
  },
  name: {
    color: colors.text,
    fontSize: "14px",
  },
  checke: {
    width: "14px",
    height: "10px",
    marginRight: "10px",
  },
  check: { alignItems: "center", display: "flex", alignSelf: "flex-start" },
  header: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  main: {
    alignItems: "center",
    marginTop: "13px",
    display: "flex",
    justifyContent: "flex-start",
  },
  image: {
    marginRight: "13px",
    objectFit: "cover",
    border: "1px solid #eee",
    borderRadius: 3,
    width: "80px",
    height: "80px",
  },
  label1: {
    marginBottom: "3px",
    color: colors.text,
    fontSize: "16px",
    "& span": {
      fontSize: "12px",
      color: colors.gray,
    },
  },
  desc: {
    marginTop: "4px",
    fontSize: "11px",
    color: colors.gray,
    "& span": {
      marginLeft: "10px",
      fontSize: "11px",
      color: colors.text,
    },
  },
  date: { fontSize: 12, color: "#666" },
  progress: {
    fontSize: 12,
    color: colors.primary,
  },
});
