import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import action from "./action/ActionReducer";
import list from "./list/ListReducer";
import message from "./message/MessageReducer";
import image from "./image/ImageReducer";
import toast from "./toast/ToastReducer";
import user from "./user/UserReducer";
import loading from "./loading/LoadingReducer";
import auth from "./auth/AuthReducer";
import signUp from "./auth/SignUpReducer";
import marketPrice from "./marketPrice/MarketPriceReducer";
import safeTradeOrder from "./safe-trade/safeTradeOrder";
import findShop from "./common/findShop";
import kakaoChat from "./kakao/kakaoChat";

const appReducer = combineReducers({
  user: user,
  message: message,
  toast: toast,
  action: action,
  list: list,
  loading: loading,
  image: image,
  auth,
  signUp,
  marketPrice,
  safeTradeOrder,
  findShop,
  kakaoChat
});

const rootReducer = (state, action) => {
  if (action.type === "clear") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const store = createStore(
  rootReducer,
  {},
  compose(applyMiddleware(thunk))
);
