import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import noticeIcon from "../../assets/images/ic_notice_sub.png"
import newIcon from "../../assets/images/ic_ing.png";

export default function NoticeItem({ data, onClick }) {
  const classes = useStyle();

  let parseDate = data.startDate;
  parseDate = parseDate.replace(/-/g, "/");

  const convertDateFormat = (date) => {
    if (date === "-") {
      return "-";
    } else {
      const convertDate = date.replace(/-/g, "/");
      const parseDate = new Date(convertDate);
      var year = parseDate.getFullYear();
      var month = parseDate.getMonth() + 1;
      month = month >= 10 ? month : "0" + month;
      var day = parseDate.getDate();
      day = day >= 10 ? day : "0" + day;
      return parseDate ? [year, month, day].join("-") : "-";
    }
  };
  
  return (
    data.status === "FIXED" ? (
      <ButtonBase onClick={onClick} className={classes.fixedroot}>
      <Text font={fonts.notoSansKRMedium} className={classes.title} style={{color: "#001b8e"}}>
      <img src={noticeIcon} className="img_noti" alt="안내" />&nbsp;
        {data.subject}
      </Text>
      <Text className={classes.date}>{convertDateFormat(data?.startDate || "-")}</Text>
    </ButtonBase>
    ) : (  
    <ButtonBase onClick={onClick} className={classes.root}>
      <Text font={fonts.notoSansKRMedium} className={classes.title}>
        {data.subject}{(new Date()).setDate((new Date()).getDate() - 3) < new Date(parseDate).getTime() && (<img src={newIcon} className="img_new" alt="new"></img>)}
      </Text>
      <Text className={classes.date}>{convertDateFormat(data?.startDate || "-")}</Text>
    </ButtonBase>)
  
  );
}

const useStyle = makeStyles({
  fixedroot: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px",
    display: "flex",
    borderBottom: "1px solid #eee",
    background : "#dfe6ef"
  },
  root: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "20px",
    display: "flex",
    borderBottom: "1px solid #eee",
  },
  date: { color: "#999", fontSize: "13px", marginTop: 5},
  title: {
    color: colors.text,
    fontSize: "16px",
    textAlign: "initial",
  },
});