import { ButtonBase, makeStyles, Snackbar } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import { axiosDispatch } from "../../services/network";
import consts from "../../libs/consts";
import { formatTime, numFormat } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";

export default function PurchaseCancelPopup(state) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const onClose = state.onClose;
  const onCancleData = state.onCancleData;

  const { id } = useSelector((s) => s.user);
  const rows = state.method;
  const orderPrice = numFormat(rows.orderPrice);
  const requestGram = numFormat(rows.requestGram);
  const remainGram = numFormat(rows.remainGram);

  const feeRate =
    rows.tradeType === "SELL" ? 1 - rows.feeRate / 100 : 1 + rows.feeRate / 100;
  const totalPrice = numFormat(
    parseInt(rows.orderPrice * rows.requestGram * feeRate)
  );

  useEffect(() => {
    if (open) {
      let tm = setTimeout(() => {
        clearTimeout(tm);
        onClose();
      }, 1000);
    }
  }, [open]);

  const orderCancle = async () => {
    dispatch(loadingStart);

    const serviceBreak = await APIS.getServiceBreak();
    if (serviceBreak) {
      dispatch(messageError(serviceBreak));
      dispatch(loadingEnd);
      return;
    }

    APIS.deleteBuySell(rows.id)
      .then(({ data: { success, message } }) => {
        if (success) {
          onCancleData();
          setOpen(true);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch(() => {
        dispatch(actionError("요청이 실패하였습니다."));
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  return (
    <Flex className={classes.root}>
      <Flex className={classes.containter}>
        <Text font={fonts.notoSansKRBold} className={classes.label}>
          {rows.tradeType === "BUY" ? "매수주문취소" : "매도주문취소"}
        </Text>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문상품</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {rows.assetType === "GOLD" ? "금" : "은"}({rows.assetType})
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문방식</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            지정가
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>
            {rows.tradeType === "BUY" ? "매수가격" : "매도가격"}
          </Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {orderPrice}원
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문금액</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {totalPrice}원
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>주문중량</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {requestGram}g
          </Text>
        </Flex>
        <Flex row className={classes.row}>
          <Text className={classes.hint}>미체결중량</Text>
          <Text className={classes.value} font={fonts.notoSansKRMedium}>
            {remainGram}g
          </Text>
        </Flex>
        <Text font={fonts.notoSansKRLight} className={classes.message}>
          미체결된 중량만 취소됩니다.
        </Text>
        <Flex row className={classes.buttons}>
          <ButtonBase onClick={onClose} className={classes.button1}>
            닫기
          </ButtonBase>
          <ButtonBase
            disabled={open}
            onClick={() => {
              orderCancle();
            }}
            style={{
              backgroundColor:
                rows.tradeType === "BUY" ? colors.textRed : colors.textBlue,
            }}
            className={classes.button2}>
            주문취소
          </ButtonBase>
        </Flex>
      </Flex>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={onClose}
        message={"주문이 취소 되었습니다."}
      />
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    zIndex: 11,
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: "flex-end",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  containter: {
    backgroundColor: colors.white,
    padding: "30px 20px",
    alignItems: "center",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  label: {
    fontSize: "20px",
    marginBottom: "20px",
    color: colors.black,
  },
  row: {
    width: "50%",
    alignItems: "center",
    marginTop: "6px",
  },
  hint: {
    color: "#999999",
    fontSize: "14px",
    flex: 1,
  },
  value: {
    flex: 1,
    textAlign: "right",
    color: "#000",
    fontSize: "14px",
  },
  buttons: {
    alignSelf: "stretch",
    alignItems: "center",
    marginTop: "30px",
  },
  button1: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    marginRight: "10px",
    fontSize: "16px",
    backgroundColor: "#999",
  },
  button2: {
    borderRadius: "5px",
    padding: "10px 0px",
    textAlign: "center",
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    flex: 1,
    fontSize: "16px",
  },
  message: {
    fontSize: "13px",
    color: "#000",
    marginTop: "16px",
  },
});
