import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";

export default function Tabs({
  data = [
    { label: "금", value: "gold" },
    { label: "은", value: "silver" },
  ],
  index = 0,
  onIndexChange,
}) {
  const classes = useStyle();
  const history = useHistory();
  return (
    <Flex row className={classes.root}>
      {data.map((x, i) => {
        const sel = i === index;
        return (
          <ButtonBase
            key={i.toString()}
            onClick={() => {
              onIndexChange(i);
            }}
            className={classes.itemButton}
          >
            <Flex className={classes.item}>
              <Text
                font={sel ? fonts.notoSansKRBold : fonts.notoSansKRRegular}
                className={`${classes.label} ${sel && classes.labelSel}`}
              >
                {x.label}
                <Text
                  style={{ fontSize: "14px" }}
                  font={sel ? fonts.notoSansKRBold : fonts.notoSansKRRegular}
                >
                  ({x.value.substr(0, 1).toUpperCase()}
                  {x.value.substr(1, x.value.length - 1)})
                </Text>
              </Text>
            </Flex>
          </ButtonBase>
        );
      })}
      <Flex
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "center" }}
      >
        <Button
          label="+ 실물인출"
          style={{
            padding: "2px 7px 2px 7px",
            marginRight: 10,
            borderRadius: 50,
            border: `1px solid ${colors.primary}`,
            backgroundColor: "white",
          }}
          textStyle={{ fontSize: 13, color: colors.primary }}
          onClick={() => {
            history.push(routes.physicalWithDrawal);
          }}
        />
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
    alignItems: "center",
    height: "60px",
    borderBottom: "0.7px solid #dddddd",
  },
  itemButton: {
    minWidth: "30%",
  },
  item: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    color: colors.gray,
    padding: "14px 0px",
    fontSize: "17px",
  },
  labelSel: {
    color: "#000000",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderBottom: "2px solid black",
  },
});
