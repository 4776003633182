import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import routes from "../../libs/routes";
import Button from "../button/Button";

export default function ProductDirectTransactionItem({ bottom, order }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex
      style={{
        ...(bottom && { borderBottom: "none" }),
      }}
      className={classes.root}
    >
      <ButtonBase
        onClick={() => {
          history.push(routes.directTransactionDetail(1));
        }}
        disabled={order}
        className={classes.item}
      >
        <img src={images.goldbar} className={classes.sample1} />
        <Flex className={classes.main}>
          <Flex row className="row-between" style={{ alignSelf: "stretch" }}>
            <Text className={classes.label1}>Relex</Text>
            <Text className={classes.label3}>DP123123123</Text>
          </Flex>
          <Text className={classes.label2} font={fonts.notoSansKRMedium}>
            Datejust
          </Text>
          <Text font={fonts.notoSansKRMedium} className={classes.label1}>
            5,829,322원
          </Text>
        </Flex>
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    border: "1px solid #eee",
    objectFit: "contain",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
  },
  item: {
    padding: "14px 0px",
    margin: "0px 14px",
    alignItems: "center",
    display: "flex",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    alignItems: "flex-start",
    flex: 1,
  },
  label1: { color: colors.text, fontSize: 14 },
  label3: { color: colors.gray, fontSize: 10 },
  label4: {
    color: colors.gray,
    fontSize: 12,
    "& span": { color: "#333" },
    marginTop: "5px",
  },
  label2: {
    fontSize: "16px",
    color: "#000",
  },
  buttons: {
    margin: "13px 13px 20px 13px",
    "& button": {
      flex: 1,
      padding: "6px",
      marginLeft: "5px",
      "& span": {
        fontSize: "14px",
      },
      "&:first-child": {
        backgroundColor: "#fff",
        border: "1px solid " + colors.primary,
        "& span": {
          color: colors.primary + " !important",
        },
        marginLeft: "0px",
        marginRight: "5px",
      },
    },
  },
  orderState: { color: colors.primary, fontSize: "12px" },
});
