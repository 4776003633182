export const actionActionTypes = {
  show: "action/show",
  hide: "action/hide",
  open: "action/open",
  close: "action/close",
};

export const actionShow =
  (message, title, onPress, content, closable) => (dispatch) => {
    dispatch({
      type: actionActionTypes.show,
      message,
      onPress: onPress || [],
      title: title || ["닫기", "확인"],
      content,
      closable,
    });
  };

export const actionError = (error) => (dispatch) => {
  dispatch({
    type: actionActionTypes.open,
    onlyConfirm: true,
    message: error.response
      ? error.response?.data
        ? (error.response?.data.error ? error.response?.data.error + " " : "") +
          (error.response?.data.detail
            ? error.response?.data.detail
            : error.response?.data.message) +
          (error.response?.data.code ? " " + error.response?.data.code : "")
        : error.response.message
      : error.message
      ? error.message
      : error.msg || error,
  });
};

export const actionHide = () => (dispatch) => {
  dispatch({ type: actionActionTypes.hide });
};

export const actionOpen = (message, onClick, t1, t2) => (dispatch) => {
  dispatch({
    type: actionActionTypes.open,
    message,
    onClick,
    cancelTitle: t1,
    confirmTitle: t2,
  });
};

export const actionClose = () => (dispatch) => {
  dispatch({ type: actionActionTypes.close });
};
