import React, { useState } from "react";
import { useHistory } from "react-router";
import { ButtonBase, makeStyles } from "@material-ui/core";

import colors from "../../libs/colors";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import routes from "../../libs/routes";
import BasicLayout from "../../layouts/basic/BasicLayout";

export default function UserGuide({ navigate }) {
  const classes = useStyle();
  const history = useHistory();
  // console.log(navigate);
  const locationState = history.location.state || {};
  const { url, title } = locationState;

  return (
    <BasicLayout
      stackTopbar={{
        title: title || "",
        option: (
          <ButtonBase
            onClick={() => {
              history.replace(routes.main);
            }}>
            <img
              src={images.home}
              style={{ width: 20, height: 20, marginRight: "16px" }}
            />
          </ButtonBase>
        ),
      }}>
      <Flex className={classes.root}>
        {url && (
          <iframe
            src={url}
            style={{
              width: "100%",
              height: window.innerHeight - 60,
              border: 0,
              top: 0,
              left: 0,
            }}></iframe>
        )}
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  root: {
    flex: 1,
    width: "100%",
    height: "auto",
  },
});
