import React from "react";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import BasicLayout from "../../layouts/basic/BasicLayout";
import StackTopbar from "../../layouts/basic/StackTopbar";

const HtmlViewer = () => {
  const history = useHistory();

  const { title = "", content } = history?.location?.state || {};

  return (
    <BasicLayout white>
      <StackTopbar
        title={title}
        border
        style={{ position: "fixed", width: "100%" }}
      />
      <Flex style={{ overflowY: "auto", marginTop: 60 }}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Flex>
    </BasicLayout>
  );
};

export default HtmlViewer;
