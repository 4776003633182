import React from "react";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import { formatTime } from "../../../../../libs/utils";

const CommonDetail = ({
  tradeNumber,
  completedAt,
  tradeType,
  tradeTypeKor,
  addTradeType,
  yourName,
}) => {
  return (
    <>
      <ContentRow>
        <DetailTitle>거래번호</DetailTitle>
        <DetailContent>{tradeNumber}</DetailContent>
      </ContentRow>
      <ContentRow>
        <DetailTitle>거래일시</DetailTitle>
        <DetailContent className="spoqa">
          {formatTime(completedAt, "YYYY.MM.DD HH:mm")}
        </DetailContent>
      </ContentRow>
      <ContentRow>
        <DetailTitle>거래구분</DetailTitle>
        <DetailContent>
          {tradeTypeKor}
          {addTradeType && `(${addTradeType})`}
        </DetailContent>
      </ContentRow>
      {yourName && (
        <ContentRow>
          <DetailTitle>
            {tradeType === "자산 보냄" ? "받는사람" : "보낸사람"}
          </DetailTitle>
          <DetailContent>{yourName}</DetailContent>
        </ContentRow>
      )}
      <Flex style={{ border: "1px dotted #b7b7b7", margin: "22px 0" }} />
    </>
  );
};

const ContentRow = styled(Flex)`
  &:not(:first-of-type) {
    margin-top: 11px;
  }
  flex-direction: row;
`;

const DetailTitle = styled(Span)`
  display: flex;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #999999;
`;

const DetailContent = styled(Span)`
  display: flex;
  flex: 1;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #444444;
  font-weight: 500;
  justify-content: flex-end;
  text-align: right;
  align-items: flex-end;
`;

export default CommonDetail;
