import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function PolicyPopup({ onClose }) {
  const classes = useStyle();
  return (
    <Flex className={classes.root}>
      <Flex row className={classes.header}>
        <Text className={classes.label}>이용약관</Text>
        <ButtonBase onClick={onClose}>
          <img alt="cloase" src={images.close} className={classes.close} />
        </ButtonBase>
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: colors.background,
  },
  header: { alignItems: "center", justifyContent: "space-between" },
  close: {
    width: "18px",
    height: "18px",
    margin: "16px 16px",
  },
  label: {
    margin: "16px",
    color: colors.text,
    fontSize: "18px",
  },
});
