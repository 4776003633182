import React from "react";

function ReviewCount() {
  return (
    <div>
      리뷰 <span className="vreview-review-count">0</span>
    </div>
  );
}

export default ReviewCount;
