import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function Checkbox({
  className,
  disabled,
  checked,
  onCheckedChange,
  label,
  labelSize,
  optionComponent,
}) {
  const classes = useStyle();
  return (
    <ButtonBase
      disabled={disabled}
      onClick={() => {
        onCheckedChange(!checked);
      }}
      className={`${classes.root} ${className}`}
      row
    >
      <img
        alt="check"
        src={checked ? images.checkon : images.checkoff}
        className={classes.checkbox}
      />
      <Text
        style={{ ...(labelSize && { fontSize: labelSize }) }}
        className={classes.check}
      >
        {label}
      </Text>
      {optionComponent}
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  root: { alignItems: "center", display: "flex", alignSelf: "stretch" },
  check: {
    fontSize: 16,
    color: colors.text,
    marginLeft: 8,
    textAlign: "left",
    flex: 1,
    alignSelf: "flex-start",
    display: "flex",
  },
  checkbox: {
    width: 20,
    height: 14,
  },
});
