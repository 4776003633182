import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import InquiryListItem from "../inquiry-listitem/InquiryListItem";
export default function ProductInquiry({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <Flex>
      <Flex className={classes.header}>
        <Text className={classes.title}>
          문의하신 내용은 상담사가 확인 후 답변을 드립니다.
        </Text>
        <Button
          onClick={() => {
            history.push(routes.productInquiryAdd);
          }}
          className={classes.button}
          label="문의하기 작성"
        />
      </Flex>

      <InquiryListItem pri />
      <InquiryListItem reply />
      <InquiryListItem />
    </Flex>
  );
}

const useStyle = makeStyles({
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 13px",
  },
  button: {
    padding: "10px",
    alignSelf: "stretch",
    margin: "0px 30px",
  },
  title: {
    color: "#000",
    fontSize: "12px",
    marginBottom: "13px",
  },
});
