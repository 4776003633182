import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { parsePhoneNumber, requestChangePassword } from "../../libs/utils";
import { messageError } from "../../redux/message/MessageActions";
import ListItem from "./ListItem";
import UsingShopDialog from "../../components/find-shop/UsingShopDialog";

export default function EditProfile({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();

  const { name: authName, sns } = useSelector((s) => s.auth);
  const {
    name,
    email = "",
    phone = "",
    usingShopId = 0,
    usingShopName = "",
    recommendShopId = 0,
    recommendShopName = "",
  } = useSelector((s) => s.user);

  const [mainAddress, setMainAddress] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [resignDays, setResignDays] = useState(30);
  const [isVisible, setIsVisible] = useState(false);
  const { address } = mainAddress;

  useEffect(() => {
    APIS.getUserAddress().then(({ data: { success, data = [] } }) => {
      if (success) {
        const userMainAddress = data.find(({ isDefault }) => isDefault);
        userMainAddress && setMainAddress(userMainAddress);
      }
    });

    APIS.getEnvConfig("USER_RESIGNUP_DAYS").then(
      ({ data: { success, data } }) => {
        success && setResignDays(data?.value);
      }
    );
  }, []);
  return (
    <BasicLayout stackTopbar={{ title: "회원정보 수정", border: true }}>
      <UsingShopDialog
        isVisible={isVisible}
        onCloseModal={() => {
          setIsVisible(false);
        }}
        purpose={"UPDATE_USING_SHOP"}
      />
      <Flex className={classes.header}>
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {name || authName}
          <Text>님</Text>
        </Text>
      </Flex>
      {/* <ListItem
        onClick={() => {
          history.push(routes.addressManagement);
        }}
      >
        배송지 관리
      </ListItem> */}
      <ListItem
        onClick={() => {
          history.push(routes.setAddress, { mainAddress });
        }}
        sub={address}>
        주소(배송지)
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.selfVerification, {
            purpose: "UPDATE_PHONENUMBER",
          });
        }}
        sub={parsePhoneNumber(phone, "-")}>
        휴대폰번호
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.editEmail);
        }}
        sub={email}>
        이메일
      </ListItem>
      <ListItem
        onClick={() => {
          history.push(routes.manageAccount);
        }}>
        입출금 계좌 관리
      </ListItem>
      {sns === "LOGIN_ID" && (
        <ListItem
          onClick={() => {
            requestChangePassword(() => {
              dispatch(messageError("최신 버전의 앱이 필요합니다."));
            });
          }}>
          로그인 비밀번호 변경
        </ListItem>
      )}

      <ListItem
        onClick={() => {
          history.push(routes.selfVerification, {
            purpose: "UPDATE_SECURE_PASSWORD",
          });
        }}>
        결제 비밀번호 변경
      </ListItem>
      <ListItem
        disabled={true}
        sub={recommendShopId === 1 ? "본사" : recommendShopName}>
        추천 대리점
      </ListItem>
      <ListItem
        onClick={() => {
          setIsVisible(true);
        }}
        sub={usingShopId === 1 ? "없음" : usingShopName}>
        이용 대리점
      </ListItem>
      {/* <ListItem onClick={() => setModalVisible(true)}>탈퇴하기</ListItem>
      {modalVisible && (
        <WithdrawalModal
          onClose={() => setModalVisible(false)}
          resignDays={resignDays}
        />
      )} */}
      <ListItem onClick={() => history.push(routes.leave)}>탈퇴하기</ListItem>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  header: { padding: "28px 20px", borderBottom: "3px solid #eee" },
  label: {
    lineHeight: 1,
    color: colors.text,
    fontSize: "19px",
    " & span": {
      color: colors.gray,
      fontSize: "12px",
    },
  },
  email: {
    fontSize: 14,
    color: colors.text,
    marginTop: 6,
  },
  withdrawalWrap: {
    flex: 1,
    alignSelf: "flex-end",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "30px 0",
    alignItems: "center",
  },
  btnCall: {
    width: 200,
    backgroundColor: "#FC5710",
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    padding: 5,
    borderRadius: 5,
    marginTop: 10,
  },
});
