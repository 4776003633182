import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import BasicLayout from "../../layouts/basic/BasicLayout";
import routes from "../../libs/routes";
import NoticeDetail from "./NoticeDetail";
import NoticeItem from "./NoticeItem";
import { axiosDispatch } from "../../services/network";
import consts, { STORAGE_KEY } from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import search from "../../assets/images/ic_search.png";
import StackTopbar from "../../layouts/basic/StackTopbar";
import Flex from "../../components/flex/Flex";

export default function Notice({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const [keyword, setKeyword] = useState("");
  const [fixedList, setFixedList] = useState([]);
  const [openList, setOpenList] = useState([]);
  const [filterOpenList, setFilterOpenList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(loadingStart);
    dispatch(
      axiosDispatch(
        {
          method: "get",
          url: consts.apiBaseUrl + "/api/v1/user/notice",
        },
        (err, res) => {
          dispatch(loadingEnd);
          if (err) {
            dispatch(messageError(err));
          } else {
            const data = res.data.data;
            setFixedList(data.fixed);
            setOpenList(data.open);
            setFilterOpenList(data.open);
          }
        }
      )
    );
  }, []);

  const onChangKeyword = (e) => {
    const { value } = e.target;
    const filterList = openList?.filter?.(
      (item) => item.subject.includes(value) || item.content.includes(value)
    );
    setFilterOpenList(filterList);
    setKeyword(value);
  };

  return (
    <BasicLayout white>
      <StackTopbar
        title="공지사항"
        border
        onGoBack={() => {
          history.goBack();
          // history.replace(routes.menu);
        }}
        style={{ position: "fixed", width: "100%", zIndex: 1000 }}
      />
      {match.params?.noticeId && (
        <NoticeDetail
          onClose={() => {
            history.goBack();
          }}
          noticeId={match.params?.noticeId}
        />
      )}
      <div className="search_new" style={{ marginTop: "60px" }}>
        <div id="topsearch">
          <input type="hidden" name="" value="" />
          <input type="hidden" name="" value="" />
          <input
            type="image"
            src={search}
            title="검색질의보내기"
            alt="검색하기"
            className="bt"
          />
          <input
            type="text"
            name="keyword"
            id="keyword"
            className="ft_noti ph"
            title="키워드입력"
            value={keyword}
            onChange={onChangKeyword}
            placeholder="검색어를 입력해주세요"
          />
        </div>
      </div>
      <div style={{ height: "146px" }} />
      {!!(fixedList.length || openList.length) ? (
        <>
          {fixedList.map((x, i) => {
            return (
              <NoticeItem
                onClick={() => {
                  history.push(routes.noticeDetail(i + 1), {
                    id: x.id,
                  });
                }}
                {...x}
                data={x}
                key={i.toString()}
              />
            );
          })}
          {(keyword.length > 0 ? filterOpenList : openList).map((x, i) => {
            return (
              <NoticeItem
                onClick={() => {
                  history.push(routes.noticeDetail(i + 1), {
                    id: x.id,
                  });
                }}
                {...x}
                data={x}
                key={i.toString()}
              />
            );
          })}
        </>
      ) : (
        <Flex style={{ marginTop: 20, flex: 1, alignItems: "center" }}>
          공지사항이 없습니다.
        </Flex>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({});
