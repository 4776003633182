import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import InputShadow from "../../components/input-shadow/InputShadow";
import Text from "../../components/text/Text";
import TopbarClose from "../../components/topbar-close/TopbarClose";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { toastShow } from "../../redux/toast/ToastActions";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr } from "../../services/utils";

export default function VerifyBankCode({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useSelector((s) => s.user);
  const state = history.location.state;
  const number = state?.number;
  const setNumber = (v) => {
    history.replace(history.location.pathname, {
      ...history.location.state,
      number: v,
    });
  };

  useEffect(() => {
    if (id) {
      history.replace(routes.main, {});
    }
  }, [history.location.key, id]);

  const checkValue = () => {
    if (!state?.number) {
      dispatch(
        messageShow(
          "입금자명(코드 " + state.bankCode.len + "자리)을 입력해 주세요."
        )
      );
    } else {
      axios
        .post(
          consts.apiBaseUrl +
            "/api/checkPay/confirmAccount" +
            objToQueryStr({
              verify_tr_dt: state.bankCode.verify_tr_dt,
              verify_tr_no: state.bankCode.verify_tr_no,
              verify_val: number,
            }),
          {}
        )
        .then((res) => {
          const data = res.data;
          if (data.return_code === "0000") {
            dispatch(toastShow("계좌 인증 되었습니다."));
            history.push("/verifyBank", {
              ...history.location.state,
              bankVerify: true,
            });
          } else {
            setErrorMessage(
              data.return_msg ||
                "계좌정보가 일치하지 않습니다. 다시 확인해 주세요."
            );
            throw (
              data.return_msg ||
              "계좌정보가 일치하지 않습니다. 다시 확인해 주세요."
            );
          }
        })
        .catch((e) => {
          dispatch(messageError(e));
        });
    }
  };

  useEffect(() => {
    if (errorMessage && number) {
      setErrorMessage("");
    }
  }, [errorMessage, number]);

  return (
    <BasicLayout backgroundGray>
      <TopbarClose />
      <Text className={classes.label} font={fonts.notoSansKRMedium}>
        1원을 보냈습니다.
      </Text>
      <Text className={classes.label2}>
        본인의 계좌인지 확인하기 위해 입력하신 계좌로 금방금방이 1원을
        입금했습니다.
      </Text>
      <InputShadow
        value={number}
        number
        onChange={setNumber}
        placeholder={`입금자명 ${state.bankCode.len}자리 코드 입력`}
        className={classes.input}
      />
      <Button
        onClick={() => {
          if (history.location.state?.bankVerify) {
            history.push(routes.bankPassword, {
              register: true,
              ...history.location.state,
            });
          } else {
            checkValue();
          }
        }}
        className={classes.button}
        label={"확인"}
      />
      {errorMessage && <Text className={classes.error}>{errorMessage}</Text>}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: 24,
    margin: "70px 30px 0px 30px",
    lineHeight: 1.5,
    color: colors.text,
  },
  button: {
    margin: "24px 30px 0px 30px",
  },
  label2: {
    color: colors.gray,
    marginBottom: 30,
    fontSize: 15,
    margin: "0px 30px",
  },
  input: { margin: "10px 30px 0px 30px" },
  inputmenu: {
    margin: "40px 30px 0px 30px",
  },
  error: {
    color: colors.textRed,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
  },
  success: {
    color: colors.text,
    textAlign: "center",
    marginTop: "10px",
    fontSize: "14px",
    marginBottom: "30px",
  },
  name: {
    margin: "10px 30px 0px 30px",
    backgroundColor: colors.dddddd,
    color: colors.text,
    borderRadius: "3px",
    padding: "13px 16px 13px 30px",
    fontSize: "15px",
  },
  bank: {
    "& select": {
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      appearance: "none",
      width: "100%",
      padding: "13px 0px 13px 30px",
      "font-size": "15px",
      border: "none",
      minHeight: 56,
      color: "#333333",
    },
    display: "flex",
    "background-color": "#ffffff",
    padding: "0px 0px 0px 0px",
    alignItems: "center",
    margin: "40px 30px 0px 30px",
    "border-radius": "3px",
    "justify-content": "space-between",
    "font-family": "NotoSansKR-Regular",
    position: "relative",
    "line-height": 2,
  },
  down: {
    position: "absolute",
    right: 16,
    width: "16px",
    height: "10px",
  },
});
