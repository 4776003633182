import React from "react";
import styled from "styled-components";
// import { Close } from "@material-ui/icons";
// import { useDispatch, useSelector } from "react-redux";
// import { closeModal } from "../../redux/modal/ModalReducer";

function Modal({ onClickBackground, children, styleProps }) {
  //   const dispatch = useDispatch();
  //   const modal = useSelector(({ modal }) => modal);

  //   const { visible, children, maskClosable, closable, onClose } = modal;

  //   const onMaskClick = (e) => {
  //     if (e.target === e.currentTarget) {
  //       close(e);
  //     }
  //   };

  //   const close = (e) => {
  //     onClose && onClose(e);
  //     dispatch(closeModal());
  //   };

  //   if (!visible) {
  //     return null;
  //   }
  const { overlay = {}, wrapper = {} } = styleProps;

  return (
    <>
      <ModalOverlay style={{ ...overlay }} />
      <ModalWrapper onClick={onClickBackground || null} tabIndex="-1">
        {/* <ModalInner tabIndex="0" className="modal-inner">
          {closable && (
            <Close
              fontSize="large"
              style={{ position: "absolute", top: 0, right: 0 }}
              onClick={close}
            />
          )}
          {children}
        </ModalInner> */}
        {children}
      </ModalWrapper>
    </>
  );
}

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  /* flex: 1;
  justify-content: center;
  align-items: center; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  padding: 40px 20px;
  align-self: center;
`;

export default Modal;
