import React, { useEffect, useState } from "react";
import { postMessage } from "../../libs/utils";
import closewhite from "../../assets/images/closewhite.png";
import coach_check from "../../assets/images/coach_check.png";
import { useHistory } from "react-router-dom";
import routes from "../../libs/routes";
import { STORAGE_KEY } from "../../libs/consts";
import { formatTime } from "../../services/utils";

const Coaching = ({ setCoachingVisible }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";

      // App 실행 시 1회만 띄우기 위해서 session에 값을 저장함
      sessionStorage.setItem(
        STORAGE_KEY.NO_COACHING,
        formatTime(new Date(), "YYYYMMDDHHmmss")
      );
    };
  }, []);

  const noCoaching = () => {
    localStorage.setItem(
      STORAGE_KEY.NO_COACHING,
      formatTime(new Date(), "YYYYMMDDHHmmss")
    );
    onClose();
  };
  const onClose = () => {
    setCoachingVisible(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        zIndex: 40,
        overflow: "auto",
        background: "white",
      }}
    >
      <div className="coaching_wrap">
        <div className="coaching">
          <div className="none_btn" onClick={noCoaching} style={{ zIndex: 41 }}>
            <img src={coach_check} />
            다시 보지 않기
          </div>
          <div className="close_btn" onClick={onClose} style={{ zIndex: 41 }}>
            <img src={closewhite} alt="X" />
          </div>
          <div className="coaching_main" />
          <div className="coaching_tab" />
        </div>
      </div>
      {/* <div className="coaching_wrap">
        <div className="coaching">
          <div className="none_btn" onClick={noCoaching}>
            다시보지않기
          </div>
          <div className="close_btn" onClick={onClose}>
            <img src={closewhite} alt="X" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Coaching;
