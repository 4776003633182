import React from "react";
import styled from "styled-components";

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ direction }) => (direction ? `flex-direction:${direction};` : "")}
`;

export default SpaceBetween;
