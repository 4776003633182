import { ButtonBase, makeStyles } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function ButtonChecked({ label, className }) {
  const classes = useStyle();
  return (
    <ButtonBase disabled className={`${classes.button} ${className}`}>
      <img alt="check" src={images.checkon} className={classes.check} />
      <Text font={fonts.notoSansKRBold} className={`${classes.label} `}>
        {label}
      </Text>
    </ButtonBase>
  );
}

const useStyle = makeStyles({
  label: {
    color: colors.text,
    fontSize: 16,
  },
  button: {
    borderRadius: "3px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
    flexDirection: "row",
    backgroundColor: colors.white,
  },
  check: {
    width: "20px",
    height: "14px",
    position: "absolute",
    left: 20,
  },
});
