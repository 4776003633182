import {
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import AppraiseItem from "../../components/appraise-item/AppraiseItem";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Flex from "../../components/flex/Flex";
import More from "../../components/more/More";
import Pager from "../../components/pager/Pager";
import PhysicalWithDrawalItem from "../../components/physical-withdrawal-item/PhysicalWithDrawalItem";
import PolicyAgreePopup from "../../components/policy-agree-popup/PolicyAgreePopup";
import ReviewItem from "../../components/review-item/ReviewItem";
import SearchTopbar from "../../components/search-topbar/SearchTopbar";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";
import { actionShow } from "../../redux/action/ActionActions";
import { listShow } from "../../redux/list/ListActions";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import StackTopbar from "../../layouts/basic/StackTopbar";
import { useInView } from "react-intersection-observer";
import { useGoodsListQuery } from "../../hooks/queries/goods";
import { useQuery, useQueryClient } from "react-query";

const GOODS_SORT = [
  { label: "낮은가격순", value: "LOWPRICE" },
  { label: "높은가격순", value: "HIGHPRICE" },
  { label: "최신순", value: "LATEST" },
];
export default function PhysicalWithDrawal({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const {
    goods_sort = "",
    category_id = 0,
    type = "GOLD",
    page = 0,
    isLast = false,
    items = [],
    scrollInfo = null,
  } = locationState;

  const [anchorEl, setAnchorEl] = useState(null);

  // const [categories, setCategories] = useState([]);
  const [marketPrice, setMarketPrice] = useState(0);
  const [goodsCategory, setGoodsCategory] = useState(category_id);
  const [sortType, setSortType] = useState(GOODS_SORT[0]["value"]);
  const [goodsList, setGoodsList] = useState([]);

  const [moreRef, inMore] = useInView();
  const queryClient = useQueryClient();
  const totalList = queryClient.getQueryData("goodsAllList");

  const { data: categories = [] } = useQuery(
    ["goodsCategory"],
    () => {
      // console.log(`useQuery`);
      return APIS.getGoodsCategoryList().then(({ data: { success, data } }) => {
        if (success) {
          // console.log(`location state category id`, category_id);
          data = data.filter((goods) => goods?.goodsIdList.length !== 0);
          if (category_id !== 0) setGoodsCategory(category_id);
          else setGoodsCategory(data[0].id);
          return data;
        }
      });
    },
    {
      initialData: [],
      enabled: Array.isArray(totalList) && totalList.length !== 0,
    }
  );

  useEffect(() => {
    APIS.getCurrentClosingMarketPrice(type).then(({ data: { data } }) => {
      const split = data.split(",");
      setMarketPrice(split[0]);
    });
  }, [type]);

  useEffect(() => {
    if (scrollInfo) {
      window.scrollTo(0, scrollInfo.Y);
      handleChangeLocationState({ scrollInfo: null });
    }
  }, []);

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleSetGoodsList = useCallback(
    (categoryId) => {
      if (
        Array.isArray(totalList) &&
        totalList.length !== 0 &&
        categories.find((item) => item.id === categoryId) !== undefined
      ) {
        setGoodsCategory(categoryId);
        let list = categories.find(
          (item) => item.id === categoryId
        )?.goodsIdList;
        let arr = [];
        list.forEach((id) => {
          if (totalList.find((item) => item.id === id)) {
            arr.push(totalList.find((item) => item.id === id));
          }
        });
        setGoodsList(setSort(arr));
      }
    },
    [categories, goodsList, goodsCategory, sortType]
  );

  const [ingFetch, setIngFetch] = useState(false);

  const setSort = useCallback(
    (data) => {
      if (!data || !Array.isArray(data) || data.length === 0) return [];
      // let func = () => {};
      let func = () => {};
      if (sortType === "LATEST") {
        func = (x, y) => y.id - x.id;
      } else if (sortType === "LOWPRICE") {
        func = (x, y) => x.totalPrice - y.totalPrice;
      } else if (sortType === "HIGHPRICE") {
        func = (x, y) => y?.totalPrice - x?.totalPrice;
      }
      return data.sort((x, y) => func(x, y));
    },
    [sortType]
  );

  useMemo(() => {
    if (goodsCategory !== 0 && categories.length !== 0)
      handleSetGoodsList(goodsCategory);
  }, [categories, goodsCategory, sortType]);

  return (
    <MainLayout white topbar={<StackTopbar border title="실물인출" />}>
      <Text className={classes.label2}>
        고객님의 자산을 실물로 인출하실 수 있습니다.
      </Text>
      <Tabs
        value={parseInt(goodsCategory)}
        TabIndicatorProps={{ style: { background: "black" } }}
        style={{
          borderBottom: "1px solid #eee",
        }}
        onChange={(event, value) => {
          const select = categories.find(({ id }) => id === value);
          if (goodsCategory !== value) handleSetGoodsList(value);
          handleChangeLocationState({
            category_id: value,
            type: select.goldOrSilver,
          });
        }}
        variant="scrollable"
        scrollButtons="on"
        // allowscrollbuttonsmobile="true"
        aria-label="scrollable force tabs example">
        {categories &&
          categories.map((category) => {
            if (category.deleted) {
              return null;
            } else {
              return (
                <Tab
                  key={category.id}
                  color={colors.gray}
                  label={category.kind}
                  value={category.id}
                />
              );
            }
          })}
      </Tabs>

      <Flex className={classes.main}>
        <Flex row className={"row-right " + classes.header}>
          <ButtonBase
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            className="row-right"
            style={{ textAlign: "right", margin: "0 0 0 auto" }}>
            <Text className={classes.legnth2}>
              {(GOODS_SORT.find(({ value }) => value === sortType) || {}).label}
            </Text>
            <img src={images.down} className={"icon"} />
          </ButtonBase>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}>
            {GOODS_SORT.map(({ label, value }) => {
              return (
                <MenuItem
                  key={value}
                  style={{ minHeight: "auto" }}
                  onClick={() => {
                    // console.log(value);
                    setAnchorEl(null);
                    setSortType(value);
                    handleSetGoodsList(goodsCategory);
                  }}>
                  {label}
                </MenuItem>
              );
            })}
          </Menu>
        </Flex>

        {goodsList &&
          goodsList.map((x, i) => {
            return (
              <div key={x.id}>
                <PhysicalWithDrawalItem
                  rows={x}
                  sPrice={marketPrice}
                  settype={type}
                />
              </div>
            );
          })}
        <div ref={moreRef} />
      </Flex>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  tabs: {
    alignSelf: "stretch",
  },
  label: {
    alignSelf: "stretch",
    padding: "12px",
    borderBottom: "1px solid #eee",
    fontSize: "12px",
    color: colors.text,
  },

  main: {
    flexGrow: 1,
  },
  header: {
    padding: "20px 13px 10px 13px",
    borderBottom: "1px solid #eee",
  },
  button: {
    backgroundColor: colors.primary,
    color: colors.white,
    fontSize: "12px",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: "20px",
    padding: "10px 16px",
    lineHeight: 1,
  },

  buttonRadio: {
    flex: 1,
    backgroundColor: "#dddddd",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
    padding: "10px",
    color: "#999",
  },
  buttonRadioSel: {
    backgroundColor: colors.primary,
    color: "#fff",
  },
  label2: { fontSize: "14px", color: "#000", margin: "20px 13px" },
  legnth: {
    marginLeft: "10px",
    fontSize: "12px",
    color: colors.text,
  },
  legnth2: {
    marginRight: "10px",
    fontSize: "12px",
    color: colors.text,
  },
});
