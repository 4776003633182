import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import BasicLayout from "../../../layouts/basic/BasicLayout";
import Modal from "../../../components/modal/Modal";
import Flex from "../../../components/flex/Flex";
import Text from "../../../components/text/Text";
import { ButtonBase } from "@material-ui/core";
import colors from "../../../libs/colors";
import * as APIS from "../../../libs/apis";
import { formatTime, numFormat } from "../../../libs/utils";
import routes from "../../../libs/routes";
import { actionError } from "../../../redux/action/ActionActions";
import {
  loadingEnd,
  loadingStart,
} from "../../../redux/loading/LoadingActions";
import ic_up from "../../../assets/images/up2.png";
import ic_down from "../../../assets/images/down2.png";

import {
  BorderLine,
  Buttons,
  Description,
  FeeTotal,
  FeeTotalDate,
  FeeTotalTitle,
  FlexRow,
  Indent,
  Message,
  ModalContentWrap,
  ModalWrap,
  Tab,
  TabTitle,
  Tabs,
  TextBorder,
  TextWithNumber,
  Title,
  TitleBottomBorder,
  WeightContent,
  WeightSubTitle,
  WeightSummary,
  WeightTitle,
} from "./style";
import StorageFeePayModal from "./StorageFeePayModal";

export default function StorageFee({}) {
  const [tabs, setTabs] = useState("weight");
  const [modalVisible, setModalVisible] = useState(false);
  const [fees, setFees] = useState({
    totalFeeKrw: 0,
    storageFeeMonthDtoList: [],
    beforeStorageFeeList: [],
  });
  const [isOpened, setIsOpened] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const today = new Date();
  const todayMonth = today.getMonth() + 2;
  const goldFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "GOLD"
  ) || {
    assetType: "GOLD",
    feeGram: 0,
    feeKrw: 0,
  };
  const silverFee = fees?.beforeStorageFeeList.find(
    (item) => item.assetType === "SILVER"
  ) || {
    assetType: "SILVER",
    feeGram: 0,
    feeKrw: 0,
  };

  const handleClickDropdown = (type) => {
    if (isOpened.length !== 0 && isOpened.includes(type)) {
      setIsOpened(isOpened.replace(type, ""));
    } else {
      setIsOpened(isOpened.concat(type));
    }
  };

  const handleClickConfirm = () => {
    if (fees?.storageFeeMonthDtoList.length === 0) {
      dispatch(actionError("납부하실 보관료가 없습니다."));
      return;
    }
    history.push(routes.commonSecureKeypad, {
      type: "STORAGE_FEE",
      payload: {
        totalFeeKrw: `${fees.totalFeeKrw}`,
      },
      buttonText: "납부하기",
    });
  };

  const init = () => {
    let todayYear = today.getFullYear();
    dispatch(loadingStart);
    APIS.getStorageFee()
      .then(({ data: { success, message, data } }) => {
        if (success) {
          let yearList = [
            {
              year: todayYear,
              data: data?.storageFeeMonthDtoList.filter((item) => {
                if (new Date(item.feeChargedDate).getFullYear() === todayYear)
                  return item;
              }),
            },
          ];
          if (
            data?.storageFeeMonthDtoList.find(
              (item) =>
                new Date(item.feeChargedDate).getFullYear() !== todayYear
            )
          ) {
            let year = new Date(
              data?.storageFeeMonthDtoList.find(
                (item) =>
                  new Date(item.feeChargedDate).getFullYear() !== todayYear
              )?.feeChargedDate
            ).getFullYear();
            let obj = {
              year: year,
              data: data?.storageFeeMonthDtoList.filter((item) => {
                if (new Date(item.feeChargedDate).getFullYear() === year)
                  return item;
              }),
            };
            if (todayYear > year) {
              yearList.push(obj);
            } else {
              yearList.unshift(obj);
            }
          }
          // console.log("yearList", yearList);
          setFees({
            ...data,
            yearList,
          });
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  useMemo(() => {
    init();
  }, []);

  return (
    <BasicLayout
      stackTopbar={{
        title: "보관료",
      }}>
      <Tabs>
        <Tab
          onClick={() => {
            setTabs("weight");
          }}>
          <TabTitle isActive={tabs === "weight"}>유료 보관 중량</TabTitle>
          <TitleBottomBorder isActive={tabs === "weight"} />
        </Tab>
        <Tab
          onClick={() => {
            setTabs("fee");
          }}>
          <TabTitle isActive={tabs === "fee"}>미납 보관료</TabTitle>
          <TitleBottomBorder isActive={tabs === "fee"} />
        </Tab>
      </Tabs>
      {tabs === "weight" && (
        <>
          {goldFee?.feeKrw === 0 && silverFee.feeKrw === 0 ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div style={{}}>
                {todayMonth}월 예정 유료 보관중량이 없습니다.
              </div>
            </div>
          ) : (
            <WeightContent>
              <WeightTitle>{todayMonth}월 예정 유료 보관 중량</WeightTitle>
              <WeightSubTitle>
                <span
                  style={{
                    padding: "2px 6px",
                    backgroundColor: "#ffeee3",
                    borderRadius: 4,
                  }}>
                  매일 0시에 갱신됩니다
                </span>
              </WeightSubTitle>
              <WeightSummary>
                <div
                  style={{
                    padding: "12px 16px",
                    borderBottom: "1px solid #eaeaea",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <span>총 보관료</span>
                  <span>
                    {numFormat(goldFee?.feeKrw + silverFee?.feeKrw)}원
                  </span>
                </div>
                <div
                  style={{
                    padding: "12px 16px",
                  }}>
                  <div
                    style={{
                      paddingBottom: 10,
                      borderBottom: "1px dotted #eaeaea",
                    }}>
                    <div style={{ marginBottom: 5 }}>
                      <span>・ 금</span>
                      <span></span>
                    </div>
                    <FlexRow style={{ marginBottom: 2 }}>
                      <span>- 유료 보관 중량</span>
                      <span>
                        {goldFee?.feeGram ? numFormat(goldFee?.feeGram) : 0}g
                      </span>
                    </FlexRow>
                    <FlexRow>
                      <span>- 보관료</span>
                      <span
                        style={{
                          color: "#333",

                          fontWeight: "bold",
                        }}>
                        {goldFee?.feeKrw ? numFormat(goldFee?.feeKrw) : 0}원
                      </span>
                    </FlexRow>
                  </div>
                  <div style={{ paddingTop: 10 }}>
                    <div style={{ marginBottom: 5 }}>
                      <span>・ 은</span>
                      <span></span>
                    </div>
                    <FlexRow style={{ marginBottom: 2 }}>
                      <span>- 유료 보관 중량</span>
                      <span>
                        {silverFee?.feeGram ? numFormat(silverFee?.feeGram) : 0}
                        g
                      </span>
                    </FlexRow>
                    <FlexRow>
                      <span>- 보관료</span>
                      <span
                        style={{
                          color: "#333",
                          fontWeight: "bold",
                        }}>
                        {silverFee?.feeKrw ? numFormat(silverFee?.feeKrw) : 0}원
                      </span>
                    </FlexRow>
                  </div>
                </div>
              </WeightSummary>
              <FlexRow style={{}}>
                <Title>보관료 개요</Title>
                <div
                  className={`dropdown-arrow ${
                    isOpened.includes("intro") ? "active" : ""
                  }`}
                  onClick={() => {
                    handleClickDropdown("intro");
                  }}></div>
              </FlexRow>
              {isOpened.includes("intro") ? (
                <Description>
                  고객님들의 보유 자산에 대한{" "}
                  <TextBorder>
                    보관 부대비용 증가 및 안전 보안상의 이유로 불가피하게 24년
                    2월 1일부터 보관료 정책을 시행합니다.
                    <br />
                  </TextBorder>
                  금방금방 보관료는 고객님의 자산을 안전하게 보관하기 위한{" "}
                  <TextBorder>최소한의 비용만 부과하기 위해 산정</TextBorder>
                  되었습니다.
                  <br /> 많은 이해와 양해 부탁드립니다.
                </Description>
              ) : (
                <BorderLine />
              )}
              <FlexRow style={{ margin: "8px 0" }}>
                <Title>무료 보관기간</Title>
                <div
                  className={`dropdown-arrow ${
                    isOpened.includes("period") ? "active" : ""
                  }`}
                  onClick={() => {
                    handleClickDropdown("period");
                  }}></div>
              </FlexRow>
              {isOpened.includes("period") ? (
                <Description>
                  자산 생성일의{" "}
                  <TextBorder>익월부터 2개월 무료 보관기간</TextBorder> 서비스가
                  제공됩니다.
                </Description>
              ) : (
                <BorderLine />
              )}
              <FlexRow style={{ margin: "8px 0" }}>
                <Title>보관료 기준</Title>
                <div
                  className={`dropdown-arrow ${
                    isOpened.includes("standard") ? "active" : ""
                  }`}
                  onClick={() => {
                    handleClickDropdown("standard");
                  }}></div>
              </FlexRow>
              {isOpened.includes("standard") ? (
                <Description>
                  <div style={{ display: "flex" }}>
                    <Indent>1. </Indent>
                    <TextWithNumber>
                      금 : 1,001g 이상부터 1g당 100원입니다.
                    </TextWithNumber>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <Indent>2. </Indent>
                    <TextWithNumber>
                      은 : 50,100g 이상부터 100g당 100원입니다.
                    </TextWithNumber>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <Indent>3. </Indent>
                    <TextWithNumber>
                      금의 소수 중량과 은 100g미만의 중량은 절사합니다.
                    </TextWithNumber>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <Indent>4.</Indent>
                    <TextWithNumber>
                      <TextBorder>
                        월마다 부과되며, 월 최대 수수료는 금 200,000원 / 은
                        200,000원입니다.
                      </TextBorder>
                    </TextWithNumber>
                  </div>
                </Description>
              ) : (
                <BorderLine />
              )}
              <FlexRow style={{ margin: "8px 0" }}>
                <Title>보관료 납부방법</Title>
                <div
                  className={`dropdown-arrow ${
                    isOpened.includes("method") ? "active" : ""
                  }`}
                  onClick={() => {
                    handleClickDropdown("method");
                  }}></div>
              </FlexRow>
              {isOpened.includes("method") ? (
                <Description>
                  <div style={{ display: "flex" }}>
                    <Indent>1.</Indent>
                    <TextWithNumber>
                      <TextBorder>
                        무료 보관기간이 끝난 자산에 대하여 익월 1일 0시에
                        보관료가 계산
                      </TextBorder>
                      됩니다.
                    </TextWithNumber>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <Indent>2. </Indent>
                    <TextWithNumber>
                      출금, 실물인출 시 보관료 납부 팝업이 표시되며 동의 시
                      고객님의 KRW자산에서 수취됩니다.
                    </TextWithNumber>
                  </div>
                  <div style={{ display: "flex", marginTop: 8 }}>
                    <Indent>3.</Indent>
                    <TextWithNumber>
                      <TextBorder>{`메뉴 > 보관료 > 미납보관료에서 납부 가능`}</TextBorder>
                      합니다.
                    </TextWithNumber>
                  </div>
                </Description>
              ) : (
                <BorderLine />
              )}
            </WeightContent>
          )}
        </>
      )}
      {tabs === "fee" && (
        <>
          {modalVisible && (
            <StorageFeePayModal
              isVisible={modalVisible}
              onClose={() => {
                setModalVisible(false);
              }}
              totalFee={fees?.totalFeeKrw}
              onConfirm={() => {
                handleClickConfirm();
              }}
            />
            // <Modal
            //   onClickBackground={() => {
            //     setModalVisible(false);
            //   }}>
            //   <ModalWrap>
            //     <ModalContentWrap>
            //       <Message>
            //         <br />
            //         <Text
            //           style={{
            //             fontSize: 16,
            //             fontWeight: 700,
            //           }}>
            //           보관료 {`${numFormat(fees?.totalFeeKrw)}`}KRW를
            //           납부해주세요
            //         </Text>
            //         <br />
            //         <br />
            //         <br />
            //         <Text
            //           style={{
            //             fontSize: 12,
            //             fontWeight: 400,
            //           }}>
            //           납부하기를 클릭하시면 보유하신 자산에서 자동 납부됩니다.
            //           납부하시겠습니까?
            //         </Text>
            //       </Message>
            //       <Flex
            //         row
            //         style={{
            //           width: "100%",
            //           padding: "20px 20px 0",
            //         }}>
            //         <Buttons
            //           onClick={() => {
            //             // onCloseConfirmModal();
            //             setModalVisible(false);
            //           }}>
            //           취소
            //         </Buttons>
            //         <div style={{ width: 16 }} />
            //         <Buttons
            //           isConfirm={true}
            //           onClick={() => {
            //             handleClickConfirm();
            //           }}>
            //           확인
            //         </Buttons>
            //       </Flex>
            //     </ModalContentWrap>
            //   </ModalWrap>
            // </Modal>
          )}
          {fees?.totalFeeKrw === 0 ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <div style={{}}>납부하실 보관료가 없습니다.</div>
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <div>
                <FeeTotal>
                  <FeeTotalTitle>
                    총 미납 보관료{" "}
                    <FeeTotalDate>{`(${formatTime(
                      today,
                      "YY.MM.DD"
                    )} 기준)`}</FeeTotalDate>
                  </FeeTotalTitle>
                  <FeeTotalTitle>
                    {`${numFormat(fees?.totalFeeKrw || 0)}`}원
                  </FeeTotalTitle>
                </FeeTotal>
                {/* {Array.isArray(fees?.storageFeeMonthDtoList) &&
                  fees?.storageFeeMonthDtoList.map((item, index) => {
                    let month = new Date(item.feeChargedDate).getMonth() + 1;
                    return (
                      <div
                        key={index}
                        style={{
                          padding: "12px 22px",
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#727272",
                          fontSize: "14px",
                        }}>
                        <span>ㄴ{`${month}`}월</span>
                        <span>{`${numFormat(item?.feeKrw)}`}원</span>
                      </div>
                    );
                  })} */}
                <div
                  style={{
                    margin: "0 16px",
                  }}>
                  {Array.isArray(fees?.yearList) &&
                    fees.yearList.map((year, index) => {
                      return (
                        <div key={`year_${index}`}>
                          <div
                            style={{
                              marginBottom: "12px",
                              fontWeight: "600",
                            }}>
                            • {`${year?.year}년 미납 보관료`}
                          </div>
                          <div style={{ marginBottom: "20px" }}>
                            {year?.data.map((item, index) => {
                              let month =
                                new Date(item.feeChargedDate).getMonth() + 1;
                              return (
                                <div
                                  key={`item_${index}`}
                                  style={{
                                    border: "1px solid #eaeaea",
                                    borderRadius: "8px",
                                    padding: "13px 16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "8px",
                                    fontWeight: "bold",
                                    fontSize: "15px",
                                  }}>
                                  <span>{`${month}월`}</span>
                                  <span>{`${numFormat(item?.feeKrw)}`}원</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div style={{ justifySelf: "end", padding: "30px 15px 56px" }}>
                <ButtonBase
                  style={{
                    width: "100%",
                    backgroundColor: "#FF842B",
                    fontFamily: "Noto Sans KR",
                    color: "#fff",
                    border: "none",
                    fontSize: "16px",
                    fontWeight: 700,
                    letterSpacing: "-0.038em",
                    padding: "22px 0",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    setModalVisible(true);
                  }}>
                  <div className="speech-bubble">
                    미납 보관료는{" "}
                    <span style={{ color: "#ff842b" }}>매월 1일</span>에
                    갱신됩니다.
                  </div>
                  보관료 납부하기
                </ButtonBase>
              </div>
            </div>
          )}
        </>
      )}
    </BasicLayout>
  );
}
