import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";

import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";

import colors from "../../libs/colors";
import HistoryItem from "./HistoryItem";
import PurchaseCancelPopup from "./PurchaseCancelPopup";
import TradeHistoryPopup from "./TradeHistoryPopup";
import { axiosDispatch } from "../../services/network";
import { objToQueryStr } from "../../services/utils";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError } from "../../redux/message/MessageActions";

export default function History({ settype }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { id } = useSelector((s) => s.user);
  const { userId } = useSelector((s) => s.user);
  const [data, setData] = useState([]);
  const [datacanle, setdatacanle] = useState(0);

  useEffect(() => {
    APIS.getProcessingList(settype).then(({ data: { data } }) => {
      setData([]);
      setData(data);
    });
  }, [datacanle]);

  const [cancelPopup, setCancelPopup] = useState({ open: false });
  const [historyPopup, setHistoryPopup] = useState({ open: false });

  const historyPopFunc = (data) => {
    setHistoryPopup({ open: true, method: data });
  };
  const canclePopFunc = (data) => {
    dispatch(loadingStart);
    APIS.getServiceBreak().then((serviceBreak) => {
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        dispatch(loadingEnd);
      } else {
        dispatch(loadingEnd);
        setCancelPopup({ open: true, method: data });
      }
    });
  };

  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    setContentHeight(contentRef.current.offsetHeight);
  }, []);

  return (
    <Flex
      className={classes.root}
      innerRef={contentRef}
      style={{
        display: contentHeight ? "block" : "flex",
        height: contentHeight || "auto",
      }}
    >
      {cancelPopup.open && (
        <PurchaseCancelPopup
          {...cancelPopup}
          onCancleData={() => setdatacanle(1)}
          onClose={() => {
            setdatacanle(0);
            setCancelPopup({ open: false });
          }}
        />
      )}
      {historyPopup.open && (
        <TradeHistoryPopup
          {...historyPopup}
          onClose={() => {
            setHistoryPopup({ open: false });
          }}
        />
      )}
      {!!contentHeight &&
        data.map((x, i) => {
          return (
            <HistoryItem
              key={i.toString()}
              rows={x}
              onHistory={(data) => historyPopFunc(data)}
              onCancel={() => canclePopFunc(x)}
            />
          );
        })}
      {data.length < 1 && (
        <Text className={classes.no}>진행 중인 내역이 없습니다.</Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    overflowY: "scroll",
    // "-webkit-overflow-scrolling": "touch",
    flex: 1,
    alignSelf: "stretch",
    backgroundColor: "#eeeeee",
  },
  no: {
    fontSize: "13px",
    alignSelf: "center",
    color: colors.text,
    marginTop: "50px",
  },
});
