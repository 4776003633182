import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import CheckboxCircle from "../../components/checkbox-circle/CheckboxCircle";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import ErrorPopup from "./ErrorPopup";

export default function Exit({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [check, setCheck] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "탈퇴하기" }}>
      {errorPopup && <ErrorPopup />}
      <Flex className={classes.content + " shadow"}>
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          총 보유자산
        </Text>

        <Text className={classes.row}>
          금(GOLD)
          <Text font={fonts.notoSansKRMedium}>0.0016g</Text>
        </Text>
        <Text className={classes.row}>
          은(SILVER)
          <Text font={fonts.notoSansKRMedium}>0.14g</Text>
        </Text>
        <Text className={classes.row}>
          원화
          <Text font={fonts.notoSansKRMedium}>800원</Text>
        </Text>
        <Text className={classes.row}>
          포인트
          <Text font={fonts.notoSansKRMedium}>2,000 P</Text>
        </Text>
      </Flex>
      <Text className={classes.text}>
        ※ 잔고가 있을 경우 탈퇴가 되지 않습니다.
        <br /> - 금(GOLD), 은(SILVER)을 보유하고 있을 시 탈퇴가 불가능합니다.
        모두 매도 후 탈퇴를 진행해 주세요.
        <br /> - 500원 이상의 잔고를 보유하고 있을 시 탈퇴할 수 없습니다. 모두
        출금 후 탈퇴를 진행해 주세요.
        <br /> ※ 500원 미만의 잔고를 보유한채 탈퇴할 경우, 잔고는 모두(주)한국
        금거래 디지털에셋으로 양도됩니다.
        <br /> ※ 회원탈퇴시 등록된 개인정보 및 거래정보는 관계 법령에 따라
        일정기간 보관 후 삭제됩니다.
      </Text>

      <CheckboxCircle
        small
        checked={check}
        onCheckedChange={setCheck}
        className={classes.cb}
        label="위의 내용을 이해했으며 동의합니다."
      />
      <Flex row style={{ margin: "30px" }}>
        <Button
          onClick={history.goBack}
          className={classes.button}
          label="취소"
        />
        <Button
          className={classes.button}
          label="계속하기"
          onClick={() => {
            if (check) {
              history.push(routes.exitVerify);
            } else {
              setErrorPopup(true);
            }
          }}
        />
      </Flex>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  content: {
    margin: "30px",
    padding: "20px 35px",
  },
  label: {
    fontSize: "18px",
    color: colors.text,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "16px",
    color: colors.gray,
    marginTop: "8px",
    "& span": {
      color: colors.text,
    },
  },
  text: {
    margin: "0px 30px",
    color: colors.gray,
    fontSize: "12px",
  },
  button: {
    flex: 1,
    "&:last-child": {
      marginLeft: "5px",
    },
    "&:first-child": {
      marginRight: "5px",
      backgroundColor: "#999999",
    },
  },
  cb: {
    margin: "30px 30px 0px 30px",
  },
});
