import { makeStyles } from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";

import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { toastShow } from "../../redux/toast/ToastActions";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import NumberFormat from "react-number-format";
import routes, { ROLE } from "../../libs/routes";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import { useHistory } from "react-router";
import { messageShow } from "../../redux/message/MessageActions";

export default function DepositIssueAccount() {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const { roles = [] } = useSelector((state) => state.auth);
  const [hasWithdrawAccount, setHasWithdrawAccount] = useState(false);

  useLayoutEffect(() => {
    dispatch(loadingStart);
    APIS.getAccountInfo("DEPOSIT")
      .then(({ data: { success, data, message } }) => {
        if (success) {
          const { bankAccountStatus } = data || {};
          if (bankAccountStatus === "COMPLETED") {
            history.replace(routes.deposit);
          } else if (bankAccountStatus === "REQUEST") {
            dispatch(actionError("계좌 등록 처리 중 입니다."));
            history.goBack();
          }
        } else {
          history.goBack();
        }
      })
      .finally(() => dispatch(loadingEnd));

    APIS.getAccountInfo("WITHDRAW")
      .then(({ data: { success, data, message } }) => {
        success && data && setHasWithdrawAccount(true);
      })
      .finally(() => dispatch(loadingEnd));
  }, []);

  const handleClickCertification = () => {
    if (hasWithdrawAccount) {
      // 출금 계좌 등록되어있을 경우
      dispatch(
        actionShow(
          "등록된 출금계좌가 있습니다.\n\n이미 등록하신 출금계좌를 입금계좌로 사용하시겠습니까?",
          ["신규 등록", "출금계좌 사용"],
          [
            () => {
              history.push(routes.authBankAccount, {
                purpose: "NEW",
                bankType: "DEPOSIT",
              });
            },
            requestDepositFromWithdraw,
          ],
          "",
          true
        )
      );
    } else {
      history.push(routes.authBankAccount, {
        purpose: "NEW",
        bankType: "DEPOSIT",
      });
    }
  };

  const requestDepositFromWithdraw = () => {
    dispatch(loadingStart);
    APIS.postDepositFromWithdraw()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          pendingCompleteAccount(1);
        } else {
          dispatch(actionError(message));
          dispatch(loadingEnd);
        }
      })
      .catch(() => dispatch(loadingEnd));
  };

  const pendingCompleteAccount = (count) => {
    dispatch(loadingStart);
    setTimeout(() => {
      APIS.getAccountInfo("DEPOSIT").then(
        ({ data: { success, data, message } }) => {
          if (success) {
            const { bankAccountStatus } = data || {};
            if (bankAccountStatus === "COMPLETED") {
              dispatch(
                messageShow("계좌 등록이 완료되었습니다.", () => {
                  history.replace(routes.deposit);
                })
              );
              dispatch(loadingEnd);
            } else if (bankAccountStatus === "REQUEST") {
              if (count === 10) {
                dispatch(actionError("계좌 등록 처리 중 입니다."));
                dispatch(loadingEnd);
                history.goBack();
              } else {
                pendingCompleteAccount(count + 1);
              }
            } else {
              dispatch(actionError("계좌 등록에 실패하였습니다."));
              dispatch(loadingEnd);
            }
          } else {
            dispatch(actionError(message));
            dispatch(loadingEnd);
          }
        }
      );
    }, 1000);
  };

  return (
    <BasicLayout stackTopbar={{ title: "입금 계좌 등록", border: true }}>
      <div className="kb_bank_wrap">
        <div className={classes.description}>
          <ul>
            <li>
              <Flex row>
                <span style={{ fontWeight: 800 }}>·</span>
                <span>
                  가상계좌로 입금하기 위해 입금계좌의 지정과 본인 계좌 인증이
                  필요합니다.
                </span>
              </Flex>
            </li>
            <li>
              <Flex row>
                <span style={{ fontWeight: 800 }}>·</span>
                <span>
                  지정 입금계좌 인증이 완료되면 전용 가상계좌가 발급됩니다.
                </span>
              </Flex>
            </li>
          </ul>
        </div>
        <Button
          label="지정 입금계좌 인증"
          className={classes.certificationButton}
          onClick={handleClickCertification}
          textStyle={{ fontSize: 14 }}
        />
        <div className="kb_bank_noti">
          <h3>입금 계좌 등록 유의사항</h3>
          <ul style={{ wordBreak: "keep-all" }}>
            <li>등록하신 계좌는 ‘내정보 수정’에서 변경이 가능합니다.</li>
            <li style={{ height: "auto" }}>
              입출금 계좌는 본인 명의 계좌만 등록이 가능하며, 사업자 계좌는
              등록이 불가합니다.
            </li>
            <li>
              고객님 전용 가상계좌가 발급되며, 등록하신 계좌로만 입금이
              가능합니다.
              <br />타 계좌에서 금방금 방 가상계좌로의 입금은 제한됩니다.
            </li>
          </ul>
        </div>
      </div>
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  description: {
    wordBreak: "keep-all",

    "& li": {
      lineHeight: 1.2,
      marginBottom: 10,
      fontWeight: 600,
    },
  },
  certificationButton: { margin: "50px 0 50px 0", width: "100%" },
});
