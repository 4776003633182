import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import fonts from "../../../../../libs/fonts";
import routes from "../../../../../libs/routes";
import { numberWithCommas } from "../../../../../libs/utils";
import CommonDetail from "./CommonDetail";
const AppraisalDetail = (props) => {
  const history = useHistory();
  const {
    tradeType,
    tradeTypeKor,
    assetType,
    assetTypeKor,
    tradeGram,
    userFee,
    productName,
    rawId,
  } = props;
  return (
    <div>
      <ContentWrap>
        <ContentTitle>{tradeTypeKor}</ContentTitle>
        <Separator color="#bdbdbd" />
        <Separator color="#eaeaea" style={{ marginTop: 2, marginBottom: 20 }} />
        <div>
          <CommonDetail {...props} />
          <ContentRow>
            <DetailTitle>제품명</DetailTitle>
            <DetailContent>{productName}</DetailContent>
          </ContentRow>
          <ContentRow>
            <DetailTitle>자산</DetailTitle>
            <DetailContent>{assetTypeKor}</DetailContent>
          </ContentRow>
          <ContentRow>
            <DetailTitle>중량</DetailTitle>
            <DetailContent>
              <AmountText>{numberWithCommas(tradeGram)}</AmountText>g
            </DetailContent>
          </ContentRow>
        </div>
      </ContentWrap>
      <AfterAsset {...props} />
      <Flex row style={{ justifyContent: "flex-end" }}>
        <Span
          size={12}
          color={"#222222"}
          style={{ marginTop: 20, cursor: "pointer" }}
          onClick={() => {
            history.push(routes.appraiseDetail, { id: rawId });
          }}
        >
          상세내역 더 보기&gt;
        </Span>
      </Flex>
    </div>
  );
};

const AfterAsset = ({ afterGold, afterKrw, afterPoint, afterSilver }) => {
  return (
    <ContentWrap>
      <SubContentTitle>거래 후 잔고</SubContentTitle>
      <Separator color="#eaeaea" style={{ marginBottom: 20 }} />
      <div>
        <ContentRow>
          <DetailTitle>금</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterGold)}</AmountText>g
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>은</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterSilver)}</AmountText>g
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>원화(KRW)</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterKrw)}</AmountText>원
          </DetailContent>
        </ContentRow>
        <ContentRow>
          <DetailTitle>포인트</DetailTitle>
          <DetailContent>
            <AmountText sub>{numberWithCommas(afterPoint)}</AmountText>pt
          </DetailContent>
        </ContentRow>
      </div>
    </ContentWrap>
  );
};

const ContentWrap = styled(Flex)`
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 20px 15px;
`;
const ContentTitle = styled(Flex)`
  font-family: ${fonts.notoSansKRBold};
  font-size: 17px;
  letter-spacing: -0.34px;
  margin-bottom: 20px;
`;
const SubContentTitle = styled(Flex)`
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #999999;
  margin-bottom: 20px;
`;
const Separator = styled(Flex)`
  border-bottom: 1px solid ${({ color }) => color};
`;

const ContentRow = styled(Flex)`
  &:not(:first-of-type) {
    margin-top: 11px;
  }
  flex-direction: row;
  justify-content: space-between;
`;

const DetailTitle = styled(Span)`
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #999999;
`;

const DetailContent = styled(Span)`
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #444444;
`;

const AmountText = styled(Span)`
  font-family: ${fonts.spoqa};
  font-weight: ${({ sub }) => (sub ? "500" : "bold")};
  font-size: 16px;
  margin-right: 1px;
`;

export default AppraisalDetail;
