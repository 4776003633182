import React from "react";
import styled from "styled-components";
import Flex from "../../../../../components/flex/Flex";
import Span from "../../../../../components/text/Span";
import fonts from "../../../../../libs/fonts";
import { formatTime, numberWithCommas } from "../../../../../libs/utils";
import check_circle from "../../../../../assets/images/check_circle.webp";
import trade_sell from "../../../../../assets/images/trade_sell.webp";

const ReceiveAssets = (props) => {
  const {
    tradeType,
    tradeTypeKor,
    assetType,
    tradeGram,
    pureTradeKrw,
    completedAt,
    addTradeType,
    tradePoint,
  } = props;

  let title = "";
  let amount = <></>;

  if (assetType === "KRW") {
    title = assetType;
    amount = (
      <Span size={16}>
        <Span
          size={18}
          font={fonts.spoqa}
          weight="bold"
          style={{ marginLeft: 3 }}
        >
          {numberWithCommas(pureTradeKrw)}
        </Span>
        원
      </Span>
    );
  } else if (assetType === "POINT") {
    title = "포인트";
    amount = (
      <Span size={16}>
        <Span
          size={18}
          font={fonts.spoqa}
          weight="bold"
          style={{ marginLeft: 3 }}
        >
          {numberWithCommas(tradePoint)}
        </Span>
        pt
      </Span>
    );
  } else if (assetType === "금" || assetType === "은") {
    title = assetType;
    amount = (
      <Span size={16}>
        <Span
          size={18}
          font={fonts.spoqa}
          weight="bold"
          style={{ marginLeft: 3 }}
        >
          {numberWithCommas(tradeGram)}
        </Span>
        g
      </Span>
    );
  }

  return (
    <Container>
      <TopWrap>
        <img
          src={check_circle}
          width={16}
          height={16}
          style={{ marginRight: 4 }}
        />
        <Span size={14} spacing={-0.28} color="#e76e26">
          거래완료
        </Span>
        <Span
          font={fonts.spoqa}
          size={14}
          color="#666666"
          style={{
            marginLeft: 5,
            paddingLeft: 6,
            borderLeft: "1px solid #999999",
          }}
        >
          {formatTime(completedAt, "YYYY.MM.DD HH:mm")}
        </Span>
      </TopWrap>

      <TradeTypeWrap>
        <img src={trade_sell} width={18} height={18} />
        {`${tradeTypeKor}(${addTradeType})`}
      </TradeTypeWrap>

      <AmountWrap>
        <Span weight="bold" size={18}>
          {title}
        </Span>
        <Span size={16}>{amount}</Span>
      </AmountWrap>
    </Container>
  );
};

const Container = styled(Flex)`
  padding: 15px 16px;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
`;

const TopWrap = styled(Flex)`
  flex-direction: row;
  padding-bottom: 8.5px;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;
const TradeTypeWrap = styled(Flex)`
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;

  img {
    margin-right: 3px;
  }
`;
const AmountWrap = styled(Flex)`
  margin-top: 8px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export default ReceiveAssets;
