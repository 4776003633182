import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import routes from "../../libs/routes";

export default function ExitComplete({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <BasicLayout
      backgroundGray
      stackTopbar={{
        title: "탈퇴하기",
      }}
    >
      <img src={images.checkcircle_out} className={classes.image} />
      <Text className={classes.label1} font={fonts.notoSansKRMedium}>
        회원탈퇴가 완료되었습니다.
      </Text>
      <Text className={classes.label2}>그동안 이용해 주셔서 감사드립니다.</Text>

      <Button
        className={classes.button}
        label="확인"
        onClick={() => {
          history.replace(routes.main);
        }}
      />
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  label1: {
    margin: "30px 30px 0px 30px",
    fontSize: "20px",
    color: colors.text,
  },
  label2: {
    margin: "5px 30px 50px 30px",
    color: "#666",
    fontSize: "14px",
  },
  image: { width: "40px", height: "40px", margin: "100px 30px 0px 30px" },
  button: {
    margin: "0px 30px 30px 30px",
  },
});
