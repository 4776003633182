import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import consts from "../../libs/consts";
import { axiosDispatch } from "../../services/network";

import { useHistory } from "react-router";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import ListItem from "./ListItem";
import Topbar from "./Topbar";
import NumberFormat from "react-number-format";
import { actionError, actionShow } from "../../redux/action/ActionActions";
import { formatTime } from "../../libs/utils";
import * as APIS from "../../libs/apis";

import ic_wallet from "../../assets/images/ic_wallet.png";
import ic_bank_won from "../../assets/images/ic_bank_won.png";
import ic_bank_stop from "../../assets/images/ic_bank_stop.png";
import FormatAmount from "../../components/common/FormatAmount";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageShow } from "../../redux/message/MessageActions";
import StorageFeePayModal from "../mypage/storageFee/StorageFeePayModal";

export default function DepositWithdrawal({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [asset, setAsset] = useState({});

  const [hasDepositAccount, setHasDepositAccount] = useState(false);
  const [hasWithdrawAccount, setHasWithdrawAccount] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    dispatch(loadingStart);

    Promise.all([
      APIS.getUserHoldingsInfo().then(
        ({ data: { success, data, message } }) => {
          success && setAsset(data);
        }
      ),
      APIS.getAccountInfo("DEPOSIT").then(
        ({ data: { success, data, message } }) => {
          if (success) {
            const { bankAccountStatus } = data || {};
            if (bankAccountStatus === "COMPLETED") {
              setHasDepositAccount(true);
            }
          }
        }
      ),

      APIS.getAccountInfo("WITHDRAW").then(
        ({ data: { success, data, message } }) => {
          success && data && setHasWithdrawAccount(true);
        }
      ),
    ]).finally(() => dispatch(loadingEnd));
  }, []);

  const handleClickDeposit = () => {
    if (hasDepositAccount) {
      navigate(routes.deposit);
    } else {
      // 입금 계좌 발급
      navigate(routes.depositIssueAccount);
    }
  };

  const handleClickWithdrawal = () => {
    const currentTime = formatTime(new Date(), "HHmm");
    if (asset?.storageFee > 0) {
      setIsVisible(true);
      return;
    }
    if (currentTime < "0100" || currentTime > "2350") {
      dispatch(
        actionError("출금 가능시간 내에(01:00 ~ 23:50) 이용하실 수 있습니다.")
      );
    } else {
      if (hasWithdrawAccount) {
        navigate(routes.withdrawal);
      } else {
        if (hasDepositAccount) {
          dispatch(
            actionShow(
              "등록된 입금계좌가 있습니다.\n\n이미 등록하신 입금계좌를 출금계좌로 사용하시겠습니까?",
              ["신규 등록", "입금계좌 사용"],
              [
                () => {
                  navigate(routes.authBankAccount, {
                    purpose: "NEW",
                    bankType: "WITHDRAW",
                    nextPage: { path: routes.withdrawal },
                  });
                },
                requestWithdrawFromDeposit,
              ],
              "",
              true
            )
          );
        } else {
          navigate(routes.authBankAccount, {
            purpose: "NEW",
            bankType: "WITHDRAW",
            nextPage: { path: routes.withdrawal },
          });
        }
      }
    }
  };

  const requestWithdrawFromDeposit = () => {
    dispatch(loadingStart);
    APIS.postWithdrawFromDeposit()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          dispatch(
            messageShow("계좌 등록이 완료되었습니다.", () =>
              navigate(routes.withdrawal)
            )
          );
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const { krw = 0, acceptableKrw = 0 } = asset;
  return (
    <MainLayout topbar={<Topbar />} white>
      <StorageFeePayModal
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
        }}
        totalFee={asset?.storageFee}
        onConfirm={() => {
          navigate(routes.commonSecureKeypad, {
            type: "STORAGE_FEE",
            payload: {
              totalFeeKrw: `${asset?.storageFee}`,
            },
            buttonText: "납부하기",
          });
        }}
        payType={"withdraw"}
      />
      <div className="kb_bank_wrap">
        <div className="kb_bank_tb_wrap">
          <div className="kb_bank_tb pd50">
            <div className="kb_bank_tb_top">
              <img src={ic_wallet} />
              <p>나의 보유 자산</p>
            </div>
            <ul>
              <li>
                <img src={ic_bank_won} />
                보유 원화
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={krw} />
                    <td className="won">원</td>
                  </tr>
                </table>
              </li>
              <li>
                <img src={ic_bank_stop} />
                거래 대기
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={krw - acceptableKrw} />
                    <td className="won">원</td>
                  </tr>
                </table>
              </li>
              <li>
                <img src={ic_bank_won} />
                사용 가능 원화
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={acceptableKrw} />
                    <td className="won">원</td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
          <h3 className="bank_box_tit pd20">
            원화를 충전하여 거래하고
            <br />
            필요할 때 현금으로 출금합니다.
          </h3>
          <div className="bank_box">
            <ul>
              <li onClick={handleClickDeposit}>
                <h3>입금하기</h3>
                <span>현금을 금방금방 가상계좌로 입금합니다.</span>
              </li>
              <li onClick={handleClickWithdrawal}>
                <h3>출금하기</h3>
                <span>금방금방에서 등록된 계좌로 출금합니다.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

const useStyle = makeStyles({
  card: {
    padding: "20px 16px",
    margin: "30px 16px 16px 16px",
  },
  de: {
    marginLeft: "5px",
  },
  label1: { color: colors.gray, fontSize: "12px" },
  label2: { color: colors.text, fontSize: "20px" },
  cardHeader: {
    alignItems: "center",
    marginBottom: 25,
    justifyContent: "space-between",
  },
  row: {
    alignItems: "center",
    marginTop: "5px",
  },
  rowLabel: {
    fontSize: "13px",
    color: colors.gray,
  },
  bank: {
    color: colors.text,
    fontSize: "13px",
    marginLeft: "6px",
  },
  change: {
    fontFamily: fonts.notoSansKRMedium,
    borderBottom: "1px solid #333",
    color: colors.text,
    fontSize: "13px",
    marginTop: "-25px",
    padding: "4px 0px",
    alignSelf: "flex-end",
  },
  listHeader: {
    padding: "8px 30px ",
    borderBottom: "1px solid #eeeeee",
  },
  value: {
    color: colors.text,
    fontSize: "13px",
  },
  value2: {
    fontSize: "13px",
    color: colors.gray,
  },
});
