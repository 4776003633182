import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ImageButton from "../../components/image-button/ImageButton";
import Image from "../../components/image/Image";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import queryString from "query-string";
import { objToQueryStr } from "../../services/utils";
import routes from "../../libs/routes";
import AppleLogin from "react-apple-login";
import consts from "../../libs/consts";
import jwtDecode from "jwt-decode";
import qs from "qs";
export default function Login({}) {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useSelector((s) => s.user);
  const { signed } = useSelector((s) => s.user);
  const state = queryString.parse(history.location.search);

  useEffect(() => {
    if (signed) {
      history.replace("/");
    }
  }, [signed]);

  const [userState, setUserState] = useState({
    token: "",
    email: "",
    name: "",
    method: "",
  });

  const needReplace = () => {
    history.replace(routes.login);
  };
  useEffect(() => {
    if (window.naver.LoginWithNaverId) {
      const login = new window.naver.LoginWithNaverId({
        clientId: "4XBMtYOL9PaRJhKsFVXB",
        callbackUrl: window.location.origin + "/login/callback/naver",
        isPopup: false,
        callbackHandle: true,
        loginButton: { type: "1", color: "green", height: "40" },
      });
      login.init();
    }
    if (window.kakao) {
      window.kakao.cleanup();
      window.kakao.init("ce0a6ba774f2383b44375ea1fe8cad45");
      if (!window.kakao.isInitialized()) {
        dispatch(messageShow("카카오 로그인 사용 불가능합니다.(초기화 실패)"));
      }
    }

    switch (history.location.pathname) {
      case "/login/callback/naver":
        // eslint-disable-next-line no-case-declarations
        const login = new window.naver.LoginWithNaverId({
          clientId: "4XBMtYOL9PaRJhKsFVXB",
          callbackUrl: window.location.origin + "/login/callback/naver",
          isPopup: false,
          callbackHandle: true,
          loginButton: { type: "1", color: "green", height: "40" },
        });
        login.init();
        login.getLoginStatus((status) => {
          if (status) {
            setUserState({
              token: login.user.getId(),
              email: login.user.getEmail(),
              name: login.user.getName(),
              method: "NAVER",
            });
          } else {
            dispatch(messageShow("다시 로그인해주세요.", needReplace));
          }
        });
        break;
      case "/login/callback/kakaotalk":
      /*    if (!state.code) {
          dispatch(messageShow("다시 로그인해주세요.", needReplace));
          return;
        }

        //        window.kakao.Auth.setAccessToken(state.code);
        window.kakao.API.request({
          url: "/v2/user/me",
          data: {
            property_keys: ["account_email", "properties.nickname"],
          },
          success: (result) => {
            console.log(result);
            const {
              id,
              properties: { nickname },
            } = result;
            setUserState({
              token: id,
              email: "",
              name: nickname,
              method: "KAKAO",
            });
          },
          fail: (e) => {
            console.log(e);
            dispatch(messageError(e));
          },
        });
        return; 
        axios

          .post(
            "https://kauth.kakao.com/oauth/token" +
              objToQueryStr({
                grant_type: "authorization_code",
                client_id: "edec7b21fee61e4f4e8d2099a7b25c91",
                redirect_uri:
                  window.location.origin + history.location.pathname,
                code: state.code,
              })
          )
          .then((res) => {
            const data = res.data;
            const token = data.access_token;
            if (!token) {
              throw "error";
            }
            window.kakao.Auth.setAccessToken(token);
            window.kakao.API.request({
              url: "/v2/user/me",
              data: {
                property_keys: ["account_email", "properties.nickname"],
              },
              success: (result) => {
                const {
                  id,
                  properties: { nickname },
                } = result;
                setUserState({
                  token: id,
                  email: "",
                  name: nickname,
                  method: "KAKAO",
                });
              },
              fail: (e) => {
                dispatch(messageError(e));
              },
            });
          })
          .catch((e) => {
            dispatch(messageShow("다시 로그인해주세요.", needReplace));
          });
        break;
          */
      case "/login/callback/apple":
        // eslint-disable-next-line no-case-declarations
        let appleQuery = queryString.parse(history.location.search);
        if (!appleQuery.code) {
          return dispatch(
            messageError("애플 로그인 불가능합니다. 다시 시도해 주세요.")
          );
        }
        axios
          .get(consts.apiBaseUrl + "/api/jwtToken/getToken")
          .then((res) => {
            const token = res.data;
            const form = new FormData();
            form.append("client_id", "com.korda.goldmarket.service.urls");
            form.append("client_secret", token);
            form.append("code", appleQuery.code);
            form.append("grant_type", "authorization_code");
            axios({
              method: "post",
              url: "https://appleid.apple.com/auth/token",
              // eslint-disable-next-line no-undef
              data: qs.stringify({
                client_id: "com.korda.goldmarket.service.urls",
                client_secret: token,
                code: appleQuery.code,
                grant_type: "authorization_code",
              }), // form,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
              .then((res) => {
                const data = res.data;
                const id = data.id_token;
                const obj = jwtDecode(id);
                setUserState({
                  token: obj.sub,
                  email: obj.email || "",
                  name:
                    typeof obj.name === "object"
                      ? obj.name.fullName
                      : obj.name || "",
                  method: "APPLE",
                });
              })
              .catch((e) => {
                dispatch(
                  messageError(e, () => {
                    history.replace(routes.login);
                  })
                );
              });
          })
          .catch((e) => {
            dispatch(
              messageError(e, () => {
                history.replace(routes.login);
              })
            );
          });
      default:
        return;
    }
  }, []);

  const handleClose = useCallback(() => {
    history.goBack();
  }, []);

  const handleLogin = useCallback(
    (type) => () => {
      switch (type) {
        case "naver":
          document.querySelector("#naverIdLogin")?.firstChild &&
            document.getElementById("naverIdLogin")?.firstChild.click();
          break;
      }
    },
    []
  );

  const successGoogle = (res) => {
    const profile = res.profileObj;
    const email = profile.email;
    const token = profile.googleId;
    const name = profile.name;
    setUserState({
      email,
      token,
      name,
      method: "GOOGLE",
    });
  };

  useEffect(() => {
    if (userId) {
      history.goBack();
    }
  }, [userId]);

  if (userState.method) {
    return (
      <Redirect
        to={{
          pathname: "/login/check",
          state: userState,
        }}
      />
    );
  }

  return (
    <Flex className={classes.root}>
      <ImageButton
        onClick={handleClose}
        className={classes.close}
        align="right"
        src={images.close}
      />
      <Flex className={classes.top}>
        <Text font={fonts.notoSansKRLight} className={classes.label}>
          온라인 금거래 서비스
        </Text>
        <Image src={images.logo} width="50vw" />
      </Flex>
      {/* <ButtonBase
        onClick={() => {
          history.push("/loginid");
        }}
        label="아이디 로그인"
        className={classes.idb}
      >
        아이디 로그인
      </ButtonBase> */}
      <Flex className={classes.bottom}>
        <Text font={fonts.notoSansKRLight} className={classes.sns}>
          SNS간편로그인으로 금방금방 서비스를 이용하세요.
        </Text>
        <div className={classes.social2} id="naverIdLogin"></div>
        <ButtonBase
          onClick={handleLogin("naver")}
          style={{ backgroundColor: "#21c603" }}
          className={classes.social}
        >
          <Image width={"5vw"} src={images.naver} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ color: "#fff" }}
          >
            네이버로 시작하기
          </Text>
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            const login = () => {
              window.kakao.Auth.login({
                success: () => {
                  window.kakao.API.request({
                    url: "/v2/user/me",
                    data: {
                      property_keys: [
                        "kakao_account.email",
                        "kakao_account.gender",
                        "account_email",
                        "properties.nickname",
                      ],
                    },
                    success: (result) => {
                      const {
                        id,
                        properties: { nickname },
                      } = result;
                      setUserState({
                        token: id,
                        email: result?.kakao_account?.email,
                        name: nickname,
                        method: "KAKAO",
                      });
                    },
                    fail: (e) => {
                      dispatch(messageError(e));
                    },
                  });
                },
                fail: (error) => {
                  console.log(error);
                },
              });
            };
            if (!window.kakao.Auth.getAccessToken()) {
              login();
              return;
            }
            window.kakao.Auth.logout(function () {
              login();
            });
          }}
          style={{ backgroundColor: "#f9e000" }}
          className={classes.social}
        >
          <Image width={"5vw"} src={images.kakaotalk} />
          <Text
            className={classes.socialLabel}
            font={fonts.notoSansKRMedium}
            style={{ olor: "#3b1c1c" }}
          >
            카카오톡으로 시작하기
          </Text>
        </ButtonBase>

        <GoogleLogin
          clientId="756330054175-get7v6gdn5ve526llbprgc8q0lt29iiq.apps.googleusercontent.com"
          cookiePolicy={"single_host_origin"}
          render={(props) => {
            return (
              <ButtonBase
                onClick={props.onClick}
                disabled={props.disabled}
                style={{ backgroundColor: "#fff" }}
                className={classes.social}
              >
                <Image width={"5vw"} src={images.google} />
                <Text
                  className={classes.socialLabel}
                  font={fonts.notoSansKRMedium}
                  style={{ color: "#000" }}
                >
                  구글로 시작하기
                </Text>
              </ButtonBase>
            );
          }}
          onSuccess={successGoogle}
          onFailure={(f) => alert(f.message || f.msg || f)}
        />

        <AppleLogin
          scope="name email"
          responseType={"code"}
          responseMode="query"
          usePopup={false}
          clientId={"com.korda.goldmarket.service.urls"}
          redirectURI={window.location.origin + "/login/callback/apple"}
          render={(props) => {
            return (
              <ButtonBase
                onClick={props.onClick}
                style={{ backgroundColor: "#000000" }}
                className={classes.social}
              >
                <Image width={"5vw"} src={images.apple} />
                <Text
                  className={classes.socialLabel}
                  font={fonts.notoSansKRMedium}
                  style={{ color: "#fff" }}
                >
                  Apple로 시작하기
                </Text>
              </ButtonBase>
            );
          }}
        />

        {/* <ButtonBase onClick={handleClose} className={classes.closeText}>
          <Text>닫기</Text>
        </ButtonBase> */}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgb(240,240,240)",
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  close: {
    padding: "20px 13px",
  },
  top: { flex: 1, alignItems: "center", justifyContent: "center" },
  bottom: { flex: 1, alignItems: "center", justifyContent: "center" },
  label: {
    color: colors.black,
    fontSize: "4.5vw",
    marginBottom: 10,
  },
  sns: {
    color: colors.text,
    marginTop: "16px",
    fontSize: "3vw",
  },
  social2: {
    //marginTop: "10px",
    //margin: "0px 36px",
    width: "calc(100% - 72px)",
    display: "none",
  },
  social: {
    alignSelf: "stretch",
    padding: "12px 17px",
    display: "flex",
    alignItems: "center",
    margin: "0px 36px",
    marginTop: "10px",
    textAlign: "center",
    borderRadius: 5,
  },
  idb: {
    marginLeft: 36,
    marginRight: 36,
    backgroundColor: colors.primary,
    width: "calc(100% - 72px)",
    padding: "12px 17px",
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: 5,
  },
  closeText: {
    margin: "26px 0px",
    alignSelf: "center",
    borderBottom: "1px solid " + colors.text,
    padding: "4px 0px",
  },

  socialLabel: {
    fontSize: "3.3vw",
    flex: 1,
  },
});
