import { makeStyles } from "@material-ui/core";
import React from "react";

export default function Flex({
  onScroll,
  children,
  innerRef,
  row,
  className,
  onTouchEnd,
  onClick,
  style,
  id = "",
  onLoad,
}) {
  const classes = useStyle();
  return (
    <div
      onClick={onClick}
      style={{ ...style, cursor: Boolean(onClick) ? "pointer" : "normal" }}
      ref={innerRef}
      onTouchEnd={onTouchEnd}
      onScroll={onScroll}
      className={`${classes.root} ${row && classes.rootRow} ${className}`}
      id={id}
      onLoad={onLoad}
    >
      {children}
    </div>
  );
}

const useStyle = makeStyles({
  root: {
    flexDirection: "column",
    display: "flex",
    "-webkit-display": "flex",
    "-moz-display": "flex",
    "-ms--display": "flex",
    display: "-webkit-box",
    display: "-moz-box",
    display: "-ms-flexbox",
    display: "-webkit-flex",
  },
  rootRow: {
    flexDirection: "row",
  },
});
