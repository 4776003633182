import { ButtonBase, InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";

export default function InputMenu({
  value,
  required,
  className,
  onClick,
  onChange,
  disabled,
  placeholder,
}) {
  const classes = useStyle();
  return (
    <Flex className={`${classes.container} ${className}`}>
      <ButtonBase
        disabled={disabled}
        onClick={onClick}
        className={`${classes.root} shadow`}
      >
        {placeholder}
        <img alt="bottom" src={images.down} className={classes.down} />
      </ButtonBase>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: colors.white,
    fontSize: "15px",
    lineHeight: 2,
    display: "flex",
    justifyContent: "space-between",
    color: colors.text,
    borderRadius: "3px",
    fontFamily: fonts.notoSansKRRegular,
    padding: "13px 16px 13px 30px",
  },
  label: {
    color: colors.text,
    fontSize: 14,
    marginBottom: 10,
  },
  container: {},
  labelRequired: {
    color: colors.gray,
  },
  down: {
    width: "16px",
    height: "10px",
  },
});
