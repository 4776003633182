import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { actionError } from "../../redux/action/ActionActions";
import { toastShow } from "../../redux/toast/ToastActions";
import useInterval from "../../hooks/useInterval";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import LeaveConfirmPopup from "./LeaveConfirmPopup";

const LeaveSms = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const locationState = history.location.state || {};
  const { leaveReason } = locationState;

  const [timeLeft, setTimeLeft] = useState(-1);
  const [verifyCode, setVerifyCode] = useState("");
  const [stopInterval, setStopInterval] = useState(false);
  const [confirmPopupVisible, setConfirmPopupVisible] = useState(false);

  useEffect(() => {
    if (!leaveReason) {
      history.goBack();
    }
  }, []);

  useInterval(
    () => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    },
    stopInterval ? null : 1000
  );

  useEffect(() => {
    if (timeLeft === 0) {
      dispatch(
        actionError("인증번호가 만료되었습니다.\n인증번호를 다시 전송해주세요.")
      );
      resetTimer();
    }
  }, [timeLeft]);

  const startTimer = () => {
    setTimeLeft(180);
    setVerifyCode("");
  };

  const resetTimer = () => {
    setTimeLeft(-1);
    setVerifyCode("");
  };

  const handleClickPostSms = () => {
    if (timeLeft <= 0) {
      dispatch(loadingStart);
      resetTimer();
      APIS.postLeaveVerifyCode()
        .then(({ data: { success } }) => {
          if (success) {
            dispatch(toastShow("인증번호가 발송되었습니다."));
            startTimer();
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleClickNext = () => {
    if (verifyCode.length === 6) {
      dispatch(loadingStart);
      APIS.patchLeavePreviousVerify(verifyCode)
        .then(({ data: { success, message } }) => {
          if (success) {
            setStopInterval(true);
            setConfirmPopupVisible(true);
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => dispatch(loadingEnd));
    }
  };

  const handleClickLeave = () => {
    dispatch(loadingStart);
    APIS.patchLeaveUser(leaveReason)
      .then(({ data: { success, message } }) => {
        if (success) {
          history.push(routes.leaveComplete);
        } else {
          dispatch(actionError(message));
          history.goBack();
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <BasicLayout stackTopbar={{ title: "탈퇴하기", border: true }}>
      <div className="withdrawal_wrap">
        <div className="check_list_wrap">
          <div className="check_list_top">
            <h3>본인인증</h3>
            <p>회원탈퇴를 진행하기 위해서는 본인인증이 필요합니다.</p>
          </div>
          <div className="num_btn">
            <button
              className={timeLeft > 0 ? "off" : "on"}
              onClick={handleClickPostSms}
            >
              내 휴대폰으로 인증번호 전송
            </button>
            <input
              type="text"
              inputMode="decimal"
              placeholder="휴대폰으로 전송된 인증번호 입력"
              disabled={timeLeft <= 0}
              value={verifyCode}
              onChange={({ target: { value, maxLength } }) => {
                var regexp = /^[0-9]*$/;
                if (!regexp.test(value)) {
                  return;
                } else {
                  setVerifyCode(value.slice(0, maxLength));
                }
              }}
              maxLength={6}
            />

            {timeLeft > -1 && (
              <p
                style={{
                  color: "#333333",
                  fontSize: 14,
                  marginTop: 16,
                  letterSpacing: "-0.02em",
                }}
              >
                남은 시간 {String(parseInt(timeLeft / 60)).padStart(2, "0")} :{" "}
                {String(timeLeft % 60).padStart(2, "0")}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="withdrawal_btn2">
        <button onClick={() => history.goBack()}>취소</button>
        <button
          className={verifyCode.length === 6 ? "on" : "off"}
          onClick={handleClickNext}
        >
          다음
        </button>
      </div>
      {confirmPopupVisible && (
        <LeaveConfirmPopup
          onCancel={() => history.push(routes.main)}
          onConfirm={handleClickLeave}
        />
      )}
    </BasicLayout>
  );
};

export default LeaveSms;
