import React from "react";

function ReviewBoard() {
  return (
    <div
      className="prdDetail"
      style={{
        marginTop: "-30px",
        marginBottom: "-30px",
        minHeight: "700px",
      }}>
      <div className="vreview-review-board-widget"></div>
    </div>
  );
}

export default ReviewBoard;
