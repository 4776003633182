import React, { useState } from "react";
import { STORAGE_KEY } from "../../libs/consts";
import { postMessage } from "../../libs/utils";
import { formatTime } from "../../services/utils";

const Intro = ({ setIntroVisible }) => {
  const [step, setStep] = useState(1);

  const handleClickNext = () => {
    if (step === 3) {
      localStorage.setItem(
        STORAGE_KEY.FIRST_LOAD,
        formatTime(new Date(), "YYYYMMDDHHmmss")
      );
      setIntroVisible(false);
    } else {
      setStep(step + 1);
    }
  };

  return (
    <div className="tuto_wrap">
      <div className={`tuto${step} tuto`}>
        <button
          className="org_bt"
          id={`tuto_bt${step}`}
          onClick={handleClickNext}
        >
          {step === 3 ? "시작하기" : "다음"}
        </button>
      </div>
    </div>
  );
};

export default Intro;
