import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import ed4_bk from "../../assets/images/ed4_bk.png";
import ic_place_bk from "../../assets/images/ic_place_bk.png";
import ic_phone from "../../assets/images/ic_phone.png";
import ic_shop from "../../assets/images/icon_shop.png";
import delivery_box from "../../assets/images/delivery_box.webp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { initFindShop } from "../../redux/common/findShop";
import routes from "../../libs/routes";
import { calcDate, callPhone } from "../../libs/utils";
import * as APIS from "../../libs/apis";
import { formatTime } from "../../services/utils";
import styled from "styled-components";
import Flex from "../flex/Flex";
import Span from "../text/Span";
import fonts from "../../libs/fonts";

const VisitReservation = ({
  description = "",
  minDate = new Date(),
  deliveryLabel = false,
  shopType = "",
  goodsId,
  usingDelivery,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const findShop = useSelector((state) => state.findShop);
  const locationState = history.location.state || {};
  const { shop = {}, reservation = {} } = locationState;
  const [month, setMonth] = useState(minDate);
  const [workingDays, setWorkingDays] = useState([]);

  useEffect(() => {
    if (findShop.id) {
      history.replace(history.location.pathname, {
        ...locationState,
        shop: findShop,
        reservation: {},
      });
      dispatch(initFindShop());
    }
  }, []);

  useEffect(() => {
    if (shop.id && month) {
      APIS.getShopHours(shop.id, formatTime(month, "YYYY-MM")).then(
        ({ data: { success, message, data } }) => {
          if (success) {
            const { businessHoursList = [], holidaysList = [] } = data;
            const businessList = {};
            const resList = {};
            const resList2 = [];

            businessHoursList.forEach(
              ({ dayWeek, isFullDayOff, openHr, closeHr }) => {
                businessList[dayWeek] = {
                  isFullDayOff,
                  open: openHr,
                  close: closeHr,
                };
              }
            );
            const copy = new Date(month);
            copy.setMonth(copy.getMonth() + 1);
            copy.setDate(0);
            const lastDay = copy.getDate();

            for (let i = 1; i <= lastDay; i++) {
              const current = new Date(month);
              current.setDate(i);

              const currentDay = current.getDay();
              resList2.push({
                date: formatTime(current),
                ...businessList[currentDay],
              });
              resList[formatTime(current)] = { ...businessList[currentDay] };
            }

            holidaysList.forEach(
              ({ manageDate, isFullDayOff, openHr, closeHr }) => {
                const holiday = resList2.find(
                  ({ date }) => date === manageDate
                );
                if (holiday) {
                  holiday.isFullDayOff = isFullDayOff;
                  holiday.open = openHr;
                  holiday.close = closeHr;
                }

                resList[manageDate] = {
                  isFullDayOff,
                  open: openHr,
                  close: closeHr,
                };
              }
            );
            setWorkingDays(resList2);
          }
        }
      );
    }
  }, [shop.id, month]);

  const handleChangeDate = (date) => {
    const schedule =
      workingDays.find(
        ({ date: workingDate }) => workingDate === formatTime(date)
      ) || {};

    history.replace(history.location.pathname, {
      ...locationState,
      reservation: { ...schedule, date },
    });
  };
  return (
    <>
      {usingDelivery === "true" ? (
        <ShopContainer>
          <Badge>직접 대면</Badge>
          <Description>
            {description.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </Description>
          <div style={{ position: "relative", display: "flex", width: "100%" }}>
            <ShopButton
              onClick={() =>
                history.push(routes.commonFindShop, {
                  shopType: shopType,
                  goodsId: goodsId,
                })
              }>
              대리점 찾기
            </ShopButton>
            <img
              src={ic_shop}
              style={{
                position: "absolute",
                right: 16,
                top: 15,
                width: "56px",
                height: "51px",
              }}
            />
          </div>
        </ShopContainer>
      ) : (
        <div className="kb_cal_wrap">
          <div className="cal_bottom" style={{ padding: 0, margin: "32px 0" }}>
            <h3 style={{ paddingBottom: deliveryLabel ? 8 : 16 }}>
              {description.split("").map((x, i) => {
                if (x === "\n") {
                  return <br key={i.toString()} />;
                } else {
                  return x;
                }
              })}
            </h3>
            {deliveryLabel && (
              <>
                <DeliveryLabel>
                  <img src={delivery_box} width={24} height={24} />
                  <Span
                    size={15}
                    color="#555555"
                    spacing={-0.6}
                    style={{ marginLeft: 4 }}>
                    택배 수령은 매장 찾기 후 매장으로 문의바랍니다.
                  </Span>
                </DeliveryLabel>
              </>
            )}
            <button
              className={shop.id ? "on" : "off"}
              onClick={() =>
                history.push(routes.commonFindShop, {
                  shopType: shopType,
                  goodsId: goodsId,
                })
              }>
              대리점 찾기
            </button>
          </div>
        </div>
      )}
      {!!shop.id && (
        <div className="calendar_wrap" style={{ padding: 0 }}>
          <div className="store_visit">방문할 매장</div>
          <div className="store_visit_list">
            <div className="ic_place">
              <img src={ic_place_bk} alt="" />
            </div>
            <div className="store_visit_list_add">
              <p className="store_dis">
                <span className="store_name">{shop.name}</span>{" "}
                {shop.distance ? (
                  <>
                    <span className="spoqa">{shop.distance}</span>km
                  </>
                ) : (
                  <></>
                )}
              </p>
              <p className="store_add" style={{ paddingBottom: 0 }}>
                {shop.address} {shop.addressDetail}
              </p>
              <span
                className="orgbt"
                onClick={() => {
                  callPhone(shop.phoneNumber);
                }}
                style={{
                  padding: "0",
                  border: "none",
                  borderBottom: "1px solid #FF6E0E",
                }}>
                <img src={ic_phone} />
                {shop.phoneNumber}
              </span>
              {deliveryLabel && (
                <span
                  className="orgbt"
                  onClick={() => {
                    callPhone(shop.phoneNumber);
                  }}
                  style={{
                    marginLeft: 16,
                    padding: "0",
                    border: "none",
                    borderBottom: "1px solid #FF6E0E",
                  }}>
                  택배문의
                </span>
              )}
            </div>
          </div>
          <div className="date_select">
            <h3>희망하시는 매장 방문 일자를 선택하세요.</h3>
            <Calendar
              value={reservation.date}
              onChange={handleChangeDate}
              month={month}
              onMonthChange={setMonth}
              holidays={workingDays
                .filter(({ isFullDayOff }) => isFullDayOff)
                .map(({ date }) => date)}
              minDate={minDate}
              maxDate={calcDate("M", 1, minDate)}
              useShop={true}
            />
          </div>
          {!!shop.id && !!reservation.date && (
            <>
              <div className="visit_date_tit">방문 일자</div>
              <div className="visit_date_list">
                <div>
                  <img src={ed4_bk} alt="" />
                </div>
                <div className="visit_list">
                  <p className="visit_date">
                    {formatTime(reservation.date, "YYYY년 MM월 DD일 ddd요일")}
                  </p>
                  {/* {reservation.open && reservation.close && (
                    <p className="visit_time">
                      영업시간:{" "}
                      <span className="spoqa">
                        {reservation.open} ~ {reservation.close}
                      </span>
                    </p>
                  )} */}
                </div>
              </div>
              <ul className="con_box">
                <li className="con_tit">선택한 지점과 예약일</li>
                <li className="con_cont">
                  {shop.name},{" "}
                  {formatTime(reservation.date, "MM월 DD일 ddd요일")}
                </li>
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
};

const DeliveryLabel = styled(Flex)`
  margin-bottom: 16px;
  flex-direction: row;
  background: #f9f9f9;
  // border: 1px solid #f2f2f2;
  border-radius: 8px;
  align-items: flex-start;
  padding: 8px 4px;
`;

const ShopContainer = styled(Flex)`
margin: 8px 0px 20px 0px;
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
border: 1px solid #EAEAEA;
background: #FFF;

/* shadow_card_02 */
box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
`;

const Badge = styled.div`
display: flex;
height: 24px;
padding: 0px 8px;
justify-content: center;
align-items: center;
border-radius: 25px;
border: 1px solid rgba(255, 110, 14, 0.20);
background: rgba(255, 255, 255, 0.00);
color: #FF6E0E;
leading-trim: both;
text-edge: cap;
font-family: Pretendard;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.24px;
`;

const Description = styled.div`
margin-top: 8px;
display: flex;
align-items: center;
align-content: center;
flex-wrap: wrap;
color: #444;
font-family: "Noto Sans KR";
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.32px;
`;
const ShopButton = styled.button`
display: flex;
margin-top: 32px;
height: 36px;
padding: 0px 16px;
justify-content: center;
align-items: center;
border-radius: 8px;
border: 0px solid #222;
background: #F5F5F5;
color: #444;
leading-trim: both;
text-edge: cap;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.28px;
`;
export default VisitReservation;
