import { ButtonBase, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import MainLayout from "../../layouts/main/MainLayout";
import * as APIS from "../../libs/apis";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import AssetItem from "./AssetItem";
import CanOrder from "./CanOrder";
import Header from "./Header";
import MyAsset from "./MyAsset";
import TradeHistory from "./trade-history/TradeHistory";

export default function MyPage({ navigate }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { tab = "ASSET" } = locationState;

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };
  const handleClickTab = (tab) => {
    history.replace(history.location.pathname, { tab });
  };

  return (
    <MainLayout white>
      <HeaderWrap>
        <HeaderTitle>마이</HeaderTitle>
        <Flex row>
          <TabWrap onClick={() => handleClickTab("ASSET")}>
            <Tab selected={tab === "ASSET"}>내 자산</Tab>
          </TabWrap>
          <TabWrap onClick={() => handleClickTab("HISTORY")}>
            <Tab selected={tab === "HISTORY"}>거래내역</Tab>
          </TabWrap>
        </Flex>
      </HeaderWrap>
      {tab === "ASSET" && <MyAsset />}
      {tab === "HISTORY" && <TradeHistory />}
    </MainLayout>
  );
}

const HeaderWrap = styled(Flex)`
  border-bottom: 1px solid #eeeeee;
`;

const HeaderTitle = styled(Flex)`
  padding: 13px 16px 11px 16px;
  font-family: ${fonts.notoSansKRBold};
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #333333;
`;

const TabWrap = styled(Flex)`
  flex: 1;
  align-items: center;
`;

const Tab = styled(Flex)`
  padding: 7px 0px;
  font-family: ${({ selected }) =>
    selected ? fonts.notoSansKRBold : fonts.notoSansKRMedium};
  font-size: 17px;
  letter-spacing: -0.34px;
  color: ${({ selected }) => (selected ? "#333333" : "#666666")};
  border-bottom: ${({ selected }) => (selected ? "4px solid #444444" : "none")};
`;
