import { makeStyles } from "@material-ui/core";
import React from "react";
import Flex from "../../components/flex/Flex";
import colors from "../../libs/colors";
import StackTopbar from "./StackTopbar";

export default function BasicLayout({
  backgroundGray,
  white,
  stackTopbar,
  children,
  center,
}) {
  const classes = useStyle();
  return (
    <Flex
      style={{
        backgroundColor: backgroundGray ? backgroundGray : colors.white,
      }}
      className={`${classes.root} ${center ? classes.rootCenter : ""}`}
    >
      {stackTopbar && <StackTopbar {...stackTopbar} />}
      <Flex
        className={classes.container}
        style={{
          backgroundColor: backgroundGray ? backgroundGray : colors.white,
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  root: {
    backgroundColor: "rgb(233,236,239)",
    flex: 1,
  },
  rootCenter: {
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "100%",
    alignSelf: "center",
    flex: 1,
    overflowY: "auto",
    maxWidth: "960px",
  },
});
