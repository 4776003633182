import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import MainLayout from "../../layouts/main/MainLayout";
import routes, { ROLE } from "../../libs/routes";
import Label from "./Label";
import ListItem from "./ListItem";
import Topbar from "./Topbar";
import * as APIS from "../../libs/apis";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { requestNativeLogin } from "../../libs/utils";
import { signOut } from "../../libs/sign";
import { actionError } from "../../redux/action/ActionActions";
import LeaveConfirmPopup from "../edit-profile/LeaveConfirmPopup";

const LOGIN_TYPE = {
  NON: "NON_LOGIN", // 비로그인 메뉴
  LOGIN: "LOGIN", // 회원 메뉴
  TEMP: "TEMP", // 임시 회원 메뉴
  VERIFICATION: "VERIFICATION", // 본인 인증 회원 메뉴
};

export default function Menu({ navigate }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();

  const [leavePopupVisible, setLeavePopupVisible] = useState(false);
  const [usingDelivery, setUsingDelivery] = useState(false);
  const {
    role: { LEVEL: userLevel },
  } = useSelector((s) => s.auth);

  useEffect(() => {
    APIS.getEnvConfig("APPRAISAL_USING_DELIVERY_POST").then(
      ({ data: { success, data } }) => {
        success && setUsingDelivery(data?.value);
      }
    );
  }, []);

  const handleClickLeave = () => {
    dispatch(loadingStart);
    APIS.patchLeaveSocial()
      .then(({ data: { success, message } }) => {
        if (success) {
          history.push(routes.leaveComplete);
        } else {
          setLeavePopupVisible(false);
          dispatch(actionError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  const MENU_LIST = [
    {
      isLabel: true,
      title: "회원가입 및 로그인",
      loginType: LOGIN_TYPE.NON,
      top: true,
    },
    {
      title: "회원가입",
      loginType: LOGIN_TYPE.NON,
      onClick: () => requestNativeLogin(history),
    },
    {
      title: "로그인",
      loginType: LOGIN_TYPE.NON,
      onClick: () => requestNativeLogin(history),
    },
    { isLabel: true, title: "매수/매도", top: true },
    { title: "금(GOLD) 매수/매도", path: routes.trading },
    {
      title: "은(SILVER) 매수/매도",
      path: routes.trading,
      param: { type: "silver" },
    },
    { isLabel: true, title: "서비스" },
    {
      title: "택배 감정 접수하기",
      loginType: LOGIN_TYPE.VERIFICATION,
      path: routes.appraiseRegist,
      param: { step: 1 },
    },
    { title: "간편감정", path: routes.appraiseRegistMain, param: { step: 1 } },
    { title: "현장 감정 접수하기", path: routes.appraiseDirect },
    { title: "실물인출", path: routes.physicalWithDrawal },
    { title: "상품 리뷰", path: routes.myreview },
    // { title: "안심직거래", path: routes.safeTradeList },
    // {
    //   title: "쿠폰 등록하기",
    //   loginType: LOGIN_TYPE.LOGIN,
    //   path: routes.couponRegister,
    // },
    { isLabel: true, title: "입출금", loginType: LOGIN_TYPE.LOGIN },
    {
      title: "출금하기",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.depositWithdrawal,
    },
    {
      title: "입금하기",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.depositWithdrawal,
    },
    { isLabel: true, title: "마이", loginType: LOGIN_TYPE.LOGIN },
    { title: "내 자산 현황", loginType: LOGIN_TYPE.LOGIN, path: routes.mypage },
    { title: "보관료", loginType: LOGIN_TYPE.LOGIN, path: routes.storageFee },
    {
      title: "매수/매도 체결내역",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.mytradehistory,
    },
    // {
    //   title: "내 간편감정",
    //   loginType: LOGIN_TYPE.LOGIN,
    //   path: routes.appraise,
    // },
    // {
    //   title: "내 실물인출",
    //   loginType: LOGIN_TYPE.LOGIN,
    //   path: routes.myPhysicalWithDrawal,
    // },
    { isLabel: true, title: "고객센터" },
    { title: "공지사항", path: routes.noticeList },
    { title: "이벤트", path: routes.eventList },
    {
      title: "이용가이드",
      onClick: () => {
        history.push(routes.userGuide, {
          url: "https://guide.kumbang.co.kr/index.html",
          title: "이용가이드",
        });
      },
    },
    { title: "자주하는 질문", path: routes.faqList },
    {
      title: "1:1 문의하기",
      loginType: LOGIN_TYPE.LOGIN,
      path: routes.inquiry,
    },
    { title: "이용약관", path: routes.termsList },
    {
      title: "내정보 수정",
      loginType: LOGIN_TYPE.VERIFICATION,
      path: routes.editProfile,
    },
    {
      title: "탈퇴하기",
      loginType: LOGIN_TYPE.TEMP,
      onClick: () => setLeavePopupVisible(true),
    },
  ];

  const filteredMenu = MENU_LIST.filter(({ loginType }) => {
    switch (loginType) {
      case LOGIN_TYPE.NON:
        return !userLevel;

      case LOGIN_TYPE.LOGIN:
        return userLevel;

      case LOGIN_TYPE.TEMP:
        return userLevel === ROLE.ROLE_TEMP.LEVEL;

      case LOGIN_TYPE.VERIFICATION:
        return userLevel >= ROLE.ROLE_SELF_VERIFICATION.LEVEL;

      default:
        return true;
    }
  });

  return (
    <MainLayout className={classes.root} white>
      <Topbar />
      {filteredMenu.map(({ isLabel, title, top, path, param, onClick }, i) => {
        if (isLabel) {
          return (
            <Label key={i} top={i === 0}>
              {title}
            </Label>
          );
        } else {
          // 감정평가 택배 사용 여부가 false이면서 택배 감정 경로일 때
          if (usingDelivery === "false" && path === routes.appraiseRegist) {
            <></>;
          } else {
            return (
              <ListItem
                key={i}
                onClick={() => {
                  onClick ? onClick() : navigate(path, param);
                }}
              >
                {title}
              </ListItem>
            );
          }
        }
      })}
      {leavePopupVisible && (
        <LeaveConfirmPopup
          onCancel={() => setLeavePopupVisible(false)}
          onConfirm={handleClickLeave}
        />
      )}
    </MainLayout>
  );
}

const useStyle = makeStyles({
  root: { paddingTop: 60 },
});
