import React from "react";
import styled from "styled-components";
import Flex from "../../../../components/flex/Flex";
import fonts from "../../../../libs/fonts";
import tradeBuy from "../../../../assets/images/trade_buy.webp";
import tradeSell from "../../../../assets/images/trade_sell.webp";
import historyGoods from "../../../../assets/images/history_goods.webp";
import SpaceBetween from "../../../../components/flex/SpaceBetween";
import Span from "../../../../components/text/Span";
import { formatTime, numberWithCommas } from "../../../../libs/utils";
import consts from "../../../../libs/consts";
import product_default from "../../../../assets/images/product_default.webp";
import { useHistory } from "react-router";
import routes from "../../../../libs/routes";

const GoodsItem = ({
  requestAt,
  tradeType,
  requestAssetType,
  requestGram,
  contractGram,
  requestPrice,
  requestKrw,
  status,
  imagePath,
  requestId,
  userFee,
  productName,
  tradeNumber,
  goodsId,
  ...rest
}) => {
  const history = useHistory();
  return (
    <Container
      onClick={() => {
        history.push(routes.orderDetail(requestId, tradeNumber, goodsId));
      }}
    >
      <Header>
        <StatusIcon />
        <StatusLabel>{status}</StatusLabel>
      </Header>
      <ItemType>
        <img src={historyGoods} width={18} /> 실물인출
      </ItemType>
      <Flex row style={{ marginTop: 14, marginBottom: 16 }}>
        <Flex>
          <img
            src={
              imagePath ? `${consts.s3BaseUrl}/${imagePath}` : product_default
            }
            width={72}
            height={72}
            style={{
              border: "1px solid #d6d6d6",
              borderRadius: 8,
              marginRight: 30,
              objectFit: "contain",
            }}
          />
        </Flex>
        <Flex style={{ flex: 1 }}>
          <Span font={fonts.notoSansKRBold} size={17}>
            {productName}
          </Span>
          <SpaceBetween>
            <Span size={15} color="#666666" style={{ width: 70 }}>
              접수번호
            </Span>
            <Span
              size={10}
              color="#666666"
              style={{ flex: 1, textAlign: "right" }}
            >
              {tradeNumber}
            </Span>
          </SpaceBetween>
          <SpaceBetween>
            <Span size={15} color="#666666" style={{ width: 70 }}>
              중량
            </Span>
            <Span size={16} color="#666666">
              <Span font={fonts.spoqa}>{numberWithCommas(requestGram)}</Span>g
            </Span>
          </SpaceBetween>
          <SpaceBetween>
            <Span size={15} color="#666666" style={{ width: 70 }}>
              수수료
            </Span>
            <Span size={16} color="#666666">
              <Span font={fonts.spoqa}>{numberWithCommas(userFee)}</Span>원
            </Span>
          </SpaceBetween>
        </Flex>
      </Flex>
    </Container>
  );
};

const Container = styled(Flex)`
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const Header = styled(Flex)`
  flex-direction: row;
  padding: 8.5px 0;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-end;
`;

const StatusLabel = styled(Flex)`
  margin-left: 3px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 14px;
  letter-spacing: -0.28px;
  color: #e76e26;
`;
const TimeLabel = styled(Flex)`
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid #999999;
  font-family: ${fonts.spoqa};
  font-size: 14px;
  color: #666666;
`;

const StatusIcon = styled(Flex)`
  align-self: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(00, 00, 00, 0.1);

  &::before {
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background: #ff6e0e;
    content: "";
  }
`;

const ItemType = styled(Flex)`
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.notoSansKRMedium};
  font-size: 15px;
  letter-spacing: -0.3px;
  color: #222222;
  padding: 14px 0 8px 0;
`;

const FeeWrap = styled(Flex)`
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  margin: 7px 0 15px 0;
`;

export default GoodsItem;
