import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DirectTransactionItem from "../../components/direct-transaction-item/DirectTransactionItem";
import StackTopbar from "../../layouts/basic/StackTopbar";
import MainLayout from "../../layouts/main/MainLayout";

export default function MyDirectTransaction({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <MainLayout white topbar={<StackTopbar border title="내 안심직거래" />}>
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
      <DirectTransactionItem order />
    </MainLayout>
  );
}

const useStyle = makeStyles({});
