import React, { useEffect, useMemo, useState } from "react";
// import logo from "../../assets/images/kumbang_logo.png";
import logo from "../../assets/images/logo_kb.png";
import banner_default from "../../assets/images/banner_default.png";
import ic_notice_a_active from "../../assets/images/ic_notice_a_active.png";
import ic_no from "../../assets/images/ic_no.png";
import ic_con_right from "../../assets/images/ic_con_right.png";
import ic_notice_danger from "../../assets/images/ic_notice_danger.png";
import ic_up from "../../assets/images/ic_up.png";
import ic_down from "../../assets/images/ic_down.png";
import ic_buy from "../../assets/images/ic_buy.png";
import ic_sell from "../../assets/images/ic_sell.png";
import ic_notice_right from "../../assets/images/ic_notice_right.png";
import gold1 from "../../assets/images/gold1.png";
import gold2 from "../../assets/images/gold2.png";
import ic_notice_a_active_w from "../../assets/images/ic_notice_a_active_w.png";
import ic_con_right_b from "../../assets/images/ic_con_right_b.png";
import ic_notice_a from "../../assets/images/ic_notice_a.png";
import tr_noti from "../../assets/images/tr_noti.png";
import ev_noti from "../../assets/images/ev_noti.png";
import silver1 from "../../assets/images/silver1.png";
import silver2 from "../../assets/images/silver2.png";
import guide from "../../assets/images/guide.png";
import ic_place from "../../assets/images/ic_place.png";
import ic_call from "../../assets/images/ic_call.png";
import footer_logo from "../../assets/images/footer_logo.png";
import notiicon_on from "../../assets/images/notiicon_on.png";
import notiicon_off from "../../assets/images/notiicon_off.png";
import icon_chat from "../../assets/images/main/icon_chat.webp";
import close_chat from "../../assets/images/main/close_chat.webp";
import guide_banner from "../../assets/images/guide_banner.webp";
import Image from "../../components/image/Image";
import useInterval from "../../hooks/useInterval";
import { refreshMarketPrice } from "../../libs/actions";
import Accordion from "./Accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  formatTime,
  getAppVersion,
  numberWithCommas,
  postMessage,
} from "../../libs/utils";
import consts, {
  KAKAO_APP_KEY,
  KAKAO_CHANNEL_PUBLIC_ID,
  SERVICE_TYPE,
} from "../../libs/consts";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import { useHistory } from "react-router-dom";
import auth from "../../hoc/auth";
import Bottombar from "../../components/bottombar/Bottombar";
import { ButtonBase, makeStyles } from "@material-ui/core";
import Modal from "../../components/modal/Modal";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import { objToQueryStr } from "../../services/utils";
import TopBanner from "./TopBanner";
import NoticePopup from "./NoticePopup";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import styled from "styled-components";
import { disableKakaoChat } from "../../redux/kakao/kakaoChat";
import BottomPopup from "./BottomPopup";
import fonts from "../../libs/fonts";
import { useQuery, useQueryClient } from "react-query";
import { ProductGrade, ReviewCollection } from "../../components/vreviewWidget";
import colors from "../../libs/colors";
import images from "../../libs/images";
SwiperCore.use([Navigation, Autoplay, Pagination]);

const Main = ({ navigate }) => {
  const classes = useStyle();
  const history = useHistory();
  const marketPrice = useSelector((s) => s.marketPrice);
  const auth = useSelector((s) => s.auth);
  const [banners, setBanners] = useState([]);
  // const [hotGoods, setHotGoods] = useState([]);
  const [hotSafeTrade, setHotSafeTrade] = useState([]);
  const [noticeList, setNoticeList] = useState([]);
  const [myNotice, setMyNotice] = useState({});
  const [swiperIndex, setSwiperIndex] = useState(0);
  const state = history?.location?.state || {};
  const [signUpLimit, setSignUpLimit] = useState(null);
  const [badgeCount, setBadgeCount] = useState(0);
  const kakaoChat = useSelector((s) => s.kakaoChat);

  const queryClient = useQueryClient();
  const totalList = queryClient.getQueryData("goodsAllList");

  const { data: hotGoods = [] } = useQuery(
    "hotGoods",
    () => {
      return APIS.getHotGoods().then(({ data: { success, data } }) => {
        if (success) {
          let arr = [];
          data?.goodsIdList.forEach((item) => {
            if (totalList.find((goods) => item === goods.id)) {
              arr.push(totalList.find((goods) => item === goods.id));
            }
          });
          return arr;
        }
      });
    },
    {
      initialData: [],
      enabled: Array.isArray(totalList) && totalList.length !== 0,
    }
  );

  useInterval(() => {
    refreshMarketPrice();
  }, 10000);

  useEffect(() => {
    init();

    // opacity 67
    if (state.errorType === "CANNOT_RESIGNUP") {
      const { leaveDate, reSignupDate } = state;
      setSignUpLimit({ leaveDate, reSignupDate });
      history.replace(routes.main);
    }
  }, []);

  const init = async () => {
    // 앱에서 뒤로가기 못하게 제어
    postMessage({ command: "CAN_NOT_GO_BACK" });

    // Flag 서버 호출하여 API 동작 여부 확인
    postMessage({ command: "CALL_FLAG_API" });

    const params = { type: "BANNER" };
    const banners = await APIS.getBanner(objToQueryStr(params)).then(
      ({ data: { success, data } }) => {
        if (success) {
          const banners = [];
          data.forEach((item) => {
            banners.push({
              src: consts.s3BaseUrl + item.path,
              content: item.content,
              category: item.category,
            });
          });
          return banners;
        }
      }
    );

    if (banners?.length) {
      setBanners(banners);
    } else {
      setBanners([{ src: banner_default }]);
    }

    // if (auth?.isSigned && auth?.role?.LEVEL > 1) {
    //   APIS.getMyNotice().then(({ data: { success, data } }) => {
    //     success && setMyNotice(data);
    //   });
    // }
    APIS.getNotice().then(({ data: { success, data } }) => {
      success && setNoticeList(data.fixed);
    });

    // APIS.getHotGoods().then(({ data: { success, data } }) => {
    //   success && setHotGoods(data);
    // });

    APIS.getHotSafeTrade().then(({ data: { success, data } }) => {
      success && setHotSafeTrade(data);
    });
  };

  useEffect(() => {
    if (auth.role.LEVEL >= 2) {
      APIS.getMyNotice().then(({ data: { success, data } }) => {
        success && setMyNotice(data);
      });

      if (window.ReactNativeWebView) {
        const listener = (event) => {
          if (event.data?.eventType) {
            return;
          }
          const message = JSON.parse(event.data) || {};
          const { command, data = {} } = message;

          if (command === "RESPONSE_BADGE_COUNT") {
            const { count = 0 } = data;
            setBadgeCount(count);
          }
        };

        document.addEventListener("message", listener);
        window.addEventListener("message", listener);

        postMessage({ command: "GET_BADGE_COUNT" });
        return () => {
          document.removeEventListener("message", listener);
          window.removeEventListener("message", listener);
        };
      }
    } else {
      setMyNotice({});
    }
  }, [auth?.role?.LEVEL]);

  const handleClickTradingGuide = (type) => {
    history.push(routes.tradingGuide, { type });
  };

  return (
    <>
      {/* <!-- 헤더 --> */}
      <header>
        <img
          src={logo}
          alt="logo"
          // style={{ height: 28, margin: "14px 0 14px 0" }}
        />
        {auth?.role?.LEVEL >= 2 && (
          <img
            src={badgeCount ? notiicon_on : notiicon_off}
            alt="icon"
            className="noticon"
            onClick={() => {
              navigate(routes.notification, { count: badgeCount });
            }}
          />
        )}
      </header>
      {/* <!-- 헤더 --> */}
      {/* <!-- 메인슬라이드 --> */}
      <TopBanner banners={banners} />
      {/* <!-- 메인슬라이드 --> */}
      {/* <!-- S:kumbang_wrap --> */}
      {/* <!-- 메인 시작 --> */}
      <div className="kumbang_wrap">
        <div className="kumbang_main">
          {/* <!--본인인증 팝업 시작--> */}
          <NoticePopup {...myNotice} navigate={navigate} />
          {/* <!--본인인증 팝업 끝--> */}
          {/* <!--살래요/팔래요 시작--> */}
          <div className="new_notice">
            <button
              className="buy_btn"
              onClick={() => handleClickTradingGuide("BUY")}>
              살래요
            </button>
            <button
              className="sell_btn"
              onClick={() => handleClickTradingGuide("SELL")}>
              팔래요
            </button>
          </div>
          {/* <!--살래요/팔래요 끝--> */}
          {/* <!--본인인증 팝업 공지사항--> */}
          {!!noticeList.length && (
            <div className="kumbang_noti">
              <Swiper
                style={{ flex: 1 }}
                loop={noticeList.length > 1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}>
                {noticeList.map((item, i) => {
                  return (
                    <SwiperSlide
                      key={i.toString()}
                      onClick={() => {
                        history.push(routes.noticeDetail(i), { id: item.id });
                      }}>
                      <p style={{ flex: 1, width: "auto" }}>
                        <img src={ic_notice_a} alt="" className="ic_notice_a" />
                        <span className="noti_org">[공지]</span>
                        {item.subject}
                      </p>
                    </SwiperSlide>
                  );
                })}
              </Swiper>

              <button
                className="btn_all"
                onClick={() => {
                  history.push(routes.noticeList);
                }}>
                더보기
                <img src={ic_notice_right} className="ic_notice_right" />
              </button>
            </div>
          )}
          {/* <!--본인인증 팝업 공지사항--> */}
          {/* <!--금,은,다이아몬드 박스 시작--> */}
          <div className="accordion vertical">
            <ul>
              <Accordion
                asset="gold"
                navigate={navigate}
                hotGoods={hotGoods.filter(
                  ({ assetType }) => assetType === "GOLD"
                )}
                className="fstline"
              />
              <Accordion
                asset="silver"
                navigate={navigate}
                hotGoods={hotGoods.filter(
                  ({ assetType }) => assetType === "SILVER"
                )}
                className="scdline"
              />
              <div className="time spoqa">
                ({formatTime(marketPrice.updatedAt, "YYYY.MM.DD HH:mm:ss")})
              </div>

              <li className="lstline">
                {/* <input type="checkbox" checked readOnly /> */}
                <label>
                  <span className={`li_tit other ${classes.noBefore}`}>
                    인기상품
                  </span>
                </label>
                <div className="content content3" style={{ height: 283 }}>
                  <div className="price_product" style={{ paddingLeft: 0 }}>
                    <div className="product_cont">
                      <ul style={{ padding: "3px 7px 0px 17px" }}>
                        {[
                          ...hotGoods.filter(
                            ({ assetType }) => assetType === "GOLD"
                          ),
                          ...hotGoods.filter(
                            ({ assetType }) => assetType === "SILVER"
                          ),
                        ].map(
                          ({ image, name, gram, totalPrice, id, code }, i) => {
                            return (
                              <li
                                className="product_card"
                                key={i.toString()}
                                onClick={() => {
                                  navigate(`${routes.detailGateway}${id}`);
                                }}>
                                <img
                                  src={`${consts.s3BaseUrl}/${image}`}
                                  alt=""
                                />

                                <p className="spoqa">
                                  {name}
                                  <span className="spoqa">
                                    ({numberWithCommas(gram)}g)
                                  </span>
                                </p>
                                <p className="product_price">
                                  <span className="spoqa">
                                    {numberWithCommas(totalPrice)}
                                  </span>
                                  원
                                </p>
                                <ProductGrade id={id} />
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <button
                    onClick={() => navigate(routes.physicalWithDrawal)}
                    className={classes.hotGoodsButton}>
                    인기상품 전체보기
                  </button>
                </div>
              </li>

              {/* <li className="lstline">
                <div
                  className="btn_cx_down"
                  onClick={() => {
                    document.getElementById("checkbox-3").click();
                  }}
                />
                <input
                  type="checkbox"
                  id="checkbox-3"
                  name="checkbox-accordion"
                />
                <label htmlFor="checkbox-3">
                  <span className="li_tit other">안심직거래</span>
                </label>
                <div className="content content3">
                  <div
                    className="btn_cx_up"
                    onClick={() => {
                      document.getElementById("checkbox-3").click();
                    }}
                  />
                  <div className="price_product">
                    <div className="product_tit">
                      <h3>안심직거래 인기 상품</h3>
                      <button
                        className="btn_all"
                        onClick={() => history.push(routes.safeTradeList)}
                      >
                        전체 보기
                      </button>
                    </div>
                    <div className="product_cont">
                      <ul>
                        <li
                          className="product_card jewelry"
                          onClick={() => {
                            window.location.href =
                              "https://guide.kumbang.co.kr/guide_view_11.html";
                          }}
                        >
                          <h3>
                            금방금방
                            <br />
                            안심직거래 안내
                          </h3>
                          <p>상세 안내 보기</p>
                        </li>

                        {hotSafeTrade.map((item, i) => {
                          return (
                            <li
                              className="product_card"
                              key={i.toString()}
                              onClick={() => {
                                history.push(routes.safeTradeItem, {
                                  id: item.item_id,
                                });
                              }}
                            >
                              <img
                                src={`${consts.s3BaseUrl}/${item.image}`}
                                alt=""
                                style={{ width: "100%", objectFit: "cover" }}
                              />
                              <p>{item.name}</p>
                              <p className="product_price">
                                <span className="spoqa">
                                  {numberWithCommas(item.price)}
                                </span>
                                원
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
          {/* <!--금,은,다이아몬드 박스 끝--> */}

          <ButtonBase
            className={classes.reviewBtn}
            onClick={() => {
              history.push(routes.reviewCollection);
            }}>
            <div className={classes.flexRow}>
              <Text className={classes.reviewLabel}>리뷰 모아보기</Text>
              <div className={classes.reviewTagWrap}>
                <Text className={classes.reviewTagLabel}>
                  #감정평가 #구매리뷰
                </Text>
              </div>
            </div>
            <img alt="right" src={images.rightgray} />
          </ButtonBase>
        </div>
      </div>
      {/* <!-- E:kumbang_wrap --> */}

      {/* <!--  이용가이드 시작 --> */}
      <div
        className="kumbang_guide"
        style={{ background: "#fafafa", padding: "24px 16px" }}>
        <div
          // className="guide"
          onClick={() => {
            history.push(routes.userGuide, {
              url: "https://guide.kumbang.co.kr/index.html",
              title: "이용가이드",
            });
          }}>
          <img src={guide_banner} style={{ width: "100%" }} />
          {/* <div className="noti_tit">
            이용가이드
            <div className="noti_cont">
              금방금방이 처음이시라면
              <br />
              이용가이드를 통해 도움 받으세요!
            </div>
          </div>
          <img src={guide} alt="" /> */}
        </div>
      </div>
      <div
        className="kumbang_agency"
        style={{ padding: "24px 16px", background: "#f5f5f5" }}>
        <div
          className="agency_noti"
          style={{ border: "1px solid #e0e0e0", borderRadius: 8 }}
          onClick={() => {
            const param = { event: "gb_search_store" };
            if (auth.userId) {
              param.value = { customer_user_id: `gb_${auth.userId}` };
              param.userId = auth.userId;
            }

            postMessage({ command: "LOG_APPS_FLYER", param });

            navigate(routes.authFindShop, { from: routes.main });
          }}>
          <img src={ic_place} alt="" /> 대리점 안내
        </div>
        <div
          className="agency_conc"
          style={{ border: "1px solid #e0e0e0", borderRadius: 8 }}
          onClick={() => {
            const param = { event: "gb_search_call" };
            if (auth.userId) {
              param.value = { customer_user_id: `gb_${auth.userId}` };
              param.userId = auth.userId;
            }

            postMessage({ command: "LOG_APPS_FLYER", param });
            window.location.href = "tel:15449993";
          }}>
          <img src={ic_call} alt="" /> 대리점 연결
        </div>
      </div>
      {/* <!--  이용가이드 끝  --> */}
      <footer>
        <div className="bottom_logo">
          <img src={footer_logo} alt="한국금거래소" />
          <p>
            서울시 종로구 돈화문로 <span className="spoqa">5</span>가길&nbsp;
            <span className="spoqa">1</span>, 피카디리플러스
            <span className="spoqa"> 8</span>층{" "}
          </p>
          <span className="spoqa">1670-8978</span>
        </div>
      </footer>
      <BottomBarWithNavigate />
      {!!signUpLimit && (
        <SignUpLimitModal
          {...signUpLimit}
          onClose={() => setSignUpLimit(null)}
        />
      )}
      {!kakaoChat.disabled && <KakaoChat />}
      <BottomPopup />
    </>
  );
};

const BottomBarWithNavigate = auth(Bottombar);

const SignUpLimitModal = ({ onClose, leaveDate, reSignupDate }) => {
  const classes = useStyle();
  return (
    <Modal onClickBackground={onClose}>
      <Flex className={classes.withdrawalWrap}>
        <Text style={{ fontSize: 24, fontWeight: 700 }}>재가입 불가</Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 10 }}>
          ※ {formatTime(leaveDate, "YYYY.MM.DD")} 탈퇴하셨습니다.
        </Text>
        <Text style={{ fontSize: 16, color: "gray", marginTop: 5 }}>
          ※ {formatTime(reSignupDate, "YYYY.MM.DD")} 부터 재가입 가능합니다.
        </Text>

        <ButtonBase className={classes.btn} onClick={onClose}>
          <Text>확인</Text>
        </ButtonBase>
      </Flex>
    </Modal>
  );
};

const StyledShadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100%;
  box-shadow: ${({ y, blur }) => `0 ${y}px ${blur}px rgba(0, 0, 0, 0.33)`};
  z-index: -1;
`;

const KakaoChat = () => {
  const dispatch = useDispatch();

  const handleClickKakaoChat = () => {
    const kakao = window.Kakao;
    if (kakao) {
      if (!kakao.isInitialized()) {
        kakao.init(KAKAO_APP_KEY);
      }

      kakao.Channel.chat({ channelPublicId: KAKAO_CHANNEL_PUBLIC_ID });
    }
  };

  const handleClickClose = () => {
    dispatch(disableKakaoChat());
  };

  return (
    <Flex
      style={{
        position: "fixed",
        bottom: 74,
        right: 16,
        zIndex: 1,
        alignItems: "center",
      }}>
      <div style={{ position: "relative" }}>
        <StyledShadow y={1} blur={10} />
        <StyledShadow y={4} blur={5} />
        <StyledShadow y={2} blur={4} />
        <img
          src={icon_chat}
          width={56}
          onClick={handleClickKakaoChat}
          style={{ display: "block" }}
        />
      </div>

      <img src={close_chat} width={34} onClick={handleClickClose} />
    </Flex>
  );
};

const useStyle = makeStyles({
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  withdrawalWrap: {
    flex: 1,
    alignSelf: "flex-end",
    boxSizing: "border-box",
    position: "relative",
    backgroundColor: "#fff",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: "30px 0",
    alignItems: "center",
  },
  btn: {
    width: 200,
    backgroundColor: "#FC5710",
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
    padding: 5,
    borderRadius: 5,
    marginTop: 50,
  },
  reviewBtn: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    "& img": {
      width: "7px",
      height: "12px",
    },
    borderRadius: 8,
    border: "1px solid #EAEAEA",
  },
  reviewLabel: {
    fontWeight: 700,
    color: colors.text,
    fontSize: "16px",
  },
  reviewTagWrap: {
    marginLeft: 12,
    backgroundColor: "#ffede2",
    borderRadius: 8,
    padding: "3px 7px",
  },
  reviewTagLabel: {
    color: "#FF6E0E",
    fontSize: "12px",
  },
  goSafeTradeSell: {
    width: "calc(100% - 17px)",
    height: 42,
    background: "#FF6E0E",
    borderRadius: 20,
    color: "#fff",
    fontSize: 14,
    fontWeight: 700,
    border: "none",
    position: "relative",
    marginBottom: 14,
  },
  noBefore: {
    "&::before": {
      backgroundImage: "none !important",
      width: "0px !important",
    },
  },
  hotGoodsButton: {
    width: "calc(100% - 34px)",
    height: 42,
    background: "#ffede2",
    borderRadius: 8,
    color: "#ff6e0e",
    fontSize: 14,
    fontFamily: fonts.notoSansKRBold,
    letterSpacing: -0.28,
    border: "none",
    position: "absolute",
    margin: "0px 17px 0px 17px",
    bottom: 17,
  },
});

export default Main;
