import React from "react";
import BasicLayout from "../../layouts/basic/BasicLayout";
import { ReviewCollection } from "../../components/vreviewWidget";

export default function ReviewCollectionPage({}) {
  return (
    <BasicLayout
      stackTopbar={{
        title: "리뷰 모아보기",
        border: true,
      }}>
      <ReviewCollection
        className="reviews"
        style={{
          marginTop: "-50px",
        }}
      />
    </BasicLayout>
  );
}
