import React from "react";
import fonts from "../../libs/fonts";

export default function Text({ children, style, className, font }) {
  return (
    <span
      className={className}
      style={{ fontFamily: `${font || fonts.notoSansKRRegular}`, ...style }}
    >
      {children}
    </span>
  );
}
