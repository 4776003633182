import { CircularProgress, makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import Flex from "../../components/flex/Flex";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { objToQueryStr } from "../../services/utils";
import qs from "query-string";
import { userInit } from "../../redux/user/UserActions";
export default function LoginNice({}) {
  const classes = useStyle();
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = match.params.state;
  const state = qs.parse(history.location.search);
  const { signed } = useSelector((s) => s.user);

  useEffect(() => {
    if (signed) {
      history.replace("/");
    }
  }, [signed]);
  useEffect(() => {
    if (match.params.state === "failed") {
      dispatch(
        messageShow(
          "본인인증을 하던중 오류가 발생했습니다.\n다시 로그인해 주세요.",
          () => {
            history.replace("/login");
          }
        )
      );
    } else {
      axios
        .get(
          consts.apiBaseUrl +
            "/api/nice/decode" +
            objToQueryStr({
              EncodeData: state.EncodeData,
            })
        )
        .then((res) => {
          const data = res.data;
          const name = data.NAME;
          const phone = data.MOBILE_NO;
          const socialKey = qs.parse(data.REQ_SEQ).socialKey;
          const type = qs.parse(data.REQ_SEQ).method;
          axios
            .post(consts.apiBaseUrl + "/api/oauth/joinAccount", {
              name,
              phone,
              socialKey,
              type,
            })
            .then((res) => {
              localStorage.setItem(
                consts.accessToken,
                res.data.response.access_token
              );
              dispatch(userInit);
            })
            .catch((e) => {
              dispatch(
                messageError(e, () => {
                  history.replace("/login");
                })
              );
            });
        })
        .catch((e) => {
          dispatch(
            messageError(e, () => {
              history.replace("/login");
            })
          );
        });
    }
  }, [state, match]);

  const signUp = () => {
    axios.post(consts.apiBaseUrl + "/api/oauth/joinAccount", {
      name: "박진호",
      phone: "01021883985",
      socialKey: "string",
      type: "NAVER",
    });
  };

  return (
    <Flex className={classes.root}>
      <CircularProgress color={colors.primary} />
      <span>본인인증 정보를 확인하고 있습니다.</span>
    </Flex>
  );
}
const useStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
