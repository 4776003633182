import { ButtonBase, Divider, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router";
import Flex from "../../components/flex/Flex";
import ProductDirectTransactionItem from "../../components/product-list/ProductDirectTranscationItem";
import ProductListItemPhysical from "../../components/product-list/ProductListItemPhysical";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import {
  actionShow,
  actionError,
  actionOpen,
} from "../../redux/action/ActionActions";
import DeliveryState from "./DeliveryState";
import ButtonMini from "../../components/button-mini/ButtonMini";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import { calcDate, formatTime } from "../../libs/utils";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import * as APIS from "../../libs/apis";
import Button from "../../components/button/Button";
import VisitReservation from "../../components/common/VisitReservation";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import styled from "styled-components";
import DeliveryInfo from "./components/DeliveryInfo";
import DeliveryHistory from "./components/DeliveryHistory";
import AssetPoint from "../../assets/images/asset-point-large.webp";
import NumberFormat from "react-number-format";
import checkIcon from "../../assets/images/ico_check.png";
import DeliveryCorpInfo from "./components/DeliveryCorpInfo";

export default function OrderDetail({ match }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const [orow, setOrow] = useState(0);
  const [sPrice, setsPrice] = useState(0);
  const [stype, setStype] = useState("SILVER");
  const [productData, setProductData] = useState({});
  const [transDeatil, setTransDetail] = useState({});
  const [stateindex, setStateIndex] = useState(0);
  const [transaction, setTransaction] = useState({ amount: 0, total: 0 });
  const [modalVisible, setModalVisible] = useState(false);

  const { isModify } = state;
  // console.log("state", state);

  const orderId = match.params.orderId;
  const orderNo = match.params.orderNo;
  const goodId = match.params.goodId;

  useEffect(() => {
    // 상품 정보 디테일
    APIS.getGoodsDetail(goodId).then(({ data: { data } }) => {
      setProductData(data);
      setStype(data.goldOrSilver);
    });
    // 매장 정보 디테일
    APIS.getGoodsTransDetail(orderId).then(({ data: { data } }) => {
      // console.log("getGoodsTransDetail", data);
      const { status } = data;
      setTransDetail(data);
      if (status === "DELIVERY") {
        setStateIndex(1);
      } else if (status === "TAKEOUT" || status === "CONFIRM") {
        setStateIndex(2);
      } else if (status === "REJECT") {
        setStateIndex(4);
      } else {
        setStateIndex(0);
      }
    });
  }, []);

  useEffect(() => {
    // 교환과정
    APIS.getGoodsTransSearch(orderNo).then(({ data: { success, data } }) => {
      if (success) {
        setOrow(data);
        setStateIndex(2);
      }
    });
  }, [stateindex]);

  useEffect(() => {
    APIS.getCurrentClosingMarketPrice(stype).then(({ data: { data } }) => {
      var splitData = data.split(",");
      setsPrice(splitData[0]);
    });
  }, [stype]);

  const init = () => {
    // 매장 정보 디테일
    APIS.getGoodsTransDetail(orderId).then(({ data: { data } }) => {
      const { status } = data;
      setTransDetail(data);
      if (status === "DELIVERY") {
        setStateIndex(1);
      } else if (status === "TAKEOUT" || status === "CONFIRM") {
        setStateIndex(2);
      } else if (status === "REJECT") {
        setStateIndex(4);
      } else {
        setStateIndex(0);
      }
    });
  };

  const isClick = useRef(false);
  const orderCancel = () => {
    if (!isClick.current) {
      isClick.current = true;
      const transId = orow.transId;
      dispatch(loadingStart);
      APIS.deleteTransCancel(transId)
        .then(({ data: { data, success, message } }) => {
          if (success) {
            dispatch(
              actionOpen(
                "실물인출 주문을 취소하셨습니다.",
                history.push(routes.mypage, { tab: "HISTORY" }),
                null,
                null,
                true
              )
            );
          } else {
            dispatch(actionError(message));
          }
        })
        .catch((error) => dispatch(actionError(error)))
        .finally(() => {
          dispatch(loadingEnd);
          isClick.current = false;
        });
    }
  };

  if (state?.index === undefined) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname,
          state: { ...state, index: 0 },
        }}
      />
    );
  }

  const isDirect = state?.type === "direct-transcation";

  const conrimSend = async () => {
    if (!isClick.current) {
      isClick.current = true;
      dispatch(loadingStart);

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        completeFetch();
        return;
      }

      APIS.putTransConfirm(orow.transId).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            pendingConfirm(1);
          } else {
            dispatch(messageError(message));
            completeFetch();
          }
        }
      );
    }
  };

  const eventData = {
    transId: orow.transId,
    couponStatus: "PAYMENT",
    eventName: "[금방금방몰-이벤트] 포인트 지급(구매확정시)",
  };

  const handlePostBuyEventPoint = (res) => {
    if (res.data.success) {
      const { amount, total } = res.data.data;
      setTransaction({ amount, total });
      setModalVisible(true);
    }
  };

  const handleShowMessage = (message) => {
    setStateIndex(-1);
    completeFetch();

    dispatch(
      messageShow(message, () => {
        APIS.postBuyEventPoint(eventData).then(handlePostBuyEventPoint);
      })
    );
  };

  const pendingConfirm = (count) => {
    setTimeout(() => {
      APIS.getTransConfirm(orow.transId).then(
        ({ data: { success, data, message } }) => {
          if (success) {
            handleShowMessage("인출확정되었습니다.");
          } else if (count === 10) {
            handleShowMessage("인출확정 처리 중 입니다.");
          } else {
            pendingConfirm(count + 1);
          }
        }
      );
    }, 1000);
  };

  const completeFetch = () => {
    dispatch(loadingEnd);
    isClick.current = false;
  };

  const canModifyReservation =
    orow.status === "APPROVAL" &&
    orow.shipmentStatus !== "SHIPMENT" &&
    orow.shipmentStatus !== "DELIVERY";

  // APPROVAL, // 결제완료
  // MAKE, // 제작중
  // SHIPMENT, // 출고중
  // DELIVERY, // 출고완료
  // TAKEOUT, // 인출완료
  // CONFIRM, // 인출확정
  // CANCEL, // 결제취소

  let description = "";

  switch (orow.status) {
    case "TAKEOUT":
    case "CONFIRM":
      description = "제품 수령이 완료되었습니다.";
      break;

    case "CANCEL":
      description = "취소되었습니다.";
      break;

    case "APPROVAL":
      switch (orow.shipmentStatus) {
        case "MAKE":
          description = "주문하신 제품을 제작 중입니다.";
          break;
        case "SHIPMENT":
          description = "제품이 출고되었습니다.";
          break;
        case "DELIVERY":
          description = "제품이 매장에 도착했습니다.";
          break;
      }
    default:
      description = "결제가 완료되었습니다.";
  }

  const deliveryInfo = transDeatil?.deliveryResponse || {};
  const deliveryHistory = transDeatil?.deliveryTrace || {};
  return (
    <BasicLayout
      white
      stackTopbar={{
        option: state.index < 2 && (
          <ButtonBase
            onClick={() => {
              history.push({
                pathname: history.location.pathname,
                state: { ...state, index: state.index + 1 },
              });
            }}
          >
            {/*테스트 다음*/}
          </ButtonBase>
        ),
        title: "주문 상세",
        border: true,
      }}
    >
      <ContentWrap style={{}}>
        <Flex className={`${classes.title} row-between`}>
          <Flex
            style={{
              alignItems: "center",
            }}
          >
            <img src={checkIcon} alt="" className={classes.checkIcon} />
            <Text font={fonts.notoSansKRMedium} className={classes.titleText}>
              {description}
            </Text>
          </Flex>
        </Flex>
        <Flex className={classes.orderNo}>
          <Text font={fonts.notoSansKRMedium} className={classes.label3}>
            주문번호 <div style={{ display: "inline-block", width: "18px" }} />{" "}
            {orow.no}
          </Text>
        </Flex>
        {state?.type === "direct-transcation" ? (
          <>
            <div style={{ height: 10 }} />
            <ProductDirectTransactionItem bottom />
            <div style={{ height: 10 }} />
          </>
        ) : (
          <ProductListItemPhysical
            inOrder
            bottom
            orow={orow}
            sPrice={sPrice}
            productData={productData}
          />
        )}
        <Flex
          style={{
            margin: "0 16px",
          }}
        >
          <DeliveryState
            status={orow.delivery === "매장방문" ? "VISIT" : "DELIVERY"}
            shipmentStatus={orow.shipmentStatus}
            updatedAt={orow.updatedAt}
            transSummary={transDeatil?.goodsTransHistory || []}
          />
        </Flex>
        <Flex style={{ margin: "0 16px 16px" }}>
          <DeliveryCorpInfo
            isVisible={deliveryInfo?.regiNo && deliveryInfo?.deliveryCorp}
            deliveryInfo={deliveryInfo}
          />
        </Flex>
        {/*<Flex className={classes.content}>
        <Text
          style={{
            paddingBottom: 8,
            marginBottom: 8,
            borderBottom: "1px solid #EAEAEA",
          }}>
          주문 상품 제작 과정 안내
        </Text>

        <Flex row style={{}}>
          <Flex className={classes.deliveryDesc}>
            <Text className={classes.deliveryLabel1}>
              {formatTime(orow.updatedAt, "YYYY-MM-DD H:m")}
            </Text>
            {state.index === 1 && (
              <Text className={classes.deliveryLabel2}>배송 준비중</Text>
            )}
            {state.index === 2 && (
              <Text className={classes.deliveryLabel1}>
                {formatTime(transDeatil.shipmentdate, "YYYY-MM-DD H:m")}
              </Text>
            )}
            <div />
          </Flex> 
        </Flex>
      </Flex>*/}
        <Divider style={{ height: 8 }} />
        {orow.delivery === "택배" ? (
          <Flex className={classes.content}>
            {!!deliveryHistory?.regiNo && (
              <DeliveryHistory {...deliveryHistory} />
            )}
            <DeliveryInfo {...deliveryInfo} style={{ marginTop: 16 }} />
            {state.index === 0 &&
              orow.status !== "CANCEL" &&
              orow.status === "APPROVAL" &&
              orow.shipmentStatus !== "MAKE" &&
              orow.shipmentStatus !== "SHIPMENT" &&
              orow.shipmentStatus !== "DELIVERY" && (
                <ButtonBase
                  onClick={() => {
                    dispatch(
                      actionShow(
                        "실물인출 주문을 취소하시겠습니까?",
                        ["닫기", "결제 취소"],
                        [null, () => orderCancel()]
                      )
                    );
                  }}
                  className={classes.textButton}
                >
                  결제취소
                </ButtonBase>
              )}
          </Flex>
        ) : (
          <>
            {isModify ? (
              <ModifyReservation
                onClickCancel={() => {
                  history.replace(location.pathname, {
                    ...state,
                    isModify: false,
                    shop: {},
                    reservation: {},
                  });
                }}
                minDate={
                  transDeatil.earliestVisitDate
                    ? new Date(transDeatil.earliestVisitDate)
                    : calcDate("D", 1)
                }
                transId={orderId}
                goodsId={goodId}
                callback={init}
              />
            ) : (
              <>
                <div style={{ padding: "10px" }}>
                  <Flex
                    row
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      font={fonts.notoSansKRMedium}
                      className={classes.label1}
                    >
                      대리점 수령 예약 정보
                    </Text>
                    {canModifyReservation && (
                      <ButtonMini
                        label="수정"
                        style={{ padding: 3, width: 70 }}
                        textStyle={{ fontSize: 14 }}
                        onClick={() => {
                          history.replace(location.pathname, {
                            ...state,
                            isModify: true,
                          });
                        }}
                      />
                    )}
                  </Flex>
                  <ShopItemSimple
                    className={classes.shopItem}
                    name={transDeatil.shopName}
                    // distance={"none"}
                    address={transDeatil.shopAddress}
                    addressDetail={""}
                    phoneNumber={transDeatil.shopPhoneNumber}
                  />

                  <Flex row className={classes.item}>
                    <Text className={classes.label}>방문일자</Text>
                    <Text className={classes.value}>
                      {transDeatil.visitDate}
                    </Text>
                  </Flex>
                  {/* <Flex row className={classes.item}>
                  <Text className={classes.label}>영업시간</Text>
                  <Text className={classes.value}>
                    {transDeatil.businessTimes}
                  </Text>
                </Flex> */}
                  {/* <div style={{ marginTop: 10, color: colors.primary }}>{I_001}</div> */}
                </div>
                {state.index === 0 &&
                  orow.status !== "CANCEL" &&
                  orow.status === "APPROVAL" &&
                  orow.shipmentStatus !== "MAKE" &&
                  orow.shipmentStatus !== "SHIPMENT" &&
                  orow.shipmentStatus !== "DELIVERY" && (
                    <ButtonBase
                      onClick={() => {
                        dispatch(
                          actionShow(
                            "실물인출 주문을 취소하시겠습니까?",
                            ["닫기", "결제 취소"],
                            [null, () => orderCancel()]
                          )
                        );
                      }}
                      className={classes.textButton}
                    >
                      결제취소
                    </ButtonBase>
                  )}
              </>
            )}
          </>
        )}

        {orow.status === "TAKEOUT" &&
          (isDirect ? (
            <>
              <Divider style={{ height: 3 }} />
              <Flex className={classes.content}>
                <Text font={fonts.notoSansKRMedium} className={classes.label1}>
                  인출을 확정해 주세요.
                </Text>
                <Text className={classes.message}>
                  인출완료 후 7일이 지나면 자동 구매 확정 됩니다.
                </Text>

                <ButtonBase
                  onClick={() => {
                    if (state.review === "complete") {
                      history.push(routes.reviewDetail(1), {
                        me: true,
                      });
                    } else if (state.order) {
                      history.push(routes.orderCompleteWrite, {
                        type: "review",
                        state: {
                          ...state,
                          review: "complete",
                        },
                      });
                    } else {
                      history.push(routes.orderCompleteWrite, {
                        type: "complete",
                        state: {
                          ...state,
                          order: "complete",
                        },
                      });
                    }
                  }}
                  className={classes.mainButton}
                >
                  {state.review === "complete"
                    ? "작성후기 보기"
                    : state.order === "complete"
                    ? "리뷰쓰기"
                    : "구매 확정"}
                </ButtonBase>
                {state.order !== "complete" && (
                  <Text font={fonts.notoSansKRMedium} className={classes.hint}>
                    <Text>상품 반품 관련 문의는 아래로 연락해 주세요.</Text>
                    <br />
                    강남본점(청담) | 1544-9993
                  </Text>
                )}
              </Flex>
            </>
          ) : (
            <>
              <Divider style={{ height: 3 }} />
              <Flex className={classes.content}>
                <Text font={fonts.notoSansKRMedium} className={classes.label1}>
                  인출을 확정해 주세요.
                </Text>
                <Text className={classes.message}>
                  인출완료 후 7일이 지나면 자동 구매 확정 됩니다.
                </Text>

                <ButtonBase
                  className={classes.mainButton}
                  onClick={() => conrimSend()}
                >
                  인출 확정
                </ButtonBase>

                <Text font={fonts.notoSansKRMedium} className={classes.hint}>
                  교환 및 반품안내
                  <Text>
                    <br />
                    모든 제품은 고객님의 주문에 의해 제작되므로 제작 중에는
                    취소, 교환이 되지 않으니 유의하셔서 주문하시기 바랍니다
                  </Text>
                </Text>
              </Flex>
            </>
          ))}
        {modalVisible && (
          <Container>
            <ModalBackdrop onClick={() => setModalVisible(false)} />
            <ModalBody>
              <PurchaseConfirm
                transaction={transaction}
                setModalVisible={setModalVisible}
              />
            </ModalBody>
          </Container>
        )}
      </ContentWrap>
    </BasicLayout>
  );
}

const PurchaseConfirm = ({ transaction, setModalVisible }) => {
  return (
    <>
      <PointIcon src={AssetPoint} alt="포인트 아이콘" />
      <Title>구매확정 완료</Title>
      <PointTitle>
        <div className="subtitle">포인트 지급</div>
        <div className="amount">
          +
          <NumberFormat
            value={transaction.amount}
            displayType={"text"}
            thousandSeparator={true}
          />
          <span className="currency">pt</span>
        </div>
      </PointTitle>
      <HoldPoint>
        <PointWrap>
          <img src={AssetPoint} alt="포인트 아이콘" />
          <div className="subtitle">사용 가능 포인트</div>
        </PointWrap>
        <div className="point">
          <NumberFormat
            value={transaction.total}
            displayType={"text"}
            thousandSeparator={true}
          />
          <span className="currency">pt</span>
        </div>
      </HoldPoint>
      <OkBtn onClick={() => setModalVisible(false)}>확인</OkBtn>
    </>
  );
};

const ModifyReservation = ({
  onClickCancel,
  transId,
  minDate,
  goodsId,
  callback,
}) => {
  const classes = useStyle2();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state || {};

  const handleClickModify = () => {
    if (!locationState?.shop?.id) {
      dispatch(messageError("방문 매장을 선택해주세요."));
      return;
    } else if (!locationState.reservation?.date) {
      dispatch(messageError("방문 일자를 선택해주세요."));
      return;
    }
    dispatch(loadingStart);
    APIS.putModifyGoodsTrans(transId, {
      shopId: locationState.shop.id,
      visitDate: formatTime(locationState.reservation.date),
    })
      .then(({ data: { success, message } }) => {
        if (success) {
          history.replace(location.pathname, {
            ...locationState,
            isModify: false,
          });
          callback();
        } else {
          dispatch(messageError(message));
        }
      })
      .finally(() => dispatch(loadingEnd));
  };

  return (
    <Flex className={classes.container}>
      <ButtonMini
        onClick={onClickCancel}
        label="취소"
        className={classes.miniButton}
      />
      <VisitReservation
        description={`제품 수령을 위해\n방문하실 매장을 선택해 주세요.`}
        minDate={minDate}
        shopType="withdraw"
        goodsId={goodsId}
      />
      <Flex style={{ height: 100 }} />
      <Button
        onClick={handleClickModify}
        label="수정하기"
        className="fixed-button"
      />
    </Flex>
  );
};

const useStyle = makeStyles({
  hint: {
    textAlign: "center",
    margin: "20px 50px 30px 50px",
    fontSize: "14px",
    alignSelf: "center",
    color: "#333",
    "& span": {
      color: "#666",
      fontSize: "12px",
    },
  },
  shopItem: {
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #ddd",
    backgroundColor: "#eee",
    marginTop: "10px",
  },
  message: {
    color: "#666",
    fontSize: "14px",
    marginTop: "3px",
  },
  title: {
    paddingTop: "28px",
    paddingBottom: "20px",
  },
  titleText: {
    fontSize: "21px",
    fontWeight: "bold",
    color: "#222",
    marginTop: 8,
  },
  orderNo: {
    padding: 8,
    borderRadius: 4,
    backgroundColor: "#FAFAFA",
    margin: "0 16px",
  },
  checkIcon: {
    width: "40px",
    height: "40px",
  },
  label1: { color: "#333", fontSize: "16px" },
  content: {
    padding: "15px 13px",
    // margin: "0 16px 23px",
    // padding: "10px 16px",
    // border: "1px solid #EAEAEA",
    // borderRadius: 4,
  },
  label2: {
    color: "#333",
    fontSize: "12px",
  },
  label3: {
    color: "#999",
    fontSize: "12px",
  },
  deliveryDesc: {
    justifyContent: "space-between",
    flex: 1,
    alignItems: "flex-end",
  },
  deliveryLabel1: {
    fontSize: "12px",
    color: "#666",
  },
  deliveryLabel2: {
    fontSize: "12px",
    color: "#333",
  },
  textButton: {
    alignSelf: "center",
    marginTop: "24px",
    height: "48px",
    padding: "0 24px !important",
    backgroundColor: "#FFE5E5",
    borderRadius: 8,
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: fonts.notoSansKRMedium,
  },
  simpleTable: {
    alignSelf: "stretch",
    margin: "10px 20px 0px 20px",
    "& span": {
      "&:first-child": {
        width: 70,

        color: "#666",
      },
      fontSize: "14px",
      color: "#333",
    },
  },
  mainButton: {
    alignSelf: "center",
    borderRadius: "3px",
    backgroundColor: "#333",
    fontSize: 16,
    fontFamily: fonts.notoSansKRBold,
    color: "#fff",
    padding: "10px",
    minWidth: "50%",
    marginTop: "30px",
    backgroundColor: colors.primary,
  },
  item: {
    marginTop: "10px",
  },
  label: {
    minWidth: 80,
    marginRight: "15px",
    fontWeight: 600,
  },
});

const useStyle2 = makeStyles({
  container: {
    position: "relative",
    margin: "-22px 10px 100px",
  },
  label1: {
    color: colors.text,
    fontSize: 18,
    justifyContent: "space-between",
  },
  miniButton: {
    position: "absolute",
    top: 32,
    right: 0,
    padding: 3,
    width: 70,
    fontSize: 14,
  },
  bt: { alignSelf: "flex-start", marginTop: "10px" },
});

const ContentWrap = styled(Flex)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DeliveryLabel = styled(Flex)`
  margin: 8px 0;
  flex-direction: row;
  background: #f9f9f9;
  border-radius: 8px;
  align-items: flex-start;
  padding: 8px 4px;
`;
// 구매확정 모달
const PointIcon = styled.img`
  width: 55px;
  height: 55px;
  margin-top: 13px;
`;

const Title = styled.h1`
  margin-top: 14px;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: #333333;
`;

const PointTitle = styled.div`
  color: #ff6e0e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    margin-bottom: -2px;
  }

  .amount {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 27px;
    line-height: 28px;
    letter-spacing: -0.54px;
  }
  .currency {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
`;
const HoldPoint = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }

  .subtitle {
    line-height: 22px;
    letter-spacing: 0.11px;
    color: #555555;
  }

  .point {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    color: #ff6e0e;
  }

  .currency {
    font-family: ${fonts.notoSansKRRegular};
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.09px;
  }
`;

const PointWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OkBtn = styled.button`
  background-color: #ff6e0e;
  border-radius: 8px;
  border: none;
  width: 100%;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24.5px;
  letter-spacing: -0.32px;
  color: white;
  margin-top: 32px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ModalBody = styled.div`
  background-color: white;
  width: 90%;
  height: auto;
  position: fixed;
  top: 47.5%;
  left: 50%;
  z-index: 1020;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: 900px) {
    align-self: center;
    min-width: auto;
    width: 432px;
  }
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  cursor: pointer;

  inset: 0;
  background-color: black;
  opacity: 0.54;
  z-index: 1010;
`;
