import React from "react";

function ProductGrade({ id, style, ...props }: HTMLDivElement) {
  return (
    <div {...props}>
      <div vreview-product-id={id}></div>
    </div>
  );
}

export default ProductGrade;
