import { InputBase, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import { numberWithCommas } from "../../libs/utils";

export default function InputSmallBorder({
  label,
  placeholder,
  className,
  value,
  onChange,
  readonly,
  labeloption,
  isComma,
}) {
  const classes = useStyle();

  return (
    <Flex row className={classes.root + " " + className}>
      {label && (
        <Text font={fonts.notoSansKRMedium} className={classes.label}>
          {label}
        </Text>
      )}

      <InputBase
        onChange={(e) => {
          if (onChange) {
            let values = e.target.value;
            if (isComma) {
              values = values.replace(/,/gi, "");
              const regexp = /^[0-9]*$/;
              if (!regexp.test(values)) {
                return;
              } else {
                values = values.replace(/,/gi, "");
              }
            }
            onChange(values);
          }
        }}
        // inputProps={{ inputMode: number ? "decimal" : "text" }}
        // type={number2 ? "number" : "text"}
        inputProps={{ inputMode: "decimal" }}
        type="text"
        className={classes.input}
        placeholder={placeholder}
        value={isComma ? numberWithCommas(value) : value}
        readOnly={readonly}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            document.activeElement.blur();
          }
        }}
      />
      {labeloption && (
        <Text font={fonts.notoSansKRMedium} className={classes.labeloption}>
          {labeloption}
        </Text>
      )}
    </Flex>
  );
}

const useStyle = makeStyles({
  label: {
    fontSize: "13px",
    padding: "10px",
    color: colors.text,
  },
  labeloption: {
    fontSize: "13px",
    padding: "10px",
    color: colors.text,
    backgroundColor: "#eee",
  },
  root: {
    alignSelf: "stretch",
    border: "0.7px solid #dddddd",
    borderRadius: "5px",
  },
  input: {
    fontFamily: fonts.notoSansKRRegular,
    flex: 1,
    fontSize: "14px",
    alignSelf: "stretch",
    textAlign: "right",
  },
});
