import { makeStyles } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import Text from "../../components/text/Text";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
export default function Cancel({ appraisalRequestStatus }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  let t =
    appraisalRequestStatus.filter((x) => x.status === "접수반려").length > 0
      ? "1"
      : appraisalRequestStatus.filter((x) => x.status === "감정반려").length > 0
      ? "2"
      : appraisalRequestStatus.filter((x) => x.status === "반송반려").length > 0
      ? "3"
      : "";

  return (
    <Flex className={classes.card}>
      <div className={classes.titleRow}>
        <img src={images.protect2} className={classes.protext} />
        <Text font={fonts.notoSansKRMedium} className={classes.title}>
          {t === "3" ? "반송불가" : "접수불가"}
        </Text>
      </div>

      <Text className={classes.message} font={fonts.notoSansKRMedium}>
        {t === "1"
          ? appraisalRequestStatus.filter((x) => x.status === "접수반려")[0]
              .requestCancelReason
          : t === "2"
          ? appraisalRequestStatus.filter((x) => x.status === "감정반려")[0]
              .appraiseCancelReason
          : t === "3"
          ? appraisalRequestStatus.filter((x) => x.status === "반송반려")[0]
              .returnCancelReason
          : ""}
      </Text>
      {/*
        
      <Flex row className={classes.buttons2}>
        <Button label="확인" onClick={history.goBack} />
      </Flex>
        */}
    </Flex>
  );
}

const useStyle = makeStyles({
  card: {
    marginBottom: "20px",
    padding: "20px 30px",
    paddingTop: 0,
    borderBottom: "6px solid #eeeeee",
  },
  protext: {
    width: 16,
    height: 16,
    marginRight: 8,
    objectFit: "contain",
  },
  title: {
    fontSize: 16,
    color: "#c02525",
    lineHeight: 1,
    "& span": { fontSize: 16 },
  },
  titleRow: {
    marginTop: 18,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    textAlign: "center",
  },
  message: {
    alignSelf: "center",
    color: "#999999",
    fontFamily: fonts.notoSansKRMedium,
    lineHeight: "26px",
    marginTop: 10,
    fontSize: 14,
  },
  buttons: {
    alignSelf: "stretch",
    "& button": {
      padding: "10px",
      flex: 1,
      "&:first-child": {
        marginRight: 5,
        backgroundColor: "#333",
      },
      "&:last-child": {
        marginLeft: "5px",
      },
    },
  },
  label: {
    fontSize: 16,
    color: "#333",
  },
  button: {
    color: colors.primary,
    border: "2px solid " + colors.primary,
    padding: "10px 20px",
    lineHeight: 1,
    fontSize: 14,
    fontFamily: fonts.notoSansKRMedium,
    borderRadius: 20,
  },
  buttons2: {
    alignSelf: "stretch",
    "& button": {
      padding: "10px",
      backgroundColor: colors.primary,
      flex: 1,
    },
  },
});
