import React, { useEffect, useState } from "react";
import SimpleAccordion from "../../../../components/accordion/SimpleAccordion";
import Flex from "../../../../components/flex/Flex";
import * as APIS from "../../../../libs/apis";
import historyTrade from "../../../../assets/images/history_trade.webp";
import historyWithdraw from "../../../../assets/images/history_withdraw.webp";
import historyAppraisalGold from "../../../../assets/images/history_appraisal_gold.webp";
import historyAppraisalSilver from "../../../../assets/images/history_appraisal_silver.webp";
import historyAppraisalGoods from "../../../../assets/images/history_goods.webp";
import history_appraisal from "../../../../assets/images/history_appraisal.webp";
import arrowDown from "../../../../assets/images/arrow_down.webp";
import styled from "styled-components";
import fonts from "../../../../libs/fonts";
import TradeItem from "./TradeItem";
import WithdrawItem from "./WithdrawItem";
import GoodsItem from "./GoodsItem";
import { useHistory } from "react-router";
import SimpleIng from "./SimpleIng";
import AppraisalItem from "./AppraisalItem";
import BottomModal from "../../../../components/modal/BottomModal";
import Span from "../../../../components/text/Span";
import Button from "../../../../components/button/Button";
import colors from "../../../../libs/colors";
import { numberWithCommas } from "../../../../libs/utils";
import { useDispatch } from "react-redux";
import { actionError } from "../../../../redux/action/ActionActions";
import { toastShow } from "../../../../redux/toast/ToastActions";
import {
  loadingEnd,
  loadingStart,
} from "../../../../redux/loading/LoadingActions";
import { messageError } from "../../../../redux/message/MessageActions";

const HistoryIng = () => {
  const history = useHistory();
  const locationState = history.location.state || {};
  const { expend = [] } = locationState;
  const dispatch = useDispatch();

  const [trade, setTrade] = useState([]);
  const [withdraw, setWithdraw] = useState([]);
  const [appraisalGold, setAppraisalGold] = useState([]);
  const [appraisalSilver, setAppraisalSilver] = useState([]);
  const [goods, setGoods] = useState([]);
  // 감정평가
  const [appraisal, setAppraisal] = useState([]);
  // 간편감정 금
  const [simpleGold, setSimpleGold] = useState([]);
  // 간편감정 은
  const [simpleSilver, setSimpleSilver] = useState([]);

  const [tradeCancelModal, setTradeCancelModal] = useState({ visible: false });

  useEffect(() => {
    fetchList();
  }, []);

  const fetchList = () => {
    dispatch(loadingStart);
    APIS.getTradeHistoryIngList()
      .then(({ data: { success, data = [], message } }) => {
        if (success) {
          setTrade(
            data.filter(
              ({ tradeType }) => tradeType === "BUY" || tradeType === "SELL"
            )
          );

          setWithdraw(data.filter(({ tradeType }) => tradeType === "WITHDRAW"));

          setAppraisalGold(
            data.filter(({ tradeType }) => tradeType === "APPRAISAL")
          );
          setAppraisalSilver(
            data.filter(({ tradeType }) => tradeType === "APPRAISAL")
          );

          setGoods(data.filter(({ tradeType }) => tradeType === "GOODS"));

          setSimpleGold(
            data.filter(
              ({ tradeType, status, requestAssetType }) =>
                tradeType === "APPRAISAL" &&
                status === "간편감정" &&
                requestAssetType === "GOLD"
            )
          );

          setSimpleSilver(
            data.filter(
              ({ tradeType, status, requestAssetType }) =>
                tradeType === "APPRAISAL" &&
                status === "간편감정" &&
                requestAssetType === "SILVER"
            )
          );

          setAppraisal(
            data.filter(
              ({ tradeType, status }) =>
                tradeType === "APPRAISAL" && status !== "간편감정"
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleChangeExpend = (expended, type) => {
    if (expended) {
      handleChangeLocationState({ expend: [...expend, type] });
    } else {
      handleChangeLocationState({
        expend: expend.filter((tradeType) => tradeType !== type),
      });
    }
  };

  const handleClickCancelBuySell = (item) => {
    dispatch(loadingStart);
    APIS.getServiceBreak().then((serviceBreak) => {
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        dispatch(loadingEnd);
      } else {
        dispatch(loadingEnd);
        setTradeCancelModal({
          visible: true,
          item: {
            id: item.requestId,
            price: item.requestPrice,
            gram: item.requestGram,
            remainGram: item.requestGram - item.contractGram,
            assetType: item.assetType,
            tradeType: item.tradeType,
          },
        });
      }
    });
  };

  return (
    <>
      <Container>
        <HistoryIngAccordion
          icon={historyTrade}
          title={"매수 ・ 매도"}
          count={trade.length}
          onChange={(expended) => handleChangeExpend(expended, "BUY_SELL")}
          expended={expend.includes("BUY_SELL")}
        >
          <AccordionContent>
            {trade.map((item, i) => (
              <TradeItem
                {...item}
                key={i.toString()}
                onCancel={() => handleClickCancelBuySell(item)}
              />
            ))}
          </AccordionContent>
        </HistoryIngAccordion>

        <HistoryIngAccordion
          icon={historyWithdraw}
          title={"출금"}
          count={withdraw.length}
          onChange={(expended) => handleChangeExpend(expended, "WITHDRAW")}
          expended={expend.includes("WITHDRAW")}
        >
          <AccordionContent>
            {withdraw.map((item, i) => (
              <WithdrawItem {...item} key={i.toString()} />
            ))}
          </AccordionContent>
        </HistoryIngAccordion>
        <HistoryIngAccordion
          icon={historyAppraisalGold}
          title={"간편감정 | 금"}
          count={simpleGold.length}
          onChange={(expended) => handleChangeExpend(expended, "SIMPLE_GOLD")}
          expended={expend.includes("SIMPLE_GOLD")}
        >
          <AccordionContent>
            <SimpleIng
              list={simpleGold}
              assetType="GOLD"
              fetchList={fetchList}
            />
          </AccordionContent>
        </HistoryIngAccordion>
        <HistoryIngAccordion
          icon={historyAppraisalSilver}
          title={"간편감정 | 은"}
          count={simpleSilver.length}
          onChange={(expended) => handleChangeExpend(expended, "SIMPLE_SILVER")}
          expended={expend.includes("SIMPLE_SILVER")}
        >
          <AccordionContent>
            <SimpleIng
              list={simpleSilver}
              assetType="SILVER"
              fetchList={fetchList}
            />
          </AccordionContent>
        </HistoryIngAccordion>
        <HistoryIngAccordion
          icon={history_appraisal}
          title={"감정평가"}
          count={appraisal.length}
          onChange={(expended) => handleChangeExpend(expended, "APPRAISAL")}
          expended={expend.includes("APPRAISAL")}
        >
          <AccordionContent>
            {appraisal.map((item, i) => (
              <AppraisalItem {...item} key={i.toString()} />
            ))}
          </AccordionContent>
        </HistoryIngAccordion>
        <HistoryIngAccordion
          icon={historyAppraisalGoods}
          title={"실물인출"}
          count={goods.length}
          onChange={(expended) => handleChangeExpend(expended, "GOODS")}
          expended={expend.includes("GOODS")}
        >
          <AccordionContent>
            {goods.map((item, i) => (
              <GoodsItem {...item} key={i.toString()} />
            ))}
          </AccordionContent>
        </HistoryIngAccordion>
      </Container>
      <TradeCancelModal
        {...tradeCancelModal}
        onClose={() => {
          setTradeCancelModal({ ...tradeCancelModal, visible: false });
        }}
        fetchList={fetchList}
      />
    </>
  );
};

const HistoryIngAccordion = ({ icon, title, count, expended, ...rest }) => {
  return (
    <AccordionContainer
      title={
        <AccordionHeader>
          <img src={icon} width={18} height={18} className="history-icon" />
          {title}
          <span className="history-badge">{count}</span>
        </AccordionHeader>
      }
      expended={expended}
      expendIcon={<img src={arrowDown} width={44} />}
      {...rest}
    />
  );
};

const TradeCancelModal = ({ visible, onClose, item = {}, fetchList }) => {
  const dispatch = useDispatch();
  const { id, tradeType, assetType, price, gram, remainGram } = item;

  const handleClickCancel = async () => {
    if (id) {
      dispatch(loadingStart);

      const serviceBreak = await APIS.getServiceBreak();
      if (serviceBreak) {
        dispatch(messageError(serviceBreak));
        dispatch(loadingEnd);
        return;
      }

      APIS.deleteBuySell(id)
        .then(({ data: { success, message } }) => {
          if (success) {
            onClose();
            dispatch(toastShow("주문이 취소 되었습니다."));
            fetchList();
          } else {
            dispatch(actionError(message));
          }
        })
        .finally(() => {
          dispatch(loadingEnd);
        });
    }
  };
  let typeText = "";
  if (tradeType === "BUY") {
    typeText = "매수";
  } else if (tradeType === "SELL") {
    typeText = "매도";
  }

  let assetText = "";
  if (assetType === "GOLD") {
    assetText = "금(GOLD)";
  } else if (assetType === "SILVER") {
    assetText = "은(SILVER)";
  }

  return (
    <BottomModal visible={visible} onClickBackground={onClose}>
      <CancelModalContainer>
        <Span
          size={20}
          font={fonts.notoSansKRBold}
          style={{ marginBottom: 20 }}
        >
          {typeText}주문취소
        </Span>
        <CancelModalRow>
          <RowTitle>주문상품</RowTitle>
          <RowDesc>{assetText}</RowDesc>
        </CancelModalRow>
        <CancelModalRow>
          <RowTitle>주문방식</RowTitle>
          <RowDesc>지정가</RowDesc>
        </CancelModalRow>
        <CancelModalRow>
          <RowTitle>{typeText}가격</RowTitle>
          <RowDesc>{numberWithCommas(price)}원</RowDesc>
        </CancelModalRow>
        <CancelModalRow>
          <RowTitle>주문금액</RowTitle>
          <RowDesc>{numberWithCommas(price * gram)}원</RowDesc>
        </CancelModalRow>
        <CancelModalRow>
          <RowTitle>주문중량</RowTitle>
          <RowDesc>{numberWithCommas(gram)}g</RowDesc>
        </CancelModalRow>
        <CancelModalRow>
          <RowTitle>미체결중량</RowTitle>
          <RowDesc>{numberWithCommas(remainGram)}g</RowDesc>
        </CancelModalRow>
        <Span font={fonts.notoSansKRLight} size={13} style={{ marginTop: 16 }}>
          미체결된 중량만 취소됩니다.
        </Span>
        <CancelModalButtonWrap>
          <CancelModalButton
            background="#999"
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            닫기
          </CancelModalButton>
          <CancelModalButton
            background={tradeType === "BUY" ? colors.textRed : colors.textBlue}
            onClick={handleClickCancel}
          >
            주문취소
          </CancelModalButton>
        </CancelModalButtonWrap>
      </CancelModalContainer>
    </BottomModal>
  );
};

const Container = styled(Flex)`
  padding: 0px 17.5px 16px 17.5px;
`;

const AccordionContainer = styled(SimpleAccordion)`
  border-bottom: 1px solid
    ${({ expended }) => (expended ? "#222222" : "#eaeaea")};

  &:last-of-type {
    border-bottom: none;
  }
`;

const AccordionHeader = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  font-family: ${fonts.spoqa};
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #222222;
  padding: 20px 0;
  position: relative;
  height: 56px;

  img.history-icon {
    margin-right: 8px;
  }

  span.history-badge {
    display: flex;
    padding: 0 5px;
    background: #ff6e0e;
    min-width: 16px;
    height: 16px;
    border-radius: 8px;
    font-size: 12px;
    letter-spacing: -0.24px;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: ${fonts.spoqa};
    font-weight: bold;
    margin-left: 3px;
  }
`;

const AccordionContent = styled(Flex)`
  padding-bottom: 32px;
`;

const CancelModalContainer = styled(Flex)`
  padding: 30px 20px;
  align-items: center;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const CancelModalRow = styled(Flex)`
  width: 50%;
  margin-top: 6px;
  align-items: center;
  flex-direction: row;
`;

const RowTitle = styled(Span)`
  flex: 1;
  color: #999999;
  font-size: 14px;
`;
const RowDesc = styled(Span)`
  flex: 1;
  font-size: 14px;
  font-family: ${fonts.notoSansKRMedium};
  text-align: right;
`;

const CancelModalButtonWrap = styled(Flex)`
  flex-direction: row;
  align-self: stretch;
  margin-top: 30px;
  align-items: center;
`;
const CancelModalButton = styled(Flex)`
  flex: 1;
  border-radius: 5px;
  padding: 10px 0;
  text-align: center;
  font-family: ${fonts.notoSansKRBold};
  font-size: 16px;
  color: white;
  background: ${({ background }) => background};
`;

export default HistoryIng;
