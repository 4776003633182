import { ButtonBase, makeStyles, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Checkbox from "../../components/checkbox/Checkbox";
import Flex from "../../components/flex/Flex";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import { messageError } from "../../redux/message/MessageActions";
import { axiosDispatch } from "../../services/network";

export default function DirectTransactionAdd4({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state || {};
  const inputRef = useRef();

  const guarantee = state.guarantee;
  const setGuarantee = (e) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, guarantee: e },
    });

  const guaranteeImage = state.guaranteeImage;
  const setGuaranteeImage = (e) =>
    history.replace({
      pathname: history.location.pathname,
      state: { ...state, guaranteeImage: e, guarantee: e ? true : false },
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fs = new FileReader();
      fs.onloadend = () => {
        let data = {
          file,
          uri: fs.result,
        };
        setGuaranteeImage(data);
      };
      fs.readAsDataURL(file);
    }
    e.target.value = "";
  };

  const add = () => {
    const form = new FormData();
    form.append("id", 37);
    form.append("userId", 37);
    form.append("storeId", 37);
    form.append("category1", state.category.parentId);
    form.append("category2", state.category.id);
    form.append("purchaseYear", state.year);
    form.append("price", state.price);
    form.append("brandName", state.brandName);
    form.append("productName", state.productName);
    form.append("productType", state.productType);
    form.append("faceImage", state.faceImage.file);
    form.append("leftSideImage", state.leftSideImage.file);
    form.append("modelNameImage", state.modelNameImage.file);
    form.append("rightSideImage", state.rightSideImage.file);
    if (state.guaranteeImage) {
      form.append("guaranteeImage", state.guaranteeImage.file);
    } else {
      form.append("guaranteeImage", state.rightSideImage.file);
    }

    dispatch(
      axiosDispatch(
        {
          url: consts.apiBaseUrl + "/api/safeDirectTransaction",
          data: form,
        },
        (err, res) => {
          if (err) {
            dispatch(messageError(err));
          } else {
            // console.log(res.data);
          }
        }
      )
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.topbar}>
        <ButtonBase
          onClick={() => {
            history.goBack();
          }}
          className={classes.back}
        >
          <img src={images.back} />
        </ButtonBase>
        <span className={classes.navi}>4/4</span>
        <ButtonBase
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={images.home} className={classes.home} />
        </ButtonBase>
      </div>
      <div className={classes.navbar}>
        <div />
      </div>
      <span className={classes.title}>
        보증서를
        <br />
        등록해 주세요.
      </span>
      <span className={classes.sub}>마지막 단계입니다.</span>

      <Checkbox
        className={classes.cb}
        disabled
        checked
        label="보증서가 있으신가요?"
      />

      <Flex row className={classes.buttons}>
        <span
          onClick={() => {
            inputRef.current?.click();
          }}
          className={(guarantee && classes.bl) + " shadow"}
        >
          있음
        </span>
        <span
          onClick={() => {
            setGuaranteeImage(null);
          }}
          className={(!guarantee && classes.bl) + " shadow"}
        >
          없음
        </span>
        <div style={{ flex: 1 }}></div>
      </Flex>

      <div
        style={{
          marginTop: 30,
          alignSelf: "stretch",
          position: "relative",
          margin: "30px",
        }}
      >
        <Paper>
          <img
            src={guaranteeImage ? guaranteeImage.uri : images.appraise4}
            style={{
              width: "100%",
              height: "150px",
              objectFit: "cover",
            }}
          />
        </Paper>
        {!guaranteeImage && (
          <span
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              borderRadius: 3,
              padding: "6px 10px",
              backgroundColor: "rgba(0,0,0,0.5)",
              color: "#fff",
              fontSize: 11,
              lineHeight: "13px",
              fontFamily: fonts.notoSansKRMedium,
            }}
          >
            사진예시
          </span>
        )}
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        ref={inputRef}
        style={{ display: "none" }}
      />

      <div style={{ height: 100 }} />
      <ButtonBase
        onClick={() => {
          history.replace({
            pathname: history.location.pathname,
            state: {
              ...state,
              guaranteeImage,
            },
          });
          add();
        }}
        className={classes.button}
      >
        다음
      </ButtonBase>
    </div>
  );
}

const useStyle = makeStyles({
  buttons: {
    alignSelf: "stretch",
    margin: "0px 20px",
    "& span": {
      flex: 1,
      display: "flex",
      lineHeight: 1,
      cursor: "pointer",
      fontSize: "14px",
      display: "flex",
      justifyContent: "center",
      color: colors.text,
      marginRight: "10px",
      alignItems: "center",
      textAlign: "center",
      "&:last-child": {
        marginRight: "0px",
      },
      borderRadius: "20px",
      padding: "10px 20px",
    },
  },
  cb: {
    margin: "0px 30px 10px 30px",
  },
  bl: {
    backgroundColor: colors.primary,
    color: "#fff !important",
  },
  title: {
    lineHeight: "26px",
    fontSize: 20,
    marginLeft: 30,
    marginRight: 30,
    color: "#333333",
    fontFamily: fonts.notoSansKRBold,
  },
  sub: {
    marginLeft: 30,
    marginRight: 30,
    lineHeight: "26px",
    color: "#666666",
    fontSize: 13,
  },

  guide: {
    position: "absolute",
    zIndex: 2,
    width: 40,
    height: 40,
    borderRadius: 100,
    fontFamily: fonts.notoSansKRMedium,
    fontSize: 12,
    color: "rgb(255,0,0)",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",

    borderStyle: "dashed",
    border: "1px solid rgb(255,0,0)",
  },
  root: {
    flex: 1,
    backgroundColor: "rgb(241,241,241)",
    flexDirection: "column",
    display: "flex",
  },

  close: {
    backgroundColor: "rgba(0,0,0,0.5)",
    width: "25%",
    height: "25%",
    position: "absolute",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    zIndex: 3,
    "& img": {
      width: "70%",
      height: "70%",
      objectFit: "contain",
    },
  },
  gg: {
    position: "absolute",
    borderRadius: 3,
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "#fff",
    fontFamily: fonts.notoSansKRMedium,
    fontSize: 12,
    padding: "5px 10px",
    left: 30,
    top: 10,
    zIndex: 2,
  },
  img: {
    borderRadius: 3,
    border: "2px solid #7d7d7d",
    borderStyle: "dotted",
    width: (window.innerWidth - 60 - 24) / 4,
    height: (window.innerWidth - 60 - 24) / 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    objectFit: "cover",
    width: (window.innerWidth - 60 - 24) / 4,
    height: (window.innerWidth - 60 - 24) / 4,
  },
  imageWrap: {
    position: "relative",
    borderRadius: 3,
    cursor: "pointer",
  },
  wrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "8px",
    "&:last-child": {
      marginRight: 0,
    },
    width: (window.innerWidth - 60 - 24) / 4,
  },
  wrapLabel: {
    marginTop: 10,
    fontSize: 12,
    lineHeight: "12px",
    color: "#666",
  },
  camera: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  home: {
    width: 20,
    marginRight: 16,
    height: 20,
    objectFit: "contain",
  },
  images: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 26,
    display: "flex",
  },
  title: {
    lineHeight: "26px",
    fontSize: 20,
    marginTop: 93,
    marginLeft: 30,
    marginRight: 30,
    color: "#333333",
    fontFamily: fonts.notoSansKRBold,
  },
  sub: {
    marginLeft: 30,
    marginRight: 30,
    lineHeight: "26px",
    color: "#666666",
    fontSize: 13,
  },
  desc: {
    color: "#666",
    fontSize: "11.5px",
    lineHeight: "16px",
    margin: "30px",
    "& span": {
      fontSize: "15px",
      color: "#333",
      fontFamily: fonts.notoSansKRMedium,
      lineHeight: "30px",
    },
  },
  message: {
    borderBottom: "1px solid " + colors.primary,
    alignSelf: "flex-start",
    margin: "5px 30px 30px 30px",
    color: colors.primary,
    fontSize: "14px",
  },
  list: {
    paddingLeft: "10px",
    overflowX: "auto",
  },
  navbar: {
    height: 3,
    backgroundColor: "#dddddd",
    alignSelf: "stretch",
    "& div": {
      height: "100%",
      backgroundColor: "#ff6e0e",
      width: "100%",
      marginLeft: 0,
    },
    position: "fixed",
    right: 0,
    left: 0,
    top: 60,
  },
  topbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    display: "flex",
    backgroundColor: "rgb(241,241,241)",
    alignItems: "center",
  },
  back: {
    "& img": {
      width: 10,
      height: 20,
      objectFit: "contain",
    },
    paddingLeft: 14,
    paddingRight: 11,
  },
  navi: {
    fontSize: 16,
    flex: 1,
    color: "#333333",
  },
  item: {
    width: "30%",
    padding: "20px",
    borderRadius: "5px",
    marginLeft: "10px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginBottom: "30px",
    flexDirection: "column",
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  label: {
    marginTop: 25,
    fontSize: 16,
    color: "#000",
  },
  label2: {
    fontSize: "14px",
    coloR: colors.text,
    alignSelf: "center",
  },
  cb: {
    margin: "30px 30px 10px 30px",
  },
  input: {
    margin: "0px 30px",
    "&:last-child": {
      marginBottom: "50px",
    },
  },
  button: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: 50,
    backgroundColor: "#ff6e0e",
    zIndex: 30,
    width: "100%",
    textAlign: "center",
    color: "#fff",
    fontSize: 13.3,
    fontFamily: fonts.notoSansKRBold,
    "&:disabled": { backgroundColor: "#999999" },
  },
});
