import { ButtonBase, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Text from "../../components/text/Text";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import FaqItem from "./FaqItem";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { objToQueryStr } from "../../services/utils";
import Flex from "../../components/flex/Flex";
import * as APIS from "../../libs/apis";

export default function Faq({}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const state = history.location.state;
  const [category, setCategory] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [tag, setTag] = useState(0);

  const handleValueChange = (key, value, more) => {
    history.replace({
      pathname: location.pathname,
      state: { ...location.state, [key]: value || "", ...more },
    });
  };

  useEffect(() => {
    handleValueChange("categoryId", "");
  }, []);

  useEffect(() => {
    dispatch(loadingStart);

    APIS.getCategory()
      .then((res) => {
        const changeCategoryData = res.data.data.map((item) => {
          return {
            label: item.name,
            categoryId: item.id,
          };
        });

        changeCategoryData.unshift({
          label: "자주하는 질문",
          categoryId: "",
        });
        setCategory(changeCategoryData);
      })
      .catch((err) => dispatch(messageError(err)))
      .finally(() => dispatch(loadingEnd));
  }, []);

  useEffect(() => {
    const paramQuery = objToQueryStr({
      faqCategoryId: state?.categoryId,
    });
    window.scrollTo(0, 0);
    dispatch(loadingStart);

    APIS.getFaqData(paramQuery)
      .then((res) => {
        const changeFaqList = res?.data.data?.map((item) => {
          return {
            ...item,
            searchType: paramQuery === "?" ? "all" : "search",
            categoryId: item?.faqCategory?.id || 0,
          };
        });
        setFaqList(changeFaqList);
      })
      .catch((err) => dispatch(messageError(err)))
      .finally(() => dispatch(loadingEnd));
  }, [state?.categoryId]);

  const handleClickCategory = (i, x) => {
    setTag(i);
    handleValueChange("categoryId", x.categoryId);
  };

  return (
    <BasicLayout stackTopbar={{ border: true, title: "자주하는 질문" }}>
      <Text font={fonts.notoSansKRMedium} className={classes.title}>
        도움이 필요하신가요?
      </Text>
      <Grid container>
        {category?.map((x, i) => {
          const selected = tag === i;
          return (
            <Grid
              xs={4}
              key={i.toString()}
              className={`${classes.gridItem} ${
                selected && classes.selectedCatygory
              }`}
              item
            >
              <div
                // onClick={() => {
                //   setCategory((item) =>
                //     item.map((item, index) => {
                //       if (index === i) {
                //         item.on = true;
                //         setTag(i);
                //       } else {
                //         item.on = false;
                //       }
                //       return item;
                //     })
                //   );
                //   handleValueChange("categoryId", x.categoryId);
                // }}
                onClick={() => {
                  handleClickCategory(i, x);
                }}
              >
                {x.label}
              </div>
            </Grid>
          );
        })}
      </Grid>
      {faqList.length ? (
        faqList?.map((item, index) => {
          return (
            <FaqItem
              key={index}
              title={item.title}
              faqCategory={item.faqCategory?.name}
              content={item.content}
              openItem={item.openItem}
              searchType={item.searchType}
              categoryId={item.categoryId}
              serviceImage={item.serviceImage}
            />
          );
        })
      ) : (
        <Flex className={classes.emptyDataMessage}>
          해당 카테고리에 관련된 질문이 없습니다.
        </Flex>
      )}
    </BasicLayout>
  );
}

const useStyle = makeStyles({
  title: {
    fontSize: "20px",
    color: colors.text,
    alignSelf: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  gridItem: {
    backgroundColor: "#f1f1f1",
    boxSizing: "border-box",
    padding: "14px",
    alignItems: "center",
    justifyContent: "center",
    color: "#999999",
    fontSize: "14px",
  },
  emptyDataMessage: { marginTop: 50, flex: 1, alignItems: "center" },
  selectedCatygory: { backgroundColor: colors.primary, color: colors.white },
});
