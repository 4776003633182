import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import ButtonMini from "../../components/button-mini/ButtonMini";
import Button from "../../components/button/Button";
import Flex from "../../components/flex/Flex";
import ShopItemSimple from "../../components/shop-item-simple/ShopItemSimple";
import Text from "../../components/text/Text";
import Span from "../../components/text/Span";
import BasicLayout from "../../layouts/basic/BasicLayout";
import colors from "../../libs/colors";
import consts from "../../libs/consts";
import fonts from "../../libs/fonts";
import routes from "../../libs/routes";
import { updateSignUp } from "../../redux/auth/SignUpReducer";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { messageError, messageShow } from "../../redux/message/MessageActions";
import { userInit } from "../../redux/user/UserActions";
import * as APIS from "../../libs/apis";
import useDebouncedEffect from "../../hooks/useDebouncedEffect";
import { validateEmail } from "../../libs/utils";
import { saveSignupStep } from "../../libs/sign";

export default function AuthRegisterEmail() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { purpose, email } = useSelector((s) => s.signUp);

  useEffect(() => {
    if (!email) {
      APIS.getSnsEmail().then(({ data: { success, data = "" } }) => {
        if (success) {
          dispatch(updateSignUp({ email: data.split(" ").join("") }));
        }
      });
    }
    if(purpose === 'SIGNUP') saveSignupStep('EMAIL')
  }, []);

  const handleChangeEmail = (email) => {
    dispatch(updateSignUp({ email: email.split(" ").join("") }));
  };

  const handleClickNext = () => {
    if (!email) {
      dispatch(messageError("이메일을 입력해주세요."));
    } else if (!validateEmail(email)) {
      dispatch(messageError("이메일 형식을 확인해주세요."));
    } else {
      history.push(routes.authRegisterPassword);
    }
  };
  return (
    <BasicLayout backgroundGray stackTopbar={{ title: "이메일 입력" }}>
      <Container>
        <Flex>
          <Span font={fonts.notoSansKRBold} size={18} spacing={-0.36}>
            이메일을 입력해주세요.
          </Span>
          <EmailInput
            placeholder="이메일을 입력하세요"
            value={email}
            onChange={({ target: { value } }) => handleChangeEmail(value)}
          />
        </Flex>
        <Button label="다음" onClick={handleClickNext} />
      </Container>
    </BasicLayout>
  );
}

const Container = styled(Flex)`
  flex: 1;
  padding: 20px 30px 50px 30px;
  justify-content: space-between;
`;

const EmailInput = styled.input`
  margin-top: 13px;
  outline: none;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  padding: 13px 15px;
  font-family: ${fonts.notoSansKRRegular};
  font-size: 16px;
  letter-spacing: -0.53px;

  &:focus {
    border: 1px solid #222222;
  }
  &::placeholder {
    color: #98999a;
  }
`;
