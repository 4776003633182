import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import _ from "react-app-polyfill/ie9";
import __ from "react-app-polyfill/ie11";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { expireAuth } from "./libs/actions";

// DatePicker css
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { STORAGE_KEY } from "./libs/consts";
registerLocale("ko", ko);
setDefaultLocale(ko);

// 응답 인터셉터 추가
axios.interceptors.response.use(
  function (response) {
    // 응답 데이터를 가공
    // ...
    return response;
  },
  (error) => {
    const status = error?.response?.status;
    if (status === 401 || status === 403) {
      postMessage({
        command: "CUSTOM_LOG",
        param: {
          log: `${error?.response}`,
        },
      });
      expireAuth();
    }

    console.log("api error", error);
    return Promise.reject(error);
  }
);

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     suspense: true,
  //   },
  // },
}); // QueryClient 인스턴스 생성

if (!sessionStorage.getItem(STORAGE_KEY.USER_AGENT)) {
  sessionStorage.setItem(STORAGE_KEY.USER_AGENT, navigator.userAgent);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
