import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import colors from "../../libs/colors";
import fonts from "../../libs/fonts";
import images from "../../libs/images";
import Flex from "../flex/Flex";
import Text from "../text/Text";
import NumberFormat from "react-number-format";
import consts from "../../libs/consts";

export default function ProductListItemPhysical({
  inOrder,
  bottom,
  orow,
  sPrice,
  productData,
}) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  var setPrice = sPrice * orow.gram * orow.qty;

  return (
    <Flex
      style={{
        ...(bottom && { borderBottom: "none" }),
        ...(inOrder && {
          paddingTop: "20px",
          paddingBottom: "20px",
        }),
      }}
      row
      className={classes.root}
    >
      <img
        src={
          productData?.imageIds?.length > 0
            ? consts.s3BaseUrl + "/" + productData?.imageIds[0].path
            : images.goldbar
        }
        className={classes.sample1}
      />
      <Flex className={classes.main}>
        <Flex className="row-between" row>
          {/*<Text className={classes.label2}>{orow.outsourcing}</Text>*/}
          <Text className={classes.label2}></Text>
          <Text className={classes.label3}>{productData.code}</Text>
        </Flex>

        <Text
          className={classes.label1}
          style={{ ...(inOrder && { fontSize: "14px" }) }}
          font={fonts.notoSansKRMedium}
        >
          {orow.name}
        </Text>
        {!inOrder ? (
          <Flex className={classes.rowStart + " row-between"} row>
            <Text className={classes.label2}>제품금액</Text>
            <Text className={classes.label4}>
              <Text font={fonts.notoSansKRMedium}>
                {orow.gram}g * {orow.qty}개
              </Text>
              <br />
              =
              <NumberFormat
                value={setPrice}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          </Flex>
        ) : (
          <Text className={classes.label4} style={{ textAlign: "left" }}>
            {!!orow.optionValue && (
              <>
                옵션 : {orow.optionValue}
                <br />
              </>
            )}
            수량 : {orow.qty}개
            {/* <Text font={fonts.notoSansKRMedium}>{orow.gram}g * {orow.qty}개</Text>
            =<NumberFormat value={setPrice} displayType={'text'} thousandSeparator={true} />원 */}
          </Text>
        )}

        {!inOrder && (
          <Flex className="row-between" row>
            <Text className={classes.label2}>수수료(공임)</Text>
            <Text font={fonts.notoSansKRMedium} className={classes.label5}>
              <NumberFormat
                value={orow.fees}
                displayType={"text"}
                thousandSeparator={true}
              />
              원
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

const useStyle = makeStyles({
  sample1: {
    width: "70px",
    height: "70px",
  },
  root: {
    borderBottom: "1px solid #eee",
    padding: "14px 0px",
    margin: "0px 14px",
  },
  main: {
    marginLeft: "16px",
    alignSelf: "stretch",
    flex: 1,
  },
  label1: { color: colors.text, fontSize: 16, marginTop: "5px" },
  label2: {
    fontSize: "12px",
    color: colors.gray,
  },
  label3: {
    fontSize: "10px",
    color: colors.gray,
  },
  label4: {
    color: "#333",
    textAlign: "right",
    fontSize: "12px",
    "& span": { fontSize: "14px" },
  },
  rowStart: {
    marginTop: "6px",
    alignItems: "flex-start !important",
  },
  label5: { fontSize: "12px", color: "#333" },
});
