import React, { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import routes from "../../libs/routes";
import { signOut } from "../../libs/sign";
import ic_ok_green from "../../assets/images/ic_ok_green.png";
import * as APIS from "../../libs/apis";
import { useSelector } from "react-redux";

const LeaveComplete = () => {
  
  const auth = useSelector((state) => state.auth);
  const { isSigned } = auth;
  useEffect(() => {
    return () => {
      if (isSigned) {
        signOut();
      }
    };
  }, []);

  return (
    <div className="withdrawal_ok">
      <img src={ic_ok_green} />
      <h3>회원 탈퇴가 완료 되었습니다.</h3>
      <p>그동안 이용해주셔서 감사드립니다.</p>
      <div className="ok_btn on" onClick={signOut}>
        확인
      </div>
    </div>
  );
};

export default LeaveComplete;
