import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BasicLayout from "../../layouts/basic/BasicLayout";
import * as APIS from "../../libs/apis";
import routes from "../../libs/routes";
import ic_wallet from "../../assets/images/ic_wallet.png";
import ic_gold from "../../assets/images/ic_gold.png";
import ic_silver from "../../assets/images/ic_silver.png";
import ic_won from "../../assets/images/ic_won.png";
import ic_point from "../../assets/images/my_asset_point.webp";
import ic_re_no from "../../assets/images/ic_re_no.png";
import ic_ok_green from "../../assets/images/ic_ok_green.png";
import ic_no_new from "../../assets/images/ic_no_new.png";
import { numberWithCommas } from "../../libs/utils";
import { useDispatch } from "react-redux";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import { actionError } from "../../redux/action/ActionActions";
import FormatAmount from "../../components/common/FormatAmount";

const Leave = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || { agree: false };
  const { agree } = locationState;

  const [asset, setAsset] = useState({
    krw: 0,
    gold: 0,
    silver: 0,
    point: 0
  });
  const [limit, setLimit] = useState(0);
  const [showDanger, setShowDanger] = useState(false);
  const [rejectPopup, setRejectPopup] = useState({ visible: false });

  useEffect(() => {
    dispatch(loadingStart);

    const getUserAsset = APIS.getUserHoldingsInfo().then(
      ({ data: { success, data } }) => {
        if (success) {
          setAsset({ ...asset, ...data });
        }
      }
    );

    const getLeaveLimit = APIS.getEnvConfig("LEAVE_LIMIT_ASSET").then(
      ({ data: { success, data } }) => {
        if (success) {
          setLimit(data.value);
        }
      }
    );

    Promise.all([getUserAsset, getLeaveLimit]).finally(() =>
      dispatch(loadingEnd)
    );
  }, []);

  const handleClickNext = () => {
    if (agree) {
      dispatch(loadingStart);
      APIS.getLeaveAsset()
        .then(({ data: { success, message } }) => {
          if (success) {
            history.push(routes.leaveReason);
          } else {
            setRejectPopup({ visible: true, message });
          }
        })
        .finally(() => dispatch(loadingEnd));
    } else {
      setShowDanger(true);
    }
  };

  const { krw, gold, silver, point } = asset;
  const formatLimit = numberWithCommas(limit);
  return (
    <BasicLayout stackTopbar={{ title: "탈퇴하기", border: true }}>
      <div
        className="withdrawal_wrap"
        style={{ display: "flex", flex: 1, flexDirection: "column" }}
      >
        <div className="assets_tb_wrap">
          <div className="assets_tb">
            <div className="assets_tb_top">
              <img src={ic_wallet} />
              <p>총 보유 자산</p>
            </div>
            <ul>
              <li>
                <img src={ic_gold} />금
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={gold} />
                    <td className="gram">g</td>
                  </tr>
                </table>
              </li>
              <li>
                <img src={ic_silver} />은
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={silver} />
                    <td className="gram">g</td>
                  </tr>
                </table>
              </li>
              <li>
                <img src={ic_won} style={{
                  height: '18px'
                }} />
                원화
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={krw} />
                    <td className="won">원</td>
                  </tr>
                </table>
              </li>
              <li>
                <img src={ic_point} style={{
                  height: '18px'
                }} />
                포인트
                <table className="num_tb">
                  <tr>
                    <FormatAmount amount={point} />
                    <td className="won">pt</td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
        </div>
        <div className="bar"></div>
        <div className="withdrawal_noti_wrap" style={{ flex: 1 }}>
          <h3 className="withdrawal_tit">주의사항</h3>
          <ul className="withdrawal_noti_check check1">
            <li>
              <img src={ic_re_no} />
              회원탈퇴 불가능
            </li>
            <li>
              평가(보유)금액 <span className="spoqa">{formatLimit}</span>원 초과
            </li>
          </ul>
          <ul className="withdrawal_noti_check check2">
            <li>
              <img src={ic_ok_green} />
              회원탈퇴 가능
            </li>
            <li>
              평가(보유)금액 <span className="spoqa">{formatLimit}</span>원
              이하는
              <br />
              ㈜한국금거래소디지털에셋에
              <br />
              양도
            </li>
          </ul>
          <div className="withdrawal_noti">
            <ul>
              {/* <li>
                보유자산이 있는 경우에는 탈퇴가 불가능합니다. 탈퇴를 희망하실{" "}
                <br />
                경우에는 모든 자산을 매도 및 출금하신 후 탈퇴하여 주십시오.
              </li> */}
              <li>
                보유자산 평가금액이 {formatLimit}원 이하일 경우,
                ㈜한국금거래소디지
                <br />
                털에셋에 자동으로 양도된 후 탈퇴 처리 됩니다.
              </li>
              <li>
                회원 탈퇴 시 등록된 개인 정보 및 거래 정보는 관계 법령에 따라
                일정 <br />
                기간 보관 후 삭제됩니다.
              </li>
            </ul>
          </div>
        </div>
        <div className="bar"></div>
        <div className="withdrawal_btn">
          <ul>
            <li>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox1"
                checked={agree}
                onChange={({ target: { checked } }) => {
                  setShowDanger(false);
                  history.replace(history.location.pathname, {
                    agree: checked,
                  });
                }}
              />
              <label htmlFor="checkbox1">
                남은 자산은 ㈜한국금거래소디지털에셋으로 <br />
                귀속됨을 확인합니다. <span className="orgcolor">(필수)</span>
              </label>
            </li>
            {showDanger && (
              <span className="danger">
                위 내용에 동의하셔야 회원탈퇴를 진행할 수 있습니다.
              </span>
            )}
          </ul>
          <div>
            <button onClick={() => history.goBack()}>취소</button>
            <button className={agree ? "on" : "off"} onClick={handleClickNext}>
              다음
            </button>
          </div>
        </div>
      </div>
      {rejectPopup.visible && (
        <RejectPopup
          {...rejectPopup}
          onClick={() => {
            history.push(routes.mypage);
          }}
        />
      )}
    </BasicLayout>
  );
};

const RejectPopup = ({ message, onClick }) => {
  return (
    <div className="trade_wrap_bk" style={{ position: "fixed", top: 0 }}>
      <div className="pd_select">
        <div className="withdrawal_pop">
          <img src={ic_no_new} />
          <h3>탈퇴하실 수 없습니다.</h3>
          <p>
            {/* 보유한 금, 은을 모두 매도하고
            <br />
            모든 원화를 출금한 후 탈퇴하시기 바랍니다. */}
            {message.split("").map((x, i) => {
              if (x === "\n") {
                return <br key={i.toString()} />;
              } else {
                return x;
              }
            })}
          </p>
        </div>
        <div className="ok_btn on" onClick={onClick}>
          보유자산 확인하기
        </div>
      </div>
    </div>
  );
};

export default Leave;
